// CSS for this modal is in calendar.css + editUser.css

// INSTRUCTIONS TO LOAD MODAL
// use props
// show modal with showEditShiftModal stored in DataContext

import React, { useEffect, useState, useContext, useRef, useMemo } from "react";
// cleaned wef 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios done 7 sep 24
import axios from "axios";

import DatePicker from "react-datepicker";

import { DataContext } from "../../../contexts/DataContext";
import { CalendarContext } from "../../../contexts/CalendarContext";
import { UserContext } from "../../../contexts/UserContext";

import { NavLink } from "react-router-dom";

import serverURL from "../../../serverURL";
import { StyleContext } from "../../../contexts/StyleContext";
import horizontalBalls from "../../../img/loaders/horizontalBalls.svg";

import close from "../../../img/modals/close.svg";
import editGrey from "../../../img/general/editGrey.svg";
import closeGrey from "../../../img/general/closeGrey.svg";
import navyClose from "../../../img/general/navyClose.svg";
import bin1 from "../../../img/general/bin1.svg";
import removeTagImg from "../../../img/general/removeTagImg.svg";
import tagAdd from "../../../img/general/tagAdd.svg";
import checkbox1 from "../../../img/general/checkboxInstall1.svg";
import checkbox2 from "../../../img/general/checkboxInstall2pub.svg";
import greyTag from "../../../img/general/greyTag.svg";
import greyAdd from "../../../img/general/greyAdd.svg";
import editReq from "../../../img/general/editReq.svg";
import noteImg from "../../../img/general/noteWhite.svg";

import dateStringer from "../../../tools/dateStringer";

// type = til, overtime, shift
const OpenMgrShiftModal = ({
  unitDs,
  unitTeamID,
  unitItemIsNew,
  unitOpenID,
}) => {
  const { mobModal } = useContext(StyleContext);

  const {
    setUpdateOpen,
    setOpenShiftConvert,
    updateOpensModal,
    setUpdateOpensModal,
    modalOpen,
    setModalOpen,
    setIndicate,
    device,
    countData,
    setCountData,
    openShiftEdited,
    setOpenShiftEdited,
  } = useContext(DataContext);
  const { checkUserState } = useContext(UserContext);
  const {
    setUpdateFlag,
    showOpenShiftModal,
    setShowOpenShiftModal,
    setSingleDay,
    setSingleDay2,
  } = useContext(CalendarContext);

  const memoVals = useMemo(
    () => ({
      setUpdateFlag,
      showOpenShiftModal,
      setShowOpenShiftModal,
      setSingleDay,
      setSingleDay2,
      setUpdateOpen,
      setOpenShiftConvert,
      checkUserState,
      mobModal,
      updateOpensModal,
      setUpdateOpensModal,
      modalOpen,
      setModalOpen,
      setIndicate,
      device,
      openShiftEdited,
      setOpenShiftEdited,
    }),
    [
      setUpdateFlag, //
      showOpenShiftModal, //
      setShowOpenShiftModal, //
      setSingleDay, //
      setSingleDay2, //
      setUpdateOpen, //
      setOpenShiftConvert, //
      checkUserState, //
      mobModal, //
      updateOpensModal,
      setUpdateOpensModal,
      modalOpen,
      setModalOpen,
      setIndicate,
      device,
      openShiftEdited,
      setOpenShiftEdited,
    ]
  );

  useEffect(() => {
    memoVals.setModalOpen((x) => true);

    return () => {
      memoVals.setModalOpen((x) => false);
    };
  }, [memoVals.setModalOpen]);

  let [itemType, setItemType] = useState("shift");

  let [editShiftStart, setEditShiftStart] = useState("");
  let [editShiftEnd, setEditShiftEnd] = useState("");
  let [editShiftBrk, setEditShiftBrk] = useState(0);

  let [editDurationMins, setEditDurationMins] = useState(0);
  let [showShiftModalTeamName, setShowShiftModalTeamName] = useState("");
  let [editPublicNoteValue, setEditPublicNoteValue] = useState("");
  let [shiftTeamID, setShiftTeamID] = useState("");
  let [tags, setTags] = useState([]);
  let [teamID, setTeamID] = useState("");
  let [availableTags, setAvailableTags] = useState([]);
  let [jobTitles, setJobTitles] = useState([]);
  let [showDatepicker, setShowDatepicker] = useState(false);
  let [editedShiftDate, setEditedShiftDate] = useState("");

  let [showEditTimes, setShowEditTimes] = useState(false);
  let [startPurgatory, setStartPurgatory] = useState("12:00");
  let [endPurgatory, setEndPurgatory] = useState("18:00");
  let [startPurgatorySubmit, setStartPurgatorySubmit] = useState("12:00");
  let [endPurgatorySubmit, setEndPurgatorySubmit] = useState("18:00");
  let [showEditBrk, setShowEditBrk] = useState(false);
  let [breakPurgatory, setBreakPurgatory] = useState(0);
  let [addingNewTag, setAddingNewTag] = useState(false);
  let [newTagValue, setNewTagValue] = useState("");

  let [editPublicNote, setEditPublicNote] = useState(false);

  let [sureDeleteShift, setSureDeleteShift] = useState(false);
  let [showAvailableTagsModal, setShowAvailableTagsModal] = useState(false);
  let [rate, setRate] = useState(1);
  let [loaded, setLoaded] = useState(false);

  let [startContradictsTil, setStartContradictsTil] = useState(false);
  let [endContradictsTil, setEndContradictsTil] = useState(false);

  let [imagesLoaded, setImagesLoaded] = useState(0);
  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if (dataLoaded && imagesLoaded === 12) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);

  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={close}
        alt="Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={editGrey}
        alt="Edit Grey"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={closeGrey}
        alt="Close Grey"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={navyClose}
        alt="Navy Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={bin1}
        alt="Bin 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={removeTagImg}
        alt="Remove Tag Img"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={tagAdd}
        alt="Tag Add"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={checkbox1}
        alt="Checkbox 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={checkbox2}
        alt="Checkbox 2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={greyTag}
        alt="Grey Tag"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={greyAdd}
        alt="Grey Add"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={editReq}
        alt="Edit Req"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );
  let [showEditUnitType, setShowEditUnitType] = useState(false);

  let [notes, setNotes] = useState([]);

  let [itemIsNew, setItemIsNew] = useState(false);
  let [timePickerDrops, setTimePickerDrops] = useState(false);
  let [initial, setInitial] = useState(false);

  let [acceptFirstUser, setAcceptFirstUser] = useState(false);

  let [editTagSuggestions, setEditTagSuggestions] = useState(false);

  let [showAssign, setShowAssign] = useState(false);
  let [assignUserID, setAssignUserID] = useState("");
  let [assignees, setAssignees] = useState([]);

  let [changed, setChanged] = useState(false);

  const addTagInputRef = useRef();
  const editNoteInputReq = useRef();

  useEffect(() => {
    // clean not needed
    if (addTagInputRef.current) {
      addTagInputRef.current.focus();
    }
  }, [addingNewTag]);

  useEffect(() => {
    // clean not needed

    if (editNoteInputReq.current) {
      editNoteInputReq.current.focus();
    }
  }, [editPublicNote]);

  let [changesMade, setChangesMade] = useState(false);
  try {
    const openDateInput = document.getElementById("openDateInput");
    if (openDateInput && !memoVals.mobModal) {
      openDateInput.addEventListener("focus", function (evt) {
        try {
          if (this.getAttribute("type") === "date") {
            this.showPicker();
          }
        } catch (error) {
          console.error("Error in openDateInput focus event listener:", error);
        }
      });
    }
  } catch (error) {
    console.error("Error in setting up openDateInput:", error);
  }

  try {
    const shiftBrkInput1 = document.getElementById("shiftBrkInput1");
    if (shiftBrkInput1 && !memoVals.mobModal) {
      shiftBrkInput1.addEventListener("focus", function (evt) {
        try {
          if (this.getAttribute("type") === "time") {
            this.showPicker();
          }
        } catch (error) {
          console.error("Error in shiftBrkInput1 focus event listener:", error);
        }
      });
    }
  } catch (error) {
    console.error("Error in setting up shiftBrkInput1:", error);
  }

  try {
    const shiftBrkInput2 = document.getElementById("shiftBrkInput2");
    if (shiftBrkInput2 && !memoVals.mobModal) {
      shiftBrkInput2.addEventListener("focus", function (evt) {
        try {
          if (this.getAttribute("type") === "time") {
            this.showPicker();
          }
        } catch (error) {
          console.error("Error in shiftBrkInput2 focus event listener:", error);
        }
      });
    }
  } catch (error) {
    console.error("Error in setting up shiftBrkInput2:", error);
  }

  let [publicNote, setPublicNote] = useState([]);

  let [changedDate, setChangedDate] = useState(false);

  let [changeType, setChangeType] = useState("shift");

  let [claimants, setClaimants] = useState([]);

  let [hoverUserID, setHoverUserID] = useState("");

  let shortenAssName = (name) => {
    if (name.length > 13) {
      return `${name.substr(0, 10)}...`;
    } else {
      return name;
    }
  };

  let [addedBy, setAddedBy] = useState("");
  let [initialMade, setInitialMade] = useState(0);
  let [shiftDoesNotExist, setShiftDoesNotExist] = useState(false);
  let [availableLocations, setAvailableLocations] = useState([]);
  let [locationID, setLocationID] = useState("");
  let [jt, setJt] = useState("");
  let generateAssigns = assignees.map((ass) => {
    if (!jt || jt === ass.jobTitle) {
      return (
        <option key={ass.userID} value={ass.userID}>
          {shortenAssName(`${ass.fName} ${ass.lName}`)}
        </option>
      );
    }
  });
  let getSingleOpenData = () => {
    axios
      .post(
        `${serverURL}/get-single-open-data`,
        {
          openID: unitOpenID,
        },

        {
          withCredentials: true,
          credentials: "include",
        }
      )
      .then((response) => {
        if (response.data.message && response.data.message.includes("avail")) {
          setShiftDoesNotExist(true);
          setDataLoaded(true);
        }
        if (response.data.message === "success") {
          setTeamID(response.data.teamID);
          setAddedBy(response.data.addedBy);
          setInitialMade(response.data.initialMade);
          setItemType(response.data.type);
          setChangeType(response.data.type);
          setRate(response.data.rate);
          setAvailableLocations(response.data.locations || []);
          setJobTitles(response.data.jobTitles || []);
          setLocationID(response.data.locationID);
          setEditShiftStart(
            dateStringer.createTimestampFromString(response.data.start)
          );
          setJt(response.data.jt);
          setEditShiftEnd(
            dateStringer.createTimestampFromString(response.data.end)
          );
          // setAssignees(response.data.assignUserObjs);
          setEditShiftBrk(response.data.break);

          setEditDurationMins(response.data.durationMins);
          response.data.publicNote.sort(function (a, b) {
            return (
              dateStringer.createTimestampFromString(b.ds) -
              dateStringer.createTimestampFromString(a.ds)
            );
          });
          setNotes(response.data.publicNote);
          setShowShiftModalTeamName(response.data.teamName);
          // setEditPublicNote(response.data.publicNote);
          setShiftTeamID(response.data.teamID);
          setTags(response.data.tags);
          setClaimants(response.data.bidUserIDs);
          setInitial(response.data.initial);
          setItemIsNew(response.data.initial);
          if (response.data.initial) {
            setTimePickerDrops(true);
          }
          if (response.data.initial) {
            setShowEditTimes(true);
          }
          setAcceptFirstUser(response.data.acceptFirstUser);
          setLoaded(true);
          setDataLoaded(true);
          if (showEditTimes) {
            setShowEditTimes(false);
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    // clean not needed

    getSingleOpenData();
  }, [memoVals.showOpenShiftModal]);

  let timeOptions = [];

  for (let hour = 0; hour <= 23; hour++) {
    for (let minute = 0; minute <= 45; minute += 15) {
      const time = `${hour.toString().padStart(2, "0")}:${minute
        .toString()
        .padStart(2, "0")}`;
      timeOptions.push(time);
    }
  }
  let generateTimeOptions = timeOptions.map((opt) => {
    return <option value={opt}>{opt}</option>;
  });

  let generateMonthDuration = (mins) => {
    let h = Math.floor(mins / 60);
    let m = mins % 60;
    if (m !== 0) {
      return `${h} hours, ${m} mins`;
    } else if (h === 0 && m === 0) {
      return ``;
    } else {
      return `${h} hours`;
    }
  };

  let [dayN, setDayN] = useState(0);
  useEffect(() => {
    // clean not needed

    if (editShiftStart) {
      let dateObj = new Date(editShiftStart);
      setDayN(dateObj.getDay());
    }
  }, [editShiftStart]);
  let generateShiftTags = tags
    ? tags.map((tag, i) => {
        return (
          <div key={`${tag}${tag[i]}`} className="tagUnit">
            <p className="tagTxt">{tag}</p>
            <img
              className="pullTagImg"
              src={closeGrey}
              alt="Remove tag"
              onClick={() => {
                setTags(
                  tags.filter((x) => {
                    return x !== tag;
                  })
                );
                axios
                  .post(
                    `${serverURL}/remove-shift-tag`,
                    {
                      shiftID: unitOpenID,
                      data: tag,
                      open: true,
                    },

                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .then((response) => {
                    if (response.data.message === "success") {
                      // setChangesMade(true);
                      // memoVals.setUpdateFlag(x=>true);
                      setTags(response.data.newItem.tags || []);
                      memoVals.setOpenShiftConvert((x) => {
                        return {
                          direction: "editing",
                          newItem: response.data.newItem,
                          openID: unitOpenID,
                        };
                      });
                      if (!availableTags.includes(tag)) {
                        setAvailableTags([...availableTags, tag]);
                      }
                      setChanged(true);
                    }
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }}
            />
          </div>
        );
      })
    : "";

  let getStartEndString = (start, end) => {
    let startDate = new Date(start);
    let startH = startDate.getHours();
    let startM = startDate.getMinutes();

    let endDate = new Date(end);
    let endH = endDate.getHours();
    let endM = endDate.getMinutes();

    let prepend0 = (val) => {
      if (JSON.stringify(val).length === 1) {
        return `0${val}`;
      } else {
        return val;
      }
    };
    return `${prepend0(startH)}:${prepend0(startM)} - ${prepend0(
      endH
    )}:${prepend0(endM)}`;
  };

  let getFullDate = (start) => {
    let dateObj = new Date(start);
    let day = dateObj.getDay();
    let date = dateObj.getDate();
    let m = dateObj.getMonth();
    let y = dateObj.getFullYear();
    let ySub = JSON.stringify(y).substr(2, 2);

    let convertDay = (num) => {
      if (num === 0) {
        return "Sun";
      } else if (num === 1) {
        return "Mon";
      } else if (num === 2) {
        return "Tue";
      } else if (num === 3) {
        return "Wed";
      } else if (num === 4) {
        return "Thu";
      } else if (num === 5) {
        return "Fri";
      } else if (num === 6) {
        return "Sat";
      }
    };

    let convertMonth = (num) => {
      if (num === 0) {
        return "Jan";
      } else if (num === 1) {
        return "Feb";
      } else if (num === 2) {
        return "Mar";
      } else if (num === 3) {
        return "Apr";
      } else if (num === 4) {
        return "May";
      } else if (num === 5) {
        return "Jun";
      } else if (num === 6) {
        return "Jul";
      } else if (num === 7) {
        return "Aug";
      } else if (num === 8) {
        return "Sep";
      } else if (num === 9) {
        return "Oct";
      } else if (num === 10) {
        return "Nov";
      } else if (num === 11) {
        return "Dec";
      }
    };

    return `${convertDay(day)} ${date} ${convertMonth(m)} '${ySub}`;
  };

  let generateMonthDurationForModal = (mins) => {
    let h = Math.floor(mins / 60);
    let m = Math.round(mins % 60);
    if (mins < 60) {
      return `${Math.round(m)} mins`;
    }
    if (m !== 0) {
      return `${h}h, ${Math.round(m)}m`;
    } else if (h === 0 && m === 0) {
      return ``;
    } else {
      if (h === 1) {
        return `${h} hour`;
      } else {
        return `${h} hours`;
      }
    }
  };

  let generateNotes =
    notes && notes[0]
      ? notes
          .sort((a, b) => {
            return a.ts - b.ts;
          })
          .map((note) => {
            let nameVal = `${note.fName} ${note.lName}`;
            return (
              <div className="shiftNotesObj">
                <div className="noteObjNameAndDs">
                  <p className="noteObjName">
                    {nameVal && nameVal.length > 14
                      ? `${note.fName} ${
                          note.lName && note.lName[0] ? note.lName[0] : ""
                        }`
                      : nameVal}
                  </p>
                  <p className="noteObjFsDs">
                    {dateStringer.printedDateWithTimeFromDs(note.ds)}
                  </p>
                </div>
                <p className="noteObjNote marginBottom10">{note.note}</p>
                {note.userID === memoVals.checkUserState.userID ? (
                  <div className="deleteNoteRow marginBottom10 flexEnd">
                    <img
                      src={bin1}
                      alt="Remove note"
                      className="binNoteImg"
                      onClick={() => {
                        axios
                          .post(
                            `${serverURL}/add-note-to-shift`,
                            {
                              type: itemType,
                              shiftID: unitOpenID,
                              open: true,
                              noteIDifRemoving: note.noteID,
                              itemIsNew: itemIsNew,
                            },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              setChangesMade(true);

                              response.data.publicNote.sort(function (a, b) {
                                return (
                                  dateStringer.createTimestampFromString(b.ds) -
                                  dateStringer.createTimestampFromString(a.ds)
                                );
                              });
                              setNotes(response.data.publicNote);
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            );
          })
      : "";
  let generateAvailableTags = availableTags
    ? availableTags.map((tag, i) => {
        return (
          <div
            className={`availableTagTxt ${
              editTagSuggestions ? "editTagDivFlex" : ""
            } ${i === availableTags.length - 1 ? "lastAvailTag" : ""}`}
            key={`${tag}${tag[i]}`}
            onClick={(e) => {
              e.stopPropagation();
              if (!editTagSuggestions) {
                // setChangesMade(true);

                axios
                  .post(
                    `${serverURL}/add-new-shift-tag`,
                    {
                      shiftID: unitOpenID,
                      data: tag,
                      open: true,
                    },

                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .then((response) => {
                    if (response.data.message === "success") {
                      setTags(response.data.newItem.tags || []);
                      setChanged(true);

                      setAddingNewTag(false);
                      setNewTagValue("");

                      let arr = [];
                      availableTags.forEach((aTag) => {
                        if (tag === aTag) {
                        } else {
                          arr.push(aTag);
                        }
                      });
                      setAvailableTags(arr);
                      setShowAvailableTagsModal(false);
                      memoVals.setOpenShiftConvert((x) => {
                        return {
                          direction: "editing",
                          newItem: response.data.newItem,
                          openID: unitOpenID,
                        };
                      });
                    }
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }
            }}
          >
            {tag}
            <img
              src={removeTagImg}
              className={`${editTagSuggestions ? "removeTagImg" : "none"}`}
              alt="Remove"
              onClick={(e) => {
                e.stopPropagation();
                axios
                  .post(
                    `${serverURL}/exclude-tag-from-bank`,
                    {
                      teamID: unitTeamID || shiftTeamID,
                      tag: tag,
                    },

                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .then((response) => {
                    if (response.data.message === "success") {
                      axios
                        .post(
                          `${serverURL}/get-available-tags`,
                          { teamID: unitTeamID || shiftTeamID },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((resp) => {
                          if (resp.data.message === "success") {
                            let arr = [];
                            resp.data.tags.forEach((tag) => {
                              if (tags.includes(tag)) {
                              } else {
                                arr.push(tag);
                              }
                            });
                            setAvailableTags(arr);
                            setShowAvailableTagsModal(true);
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }
                  });
              }}
            />
          </div>
        );
      })
    : "";

  let generateClaimants = claimants.map((claim) => {
    return (
      <div className="claimantUnit" key={claim.ts}>
        <div className="claimantTxtLeft">
          <p className="claimantName">
            {claim.fName} {claim.lName[0]}{" "}
          </p>
          <p className="claimantTsSpan">
            {/* Claimed{" "} */}
            {dateStringer.printedDateWithTimeFromDs(
              dateStringer.createStringFromTimestamp(claim.ts)
            )}
          </p>
          <p className="usersHoursThisWeek">
            {/* Claimed{" "} */}
            {dateStringer.formatMinsDurationToHours(claim.totMins)} scheduled
            this week
          </p>
        </div>
        <p
          className="giveClaimantBtn"
          onClick={() => {
            axios
              .post(
                `${serverURL}/convert-open-to-shift-or-til`,
                {
                  userID: claim.userID,
                  openID: unitOpenID,
                  nowDs: dateStringer.createStringFromTimestamp(
                    new Date().getTime()
                  ),
                },

                {
                  withCredentials: true,
                  credentials: "include",
                }
              )
              .then((response) => {
                if (response.data.message === "success") {
                  memoVals.setOpenShiftConvert((x) => {
                    return {
                      direction: "toUser",
                      newItem: response.data.newItem,
                      openID: unitOpenID,
                    };
                  });
                  // setmemoVals.memoVals.showOpenShiftModal({});

                  setCountData({
                    openShiftsMgr: countData.openShiftsMgr - 1,
                  });
                  memoVals.setShowOpenShiftModal((x) => false);
                  setUpdateOpensModal(!updateOpensModal);
                  // memoVals.setShowOpenShiftModal(x =>false);
                }
              })
              .catch((err) => {
                console.error(err);
              });
          }}
        >
          Assign
        </p>
      </div>
    );
  });

  let enterFct = (e) => {
    if (e.key === "Enter") {
      if (newTagValue.length > 0) {
        setChangesMade(true);
        memoVals.setUpdateFlag((x) => true);

        axios
          .post(
            `${serverURL}/add-new-shift-tag`,
            {
              shiftID: unitOpenID,
              data: newTagValue,
              open: true,
            },

            {
              withCredentials: true,
              credentials: "include",
            }
          )
          .then((response) => {
            if (response.data.message === "success") {
              setTags(response.data.newItem.tags || []);
              setAddingNewTag(false);
              setNewTagValue("");
              setChanged(true);

              memoVals.setOpenShiftConvert((x) => {
                return {
                  direction: "editing",
                  newItem: response.data.newItem,
                  openID: unitOpenID,
                };
              });
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  };

  let closeModal = () => {
    if (changed) {
      memoVals.setUpdateOpensModal(!memoVals.updateOpensModal);
      memoVals.setOpenShiftEdited((x) => !memoVals.openShiftEdited);
    }

    memoVals.setShowOpenShiftModal((x) => {});

    memoVals.setShowOpenShiftModal((x) => false);

    if (itemIsNew) {
      axios
        .post(
          `${serverURL}/delete-shift`,
          {
            shiftID: unitOpenID,
            type: "open",
          },

          {
            withCredentials: true,
            credentials: "include",
          }
        )
        .then((response) => {
          if (response.status === 200) {
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    memoVals.setShowOpenShiftModal((x) => false);
  };

  const tagsModalRef = useRef(null);

  const [isDraggingTags, setIsDraggingTags] = useState(false);
  const [dragStartTags, setDragStartTags] = useState({ x: 0, y: 0 });
  const [modalPositionTags, setModalPositionTags] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPositionTags, setDragPositionTags] = useState(0);

  const handleDownTags = (e) => {
    setIsDraggingTags(true);
    setDragStartTags({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMoveTags = (e) => {
    if (isDraggingTags) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStartTags.y;

      if (deltaY > 0) {
        setDragPositionTags(deltaY);
        setModalPositionTags({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };
  useEffect(() => {
    if (showAvailableTagsModal) {
      setModalPositionTags({
        bottom: `0px`,
        // left: `calc(0% - ${deltaX}px)`,
      });
    }
  }, [showAvailableTagsModal]);

  const handleUpTags = () => {
    setIsDraggingTags(false);

    if (dragPositionTags > 140) {
      if (loading) {
      } else {
        setShowAvailableTagsModal(false);
      }
    } else {
      setModalPositionTags({ bottom: "0%", left: "0%" });
    }
  };
  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 30) {
      memoVals.setShowOpenShiftModal((x) => false);
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  let notesRef = useRef();
  let noteEnterFct = (e) => {
    if (e === "add" || e.key === "Enter") {
      if (editPublicNoteValue) {
        // setChangesMade(true);
        axios
          .post(
            `${serverURL}/add-note-to-shift`,
            {
              open: true,
              type: itemType,
              shiftID: unitOpenID,
              ds: dateStringer.createStringFromTimestamp(new Date().getTime()),
              note: editPublicNoteValue,
            },

            {
              withCredentials: true,
              credentials: "include",
            }
          )
          .then((response) => {
            if (response.data.message === "success") {
              setChangesMade(true);

              response.data.publicNote.sort(function (a, b) {
                return (
                  dateStringer.createTimestampFromString(b.ds) -
                  dateStringer.createTimestampFromString(a.ds)
                );
              });

              setNotes(response.data.publicNote);
              setEditPublicNote(false);
              setTimeout(() => {
                if (notesRef && notesRef.current) {
                  notesRef.current.scrollTo({
                    top: notesRef.current.scrollHeight, // Scroll to the bottom of the div
                    behavior: "smooth", // Smooth scroll behavior
                  });
                }
              }, 200);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  };

  let [notified, setNotified] = useState(false);

  let [editTeam, setEditTeam] = useState(false);

  let [availableTeams, setAvailableTeams] = useState([]);
  useEffect(() => {
    if (!availableTeams[0]) {
      // get teams
      axios
        .post(
          `${serverURL}/get-teams-user-manages`,
          { someData: 1 },

          {
            withCredentials: true,
            credentials: "include",
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            setAvailableTeams(response.data.array || []);
          }
        });
    }
  }, []);

  let generateAvailableTeams = useMemo(() => {
    if (availableTeams) {
      return availableTeams.map((team) => {
        if (team.teamName !== "Teamless") {
          return (
            <option key={team.teamID} value={team.teamID}>
              {dateStringer.shorten(team.teamName, 30)}
            </option>
          );
        }
      });
    }
  }, [availableTeams]);
  // master return
  return (
    <div
      className="editShiftModalUnderlay"
      onClick={() => {
        closeModal();
      }}
    >
      {loading ? (
        <div
          className={`shiftLoadingBox ${
            memoVals.mobModal ? "shiftLoadingBoxMob" : "shiftLoadingBoxDesktop"
          }`}
        >
          <img
            src={horizontalBalls}
            alt="Loading"
            className={`shiftLoadingBallsImg ${
              memoVals.mobModal ? "shiftLoadingBallsImgMob" : "zoomIn"
            }`}
          />
        </div>
      ) : (
        <div
          className={`${
            editShiftStart && editShiftEnd
              ? //   editShiftEnd && editShiftStart && editShiftShiftID
                "editShiftModalBox mgrShiftModalBoxBorder"
              : "none"
          }  ${
            memoVals.mobModal
              ? `mobManagerOpenShiftModalBox mobModalShoulder ${
                  itemIsNew ? "newOpenShiftModalBoxHeights" : ""
                } slideUp`
              : "zoomIn"
          } mgrModal`}
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={modalPosition}
          ref={modalRef}
        >
          {memoVals.mobModal && (
            <div
              className="modalSwiper modalSwiperOpenMgr"
              onTouchStart={handleDown}
              onTouchMove={memoVals.mobModal ? handleMove : null}
              onTouchEnd={memoVals.mobModal ? handleUp : null}
              onMouseDown={memoVals.mobModal ? handleDown : null}
              onMouseMove={memoVals.mobModal ? handleMove : null}
              onMouseUp={memoVals.mobModal ? handleUp : null}
            ></div>
          )}
          {/* header of add admin user - inherited from Add user to team modal in EditTeam.js */}
          <div
            className="addUserToTeamHeaderContainer"
            onTouchStart={handleDown}
            onTouchMove={memoVals.mobModal ? handleMove : null}
            onTouchEnd={memoVals.mobModal ? handleUp : null}
            onMouseDown={memoVals.mobModal ? handleDown : null}
            onMouseMove={memoVals.mobModal ? handleMove : null}
            onMouseUp={memoVals.mobModal ? handleUp : null}
          >
            {/* <div
              // className={`shiftCloseHolder ${loading ? "disableCloseBtn" : ""}`}
            // > */}
            <img
              src={close}
              // src={memoVals.mobModal ? downModal : close}
              alt="Close"
              className="closeManageShiftModalBtnImg"
              onClick={() => {
                closeModal();
              }}
            />
            {/* </div> */}
            <p className="addUserToTeamTitle marginLeft0">
              {itemType === "shift"
                ? `${itemIsNew ? "Add open shift" : "Open shift"}`
                : `${
                    itemType === "overtime"
                      ? `${itemIsNew ? "Add open overtime" : "Open overtime"}`
                      : `${
                          itemIsNew
                            ? "Add open overtime (time in lieu)"
                            : "Open overtime (time in lieu)"
                        }`
                  }`}
            </p>
            <div className="shiftCloseHolder shiftCloseHolderRight marginLeft0 minWidth33">
              <p
                className={`editShiftDurationTopRightTxt  ${
                  itemIsNew ? "none" : ""
                }`}
              >
                {`${generateMonthDurationForModal(editDurationMins)}`}
              </p>
            </div>
          </div>
          {/* end of header */}
          {/* subheader bubble bar */}
          <div className={`shiftBubblesBar ${loaded ? "" : "none"}`}>
            {!editTeam ? (
              <div
                className="shiftTeamSubBubble shiftTeamSubBubbleMgrBg cursorPointer"
                onClick={() => {
                  setEditTeam(true);
                }}
              >
                {dateStringer.shorten(showShiftModalTeamName, 30)}
                <img
                  src={editGrey}
                  className="purpleEditChangeTeamIcon"
                  alt="Change team"
                />
              </div>
            ) : (
              <select
                className="editShiftTeamDropdown editShiftTeamDropdownOpen"
                value={shiftTeamID}
                onChange={(e) => {
                  let val = e.target.value;

                  if (val === "new") {
                    window.location.href = `${appURL}/admin/teams`;
                  } else {
                    axios
                      .post(
                        `${serverURL}/edit-shift-team`,
                        {
                          shiftID: unitOpenID,
                          type: "open",
                          teamID: val,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          setChangesMade(true);
                          setShiftTeamID(response.data.teamID);
                          setTeamID(response.data.teamID);
                          setShowShiftModalTeamName(response.data.teamName);
                          setEditTeam(false);
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }
                }}
              >
                {itemIsNew &&
                availableTeams.length === 0 &&
                memoVals.checkUserState.permissions === 1 ? (
                  <option value="new">-- Create a new team</option>
                ) : (
                  ""
                )}
                {generateAvailableTeams}
              </select>
            )}
          </div>
          {/* end of subheader bubble bar */}
          {/* modal body */}
          <p
            onClick={() => {}}
            className={`${loaded ? "" : "none"} shiftPublishedStatus ${
              !acceptFirstUser && claimants.length === 0 ? "unpubBg" : ""
            }`}
          >
            {`${
              claimants.length > 0
                ? `${claimants.length} ${
                    claimants.length === 1 ? "person" : "employees"
                  } want${claimants.length === 1 ? "s" : ""} this ${
                    itemType === "til"
                      ? "overtime (time in lieu)"
                      : `${itemType}`
                  }`
                : `${
                    acceptFirstUser
                      ? `Given to the first bidder`
                      : `You will choose the winning bidder`
                  }`
            }`}
          </p>{" "}
          <div
            className={`${
              editShiftStart && editShiftEnd
                ? `editShiftModalBody ${
                    memoVals.device.ios ? "editShiftModalBodyIos" : ""
                  }`
                : "none"
            }`}
            ref={notesRef}
          >
            <p className="openAddedBy">
              Created by {addedBy} on{" "}
              {dateStringer.printedDateFromDs(
                dateStringer.createStringFromTimestamp(initialMade)
              )}
            </p>
            {/* new row - - - - - - - - - - - - - - - - */}
            <div
              className={`editShiftRow ${
                !showEditUnitType ? "cursorPointer" : ""
              }`}
              onClick={() => {
                if (!showEditUnitType) {
                  setShowEditUnitType(true);
                }
              }}
            >
              <div className="shiftTypeLeftBox">
                <div className="shiftEditTitleTxt">Type</div>

                <div
                  className={`${
                    showEditUnitType
                      ? "none"
                      : "shiftEditTitle mgrModalTitleColour openTypeBox"
                  }`}
                >
                  <span className="unitTypeSpan openTypeSpan">
                    {itemType === "shift"
                      ? "Shift"
                      : `${
                          itemType === "til"
                            ? "Overtime (time in lieu)"
                            : "Overtime"
                        }`}
                  </span>
                </div>
                <select
                  className={`${
                    showEditUnitType
                      ? "unitTypeDropdown openUnitTypeDropdown"
                      : "none"
                  }`}
                  value={changeType}
                  onChange={(e) => {
                    setChangeType(e.target.value);
                  }}
                >
                  <option value="shift">Shift</option>
                  <option value="overtime">Overtime (paid)</option>
                  <option value="til">Overtime (time in lieu)</option>
                </select>
              </div>

              <img
                src={editGrey}
                className={`${showEditUnitType ? "none" : "editShiftPencil"}`}
                onClick={() => {}}
              />
              <p
                className={`${
                  showEditUnitType ? "saveBtnTxt saveOpenTypeBtn" : "none"
                }`}
                onClick={() => {
                  // fire request here
                  axios
                    .post(
                      `${serverURL}/convert-shift-or-til`,
                      {
                        shiftID: unitOpenID,
                        oldType: itemType,
                        newType: changeType,
                        open: true,
                        itemIsNew: itemIsNew,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        setChangeType(response.data.newType);
                        setItemType(response.data.newType);
                        // getSingleOpenData();

                        setShowEditUnitType(!showEditUnitType);
                      } else {
                        setShowEditUnitType(!showEditUnitType);
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Save
              </p>
            </div>
            {/*  new row */}
            <div className="editShiftRow">
              <div className="shiftTypeLeftBox">
                <div className="shiftEditTitleTxt dateOpenTxtTitle">Date</div>
                <div
                  className={`${
                    showDatepicker ? "none" : "shiftEditTitle openDateString"
                  }`}
                >
                  {getFullDate(editShiftStart)}
                </div>
                {/* <input className={`${showDatepicker ? "shiftBrkInput" : "none"}`} id="shiftBrkInput" defaultValue={editShiftBrk} type="number" onChange={(e) => {setBreakPurgatory(e.target.value)}}></input> mins break */}

                <input
                  type="date"
                  className={`${
                    showDatepicker ? "addShiftDateInput openDateInput" : "none"
                  } ${memoVals.device.ios ? "iosDateInput" : ""}`}
                  id="openDateInput"
                  defaultValue={editedShiftDate}
                  onChange={(e) => {
                    let ds = dateStringer.createStringFromDateInput(
                      e.target.value
                    );

                    if (e.target.value[0] === "2") {
                      setEditedShiftDate(e.target.value);
                    }
                  }}
                ></input>
              </div>

              <img
                src={editGrey}
                className={`${showDatepicker ? "none" : "editShiftPencil"}`}
                onClick={() => {
                  setEditedShiftDate(
                    dateStringer.createDateInputFromDateString(
                      dateStringer.createStringFromTimestamp(editShiftStart)
                    )
                  );
                  setTimePickerDrops(false);
                  setShowDatepicker(!showDatepicker);
                }}
              />
              <p
                className={`${
                  showDatepicker && editedShiftDate.length === 10
                    ? "saveBtnTxt openDateSaveBtn"
                    : "none"
                }`}
                onClick={() => {
                  // fire request here
                  memoVals.setSingleDay((x) => (x) => dayN);

                  let d = editedShiftDate.substr(8, 2);
                  let m = parseInt(editedShiftDate.substr(5, 2)) - 1;
                  let y = editedShiftDate.substr(0, 4);
                  let dateObj = new Date(y, m, d, 0, 0, 0, 0);
                  let timestamp = dateObj.getTime();
                  memoVals.setSingleDay2((x) => dateObj.getDay());

                  // convert the editedShiftDate to a timestamp, then dateStringer to string it for axios
                  if (
                    dateStringer.createStringFromTimestamp(timestamp).length ===
                    17
                  ) {
                    setChangesMade(true);
                    memoVals.setUpdateFlag((x) => true);
                    setChanged(true);
                    //   setLoading(true);
                    setChangedDate(true);
                    axios
                      .post(
                        `${serverURL}/edit-shift-midnight-timestamp`,
                        {
                          shiftID: unitOpenID,
                          data: dateStringer.createStringFromTimestamp(
                            timestamp
                          ),
                          open: true,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          setEditShiftStart(
                            dateStringer.createTimestampFromString(
                              response.data.newStartDs
                            )
                          );
                          setEditShiftEnd(
                            dateStringer.createTimestampFromString(
                              response.data.newEndDs
                            )
                          );

                          setShowDatepicker(!showDatepicker);
                          setEditedShiftDate("");

                          memoVals.setOpenShiftConvert((x) => {
                            return {
                              direction: "editing",
                              newItem: response.data.newItem,
                              openID: unitOpenID,
                            };
                          });
                          //   setLoading(false);
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }
                }}
              >
                Save
              </p>
            </div>
            {/* new row...time row- - - - - - - - - - - - - - - - */}
            <div
              className={`editShiftRow ${itemIsNew ? "timesItemIsNewBg" : ""}`}
            >
              <div className="shiftTypeLeftBox">
                <div
                  className={`shiftEditTitleTxt ${
                    itemIsNew ? "whiteUntil" : ""
                  }`}
                >
                  Times
                </div>

                <div
                  className={`${
                    showEditTimes
                      ? "none"
                      : "shiftEditTilTimeContainer openTimesStrip"
                  }`}
                >
                  {getStartEndString(editShiftStart, editShiftEnd)}
                </div>
                <div
                  className={`${
                    showEditTimes ? "shiftEditTitle openDateStripInput" : "none"
                  }`}
                >
                  <div
                    className={`${
                      showEditTimes ? "startEndShiftContainer" : "none"
                    }`}
                  >
                    {timePickerDrops ? (
                      <select
                        className={`timePickerDropper `}
                        defaultValue="12:00"
                        onChange={(e) => {
                          setStartPurgatory(e.target.value);
                          setStartPurgatorySubmit(
                            JSON.stringify(e.target.value)
                          );
                        }}
                      >
                        {generateTimeOptions}
                      </select>
                    ) : (
                      <input
                        className={`shiftTimeInput ${
                          startContradictsTil ? "contradictsTil" : ""
                        } ${memoVals.device.ios ? "iosTimeInput" : ""}`}
                        id="newTimeStartInput1"
                        value={startPurgatory}
                        type="time"
                        onChange={(e) => {
                          setStartPurgatory(e.target.value);
                          setStartPurgatorySubmit(
                            JSON.stringify(e.target.value)
                          );
                        }}
                      ></input>
                    )}
                    {/*                   
                  <input
                    className={`shiftTimeInput `}
                    id="shiftBrkInput1"
                    value={startPurgatory}
                    type="time"
                    onChange={(e) => {
                      setStartPurgatory(e.target.value);
                      setStartPurgatorySubmit(JSON.stringify(e.target.value));
                    }}
                  ></input> */}
                    <p
                      className={`editShiftTimesUntilTxt ${
                        itemIsNew ? "whiteUntil" : ""
                      }`}
                    >
                      until
                    </p>
                    {timePickerDrops ? (
                      <select
                        className={`timePickerDropper `}
                        defaultValue="18:00"
                        onChange={(e) => {
                          setEndPurgatory(e.target.value);
                          setEndPurgatorySubmit(JSON.stringify(e.target.value));
                        }}
                      >
                        {generateTimeOptions}
                      </select>
                    ) : (
                      <input
                        className={`shiftTimeInput ${
                          endContradictsTil ? "contradictsTil" : ""
                        } ${memoVals.device.ios ? "iosTimeInput" : ""}`}
                        id="newTimeEndInput1"
                        value={endPurgatory}
                        type="time"
                        onChange={(e) => {
                          setEndPurgatory(e.target.value);
                          setEndPurgatorySubmit(JSON.stringify(e.target.value));
                        }}
                      ></input>
                    )}
                    {/* <input
                    className={`shiftTimeInput`}
                    id="shiftBrkInput2"
                    value={endPurgatory}
                    type="time"
                    onChange={(e) => {
                      setEndPurgatory(e.target.value);
                      setEndPurgatorySubmit(JSON.stringify(e.target.value));
                    }}
                  ></input> */}
                  </div>
                  {timePickerDrops ? (
                    <p
                      className="editTimeSpecificBtn specTimesOpenMgr"
                      onClick={() => {
                        setTimePickerDrops(false);
                      }}
                    >
                      Specific times{" "}
                      <img
                        src={editReq}
                        alt="Specific times mode"
                        className="specTimesModeImg"
                      />
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <img
                src={editGrey}
                className={`${
                  showEditTimes || startContradictsTil || endContradictsTil
                    ? "none"
                    : "editShiftPencil"
                }`}
                onClick={() => {
                  let startPre = JSON.stringify(
                    getStartEndString(editShiftStart, editShiftEnd)
                  ).substr(1, 5);
                  let endPre = JSON.stringify(
                    getStartEndString(editShiftStart, editShiftEnd)
                  ).substr(9, 5);
                  setStartPurgatory(startPre);
                  setStartPurgatorySubmit(startPre);
                  setEndPurgatory(endPre);
                  setEndPurgatorySubmit(endPre);
                  setShowEditBrk(false);
                  setShowDatepicker(false);

                  setShowEditTimes(!showEditTimes);
                }}
              />
              {showEditTimes ? (
                <div className="saveAndSpecContainer">
                  <p
                    className={`${
                      showEditTimes && startPurgatory && endPurgatory
                        ? "saveBtnTxt openDateSaveBtn"
                        : "none"
                    }`}
                    onClick={() => {
                      if (startPurgatory && endPurgatory) {
                        let startSub = startPurgatorySubmit;
                        let endSub = endPurgatorySubmit;

                        if (startPurgatorySubmit.length === 7) {
                          startSub = `${startPurgatorySubmit.substr(1, 5)}`;
                        }

                        if (endPurgatorySubmit.length === 7) {
                          endSub = `${endPurgatorySubmit.substr(1, 5)}`;
                        }
                        setChangesMade(true);
                        memoVals.setUpdateFlag((x) => true);
                        setChanged(true);

                        //   setLoading(true);

                        axios
                          .post(
                            `${serverURL}/edit-shift-times`,
                            {
                              shiftID: unitOpenID,
                              open: true,
                              startTime: startSub,
                              endTime: endSub,
                            },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              // if (response.status === 200) {
                              getSingleOpenData();

                              memoVals.setOpenShiftConvert((x) => {
                                return {
                                  direction: "editing",
                                  newItem: response.data.newItem,
                                  openID: unitOpenID,
                                };
                              });

                              // }
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }
                    }}
                  >
                    Save
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
            {/* new row - - - - - - - - - - - - - - - - */}
            <div className={`editShiftRow ${itemIsNew ? "noBorder_" : ""}`}>
              <div className="shiftTypeLeftBox">
                <div className="shiftEditTitleTxt">Break</div>
                <div
                  className={`${
                    showEditBrk
                      ? "shiftEditTitle shiftEditBrkInputMove"
                      : "none"
                  }`}
                >
                  <input
                    className={`${showEditBrk ? "shiftBrkInput" : "none"}`}
                    id="shiftBrkInput"
                    value={breakPurgatory}
                    type="number"
                    onChange={(e) => {
                      setBreakPurgatory(e.target.value);
                    }}
                  ></input>{" "}
                  mins
                </div>

                <div
                  className={`${
                    showEditBrk ? "none" : "shiftEditTitle editBreakMove"
                  }`}
                >
                  {editShiftBrk} mins
                </div>
              </div>

              <img
                src={editGrey}
                className={`${showEditBrk ? "none" : "editShiftPencil"}`}
                onClick={() => {
                  setBreakPurgatory(editShiftBrk);
                  setShowEditBrk(!showEditBrk);

                  if (!itemIsNew) {
                    setShowDatepicker(false);
                    setShowEditTimes(false);
                  }
                }}
              />

              <p
                className={`${
                  showEditBrk ? "saveBtnTxt pushSaveBrkBtnDown" : "none"
                }`}
                onClick={() => {
                  if (
                    // editDurationMins > breakPurgatory &&
                    breakPurgatory >= 0
                  ) {
                    setChangesMade(true);
                    memoVals.setUpdateFlag((x) => true);

                    axios
                      .post(
                        `${serverURL}/edit-shift-break`,
                        {
                          shiftID: unitOpenID,
                          data: parseInt(breakPurgatory),
                          open: true,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.status === 200) {
                          setEditShiftBrk(response.data.newBrkMins);
                          setBreakPurgatory(response.data.newBrkMins);
                          setEditDurationMins(response.data.newDurMins); // stella
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }
                  setShowEditBrk(!showEditBrk);
                }}
              >
                Save
              </p>
            </div>{" "}
            {/* new row - - - - - - - - - - - - - - - - */}
            <div className="editShiftRow">
              <p className="shiftEditTitle move5pxR">Role</p>

              <select
                className="shiftRateDropdown locDrop"
                value={jt}
                onChange={(e) => {
                  let val = e.target.value;
                  setChangesMade(true);
                  setJt(val);
                  axios
                    .post(
                      `${serverURL}/edit-job-title-on-open-shift`,
                      {
                        openID: unitOpenID,
                        jobTitle: val,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        // setShiftCost(response.data.newCost);
                        // here we goThe am
                        memoVals.setOpenShiftEdited(
                          (x) => !memoVals.openShiftEdited
                        );

                        setJt(response.data.jobTitle);

                        // setShiftCost(response.data.newCost);
                        // setItemBankData(response.data.bankData);
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                <option value="">-- Any</option>
                {jobTitles.map((x) => {
                  return (
                    <option value={x} key={x}>
                      {x === jt ? dateStringer.shorten(x, 18) : x}
                    </option>
                  );
                })}
              </select>
            </div>
            {/* new row - - - - - - - - - - - - - - - - */}
            {/* new row - - - - - - - - - - - - - - - - */}
            <div className="editShiftRow">
              <p className="shiftEditTitle move5pxR">Location</p>

              <select
                className="shiftRateDropdown locDrop"
                value={locationID}
                onChange={(e) => {
                  let val = e.target.value;
                  setChangesMade(true);

                  setLocationID(val);
                  axios
                    .post(
                      `${serverURL}/edit-shift-location`,
                      {
                        shiftID: unitOpenID,
                        locationID: val,
                        type: "open",
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        // setShiftCost(response.data.newCost);
                        // here we goThe am

                        setLocationID(response.data.locationID);
                        memoVals.setOpenShiftEdited(
                          (x) => !memoVals.openShiftEdited
                        );

                        // setShiftCost(response.data.newCost);
                        // setItemBankData(response.data.bankData);
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                <option value="">-- No location</option>
                {availableLocations.map((loc) => {
                  return (
                    <option value={loc.locationID} key={loc.locationID}>
                      {locationID === loc.locationID
                        ? dateStringer.shorten(loc.locationName, 18)
                        : loc.locationName}
                    </option>
                  );
                })}
              </select>
            </div>
            {/* new row - - - - - - - - - - - - - - - - */}
            {!itemIsNew && itemType !== "til" && (
              <div className="editShiftRow">
                <p className="shiftEditTitle move5pxR">Rate</p>

                <select
                  className="shiftRateDropdown"
                  value={rate}
                  onChange={(e) => {
                    setChangesMade(true);

                    setRate(e.target.value);

                    axios
                      .post(
                        `${serverURL}/edit-shift-rate`,
                        {
                          shiftID: unitOpenID,
                          data: parseFloat(e.target.value),
                          open: true,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          setRate(response.data.rate);
                          memoVals.setOpenShiftConvert((x) => {
                            return {
                              direction: "editing",
                              newItem: response.data.newItem,
                              openID: unitOpenID,
                            };
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <option value="0">0x</option>
                  <option value="0.3">0.3x</option>
                  <option value="0.5">0.5x</option>
                  <option value="0.6">0.6x</option>
                  <option value="0.75">0.75x</option>

                  <option value="1">1x</option>
                  <option value="1.5">1.5x</option>
                  <option value="1.75">1.75x</option>
                  <option value="2">2x</option>
                  <option value="2.25">2.25x</option>
                  <option value="2.5">2.5x</option>
                  <option value="2.75">2.75x</option>
                  <option value="3">3x</option>
                  <option value="3.25">3.25x</option>
                  <option value="3.5">3.5x</option>
                  <option value="3.75">3.75x</option>
                  <option value="4">4x</option>
                </select>
              </div>
            )}
            <div
              className={`${claimants.length > 0 ? "editShiftRow" : "none"}`}
            >
              <div className="shiftEditTitle mgrModalTitleColour claimantsTitle">
                <p className="selectClaimantTitle">
                  Bids:{" "}
                  <span className="chooseClaimantSpan">Choose a bidder</span>
                </p>
                <div className="claimantsDiv">{generateClaimants}</div>
              </div>
            </div>
            {/* new row - - - - - - - - - - - - - - - - */}
            {/* new row - - - - - - - - - - - - - - - - */}
            <div className={`${itemIsNew ? "none" : "editShiftRow"}`}>
              <div className="shiftTypeLeftBox">
                <div className="shiftEditTitleTxt">Tags</div>
                <div
                  className={`${
                    tags.length === 0 ? "shiftEditValue noTagsTxt" : "none"
                  }`}
                >
                  No tags
                </div>

                <div
                  className={`${
                    !addingNewTag && tags.length === 0
                      ? "none"
                      : "shiftTagHolder"
                  }`}
                >
                  {generateShiftTags}
                  <div
                    className={`${addingNewTag ? "addTagUnit" : "none"}`}
                    onClick={() => {
                      setNewTagValue("");
                    }}
                  >
                    <input
                      className="tagInput"
                      placeholder="New tag"
                      ref={addTagInputRef}
                      onKeyPress={(e) => {
                        enterFct(e);
                      }}
                      type="text"
                      // defaultValue={newTagValue}
                      value={newTagValue}
                      onChange={(e) => {
                        // if (newTagValue.length < 18) {
                        setNewTagValue(e.target.value);
                        // }
                      }}
                    ></input>
                    <img
                      className="addTagIcon"
                      src={tagAdd}
                      alt="Add tag"
                      onClick={() => {
                        if (newTagValue.length > 0) {
                          setChangesMade(true);
                          memoVals.setUpdateFlag((x) => true);
                          setAddingNewTag(false);

                          axios
                            .post(
                              `${serverURL}/add-new-shift-tag`,
                              {
                                shiftID: unitOpenID,
                                data: newTagValue,
                                open: true,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                setTags(response.data.newItem.tags || []);
                                setAddingNewTag(false);
                                setNewTagValue("");
                                setShowAvailableTagsModal(false);
                                setChanged(true);

                                memoVals.setOpenShiftConvert((x) => {
                                  return {
                                    direction: "editing",
                                    newItem: response.data.newItem,
                                    openID: unitOpenID,
                                  };
                                });
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }
                      }}
                    />
                  </div>
                </div>
              </div>

              <p
                className={`${!addingNewTag ? "addTagTxtBtn" : "none"}`}
                onClick={() => {
                  // setNewTagValue("New tag");
                  // setAddingNewTag(true);
                  setChangesMade(true);
                  memoVals.setUpdateFlag((x) => true);

                  if (!availableTags[0]) {
                    axios
                      .post(
                        `${serverURL}/get-available-tags`,
                        { teamID: shiftTeamID },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((resp) => {
                        if (resp.data.message === "success") {
                          let arr = [];
                          resp.data.tags.forEach((tag) => {
                            if (tags.includes(tag)) {
                            } else {
                              arr.push(tag);
                            }
                          });
                          setAvailableTags(arr);
                          if (arr[0]) {
                            setShowAvailableTagsModal(true);
                          } else {
                            setNewTagValue("");
                            setAddingNewTag(true);
                            setShowAvailableTagsModal(false);
                          }
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  } else {
                    setShowAvailableTagsModal(true);
                  }
                }}
              >
                Add tag{" "}
                <img src={greyTag} alt="Add tag" className="addTagInlineSh" />
              </p>
            </div>
            {/* new row - - - - - - - - - - - - - - - - */}
            <div className={`${itemIsNew ? "none" : "editShiftRow assignRow"}`}>
              <div className="makeOpenTxtDiv">
                <p className="assignTitle">Assign </p>
                <p
                  className={`${
                    showAssign ? "makeOpenExplainTxt" : "makeOpenExplainTxt"
                  }`}
                >
                  {" "}
                  Assign this open{" "}
                  {itemType === "til"
                    ? "overtime (time in lieu)"
                    : `${itemType === "shift" ? "shift" : "overtime"}`}{" "}
                  to{" "}
                  {!jt
                    ? "someone"
                    : `${
                        jt === "No job title"
                          ? "someone with no job title"
                          : jt[0] === "A" ||
                            jt[0] === "E" ||
                            jt[0] === "I" ||
                            jt[0] === "O" ||
                            jt[0] === "U"
                          ? `an ${jt}`
                          : `a ${jt}`
                      }`}{" "}
                  in {showShiftModalTeamName}
                </p>
              </div>
              <p
                className={`${!showAssign ? "makeOpenBtn" : "none"}`}
                onClick={() => {
                  if (!showAssign) {
                    axios
                      .post(
                        `${serverURL}/get-team-users-for-open-shift-assign`,
                        { teamID: teamID || unitTeamID || shiftTeamID },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          setAssignees(response.data.assignUserObjs);
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }
                  setShowAssign(!showAssign);
                }}
              >
                Assign
              </p>
              <select
                className={`${showAssign ? "assignOpenDropdown" : "none"}`}
                onChange={(e) => {
                  setAssignUserID(e.target.value);
                  axios
                    .post(
                      `${serverURL}/convert-open-to-shift-or-til`,
                      {
                        userID: e.target.value,
                        openID: unitOpenID,
                        nowDs: dateStringer.createStringFromTimestamp(
                          new Date().getTime()
                        ),
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        setUpdateOpensModal(!updateOpensModal);

                        memoVals.setOpenShiftConvert((x) => {
                          return {
                            direction: "toUser",
                            newItem: response.data.newItem,
                            openID: unitOpenID,
                          };
                        });
                        setCountData({
                          openShiftsMgr: countData.openShiftsMgr - 1,
                        });

                        memoVals.setShowOpenShiftModal((x) => false);
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                <option value="default">-- Assign to...</option>
                {generateAssigns}
              </select>
            </div>
            {/* end of new row - - - - - - - - -  */}
            {/* new row - - - - - - - - - - - - - - - - */}
            <div className={`${itemIsNew ? "none" : "editShiftRow assignRow"}`}>
              <div className="makeOpenTxtDiv">
                <p className="assignTitle">Notify team </p>
                <p
                  className={`${
                    showAssign ? "makeOpenExplainTxt" : "makeOpenExplainTxt"
                  }`}
                >
                  {" "}
                  Notify {showShiftModalTeamName} about this open{" "}
                  {itemType === "til"
                    ? "overtime (time in lieu)"
                    : `${itemType === "shift" ? "shift" : "overtime"}`}{" "}
                  and invite them to bid.
                </p>
              </div>
              <p
                className={`makeOpenBtn lilacColours ${
                  notified ? "disabled" : ""
                }`}
                onClick={() => {
                  setNotified(true);
                  setTimeout(() => {
                    setNotified(false);
                  }, 8000);
                  axios
                    .post(
                      `${serverURL}/notify-open`,
                      {
                        openID: unitOpenID,
                        nowDs: dateStringer.createStringFromTimestamp(
                          new Date().getTime()
                        ),
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        memoVals.setIndicate((x) => {
                          return {
                            show: true,
                            message: `Notified ${showShiftModalTeamName}`,
                            duration: 4000,
                            colour: "green",
                          };
                        });
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Notify
              </p>
            </div>
            {/* end of new row - - - - - - - - -  */}
            {/* new row - - - - - - - - - - - - - - - - */}
            <div
              className={`editShiftRow  ${editPublicNote ? "noteRow" : ""} ${
                itemIsNew ? "none" : ""
              } noBorder`}
            >
              <div className="shiftTypeLeftBox">
                <div className="shiftEditTitleTxt">Notes</div>
              </div>

              <p></p>
            </div>
            <div className="notesHolder">
              {notes[0] ? (
                generateNotes
              ) : (
                <p className="noOpenNotes">No notes yet</p>
              )}
              {editPublicNote ? (
                <div className="shiftAddNoteDiv x32934929244">
                  <textarea
                    className={`${
                      editPublicNote ? "editShiftNoteOnAdmin" : "none"
                    }`}
                    id="editPublicNoteValueInput"
                    type="textarea"
                    // defaultValue={editPublicNoteValue}
                    ref={editNoteInputReq}
                    placeholder="Insert note"
                    value={editPublicNoteValue}
                    onChange={(e) => {
                      setEditPublicNoteValue(e.target.value);
                    }}
                    onKeyPress={(e) => {
                      noteEnterFct(e);
                    }}
                  ></textarea>
                  <div className="shiftAddNoteRightBtns">
                    <p
                      className={`shiftAddNoteSaveBtn ${
                        !editPublicNoteValue ? "disable" : ""
                      }`}
                      onClick={() => {
                        noteEnterFct("add");
                      }}
                    >
                      {/* shiftID, type, ds, noteIDifRemoving, note */}
                      Add
                    </p>
                    {/* <p
                    className="shiftAddNoteSaveBtnCanx"
                    onClick={() => {
                      setToggleAddNote(false);
                    }}
                  >
                    Cancel
                  </p> */}
                  </div>
                </div>
              ) : (
                <p
                  className="addNoteMgrShiftBtn blueColours"
                  onClick={() => {
                    setEditPublicNoteValue("");
                    setEditPublicNote(true);
                    setTimeout(() => {
                      if (notesRef && notesRef.current) {
                        notesRef.current.scrollTo({
                          top: notesRef.current.scrollHeight, // Scroll to the bottom of the div
                          behavior: "smooth", // Smooth scroll behavior
                        });
                      }
                    }, 200);
                  }}
                >
                  Add note{" "}
                  <img
                    src={noteImg}
                    alt="Add note"
                    className="addNoteMgrShiftBtnImg"
                  />
                </p>
              )}
            </div>
            {/* new row - - - - - - - - - - - - - - - - */}
            {/* new row - - - - - - - - - - - - - - - - */}
            {/* new row - - - - - - - - - - - - - - - - */}
          </div>
          {/* end of modal body */}
          {/* convert modal -- til only */}
          {/* end of convert modal -- til only */}
          {/* modal footer */}
          <div className="editShiftModalFooter">
            <div className="openShiftFooterLeftContainer">
              <div
                className={`pubShiftBtn ${
                  acceptFirstUser ? "pubImmedBg_" : ""
                }  ${claimants.length > 0 ? "none" : ""}
              `}
                onClick={() => {
                  setSureDeleteShift("");
                  setAcceptFirstUser(!acceptFirstUser);
                  axios
                    .post(
                      `${serverURL}/toggle-open-accept-first-user`,
                      {
                        openID: unitOpenID,
                        data: !acceptFirstUser,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        setAcceptFirstUser(response.data.data);
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                <img
                  src={acceptFirstUser ? checkbox2 : checkbox1}
                  className={`autoAccImg ${
                    acceptFirstUser ? "autoAccImgActive" : ""
                  }`}
                  alt="Publish"
                />
                Auto-accept
              </div>

              {!itemIsNew ? (
                <p
                  className={`notSureDeleteShiftBtn ${
                    claimants.length > 0 ? "marginLeft0" : "marginLeft8px"
                  }`}
                  onClick={() => {
                    if (!sureDeleteShift) {
                      setSureDeleteShift(true);
                    }
                  }}
                >
                  Delete
                </p>
              ) : (
                ""
              )}
              {/* <p
              className={`${itemType !== "shift" ? "convertTilBtn" : "none"}`}
              onClick={() => {
                if (itemType !== "shift") {
                  setShowConvertModal(true);
                }
              }}
            >
              Convert
            </p> */}
            </div>
            <p
              className={`closeEditShiftBtn`}
              onClick={() => {
                closeModal();
              }}
            >
              Close
            </p>
          </div>
          {/* end of modal footer */}
        </div>
      )}
      {/*end of modalBox  */}

      {/* choose available tags modal */}
      <div
        className={`${
          showAvailableTagsModal ? "availableTagsUnderlay" : "none"
        }`}
        onClick={(e) => {
          e.stopPropagation();
          setShowAvailableTagsModal(false);
        }}
      >
        <div
          className={`availableTagsModal ${
            memoVals.mobModal ? "mobModalShoulder" : ""
          }`}
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={modalPositionTags}
          ref={tagsModalRef}
        >
          {memoVals.mobModal && (
            <div
              className="modalSwiper modalSwiperAddTags"
              onTouchStart={handleDownTags}
              onTouchMove={memoVals.mobModal ? handleMoveTags : null}
              onTouchEnd={memoVals.mobModal ? handleUpTags : null}
              onMouseDown={memoVals.mobModal ? handleDownTags : null}
              onMouseMove={memoVals.mobModal ? handleMoveTags : null}
              onMouseUp={memoVals.mobModal ? handleUpTags : null}
            ></div>
          )}
          <div
            className="addTagModalHeader"
            onTouchStart={handleDownTags}
            onTouchMove={memoVals.mobModal ? handleMoveTags : null}
            onTouchEnd={memoVals.mobModal ? handleUpTags : null}
            onMouseDown={memoVals.mobModal ? handleDownTags : null}
            onMouseMove={memoVals.mobModal ? handleMoveTags : null}
            onMouseUp={memoVals.mobModal ? handleUpTags : null}
          >
            <img
              className="closeAddTagModalImg"
              src={navyClose}
              alt="Close"
              onClick={() => {
                setShowAvailableTagsModal(false);
              }}
            />
            <p className="addTagModalHeaderTitle">Add tags</p>
            <p className="phantomModalHeaderUnit">&nbsp;</p>
          </div>

          <div className="generateAvailTagsScrollBox">
            {generateAvailableTags}{" "}
            <div
              className={`${editTagSuggestions ? "none" : "addTagRowItem"}`}
              onClick={() => {
                setNewTagValue("");
                setAddingNewTag(true);
                setShowAvailableTagsModal(false);
              }}
            >
              Add new tag
            </div>
          </div>
          <div className="tagMgrDiv">
            <p
              className="editTagSugBtn"
              onClick={() => {
                setEditTagSuggestions(!editTagSuggestions);
              }}
            >
              {editTagSuggestions ? "Done" : "Edit suggestions"}
            </p>{" "}
            {/* <div
              className={`${editTagSuggestions ? "none" : "addTagRowItem"}`}
              onClick={() => {
                setNewTagValue("");
                setAddingNewTag(true);
                setShowAvailableTagsModal(false);
              }}
            >
              Add new tag
            </div> */}
            <p
              className="closeMgrAvailReqBtn"
              onClick={() => {
                setNewTagValue("");
                setAddingNewTag(true);
                setShowAvailableTagsModal(false);
              }}
            >
              Close
            </p>
            {/* <p className="phantomModalHeaderUnit">&nbsp;</p> */}
            {/* <p
              className="addNewTagBtn"
              onClick={() => {
                setNewTagValue("");
                setAddingNewTag(true);
                setShowAvailableTagsModal(false);
              }}
            >
              Add new tag
            </p> */}
          </div>
        </div>
      </div>

      {/* end of choose available tags modal */}
      {sureDeleteShift ? (
        <div
          className="areYouSureModalUnderlay"
          onClick={() => {
            setSureDeleteShift(false);
          }}
        >
          <div
            className="formCreatedModal"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <p className="overlapsRenTxt">
              Are you sure you want to delete this open{" "}
              {itemType === "til" ? "overtime (time in lieu)" : itemType}?
            </p>
            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  setChangesMade(true);
                  memoVals.setUpdateFlag((x) => true);

                  axios
                    .post(
                      `${serverURL}/delete-shift`,
                      {
                        shiftID: unitOpenID,
                        type: `open`,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        if (!itemIsNew) {
                          setCountData({
                            openShiftsMgr: countData.openShiftsMgr - 1,
                          });
                          memoVals.setOpenShiftConvert((x) => {
                            return {
                              direction: "deleting",
                              openID: unitOpenID,
                            };
                          });
                        }

                        memoVals.setShowOpenShiftModal((x) => false);
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Yes
              </p>
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setSureDeleteShift(false);
                }}
              >
                No
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {shiftDoesNotExist ? (
        <div
          className="addAbsenceModalUnderlay"
          onClick={(e) => {
            closeModal();
          }}
        >
          <div
            className="formCreatedModal"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            This open shift no longer exists.
            <div className="areYouModalBtnsDiv">
              <p
                className="closeSwapsNoLongerBtn"
                onClick={() => {
                  closeModal();
                }}
              >
                Close
              </p>
              {/* <p
                    className="areYouModalNoBtn"
                    onClick={() => {
                      setNotEnoughModal(false);
                    }}
                  >
                    Cancel
                  </p> */}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {imgPreload}
    </div>
    // end of underlay
  );
};

export default OpenMgrShiftModal;

// to open this modal, go to My Account and click on a team bubble
import React, { useContext, useEffect, useState, useRef, useMemo } from "react";
// cleaned wef 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios done 7 aug 24

// opened on my account page -- your teams

import { StyleContext } from "../contexts/StyleContext";
import { DataContext } from "../contexts/DataContext";
import { UserContext } from "../contexts/UserContext";

import axios from "axios";
import serverURL from "../serverURL";

import "../styles/navbar.css";
import dateStringer from "../tools/dateStringer";
import horizontalBalls from "../img/loaders/horizontalBalls.svg";

import navyClose from "../img/general/navycross.svg";
import greyCross from "../img/general/greyCross.svg";
import tagAdd from "../img/general/tagAdd.svg";
import navyTick from "../img/general/navyTick.svg";
import tagsGo from "../img/general/tagsGo.svg";
import whitePlus from "../img/general/plus_white.svg";
import whitePencil from "../img/general/editReq.svg";
import allowArr from "../img/general/allowArr.svg";
import greyPower from "../img/general/greyPower.svg";
import rotaTag from "../img/general/rotaTag.svg";

const ViewTeamUserModal = ({ teamID }) => {
  const { mobModal } = useContext(StyleContext);
  const { checkUserState } = useContext(UserContext);

  const {
    setShowViewTeamModal,
    showViewUserTeamModal,
    setShowViewUserTeamModal,
    setSeeUserID,
    seeUserID,
    showEditUser,
    setShowEditUser,
    modalOpen,
    device,
    setModalOpen,
  } = useContext(DataContext);

  const memoVals = useMemo(
    () => ({
      setShowViewTeamModal, //
      showViewUserTeamModal, //
      setShowViewUserTeamModal, //
      setSeeUserID, //
      seeUserID, //
      showEditUser, //
      setShowEditUser, //
      checkUserState, //
      mobModal, //
      modalOpen,
      setModalOpen,
      device,
    }),
    [
      setShowViewTeamModal, //
      showViewUserTeamModal, //
      setShowViewUserTeamModal, //
      setSeeUserID, //
      seeUserID, //
      showEditUser, //
      setShowEditUser, //
      checkUserState, //
      mobModal, //
      modalOpen,
      setModalOpen,
      device,
    ]
  );

  useEffect(() => {
    memoVals.setModalOpen((x) => true);

    return () => {
      memoVals.setModalOpen((x) => false);
    };
  }, [memoVals.setModalOpen]);

  let [imagesLoaded, setImagesLoaded] = useState(0);
  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if (dataLoaded && imagesLoaded === 10) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);

  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={navyClose}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={rotaTag}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={greyCross}
        alt="2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={tagAdd}
        alt="3"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={navyTick}
        alt="4"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={tagsGo}
        alt="5"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whitePlus}
        alt="6"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whitePencil}
        alt="7"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={allowArr}
        alt="8"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={greyPower}
        alt="9"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  let [isMgr, setIsMgr] = useState(false);

  let [users, setUsers] = useState([]);
  let [mgrs, setMgrs] = useState([]);
  let [teamName, setTeamName] = useState("");
  let [addTag, setAddTag] = useState(false);
  let [newTagValue, setNewTagValue] = useState("");
  let [newlyAddedTags, setNewlyAddedTags] = useState([]);

  let enterFct = (e) => {
    if (e.key === "Enter") {
      if (newTagValue.length > 0) {
        axios
          .post(
            `${serverURL}/add-tag-to-bank`,
            {
              teamID,
              tag: newTagValue,
            },

            {
              withCredentials: true,
              credentials: "include",
            }
          )
          .then((response) => {
            if (response.data.message === "success") {
              let arr = [...tags, newTagValue];
              arr.sort((a, b) => {
                return a.localeCompare(b);
              });
              setNewlyAddedTags([...newlyAddedTags, newTagValue]);
              setTags(arr);
              setAddTag(false);
              setNewTagValue("");
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  };

  let [mgrsCanEditTeams, setMgrsCanEditTeams] = useState(false);
  let [hideAmendBtn, setHideAmendBtn] = useState(false);
  let [tags, setTags] = useState([]);
  let [showTags, setShowTags] = useState(false);
  let [showSwapSettings, setShowSwapSettings] = useState(false);
  let addTagInputRef = useRef();
  let scrollBox = useRef();
  useEffect(() => {
    // clean not needed
    if (addTagInputRef.current) {
      addTagInputRef.current.focus();
    }
  }, [addTag]);

  useEffect(() => {
    // clean not needed
    if (scrollBox.current) {
      scrollBox.current.scrollTop = 0;
    }
  }, [showTags]);

  let [isPrimary, setIsPrimary] = useState(false);
  let [autoApprove, setAutoApprove] = useState(false);
  let [allowSwaps, setAllowSwaps] = useState(false);
  let [teamlessAdmins, setTeamlessAdmins] = useState([]);
  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    if (memoVals.showViewUserTeamModal) {
      axios
        .post(
          `${serverURL}/view-team`,
          { teamID: teamID },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            setMgrsCanEditTeams(response.data.mgrsCanEditTeams);
            setIsMgr(response.data.isMgr);
            setIsPrimary(response.data.myPrimaryTeam);
            setUsers(
              response.data.users.filter((x) => {
                return x.usrOfThisTeam;
              }) || []
            );
            setMgrs(
              response.data.users.filter((x) => {
                return x.mgrOfThisTeam;
              }) || []
            );
            setTeamName(response.data.teamName || "");
            setTags(response.data.tags || []);
            setAllowSwaps(response.data.allowSwaps);
            setAutoApprove(response.data.autoApprove);
            setTeamlessAdmins(response.data.teamlessAdmins);
            setDataLoaded(true);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, []);

  let closeModal = () => {
    memoVals.setShowViewUserTeamModal((x) => "");
  };

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 30) {
      closeModal();
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  // master return
  return (
    <div
      className={`clockOnModalUnderlay ${
        memoVals.seeUserID.userID || memoVals.showEditUser ? "invis" : ""
      }`}
      onClick={() => {
        closeModal();
      }}
    >
      {loading ? (
        <div
          className={`shiftLoadingBox ${
            memoVals.mobModal ? "shiftLoadingBoxMob" : "shiftLoadingBoxDesktop"
          }`}
        >
          <img
            src={horizontalBalls}
            alt="Loading"
            className={`shiftLoadingBallsImg ${
              memoVals.mobModal ? "shiftLoadingBallsImgMob" : "zoomIn"
            }`}
          />
        </div>
      ) : (
        <div
          className={`userReqInfoModalBox ${
            window.innerWidth < 500 ? "mobReqInfoModalBox slideUp" : "zoomIn"
          } ${memoVals.mobModal ? `mobModalShoulder mobModalTeamModal` : ""} ${
            loading ? "invis" : ""
          }  ${memoVals.device.ios ? "paddingBottom35" : ""}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={modalPosition}
          ref={modalRef}
        >
          {memoVals.mobModal && (
            <div
              className="modalSwiper modalSwiperViewTeamUser"
              onTouchStart={handleDown}
              onTouchMove={memoVals.mobModal ? handleMove : null}
              onTouchEnd={memoVals.mobModal ? handleUp : null}
              onMouseDown={memoVals.mobModal ? handleDown : null}
              onMouseMove={memoVals.mobModal ? handleMove : null}
              onMouseUp={memoVals.mobModal ? handleUp : null}
            ></div>
          )}
          <div
            className="reqInfoHeader"
            onTouchStart={handleDown}
            onTouchMove={memoVals.mobModal ? handleMove : null}
            onTouchEnd={memoVals.mobModal ? handleUp : null}
            onMouseDown={memoVals.mobModal ? handleDown : null}
            onMouseMove={memoVals.mobModal ? handleMove : null}
            onMouseUp={memoVals.mobModal ? handleUp : null}
          >
            <img
              src={window.innerWidth < 500 ? navyClose : navyClose}
              className="closeReqInfoModalImg"
              alt="Close"
              onClick={() => {
                closeModal();
              }}
            />
            <div
              className={`adminTeamNameTitle x13812214 ${
                teamName.length > 20
                  ? "teamNameOver20"
                  : teamName.length > 26
                  ? "teamNameOver26"
                  : ""
              }`}
            >
              <p>{dateStringer.shorten(teamName, 29)}</p>
              {isPrimary ? <p className="isPrimSpan">Your primary team</p> : ""}
            </div>

            {/* <div className="closeReqInfoModalImg"></div> */}
          </div>
          <div className="userReqBody">
            {showSwapSettings ? (
              <div
                className={`teamAdminLeft ${
                  window.innerWidth < 500 ? "teamAdminLeftMob" : ""
                }`}
                ref={scrollBox}
              >
                <div className="adminTeamTitleBar">
                  <p
                    className="tagsGoBack"
                    onClick={() => {
                      setShowSwapSettings(false);
                    }}
                  >
                    <img src={allowArr} alt="Back" className="tagsBackImg" />{" "}
                    Back
                  </p>
                  <p className="adminTeamRowTitle x234895372">Swap settings</p>
                </div>

                {/* <p className="teamTagsInfoTxt">
                Amend the shift swap settings for {teamName}
              </p> */}

                <div
                  className={`teamSwapRow teamSwapRowTop ${
                    !memoVals.mobModal ? "teamSwapRowDesktop" : ""
                  }`}
                  onClick={() => {
                    axios
                      .post(
                        `${serverURL}/amend-team-swap-settings`,
                        {
                          teamID,
                          allowSwaps: !allowSwaps,
                          autoApprove,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          setAllowSwaps(response.data.doc.allowSwaps);
                        }
                      });
                    setAllowSwaps(!allowSwaps);
                  }}
                >
                  <p className="teamSwapRowTitle">Allow shift swaps</p>
                  {/*  KNOB BEGIN */}
                  <div className="editUserReqClockOutKnobContainer adminTeamFilterKnob">
                    <div className="knobContainer knobContainerEditUser">
                      <div
                        className={`switchKnob ${
                          allowSwaps ? "knobOn" : "knobOff"
                        }`}
                      ></div>
                      <div className="knobBg"></div>
                    </div>
                  </div>
                  {/*  KNOB END */}
                </div>

                <div
                  className={`teamSwapRow ${
                    !memoVals.mobModal ? "teamSwapRowDesktop" : ""
                  }`}
                  onClick={() => {
                    axios
                      .post(
                        `${serverURL}/amend-team-swap-settings`,
                        {
                          teamID,
                          allowSwaps,
                          autoApprove: !autoApprove,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          setAutoApprove(response.data.doc.autoApprove);
                        }
                      });
                  }}
                >
                  <p className="teamSwapRowTitle">Require manager approval</p>
                  {/*  KNOB BEGIN */}
                  <div className="editUserReqClockOutKnobContainer adminTeamFilterKnob">
                    <div className="knobContainer knobContainerEditUser">
                      <div
                        className={`switchKnob ${
                          !autoApprove ? "knobOn" : "knobOff"
                        }`}
                      ></div>
                      <div className="knobBg"></div>
                    </div>
                  </div>
                  {/*  KNOB END */}
                </div>
              </div>
            ) : showTags ? (
              <div
                className={`teamAdminLeft ${
                  window.innerWidth < 500 ? "teamAdminLeftMob" : ""
                }`}
                ref={scrollBox}
              >
                <div className="adminTeamTitleBar">
                  <p
                    className="tagsGoBack"
                    onClick={() => {
                      setShowTags(false);
                      setShowSwapSettings(false);
                    }}
                  >
                    <img src={allowArr} alt="Back" className="tagsBackImg" />{" "}
                    Back
                  </p>
                  <p className="adminTeamRowTitle x234895372">Team tags</p>
                </div>

                <p className="teamTagsInfoTxt">
                  Tags can be assigned to shifts to represent duties /
                  locations.
                </p>
                <div className="adminTeamUsrRow">
                  {tags[0] ? (
                    tags.map((tag) => {
                      return (
                        <div
                          className={`teamTagIemX ${
                            newlyAddedTags.includes(tag) ? "x1841984_" : ""
                          }`}
                        >
                          {tag}{" "}
                          {isMgr ? (
                            <img
                              src={greyCross}
                              className="removeTeamTagImgX"
                              alt="Remove tag"
                              onClick={() => {
                                axios
                                  .post(
                                    `${serverURL}/exclude-tag-from-bank`,
                                    { teamID: teamID, tag },

                                    {
                                      withCredentials: true,
                                      credentials: "include",
                                    }
                                  )
                                  .then((response) => {
                                    if (response.data.message === "success") {
                                      setTags(
                                        tags.filter((x) => {
                                          return x !== tag;
                                        })
                                      );
                                    }
                                  })
                                  .catch((err) => {
                                    console.error(err);
                                  });
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <p className="noUsersTeamTxt">This team has no tags</p>
                  )}
                  {addTag ? (
                    <div
                      className="addTagUnit"
                      onClick={() => {
                        setNewTagValue("");
                      }}
                    >
                      <input
                        className="tagInput"
                        placeholder="New tag"
                        ref={addTagInputRef}
                        onKeyPress={(e) => {
                          enterFct(e);
                        }}
                        type="text"
                        // defaultValue={newTagValue}
                        value={newTagValue}
                        onChange={(e) => {
                          // if (newTagValue.length < 18) {
                          setNewTagValue(e.target.value);
                          // }
                        }}
                      ></input>
                      <img
                        className="addTagIcon"
                        src={tagAdd}
                        alt="Add tag"
                        onClick={() => {
                          if (newTagValue.length > 0) {
                            axios
                              .post(
                                `${serverURL}/add-tag-to-bank`,
                                {
                                  teamID,
                                  tag: newTagValue,
                                },

                                {
                                  withCredentials: true,
                                  credentials: "include",
                                }
                              )
                              .then((response) => {
                                if (response.data.message === "success") {
                                  let arr = [...tags, newTagValue];
                                  arr.sort((a, b) => {
                                    return a.localeCompare(b);
                                  });
                                  setNewlyAddedTags([
                                    ...newlyAddedTags,
                                    newTagValue,
                                  ]);

                                  setTags(arr);
                                  setAddTag(false);
                                  setNewTagValue("");
                                }
                              })
                              .catch((err) => {
                                console.error(err);
                              });
                          }
                        }}
                      />
                    </div>
                  ) : (
                    <p
                      className="addTagToTeamBtn"
                      onClick={() => {
                        setAddTag(true);
                      }}
                    >
                      Add new tag{" "}
                      <img
                        src={whitePlus}
                        alt="Add tag"
                        className="addTagInlineImgTeam"
                      />
                    </p>
                  )}
                </div>
              </div>
            ) : (
              <div
                className={`teamAdminLeft ${
                  window.innerWidth < 500 ? "teamAdminLeftMob" : ""
                }`}
              >
                <div className="adminTeamTitleBar">
                  <p className="adminTeamRowTitle">Managers</p>
                </div>

                <div className="adminTeamUsrRow">
                  {mgrs[0] ? (
                    mgrs
                      .sort((a, b) => {
                        return a.fName.localeCompare(b.fName);
                      })
                      .map((mgr) => {
                        return (
                          <div
                            className={`teamAdminUsrItem ${
                              !memoVals.mobModal ? "teamSwapRowDesktop" : ""
                            }`}
                            onClick={() => {
                              //   if (isMgr) {
                              if (isMgr) {
                                memoVals.setShowEditUser(mgr.userID);
                              } else {
                                memoVals.setSeeUserID((x) => {
                                  return {
                                    userID: mgr.userID,
                                    openedFromMyAAccount: true,
                                  };
                                });
                              }
                              //   }
                            }}
                          >
                            <div className="teamAdminItemLeft">
                              <p className="adminTeamMgrTxt x13981971">
                                {mgr.profPicUrl ? (
                                  <img
                                    src={mgr.profPicUrl}
                                    alt={mgr.fName}
                                    className="viewTeamUserProfPic"
                                  />
                                ) : (
                                  <p className="proPicPlaceholderViewTeam">
                                    {mgr.fName[0]}
                                    {mgr.lName[0]}
                                  </p>
                                )}
                                {mgr.fName}{" "}
                                {mgr.fName.length + mgr.lName.length > 18
                                  ? mgr.lName[0]
                                  : mgr.lName}
                              </p>
                              <p className="viewTeamUsrJt2">{mgr.jobTitle}</p>
                            </div>
                          </div>
                        );
                      })
                  ) : (
                    <p className="noUsersTeamTxt">This team has no managers</p>
                  )}
                  {teamlessAdmins[0] ? (
                    <p className="teamlessSecTitle">
                      FlowRota admins who can manage teamless staff:
                    </p>
                  ) : (
                    ""
                  )}
                  {teamlessAdmins[0] ? (
                    <div className="teamlessTeamMgrsBox">
                      {teamlessAdmins.map((usr) => {
                        return (
                          <p className="teamlessAdminMgrItem">
                            {usr.fName} {usr.lName}
                          </p>
                        );
                      })}
                      {teamlessAdmins.map((usr) => {
                        return (
                          <p className="teamlessAdminMgrItem">
                            {usr.fName} {usr.lName}
                          </p>
                        );
                      })}
                      {teamlessAdmins.map((usr) => {
                        return (
                          <p className="teamlessAdminMgrItem">
                            {usr.fName} {usr.lName}
                          </p>
                        );
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="adminTeamTitleBar">
                  <p className="adminTeamRowTitle">Members</p>
                </div>

                <div className="adminTeamUsrRow">
                  {users[0] ? (
                    users
                      .sort((a, b) => {
                        return a.fName.localeCompare(b.fName);
                      })
                      .map((usr) => {
                        return (
                          <div
                            className={`teamAdminUsrItem ${
                              !memoVals.mobModal ? "teamSwapRowDesktop" : ""
                            }`}
                            onClick={() => {
                              if (isMgr) {
                                memoVals.setShowEditUser(usr.userID);
                              } else {
                                setSeeUserID((x) => {
                                  return {
                                    userID: usr.userID,
                                    openedFromMyAAccount: true,
                                  };
                                });
                              }
                            }}
                          >
                            {" "}
                            <div className="teamAdminItemLeft">
                              <p className="adminTeamUsrTxt x13981971">
                                {usr.profPicUrl ? (
                                  <img
                                    src={usr.profPicUrl}
                                    alt={usr.fName}
                                    className="viewTeamUserProfPic"
                                  />
                                ) : (
                                  <p className="proPicPlaceholderViewTeam">
                                    {usr.fName[0]}
                                    {usr.lName[0]}
                                  </p>
                                )}
                                {usr.fName}{" "}
                                {usr.fName.length + usr.lName.length > 18
                                  ? usr.lName[0]
                                  : usr.lName}
                              </p>
                              <p className="viewTeamUsrJt2">{usr.jobTitle}</p>
                            </div>
                          </div>
                        );
                      })
                  ) : (
                    <p className="noUsersTeamTxt">This team has no members</p>
                  )}
                </div>
              </div>
            )}
            <div className="staffReqModalContentRight">
              <div className="adminTeamStat adminTeamStatMgrs">
                <p className="adminTeamStatNum">{mgrs.length}</p>
                <p className="adminTeamStatVal">managers</p>
              </div>
              <div className="adminTeamStat">
                <p className="adminTeamStatNum">{users.length}</p>
                <p className="adminTeamStatVal">members</p>
              </div>
              {isMgr && (
                <p
                  className={`teamSwapSettings ${
                    teamlessAdmins[0] ? "none" : ""
                  } ${showSwapSettings ? "navyBorder" : ""}`}
                  onClick={() => {
                    setShowSwapSettings(!showSwapSettings);
                    if (showTags) {
                      setShowTags(false);
                    }
                  }}
                >
                  <p className="swapSettTxt">Swaps</p>{" "}
                  <img
                    src={allowSwaps ? navyTick : greyPower}
                    alt="Swap settings"
                    className={`swapSettingsBtn ${
                      !allowSwaps ? "x139812" : ""
                    }`}
                  />
                </p>
              )}{" "}
              {isMgr && !teamlessAdmins[0] ? (
                <p
                  className={`teamSwapSettings ${showTags ? "navyBorder" : ""}`}
                  onClick={() => {
                    setShowTags(!showTags);
                    setShowSwapSettings(false);
                    if (showSwapSettings) {
                      setShowSwapSettings(false);
                    }
                  }}
                >
                  <p className="swapSettTxt">Tags</p>{" "}
                  <img
                    src={rotaTag}
                    alt="Swap settings"
                    className={`swapSettingsBtn swapSettingsBtnTag`}
                  />
                </p>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="adminTeamFooter">
            {memoVals.checkUserState.permissions === 2 ? (
              <p className="askAdminToManageTeamViewTeam">
                Only admins can amend teams
              </p>
            ) : mgrsCanEditTeams &&
              isMgr &&
              !hideAmendBtn &&
              !teamlessAdmins[0] ? (
              <div
                className="viewMapsAndSaveDiv"
                onClick={() => {
                  setShowViewTeamModal((x) => {
                    return { teamID, loadedFromMyAcc: true };
                  });
                  setHideAmendBtn(true);
                  closeModal();
                }}
              >
                <a
                  // href={`https://www.google.com/maps/search/?api=1&query=`}
                  className="viewGpsZoneMapBtn x1344222"
                  target="_blank"
                >
                  Add / remove staff
                  <img
                    src={whitePencil}
                    alt="View map"
                    className="editTeamPenIcon"
                  />
                </a>
              </div>
            ) : (
              <p></p>
            )}

            <p
              className="closeSeeUserIDBtn"
              onClick={() => {
                closeModal();
              }}
            >
              Close
            </p>
          </div>
        </div>
      )}
      {imgPreload}
    </div>
  );
};

export default ViewTeamUserModal;

import React, {
  useState,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useMemo,
  Fragment,
} from "react";
// cleaned wef 24 jul 23
// params cleared 10 sep 23
// preloaded images 19 sep 23
// axios caught 21 sep 23
// streamlined the rota from the old version in March 2024. Class names with stream in refer to the new rota. Old rota style is removed.

import axios from "axios";
import { RequestContext } from "../../contexts/RequestContext";
import { CalendarContext } from "../../contexts/CalendarContext";
import { UserContext } from "../../contexts/UserContext";
import { StyleContext } from "../../contexts/StyleContext";
import { DataContext } from "../../contexts/DataContext";
import { NavLink } from "react-router-dom";

import dateStringer from "../../tools/dateStringer";
import serverURL from "../../serverURL";
import appURL from "../../appURL";

import TopBar from "../TopBar";
import horizontalBalls from "../../img/loaders/horizontalBalls.svg";
import whiteAdd from "../../img/general/whiteAdd.svg";

import swapInline from "../../img/general/swapInline2.svg";
import filter from "../../img/general/filter.svg";
import absIcon from "../../img/general/absIcon.svg";
// import doubleTickExample from "../../img/general/doubleTickExample.png";
import streamCross from "../../img/general/streamCross.svg";

import filterActive from "../../img/general/filterActive.svg";
import filterClose from "../../img/general/filterClose.svg";
import navyTri from "../../img/general/navyTri.svg";
import unclockedCheck from "../../img/general/checkboxInstall3.svg";
import whitecross from "../../img/general/whitecross.svg";
import arrGrey from "../../img/general/arrGrey.svg";
import whiteDown from "../../img/general/whiteDown.svg";
import thickCross from "../../img/general/thickCross.svg";
import downArrNavy from "../../img/general/downArrNavy.svg";
import rotaView1 from "../../img/general/rotaView1.svg";
import rotaView2 from "../../img/general/rotaView2.svg";
import rotaPlane from "../../img/general/rotaPlane.svg";
import whiteBurger from "../../img/general/whiteBurger.svg";
import greyCross from "../../img/general/greyCross.svg";
import nightshift from "../../img/general/nightshift.svg";
import darkRedCross from "../../img/general/darkRedCross.svg";

import lightning from "../../img/general/lightning.svg";
import close from "../../img/general/navyClose.svg";
import currWeek from "../../img/general/currWeek2.svg";
import shiftDelete from "../../img/general/shiftDelete 2.svg";
import shiftDelete2 from "../../img/general/shiftDelete22.svg";
import cancelShiftMove2 from "../../img/general/cancelShiftMove2.svg";
import whiteGo from "../../img/general/whiteGo.svg";
import popup2 from "../../img/general/popup2.svg";
import greyBin from "../../img/general/greyBin.svg";
import pin from "../../img/general/pin.svg";

import statsWhite from "../../img/general/statsWhite.svg";
import rotaClock from "../../img/general/rotaClock.svg";
import exclaimWhite from "../../img/general/exclaimWhite.svg";
import navyClose from "../../img/general/navyClose.svg";
import shareGrey from "../../img/general/shareGrey.svg";
import doubleTick from "../../img/general/doubleTick.svg";

import rotaClockWhite from "../../img/general/rotaClockWhite.svg";
import rotaCoffee from "../../img/general/rotaCoffee.svg";
import whitePower from "../../img/general/whitePower.svg";
import toolsCopy from "../../img/general/toolsCopy.svg";
import toolsLimit from "../../img/general/toolsLimit.svg";
import toolsCog from "../../img/general/toolsCog.svg";
import toolsPublish from "../../img/general/toolsPublish.svg";
import limitActive from "../../img/general/limitActive.svg";
import schedLimitPlus from "../../img/general/schedLimitPlus.svg";
import schedLimitMinus from "../../img/general/schedLimitMinus.svg";
import profilePicturePlaceholder from "../../img/general/profilePicturePlaceholder.svg";

import schedLimitPowerOff from "../../img/general/schedLimitPowerOff.svg";
import limitSettings from "../../img/general/whiteCog.svg";
import shiftMove from "../../img/general/shiftMove3.svg";
import expand from "../../img/general/expand3.svg";
import shiftDuplicate from "../../img/general/duplicateShift3.svg";
import moveShiftHere from "../../img/general/moveShiftHere.svg";
import duplicateShiftHere from "../../img/general/duplicateShiftHere.svg";
import blueLoader from "../../img/general/greyLoader.gif";
import unitTick from "../../img/general/unitTick.svg";
import unitCross from "../../img/general/unitCross.svg";
import whiteTick from "../../img/general/whiteTick.svg";

import partialAbsentTick from "../../img/general/partialAbsent.svg";
import hidden from "../../img/general/hidden.svg";
import comment from "../../img/general/comment.svg";
import commentRota from "../../img/general/comment2.svg";
import emailWhite from "../../img/general/emailWhite.svg";
import whatsappWhite from "../../img/general/whatsappWhite.svg";
import lightBlueQuestionMark from "../../img/general/lightBlueQuestionMark.svg";

import "../../styles/calendar.css";

import AddShiftModal2 from "../account/admin/subcomponents/AddShiftModal2";

// import BulkEditLimitsModal from "./BulkEditLimitsModal";
import SaveTemplateModal from "./SaveTemplateModal";

let hoverButtonsAtScreenWidth = 820;

const TeamSchedule = () => {
  const { currencySymbol, checkUserState, setCheckUserState } =
    useContext(UserContext);
  const { setUpdateReq } = useContext(RequestContext);
  const { mob, width, mobModal } = useContext(StyleContext);
  useEffect(() => {
    const handlePopstate = () => {
      console.log("BACK BUTTON PRESS * * *");
      window.history.pushState(null, document.title, window.location.href);
    };

    // Add the event listener for "popstate" event
    window.history.pushState(null, document.title, window.location.href);
    if (!mob) {
      window.addEventListener("popstate", handlePopstate);
    } else {
      window.removeEventListener("popstate", handlePopstate);
    }

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [mob]);
  const {
    setShiftsPage,
    setCalPage,
    updatedItem,
    setUserShiftsPage,
    updateInfo,
    setUpdateInfo,
    setShowOpenShiftModal,
    copyWeek,
    setCopyWeek,
    bulkEditLimitObj,
    setBulkEditLimitObj,
    updateLimits,
    setUpdateLimits,
  } = useContext(CalendarContext);
  const {
    setAddShiftPage,
    setAddShiftOrigin,
    setShowAddShiftTeamSched,
    showAddShiftTeamSched,
    setAddTilUserID,
    setAddTilSelf,
    setAddTilTeam,
    showAddTilModalTeamSched,
    setShowAddTilModalTeamSched,
    setAddTilPage,
    setAddTilUserKnown,
    tilOrOvertime,
    setTilOrOvertime,
    showEditShiftModal,
    setShowEditShiftModal,
    updateShifts,
    setUpdateShifts,
    update2,
    setIndicate,
    updateOpen,
    addShiftTilPassBackToTeamSched,
    setAddShiftTilPassBackToTeamSched,
    setShowEditUser,
    showEditUser,
    updateSched,
    setUpdateSched,
    dontAddNewItemToRota,
    loadSingleUserSched,
    setLoadSingleUserSched,
    changedMidnightTimestamp,
    setShowReqMgrModal,
    setShowMgrAvailModal,
    device,
    setSeeUserID,
    setShowTeamSwapsModal,
    setShowDayCommentModal,
    amendDayComment,
    dynamicSwap,
    setShiftIDNoLongerExists,
    shiftIDNoLongerExists,
    teamSwapsQty,
    setShowAddLeaveModal,
    openShiftConvert,
    setOpenShiftConvert,
    addTagToAllUserShifts,
    setAddtagToAllUserShifts,
    showMessages,
    showNotifications,
    showMobMenu,
    setPage,
    setSelectedDesktop,
    setShowMgrAcceptanceModal,
    updatedHighlightedComment,
    setShowConversionModal,
    showAddLeaveOnTeam,
    setShowAddLeaveOnTeam,
    setShowMyShiftModal,
    setModalOpen,
    mobRotaMenuOpen,
    setMobRotaMenuOpen,
    setShowAddUserModal,
    openShiftEdited,
  } = useContext(DataContext);

  const memoVals = useMemo(
    () => ({
      currencySymbol, //
      checkUserState, //
      setCheckUserState, //
      setUpdateReq, //
      mob, //
      width, //
      mobModal, //
      setShiftsPage, //
      setCalPage, //
      updatedItem, //
      setUserShiftsPage, //
      updateInfo, //
      setUpdateInfo,
      setShowOpenShiftModal, //
      copyWeek, //
      setCopyWeek, //
      setBulkEditLimitObj, //
      updateLimits, //
      setUpdateLimits, //
      setAddShiftPage, //
      setAddShiftOrigin, //
      setShowAddShiftTeamSched, //
      showAddShiftTeamSched, //
      setAddTilUserID, //
      setAddTilSelf, //
      setAddTilTeam, //
      showAddTilModalTeamSched, //
      setShowAddTilModalTeamSched, //
      setAddTilPage, //
      setAddTilUserKnown, //
      tilOrOvertime, //
      setTilOrOvertime, //
      setShowEditShiftModal, //
      updateShifts, //
      setUpdateShifts, //
      update2, //
      setIndicate, //
      updateOpen, //
      addShiftTilPassBackToTeamSched, //
      setAddShiftTilPassBackToTeamSched, //
      setShowEditUser, //
      showEditUser, //
      updateSched, //
      setUpdateSched, //
      dontAddNewItemToRota, //
      loadSingleUserSched, //
      setLoadSingleUserSched, //
      changedMidnightTimestamp, //
      setShowReqMgrModal, //
      setShowMgrAvailModal, //
      device, //
      setSeeUserID, //
      setShowTeamSwapsModal, //
      setShowDayCommentModal, //
      amendDayComment, //
      dynamicSwap, //
      setShiftIDNoLongerExists, //
      shiftIDNoLongerExists, //
      teamSwapsQty, //
      setShowAddLeaveModal, //
      openShiftConvert, //
      setOpenShiftConvert, //
      addTagToAllUserShifts, //
      setAddtagToAllUserShifts, //
      showMessages, //
      showNotifications, //
      showMobMenu, //
      setPage, //
      setSelectedDesktop, //
      setShowMgrAcceptanceModal, //
      updatedHighlightedComment, //
      showAddLeaveOnTeam,
      setShowAddLeaveOnTeam,
      setShowMyShiftModal,
      setModalOpen,
      mobRotaMenuOpen,
      setMobRotaMenuOpen,
      setShowAddUserModal,
      openShiftEdited,
    }),
    [
      currencySymbol, //
      checkUserState, //
      setCheckUserState, //
      setUpdateReq, //
      mob, //
      width, //
      mobModal, //
      setShiftsPage, //
      setCalPage, //
      updatedItem, //
      setUserShiftsPage, //
      updateInfo, //
      setUpdateInfo,
      setShowOpenShiftModal, //
      copyWeek, //
      setCopyWeek, //
      setBulkEditLimitObj, //
      updateLimits, //
      setUpdateLimits, //
      setAddShiftPage, //
      setAddShiftOrigin, //
      setShowAddShiftTeamSched, //
      showAddShiftTeamSched, //
      setAddTilUserID, //
      setAddTilSelf, //
      setAddTilTeam, //
      showAddTilModalTeamSched, //
      setShowAddTilModalTeamSched, //
      setAddTilPage, //
      setAddTilUserKnown, //
      tilOrOvertime, //
      setTilOrOvertime, //
      setShowEditShiftModal, //
      updateShifts, //
      setUpdateShifts, //
      update2, //
      setIndicate, //
      updateOpen, //
      addShiftTilPassBackToTeamSched, //
      setAddShiftTilPassBackToTeamSched, //
      setShowEditUser, //
      showEditUser, //
      updateSched, //
      setUpdateSched, //
      dontAddNewItemToRota, //
      loadSingleUserSched, //
      setLoadSingleUserSched, //
      changedMidnightTimestamp, //
      setShowReqMgrModal, //
      setShowMgrAvailModal, //
      device, //
      setSeeUserID, //
      setShowTeamSwapsModal, //
      setShowDayCommentModal, //
      amendDayComment, //
      dynamicSwap, //
      setShiftIDNoLongerExists, //
      shiftIDNoLongerExists, //
      teamSwapsQty, //
      setShowAddLeaveModal, //
      openShiftConvert, //
      setOpenShiftConvert, //
      addTagToAllUserShifts, //
      setAddtagToAllUserShifts, //
      showMessages, //
      showNotifications, //
      showMobMenu, //
      setPage, //
      setSelectedDesktop, //
      setShowMgrAcceptanceModal, //
      updatedHighlightedComment, //
      showAddLeaveOnTeam,
      setShowAddLeaveOnTeam,
      setShowMyShiftModal,
      setModalOpen,
      mobRotaMenuOpen,
      setMobRotaMenuOpen,
      setShowAddUserModal,
      openShiftEdited,
    ]
  );

  let [streamShiftID, setStreamShiftID] = useState({ shiftID: "", type: "" });

  let todayDate = new Date();
  todayDate.setHours(0, 0, 0, 0);
  let yesterdayDate = new Date(todayDate.getTime());
  yesterdayDate.setDate(yesterdayDate.getDate() - 1);
  yesterdayDate.setHours(0, 0, 0, 0);
  let tomorrowDate = new Date(todayDate.getTime());
  tomorrowDate.setDate(tomorrowDate.getDate() + 1);
  tomorrowDate.setHours(0, 0, 0, 0);
  let [yesterdayDs, setYesterdayDs] = useState(
    dateStringer.createStringFromTimestamp(yesterdayDate.getTime())
  );

  let todayDateObj = new Date();
  todayDateObj.setHours(0, 0, 0, 0);
  let todayDs = dateStringer.createStringFromTimestamp(todayDateObj.getTime());

  let tonightDateObj = new Date();
  tonightDateObj.setHours(23, 59, 0, 0);

  let generateDayString = (ds) => {
    if (ds === dateStringer.createStringFromTimestamp(todayDate.getTime())) {
      return "Today";
    }
    if (
      ds === dateStringer.createStringFromTimestamp(yesterdayDate.getTime())
    ) {
      return "Yesterday";
    }
    if (ds === dateStringer.createStringFromTimestamp(tomorrowDate.getTime())) {
      return "Tomorrow";
    }
    return `${dateStringer.printedDateFromDs(ds)}`;
  };
  let [collapsedJts, setCollapsedJts] = useState([]);
  let [saveTemplateObj, setSaveTemplateObj] = useState({});

  let [showNoPrimLimits, setShowNoPrimLimits] = useState(false);
  let [showBookedOffExplain, setShowBookedOffExplain] = useState(false);
  let [showPubTools, setShowPubTools] = useState(false);
  let [showCopyTools, setShowCopyTools] = useState(false);
  let [showLimTools, setShowLimTools] = useState(false);
  let [teamIDDoesNotExist, setTeamIDDoesNotExist] = useState(false);
  let [showPubRemind, setShowPubRemind] = useState(false);

  let [showNonPrimUserLeaveModal, setShowNonPrimUserLeaveModal] =
    useState(false);

  let today00 = new Date();
  today00.setHours(0, 0, 0, 0);
  let yesterday00 = new Date(today00.getTime());
  yesterday00.setDate(yesterday00.getDate() - 1);
  yesterday00.setHours(0, 0, 0, 0);
  let [showSureGenModal, setShowSureGenModal] = useState(false);
  let [showViz, setShowViz] = useState(false);
  let [showViewOptions, setShowViewOptions] = useState(false);
  let shortenTag = (txt) => {
    if (txt.length > 15) {
      return `${txt.substr(0, 12)}...`;
    } else {
      return txt;
    }
  };
  function handleWindowSizeChange() {
    if (window.innerWidth >= 820) {
      setShowPubTools(true);
    } else {
      setShowPubTools(false);
    }

    if (window.innerWidth > 1015) {
      setShowCopyTools(true);
    } else {
      setShowCopyTools(false);
    }

    if (window.innerWidth > 1082) {
      setShowLimTools(true);
    } else {
      setShowLimTools(false);
    }
  }

  let [teamName, setTeamName] = useState("");
  let [filterTags, setFilterTags] = useState(false);
  let [filterTagsArr, setFilterTagsArr] = useState([]);

  let shortenTeamNameForMobOnly = (val) => {
    if (window.innerWidth > 910) {
      if (val.length > 100) {
        return `${val.substr(0, 90)}...`;
      } else {
        return val;
      }
    } else {
      if (val.length > 20) {
        return `${val.substr(0, 22)}...`;
      } else {
        return val;
      }
    }
  };

  let shortenTeamNameForTitle = (val) => {
    if (val.length > 14) {
      return `${val.substr(0, 11)}...`;
    } else {
      return val;
    }
  };

  useEffect(() => {
    // clean not needed
    handleWindowSizeChange();
    memoVals.setCheckUserState((x) => {
      return { hideMobSideBtns: true };
    });
  }, []);

  window.addEventListener("resize", handleWindowSizeChange);

  let [mgrTeams, setMgrTeams] = useState([]);
  let [monWeeks, setMonWeeks] = useState([]);
  let [selectedMonWeek, setSelectedMonWeek] = useState("");
  let [selectedTeam, setSelectedTeam] = useState("");
  let [highlightedComments, setHighlightedComments] = useState([]);

  let [
    tryingToOpenShiftUserManagesButNotAllowed,
    setTryingToOpenShiftUserManagesButNotAllowed,
  ] = useState(false);
  useEffect(() => {
    if (memoVals.updatedHighlightedComment) {
      let newArr = memoVals.updatedHighlightedComment.comment
        ? [
            {
              comment: memoVals.updatedHighlightedComment.comment,
              ds: memoVals.updatedHighlightedComment.ds,
            },
          ]
        : [];
      highlightedComments.forEach((c) => {
        if (c.ds !== memoVals.updatedHighlightedComment.ds) {
          newArr.push(c);
        }
      });

      setHighlightedComments(newArr);
    }
  }, [memoVals.updatedHighlightedComment]);
  useEffect(() => {
    if (collapsedJts[0]) {
      setCollapsedJts([]);
    }
  }, [selectedTeam, selectedMonWeek]);
  let [generateLoading, setGenerateLoading] = useState(false);
  let [publishLoading, setPublishLoading] = useState(false);

  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);
  let [imagesLoaded, setImagesLoaded] = useState(0);

  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={downArrNavy}
        alt="Swap Inline"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={darkRedCross}
        alt="Swap Inline"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={pin}
        alt="Swap Inline"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={whiteAdd}
        alt="Swap Inline"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={nightshift}
        alt="Swap Inline"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={whiteBurger}
        alt="Swap Inline"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={streamCross}
        alt="Swap Inline"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={profilePicturePlaceholder}
        alt="Swap Inline"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaView1}
        alt="Swap Inline"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaPlane}
        alt="Swap Inline"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaView2}
        alt="Swap Inline"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={absIcon}
        alt="Swap Inline"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={lightBlueQuestionMark}
        alt="Swap Inline"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={shareGrey}
        alt="Swap Inline"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={greyBin}
        alt="Swap Inline"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={whiteTick}
        alt="Swap Inline"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={swapInline}
        alt="Swap Inline"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={navyClose}
        alt="Swap Inline"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={filter}
        alt="Filter"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={filterActive}
        alt="Filter Active"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={filterClose}
        alt="Filter Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={navyTri}
        alt="Navy Triangle"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={unclockedCheck}
        alt="Unclocked Check"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whitecross}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={exclaimWhite}
        alt="Arrow Grey"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={arrGrey}
        alt="Arrow Grey"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whiteDown}
        alt="White Down"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={thickCross}
        alt="Thick Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      {/* 10 above */}
      <img
        src={lightning}
        alt="Lightning"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={close}
        alt="Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={currWeek}
        alt="Current Week"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={shiftDelete}
        alt="Shift Delete"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={shiftDelete2}
        alt="Shift Delete 2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={cancelShiftMove2}
        alt="Cancel Shift Move 2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whiteGo}
        alt="White Go"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={popup2}
        alt="Popup 2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={statsWhite}
        alt="Stats White"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={rotaClock}
        alt="Rota Clock"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      {/* 20 above */}
      {/* Previously added images */}
      {/* ... */}
      {/* Additional images */}
      <img
        src={rotaClockWhite}
        alt="Rota Clock White"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={rotaCoffee}
        alt="Rota Coffee"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={doubleTick}
        alt="Rota Coffee"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whitePower}
        alt="White Power"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={toolsCopy}
        alt="Tools Copy"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={toolsLimit}
        alt="Tools Limit"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={toolsCog}
        alt="Tools Cog"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={toolsPublish}
        alt="Tools Publish"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={limitActive}
        alt="Limit Active"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={schedLimitPlus}
        alt="Sched Limit Plus"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={schedLimitMinus}
        alt="Sched Limit Minus"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={schedLimitPowerOff}
        alt="Sched Limit Power Off"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={limitSettings}
        alt="Limit Settings"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={shiftMove}
        alt="Shift Move"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={expand}
        alt="Expand"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={shiftDuplicate}
        alt="Shift Duplicate"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={moveShiftHere}
        alt="Move Shift Here"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={duplicateShiftHere}
        alt="Duplicate Shift Here"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={blueLoader}
        alt="Blue Loader"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={unitTick}
        alt="Unit Tick"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={unitCross}
        alt="Unit Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      {/* 40 above */}
      <img
        src={partialAbsentTick}
        alt="Partial Absent Tick"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={hidden}
        alt="Hidden"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={comment}
        alt="Comment"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={commentRota}
        alt="Comment Rota"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  useEffect(() => {
    if (dataLoaded && imagesLoaded === 63) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);
  let [showShare, setShowShare] = useState(false);
  let [password, setPassword] = useState("");
  let [originalPassword, setOriginalPassword] = useState("");
  let [shareLink, setShareLink] = useState(false);
  let [shareEmail, setShareEmail] = useState(false);
  let [copied, setCopied] = useState(false);
  let [sureDestroy, setSureDestroy] = useState(false);
  let [passwordEdited, setPasswordEdited] = useState(false);
  let [lastDs, setLastDs] = useState("");
  let [locationMode, setLocationMode] = useState("");
  let [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      userIDsThatUserManages: [],
      isAdmin: false,
      firstLoaded: false,
      specDays: [],
      hoveredBoxForMove: "",
      hoveredShift: {},
      hideSchedLimits: true,
      availData: [],
      sched: [],
      sunCost: 0,
      sunOpens: [],
      monCost: 0,
      monOpens: [],
      tueCost: 0,
      tueOpens: [],
      wedCost: 0,
      wedOpens: [],
      thuCost: 0,
      thuOpens: [],
      friCost: 0,
      friOpens: [],
      satCost: 0,
      satOpens: [],
      secondaryUserIDs: [],
      daysArr: [],
      closedDayNums: [],
      jobTitles: [],
      loadingMove: "",
      singleDay: "",
      singleDay2: "",
      loading: true,
      limLoad: "",
      availLoading: false,
      pasteLoading: false,
      dayLimitData: [],
      jtLimitData: [],
      fadeShiftID: "",
      initialLimitsGot: false,
      loadingLimits: true,
      monCommentsQty: 0,
      tueCommentsQty: 0,
      wedCommentsQty: 0,
      thuCommentsQty: 0,
      friCommentsQty: 0,
      satCommentsQty: 0,
      sunCommentsQty: 0,
      // userTotals: [{ userID: "", totUsrMins: 0, totUsrCost: 0 }],
      maxTs: 0,
    }
  );

  let [tags, setTags] = useState([]);

  let [showLimits, setShowLimits] = useState(false);

  let [requireSeen, setRequireSeen] = useState(false);
  let [showAcceptModal, setShowAcceptModal] = useState(false);

  useEffect(() => {
    if (!tags[0]) {
      let arr = [];
      state.sched.forEach((usr) => {
        let items = [
          ...usr.monItems,
          ...usr.tueItems,
          ...usr.wedItems,
          ...usr.thuItems,
          ...usr.friItems,
          ...usr.satItems,
          ...usr.sunItems,
        ];
        items.forEach((item) => {
          if (item.tags && item.tags[0]) {
            item.tags.forEach((tag) => {
              if (!arr.includes(tag)) {
                arr.push(tag);
              }
            });
          }
        });
      });

      setTags(arr);
    }
  }, [state.sched]);

  let calcTotals = () => {
    let totCost = 0;
    let totMins = 0;

    let totShiftCost = 0;
    let totShiftMins = 0;

    let totOvertimeCost = 0;
    let totOvertimeMins = 0;

    let totLeaveMins = 0;

    let totAbsenceMins = 0;

    let totLateMins = 0;

    let totLeaveCost = 0;
    let totAbsenceCost = 0;

    let monCost = 0;
    let tueCost = 0;
    let wedCost = 0;
    let thuCost = 0;
    let friCost = 0;
    let satCost = 0;
    let sunCost = 0;

    let monMins = 0;
    let tueMins = 0;
    let wedMins = 0;
    let thuMins = 0;
    let friMins = 0;
    let satMins = 0;
    let sunMins = 0;

    state.sched.forEach((usr) => {
      let items = [
        usr.monItems,
        usr.tueItems,
        usr.wedItems,
        usr.thuItems,
        usr.friItems,
        usr.satItems,
        usr.sunItems,
      ];
      //

      items.forEach((arr, i) => {
        arr.forEach((item) => {
          if (item.type === "leave" && usr.primaryTeamID === selectedTeam) {
            if (item.isAbsence) {
              if (!item.unpaid) {
                totAbsenceCost += item.trueCost;
                totCost += item.trueCost || 0;
                if (i === 0) {
                  monCost += item.trueCost || 0;
                } else if (i === 1) {
                  tueCost += item.trueCost || 0;
                } else if (i === 2) {
                  wedCost += item.trueCost || 0;
                } else if (i === 3) {
                  thuCost += item.trueCost || 0;
                } else if (i === 4) {
                  friCost += item.trueCost || 0;
                } else if (i === 5) {
                  satCost += item.trueCost || 0;
                } else if (i === 6) {
                  sunCost += item.trueCost || 0;
                }

                if (i === 0) {
                  monMins += item.durMins || 0;
                } else if (i === 1) {
                  tueMins += item.durMins || 0;
                } else if (i === 2) {
                  wedMins += item.durMins || 0;
                } else if (i === 3) {
                  thuMins += item.durMins || 0;
                } else if (i === 4) {
                  friMins += item.durMins || 0;
                } else if (i === 5) {
                  satMins += item.durMins || 0;
                } else if (i === 6) {
                  sunMins += item.durMins || 0;
                }
              }
              totAbsenceMins += item.durMins;
            } else {
              if (!item.unpaid) {
                totLeaveCost += item.trueCost;
                totCost += item.trueCost || 0;
                if (i === 0) {
                  monCost += item.trueCost || 0;
                } else if (i === 1) {
                  tueCost += item.trueCost || 0;
                } else if (i === 2) {
                  wedCost += item.trueCost || 0;
                } else if (i === 3) {
                  thuCost += item.trueCost || 0;
                } else if (i === 4) {
                  friCost += item.trueCost || 0;
                } else if (i === 5) {
                  satCost += item.trueCost || 0;
                } else if (i === 6) {
                  sunCost += item.trueCost || 0;
                }
                totMins += item.durMins;

                if (i === 0) {
                  monMins += item.durMins || 0;
                } else if (i === 1) {
                  tueMins += item.durMins || 0;
                } else if (i === 2) {
                  wedMins += item.durMins || 0;
                } else if (i === 3) {
                  thuMins += item.durMins || 0;
                } else if (i === 4) {
                  friMins += item.durMins || 0;
                } else if (i === 5) {
                  satMins += item.durMins || 0;
                } else if (i === 6) {
                  sunMins += item.durMins || 0;
                }
              }
              totLeaveMins += item.durMins;
            }
          }

          if (item.type === "shift" || item.type === "overtime") {
            if (item.type === "shift") {
              totShiftCost += item.trueCost || 0;
              totCost += item.trueCost || 0;
              if (i === 0) {
                monCost += item.trueCost || 0;
              } else if (i === 1) {
                tueCost += item.trueCost || 0;
              } else if (i === 2) {
                wedCost += item.trueCost || 0;
              } else if (i === 3) {
                thuCost += item.trueCost || 0;
              } else if (i === 4) {
                friCost += item.trueCost || 0;
              } else if (i === 5) {
                satCost += item.trueCost || 0;
              } else if (i === 6) {
                sunCost += item.trueCost || 0;
              }

              if (i === 0) {
                monMins += item.durMins || 0;
              } else if (i === 1) {
                tueMins += item.durMins || 0;
              } else if (i === 2) {
                wedMins += item.durMins || 0;
              } else if (i === 3) {
                thuMins += item.durMins || 0;
              } else if (i === 4) {
                friMins += item.durMins || 0;
              } else if (i === 5) {
                satMins += item.durMins || 0;
              } else if (i === 6) {
                sunMins += item.durMins || 0;
              }

              totShiftMins += item.durMins;
              totMins += item.durMins;
            }

            if (item.type === "overtime") {
              totOvertimeCost += item.trueCost || 0;
              totCost += item.trueCost || 0;
              if (i === 0) {
                monCost += item.trueCost || 0;
              } else if (i === 1) {
                tueCost += item.trueCost || 0;
              } else if (i === 2) {
                wedCost += item.trueCost || 0;
              } else if (i === 3) {
                thuCost += item.trueCost || 0;
              } else if (i === 4) {
                friCost += item.trueCost || 0;
              } else if (i === 5) {
                satCost += item.trueCost || 0;
              } else if (i === 6) {
                sunCost += item.trueCost || 0;
              }

              if (i === 0) {
                monMins += item.durMins || 0;
              } else if (i === 1) {
                tueMins += item.durMins || 0;
              } else if (i === 2) {
                wedMins += item.durMins || 0;
              } else if (i === 3) {
                thuMins += item.durMins || 0;
              } else if (i === 4) {
                friMins += item.durMins || 0;
              } else if (i === 5) {
                satMins += item.durMins || 0;
              } else if (i === 6) {
                sunMins += item.durMins || 0;
              }

              totOvertimeMins += item.durMins;
              totMins += item.durMins;
            }

            if (item.absent) {
              if (item.partialAbsent) {
                totAbsenceMins += item.partialAbsentDuration / 1000 / 60;
              } else {
                totAbsenceMins += item.durMins;
              }
            }

            if (item.lateDurMins) {
              totLateMins += item.lateDurMins;
            }
          }
        });
      });

      //
    });
    return {
      totCost,
      totMins,
      totShiftCost,
      totShiftMins,
      totOvertimeCost,
      totOvertimeMins,
      totLeaveMins,
      totAbsenceMins,
      totAbsenceCost,
      totLeaveCost,
      totLateMins,
      monCost,
      tueCost,
      wedCost,
      thuCost,
      friCost,
      satCost,
      sunCost,
      monMins,
      tueMins,
      wedMins,
      thuMins,
      friMins,
      satMins,
      sunMins,
    };
  };

  let calcUserData = (userID) => {
    // returns object {payableMins, cost};

    let userFilt = state.sched.filter((x) => {
      return x.userID === userID;
    });

    if (userFilt[0]) {
      let items = [
        ...userFilt[0].monItems,
        ...userFilt[0].tueItems,
        ...userFilt[0].wedItems,
        ...userFilt[0].thuItems,
        ...userFilt[0].friItems,
        ...userFilt[0].satItems,
        ...userFilt[0].sunItems,
      ];

      let mins = 0;
      let cost = 0;
      let shifts = 0;
      items.forEach((item) => {
        if (
          item.type === "leave" &&
          userFilt[0].primaryTeamID === selectedTeam
        ) {
          if (!item.unpaid) {
            mins += item.durMins;
            cost += item.trueCost || 0;
          }
        }

        if (
          item.type === "shift" ||
          item.type === "overtime" ||
          item.type === "til"
        ) {
          shifts++;
          mins += item.durMins;
          cost += item.trueCost || 0;
        }
      });

      return { mins, cost, shifts };
    }
  };

  let enterFct = (e) => {
    if (e.key === "Enter" || e === "tick") {
      axios
        .post(
          `${serverURL}/update-rota-link-password`,
          {
            monWeek: selectedMonWeek,
            teamID: selectedTeam,
            data: password,
          },

          {
            withCredentials: true,
            credentials: "include",
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            setPassword(response.data.password);
            setOriginalPassword(response.data.password);
            setPasswordEdited(false);
            if (password) {
              memoVals.setIndicate((x) => {
                return {
                  show: true,
                  message: "Password saved",
                  colour: "green",
                  duration: 4000,
                };
              });
            } else {
              memoVals.setIndicate((x) => {
                return {
                  show: true,
                  message: "Password removed",
                  colour: "blue",
                  duration: 4000,
                };
              });
            }
          }
        });
    }
  };

  useEffect(() => {
    if (bulkEditLimitObj.disable) {
      setState({
        hideSchedLimits: true,
      });
    }
  }, [bulkEditLimitObj]);

  let [
    jtsThatArePrimaryTeamedWithThisTeam,
    setJtsThatArePrimaryTeamedWithThisTeam,
  ] = useState([]);
  let [viz, setViz] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      userCount: 0,
      rows: 0, // height (number of staff)
      totalMin: 0,
      totalMax: 0,
      columns: 0, // hour blocks (9am - 10pm  = 13 cols),

      monData: [],
      tueData: [],
      wedData: [],
      thuData: [],
      friData: [],
      satData: [],
      sunData: [],

      totCost: 0,

      costFromLeave: 0,

      costFromShifts: 0,
      costFromOvertime: 0,
    }
  );

  useEffect(() => {
    // clean not needed
    if (showViz) {
      let startTsArr = [];
      let endTsArr = [];

      state.sched.forEach((user) => {
        let days = [
          ...user.monItems,
          ...user.tueItems,
          ...user.wedItems,
          ...user.thuItems,
          ...user.friItems,
          ...user.satItems,
          ...user.sunItems,
        ];

        let filterNoLeave = days.filter((item) => {
          return item.type !== "leave";
        });
        filterNoLeave.forEach((item) => {
          let startDs = `Y2022M01D01H${item.start.substr(12, 5)}`;
          if (item.start === "Y2023M03D10H00M00") {
          }
          let endDs = `Y2022M01D01H${item.end.substr(12, 5)}`;

          startTsArr.push(dateStringer.createTimestampFromString(startDs));

          endTsArr.push(dateStringer.createTimestampFromString(endDs));
        });
      });

      startTsArr.sort((a, b) => {
        return a - b;
      });

      endTsArr.sort((a, b) => {
        return b - a;
      });

      let totalMin = startTsArr.length > 0 ? startTsArr[0] : 0;
      let totalMax = endTsArr.length > 0 ? endTsArr[0] : 0;

      if (totalMin) {
        let ds = dateStringer.createStringFromTimestamp(totalMin);
        if (ds.substr(15, 2) !== "00") {
          ds = `${ds.substr(0, 15)}00`;
        }
        totalMin = dateStringer.createTimestampFromString(ds);
      }

      if (totalMax) {
        let ds = dateStringer.createStringFromTimestamp(totalMax);
        if (ds.substr(15, 2) !== "00") {
          ds = `${ds.substr(0, 15)}00`;
          let dateObj = new Date(dateStringer.createTimestampFromString(ds));
          dateObj.setHours(dateObj.getHours() + 1);
          ds = dateStringer.createStringFromTimestamp(dateObj.getTime());
        }
        totalMax = dateStringer.createTimestampFromString(ds);
      }

      let numberOfHours = Math.round((totalMax - totalMin) / 1000 / 60 / 60);

      let thresholds = [];

      let initMin = new Date(totalMin);

      for (var i = 0; i < numberOfHours; i++) {
        let minTs = initMin.getTime();
        let maxTs = new Date(minTs);
        maxTs.setMinutes(59);
        maxTs = maxTs.getTime();
        thresholds.push({
          minTs,
          maxTs,
          minDs: dateStringer.createStringFromTimestamp(minTs),
          maxDs: dateStringer.createStringFromTimestamp(maxTs),
        });

        initMin.setHours(initMin.getHours() + 1);
      }

      let monItems = [];
      let tueItems = [];
      let wedItems = [];
      let thuItems = [];
      let friItems = [];
      let satItems = [];
      let sunItems = [];

      let costFromLeave = 0;
      let costFromShifts = 0;
      let costFromOvertime = 0;

      state.sched.forEach((usr) => {
        usr.monItems.forEach((item) => {
          if (item.payRoll) {
            // is shift or til
            // totCost += item.payRoll.costIncludingSalariedCalc;
            console.log(
              "NEW COST FROM SHIFTS: ",
              item.payRoll.costIncludingSalariedCalc
            );
            if (item.type === "shift") {
              costFromShifts += item.payRoll.costIncludingSalariedCalc;
            } else {
              costFromOvertime +=
                item.payRoll && item.type === "overtime"
                  ? item.payRoll.costIncludingSalariedCalc
                  : 0;
            }
          }
          if (item.costObj) {
            // is leave request
            // totCost += item.costObj.cost;
            costFromLeave += item.costObj.cost;
          }
          if (item.type !== "leave" && item.type !== "absent") {
            monItems.push(item);
          }
        });

        usr.tueItems.forEach((item) => {
          if (item.payRoll) {
            // is shift or til
            // totCost += item.payRoll.costIncludingSalariedCalc;
            if (item.type === "shift") {
              costFromShifts += item.payRoll.costIncludingSalariedCalc;
            } else {
              costFromOvertime +=
                item.payRoll && item.type === "overtime"
                  ? item.payRoll.costIncludingSalariedCalc
                  : 0;
            }
          }
          if (item.costObj) {
            // is leave request
            // totCost += item.costObj.cost;
            costFromLeave += item.costObj.cost;
          }
          if (item.type !== "leave" && item.type !== "absent") {
            tueItems.push(item);
          }
        });

        usr.wedItems.forEach((item) => {
          if (item.payRoll) {
            // is shift or til
            // totCost += item.payRoll.costIncludingSalariedCalc;
            if (item.type === "shift") {
              costFromShifts += item.payRoll.costIncludingSalariedCalc;
            } else {
              costFromOvertime +=
                item.payRoll && item.type === "overtime"
                  ? item.payRoll.costIncludingSalariedCalc
                  : 0;
            }
          }
          if (item.costObj) {
            // is leave request
            // totCost += item.costObj.cost;
            costFromLeave += item.costObj.cost;
            if (item.type === "shift") {
              costFromShifts += item.payRoll.costIncludingSalariedCalc;
            } else {
              costFromOvertime +=
                item.payRoll && item.type === "overtime"
                  ? item.payRoll.costIncludingSalariedCalc
                  : 0;
            }
          }
          if (item.type !== "leave" && item.type !== "absent") {
            wedItems.push(item);
          }
        });

        usr.thuItems.forEach((item) => {
          if (item.payRoll) {
            // is shift or til
            // totCost += item.payRoll.costIncludingSalariedCalc;
            if (item.type === "shift") {
              costFromShifts += item.payRoll.costIncludingSalariedCalc;
            } else {
              costFromOvertime +=
                item.payRoll && item.type === "overtime"
                  ? item.payRoll.costIncludingSalariedCalc
                  : 0;
            }
          }
          if (item.costObj) {
            // is leave request
            // totCost += item.costObj.cost;
            if (item.type === "shift") {
              costFromShifts += item.payRoll.costIncludingSalariedCalc;
            } else {
              costFromOvertime +=
                item.payRoll && item.type === "overtime"
                  ? item.payRoll.costIncludingSalariedCalc
                  : 0;
            }
          }
          if (item.type !== "leave" && item.type !== "absent") {
            thuItems.push(item);
          }
        });

        usr.friItems.forEach((item) => {
          if (item.payRoll) {
            // is shift or til
            // totCost += item.payRoll.costIncludingSalariedCalc;
            if (item.type === "shift") {
              costFromShifts += item.payRoll.costIncludingSalariedCalc;
            } else {
              costFromOvertime +=
                item.payRoll && item.type === "overtime"
                  ? item.payRoll.costIncludingSalariedCalc
                  : 0;
            }
          }
          if (item.costObj) {
            // is leave request
            // totCost += item.costObj.cost;
            costFromLeave += item.costObj.cost;
          }
          if (item.type !== "leave" && item.type !== "absent") {
            friItems.push(item);
          }
        });

        usr.satItems.forEach((item) => {
          if (item.payRoll) {
            // is shift or til
            // totCost += item.payRoll.costIncludingSalariedCalc;
            if (item.type === "shift") {
              costFromShifts += item.payRoll.costIncludingSalariedCalc;
            } else {
              costFromOvertime +=
                item.payRoll && item.type === "overtime"
                  ? item.payRoll.costIncludingSalariedCalc
                  : 0;
            }
          }
          if (item.costObj) {
            // is leave request
            // totCost += item.costObj.cost;
            if (item.type === "shift") {
              costFromShifts += item.payRoll.costIncludingSalariedCalc;
            } else {
              costFromOvertime += item.payRoll
                ? item.payRoll.costIncludingSalariedCalc
                : 0;
            }
          }
          if (item.type !== "leave" && item.type !== "absent") {
            satItems.push(item);
          }
        });

        usr.sunItems.forEach((item) => {
          if (item.payRoll) {
            // is shift or til
            // totCost += item.payRoll.costIncludingSalariedCalc;
            if (item.type === "shift") {
              costFromShifts += item.payRoll.costIncludingSalariedCalc;
            } else {
              costFromOvertime +=
                item.payRoll && item.type === "overtime"
                  ? item.payRoll.costIncludingSalariedCalc
                  : 0;
            }
          }
          if (item.costObj) {
            // is leave request
            // totCost += item.costObj.cost;
            costFromLeave += item.costObj.cost;
          }
          if (item.type !== "leave" && item.type !== "absent") {
            sunItems.push(item);
          }
        });
      });

      let monData = [];
      let tueData = [];
      let wedData = [];
      let thuData = [];
      let friData = [];
      let satData = [];
      let sunData = [];

      thresholds.forEach((thresh, i) => {
        let monThreshCount = [];
        let tueThreshCount = [];
        let wedThreshCount = [];
        let thuThreshCount = [];
        let friThreshCount = [];
        let satThreshCount = [];
        let sunThreshCount = [];

        monItems.forEach((item) => {
          if (item.type !== "leave") {
            let shiftStartObj = new Date(item.startTs);
            let shiftEndObj = new Date(
              dateStringer.createTimestampFromString(item.end)
            );
            shiftStartObj.setFullYear(2022);
            shiftStartObj.setMonth(1);
            shiftStartObj.setDate(1);
            shiftEndObj.setFullYear(2022);
            shiftEndObj.setMonth(1);
            shiftEndObj.setDate(1);

            let minTs = dateStringer.createTimestampFromString(
              `Y2022M01D01H${item.start.substr(12, 5)}`
            );
            let maxTs = dateStringer.createTimestampFromString(
              `Y2022M01D01H${item.end.substr(12, 5)}`
            );

            if (thresh.minTs >= minTs && thresh.maxTs <= maxTs) {
              monThreshCount.push(1);
            }
          }
        });
        monData.push({ i, qty: monThreshCount });

        tueItems.forEach((item) => {
          if (item.type !== "leave") {
            let minTs = dateStringer.createTimestampFromString(
              `Y2022M01D01H${item.start.substr(12, 5)}`
            );
            let maxTs = dateStringer.createTimestampFromString(
              `Y2022M01D01H${item.end.substr(12, 5)}`
            );

            if (thresh.minTs >= minTs && thresh.maxTs <= maxTs) {
              tueThreshCount.push(1);
            }
          }
        });
        tueData.push({ i, qty: tueThreshCount });

        wedItems.forEach((item) => {
          if (item.type !== "leave") {
            let minTs = dateStringer.createTimestampFromString(
              `Y2022M01D01H${item.start.substr(12, 5)}`
            );
            let maxTs = dateStringer.createTimestampFromString(
              `Y2022M01D01H${item.end.substr(12, 5)}`
            );

            if (thresh.minTs >= minTs && thresh.maxTs <= maxTs) {
              wedThreshCount.push(1);
            }
          }
        });
        wedData.push({ i, qty: wedThreshCount });

        thuItems.forEach((item) => {
          if (item.type !== "leave") {
            let minTs = dateStringer.createTimestampFromString(
              `Y2022M01D01H${item.start.substr(12, 5)}`
            );
            let maxTs = dateStringer.createTimestampFromString(
              `Y2022M01D01H${item.end.substr(12, 5)}`
            );

            if (thresh.minTs >= minTs && thresh.maxTs <= maxTs) {
              thuThreshCount.push(1);
            }
          }
        });
        thuData.push({ i, qty: thuThreshCount });

        friItems.forEach((item) => {
          if (item.type !== "leave") {
            let minTs = dateStringer.createTimestampFromString(
              `Y2022M01D01H${item.start.substr(12, 5)}`
            );
            let maxTs = dateStringer.createTimestampFromString(
              `Y2022M01D01H${item.end.substr(12, 5)}`
            );

            if (thresh.minTs >= minTs && thresh.maxTs <= maxTs) {
              friThreshCount.push(1);
            }
          }
        });
        friData.push({ i, qty: friThreshCount });

        satItems.forEach((item) => {
          if (item.type !== "leave") {
            let minTs = dateStringer.createTimestampFromString(
              `Y2022M01D01H${item.start.substr(12, 5)}`
            );
            let maxTs = dateStringer.createTimestampFromString(
              `Y2022M01D01H${item.end.substr(12, 5)}`
            );

            if (thresh.minTs >= minTs && thresh.maxTs <= maxTs) {
              satThreshCount.push(1);
            }
          }
        });
        satData.push({ i, qty: satThreshCount });

        sunItems.forEach((item) => {
          if (item.type !== "leave") {
            let minTs = dateStringer.createTimestampFromString(
              `Y2022M01D01H${item.start.substr(12, 5)}`
            );
            let maxTs = dateStringer.createTimestampFromString(
              `Y2022M01D01H${item.end.substr(12, 5)}`
            );

            if (thresh.minTs >= minTs && thresh.maxTs <= maxTs) {
              sunThreshCount.push(1);
            }
          }
        });
        sunData.push({ i, qty: sunThreshCount });
      });

      setViz({
        userCount: state.sched.length,
        rows: state.sched.length, // height
        totalMin,
        totalMax,
        totalMinDs: dateStringer.createStringFromTimestamp(totalMin),
        totalMaxDs: dateStringer.createStringFromTimestamp(totalMax),
        columns: numberOfHours,
        thresholds,
        monData,
        tueData,
        wedData,
        thuData,
        friData,
        satData,
        sunData,
        costFromLeave,
        costFromShifts,
        costFromOvertime,
      });
    }
  }, [state.sched, showViz]);

  let userHasNoItemsAtAll = (userID) => {
    //train
    let userFilt = state.sched.filter((item) => {
      return item.userID === userID;
    });

    if (userFilt[0]) {
      if (
        userFilt[0].nonWorkingDays &&
        userFilt[0].nonWorkingDays.length === 0
      ) {
        // todo: check for leave or absence
        let combi = [
          ...userFilt[0].monItems,
          ...userFilt[0].tueItems,
          ...userFilt[0].wedItems,
          ...userFilt[0].thuItems,
          ...userFilt[0].friItems,
          ...userFilt[0].satItems,
          ...userFilt[0].sunItems,
        ];

        let leaveAbsFilt = combi.filter((x) => {
          return x.type === "leave" || x.type === "absence";
        });

        return !leaveAbsFilt[0] ? true : false;
      }
    }
    // if (userID === "9f1bb4a1-b89f-4942-b2dd-2589c1086884") {
    //   return true;
    // }
  };

  useEffect(() => {
    // clean not needed

    if (memoVals.amendDayComment.ds) {
      let dateObj = new Date(
        dateStringer.createTimestampFromString(memoVals.amendDayComment.ds)
      );
      if (dateObj.getDay() === 0) {
        if (memoVals.amendDayComment.plusOne) {
          setState({ sunCommentsQty: state.sunCommentsQty + 1 });
        }
        if (memoVals.amendDayComment.minusOne) {
          setState({ sunCommentsQty: state.sunCommentsQty - 1 });
        }
      }

      if (dateObj.getDay() === 1) {
        if (memoVals.amendDayComment.plusOne) {
          setState({ monCommentsQty: state.monCommentsQty + 1 });
        }
        if (memoVals.amendDayComment.minusOne) {
          setState({ monCommentsQty: state.monCommentsQty - 1 });
        }
      }

      if (dateObj.getDay() === 2) {
        if (memoVals.amendDayComment.plusOne) {
          setState({ tueCommentsQty: state.tueCommentsQty + 1 });
        }
        if (memoVals.amendDayComment.minusOne) {
          setState({ tueCommentsQty: state.tueCommentsQty - 1 });
        }
      }

      if (dateObj.getDay() === 3) {
        if (memoVals.amendDayComment.plusOne) {
          setState({ wedCommentsQty: state.wedCommentsQty + 1 });
        }
        if (memoVals.amendDayComment.minusOne) {
          setState({ wedCommentsQty: state.wedCommentsQty - 1 });
        }
      }

      if (dateObj.getDay() === 4) {
        if (memoVals.amendDayComment.plusOne) {
          setState({ thuCommentsQty: state.thuCommentsQty + 1 });
        }
        if (memoVals.amendDayComment.minusOne) {
          setState({ thuCommentsQty: state.thuCommentsQty - 1 });
        }
      }

      if (dateObj.getDay() === 5) {
        if (memoVals.amendDayComment.plusOne) {
          setState({ friCommentsQty: state.friCommentsQty + 1 });
        }
        if (memoVals.amendDayComment.minusOne) {
          setState({ friCommentsQty: state.friCommentsQty - 1 });
        }
      }

      if (dateObj.getDay() === 6) {
        if (memoVals.amendDayComment.plusOne) {
          setState({ satCommentsQty: state.satCommentsQty + 1 });
        }
        if (memoVals.amendDayComment.minusOne) {
          setState({ satCommentsQty: state.satCommentsQty - 1 });
        }
      }
    }
  }, [memoVals.amendDayComment]);

  let [shiftsExist, setShiftsExist] = useState(0);
  useEffect(() => {
    let shiftsCount = 0;
    state.sched.forEach((usr) => {
      let combi = [
        ...usr.monItems,
        ...usr.tueItems,
        ...usr.wedItems,
        ...usr.thuItems,
        ...usr.friItems,
        ...usr.satItems,
        ...usr.sunItems,
      ];
      combi.forEach((x) => {
        if (x.type === "shift" || x.type === "overtime" || x.type === "til") {
          if (!x.otherTeam) {
            shiftsCount++;
          }
        }
      });
    });

    if (shiftsCount > 0) {
      setShiftsExist(shiftsCount);
    } else {
      setShiftsExist(0);
    }
  }, [state.sched]);

  useEffect(() => {
    // clean not needed

    if (memoVals.addTagToAllUserShifts.userID) {
      let newArr = [];
      state.sched.forEach((usr) => {
        if (usr.userID === memoVals.addTagToAllUserShifts.userID) {
          let newMon = [];
          let newTue = [];
          let newWed = [];
          let newThu = [];
          let newFri = [];
          let newSat = [];
          let newSun = [];
          usr.monItems.forEach((item) => {
            let newObj = item;
            if (
              (!item.tags.includes(memoVals.addTagToAllUserShifts.tag) &&
                item.type === "shift") ||
              item.type === "til" ||
              item.type === "overtime"
            ) {
              newObj["tags"] = [
                ...item.tags,
                memoVals.addTagToAllUserShifts.tag,
              ];
            }

            newMon.push(item);
          });

          usr.tueItems.forEach((item) => {
            let newObj = item;
            if (
              (!item.tags.includes(memoVals.addTagToAllUserShifts.tag) &&
                item.type === "shift") ||
              item.type === "til" ||
              item.type === "overtime"
            ) {
              newObj["tags"] = [
                ...item.tags,
                memoVals.addTagToAllUserShifts.tag,
              ];
            }

            newTue.push(item);
          });

          usr.wedItems.forEach((item) => {
            let newObj = item;
            if (
              (!item.tags.includes(memoVals.addTagToAllUserShifts.tag) &&
                item.type === "shift") ||
              item.type === "til" ||
              item.type === "overtime"
            ) {
              newObj["tags"] = [
                ...item.tags,
                memoVals.addTagToAllUserShifts.tag,
              ];
            }

            newWed.push(item);
          });

          usr.thuItems.forEach((item) => {
            let newObj = item;
            if (
              (!item.tags.includes(memoVals.addTagToAllUserShifts.tag) &&
                item.type === "shift") ||
              item.type === "til" ||
              item.type === "overtime"
            ) {
              newObj["tags"] = [
                ...item.tags,
                memoVals.addTagToAllUserShifts.tag,
              ];
            }

            newThu.push(item);
          });

          usr.friItems.forEach((item) => {
            let newObj = item;
            if (
              (!item.tags.includes(memoVals.addTagToAllUserShifts.tag) &&
                item.type === "shift") ||
              item.type === "til" ||
              item.type === "overtime"
            ) {
              newObj["tags"] = [
                ...item.tags,
                memoVals.addTagToAllUserShifts.tag,
              ];
            }

            newFri.push(item);
          });

          usr.satItems.forEach((item) => {
            let newObj = item;
            if (
              (!item.tags.includes(memoVals.addTagToAllUserShifts.tag) &&
                item.type === "shift") ||
              item.type === "til" ||
              item.type === "overtime"
            ) {
              newObj["tags"] = [
                ...item.tags,
                memoVals.addTagToAllUserShifts.tag,
              ];
            }

            newSat.push(item);
          });

          usr.sunItems.forEach((item) => {
            let newObj = item;
            if (
              (!item.tags.includes(memoVals.addTagToAllUserShifts.tag) &&
                item.type === "shift") ||
              item.type === "til" ||
              item.type === "overtime"
            ) {
              newObj["tags"] = [
                ...item.tags,
                memoVals.addTagToAllUserShifts.tag,
              ];
            }

            newSun.push(item);
          });

          // let userCost = usr.cost;
          let primaryOrder = usr.primaryOrder;
          let userID = usr.userID;
          let fName = usr.fName;
          let lName = usr.lName;
          let jobTitle = usr.jobTitle;
          let profPicUrl = usr.profPicUrl;

          let monItems = newMon;
          let tueItems = newTue;
          let wedItems = newWed;
          let thuItems = newThu;
          let friItems = newFri;
          let satItems = newSat;
          let sunItems = newSun;
          newArr.push({
            cost: usr.cost,
            primaryOrder,
            userID,
            fName,
            lName,
            jobTitle,
            monItems,
            tueItems,
            wedItems,
            thuItems,
            friItems,
            satItems,
            sunItems,
            profPicUrl,
            nonWorkingDays: usr.nonWorkingDays,
            avgContractedMpw: usr.avgContractedMpw,
            primaryTeamID: usr.primaryTeamID,
            managesPrimaryTeam: usr.managesPrimaryTeam,
            mins: usr.mins,
            primaryTeamName: usr.primaryTeamName,
          });
        } else {
          newArr.push(usr);
        }
      });

      setState({ sched: newArr });
      memoVals.setAddtagToAllUserShifts((x) => {
        return { userID: "", tag: "" };
      });
    }
  }, [memoVals.addTagToAllUserShifts]);

  let [breakTeams, setBreakTeams] = useState(true);
  let teamsBar = useRef();

  // useEffect(() => {

  //   if (!memoVals.mob && mgrTeams[0]) {
  //     let done = false;
  //     if (window.innerWidth < 870) {
  //       if (mgrTeams.length > 3) {
  //         done = true;
  //         setBreakTeams(true);
  //       } else {
  //         setBreakTeams(false);
  //       }
  //     }

  //     if (window.innerWidth < 1070) {
  //       if (mgrTeams.length > 4) {
  //         done = true;

  //         setBreakTeams(true);
  //       } else {
  //         if (!done) {
  //           setBreakTeams(false);
  //         }
  //       }
  //     }

  //     if (window.innerWidth < 1270) {
  //       if (mgrTeams.length > 5) {
  //         done = true;

  //         setBreakTeams(true);
  //       } else {
  //         if (!done) {
  //           done = true;

  //           setBreakTeams(false);
  //         }
  //       }
  //     }

  //     if (mgrTeams.length > 7) {
  //       done = true;

  //       setBreakTeams(true);
  //     } else {
  //       if (!done) {
  //         setBreakTeams(false);
  //       }
  //     }
  //   }
  // }, [mgrTeams, memoVals.width]);

  useEffect(() => {
    // clean not needed

    //  - - - - - - -

    if (
      memoVals.openShiftConvert &&
      memoVals.openShiftConvert.direction &&
      memoVals.openShiftConvert.direction === "editing"
    ) {
      // it's removed already when the modal is closed

      // add to opens

      if (memoVals.openShiftConvert.newItem.teamID === selectedTeam) {
        let dateObj = new Date(
          dateStringer.createTimestampFromString(
            memoVals.openShiftConvert.newItem.ds
          )
        );

        let newMonOpens = state.monOpens.filter((x) => {
          return x.openID !== memoVals.openShiftConvert.openID;
        });
        let newTueOpens = state.tueOpens.filter((x) => {
          return x.openID !== memoVals.openShiftConvert.openID;
        });
        let newWedOpens = state.wedOpens.filter((x) => {
          return x.openID !== memoVals.openShiftConvert.openID;
        });
        let newThuOpens = state.thuOpens.filter((x) => {
          return x.openID !== memoVals.openShiftConvert.openID;
        });
        let newFriOpens = state.friOpens.filter((x) => {
          return x.openID !== memoVals.openShiftConvert.openID;
        });
        let newSatOpens = state.satOpens.filter((x) => {
          return x.openID !== memoVals.openShiftConvert.openID;
        });
        let newSunOpens = state.sunOpens.filter((x) => {
          return x.openID !== memoVals.openShiftConvert.openID;
        });

        if (dateObj.getDay() === 1) {
          newMonOpens.push({
            rate: memoVals.openShiftConvert.newItem.rate,

            openID: memoVals.openShiftConvert.openID,
            ds: memoVals.openShiftConvert.newItem.ds,
            bidUserIDs: memoVals.openShiftConvert.newItem.bidUserIDs,
            startDs: memoVals.openShiftConvert.newItem.startDs,
            endDs: memoVals.openShiftConvert.newItem.endDs,
            teamID: memoVals.openShiftConvert.newItem.teamID,
            tags:
              memoVals.openShiftConvert.newItem.tags &&
              memoVals.openShiftConvert.newItem
                ? memoVals.openShiftConvert.newItem.tags
                : [],
          });
        }

        if (dateObj.getDay() === 2) {
          newTueOpens.push({
            rate: memoVals.openShiftConvert.newItem.rate,

            openID: memoVals.openShiftConvert.openID,
            ds: memoVals.openShiftConvert.newItem.ds,
            bidUserIDs: memoVals.openShiftConvert.newItem.bidUserIDs,
            startDs: memoVals.openShiftConvert.newItem.startDs,
            endDs: memoVals.openShiftConvert.newItem.endDs,
            teamID: memoVals.openShiftConvert.newItem.teamID,
            tags:
              memoVals.openShiftConvert.newItem.tags &&
              memoVals.openShiftConvert.newItem
                ? memoVals.openShiftConvert.newItem.tags
                : [],
          });
        }

        if (dateObj.getDay() === 3) {
          newWedOpens.push({
            rate: memoVals.openShiftConvert.newItem.rate,

            openID: memoVals.openShiftConvert.openID,
            ds: memoVals.openShiftConvert.newItem.ds,
            bidUserIDs: memoVals.openShiftConvert.newItem.bidUserIDs,
            startDs: memoVals.openShiftConvert.newItem.startDs,
            endDs: memoVals.openShiftConvert.newItem.endDs,
            teamID: memoVals.openShiftConvert.newItem.teamID,
            tags:
              memoVals.openShiftConvert.newItem.tags &&
              memoVals.openShiftConvert.newItem
                ? memoVals.openShiftConvert.newItem.tags
                : [],
          });
        }

        if (dateObj.getDay() === 4) {
          newThuOpens.push({
            rate: memoVals.openShiftConvert.newItem.rate,

            openID: memoVals.openShiftConvert.openID,
            ds: memoVals.openShiftConvert.newItem.ds,
            bidUserIDs: memoVals.openShiftConvert.newItem.bidUserIDs,
            startDs: memoVals.openShiftConvert.newItem.startDs,
            endDs: memoVals.openShiftConvert.newItem.endDs,
            teamID: memoVals.openShiftConvert.newItem.teamID,
            tags:
              memoVals.openShiftConvert.newItem.tags &&
              memoVals.openShiftConvert.newItem
                ? memoVals.openShiftConvert.newItem.tags
                : [],
          });
        }

        if (dateObj.getDay() === 5) {
          newFriOpens.push({
            rate: memoVals.openShiftConvert.newItem.rate,

            openID: memoVals.openShiftConvert.openID,
            ds: memoVals.openShiftConvert.newItem.ds,
            bidUserIDs: memoVals.openShiftConvert.newItem.bidUserIDs,
            startDs: memoVals.openShiftConvert.newItem.startDs,
            endDs: memoVals.openShiftConvert.newItem.endDs,
            teamID: memoVals.openShiftConvert.newItem.teamID,
            tags:
              memoVals.openShiftConvert.newItem.tags &&
              memoVals.openShiftConvert.newItem
                ? memoVals.openShiftConvert.newItem.tags
                : [],
          });
        }

        if (dateObj.getDay() === 6) {
          newSatOpens.push({
            rate: memoVals.openShiftConvert.newItem.rate,

            openID: memoVals.openShiftConvert.openID,
            ds: memoVals.openShiftConvert.newItem.ds,
            bidUserIDs: memoVals.openShiftConvert.newItem.bidUserIDs,
            startDs: memoVals.openShiftConvert.newItem.startDs,
            endDs: memoVals.openShiftConvert.newItem.endDs,
            teamID: memoVals.openShiftConvert.newItem.teamID,
            tags:
              memoVals.openShiftConvert.newItem.tags &&
              memoVals.openShiftConvert.newItem
                ? memoVals.openShiftConvert.newItem.tags
                : [],
          });
        }

        if (dateObj.getDay() === 0) {
          newSunOpens.push({
            rate: memoVals.openShiftConvert.newItem.rate,
            openID: memoVals.openShiftConvert.openID,
            ds: memoVals.openShiftConvert.newItem.ds,
            bidUserIDs: memoVals.openShiftConvert.newItem.bidUserIDs,
            startDs: memoVals.openShiftConvert.newItem.startDs,
            endDs: memoVals.openShiftConvert.newItem.endDs,
            teamID: memoVals.openShiftConvert.newItem.teamID,
            tags:
              memoVals.openShiftConvert.newItem.tags &&
              memoVals.openShiftConvert.newItem
                ? memoVals.openShiftConvert.newItem.tags
                : [],
          });
        }

        setState({
          monOpens: newMonOpens,
          tueOpens: newTueOpens,
          wedOpens: newWedOpens,
          thuOpens: newThuOpens,
          friOpens: newFriOpens,
          satOpens: newSatOpens,
          sunOpens: newSunOpens,
        });
      }

      memoVals.setOpenShiftConvert((x) => {
        return {};
      });
    }

    // - - - - - -
    if (
      memoVals.openShiftConvert &&
      memoVals.openShiftConvert.direction &&
      memoVals.openShiftConvert.direction === "toOpen"
    ) {
      // it's removed already when the modal is closed

      // add to opens

      if (memoVals.openShiftConvert.teamID === selectedTeam) {
        let dateObj = new Date(
          dateStringer.createTimestampFromString(memoVals.openShiftConvert.ds)
        );

        let newMonOpens =
          dateObj.getDay() === 1
            ? [
                ...state.monOpens,
                {
                  openID: memoVals.openShiftConvert.openID,
                  ds: memoVals.openShiftConvert.ds,
                  bidUserIDs: [],
                  startDs: memoVals.openShiftConvert.startDs,
                  endDs: memoVals.openShiftConvert.endDs,
                  teamID: memoVals.openShiftConvert.teamID,
                  tags:
                    memoVals.openShiftConvert.tags &&
                    memoVals.openShiftConvert.tags[0]
                      ? memoVals.openShiftConvert.tags
                      : [],
                },
              ]
            : state.monOpens;

        let newTueOpens =
          dateObj.getDay() === 2
            ? [
                ...state.tueOpens,
                {
                  openID: memoVals.openShiftConvert.openID,
                  ds: memoVals.openShiftConvert.ds,
                  bidUserIDs: [],
                  startDs: memoVals.openShiftConvert.startDs,
                  endDs: memoVals.openShiftConvert.endDs,
                  teamID: memoVals.openShiftConvert.teamID,
                  tags:
                    memoVals.openShiftConvert.tags &&
                    memoVals.openShiftConvert.tags[0]
                      ? memoVals.openShiftConvert.tags
                      : [],
                },
              ]
            : state.tueOpens;

        let newWedOpens =
          dateObj.getDay() === 3
            ? [
                ...state.wedOpens,
                {
                  openID: memoVals.openShiftConvert.openID,
                  ds: memoVals.openShiftConvert.ds,
                  bidUserIDs: [],
                  startDs: memoVals.openShiftConvert.startDs,
                  endDs: memoVals.openShiftConvert.endDs,
                  teamID: memoVals.openShiftConvert.teamID,
                  tags:
                    memoVals.openShiftConvert.tags &&
                    memoVals.openShiftConvert.tags[0]
                      ? memoVals.openShiftConvert.tags
                      : [],
                },
              ]
            : state.wedOpens;

        let newThuOpens =
          dateObj.getDay() === 4
            ? [
                ...state.thuOpens,
                {
                  openID: memoVals.openShiftConvert.openID,
                  ds: memoVals.openShiftConvert.ds,
                  bidUserIDs: [],
                  startDs: memoVals.openShiftConvert.startDs,
                  endDs: memoVals.openShiftConvert.endDs,
                  teamID: memoVals.openShiftConvert.teamID,
                  tags:
                    memoVals.openShiftConvert.tags &&
                    memoVals.openShiftConvert.tags[0]
                      ? memoVals.openShiftConvert.tags
                      : [],
                },
              ]
            : state.thuOpens;

        let newFriOpens =
          dateObj.getDay() === 5
            ? [
                ...state.friOpens,
                {
                  openID: memoVals.openShiftConvert.openID,
                  ds: memoVals.openShiftConvert.ds,
                  bidUserIDs: [],
                  startDs: memoVals.openShiftConvert.startDs,
                  endDs: memoVals.openShiftConvert.endDs,
                  teamID: memoVals.openShiftConvert.teamID,
                  tags:
                    memoVals.openShiftConvert.tags &&
                    memoVals.openShiftConvert.tags[0]
                      ? memoVals.openShiftConvert.tags
                      : [],
                },
              ]
            : state.friOpens;

        let newSatOpens =
          dateObj.getDay() === 6
            ? [
                ...state.satOpens,
                {
                  openID: memoVals.openShiftConvert.openID,
                  ds: memoVals.openShiftConvert.ds,
                  bidUserIDs: [],
                  startDs: memoVals.openShiftConvert.startDs,
                  endDs: memoVals.openShiftConvert.endDs,
                  teamID: memoVals.openShiftConvert.teamID,
                  tags:
                    memoVals.openShiftConvert.tags &&
                    memoVals.openShiftConvert.tags[0]
                      ? memoVals.openShiftConvert.tags
                      : [],
                },
              ]
            : state.satOpens;

        let newSunOpens =
          dateObj.getDay() === 0
            ? [
                ...state.sunOpens,
                {
                  openID: memoVals.openShiftConvert.openID,
                  ds: memoVals.openShiftConvert.ds,
                  bidUserIDs: [],
                  startDs: memoVals.openShiftConvert.startDs,
                  endDs: memoVals.openShiftConvert.endDs,
                  teamID: memoVals.openShiftConvert.teamID,
                  tags:
                    memoVals.openShiftConvert.tags &&
                    memoVals.openShiftConvert.tags[0]
                      ? memoVals.openShiftConvert.tags
                      : [],
                },
              ]
            : state.sunOpens;

        let newArr = [];
        state.sched.forEach((usr) => {
          newArr.push({
            cost: usr.cost,
            primaryOrder: usr.primaryOrder,
            nonWorkingDays: usr.nonWorkingDays,
            userID: usr.userID,
            fName: usr.fName,
            lName: usr.lName,
            jobTitle: usr.jobTitle,
            monItems: usr.monItems.filter((x) => {
              return x.typeID !== memoVals.openShiftConvert.openID;
            }),
            tueItems: usr.tueItems.filter((x) => {
              return x.typeID !== memoVals.openShiftConvert.openID;
            }),
            wedItems: usr.wedItems.filter((x) => {
              return x.typeID !== memoVals.openShiftConvert.openID;
            }),
            thuItems: usr.thuItems.filter((x) => {
              return x.typeID !== memoVals.openShiftConvert.openID;
            }),
            friItems: usr.friItems.filter((x) => {
              return x.typeID !== memoVals.openShiftConvert.openID;
            }),
            satItems: usr.satItems.filter((x) => {
              return x.typeID !== memoVals.openShiftConvert.openID;
            }),
            sunItems: usr.sunItems.filter((x) => {
              return x.typeID !== memoVals.openShiftConvert.openID;
            }),
            profPicUrl: usr.profPicUrl,
            primaryTeamID: usr.primaryTeamID,
            managesPrimaryTeam: usr.managesPrimaryTeam,
            mins: usr.mins,
            primaryTeamName: usr.primaryTeamName,
          });
        });

        setState({
          sched: newArr,
          monOpens: newMonOpens,
          tueOpens: newTueOpens,
          wedOpens: newWedOpens,
          thuOpens: newThuOpens,
          friOpens: newFriOpens,
          satOpens: newSatOpens,
          sunOpens: newSunOpens,
        });
      }

      memoVals.setOpenShiftConvert((x) => {
        return {};
      });
    }

    if (
      memoVals.openShiftConvert &&
      memoVals.openShiftConvert.direction &&
      memoVals.openShiftConvert.direction === "toUser"
    ) {
      let obj = memoVals.openShiftConvert.newItem
        ? memoVals.openShiftConvert.newItem
        : false;
      let newItem = obj
        ? {
            break: obj.break,
            dayN: obj.dayN,
            ds: obj.ds,
            cost: obj.cost,
            trueCost: obj.trueCost,
            durMins: obj.durMins,
            start: obj.start,
            end: obj.end,
            midnightTimestamp: obj.midnightTimestamp,
            published: obj.published,
            absent: obj.absent,
            partialAbsent: obj.partialAbsent,
            partialAbsentDuration: obj.partialAbsentDuration,
            salaried: obj.salaried,
            startTs: obj.startTs,
            tags: obj.tags,
            teamID: obj.teamID,
            type: obj.type,
            userID: obj.userID,
            typeID: obj.typeID,
          }
        : {};

      let newArr = [];
      state.sched.forEach((usr) => {
        let newMon = usr.monItems;
        let newTue = usr.tueItems;
        let newWed = usr.wedItems;
        let newThu = usr.thuItems;
        let newFri = usr.friItems;
        let newSat = usr.satItems;
        let newSun = usr.sunItems;

        if (newItem.dayN === 0 && newItem.userID === usr.userID) {
          newSun.push(newItem);
        }
        if (newItem.dayN === 1 && newItem.userID === usr.userID) {
          newMon.push(newItem);
        }
        if (newItem.dayN === 2 && newItem.userID === usr.userID) {
          newTue.push(newItem);
        }
        if (newItem.dayN === 3 && newItem.userID === usr.userID) {
          newWed.push(newItem);
        }
        if (newItem.dayN === 4 && newItem.userID === usr.userID) {
          newThu.push(newItem);
        }
        if (newItem.dayN === 5 && newItem.userID === usr.userID) {
          newFri.push(newItem);
        }
        if (newItem.dayN === 6 && newItem.userID === usr.userID) {
          newSat.push(newItem);
        }

        let userCost = usr.cost;
        let primaryOrder = usr.primaryOrder;
        let userID = usr.userID;
        let fName = usr.fName;
        let lName = usr.lName;
        let jobTitle = usr.jobTitle;
        let profPicUrl = usr.profPicUrl;

        let monItems = newMon;
        let tueItems = newTue;
        let wedItems = newWed;
        let thuItems = newThu;
        let friItems = newFri;
        let satItems = newSat;
        let sunItems = newSun;
        newArr.push({
          cost: usr.cost,
          primaryOrder,
          userID,
          fName,
          lName,
          jobTitle,
          monItems,
          tueItems,
          wedItems,
          thuItems,
          friItems,
          satItems,
          sunItems,
          profPicUrl,
          primaryTeamID: usr.primaryTeamID,
          managesPrimaryTeam: usr.managesPrimaryTeam,
          mins: usr.mins,
          primaryTeamName: usr.primaryTeamName,
          nonWorkingDays: usr.nonWorkingDays,
        });
      });

      setState({
        sched: newArr,
        monOpens: state.monOpens.filter((x) => {
          return x.openID !== memoVals.openShiftConvert.openID;
        }),
        tueOpens: state.tueOpens.filter((x) => {
          return x.openID !== memoVals.openShiftConvert.openID;
        }),
        wedOpens: state.wedOpens.filter((x) => {
          return x.openID !== memoVals.openShiftConvert.openID;
        }),
        thuOpens: state.thuOpens.filter((x) => {
          return x.openID !== memoVals.openShiftConvert.openID;
        }),
        friOpens: state.friOpens.filter((x) => {
          return x.openID !== memoVals.openShiftConvert.openID;
        }),
        satOpens: state.satOpens.filter((x) => {
          return x.openID !== memoVals.openShiftConvert.openID;
        }),
        sunOpens: state.sunOpens.filter((x) => {
          return x.openID !== memoVals.openShiftConvert.openID;
        }),
      });
    }

    if (
      memoVals.openShiftConvert &&
      memoVals.openShiftConvert.direction &&
      memoVals.openShiftConvert.direction === "deleting"
    ) {
      setState({
        monOpens: state.monOpens.filter((x) => {
          return x.openID !== memoVals.openShiftConvert.openID;
        }),
        tueOpens: state.tueOpens.filter((x) => {
          return x.openID !== memoVals.openShiftConvert.openID;
        }),
        wedOpens: state.wedOpens.filter((x) => {
          return x.openID !== memoVals.openShiftConvert.openID;
        }),
        thuOpens: state.thuOpens.filter((x) => {
          return x.openID !== memoVals.openShiftConvert.openID;
        }),
        friOpens: state.friOpens.filter((x) => {
          return x.openID !== memoVals.openShiftConvert.openID;
        }),
        satOpens: state.satOpens.filter((x) => {
          return x.openID !== memoVals.openShiftConvert.openID;
        }),
        sunOpens: state.sunOpens.filter((x) => {
          return x.openID !== memoVals.openShiftConvert.openID;
        }),
      });
    }
  }, [memoVals.openShiftConvert]);

  useEffect(() => {
    // clean not needed

    // update the sched if memoVals.dynamicSwap is updated
    let deleteTypeIDs = memoVals.dynamicSwap.getRidOfTypeIDs;
    let newItem1 = memoVals.dynamicSwap.newItem1;
    let newItem2 = memoVals.dynamicSwap.newItem2;

    let newArr = [];
    state.sched.forEach((usr) => {
      let newMon = [];
      let newTue = [];
      let newWed = [];
      let newThu = [];
      let newFri = [];
      let newSat = [];
      let newSun = [];

      usr.monItems.forEach((item) => {
        if (!deleteTypeIDs.includes(item.typeID)) {
          newMon.push(item);
        }
      });

      if (newItem1.userID === usr.userID && newItem1.dayN === 1) {
        newMon.push(newItem1);
      }

      if (newItem2.userID === usr.userID && newItem2.dayN === 1) {
        newMon.push(newItem2);
      }

      // - - - - - - - - - - -

      usr.tueItems.forEach((item) => {
        if (!deleteTypeIDs.includes(item.typeID)) {
          newTue.push(item);
        }
      });

      if (newItem1.userID === usr.userID && newItem1.dayN === 2) {
        newTue.push(newItem1);
      }

      if (newItem2.userID === usr.userID && newItem2.dayN === 2) {
        newTue.push(newItem2);
      }

      // - - - - - - - - - - -

      usr.wedItems.forEach((item) => {
        if (!deleteTypeIDs.includes(item.typeID)) {
          newWed.push(item);
        }
      });

      if (newItem1.userID === usr.userID && newItem1.dayN === 3) {
        newWed.push(newItem1);
      }

      if (newItem2.userID === usr.userID && newItem2.dayN === 3) {
        newWed.push(newItem2);
      }
      // - - - - - - - - - - -

      usr.thuItems.forEach((item) => {
        if (!deleteTypeIDs.includes(item.typeID)) {
          newThu.push(item);
        }
      });

      if (newItem1.userID === usr.userID && newItem1.dayN === 4) {
        newThu.push(newItem1);
      }

      if (newItem2.userID === usr.userID && newItem2.dayN === 4) {
        newThu.push(newItem2);
      }
      // - - - - - - - - - - -

      usr.friItems.forEach((item) => {
        if (!deleteTypeIDs.includes(item.typeID)) {
          newFri.push(item);
        }
      });

      if (newItem1.userID === usr.userID && newItem1.dayN === 5) {
        newFri.push(newItem1);
      }

      if (newItem2.userID === usr.userID && newItem2.dayN === 5) {
        newFri.push(newItem2);
      }
      // - - - - - - - - - - -

      usr.satItems.forEach((item) => {
        if (!deleteTypeIDs.includes(item.typeID)) {
          newSat.push(item);
        }
      });

      if (newItem1.userID === usr.userID && newItem1.dayN === 6) {
        newSat.push(newItem1);
      }

      if (newItem2.userID === usr.userID && newItem2.dayN === 6) {
        newSat.push(newItem2);
      }

      // - - - - - - - - - - -

      usr.sunItems.forEach((item) => {
        if (!deleteTypeIDs.includes(item.typeID)) {
          newSun.push(item);
        }
      });

      if (newItem1.userID === usr.userID && newItem1.dayN === 0) {
        newSun.push(newItem1);
      }

      if (newItem2.userID === usr.userID && newItem2.dayN === 0) {
        newSun.push(newItem2);
      }

      // - - - - - - - - - - -

      let userCost = usr.cost;
      let primaryOrder = usr.primaryOrder;
      let userID = usr.userID;
      let fName = usr.fName;
      let lName = usr.lName;
      let jobTitle = usr.jobTitle;
      let profPicUrl = usr.profPicUrl;

      let monItems = newMon;
      let tueItems = newTue;
      let wedItems = newWed;
      let thuItems = newThu;
      let friItems = newFri;
      let satItems = newSat;
      let sunItems = newSun;
      newArr.push({
        cost: usr.cost,
        primaryOrder,
        userID,
        fName,
        lName,
        jobTitle,
        monItems,
        tueItems,
        wedItems,
        thuItems,
        friItems,
        satItems,
        sunItems,
        profPicUrl,
        primaryTeamID: usr.primaryTeamID,
        managesPrimaryTeam: usr.managesPrimaryTeam,
        mins: usr.mins,
        primaryTeamName: usr.primaryTeamName,
      });
    });

    setState({ sched: newArr });
  }, [memoVals.dynamicSwap]);

  let [noPermissions, setNoPermissions] = useState(false);
  let [showLimitTools, setShowLimitTools] = useState(false);
  let [basicView, setBasicView] = useState(false);
  let [currMonWeek, setCurrMonWeek] = useState("");
  let [creatingShift, setCreatingShift] = useState(false);
  let [moveUnit, setMoveUnit] = useState({}); // { itemID, type, oldUserID, oldDs }
  let [pastable, setPastable] = useState(false);

  document.onkeydown = function (evt) {
    evt = evt || window.event;

    if (evt.keyCode == 27 || evt.key == 27) {
      setMoveUnit({});
      memoVals.setShowAddLeaveModal((x) => false);
      setShowAddLeaveOnTeam(false);
      memoVals.setShowAddShiftTeamSched((x) => false);
      setState({ hoveredShift: {} });
      setSelectedHorizAdd(false);
      setSelectedHorizAddShift("");
      setSelectedAddShiftEmpty("");
      setShowTilOrShiftPopUp(false);
    }
  };

  // ------

  let [showToolsPublish, setShowToolsPublish] = useState(false);
  let [showToolsLimits, setShowToolsLimits] = useState(false);
  let [showToolsCopy, setShowToolsCopy] = useState(false);
  let [showToolsTools, setShowToolsTools] = useState(false);

  let showSpecDays = () => {
    if (
      (state.specDays.d0 && state.specDays.d0.name) ||
      (state.specDays.d1 && state.specDays.d1.name) ||
      (state.specDays.d2 && state.specDays.d2.name) ||
      (state.specDays.d3 && state.specDays.d3.name) ||
      (state.specDays.d4 && state.specDays.d4.name) ||
      (state.specDays.d5 && state.specDays.d5.name) ||
      (state.specDays.d6 && state.specDays.d6.name)
    ) {
      return true;
    } else {
      return false;
    }
  };

  let shortenSpecDay = (txt) => {
    if (txt && txt.length > 20) {
      return `${txt.substr(0, 19)}...`;
    } else {
      return txt;
    }
  };

  let specDay = (ds) => {
    let dateObj = new Date(dateStringer.createTimestampFromString(ds));
    if (dateObj.getDay() === 0) {
      return shortenSpecDay(state.specDays.d0.name);
    }
    if (dateObj.getDay() === 1) {
      return shortenSpecDay(state.specDays.d1.name);
    }
    if (dateObj.getDay() === 2) {
      return shortenSpecDay(state.specDays.d2.name);
    }
    if (dateObj.getDay() === 3) {
      return shortenSpecDay(state.specDays.d3.name);
    }
    if (dateObj.getDay() === 4) {
      return shortenSpecDay(state.specDays.d4.name);
    }
    if (dateObj.getDay() === 5) {
      return shortenSpecDay(state.specDays.d5.name);
    }
    if (dateObj.getDay() === 6) {
      return shortenSpecDay(state.specDays.d6.name);
    }
  };

  let specDayClosed = (ds) => {
    let dateObj = new Date(dateStringer.createTimestampFromString(ds));
    if (dateObj.getDay() === 0 && !state.specDays.d0.open) {
      return true;
    }
    if (dateObj.getDay() === 1 && !state.specDays.d1.open) {
      return true;
    }
    if (dateObj.getDay() === 2 && !state.specDays.d2.open) {
      return true;
    }
    if (dateObj.getDay() === 3 && !state.specDays.d3.open) {
      return true;
    }
    if (dateObj.getDay() === 4 && !state.specDays.d4.open) {
      return true;
    }
    if (dateObj.getDay() === 5 && !state.specDays.d5.open) {
      return true;
    }
    if (dateObj.getDay() === 6 && !state.specDays.d6.open) {
      return true;
    }
  };

  let [showTip, setShowTip] = useState(false);

  useEffect(() => {
    // clean not needed

    setShowTools(false);
    setShowToolsLimits(false);
    setShowToolsTools(false);
    setShowLimitTools(false);

    if (streamShiftID.shiftID) {
      setStreamShiftID({ shiftID: "", type: "" });
    }
  }, [memoVals.mob]);

  useEffect(() => {
    // clean not needed

    // reloads shifts when memoVals.update2 is updated in DataContext by the ManagerShiftModal
    memoVals.setUpdateInfo((x) => !memoVals.updateInfo);
  }, [memoVals.update2]);

  // update sched locally with the updated item -- without calling the request again
  useEffect(() => {
    // clean not needed

    let newArr = [];

    if (!memoVals.dontAddNewItemToRota) {
      // if (!memoVals.dontAddNewItemToRota) {
      if (memoVals.updatedItem && memoVals.updatedItem.type === "leave") {
        memoVals.setUpdateInfo((x) => !memoVals.updateInfo);
      }
      if (memoVals.updatedItem.data) {
        if (memoVals.updatedItem.doesNotExist) {
          setState({ fadeShiftID: memoVals.updatedItem.failedID });
        }
        // "leave" changes do not have the data property - so assumed is a shift or til
        let typeID =
          memoVals.updatedItem.type === "shift"
            ? memoVals.updatedItem.data.shiftID
            : memoVals.updatedItem.data.tilID;

        let newItem = {
          nightShift: memoVals.updatedItem.nightShift,
          absent: memoVals.updatedItem.data.absent,
          partialAbsent: memoVals.updatedItem.data.partialAbsent,
          partialAbsentStart: memoVals.updatedItem.data.partialAbsentStart,
          partialAbsentEnd: memoVals.updatedItem.data.partialAbsentEnd,

          partialAbsentDuration:
            memoVals.updatedItem.data.partialAbsentDuration,
          bankCost: memoVals.updatedItem.data.bankCost,
          break: memoVals.updatedItem.data.break,
          dayN: memoVals.updatedItem.dayN,
          ds: memoVals.updatedItem.data.ds,
          durMins: memoVals.updatedItem.data.durMins,
          end: memoVals.updatedItem.data.end,
          ghost: memoVals.updatedItem.data.ghost,
          initial: false,
          midnightTimestamp: memoVals.updatedItem.midnightTimestamp,
          published: memoVals.updatedItem.published,
          start: memoVals.updatedItem.data.start,
          startTs: dateStringer.createTimestampFromString(
            memoVals.updatedItem.data.start
          ),
          tags: memoVals.updatedItem.data.tags,
          type: memoVals.updatedItem.type,
          shTeamName: locationMode ? memoVals.updatedItem.teamName : "",
          otherTeam:
            memoVals.updatedItem.teamID === selectedTeam || locationMode
              ? false
              : true,
          teamID: memoVals.updatedItem.teamID,
          typeID: typeID,
          trueCost: memoVals.updatedItem.data.cost,

          cost: memoVals.updatedItem.data.cost,
          trueCost: memoVals.updatedItem.trueCost,
          // !memoVals.updatedItem.data.cost || memoVals.updatedItem.data.cost === 0
          //   ? memoVals.updatedItem.trueCost
          //   : 0,
          salaried: memoVals.updatedItem.salaried,
          userHasSwapped: memoVals.updatedItem.userHasSwapped,
          publicNote: memoVals.updatedItem.comments ? ["00"] : false,
          payRoll: memoVals.updatedItem.payRoll,
          lateDurMins: memoVals.updatedItem.data.lateDurMins,
          accepted: memoVals.updatedItem.accepted,
          requireAccept: memoVals.updatedItem.requireAccept,
          tilApproved: memoVals.updatedItem.tilApproved,
          tilDeclined: memoVals.updatedItem.tilDeclined,
          tilStart: memoVals.updatedItem.tilStart,
          tilEnd: memoVals.updatedItem.tilEnd,
          location: memoVals.updatedItem.location,
        };

        let isNew = true;
        if (!memoVals.updatedItem.teamChanged) {
          state.sched.forEach((user) => {
            user.monItems.forEach((item) => {
              if (item.typeID === typeID) {
                isNew = false;
              }
            });
            user.tueItems.forEach((item) => {
              if (item.typeID === typeID) {
                isNew = false;
              }
            });
            user.wedItems.forEach((item) => {
              if (item.typeID === typeID) {
                isNew = false;
              }
            });
            user.thuItems.forEach((item) => {
              if (item.typeID === typeID) {
                isNew = false;
              }
            });
            user.friItems.forEach((item) => {
              if (item.typeID === typeID) {
                isNew = false;
              }
            });
            user.satItems.forEach((item) => {
              if (item.typeID === typeID) {
                isNew = false;
              }
            });
            user.sunItems.forEach((item) => {
              if (item.typeID === typeID) {
                isNew = false;
              }
            });
          });
        }

        state.sched.forEach((usr) => {
          let newMon = [];
          let newTue = [];
          let newWed = [];
          let newThu = [];
          let newFri = [];
          let newSat = [];
          let newSun = [];
          usr.monItems.forEach((item) => {
            if (item.typeID === typeID) {
              if (
                !memoVals.updatedItem.teamChanged &&
                !memoVals.updatedItem.deleted &&
                !memoVals.updatedItem.doesNotExist &&
                item.typeID !== memoVals.updatedItem.failedID
              ) {
                newMon.push(newItem);
              }
            } else {
              // if(item.typeID !== forceDeleteShiftOrTil) {

              // }
              newMon.push(item);
            }
          });

          if (isNew && !memoVals.updatedItem.deleted) {
            if (usr.userID === memoVals.updatedItem.data.userID) {
              if (memoVals.updatedItem.dayN === 1) {
                newMon.push(newItem);
              }
            }
          }

          usr.tueItems.forEach((item) => {
            if (item.typeID === typeID) {
              if (
                !memoVals.updatedItem.teamChanged &&
                !memoVals.updatedItem.deleted &&
                !memoVals.updatedItem.doesNotExist &&
                item.typeID !== memoVals.updatedItem.failedID
              ) {
                newTue.push(newItem);
              }
            } else {
              newTue.push(item);
            }
          });

          if (isNew && !memoVals.updatedItem.deleted) {
            if (usr.userID === memoVals.updatedItem.data.userID) {
              if (memoVals.updatedItem.dayN === 2) {
                newTue.push(newItem);
              }
            }
          }

          console.log("wedItems!!!!", usr.fName, usr.wedItems);
          usr.wedItems.forEach((item) => {
            // console.log("wed item: ", item);
            console.log("LLLLLLLLL", item.typeID, typeID);
            if (item.typeID === typeID) {
              console.log(
                "****",
                memoVals.updatedItem.teamChanged,
                memoVals.updatedItem.deleted,
                memoVals.updatedItem.doesNotExist,
                item.typeID,
                memoVals.updatedItem.failedID
              );
              if (
                !memoVals.updatedItem.teamChanged &&
                !memoVals.updatedItem.deleted &&
                !memoVals.updatedItem.doesNotExist &&
                item.typeID !== memoVals.updatedItem.failedID
              ) {
                newWed.push(newItem);
              }
            } else {
              newWed.push(item);
            }
          });

          if (isNew && !memoVals.updatedItem.deleted) {
            if (usr.userID === memoVals.updatedItem.data.userID) {
              if (memoVals.updatedItem.dayN === 3) {
                newWed.push(newItem);
              }
            }
          }

          usr.thuItems.forEach((item) => {
            if (item.typeID === typeID) {
              if (
                !memoVals.updatedItem.teamChanged &&
                !memoVals.updatedItem.deleted &&
                !memoVals.updatedItem.doesNotExist &&
                item.typeID !== memoVals.updatedItem.failedID
              ) {
                newThu.push(newItem);
              }
            } else {
              newThu.push(item);
            }
          });

          if (isNew && !memoVals.updatedItem.deleted) {
            if (usr.userID === memoVals.updatedItem.data.userID) {
              if (memoVals.updatedItem.dayN === 4) {
                newThu.push(newItem);
              }
            }
          }

          usr.friItems.forEach((item) => {
            if (item.typeID === typeID) {
              if (
                !memoVals.updatedItem.teamChanged &&
                !memoVals.updatedItem.deleted &&
                !memoVals.updatedItem.doesNotExist &&
                item.typeID !== memoVals.updatedItem.failedID
              ) {
                newFri.push(newItem);
              }
            } else {
              newFri.push(item);
            }
          });

          if (isNew && !memoVals.updatedItem.deleted) {
            if (usr.userID === memoVals.updatedItem.data.userID) {
              if (memoVals.updatedItem.dayN === 5) {
                newFri.push(newItem);
              }
            }
          }

          usr.satItems.forEach((item) => {
            if (item.typeID === typeID) {
              if (
                !memoVals.updatedItem.teamChanged &&
                !memoVals.updatedItem.deleted &&
                !memoVals.updatedItem.doesNotExist &&
                item.typeID !== memoVals.updatedItem.failedID
              ) {
                newSat.push(newItem);
              }
            } else {
              newSat.push(item);
            }
          });

          if (isNew && !memoVals.updatedItem.deleted) {
            if (usr.userID === memoVals.updatedItem.data.userID) {
              if (memoVals.updatedItem.dayN === 6) {
                newSat.push(newItem);
              }
            }
          }

          usr.sunItems.forEach((item) => {
            if (item.typeID === typeID) {
              if (
                !memoVals.updatedItem.teamChanged &&
                !memoVals.updatedItem.deleted &&
                !memoVals.updatedItem.doesNotExist &&
                item.typeID !== memoVals.updatedItem.failedID
              ) {
                newSun.push(newItem);
              }
            } else {
              newSun.push(item);
            }
          });

          if (isNew && !memoVals.updatedItem.deleted) {
            if (usr.userID === memoVals.updatedItem.data.userID) {
              if (memoVals.updatedItem.dayN === 0) {
                newSun.push(newItem);
              }
            }
          }

          // let userCost = usr.cost;
          let primaryOrder = usr.primaryOrder;
          let userID = usr.userID;
          let fName = usr.fName;
          let lName = usr.lName;
          let jobTitle = usr.jobTitle;
          let profPicUrl = usr.profPicUrl;

          let monItems = newMon;
          let tueItems = newTue;
          let wedItems = newWed;
          let thuItems = newThu;
          let friItems = newFri;
          let satItems = newSat;
          let sunItems = newSun;
          newArr.push({
            cost: usr.cost,
            primaryOrder,
            userID,
            fName,
            lName,
            jobTitle,
            monItems,
            tueItems,
            wedItems,
            thuItems,
            friItems,
            satItems,
            sunItems,
            profPicUrl,
            nonWorkingDays: usr.nonWorkingDays,
            avgContractedMpw: usr.avgContractedMpw,
            primaryTeamID: usr.primaryTeamID,
            managesPrimaryTeam: usr.managesPrimaryTeam,
            mins: usr.mins,
            primaryTeamName: usr.primaryTeamName,
          });
        });

        setState({ sched: newArr });
      }
      // }
    }
  }, [memoVals.updatedItem]);

  useEffect(() => {
    // clean not needed

    if (memoVals.shiftIDNoLongerExists.userID) {
      let newArr = [];

      state.sched.forEach((usr) => {
        let newMonItems = [];
        let newTueItems = [];
        let newWedItems = [];
        let newThuItems = [];
        let newFriItems = [];
        let newSatItems = [];
        let newSunItems = [];

        usr.monItems.forEach((item) => {
          if (item.typeID !== memoVals.shiftIDNoLongerExists.itemID) {
            newMonItems.push(item);
          }
        });

        usr.tueItems.forEach((item) => {
          if (item.typeID !== memoVals.shiftIDNoLongerExists.itemID) {
            newTueItems.push(item);
          }
        });

        usr.wedItems.forEach((item) => {
          if (item.typeID !== memoVals.shiftIDNoLongerExists.itemID) {
            newWedItems.push(item);
          }
        });

        usr.thuItems.forEach((item) => {
          if (item.typeID !== memoVals.shiftIDNoLongerExists.itemID) {
            newThuItems.push(item);
          }
        });

        usr.friItems.forEach((item) => {
          if (item.typeID !== memoVals.shiftIDNoLongerExists.itemID) {
            newFriItems.push(item);
          }
        });

        usr.satItems.forEach((item) => {
          if (item.typeID !== memoVals.shiftIDNoLongerExists.itemID) {
            newSatItems.push(item);
          }
        });

        usr.sunItems.forEach((item) => {
          if (item.typeID !== memoVals.shiftIDNoLongerExists.itemID) {
            newSunItems.push(item);
          }
        });

        let userCost = usr.cost;
        let primaryOrder = usr.primaryOrder;
        let userID = usr.userID;
        let fName = usr.fName;
        let lName = usr.lName;
        let jobTitle = usr.jobTitle;
        let profPicUrl = usr.profPicUrl;

        let monItems = newMonItems;
        let tueItems = newTueItems;
        let wedItems = newWedItems;
        let thuItems = newThuItems;
        let friItems = newFriItems;
        let satItems = newSatItems;
        let sunItems = newSunItems;
        newArr.push({
          cost: usr.cost,
          primaryOrder,
          userID,
          fName,
          lName,
          jobTitle,
          monItems,
          tueItems,
          wedItems,
          thuItems,
          friItems,
          satItems,
          sunItems,
          profPicUrl,
          nonWorkingDays: usr.nonWorkingDays,
          avgContractedMpw: usr.avgContractedMpw,
          primaryTeamID: usr.primaryTeamID,
          managesPrimaryTeam: usr.managesPrimaryTeam,
          mins: usr.mins,
          primaryTeamName: usr.primaryTeamName,
        });
      });
      setState({ sched: newArr });
      // updateCostAndMinsOnUser(memoVals.shiftIDNoLongerExists.userID, newArr);
      memoVals.setShiftIDNoLongerExists((x) => {
        return { userID: "", itemID: "" };
      });
    }
    // harry
  }, [memoVals.shiftIDNoLongerExists]);

  useEffect(() => {
    // clean not needed

    if (
      memoVals.changedMidnightTimestamp &&
      memoVals.changedMidnightTimestamp.typeID
    ) {
      let newArr = [];
      state.sched.forEach((usr) => {
        let newMon = [];
        let newTue = [];
        let newWed = [];
        let newThu = [];
        let newFri = [];
        let newSat = [];
        let newSun = [];

        usr.monItems.forEach((item) => {
          if (
            memoVals.changedMidnightTimestamp.oldDayN === 1 &&
            item.typeID === memoVals.changedMidnightTimestamp.typeID
          ) {
            // do not add because it no longer lives here
            if (memoVals.changedMidnightTimestamp.newDayN === 1) {
              newMon.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 2) {
              newTue.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 3) {
              newWed.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 4) {
              newThu.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 5) {
              newFri.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 6) {
              newSat.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 0) {
              newSun.push(item);
            }
          } else {
            newMon.push(item);
          }
        });

        usr.tueItems.forEach((item) => {
          if (
            memoVals.changedMidnightTimestamp.oldDayN === 2 &&
            item.typeID === memoVals.changedMidnightTimestamp.typeID
          ) {
            // do not add because it no longer lives here
            if (memoVals.changedMidnightTimestamp.newDayN === 1) {
              newMon.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 2) {
              newTue.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 3) {
              newWed.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 4) {
              newThu.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 5) {
              newFri.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 6) {
              newSat.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 0) {
              newSun.push(item);
            }
          } else {
            newTue.push(item);
          }
        });

        usr.wedItems.forEach((item) => {
          if (
            memoVals.changedMidnightTimestamp.oldDayN === 3 &&
            item.typeID === memoVals.changedMidnightTimestamp.typeID
          ) {
            // do not add because it no longer lives here
            if (memoVals.changedMidnightTimestamp.newDayN === 1) {
              newMon.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 2) {
              newTue.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 3) {
              newWed.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 4) {
              newThu.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 5) {
              newFri.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 6) {
              newSat.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 0) {
              newSun.push(item);
            }
          } else {
            newWed.push(item);
          }
        });

        usr.thuItems.forEach((item) => {
          if (
            memoVals.changedMidnightTimestamp.oldDayN === 4 &&
            item.typeID === memoVals.changedMidnightTimestamp.typeID
          ) {
            // do not add because it no longer lives here
            if (memoVals.changedMidnightTimestamp.newDayN === 1) {
              newMon.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 2) {
              newTue.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 3) {
              newWed.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 4) {
              newThu.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 5) {
              newFri.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 6) {
              newSat.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 0) {
              newSun.push(item);
            }
          } else {
            newThu.push(item);
          }
        });

        usr.friItems.forEach((item) => {
          if (
            memoVals.changedMidnightTimestamp.oldDayN === 5 &&
            item.typeID === memoVals.changedMidnightTimestamp.typeID
          ) {
            // do not add because it no longer lives here
            if (memoVals.changedMidnightTimestamp.newDayN === 1) {
              newMon.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 2) {
              newTue.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 3) {
              newWed.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 4) {
              newThu.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 5) {
              newFri.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 6) {
              newSat.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 0) {
              newSun.push(item);
            }
          } else {
            newFri.push(item);
          }
        });

        usr.satItems.forEach((item) => {
          if (
            memoVals.changedMidnightTimestamp.oldDayN === 6 &&
            item.typeID === memoVals.changedMidnightTimestamp.typeID
          ) {
            // do not add because it no longer lives here
            if (memoVals.changedMidnightTimestamp.newDayN === 1) {
              newMon.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 2) {
              newTue.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 3) {
              newWed.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 4) {
              newThu.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 5) {
              newFri.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 6) {
              newSat.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 0) {
              newSun.push(item);
            }
          } else {
            newSat.push(item);
          }
        });

        usr.sunItems.forEach((item) => {
          if (
            memoVals.changedMidnightTimestamp.oldDayN === 0 &&
            item.typeID === memoVals.changedMidnightTimestamp.typeID
          ) {
            // do not add because it no longer lives here
            if (memoVals.changedMidnightTimestamp.newDayN === 1) {
              newMon.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 2) {
              newTue.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 3) {
              newWed.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 4) {
              newThu.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 5) {
              newFri.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 6) {
              newSat.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 0) {
              newSun.push(item);
            }
          } else {
            newSun.push(item);
          }
        });

        let primaryOrder = usr.primaryOrder;
        let userID = usr.userID;
        let fName = usr.fName;
        let lName = usr.lName;
        let jobTitle = usr.jobTitle;
        let profPicUrl = usr.profPicUrl;

        let monItems = newMon;
        let tueItems = newTue;
        let wedItems = newWed;
        let thuItems = newThu;
        let friItems = newFri;
        let satItems = newSat;
        let sunItems = newSun;
        newArr.push({
          cost: usr.cost,
          primaryOrder,
          userID,
          fName,
          lName,
          jobTitle,
          monItems,
          tueItems,
          wedItems,
          thuItems,
          friItems,
          satItems,
          sunItems,
          profPicUrl,
          avgContractedMpw: usr.avgContractedMpw,
          primaryTeamID: usr.primaryTeamID,
          managesPrimaryTeam: usr.managesPrimaryTeam,
          mins: usr.mins,
          primaryTeamName: usr.primaryTeamName,
        });
      });
      setState({ sched: newArr });
    }
  }, [memoVals.changedMidnightTimestamp]);

  useEffect(() => {
    if (memoVals.updateOpen.openID) {
      // reload updated open
      let dateObj = new Date(
        dateStringer.createTimestampFromString(memoVals.updateOpen.start)
      );
      let dayN = dateObj.getDay();

      let monNew = [];
      let tueNew = [];
      let wedNew = [];
      let thuNew = [];
      let friNew = [];
      let satNew = [];
      let sunNew = [];

      if (dayN === 0) {
        sunNew.push({
          openID: memoVals.updateOpen.openID,
          bidUserIDs: memoVals.updateOpen.bidUserIDs,
          startDs: memoVals.updateOpen.start,
          endDs: memoVals.updateOpen.end,
          ds: memoVals.updateOpen.start,
        });
      }

      if (dayN === 1) {
        monNew.push({
          openID: memoVals.updateOpen.openID,
          bidUserIDs: memoVals.updateOpen.bidUserIDs,
          startDs: memoVals.updateOpen.start,
          endDs: memoVals.updateOpen.end,
          ds: memoVals.updateOpen.start,
        });
      }

      if (dayN === 2) {
        tueNew.push({
          openID: memoVals.updateOpen.openID,
          bidUserIDs: memoVals.updateOpen.bidUserIDs,
          startDs: memoVals.updateOpen.start,
          endDs: memoVals.updateOpen.end,
          ds: memoVals.updateOpen.start,
        });
      }

      if (dayN === 3) {
        wedNew.push({
          openID: memoVals.updateOpen.openID,
          bidUserIDs: memoVals.updateOpen.bidUserIDs,
          startDs: memoVals.updateOpen.start,
          endDs: memoVals.updateOpen.end,
          ds: memoVals.updateOpen.start,
        });
      }

      if (dayN === 4) {
        thuNew.push({
          openID: memoVals.updateOpen.openID,
          bidUserIDs: memoVals.updateOpen.bidUserIDs,
          startDs: memoVals.updateOpen.start,
          endDs: memoVals.updateOpen.end,
          ds: memoVals.updateOpen.start,
        });
      }

      if (dayN === 5) {
        friNew.push({
          openID: memoVals.updateOpen.openID,
          bidUserIDs: memoVals.updateOpen.bidUserIDs,
          startDs: memoVals.updateOpen.start,
          endDs: memoVals.updateOpen.end,
          ds: memoVals.updateOpen.start,
        });
      }

      if (dayN === 6) {
        satNew.push({
          openID: memoVals.updateOpen.openID,
          bidUserIDs: memoVals.updateOpen.bidUserIDs,
          startDs: memoVals.updateOpen.start,
          endDs: memoVals.updateOpen.end,
          ds: memoVals.updateOpen.start,
        });
      }
      state.monOpens.forEach((item) => {
        if (item.openID !== memoVals.updateOpen.openID) {
          monNew.push(item);
        }
      });

      state.tueOpens.forEach((item) => {
        if (item.openID !== memoVals.updateOpen.openID) {
          tueNew.push(item);
        }
      });

      state.wedOpens.forEach((item) => {
        if (item.openID !== memoVals.updateOpen.openID) {
          wedNew.push(item);
        }
      });

      state.thuOpens.forEach((item) => {
        if (item.openID !== memoVals.updateOpen.openID) {
          thuNew.push(item);
        }
      });

      state.friOpens.forEach((item) => {
        if (item.openID !== memoVals.updateOpen.openID) {
          friNew.push(item);
        }
      });

      state.satOpens.forEach((item) => {
        if (item.openID !== memoVals.updateOpen.openID) {
          satNew.push(item);
        }
      });

      state.sunOpens.forEach((item) => {
        if (item.openID !== memoVals.updateOpen.openID) {
          sunNew.push(item);
        }
      });
      setState({
        monOpens: monNew,
        tueOpens: tueNew,
        wedOpens: wedNew,
        thuOpens: thuNew,
        friOpens: friNew,
        satOpens: satNew,
        sunOpens: sunNew,
      });
    }
  }, [memoVals.updateOpen]);

  let [hoverUserID, setHoverUserID] = useState("");
  let [hoverDs, setHoverDs] = useState("");
  let [hoveredStreamShiftID, setHoveredStreamShiftID] = useState("");

  let [fadeGenBtn, setFadeGenBtn] = useState(false);
  let [currentMw, setCurrentMw] = useState("");

  let [weekNum, setWeekNum] = useState(0);
  let [weekHasNoData, setWeekHasNoData] = useState(false);
  // split == is today split through curr week?
  useEffect(() => {
    // clean not needed

    setState({ pasteLoading: true });
    //

    let dateObj = new Date(dateStringer.createTimestampFromString(currentMw));
    dateObj.setDate(dateObj.getDate() + 6);
    dateObj.setHours(23, 59, 0, 0);
    if (
      dateStringer.createTimestampFromString(selectedMonWeek) >=
      dateStringer.createTimestampFromString(currentMw)
    ) {
      setFadeGenBtn(false);
    } else {
      setFadeGenBtn(true);
    }

    let day2currWeek = new Date(
      dateStringer.createTimestampFromString(selectedMonWeek)
    );
    day2currWeek.setDate(day2currWeek.getDate() + 1);

    let dateObj2 = new Date(
      dateStringer.createTimestampFromString(selectedMonWeek)
    );
    dateObj2.setDate(dateObj2.getDate() + 6);
    dateObj2.setHours(23, 59, 0, 0);
    setLastDs(dateStringer.createStringFromTimestamp(dateObj2.getTime()));
  }, [selectedTeam, selectedMonWeek, currentMw]);
  let [linkExists, setLinkExists] = useState(false);

  let [individuals, setIndividuals] = useState([]);

  let [showGenOffer, setShowGenOffer] = useState([]);
  // - - - -
  let getTeamName = (teamID) => {
    let teamFilt = mgrTeams.filter((item) => item.teamID === teamID);
    if (teamFilt[0]) {
      return teamFilt[0].teamName;
    } else {
      return "";
    }
  };

  let [locations, setLocations] = useState([]);
  let [templateExists, setTemplateExists] = useState(false);
  let [selfManageShifts, setSelfManageShifts] = useState(false);
  const cancelSource1 = axios.CancelToken.source();
  const cancelSource2 = axios.CancelToken.source();
  useEffect(() => {
    setState({ loading: true, limLoad: true });

    if (selectedTeam) {
      let teamFilt = mgrTeams.filter((item) => item.teamID === selectedTeam);
      if (teamFilt[0]) {
        setTeamName(teamFilt[0].teamName);
        document.title = `${shortenTeamNameForTitle(
          teamFilt[0].teamName
        )} rota - FlowRota`;
      } else {
        document.title = "Team rotas - FlowRota";
      }

      // setTimeout(
      // () => {
      setGenerateLoading(false);
      setPublishLoading(false);

      if (
        memoVals.copyWeek &&
        memoVals.copyWeek.monWeek !== selectedMonWeek &&
        memoVals.copyWeek.teamID === selectedTeam
      ) {
        setPastable(true);
      } else {
        setPastable(false);
      }
      // get schedule data here
      let locationID;
      let teamFilt2 = mgrTeams.filter((x) => {
        return x.teamID === selectedTeam;
      });
      let locFilt2 = locations.filter((x) => {
        return x.locationID === selectedTeam;
      });
      if (locFilt2[0]) {
        setLocationMode(locFilt2[0].locationName);
      } else {
        setLocationMode("");
      }
      axios
        .post(
          `${serverURL}/get-team-schedule-for-mon-week`,
          {
            monWeek: selectedMonWeek,
            teamID: locFilt2[0] ? "" : selectedTeam,
            singleDay: state.singleDay,
            singleDay2: state.singleDay2,
            singleUserID: memoVals.dontAddNewItemToRota
              ? memoVals.dontAddNewItemToRota
              : false,
            nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
            locationID: locFilt2[0] ? selectedTeam : "",
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message === "teamID does not exist") {
            setTeamIDDoesNotExist(true);
          }
          if (response.data.message === "success") {
            setLocations(response.data.locations);
            setWeekNum(response.data.weekNumber);
            setSelfManageShifts(response.data.selfManageShifts);
            setHighlightedComments(response.data.highlightedComments);
            setRequireSeen(response.data.requireSeen);
            if (response.data.linkData.active) {
              setPassword(response.data.linkData.password);
              setOriginalPassword(response.data.linkData.password);
              setShareLink(response.data.linkData.shortenedLink);
              setLinkExists(true);
            } else {
              if (linkExists) {
                setLinkExists(false);
              }
              if (password) {
                setPassword("");
              }
              if (shareLink) {
                setShareLink(false);
              }
            }
            // SCHEDULE DATA
            let schedLocal = [];

            if (state.singleDay || state.singleDay2) {
              let newSchedArr = [];

              response.data.arr.forEach((usr) => {
                let items = usr.sunItems;
                let items2 = usr.sunItems;
                if (state.singleDay === 1) {
                  items = usr.monItems;
                }
                if (state.singleDay2 === 1) {
                  items2 = usr.monItems;
                }
                if (state.singleDay === 2) {
                  items = usr.tueItems;
                }
                if (state.singleDay2 === 2) {
                  items2 = usr.tueItems;
                }
                if (state.singleDay === 3) {
                  items = usr.wedItems;
                }
                if (state.singleDay2 === 3) {
                  items2 = usr.wedItems;
                }

                if (state.singleDay === 4) {
                  items = usr.thuItems;
                }
                if (state.singleDay2 === 4) {
                  items2 = usr.thuItems;
                }
                if (state.singleDay === 5) {
                  items = usr.friItems;
                }
                if (state.singleDay2 === 5) {
                  items2 = usr.friItems;
                }
                if (state.singleDay === 6) {
                  items = usr.satItems;
                }
                if (state.singleDay2 === 6) {
                  items2 = usr.satItems;
                }

                state.sched.forEach((user) => {
                  if (usr.userID === user.userID) {
                    if (state.singleDay === 0) {
                      user.sunItems = items;
                    }
                    if (state.singleDay2 === 0) {
                      user.sunItems = items2;
                    }
                    if (state.singleDay === 1) {
                      user.monItems = items;
                    }
                    if (state.singleDay2 === 1) {
                      user.monItems = items2;
                    }
                    if (state.singleDay === 2) {
                      user.tueItems = items;
                    }
                    if (state.singleDay2 === 2) {
                      user.tueItems = items2;
                    }
                    if (state.singleDay === 3) {
                      user.wedItems = items;
                    }
                    if (state.singleDay2 === 3) {
                      user.wedItems = items2;
                    }
                    if (state.singleDay === 4) {
                      user.thuItems = items;
                    }
                    if (state.singleDay2 === 4) {
                      user.thuItems = items2;
                    }

                    if (state.singleDay === 5) {
                      user.friItems = items;
                    }
                    if (state.singleDay2 === 5) {
                      user.friItems = items2;
                    }
                    if (state.singleDay === 6) {
                      user.satItems = items;
                    }
                    if (state.singleDay2 === 6) {
                      user.satItems = items2;
                    }
                    newSchedArr.push(user);
                  }
                });
              });
              schedLocal = newSchedArr;
            } else {
              schedLocal = response.data.arr;
            }

            let c = 0;

            let opens = 0;
            if (
              response.data.monOpens &&
              Array.isArray(response.data.monOpens)
            ) {
              opens += response.data.monOpens.length;
            }
            if (
              response.data.tueOpens &&
              Array.isArray(response.data.tueOpens)
            ) {
              opens += response.data.tueOpens.length;
            }
            if (
              response.data.wedOpens &&
              Array.isArray(response.data.wedOpens)
            ) {
              opens += response.data.wedOpens.length;
            }
            if (
              response.data.thuOpens &&
              Array.isArray(response.data.thuOpens)
            ) {
              opens += response.data.thuOpens.length;
            }
            if (
              response.data.friOpens &&
              Array.isArray(response.data.friOpens)
            ) {
              opens += response.data.friOpens.length;
            }
            if (
              response.data.satOpens &&
              Array.isArray(response.data.satOpens)
            ) {
              opens += response.data.satOpens.length;
            }
            if (
              response.data.sunOpens &&
              Array.isArray(response.data.sunOpens)
            ) {
              opens += response.data.sunOpens.length;
            }

            let nonLeaveCount = 0;
            let fixedSchedUserCount = [];
            response.data.arr.forEach((usr) => {
              if (
                usr.fixedScheduled &&
                usr.primaryTeamID === selectedTeam &&
                usr.nonWorkingDays?.length < 7
              ) {
                fixedSchedUserCount.push(`${usr.fName} ${usr.lName[0]}`);
              }
              usr.monItems.forEach((item) => {
                c++;
                if (item.type !== "leave") {
                  nonLeaveCount++;
                }
              });
              usr.tueItems.forEach((item) => {
                c++;
                if (item.type !== "leave") {
                  nonLeaveCount++;
                }
              });
              usr.wedItems.forEach((item) => {
                c++;
                if (item.type !== "leave") {
                  nonLeaveCount++;
                }
              });
              usr.thuItems.forEach((item) => {
                c++;
                if (item.type !== "leave") {
                  nonLeaveCount++;
                }
              });
              usr.friItems.forEach((item) => {
                c++;
                if (item.type !== "leave") {
                  nonLeaveCount++;
                }
              });
              usr.satItems.forEach((item) => {
                c++;
                if (item.type !== "leave") {
                  nonLeaveCount++;
                }
              });
              usr.sunItems.forEach((item) => {
                c++;
                if (item.type !== "leave") {
                  nonLeaveCount++;
                }
              });
            });
            if (c === 0) {
              setWeekHasNoData(true);
            }

            if (
              dateStringer.createTimestampFromString(selectedMonWeek) >=
              dateStringer.createTimestampFromString(currMonWeek)
            ) {
              if (
                nonLeaveCount === 0 &&
                fixedSchedUserCount.length > 0 &&
                opens === 0
              ) {
                setShowGenOffer(fixedSchedUserCount);
              } else {
                setShowGenOffer([]);
              }
            }

            setTemplateExists(response.data.templateExists);

            let monCostLocal = response.data.monCost || 0;
            let tueCostLocal = response.data.tueCost || 0;
            let wedCostLocal = response.data.wedCost || 0;
            let thuCostLocal = response.data.thuCost || 0;
            let friCostLocal = response.data.friCost || 0;
            let satCostLocal = response.data.satCost || 0;
            let sunCostLocal = response.data.sunCost || 0;

            let monOpensLocal = response.data.monOpens;
            let tueOpensLocal = response.data.tueOpens;
            let wedOpensLocal = response.data.wedOpens;
            let thuOpensLocal = response.data.thuOpens;
            let friOpensLocal = response.data.friOpens;
            let satOpensLocal = response.data.satOpens;
            let sunOpensLocal = response.data.sunOpens;

            if (state.singleDay) {
              if (state.singleDay === 0) {
                sunCostLocal = response.data.sunCost || 0;
                sunOpensLocal = response.data.sunOpens;
              }

              if (state.singleDay === 1) {
                monCostLocal = response.data.monCost || 0;
                monOpensLocal = response.data.monOpens;
              }

              if (state.singleDay === 2) {
                tueCostLocal = response.data.tueCost || 0;
                tueOpensLocal = response.data.tueOpens;
              }

              if (state.singleDay === 3) {
                wedCostLocal = response.data.wedCost || 0;
                wedOpensLocal = response.data.wedOpens;
              }

              if (state.singleDay === 4) {
                thuCostLocal = response.data.thuCost || 0;
                thuOpensLocal = response.data.thuOpens;
              }

              if (state.singleDay === 5) {
                friCostLocal = response.data.friCost || 0;
                friOpensLocal = response.data.friOpens;
              }

              if (state.singleDay === 6) {
                satCostLocal = response.data.satCost || 0;
                satOpensLocal = response.data.satOpens;
              }

              if (state.singleDay2) {
                // - - -
                if (state.singleDay2 === 0) {
                  sunCostLocal = response.data.sunCost || 0;
                  sunOpensLocal = response.data.sunOpens;
                }

                if (state.singleDay2 === 1) {
                  monCostLocal = response.data.monCost || 0;
                  monOpensLocal = response.data.monOpens;
                }

                if (state.singleDay2 === 2) {
                  tueCostLocal = response.data.tueCost || 0;
                  tueOpensLocal = response.data.tueOpens;
                }

                if (state.singleDay2 === 3) {
                  wedCostLocal = response.data.wedCost || 0;
                  wedOpensLocal = response.data.wedOpens;
                }

                if (state.singleDay2 === 4) {
                  thuCostLocal = response.data.thuCost || 0;
                  thuOpensLocal = response.data.thuOpens;
                }

                if (state.singleDay2 === 5) {
                  friCostLocal = response.data.friCost || 0;
                  friOpensLocal = response.data.friOpens;
                }

                if (state.singleDay2 === 6) {
                  satCostLocal = response.data.satCost || 0;
                  satOpensLocal = response.data.satOpens;
                }
              }
            }

            let availDataLocal = response.data.availData;

            if (state.singleDay) {
              let newAvailData = [];
              state.availData.forEach((obj) => {
                if (obj.ds !== state.singleDay) {
                  newAvailData.push(obj);
                }
              });

              response.data.availData.forEach((obj) => {
                if (state.singleDay === obj.ds) {
                  newAvailData.push(obj);
                }
              });

              availDataLocal = newAvailData;
            }

            if (response.data.showTip && !memoVals.mob && !state.firstLoaded) {
              setTimeout(() => {
                setShowTip(true);
                setTimeout(() => {
                  setShowTip(false);
                }, 30000);
              }, 3000);
            }

            memoVals.setUpdateSched((x) => false);
            setDataLoaded(true);

            setState({
              userIDsThatUserManages: response.data.userIDsThatUserManages,
              isAdmin: response.data.isAdmin,
              firstLoaded: true,
              specDays: response.data.specialPublicHolData,
              hideSchedLimits: response.data.hideLimits,
              availData: availDataLocal,
              sched: schedLocal,
              secondaryUserIDs: response.data.secondaryUserIDs,
              daysArr: [
                response.data.mon,
                response.data.tue,
                response.data.wed,
                response.data.thu,
                response.data.fri,
                response.data.sat,
                response.data.sun,
              ],
              monCost: monCostLocal,
              monOpens: monOpensLocal,
              tueCost: tueCostLocal,
              tueOpens: tueOpensLocal,
              wedCost: wedCostLocal,
              wedOpens: wedOpensLocal,
              thuCost: thuCostLocal,
              thuOpens: thuOpensLocal,
              friCost: friCostLocal,
              friOpens: friOpensLocal,
              satCost: satCostLocal,
              satOpens: satOpensLocal,
              sunCost: sunCostLocal,
              sunOpens: sunOpensLocal,
              closedDayNums: response.data.closedDayNums,
              jobTitles: response.data.jobTitles,
              loadingMove: false,
              singleDay: "",
              singleDay2: "",
              loading: false,
              limLoad: true,
              availLoading: false,
              pasteLoading: false,

              fadeShiftID: "",

              monCommentsQty: response.data.monCommentsQty,
              tueCommentsQty: response.data.tueCommentsQty,
              wedCommentsQty: response.data.wedCommentsQty,
              thuCommentsQty: response.data.thuCommentsQty,
              friCommentsQty: response.data.friCommentsQty,
              satCommentsQty: response.data.satCommentsQty,
              sunCommentsQty: response.data.sunCommentsQty,
            });

            if (selectedMonWeek && selectedTeam && !locFilt2[0]) {
              axios
                .post(
                  `${serverURL}/get-team-schedule-limit-all-data`,
                  {
                    teamID: selectedTeam,
                    monWeek: selectedMonWeek,
                    cancelToken: cancelSource2.token,
                  },
                  {
                    withCredentials: true,
                    credentials: "include",
                  }
                )
                .then((response2) => {
                  if (response2.data.message === "success") {
                    setState({
                      limLoad: false,

                      dayLimitData: response2.data.output,
                      jtLimitData: response2.data.jtSchedLimitData,
                      initialLimitsGot: true,
                      loadingLimits: false,
                    });

                    setJtsThatArePrimaryTeamedWithThisTeam(
                      response2.data.jtsThatArePrimaryTeamedWithThisTeam || []
                    );
                    // setLoading(false);
                  }
                })
                .catch((err) => {
                  console.error(err);
                });
            }

            memoVals.setLoadSingleUserSched((x) => "");
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    return () => {
      cancelSource1.cancel("Component unmounted");
      cancelSource2.cancel("Component unmounted");
    };
  }, [
    memoVals.updateInfo,
    selectedTeam,
    selectedMonWeek,
    memoVals.openShiftEdited,
  ]);

  useEffect(() => {
    if (!weekHasNoData) {
      let counter = 0;
      state.sched.forEach((user) => {
        user.monItems.forEach((item) => {
          counter++;
        });
        user.tueItems.forEach((item) => {
          counter++;
        });
        user.wedItems.forEach((item) => {
          counter++;
        });
        user.thuItems.forEach((item) => {
          counter++;
        });
        user.friItems.forEach((item) => {
          counter++;
        });
        user.satItems.forEach((item) => {
          counter++;
        });
        user.sunItems.forEach((item) => {
          counter++;
        });
      });

      if (counter === 0) {
        memoVals.setUpdateInfo((x) => !memoVals.updateInfo);
      }
    }
  }, [state.sched]);

  useEffect(() => {
    // clean not needed

    if (memoVals.updateSched) {
      memoVals.setUpdateInfo((x) => !memoVals.updateInfo);
    }
  }, [memoVals.showEditUser]);

  useEffect(() => {
    // clean not needed

    if (memoVals.addShiftTilPassBackToTeamSched) {
      let min = dateStringer.createTimestampFromString(selectedMonWeek);
      let maxObj = new Date(min);
      maxObj.setDate(maxObj.getDate() + 7);
      maxObj.setMinutes(maxObj.getMinutes() - 1);

      let max = maxObj.getTime();
      let dsToTs = dateStringer.createTimestampFromString(
        memoVals.addShiftTilPassBackToTeamSched
      );
      if (dsToTs >= min && dsToTs <= max) {
        memoVals.setUpdateInfo((x) => !memoVals.updateInfo);
      }
      memoVals.setAddShiftTilPassBackToTeamSched((x) => "");
    }
  }, [memoVals.addShiftTilPassBackToTeamSched]);
  const cancelSource3 = axios.CancelToken.source();

  useEffect(() => {
    if (state.initialLimitsGot) {
      setState({ limLoad: true });
      if (selectedMonWeek && selectedTeam) {
        axios
          .post(
            `${serverURL}/get-team-schedule-limit-all-data`,
            {
              teamID: selectedTeam,
              monWeek: selectedMonWeek,
            },
            {
              withCredentials: true,
              credentials: "include",
              cancelToken: cancelSource3.token,
            }
          )
          .then((response2) => {
            if (response2.data.message === "success") {
              setState({
                dayLimitData: response2.data.output,
                jtLimitData: response2.data.jtSchedLimitData,
                pasteLoading: false,
                fadeShiftID: "",
                limLoad: false,
              });
              setJtsThatArePrimaryTeamedWithThisTeam(
                response2.data.jtsThatArePrimaryTeamedWithThisTeam || []
              );
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
    return () => {
      cancelSource3.cancel("Component unmounted");
    };
  }, [memoVals.updateLimits]);

  let [sureDelete, setSureDelete] = useState("");

  const cancelSource4 = axios.CancelToken.source();
  const cancelSource5 = axios.CancelToken.source();
  const cancelSource6 = axios.CancelToken.source();
  useEffect(() => {
    axios
      .post(
        `${serverURL}/get-teams-user-manages`,
        { someData: 1 },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource4.token,
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          // if (!response.data.array || response.data.array.length === 0) {
          //   axios
          //     .post(
          //       `${serverURL}/get-individuals-user-manages`,
          //       {
          //         someData: 1,
          //       },

          //       {
          //         withCredentials: true,
          //         credentials: "include",
          //         cancelToken: cancelSource5.token,
          //       }
          //     )
          //     .then((response) => {
          //       if (response.data.message === "success") {
          //         setIndividuals(response.data.usersArr);
          //       }
          //     })
          //     .catch((err) => {
          //       console.error(err);
          //     });
          //   setShowIndiOnlymodal(true);
          // }

          setMgrTeams(response.data.array);
          const urlSearchParams = new URLSearchParams(window.location.search);
          const params = Object.fromEntries(urlSearchParams.entries());

          if (dateStringer.cleanParam(params.editUser)) {
            memoVals.setShowEditUser((x) =>
              dateStringer.cleanParam(params.editUser)
            );
          }
          if (params && params.load) {
            if (dateStringer.cleanParam(params.teamID)) {
              setSelectedTeam(dateStringer.cleanParam(params.teamID));
            }

            if (
              dateStringer.cleanParam(params.comments) &&
              dateStringer.cleanParam(params.ds)
            ) {
              console.log("COMMENTS TO SHOW!!! ");
              memoVals.setShowDayCommentModal((x) => {
                return {
                  show: true,
                  isMgr: true,
                  teamID: dateStringer.cleanParam(params.teamID),
                  ds: dateStringer.cleanParam(params.ds),
                };
              });
            }

            if (params.openID) {
              memoVals.setShowOpenShiftModal((x) => {
                return {
                  ds: dateStringer.cleanParam(params.startDs),
                  openID: dateStringer.cleanParam(params.openID),
                  new: false,
                  teamID: dateStringer.cleanParam(params.teamID),
                };
              });
            }
          } else {
            if (response.data.array[0]) {
              setSelectedTeam(response.data.array[0].teamID);
            }
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });

    axios
      .post(
        `${serverURL}/get-week-starts-for-schedule`,
        { someData: 1 },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource6.token,
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          let arr = [];
          let firstDateObj = new Date(
            dateStringer.createTimestampFromString(response.data.firstDs)
          );
          let lastDateObj = new Date(
            dateStringer.createTimestampFromString(response.data.lastDs)
          );
          let weeks = Math.round(
            (lastDateObj.getTime() - firstDateObj.getTime()) /
              1000 /
              60 /
              60 /
              24 /
              7
          );

          for (var i = 0; i < weeks; i++) {
            arr.push(
              dateStringer.createStringFromTimestamp(firstDateObj.getTime())
            );
            firstDateObj.setHours(0, 0, 0, 0);
            firstDateObj.setDate(firstDateObj.getDate() + 7);
          }

          setMonWeeks(arr);

          const urlSearchParams = new URLSearchParams(window.location.search);
          const params = Object.fromEntries(urlSearchParams.entries());
          if (params && params.load) {
            if (params.monweek) {
              setSelectedMonWeek(
                dateStringer.getMonWeekOfDs(
                  dateStringer.cleanParam(params.monweek)
                )
              );
            }
            if (params.showAccepted) {
              memoVals.setShowMgrAcceptanceModal((x) => true);
            }
            if (
              params.showAvailabilityUserID &&
              params.teamID &&
              params.monweek
            ) {
              memoVals.setShowMgrAvailModal((x) => {
                return {
                  team: dateStringer.cleanParam(params.teamID),
                  ds: dateStringer.cleanParam(params.monweek),
                  userID: dateStringer.cleanParam(
                    params.showAvailabilityUserID
                  ),
                  isMgr: true,
                };
              });
            }
          } else {
            setSelectedMonWeek(
              dateStringer.getMonWeekOfDs(response.data.current)
            );
          }

          setState({ availLoading: true, maxTs: response.data.maxTs });
          setCurrentMw(response.data.current);
          setCurrMonWeek(response.data.current);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      cancelSource4.cancel("Component unmounted");
      cancelSource5.cancel("Component unmounted");
      cancelSource6.cancel("Component unmounted");
    };
  }, []);

  let [showTilOrShiftPopUp, setShowTilOrShiftPopUp] = useState(false);

  // let [itemFName, setItemFName] = useState("");
  // let [itemLName, setItemLName] = useState("");

  let [typeIDsToHide, setTypeIDsToHide] = useState([]);
  // let [showIndiOnlyModal, setShowIndiOnlymodal] = useState(false);

  let getAvail = (userID, ds, dontGetNwDayX) => {
    if (userID && ds) {
      let dateObj = new Date(dateStringer.createTimestampFromString(ds));

      let dayN = dateObj.getDay();

      let userAvail = state.availData[0]
        ? state.availData.filter((item) => {
            return item.userID === userID;
          })
        : [];

      let userAvailObj = userAvail.filter((item) => {
        return item.ds === dayN;
      });
      // if(userAvailObj[])
      if (userAvailObj[0]) {
        if (userAvailObj[0].data.string2) {
          return `${userAvailObj[0].data.string1}\u00A0\u00A0\u00A0\u00A0+\u00A0\u00A0\u00A0\u00A0${userAvailObj[0].data.string2}`;
        } else {
          if (userAvailObj[0].data.string1) {
            return `${userAvailObj[0].data.string1}`;
          } else {
            return;
          }
        }
      } else {
        // check if non-working day
        let userFilt = state.sched.filter((item) => {
          return item.userID === userID;
        });
        if (userFilt[0] && userFilt[0].nonWorkingDays) {
          if (userFilt[0].nonWorkingDays.includes(dayN)) {
            let today00 = new Date();
            today00.setHours(0, 0, 0, 0);
            if (
              dateStringer.createTimestampFromString(ds) >= today00.getTime() &&
              !dontGetNwDayX
            ) {
              return "Non-working day";
            } else {
              return dontGetNwDayX ? "" : "Non-working dayX";
            }
          }
        }
      }
      return;
      // return userAvailObj[0] && userAvailObj[0].data;
    }
  };

  let doesUserContainAnyBlankItems = (userID) => {
    let dsArr = state.daysArr;

    let blanks = 0;

    dsArr.forEach((x) => {
      let availData = getAvail(userID, x, true);
      if (availData) {
        blanks++;
      }
    });

    if (blanks > 0) {
      return true;
    }
  };

  const targetRef = useRef(null);
  const containerRef = useRef(null);
  let nullRef = useRef();

  const handleScroll = () => {
    let todayDay = new Date();
    if (todayDay.getDay() !== 1) {
      setTimeout(() => {
        if (
          containerRef.current &&
          targetRef.current &&
          currMonWeek === selectedMonWeek
        ) {
          const scrollTop = containerRef.current.scrollLeft;
          const targetOffset = targetRef.current.offsetLeft;
          const position = targetOffset - scrollTop;
          containerRef.current.scrollLeft =
            position - (memoVals.mob ? 105 : 305);
        } else {
          // containerRef.current.scrollLeft = 0;
        }
      }, 250);
    }
  };

  useEffect(() => {
    // clean not needed
    if (!loading) {
      handleScroll();
    }
  }, [loading]);

  let getTeamNameFromTeamID = (teamID) => {
    let filt = mgrTeams.filter((item) => item.teamID === teamID);
    return filt[0].teamName;
  };

  const mergeAndSort = (teams, locs) => {
    // Add locationTitle object if locations.length > 0
    // if (locations.length > 0) {
    //   locations.unshift({ locationTitle: true });
    // }

    // Sort mgrTeams: primary first, then alphabetically by teamName
    const sortedMgrTeams = teams.sort((a, b) => {
      if (a.primary && !b.primary) return -1;
      if (!a.primary && b.primary) return 1;
      if (a.teamName && b.teamName) {
        return a.teamName.localeCompare(b.teamName);
      }
    });

    // Sort locations alphabetically by locationName
    const sortedLocations = locs.sort((a, b) => {
      // Skip the locationTitle object during sorting
      // if (a.locationTitle) return -1;
      // if (b.locationTitle) return 1;

      if (a.locationName && b.locationName) {
        return a.locationName.localeCompare(b.locationName);
      }
    });

    // Combine sorted arrays
    if (sortedLocations.length > 0) {
      sortedMgrTeams.push({ gap: true });
      sortedMgrTeams.push({ locationTitle: true });
    }
    return [...sortedMgrTeams, ...sortedLocations];
  };
  let teamMap =
    breakTeams || memoVals.mob
      ? mergeAndSort(mgrTeams, locations)
      : mgrTeams.sort((a, b) => {
          // Sort by 'primary' in descending order (true first)
          if (a.primary && !b.primary) {
            return -1;
          }
          if (!a.primary && b.primary) {
            return 1;
          }

          // If 'primary' values are the same, sort alphabetically by 'teamName'
          return a.teamName.localeCompare(b.teamName);
        });

  // console.log({ teamMap });

  let generateTeamsSelectBar = teamMap.map((team, i) => {
    if (memoVals.mob || breakTeams) {
      if (team.teamID) {
        return (
          <option key={team.teamID} value={team.teamID}>
            {shortenTeamNameForMobOnly(team.teamName || "")}
          </option>
        );
      }
      if (team.gap) {
        let firstGap = teamMap.findIndex((x) => {
          return x.gap;
        });
        if (i <= firstGap) {
          return <option key="x3849842" value=""></option>;
        }
      }
      if (team.locationTitle) {
        let firstInstance = teamMap.findIndex((x) => {
          return x.locationTitle;
        });
        if (i <= firstInstance) {
          return (
            <option key="x35773" value="">
              -- Locations
            </option>
          );
        }
      }
      if (team.locationID) {
        return (
          <option key={team.locationID} value={team.locationID}>
            {shortenTeamNameForMobOnly(team.locationName || "")}
          </option>
        );
      }
    } else {
      return (
        <Fragment key={team.teamID}>
          {" "}
          <div
            onClick={() => {
              // if (!loading) {
              if (team.teamID) {
                if (selectedTeam !== team.teamID) {
                  setSelectedTeam(team.teamID);
                  //
                  setState({ loading: true });
                  memoVals.setUpdateShifts((x) => !memoVals.updateShifts);
                  setSelectedHorizAdd("");
                  setSelectedHorizAddShift("");
                  setShowTools(false);
                }
              }
              // if (team.locationID) {
              //   if (locationID !== team.locationID) {
              //     setSelectedTeam("");
              //     console.log("Selecting locID: ", team.locationID);
              //     //
              //     setState({ loading: true });
              //     memoVals.setUpdateShifts((x) => !memoVals.updateShifts);
              //     setSelectedHorizAdd("");
              //     setSelectedHorizAddShift("");
              //     setShowTools(false);
              //   }
              // }

              // }

              // axios request to get team shifts
            }}
            className={`teamNameAbsence ${
              selectedTeam === team.teamID ? "absenceTeamSelected" : ""
            } `}
          >
            {team.teamName}
          </div>
          {i !== mgrTeams.length - 1 ? (
            <div className="subHeadSplit inlineTeamSplit"></div>
          ) : (
            ""
          )}
        </Fragment>
      );
    }
  });

  let generateMonWeeks = useMemo(() => {
    return monWeeks.map((monWeek) => {
      let dateObj;
      let string;
      if (window.innerWidth > 1072) {
        dateObj = new Date(dateStringer.createTimestampFromString(monWeek));
        dateObj.setDate(dateObj.getDate() + 6);
        dateObj.setHours(0, 0, 0, 0);
        // ds, withYear, full, suffix
        string = `${dateStringer.printedDateFromDs(
          monWeek,
          false,
          false,
          false
        )} - ${dateStringer.printedDateFromDs(
          dateStringer.createStringFromTimestamp(dateObj.getTime()),
          true,
          false,
          false,
          true
        )}`;
      }

      if (window.innerWidth > 560 && window.innerWidth <= 1072) {
        dateObj = new Date(dateStringer.createTimestampFromString(monWeek));
        dateObj.setDate(dateObj.getDate() + 6);
        dateObj.setHours(0, 0, 0, 0);
        // ds, withYear, full, suffix
        string = `${dateStringer.printedDateFromDs(
          monWeek,
          false,
          false,
          false
        )} - ${dateStringer.printedDateFromDs(
          dateStringer.createStringFromTimestamp(dateObj.getTime()),
          true,
          false,
          false,
          true
        )}`;
      }
      return (
        <option value={monWeek} key={monWeek}>
          {window.innerWidth > 1072 ||
          (window.innerWidth > 560 &&
            window.innerWidth <= 1072 &&
            (window.innerWidth < 820 || window.innerWidth > 890))
            ? string
            : `${
                (window.innerWidth <= 560 && window.innerWidth > 423) ||
                (window.innerWidth >= 820 && window.innerWidth <= 890)
                  ? `Mon ${dateStringer.printedDateWithYrFromDWithoutDay(
                      monWeek
                    )}`
                  : dateStringer.printedDateWithYrFromDWithoutDay(monWeek)
              }`}
        </option>
      );

      // return (
      //   <option value={monWeek} key={monWeek}>
      //     {dateStringer.printedDateWithYrFromDWithoutDay(monWeek)}
      //   </option>
      // );
    });
  }, [monWeeks, window.innerWidth]);

  // ------------------------------------------------------------------------
  let [itemType, setItemType] = useState("");
  let [itemID, setItemID] = useState("");

  let [itemIsNew, setItemIsNew] = useState(false);

  let [showTools, setShowTools] = useState(false);

  useEffect(() => {
    if (memoVals.showNotifications) {
      if (showTools) {
        setShowTools(false);
      }
    }
    if (memoVals.showMessages) {
      if (showTools) {
        setShowTools(false);
      }
    }
    if (memoVals.showMobMenu) {
      if (showTools) {
        setShowTools(false);
      }
    }
  }, [memoVals.showNotifications, memoVals.showMessages, memoVals.showMobMenu]);
  // ------------------------------------------------------------------------

  // let renderOpenShiftModal = () => {
  //   if (showOpenShiftModal.ds) {
  //     return (
  //       <OpenMgrShiftModal
  //         unitDs={showOpenShiftModal.ds}
  //         unitTeamID={selectedTeam}
  //         unitItemIsNew={showOpenShiftModal.new}
  //         unitOpenID={showOpenShiftModal.openID || itemID}
  //       />
  //     );
  //   }
  // };

  let [itemUserID, setItemUserID] = useState("");
  // let renderEditShiftModal = () => {
  //   if (showEditShiftModal && !showEditShiftModal.itemID) {
  //     return (
  //       <ManagerShiftModal
  //         getFromServerID={itemID}
  //         unitType={itemType}
  //         unitFName={itemFName}
  //         unitLName={itemLName}
  //         unitIsNew={itemIsNew}
  //         unitUserID={itemUserID}
  //       />
  //     );
  //   }
  // };

  let renderSaveTemplateModal = () => {
    if (saveTemplateObj.teamID || saveTemplateObj.locationID) {
      return (
        <SaveTemplateModal
          saveTemplateObj={saveTemplateObj}
          setSaveTemplateObj={setSaveTemplateObj}
          updateInfo={memoVals.updateInfo}
          setUpdateInfo={memoVals.setUpdateInfo}
          mobModal={memoVals.mobModal}
        />
      );
    }
  };

  let [selectedHorizAdd, setSelectedHorizAdd] = useState("");
  let [selectedHorizAddShift, setSelectedHorizAddShift] = useState("");
  let [selectedAddShiftEmpty, setSelectedAddShiftEmpty] = useState("");

  let [collapse, setCollapse] = useState(false);
  // ------------

  // ------------------------------------------------------------------------

  let availIsProvidedForOneOfUsersDays = (userID, ignoreNonWorkingDays) => {
    let days = [0, 1, 2, 3, 4, 5, 6];
    let count = 0;

    let dsArr = state.daysArr;

    days.forEach((d) => {
      let isAvail = getAvail(userID, dsArr[d], ignoreNonWorkingDays ? true : d);

      if (isAvail && isAvail[0] && !isAvail[0].includes("X")) {
        count++;
      } else {
      }
    });

    return count;
  };
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  // - - - - - - - -
  let publishLocally = (unpubOrPub) => {
    let pub = unpubOrPub === "publish" ? true : false;

    let newArr = [];

    state.sched.forEach((usr) => {
      let newMon = [];
      let newTue = [];
      let newWed = [];
      let newThu = [];
      let newFri = [];
      let newSat = [];
      let newSun = [];
      usr.monItems.forEach((item) => {
        item.published = item.tsClock ? item.published : pub ? true : false;
        newMon.push(item);
      });
      usr.tueItems.forEach((item) => {
        item.published = item.tsClock ? item.published : pub ? true : false;
        newTue.push(item);
      });
      usr.wedItems.forEach((item) => {
        item.published = item.tsClock ? item.published : pub ? true : false;
        newWed.push(item);
      });
      usr.thuItems.forEach((item) => {
        item.published = item.tsClock ? item.published : pub ? true : false;
        newThu.push(item);
      });
      usr.friItems.forEach((item) => {
        item.published = item.tsClock ? item.published : pub ? true : false;
        newFri.push(item);
      });
      usr.satItems.forEach((item) => {
        item.published = item.tsClock ? item.published : pub ? true : false;
        newSat.push(item);
      });
      usr.sunItems.forEach((item) => {
        item.published = item.tsClock ? item.published : pub ? true : false;
        newSun.push(item);
      });
      let userCost = usr.cost;
      let primaryOrder = usr.primaryOrder;
      let userID = usr.userID;
      let fName = usr.fName;
      let lName = usr.lName;
      let jobTitle = usr.jobTitle;
      let profPicUrl = usr.profPicUrl;
      let monItems = newMon;
      let tueItems = newTue;
      let wedItems = newWed;
      let thuItems = newThu;
      let friItems = newFri;
      let satItems = newSat;
      let sunItems = newSun;

      newArr.push({
        cost: usr.cost,
        primaryOrder,
        userID,
        fName,
        lName,
        jobTitle,
        monItems,
        tueItems,
        wedItems,
        thuItems,
        friItems,
        satItems,
        sunItems,
        profPicUrl,
        avgContractedMpw: usr.avgContractedMpw,
        nonWorkingDays: usr.nonWorkingDays,
        primaryTeamID: usr.primaryTeamID,
        managesPrimaryTeam: usr.managesPrimaryTeam,
        mins: usr.mins,
        primaryTeamName: usr.primaryTeamName,
      });
    });

    setState({ sched: newArr });
  };

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  let nowDateObj = new Date();
  let isUserActive = (userID) => {
    let active = false;

    let userObjArrItem = state.sched.filter((item) => {
      return item.userID === userID;
    });

    let itemsArr = userObjArrItem[0].sunItems;
    if (nowDateObj.getDay() === 1) {
      itemsArr = userObjArrItem[0].monItems;
    }
    if (nowDateObj.getDay() === 2) {
      itemsArr = userObjArrItem[0].tueItems;
    }
    if (nowDateObj.getDay() === 3) {
      itemsArr = userObjArrItem[0].wedItems;
    }
    if (nowDateObj.getDay() === 4) {
      itemsArr = userObjArrItem[0].thuItems;
    }
    if (nowDateObj.getDay() === 5) {
      itemsArr = userObjArrItem[0].friItems;
    }
    if (nowDateObj.getDay() === 6) {
      itemsArr = userObjArrItem[0].satItems;
    }

    itemsArr.forEach((item) => {
      if (item.type !== "leave" && item.published) {
        let startTs = item.startTs;
        let endObj = new Date(dateStringer.createTimestampFromString(item.end));
        if (endObj.getTime() <= startTs) {
          endObj.setDate(endObj.getDate() + 1);
        }
        let endTs = endObj.getTime();
        if (nowDateObj.getTime() >= startTs && nowDateObj.getTime() < endTs) {
          active = true;
        }
      }
    });

    return active;
  };

  let limitColour = (ds, jt) => {
    let isJt = false;
    if (ds && state.dayLimitData.mon) {
      let dsObj = new Date(dateStringer.createTimestampFromString(ds));
      let dayN = dsObj.getDay();
      if (dayN === 0) {
        if (
          state.dayLimitData.sun.enable &&
          state.dayLimitData.sun.limitByJobTitle
        ) {
          isJt = true;
        }
      }
      if (dayN === 1) {
        if (
          state.dayLimitData.mon.enable &&
          state.dayLimitData.mon.limitByJobTitle
        ) {
          isJt = true;
        }
      }
      if (dayN === 2) {
        if (
          state.dayLimitData.tue.enable &&
          state.dayLimitData.tue.limitByJobTitle
        ) {
          isJt = true;
        }
      }
      if (dayN === 3) {
        if (
          state.dayLimitData.wed.enable &&
          state.dayLimitData.wed.limitByJobTitle
        ) {
          isJt = true;
        }
      }
      if (dayN === 4) {
        if (
          state.dayLimitData.thu.enable &&
          state.dayLimitData.thu.limitByJobTitle
        ) {
          isJt = true;
        }
      }
      if (dayN === 5) {
        if (
          state.dayLimitData.fri.enable &&
          state.dayLimitData.fri.limitByJobTitle
        ) {
          isJt = true;
        }
      }
      if (dayN === 6) {
        if (
          state.dayLimitData.sat.enable &&
          state.dayLimitData.sat.limitByJobTitle
        ) {
          isJt = true;
        }
      }
    }

    if (isJt) {
      let filt = state.jtLimitData.filter(
        (item) => item.ds === ds && item.jt === jt
      );

      if (filt[0] && filt[0].active) {
        if (filt[0].currOff > filt[0].maxOff) {
          return true;
        }
      }
    }
  };

  // let calcUserMins = (userID, shorten, getMinsOnly, pTeamID) => {
  //   let totMins = 0;
  //   let userObjArr = state.sched.filter((item) => item.userID === userID);
  //   let itemsArr = [
  //     ...userObjArr[0].monItems,
  //     ...userObjArr[0].tueItems,
  //     ...userObjArr[0].wedItems,
  //     ...userObjArr[0].thuItems,
  //     ...userObjArr[0].friItems,
  //     ...userObjArr[0].satItems,
  //     ...userObjArr[0].sunItems,
  //   ];
  //   itemsArr.forEach((item) => {
  //     // if ((item.reqID && pTeamID === selectedTeam) || !item.reqID) {
  //     if (item.teamID === selectedTeam) {
  //       if (item.duration) {
  //         totMins += item.duration / 1000 / 60;
  //       }

  //       if (item.durMins) {
  //         totMins += item.durMins;
  //       }
  //     }
  //     // }
  //   });

  //   if (getMinsOnly) {
  //     return totMins;
  //   } else {
  //     return dateStringer.formatMinsDurationToHours(totMins, shorten);
  //   }
  // };

  // -- - - - - - - - - - -
  let isPublishedMix = (arr) => {
    let publishedCount = 0;
    let unpublishedCount = 0;

    arr.forEach((usr) => {
      usr.sunItems.forEach((itm) => {
        if (itm.type !== "leave") {
          if (itm.published) {
            publishedCount++;
          } else {
            unpublishedCount++;
          }
        }
      });
      usr.monItems.forEach((itm) => {
        if (itm.type !== "leave") {
          if (itm.published) {
            publishedCount++;
          } else {
            unpublishedCount++;
          }
        }
      });
      usr.tueItems.forEach((itm) => {
        if (itm.type !== "leave") {
          if (itm.published) {
            publishedCount++;
          } else {
            unpublishedCount++;
          }
        }
      });
      usr.wedItems.forEach((itm) => {
        if (itm.type !== "leave") {
          if (itm.published) {
            publishedCount++;
          } else {
            unpublishedCount++;
          }
        }
      });
      usr.thuItems.forEach((itm) => {
        if (itm.type !== "leave") {
          if (itm.published) {
            publishedCount++;
          } else {
            unpublishedCount++;
          }
        }
      });
      usr.friItems.forEach((itm) => {
        if (itm.type !== "leave") {
          if (itm.published) {
            publishedCount++;
          } else {
            unpublishedCount++;
          }
        }
      });
      usr.satItems.forEach((itm) => {
        if (itm.type !== "leave") {
          if (itm.published) {
            publishedCount++;
          } else {
            unpublishedCount++;
          }
        }
      });
    });

    if (unpublishedCount !== publishedCount || publishedCount > 0) {
      return { published: publishedCount, unpublished: unpublishedCount };
    } else {
      return false;
    }
  };

  let shortenJt = (jt) => {
    if (jt && jt.length > 28) {
      return `${jt.substr(0, 23)}...`;
    } else {
      return jt;
    }
  };
  let generateNames = state.jobTitles.map((jt) => {
    let users = [];
    state.sched.forEach((usr) => {
      if (usr.jobTitle === jt) {
        users.push(usr);
      }
    });

    // users.sort(function (a, b) {
    //   return a.fName.localeCompare(b.fName);
    // });

    users.sort((a, b) => b.primaryOrder - a.primaryOrder);

    let primaryUsers = [];
    let secondaryUsers = [];

    users.forEach((u) => {
      if (!state.secondaryUserIDs.includes(u.userID)) {
        primaryUsers.push(u);
      } else {
        secondaryUsers.push(u);
      }
    });
    primaryUsers.sort(function (a, b) {
      return a.fName.localeCompare(b.fName);
    });

    secondaryUsers.sort(function (a, b) {
      return a.fName.localeCompare(b.fName);
    });

    let generateJtPrimaryUsers = primaryUsers.map((usr) => {
      return (
        <div
          key={usr.userID}
          className={`jtUserWrap ${collapsedJts.includes(jt) ? "none" : ""}`}
        >
          <div
            onClick={() => {
              if (
                state.userIDsThatUserManages.includes(usr.userID) ||
                state.isAdmin
              ) {
                memoVals.setShowEditUser((x) => usr.userID);
              } else {
                setNoPermissions(true);
              }
            }}
            className={`jtUser  ${memoVals.mob ? "font10" : "jtUserDesktop"} ${
              memoVals.mob && collapse ? "minHeight102" : ""
            } ${
              availIsProvidedForOneOfUsersDays(usr.userID) &&
              !state.pasteLoading &&
              !state.availLoading &&
              !state.loadingMove
                ? "jtUserWithAvail"
                : "jtUserWithAvail"
            } ${
              state.secondaryUserIDs.includes(usr.userID)
                ? `secondaryUserToTheTeam`
                : ""
            }`}
            key={usr.userID}
          >
            <div className={`${collapse ? "collapseNameDiv" : "none"}`}>
              {usr.fName[0]}
              {usr.lName[0]}
            </div>
            <div className={`${collapse ? "none" : "jtUserLeft"}`}>
              <div
                className={`${
                  !memoVals.mob || collapse || currMonWeek !== selectedMonWeek
                    ? "none"
                    : `${
                        isUserActive(usr.userID)
                          ? "userLightBarOn"
                          : "lightBarOffOff"
                      }`
                }`}
              ></div>
            </div>
            {!memoVals.mob && !collapse ? (
              <img
                src={usr.profPicUrl}
                alt={usr.fName}
                className={`jtUserProfilePicImg x9101 ${
                  isUserActive(usr.userID) &&
                  !collapse &&
                  currMonWeek === selectedMonWeek
                    ? "desktopUserIsWorkingNowLight"
                    : ""
                } ${usr.primaryTeamID !== selectedTeam ? "nonPrimProPic" : ""}`}
              />
            ) : (
              ""
            )}

            <div
              className={`${
                collapse
                  ? "none"
                  : `jtUserRight ${!memoVals.mob ? "width87" : ""}`
              } ${
                usr.fName.length > 13 || usr.lName.length > 13 ? "name-s" : ""
              } ${usr.primaryTeamID !== selectedTeam ? "colourBBCADA" : ""}`}
            >
              {usr.fName}
              <br />
              {usr.lName.length > 11
                ? `${usr.lName.substr(0, 10)}...`
                : usr.lName}
              <br />
              <div
                className={`costAndHoursP ${
                  state.secondaryUserIDs.includes(usr.userID)
                    ? "costAndHoursPOnNonPrimary"
                    : ""
                } ${memoVals.mob ? "noPaddingRight" : ""}`}
              >
                <p
                  className={`${
                    calcUserData(usr.userID).cost
                      ? // calcUserCost(usr.userID)
                        "jtUserCostSpan"
                      : "jtUserCostSpan"
                  }`}
                >
                  {memoVals.currencySymbol}
                  {Math.round(calcUserData(usr.userID).cost || 0)}

                  {/* {Math.round(calcUserCost(usr.userID))} */}
                </p>
                <p
                  className={`userHoursP ${
                    memoVals.mob ? "userHoursPMob" : ""
                  } ${
                    calcUserData(usr.userID).mins >=
                    // calcUserMins(usr.userID, false, true, usr.primaryTeamID) >=
                    usr.avgContractedMpw
                      ? "contractedGreen"
                      : ""
                  }
                   ${
                     calcUserData(usr.userID).mins ===
                     //  calcUserMins(
                     //    usr.userID,
                     //    false,
                     //    true,
                     //    usr.primaryTeamID
                     //  )
                     //  (usr.mins || 0)
                     0
                       ? "zeroHoursContracted"
                       : ""
                   } ${
                    dateStringer.formatMinsDurationToHours(
                      calcUserData(usr.userID).mins || 0
                    ).length > 7 && memoVals.mob
                      ? "fontSize7"
                      : ""
                  }
                  `}
                >
                  {dateStringer.formatMinsDurationToHours(
                    calcUserData(usr.userID).mins || 0
                  )}
                  {/* {calcUserMins(
                    usr.userID,
                    JSON.stringify(Math.round(calcUserCost(usr.userID)))
                      .length > 3
                      ? true
                      : false,
                    usr.primaryTeamID
                  )} */}
                </p>
              </div>
            </div>
          </div>
          <div
            className={`${
              state.secondaryUserIDs.includes(usr.userID) && !collapse
                ? "secondaryUserDiv"
                : "none"
            }`}
          >
            Non-primary
          </div>
          <div
            className={`jtUserContractedHours  ${
              memoVals.mob ? "jtUserContractedHoursMob" : ""
            } ${
              availIsProvidedForOneOfUsersDays(usr.userID) &&
              !state.pasteLoading &&
              !state.availLoading &&
              !state.loadingMove
                ? "contractedWithAvail"
                : ""
            } ${collapse ? "invis" : ""}`}
            onClick={() => {
              memoVals.setSeeUserID((x) => {
                return { userID: usr.userID, openedFromUserRota: true };
              });
            }}
          >
            <p
              className={`jtUserContractedTitle ${
                memoVals.mob ? "jtUserContractedTitleMob" : ""
              }`}
            >
              {memoVals.mob ? "Contracted:" : "Contracted:"}
            </p>
            <p
              className={`jtUserContractedValue ${
                memoVals.mob ? "jtUserContractedValueMob" : ""
              } `}
            >
              {usr.avgContractedMpw
                ? dateStringer.formatMinsDurationToHours(usr.avgContractedMpw)
                : "None"}
            </p>
          </div>
          <div
            className={`${
              (selectedHorizAddShift.includes(jt) &&
                selectedHorizAddShift.includes(usr.userID)) ||
              (selectedAddShiftEmpty.includes(jt) &&
                selectedAddShiftEmpty.includes(usr.userID))
                ? `extendUserForAddShiftBox BBB ${
                    availIsProvidedForOneOfUsersDays(usr.userID) ? "" : `aallaa`
                  }`
                : ""
            }`}
          ></div>

          <div className="unitSplit"></div>
        </div>
      );
    });

    let generateJtSecondaryUsers = secondaryUsers.map((usr) => {
      return (
        <div
          key={usr.userID}
          className={`jtUserWrap ${collapsedJts.includes(jt) ? "none" : ""}`}
        >
          <div
            className={`jtUser ${memoVals.mob ? "font10" : "jtUserDesktop"} ${
              availIsProvidedForOneOfUsersDays(usr.userID) &&
              !state.pasteLoading &&
              !state.availLoading &&
              !state.loadingMove
                ? "jtUserWithAvail"
                : ""
            } ${
              state.secondaryUserIDs.includes(usr.userID)
                ? "secondaryUserToTheTeam"
                : ""
            } ${userHasNoItemsAtAll(usr.userID) ? "x91dd9" : ""}`}
            key={usr.userID}
            onClick={() => {
              if (
                state.userIDsThatUserManages.includes(usr.userID) ||
                state.isAdmin
              ) {
                memoVals.setShowEditUser((x) => usr.userID);
              } else {
                setNoPermissions(true);
              }
            }}
          >
            <div className={`${collapse ? "collapseNameDiv" : "none"}`}>
              {usr.fName[0]}
              {usr.lName[0]}
            </div>
            <div className={`${collapse ? "none" : "jtUserLeft"}`}>
              {memoVals.mob ? (
                <div
                  className={`${
                    !memoVals.mob || collapse || currentMw !== selectedMonWeek
                      ? "none"
                      : `${
                          isUserActive(usr.userID)
                            ? "userLightBarOn lightBarOnNonPrimary"
                            : "lightBarOffOff lightBarOnNonPrimary"
                        }`
                  }`}
                ></div>
              ) : (
                ""
              )}
            </div>
            {!memoVals.mob && !collapse ? (
              <img
                src={usr.profPicUrl}
                alt={usr.fName}
                className={`jtUserProfilePicImg x9101 ${
                  isUserActive(usr.userID) &&
                  !collapse &&
                  currMonWeek === selectedMonWeek
                    ? "desktopUserIsWorkingNowLight"
                    : ""
                } ${usr.primaryTeamID !== selectedTeam ? "nonPrimProPic" : ""}`}
              />
            ) : (
              ""
            )}
            <div
              className={`${
                collapse
                  ? "none"
                  : `jtUserRight ${!memoVals.mob ? "width87" : ""}`
              } ${
                usr.fName.length > 13 || usr.lName.length > 13 ? "name-s" : ""
              } ${usr.primaryTeamID !== selectedTeam ? "colourBBCADA" : ""}`}
            >
              {usr.fName}
              <br />
              {usr.lName.length > 11
                ? `${usr.lName.substr(0, 10)}...`
                : usr.lName}
              <br />
              <div
                className={`costAndHoursP ${
                  state.secondaryUserIDs.includes(usr.userID)
                    ? "costAndHoursPOnNonPrimary"
                    : ""
                }  ${memoVals.mob ? "noPaddingRight" : ""}`}
              >
                <p
                  className={`${
                    calcUserData(usr.userID).cost
                      ? // calcUserCost(usr.userID)
                        "jtUserCostSpan"
                      : "none"
                  }`}
                >
                  {memoVals.currencySymbol}
                  {Math.round(calcUserData(usr.userID).cost || 0)}

                  {/* {Math.round(calcUserCost(usr.userID))} */}
                </p>
                {/* <p className={`${usr.userCost ? "jtUserCostSpan" : "none"}`}>
                  {memoVals.currencySymbol}
                  
                  {Math.round(usr.userCost)}
                </p> */}
                <p
                  className={`userHoursP  ${
                    memoVals.mob ? "userHoursPMob" : ""
                  } ${
                    dateStringer.formatMinsDurationToHours(
                      calcUserData(usr.userID).mins || 0
                    ).length > 7 && memoVals.mob
                      ? "fontSize7"
                      : ""
                  }`}
                >
                  {/* {usr.totMins
                    ? dateStringer.formatMinsDurationToShortHours(usr.totMins)
                    : ""} */}
                  {dateStringer.formatMinsDurationToHours(
                    calcUserData(usr.userID).mins || 0
                  )}
                  {/* {calcUserMins(
                    usr.userID,
                    JSON.stringify(Math.round(calcUserCost(usr.userCost)))
                      .length > 3
                      ? true
                      : false,
                    usr.primaryTeamID
                  )} */}
                </p>
              </div>
            </div>
          </div>
          <div
            className={`${
              (selectedHorizAddShift.includes(jt) &&
                selectedHorizAddShift.includes(usr.userID)) ||
              (selectedAddShiftEmpty.includes(jt) &&
                selectedAddShiftEmpty.includes(usr.userID))
                ? `extendUserForAddShiftBox BBB ${
                    availIsProvidedForOneOfUsersDays(usr.userID)
                      ? ""
                      : `aallaa ${
                          usr.primaryTeamID !== selectedTeam ? "bbllbb" : ""
                        }`
                  }`
                : ""
            }`}
          ></div>
          {/* no- */}
          <div
            className={`${
              state.secondaryUserIDs.includes(usr.userID) && !collapse
                ? "secondaryUserDiv"
                : "none"
            }`}
          >
            Non-primary
          </div>
          {!state.secondaryUserIDs.includes(usr.userID) ? (
            <div
              className={`jtUserContractedHours  ${
                memoVals.mob ? "jtUserContractedHoursMob" : ""
              } jtUserContractedSecondaryOnly ${
                availIsProvidedForOneOfUsersDays(usr.userID) &&
                !state.pasteLoading &&
                !state.availLoading &&
                !state.loadingMove
                  ? "contractedWithAvail contractedWithAvailSecondaryOnly"
                  : ""
              } ${collapse ? "invis" : ""}`}
              onClick={() => {
                memoVals.setSeeUserID((x) => {
                  return { userID: usr.userID, openedFromUserRota: true };
                });
              }}
            >
              <p
                className={`jtUserContractedTitle ${
                  memoVals.mob ? "jtUserContractedTitleMob" : ""
                }`}
              >
                {memoVals.mob ? "Contracted:" : "Contracted:"}
              </p>
              <p
                className={`jtUserContractedValue ${
                  memoVals.mob ? "jtUserContractedValueMob" : ""
                }`}
              >
                {usr.avgContractedMpw
                  ? dateStringer.formatMinsDurationToHours(usr.avgContractedMpw)
                  : "None"}
              </p>
            </div>
          ) : (
            <div
              className={`jtUserContractedHours  ${
                memoVals.mob ? "jtUserContractedHoursMob" : ""
              } contractedWithAvailSecondaryOnly xtx flexStart ${
                userHasNoItemsAtAll(usr.userID) ? "x9910ss" : ""
              }`}
              onClick={() => {
                memoVals.setSeeUserID((x) => {
                  return { userID: usr.userID, openedFromUserRota: true };
                });
              }}
            >
              <p
                className={`jtUserContractedTitle ${
                  memoVals.mob ? "jtUserContractedTitleMob" : ""
                }`}
              >
                Primary:
              </p>
              <p
                className={`jtUserContractedValue ${
                  memoVals.mob ? "jtUserContractedValueMob" : ""
                } x0873`}
                onClick={() => {}}
              >
                {dateStringer.shorten(
                  usr.primaryTeamName,
                  memoVals.mob ? 16 : 13
                )}
              </p>
            </div>
          )}
          <div className="unitSplit"></div>
        </div>
      );
    });
    return (
      <div className="jobTitleUsersContainer" key={jt}>
        <div
          className={`jobTitleTitle ${collapse ? "collapsedJobTitle" : ""} ${
            collapsedJts.includes(jt) ? "collapgesJtHeight" : ""
          } ${memoVals.mob ? "mobJobTitleTitle" : ""}`}
        >
          {/* {collapse ? "" : shortenJt(jt)} */}
          {collapse ? (
            ""
          ) : (
            <div
              className="jobTitleA1box"
              onClick={() => {
                if (!collapsedJts.includes(jt)) {
                  setCollapsedJts([...collapsedJts, jt]);
                } else {
                  let newArr = [];
                  collapsedJts.forEach((x) => {
                    if (x === jt) {
                    } else {
                      newArr.push(x);
                    }
                  });
                  setCollapsedJts(newArr);
                }
              }}
            >
              {!memoVals.mob ? (
                <img
                  src={downArrNavy}
                  alt="Collapge job title"
                  className={`collapgeJt ${
                    collapsedJts.includes(jt) ? "collapgesJtArr" : ""
                  }`}
                />
              ) : (
                ""
              )}
              <p
                className={`jtDataRowTxt ${
                  dateStringer.pluraliseNoun(jt).length > 30
                    ? `jtSmall ${memoVals.mob ? "jtSmallMob" : ""}`
                    : ""
                } ${memoVals.mob ? "jtDataRowTxtMob" : ""}`}
              >
                {jt.length > 26
                  ? `${jt.substr(0, 24)}...`
                  : dateStringer.pluraliseNoun(jt)}
                {/* {dateStringer.shorten(
                  dateStringer.pluraliseNoun(jt),
                  dateStringer.pluraliseNoun(jt).length > 30 ? 28 : 100
                )} */}
              </p>
            </div>
          )}
          {jt[jt.length - 1] === "s" || (users.length && !collapse < 2)
            ? ""
            : "s"}
        </div>
        <div className="jtUsers">
          {generateJtPrimaryUsers}
          {generateJtSecondaryUsers}
        </div>
      </div>
    );
  });

  let scheduleNewShift = (type, day, userID) => {
    if (type !== "open") {
      // if (!moveUnit.itemID) {
      setCreatingShift(true);
      // }
    }
    let today = new Date();
    axios
      .post(
        `${serverURL}/schedule-new-shift`,
        {
          teamID: locationMode ? "" : selectedTeam,
          locationID: locationMode ? selectedTeam : "",
          ds: day,
          userID: userID ? userID : "",
          type: type,
          nowDs: dateStringer.createStringFromTimestamp(today.getTime()),
        },

        {
          withCredentials: true,
          credentials: "include",
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          setItemUserID(type === "open" ? "open" : userID);
          // setItemFName(response.data.fName || "");
          // setItemLName(response.data.lName || "");
          setItemType(response.data.type);
          setItemID(response.data.shiftID);
          setItemIsNew(true);
          if (type === "open") {
            memoVals.setShowOpenShiftModal((x) => {
              return {
                ds: day,
                new: true,
                openID: response.data.shiftID,
              };
            });
          } else {
            memoVals.setShowEditShiftModal((x) => {
              return {
                itemID: response.data.shiftID,
                itemType: response.data.type,
                itemFName: response.data.fName,
                itemLName: response.data.lName,
                itemIsNew: true,
                needsTeam: locationMode ? true : false,
                itemUserID: type === "open" ? "open" : userID,
              };
            });
          }
          // memoVals.setUpdateInfo(x =>!memoVals.updateInfo);
          setSelectedAddShiftEmpty("");
          setSelectedHorizAdd("");
          setSelectedHorizAddShift("");
          setCreatingShift(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // -

  let [movingShiftID, setMovingShiftID] = useState("");
  let moveShiftUnit = (moveOrDupe, day, userID, duplicate) => {
    setShowTip(false);

    let sameDs = false;
    let sameUser = true;

    if (moveUnit.itemID) {
      sameUser = userID !== moveUnit.oldUserID ? false : true;

      if (day === moveUnit.oldDs) {
        sameDs = true;
      }

      if (sameUser && sameDs) {
        setMoveUnit({});
      } else {
        let newDateObj = new Date(dateStringer.createTimestampFromString(day));
        let oldDateObj = new Date(
          dateStringer.createTimestampFromString(moveUnit.oldDs)
        );

        setState({ singleDay: oldDateObj.getDay() });

        if (newDateObj.getDay() !== oldDateObj.getDay()) {
          setState({ singleDay2: newDateObj.getDay() });
        }

        let typeString = moveUnit.type === "til" ? "lieu shift" : moveUnit.type;

        // memoVals.setIndicate((x) => {
        //   return {
        //     show: true,
        //     message: duplicate
        //       ? `Duplicating ${typeString}...`
        //       : `Moving ${typeString}...`,
        //     colour: "blue",
        //     duration: 4000,
        //   };
        // });

        setMovingShiftID(moveUnit.itemID);
        axios
          .post(
            `${serverURL}/move-shift-unit`,
            {
              shiftID: moveUnit.itemID,
              newDs: day !== moveUnit.oldDs ? day : false,
              type: moveUnit.type,
              newUserID: userID !== moveUnit.oldUserID ? userID : false,
              duplicate: duplicate,
              nowDs: dateStringer.createStringFromTimestamp(
                new Date().getTime()
              ),
            },

            {
              withCredentials: true,
              credentials: "include",
            }
          )
          .then((response) => {
            if (response.data.message === "success") {
              setMoveUnit({});

              setMovingShiftID("");

              setSelectedAddShiftEmpty("");
              setSelectedHorizAddShift("");
              setState({ hoveredShift: {} });
              setSureDelete("");
              setSelectedHorizAdd("");
              memoVals.setIndicate((x) => {
                return {
                  show: true,
                  message: duplicate
                    ? `Duplicated ${typeString}`
                    : `Moved ${typeString}`,
                  colour: "green",
                  duration: 4000,
                };
              });

              // memoVals.setUpdateInfo(x =>!memoVals.updateInfo);
              let newItem = response.data.sendItemBack[0];
              let dayN = response.data.dayN;

              let start = newItem.start;
              let end = newItem.end;
              if (moveUnit.type !== "shift") {
                let startDateObj = new Date(
                  dateStringer.createTimestampFromString(
                    `${newItem.ds.substr(0, 11)}H${newItem.start.substr(
                      0,
                      2
                    )}M${newItem.start.substr(3, 2)}`
                  )
                );

                let endDateObj = new Date(
                  dateStringer.createTimestampFromString(
                    `${newItem.ds.substr(0, 11)}H${newItem.end.substr(
                      0,
                      2
                    )}M${newItem.end.substr(3, 2)}`
                  )
                );

                if (endDateObj.getTime() <= startDateObj.getTime()) {
                  endDateObj.setDate(endDateObj.getDate() + 1);
                }

                start = dateStringer.createStringFromTimestamp(
                  startDateObj.getTime()
                );
                end = dateStringer.createStringFromTimestamp(
                  endDateObj.getTime()
                );
              }

              let newItemFormatted = {
                absent: newItem.absent,
                nightShift: newItem.nightShift,
                partialAbsentDuration: newItem.partialAbsentDuration,
                bankCost: newItem.bankCost,
                break: moveUnit.type === "shift" ? newItem.break : newItem.brk,
                dayN,
                ds:
                  moveUnit.type === "shift"
                    ? `${newItem.start.substr(0, 11)}H00M00`
                    : newItem.ds,
                durMins: response.data.durMins,
                start,
                end,
                ghost: newItem.ghost,
                midnightTimestamp: newItem.midnightTimestamp,
                published: newItem.published,
                startTs: dateStringer.createTimestampFromString(start),
                tags: newItem.tags,
                type: moveUnit.type,
                cost: response.data.trueCost,
                rate: newItem.rate,
                trueCost: response.data.trueCost,
                typeID:
                  moveUnit.type === "shift" ? newItem.shiftID : newItem.tilID,
                salaried: newItem.salaried,
                otherTeam: false,
                accepted: response.data.accepted,
                locationID: newItem.locationID,
                location: response.data.locationName,
                requireAccept:
                  response.data.requireAccept &&
                  today00.getTime() <=
                    dateStringer.createTimestampFromString(start),
              };

              console.log({ newItemFormatted });
              let newArr = [];
              state.sched.forEach((usr) => {
                let newMon = [];
                let newTue = [];
                let newWed = [];
                let newThu = [];
                let newFri = [];
                let newSat = [];
                let newSun = [];

                if (newItem.userID === usr.userID) {
                  if (dayN === 1) {
                    newMon.push(newItemFormatted);
                  }
                  if (dayN === 2) {
                    newTue.push(newItemFormatted);
                  }
                  if (dayN === 3) {
                    newWed.push(newItemFormatted);
                  }
                  if (dayN === 4) {
                    newThu.push(newItemFormatted);
                  }
                  if (dayN === 5) {
                    newFri.push(newItemFormatted);
                  }
                  if (dayN === 6) {
                    newSat.push(newItemFormatted);
                  }
                  if (dayN === 0) {
                    newSun.push(newItemFormatted);
                  }
                }

                usr.monItems.forEach((item) => {
                  if (!duplicate) {
                    if (item.typeID !== moveUnit.itemID) {
                      newMon.push(item);
                    }
                  } else {
                    // is duplicating, so keep old one
                    newMon.push(item);
                  }
                });

                usr.tueItems.forEach((item) => {
                  if (!duplicate) {
                    if (item.typeID !== moveUnit.itemID) {
                      newTue.push(item);
                    }
                  } else {
                    // is duplicating, so keep old one
                    newTue.push(item);
                  }
                });

                usr.wedItems.forEach((item) => {
                  if (!duplicate) {
                    if (item.typeID !== moveUnit.itemID) {
                      newWed.push(item);
                    }
                  } else {
                    // is duplicating, so keep old one
                    newWed.push(item);
                  }
                });

                usr.thuItems.forEach((item) => {
                  if (!duplicate) {
                    if (item.typeID !== moveUnit.itemID) {
                      newThu.push(item);
                    }
                  } else {
                    // is duplicating, so keep old one
                    newThu.push(item);
                  }
                });

                usr.friItems.forEach((item) => {
                  if (!duplicate) {
                    if (item.typeID !== moveUnit.itemID) {
                      newFri.push(item);
                    }
                  } else {
                    // is duplicating, so keep old one
                    newFri.push(item);
                  }
                });

                usr.satItems.forEach((item) => {
                  if (!duplicate) {
                    if (item.typeID !== moveUnit.itemID) {
                      newSat.push(item);
                    }
                  } else {
                    // is duplicating, so keep old one
                    newSat.push(item);
                  }
                });

                usr.sunItems.forEach((item) => {
                  if (!duplicate) {
                    if (item.typeID !== moveUnit.itemID) {
                      newSun.push(item);
                    }
                  } else {
                    // is duplicating, so keep old one
                    newSun.push(item);
                  }
                });

                let avgContractedMpw = usr.avgContractedMpw;

                newArr.push({
                  fName: usr.fName,
                  lName: usr.lName,
                  userID: usr.userID,
                  jobTitle: usr.jobTitle,
                  userCost: usr.userCost,
                  primaryOrder: usr.primaryOrder,
                  profPicUrl: usr.profPicUrl,
                  monItems: newMon,
                  tueItems: newTue,
                  wedItems: newWed,
                  thuItems: newThu,
                  friItems: newFri,
                  satItems: newSat,
                  sunItems: newSun,
                  avgContractedMpw,
                  nonWorkingDays: usr.nonWorkingDays,
                  avgContractedMpw: usr.avgContractedMpw,
                  primaryTeamID: usr.primaryTeamID,
                  managesPrimaryTeam: usr.managesPrimaryTeam,
                  mins: usr.mins,
                  primaryTeamName: usr.primaryTeamName,
                });
              });

              setState({ sched: newArr, loadingMove: "", fadeShiftID: "" });

              // updateCostAndMinsOnUser(moveUnit.oldUserID, newArr);
            } else {
              setMoveUnit({});
              setMovingShiftID("");

              setSelectedAddShiftEmpty("");
              setSelectedHorizAddShift("");
              setState({ hoveredShiftID: "", loadingMove: "" });
              setSureDelete("");
              setSelectedHorizAdd("");

              memoVals.setIndicate((x) => {
                return {
                  show: true,
                  message: duplicate
                    ? `Duplicating ${typeString} failed`
                    : `Moving ${typeString} failed`,
                  colour: "red",
                  duration: 8000,
                };
              });
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  };

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  useEffect(() => {
    if (showShare || showAcceptModal) {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  }, [showShare, showAcceptModal]);

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 10) {
      if (showShare) {
        setPassword(originalPassword);
        setShowShare(false);
        setPasswordEdited(false);
      }

      if (showAcceptModal) {
        setShowAcceptModal(false);
        memoVals.setModalOpen((x) => false);
      }
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  // Modal swiper for generate suggestion modal

  // Create a ref to hold the modal element
  const modalRefGen = useRef(null);

  const [isDraggingGen, setIsDraggingGen] = useState(false);
  const [dragStartGen, setDragStartGen] = useState({ x: 0, y: 0 });
  const [modalPositionGen, setModalPositionGen] = useState({
    bottom: "0%",
    left: "0%",
  });

  useEffect(() => {
    if (showGenOffer) {
      setModalPositionGen({ bottom: "0%", left: "0%" });
    }
  }, [showGenOffer]);

  let [dragPositionGen, setDragPositionGen] = useState(0);

  const handleDownGen = (e) => {
    setIsDraggingGen(true);
    setDragStartGen({
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMoveGen = (e) => {
    if (isDraggingGen) {
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStartGen.y;

      if (deltaY > 0) {
        setDragPositionGen(deltaY);
        setModalPositionGen({
          bottom: `calc(0% - ${deltaY}px)`,
        });
      }
    }
  };

  const handleUpGen = () => {
    setIsDraggingGen(false);

    if (dragPositionGen > 10) {
      setShowGenOffer([]);
    } else {
      setModalPositionGen({ bottom: "0%", left: "0%" });
    }
  };

  useEffect(() => {
    if (selectedHorizAdd) {
      setSelectedHorizAdd("");
    }
    if (selectedAddShiftEmpty) {
      setSelectedAddShiftEmpty("");
    }

    if (selectedHorizAddShift) {
      setSelectedHorizAddShift(false);
    }
  }, [selectedTeam, selectedMonWeek]);

  const [draggedItem, setDraggedItem] = useState(null);

  const handleDragStart = (event, item, userID) => {
    if (memoVals.checkUserState.userID === userID && !selfManageShifts) {
      setMoveUnit({});
      setShowCannotManageOwnShift({
        show: true,
      });
    } else {
      console.log("setting dragged itm", item);
      let shift = item;
      shift["userID"] = userID;
      setDraggedItem(shift);

      if (moveUnit.itemID === item.typeID) {
        setMoveUnit({});
        memoVals.setIndicate((x) => {
          return {
            show: false,
            message: "",
            colour: "",
            duration: 0,
          };
        });
      } else {
        setMoveUnit({
          itemID: item.typeID,
          type: item.type,
          oldUserID: item.userID,
          oldDs: `${item.ds.substr(0, 11)}H00M00`,
          duplicate: false,
          startDs: item.start,
          endDs: item.end,
          durMins: item.durMins,
        });
      }
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event, targetUserID, targetDs) => {
    console.log({ draggedItem }, { targetUserID, targetDs });
    if (memoVals.checkUserState.userID === targetUserID && !selfManageShifts) {
      setShowCannotManageOwnShift({
        show: true,
      });
      setMoveUnit({});
      setDraggedItem(null);
    } else {
      event.preventDefault();

      if (!draggedItem || !targetUserID || !targetDs) return;

      console.log(
        "Moving ",
        draggedItem.type,
        " from ",
        draggedItem.userID,
        " to ",
        targetUserID,
        " from ",
        draggedItem.ds,
        " to ",
        targetDs
      );

      moveShiftUnit("move", targetDs, targetUserID, false);

      setDraggedItem(null);
    }
  };

  let heldShift = {
    shfitID: "",
    ts: 0,
  };

  function handleTouchStart(
    event,
    shiftID,
    type,
    userID,
    ds,
    startDs,
    endDs,
    durMins
  ) {
    // if (memoVals.checkUserState.userID === userID && !selfManageShifts) {
    //   setShowCannotManageOwnShift({
    //     show: true,
    //   });
    // } else {
    heldShift = { shiftID, ts: Date.now() };
    setTimeout(() => {
      if (!showEditShiftModal && heldShift.shiftID === shiftID) {
        if (memoVals.checkUserState.userID === userID && !selfManageShifts) {
          setShowCannotManageOwnShift({
            show: true,
          });
        } else {
          setStreamShiftID({
            shiftID,
            type,
            userID,
            ds,
            startDs,
            endDs,
            durMins,
          });
          heldShift = { shiftID: "", ts: 0 };
        }
      }
    }, 500);
    // }
  }

  useEffect(() => {
    if (showEditShiftModal && streamShiftID.shiftID) {
      setStreamShiftID({ shiftID: "" });
    }
  }, [showEditShiftModal, streamShiftID]);

  // Function to handle touchend event
  function handleTouchEnd(event, shiftID) {
    heldShift = { shiftID: "", ts: 0 };
  }

  let [streamMovingShift, setStreamMovingShift] = useState({
    shiftID: "",

    originalDs: "",
    originalUser: "",
    duplicate: false,
  });

  let [showCannotManageOwnShift, setShowCannotManageOwnShift] = useState({
    show: false,
  });

  let isDayLimitedByJt = (index) => {
    let limitObj = {};

    if (index === 0) {
      limitObj = state.dayLimitData.mon || {};
    } else if (index === 1) {
      limitObj = state.dayLimitData.tue || {};
    } else if (index === 2) {
      limitObj = state.dayLimitData.wed || {};
    } else if (index === 3) {
      limitObj = state.dayLimitData.thu || {};
    } else if (index === 4) {
      limitObj = state.dayLimitData.fri || {};
    } else if (index === 5) {
      limitObj = state.dayLimitData.sat || {};
    } else if (index === 6) {
      limitObj = state.dayLimitData.sun || {};
    }

    if (limitObj && limitObj.enable && !limitObj.limitByAll) {
      return true;
    } else {
      false;
    }
  };

  useEffect(() => {
    if (window.innerHeight < 766 && memoVals.mob) {
      if (showTools && !showLimitTools) {
        memoVals.setMobRotaMenuOpen((x) => true);
      } else {
        memoVals.setMobRotaMenuOpen((x) => false);
      }
    }
  }, [showTools, showLimitTools]);

  let tagsFilt = (tags) => {
    if (filterTagsArr.length === 0) {
      return true;
    }
    let tagIsInFilter = false;

    tags.forEach((x) => {
      let tagFilt = filterTagsArr.filter((xx) => {
        return xx === x;
      });
      if (tagFilt[0]) {
        tagIsInFilter = true;
      }
    });

    return tagIsInFilter;
  };

  let [hoveredInlineWithShifts, setHoveredInlineWithShifts] = useState("");
  //  - - - - MASTER RETURN

  return (
    <div
      className={`userShiftsPage ${
        !memoVals.mob ? "desktopTeamSchedHeight" : "mobTeamShiftBgColour"
      }`}
    >
      <div
        onDragOver={(event) => {
          handleDragOver(event);
        }}
        onDrop={(event) => {
          if (moveUnit.itemID) {
            setMoveUnit({});
          }
        }}
        className={`userShiftsPageUpperContainer purpleBg ${
          memoVals.mob ? "teamHeaderColours" : ""
        }`}
      >
        {!memoVals.mob ? <TopBar teamSchedule={true} /> : ""}
        <div
          className={`userShiftsUpperInnerContainer ${
            memoVals.mob ? "mobUpper" : ""
          }`}
        >
          <div
            className={`teamShiftsTitleContainer ${
              memoVals.mob
                ? "teamShiftsTitleContainerMob"
                : "desktopTeamRotaTitleBlock"
            }`}
          >
            <div
              className={`teamShiftsTitle ${
                memoVals.mob
                  ? "colourWhite teamShiftsTitleMob"
                  : `headerTitleSize x392841 ${
                      breakTeams ? "staffCalBreakTeamsRota" : ""
                    } headerTitleColourMgr`
              }`}
              onClick={() => {
                // console.log({ state });
                console.log({ streamShiftID });
                setState({ loading: true, limLoad: true });
              }}
            >
              {" "}
              {!memoVals.mob ? <div className="staffTitleLeftBlob"></div> : ""}
              Edit staff rota
              {/* {memoVals.width} */}
              {memoVals.checkUserState.permissions === 1 &&
              window.innerWidth > 560 ? (
                <div className="teamSchedAllTeamsSplitter"></div>
              ) : (
                ""
              )}
              {memoVals.checkUserState.permissions === 1 &&
              window.innerWidth > 560 ? (
                <p className="teamSchedAllTeams">
                  You can edit all team rotas because you are an admin
                </p>
              ) : (
                ""
              )}
            </div>
          </div>

          <NavLink
            to="/rota"
            onClick={() => {
              memoVals.setCalPage((x) => "schedule");
            }}
          >
            <div
              className={`staffRotaBtn ${
                memoVals.mob ? "staffRotaBtnMob" : ""
              } blueColours`}
            >
              My rotas{" "}
              <img
                src={whiteGo}
                alt="Staff rota"
                className="staffRotaWhiteGoImg"
              />{" "}
            </div>
          </NavLink>

          <p
            className={`staffRotaMasterHrs ${
              window.innerWidth < 820 && window.innerWidth > 520
                ? "staffRotaMasterHrsMidMob"
                : window.innerWidth <= 520
                ? "staffRotaMasterHrsSmallMob"
                : ""
            } ${window.innerWidth >= 820 ? "staffRotaMasterHrsFull" : ""}`}
          >
            {" "}
            {state.loading
              ? ""
              : calcTotals().totMins === 0
              ? "No hours"
              : dateStringer.formatMinsDurationToHours(calcTotals().totMins)}
          </p>
          <p
            className={`rotaWeekNum ${
              window.innerWidth < 820 && window.innerWidth > 520
                ? "rotaWeekNumMidMob"
                : window.innerWidth <= 520
                ? ""
                : window.innerWidth <= 980
                ? "rotaWeekNumDesktop"
                : "rotaWeekNumDesktop"
            } ${state.loading ? "none" : ""} ${
              window.innerWidth >= 820 ? "none" : ""
            }`}
          >
            Week {weekNum}
          </p>

          {!memoVals.mob ? (
            <div
              className={`userSchedMgrAvailBtn ${
                memoVals.mob
                  ? "userSchedMgrAvailBtnMob"
                  : "teamRotaAvailCorrection"
              } `}
              onClick={() => {
                memoVals.setShowMgrAvailModal((x) => {
                  return {
                    team: selectedTeam,
                    ds: selectedMonWeek,
                  };
                });
              }}
            >
              {memoVals.mob ? "Availability" : "Team availability"}
              {!memoVals.mob ? (
                <img src={popup2} alt="Staff rota" className="availPopupImg" />
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </div>{" "}
        {!memoVals.mob && !breakTeams ? (
          <div className={`shiftsTeamBar`}>{generateTeamsSelectBar}</div>
        ) : (
          ""
        )}
        {memoVals.mob || breakTeams ? (
          <select
            className={`teamRotaTeamSelectDropdown teamRotaTeamSelectDropdownStaff ${
              breakTeams && !memoVals.mob
                ? `breakTeamsPushDown ${locationMode ? "teamLocColour" : ""} ${
                    window.innerWidth > 910
                      ? "teamRotaTeamSelectDropdownStaffWider910"
                      : ""
                  }`
                : "teamRotaTeamSelectDropdownStaffMob"
            }`}
            ref={teamsBar}
            value={selectedTeam}
            onChange={(e) => {
              let val = e.target.value;
              if (!val) {
                return;
              }
              setSelectedTeam(val);
              setState({ loading: true });
              memoVals.setUpdateShifts((x) => !memoVals.updateShifts);
              setSelectedHorizAdd("");
              setSelectedHorizAddShift("");
              setShowTools(false);

              let teamFilt = mgrTeams.filter((x) => {
                return x.teamID === val;
              });
              let locFilt = locations.filter((x) => {
                return x.locationID === val;
              });

              if (teamFilt[0]) {
                if (selectedTeam !== teamFilt[0].teamID) {
                  setSelectedTeam(teamFilt[0].teamID);
                  setState({ loading: true });
                  memoVals.setUpdateShifts((x) => !memoVals.updateShifts);
                  setSelectedHorizAdd("");
                  setSelectedHorizAddShift("");
                  setShowTools(false);
                }
              }
              if (locFilt[0]) {
                if (selectedTeam !== locFilt[0].locationID) {
                  console.log("l;", locFilt[0].locationID);
                  setSelectedTeam(locFilt[0].locationID);
                  //
                  setState({ loading: true });
                  memoVals.setUpdateShifts((x) => !memoVals.updateShifts);
                  setSelectedHorizAdd("");
                  setSelectedHorizAddShift("");
                  setShowTools(false);
                }
              }
            }}
          >
            {generateTeamsSelectBar}
          </select>
        ) : (
          ""
        )}
      </div>
      <div className="adminCurverCropper bgColUpcoming absenceShoulder hardPurpleBg">
        <div className="adminCurve"></div>
      </div>{" "}
      <div
        onDragOver={(event) => {
          handleDragOver(event);
        }}
        onDrop={(event) => {
          if (moveUnit.itemID) {
            setMoveUnit({});
          }
        }}
        onClick={() => {
          if (streamMovingShift.shiftID) {
            setStreamMovingShift({ shiftID: "" });
          }
          if (moveUnit.itemID) {
            setMoveUnit({});
          }
        }}
        className={`shiftsPageFilterBarRota noPadLeft teamSchedBar teamShiftsSectionTeamOnly ${
          !memoVals.mob
            ? "borderLeftGrey"
            : "mobRotaTopLine whiteBg whiteBorderBottom"
        } noBorderRight`}
      >
        <div
          className={`teamSchedFilterBarLeft ${
            memoVals.mob ? "paddingLeft2" : ""
          }`}
        >
          {!memoVals.mob || memoVals.mob ? (
            <img
              src={currMonWeek === selectedMonWeek ? arrGrey : arrGrey}
              alt="Left"
              className={`leftMonWeek ${
                memoVals.mobModal ? "" : "leftMonWeekDesktop"
              } ${
                selectedMonWeek === monWeeks[0] || state.loading
                  ? "fadeSelMonWeekBtn"
                  : ""
              } ${window.innerWidth < 385 ? "none" : ""}`}
              onClick={() => {
                setHoverUserID("");
                setHoverDs("");

                setSelectedAddShiftEmpty("");
                setSelectedHorizAddShift("");
                setSelectedHorizAdd("");

                // setState({ loadingLimits: true });
                // let currTs =
                //   dateStringer.createTimestampFromString(selectedMonWeek);
                // let dateObj = new Date(currTs);
                // dateObj.setDate(dateObj.getDate() - 7);
                // dateObj.setHours(0, 0, 0, 0);

                let ind = monWeeks.indexOf(selectedMonWeek);
                if (selectedMonWeek !== monWeeks[0]) {
                  setSelectedMonWeek(
                    dateStringer.getMonWeekOfDs(monWeeks[ind - 1])
                  );
                  setState({ availLoading: true, loadingLimits: true });
                }
              }}
            />
          ) : (
            ""
          )}
          <select
            className={`monWeeksDropdown ${
              currMonWeek === selectedMonWeek ? "currMonWeekBgDrop" : ""
            } ${window.innerWidth > 1072 ? "fullScreenWeekSelector" : ""} ${
              window.innerWidth > 560 && window.innerWidth <= 1072
                ? "weekSelectorLonger"
                : ""
            } ${
              (window.innerWidth <= 560 && window.innerWidth > 423) ||
              (window.innerWidth >= 820 && window.innerWidth <= 890)
                ? "weekSelectorMedium"
                : ""
            }`}
            value={selectedMonWeek}
            onChange={(e) => {
              setSelectedMonWeek(dateStringer.getMonWeekOfDs(e.target.value));
              setState({ availLoading: true, loading: true });
            }}
          >
            {generateMonWeeks}
          </select>{" "}
          <img
            src={currMonWeek === selectedMonWeek ? arrGrey : arrGrey}
            alt="Right"
            className={`rightMonWeek ${
              !memoVals.mobModal ? "rightMonWeekDesktop" : ""
            } ${
              selectedMonWeek === monWeeks[monWeeks.length - 1] || state.loading
                ? "fadeSelMonWeekBtn"
                : ""
            }`}
            onClick={() => {
              setState({ loadingLimits: true });
              if (hoverUserID) {
                setHoverUserID("");
              }
              if (hoverDs) {
                setHoverDs("");
              }

              if (selectedAddShiftEmpty) {
                setSelectedAddShiftEmpty("");
              }
              if (selectedHorizAddShift) {
                setSelectedHorizAddShift("");
              }
              if (selectedHorizAdd) {
                setSelectedHorizAdd("");
              }

              let ind = monWeeks.indexOf(selectedMonWeek);
              if (selectedMonWeek !== monWeeks[monWeeks.length - 1]) {
                setSelectedMonWeek(
                  dateStringer.getMonWeekOfDs(monWeeks[ind + 1])
                );
                setState({ availLoading: true, loadingLimits: true });
              }
            }}
          />
          <img
            src={currWeek}
            onClick={() => {
              setSelectedMonWeek(dateStringer.getMonWeekOfDs(currMonWeek));
              setState({ availLoading: true });
            }}
            alt="Current week"
            className={`${
              currMonWeek !== selectedMonWeek && !memoVals.mobModal
                ? "currWeekIcon lightBlueColours"
                : "none"
            } ${state.loading ? "fadeSelMonWeekBtn" : ""} ${
              window.innerWidth >= 1180 && window.innerWidth <= 1211
                ? "none"
                : ""
            } ${
              window.innerWidth >= 1064 && window.innerWidth <= 1123
                ? "none"
                : ""
            }`}
          />
        </div>
      </div>{" "}
      <div
        className={`${noPermissions ? "editUserUnderlay" : "none"}`}
        onClick={() => {
          setNoPermissions(false);
        }}
      >
        <div
          className="overlapsRenewalModalBox"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <p className="overlapsRenTxt">You do not manage this user.</p>
          <p
            className="tempSavedCloseBtn"
            onClick={() => {
              setNoPermissions(false);
            }}
          >
            Understood
          </p>
        </div>
      </div>
      {teamIDDoesNotExist ? (
        <div
          className="editUserUnderlay"
          onClick={() => {
            setTeamIDDoesNotExist(false);
          }}
        >
          <div
            className="overlapsRenewalModalBox"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <p className="overlapsRenTxt">The selected team does not exist</p>
            <p
              className="tempSavedCloseBtn"
              onClick={() => {
                setTeamIDDoesNotExist(false);
              }}
            >
              Understood
            </p>
          </div>
        </div>
      ) : (
        ""
      )}
      {showNonPrimUserLeaveModal && showNonPrimUserLeaveModal.show ? (
        <div
          className="editUserUnderlay"
          onClick={() => {
            setShowNonPrimUserLeaveModal(false);
          }}
        >
          <div
            className="overlapsRenewalModalBox"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <p className="overlapsRenTxt">
              You cannot view leave items for {showNonPrimUserLeaveModal.fName}{" "}
              because you do not manage their primary team -{" "}
              {showNonPrimUserLeaveModal.pTeamName}.
              <br />
              <br />
              Only admins and managers of {
                showNonPrimUserLeaveModal.pTeamName
              }{" "}
              can view{" "}
              {dateStringer.possession(showNonPrimUserLeaveModal.fName)} leave
              items.
            </p>
            <p
              className="tempSavedCloseBtn"
              onClick={() => {
                setShowNonPrimUserLeaveModal(false);
              }}
            >
              Understood
            </p>
          </div>
        </div>
      ) : (
        ""
      )}
      {showNoPrimLimits ? (
        <div
          className="editUserUnderlay"
          onClick={() => {
            setShowNoPrimLimits(false);
          }}
        >
          <div
            className="overlapsRenewalModalBox"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <p className="overlapsRenTxt">
              No employees with the job title{" "}
              <span className="colourOrange">{showNoPrimLimits}</span> have
              their primary team set to {getTeamName(selectedTeam)}.<br />
              <br />
              {dateStringer.possession(getTeamName(selectedTeam))} leave limits
              only apply to employees whose primary team is set to{" "}
              {getTeamName(selectedTeam)}.
            </p>
            <p
              className="tempSavedCloseBtn"
              onClick={() => {
                setShowNoPrimLimits(false);
              }}
            >
              Understood
            </p>
          </div>
        </div>
      ) : (
        ""
      )}
      {filterTags ? (
        <div
          className="filtTagsUnderlay"
          onClick={() => {
            setFilterTags(false);
          }}
        >
          <div className="tagsFiltDropper">
            <div
              className={`filtTagsModal ${
                memoVals.mobModal ? "filtTagsModalMob" : ""
              }`}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div className={`toilHeader `}>
                <img
                  src={close}
                  onClick={() => {
                    setFilterTags(false);
                  }}
                  alt="Close"
                  className="closeMyShiftModalImg"
                />
                <p
                  className="toilHeaderTxt"
                  onClick={() => {
                    if (memoVals.mob) {
                      setFilterTags(false);
                    }
                  }}
                >
                  Filter shifts by tags
                </p>
                {/* <div className="myShiftModalHeaderRight">&nbsp;</div> */}
              </div>
              <div className="tagsFiltScrollBox">
                {!tags[0] ? (
                  <p className="noFiltTagsTxt">
                    No shifts in this rota week have tags to filter by
                  </p>
                ) : (
                  tags
                    .sort((a, b) => {
                      return a.localeCompare(b);
                    })
                    .map((tag) => {
                      return (
                        <div
                          className="tagFiltItem"
                          onClick={() => {
                            if (filterTagsArr.includes(tag)) {
                              setFilterTagsArr(
                                filterTagsArr.filter((x) => {
                                  return x !== tag;
                                })
                              );
                            } else {
                              setFilterTagsArr([...filterTagsArr, tag]);
                            }
                          }}
                        >
                          <img
                            src={unclockedCheck}
                            className={`${
                              filterTagsArr.includes(tag)
                                ? "unclockTickImg x134214253"
                                : "none"
                            }`}
                            alt="Select"
                          />
                          <div
                            className={`${
                              filterTagsArr.includes(tag)
                                ? "none"
                                : "unselectedUnclockRadio x134214253"
                            }`}
                          ></div>
                          {tag}
                        </div>
                      );
                    })
                )}
              </div>
              <div className="toilFooter">
                <p
                  className={`showAllTags ${!filterTagsArr[0] ? "invis" : ""}`}
                  onClick={() => {
                    setFilterTagsArr([]);
                    setFilterTags(false);
                  }}
                >
                  Show all
                </p>

                <p
                  className="closeToilBtn"
                  onClick={() => {
                    setFilterTags(false);
                  }}
                >
                  Close
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {showTools && showLimitTools && (
        <div
          className="toolsUnderlay"
          onMouseLeave={() => {
            setShowTools(false);
            setShowToolsTools(false);

            setShowLimitTools(false);
          }}
          onClick={() => {
            setShowTools(false);
            setShowToolsTools(false);

            setShowLimitTools(false);
          }}
        >
          <div
            className={`${showLimitTools ? "limitsDropper" : "none"}`}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <p
              className="schedActionItem"
              onClick={() => {
                setShowLimitTools(false);
                setShowTools(true);
              }}
            >
              Back
            </p>
            <p
              className="schedActionItem"
              onClick={() => {
                setShowTools(false);
                setShowToolsLimits(false);
                setShowToolsTools(false);
                setShowLimitTools(false);
                axios
                  .post(
                    `${serverURL}/toggle-hide-sched-limits-for-team`,
                    { data: !state.hideSchedLimits, teamID: selectedTeam },

                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .then((response) => {
                    if (response.data.message === "success") {
                      setState({ hideSchedLimits: response.data.data });
                      memoVals.setUpdateLimits((x) => !memoVals.updateLimits);

                      memoVals.setIndicate((x) => {
                        return {
                          show: true,
                          message: state.hideSchedLimits
                            ? "Limits are now enabled"
                            : "Limits are now disabled",
                          colour: "blue",
                          duration: 4000,
                          ts: new Date().getTime(),
                        };
                      });
                    }
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }}
            >
              {state.hideSchedLimits ? "Enable limits" : "Disable limits"}
            </p>{" "}
            <p
              className={`schedActionItem ${
                state.hideSchedLimits ? "disableBulkLimitBtn" : ""
              }`}
              onClick={() => {
                memoVals.setBulkEditLimitObj((x) => {
                  return {
                    teamID: selectedTeam,
                    monWeek: selectedMonWeek,
                  };
                });
                setShowLimitTools(false);
              }}
            >
              Bulk edit limits
            </p>{" "}
          </div>
        </div>
      )}
      {showTools || showLimitTools ? (
        <div
          className="toolsUnderlay x1"
          onMouseLeave={() => {
            setShowTools(false);
            setShowLimitTools(false);
          }}
          onClick={() => {
            setShowTools(false);
            setShowLimitTools(false);
          }}
        >
          <div
            className={`${showLimitTools ? "limitsDropper" : "none"}`}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <p
              className="schedActionItem"
              onClick={() => {
                setShowLimitTools(false);
                setShowTools(true);
              }}
            >
              Back
            </p>
            <p
              className="schedActionItem"
              onClick={() => {
                setShowTools(false);
                setShowToolsLimits(false);
                setShowToolsTools(false);
                setShowLimitTools(false);
                axios
                  .post(
                    `${serverURL}/toggle-hide-sched-limits-for-team`,
                    { data: !state.hideSchedLimits, teamID: selectedTeam },

                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .then((response) => {
                    if (response.data.message === "success") {
                      setState({ hideSchedLimits: response.data.data });
                      memoVals.setUpdateLimits((x) => !memoVals.updateLimits);

                      memoVals.setIndicate((x) => {
                        return {
                          show: true,
                          message: state.hideSchedLimits
                            ? "Limits are now enabled"
                            : "Limits are now disabled",
                          colour: "blue",
                          duration: 4000,
                          ts: new Date().getTime(),
                        };
                      });
                    }
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }}
            >
              {state.hideSchedLimits ? "Enable limits" : "Disable limits"}
            </p>{" "}
            <p
              className={`schedActionItem ${
                state.hideSchedLimits ? "disableBulkLimitBtn" : ""
              }`}
              onClick={() => {
                memoVals.setBulkEditLimitObj((x) => {
                  return {
                    teamID: selectedTeam,
                    monWeek: selectedMonWeek,
                  };
                });
                setShowLimitTools(false);
              }}
            >
              Bulk edit limits
            </p>{" "}
          </div>
          {/* MOBILE FULL TOOLS MENU */}
          {!showLimitTools && (
            <div
              className={`actionsDropper actionsDropperMob ${
                locationMode ? "actionsDropperMobLoc" : ""
              }`}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <p
                className={`schedActionItem schedActionItemMob`}
                onClick={() => {
                  setShowLimitTools(false);
                  setShowTools(false);
                  setShowShare(true);
                }}
              >
                Share
              </p>
              <div
                className={`schedActionItem  schedActionItemMob ${
                  requireSeen ? "x1231311xa" : "x1231311xa"
                } ${locationMode && "none"}`}
                onClick={() => {
                  setShowAcceptModal(true);
                  memoVals.setModalOpen((x) => true);

                  setShowLimitTools(false);
                  setShowTools(false);
                }}
              >
                Require acceptance{" "}
                <div
                  className={`reqSeenActive ${
                    !requireSeen ? "reqSeenActiveInactive" : ""
                  }`}
                ></div>
              </div>
              <p
                className={`schedActionItem schedActionItemMob`}
                onClick={() => {
                  setShowLimitTools(false);
                  setShowTools(false);
                  axios
                    .post(
                      `${serverURL}/notify-team`,
                      {
                        teamID: locationMode ? "" : selectedTeam,
                        locationID: locationMode ? selectedTeam : "",
                        monWeek: selectedMonWeek,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        memoVals.setIndicate((x) => {
                          return {
                            show: true,
                            message: `${teamName} staff notified`,
                            colour: "green",
                            duration: 4000,
                          };
                        });
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Notify {locationMode ? "staff" : "team"}
              </p>
              <p
                className={`schedActionItem schedActionItemMob ${
                  locationMode && "none"
                }`}
                onClick={() => {
                  if (!state.hideSchedLimits) {
                    memoVals.setBulkEditLimitObj((x) => {
                      return {
                        teamID: selectedTeam,
                        monWeek: selectedMonWeek,
                      };
                    });
                    setShowTools(false);
                  } else {
                    setShowTools(false);

                    axios
                      .post(
                        `${serverURL}/toggle-hide-sched-limits-for-team`,
                        { data: !state.hideSchedLimits, teamID: selectedTeam },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          setState({ hideSchedLimits: response.data.data });
                          memoVals.setUpdateLimits(
                            (x) => !memoVals.updateLimits
                          );

                          memoVals.setIndicate((x) => {
                            return {
                              show: true,
                              message: state.hideSchedLimits
                                ? "Limits are now enabled"
                                : "Limits are now disabled",
                              colour: "blue",
                              duration: 4000,
                              ts: new Date().getTime(),
                            };
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }
                }}
              >
                {state.hideSchedLimits ? "Enable limits" : "Limit tools"}
              </p>
              <p
                className={`schedActionItem schedActionItemMob ${
                  isPublishedMix(state.sched) &&
                  isPublishedMix(state.sched).unpublished === 0
                    ? "fadePublishBtn"
                    : ""
                }`}
                onClick={() => {
                  axios
                    .post(
                      `${serverURL}/publish-team-week`,
                      {
                        teamID: locationMode ? "" : selectedTeam,
                        locationID: locationMode ? selectedTeam : "",
                        monWeek: selectedMonWeek,
                        data: true,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      setShowTools(false);
                      setShowToolsPublish(false);
                      if (response.data.message === "success") {
                        publishLocally("publish");
                      }

                      memoVals.setIndicate((x) => {
                        return {
                          show: true,
                          message: "All shifts published",
                          colour: "green",
                          duration: 4000,
                        };
                      });
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Publish all
              </p>
              <p
                className={`schedActionItem schedActionItemMob ${
                  isPublishedMix(state.sched) &&
                  isPublishedMix(state.sched).published === 0
                    ? "fadePublishBtn"
                    : ""
                } `}
                onClick={() => {
                  // setPublishLoading(true);

                  axios
                    .post(
                      `${serverURL}/publish-team-week`,
                      {
                        teamID: locationMode ? "" : selectedTeam,
                        locationID: locationMode ? selectedTeam : "",
                        monWeek: selectedMonWeek,
                        data: false,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      setShowTools(false);
                      setShowToolsPublish(false);

                      if (response.data.message === "success") {
                        publishLocally("unpublish");
                      }
                      memoVals.setIndicate((x) => {
                        return {
                          show: true,
                          message: "All shifts unpublished",
                          colour: "blue",
                          duration: 4000,
                        };
                      });
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Unpublish all
              </p>
              <div
                className={`schedActionItem schedActionItemMob ${
                  fadeGenBtn ? "fadeG enBtn" : ""
                } ${locationMode && "none"}`}
                // className={`${!generated ? "schedActionItem" : "none"}`}
                onClick={() => {
                  if (
                    new Date().getTime() >=
                      dateStringer.createTimestampFromString(selectedMonWeek) &&
                    shiftsExist
                  ) {
                    setShowSureGenModal("gen");
                  } else {
                    // setGenerateLoading(true);
                    setShowTools(false);
                    setShowToolsPublish(false);
                    // fire
                    axios
                      .post(
                        `${serverURL}/generate-team-week`,
                        {
                          teamID: selectedTeam,
                          monWeek: selectedMonWeek,
                          ungenerating: false,
                          nowDs: dateStringer.createStringFromTimestamp(
                            new Date().getTime()
                          ),
                          // ungenerating: generated ? true : false,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        memoVals.setUpdateInfo((x) => !memoVals.updateInfo);
                        memoVals.setIndicate((x) => {
                          return {
                            show: true,
                            message: "Generating week schedule",
                            colour: "blue",
                            duration: 4000,
                          };
                        });
                        setTimeout(() => {
                          setShowPubRemind(true);
                        }, 1500);
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }
                }}
              >
                Generate week
              </div>{" "}
              <p
                className={`${
                  state.sched ? "schedActionItem schedActionItemMob" : "none"
                } ${locationMode && "none"}`}
                onClick={() => {
                  if (
                    new Date().getTime() >=
                      dateStringer.createTimestampFromString(selectedMonWeek) &&
                    shiftsExist
                  ) {
                    setShowSureGenModal("clear");
                  } else {
                    setShowTools(false);
                    setShowToolsCopy(false);

                    setShowLimitTools(false);
                    setShowToolsPublish(false);
                    setShowToolsTools(false); // fire
                    axios
                      .post(
                        `${serverURL}/clear-mon-week`,
                        {
                          teamID: selectedTeam,
                          monWeek: selectedMonWeek,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        memoVals.setUpdateInfo((x) => !memoVals.updateInfo);
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }
                }}
              >
                Clear week
              </p>{" "}
              <p
                className={`schedActionItem schedActionItemMob ${
                  locationMode && "none"
                }`}
                onClick={() => {
                  setShowTools(false);
                  memoVals.setShowMgrAvailModal((x) => {
                    return {
                      team: selectedTeam,
                      ds: selectedMonWeek,
                    };
                  });
                }}
              >
                Team availability
              </p>
              <p
                className={`schedActionItem teamSwapsFlex  schedActionItemMob ${
                  locationMode && "none"
                }`}
                onClick={() => {
                  setShowTools(false);

                  memoVals.setShowTeamSwapsModal((x) => selectedTeam);
                }}
              >
                Swap requests{" "}
                {memoVals.teamSwapsQty > 0 ? (
                  <span className="teamSwapReqsQtyItem">
                    {memoVals.teamSwapsQty > 9 ? "9+" : memoVals.teamSwapsQty}
                  </span>
                ) : (
                  ""
                )}
              </p>
              <p
                className="schedActionItem"
                onClick={() => {
                  setShowTools(false);
                  setShowToolsTools(false);
                  setShowToolsCopy(false);
                  let opens = {
                    mon: state.monOpens,
                    tue: state.tueOpens,
                    wed: state.wedOpens,
                    thu: state.thuOpens,
                    fri: state.friOpens,
                    sat: state.satOpens,
                    sun: state.sunOpens,
                  };
                  setSaveTemplateObj((x) => {
                    return {
                      teamID: locationMode ? "" : selectedTeam,
                      locationID: locationMode ? selectedTeam : "",
                      locationName: locationMode,
                      teamName,
                      monWeek: selectedMonWeek,
                      saving: false,
                      loading: true,
                      shifts: state.sched,
                      opens: opens,
                    };
                  });
                }}
              >
                Load template
              </p>
              <p
                className="schedActionItem schedActionItemMob"
                onClick={() => {
                  setShowTools(false);
                  setShowToolsTools(false);
                  setShowToolsCopy(false);

                  let opens = {
                    mon: state.monOpens,
                    tue: state.tueOpens,
                    wed: state.wedOpens,
                    thu: state.thuOpens,
                    fri: state.friOpens,
                    sat: state.satOpens,
                    sun: state.sunOpens,
                  };
                  setSaveTemplateObj((x) => {
                    return {
                      teamID: locationMode ? "" : selectedTeam,
                      locationID: locationMode ? selectedTeam : "",
                      locationName: locationMode,
                      teamName,
                      monWeek: selectedMonWeek,
                      saving: true,
                      loading: false,
                      shifts: state.sched,
                      opens: opens,
                    };
                  });
                }}
              >
                Save template
              </p>
              <p
                className={`schedActionItem schedActionItemMob ${
                  memoVals.copyWeek &&
                  memoVals.copyWeek.teamID === selectedTeam &&
                  memoVals.copyWeek.monWeek === selectedMonWeek
                    ? "copiedWeekTxt"
                    : ""
                }`}
                onClick={() => {
                  let objToCopy = {
                    monWeek: selectedMonWeek,
                    teamID: selectedTeam,
                  };
                  setPastable(false);
                  memoVals.setCopyWeek((x) => objToCopy);
                  memoVals.setIndicate((x) => {
                    return {
                      show: true,
                      message: "Week schedule copied",
                      colour: "blue",
                      duration: 4000,
                    };
                  });
                  setShowToolsCopy(false);
                  setShowTools(false);
                }}
              >
                {memoVals.copyWeek &&
                memoVals.copyWeek.teamID === selectedTeam &&
                memoVals.copyWeek.monWeek === selectedMonWeek
                  ? "Copied"
                  : "Copy week"}
              </p>
              <p
                className={`schedActionItem schedActionItemMob
            ${!pastable ? "pasteNotAvail" : ""}
            
            `}
                onClick={() => {
                  setShowTools(false);
                  setShowToolsCopy(false);

                  if (pastable) {
                    setState({ pasteLoading: true });
                    axios
                      .post(
                        `${serverURL}/copy-mon-week`,
                        {
                          teamID: locationMode ? "" : selectedTeam,
                          locationID: locationMode ? selectedTeam : "",
                          monWeek: memoVals.copyWeek.monWeek,
                          newMonWeek: selectedMonWeek,
                          nowDs: dateStringer.createStringFromTimestamp(
                            new Date().getTime()
                          ),
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          memoVals.setIndicate((x) => {
                            return {
                              show: true,
                              message: "Week schedule pasted",
                              colour: "green",
                              duration: 4000,
                            };
                          });
                          memoVals.setUpdateInfo((x) => !memoVals.updateInfo);
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }
                }}
              >
                Paste week
              </p>
            </div>
          )}
          {/* END OF MOBILE FULL TOOLS MENU */}
        </div>
      ) : (
        ""
      )}
      {/* PUBLISH TOOLS */}
      {showToolsPublish && (
        <div
          className="toolsUnderlay x2"
          onMouseLeave={() => {
            setShowToolsPublish(false);
          }}
          onClick={() => {
            setShowToolsPublish(false);
          }}
        >
          <div
            className={`actionsDropperPublish ${!showLimitTools ? "none" : ""}`}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <p
              className="schedActionItem"
              onClick={() => {
                setShowLimitTools(false);
                setShowTools(true);
              }}
            >
              Back
            </p>
            <p
              className={`schedActionItem ${locationMode && "none"}`}
              onClick={() => {
                setShowTools(false);
                setShowToolsLimits(false);
                setShowToolsTools(false);
                setShowLimitTools(false);
                axios
                  .post(
                    `${serverURL}/toggle-hide-sched-limits-for-team`,
                    { data: !state.hideSchedLimits, teamID: selectedTeam },

                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .then((response) => {
                    if (response.data.message === "success") {
                      setState({ hideSchedLimits: response.data.data });
                      memoVals.setUpdateLimits((x) => !memoVals.updateLimits);

                      memoVals.setIndicate((x) => {
                        return {
                          show: true,
                          message: state.hideSchedLimits
                            ? "Limits are now enabled"
                            : "Limits are now disabled",
                          colour: "blue",
                          duration: 4000,
                          ts: new Date().getTime(),
                        };
                      });
                    }
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }}
            >
              {state.hideSchedLimits ? "Enable limits" : "Disable limits"}
            </p>{" "}
            <p
              className={`schedActionItem ${
                state.hideSchedLimits ? "disableBulkLimitBtn" : ""
              } ${locationMode && "none"}`}
              onClick={() => {
                memoVals.setBulkEditLimitObj((x) => {
                  return {
                    teamID: selectedTeam,
                    monWeek: selectedMonWeek,
                  };
                });
                setShowLimitTools(false);
              }}
            >
              Bulk edit limits
            </p>{" "}
          </div>
          <div
            className={`${
              showToolsPublish ? "actionsDropperPublish" : "none"
            } ${!showLimTools && showCopyTools ? "pubCorrection3" : ""}  ${
              !showLimTools && !showCopyTools ? "pubCorrection4" : ""
            } ${memoVals.mob ? "none" : ""}`}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <p
              className={`schedActionItem ${
                isPublishedMix(state.sched) &&
                isPublishedMix(state.sched).unpublished === 0
                  ? "fadePublishBtn"
                  : ""
              } `}
              onClick={() => {
                axios
                  .post(
                    `${serverURL}/publish-team-week`,
                    {
                      teamID: locationMode ? "" : selectedTeam,
                      locationID: locationMode ? selectedTeam : "",
                      monWeek: selectedMonWeek,
                      data: true,
                    },

                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .then((response) => {
                    setShowTools(false);
                    setShowToolsPublish(false);

                    if (response.data.message === "success") {
                      publishLocally("publish");
                    }
                    memoVals.setIndicate((x) => {
                      return {
                        show: true,
                        message: "Published",
                        colour: "green",
                        duration: 4000,
                      };
                    });
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }}
            >
              Publish all
            </p>
            <p
              className={`schedActionItem ${
                isPublishedMix(state.sched) &&
                isPublishedMix(state.sched).published === 0
                  ? "fadePublishBtn"
                  : ""
              } `}
              onClick={() => {
                axios
                  .post(
                    `${serverURL}/publish-team-week`,
                    {
                      teamID: locationMode ? "" : selectedTeam,
                      locationID: locationMode ? selectedTeam : "",
                      monWeek: selectedMonWeek,
                      data: false,
                    },

                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .then((response) => {
                    setShowTools(false);
                    setShowToolsPublish(false);

                    if (response.data.message === "success") {
                      publishLocally("unpublish");
                    }
                    memoVals.setIndicate((x) => {
                      return {
                        show: true,
                        message: "All shifts unpublished",
                        colour: "blue",
                        duration: 4000,
                      };
                    });
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }}
            >
              Unpublish all
            </p>
            <div
              className={`schedActionItem ${fadeGenBtn ? "fadeG en Btn" : ""} ${
                locationMode && "none"
              }`}
              onClick={() => {
                if (
                  new Date().getTime() >=
                    dateStringer.createTimestampFromString(selectedMonWeek) &&
                  shiftsExist
                ) {
                  setShowSureGenModal("gen");
                } else {
                  // setGenerateLoading(true);
                  setShowTools(false);
                  setShowToolsPublish(false);
                  // fire
                  axios
                    .post(
                      `${serverURL}/generate-team-week`,
                      {
                        teamID: selectedTeam,
                        monWeek: selectedMonWeek,
                        ungenerating: false,
                        nowDs: dateStringer.createStringFromTimestamp(
                          new Date().getTime()
                        ),

                        // ungenerating: generated ? true : false,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      memoVals.setUpdateInfo((x) => !memoVals.updateInfo);
                      memoVals.setIndicate((x) => {
                        return {
                          show: true,
                          message: "Generating week schedule",
                          colour: "blue",
                          duration: 4000,
                        };
                      });
                      setTimeout(() => {
                        setShowPubRemind(true);
                      }, 1500);
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }
              }}
            >
              Generate week
            </div>{" "}
            <p
              className={`${state.sched ? "schedActionItem" : "none"} ${
                locationMode && "none"
              }`}
              onClick={() => {
                if (
                  new Date().getTime() >=
                    dateStringer.createTimestampFromString(selectedMonWeek) &&
                  shiftsExist
                ) {
                  setShowSureGenModal("clear");
                } else {
                  setShowTools(false);
                  setShowToolsCopy(false);

                  setShowLimitTools(false);
                  setShowToolsPublish(false);
                  setShowToolsTools(false); // fire
                  axios
                    .post(
                      `${serverURL}/clear-mon-week`,
                      {
                        teamID: selectedTeam,
                        monWeek: selectedMonWeek,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      memoVals.setUpdateInfo((x) => !memoVals.updateInfo);
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }
              }}
            >
              Clear week
            </p>{" "}
          </div>
        </div>
      )}
      {/* END OF PUBLISH TOOLS */}
      {/* LIMIT TOOLS */}
      {showToolsLimits && showLimTools && (
        <div
          className="toolsUnderlay x3"
          onMouseLeave={() => {
            setShowToolsLimits(false);
          }}
          onClick={() => {
            setShowToolsLimits(false);
          }}
        >
          <div
            className="limitsDropperDesktop"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <p
              className="schedActionItem"
              onClick={() => {
                setShowTools(false);
                setShowToolsLimits(false);
                setShowToolsTools(false);
                setShowLimitTools(false);
                axios
                  .post(
                    `${serverURL}/toggle-hide-sched-limits-for-team`,
                    { data: !state.hideSchedLimits, teamID: selectedTeam },

                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .then((response) => {
                    if (response.data.message === "success") {
                      setState({ hideSchedLimits: response.data.data });
                      memoVals.setUpdateLimits((x) => !memoVals.updateLimits);

                      memoVals.setIndicate((x) => {
                        return {
                          show: true,
                          message: state.hideSchedLimits
                            ? "Limits are now enabled"
                            : "Limits are now disabled",
                          colour: "blue",
                          duration: 4000,
                          ts: new Date().getTime(),
                        };
                      });
                    }
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }}
            >
              {state.hideSchedLimits ? "Enable limits" : "Disable limits"}
            </p>{" "}
            <p
              className={`schedActionItem ${
                state.hideSchedLimits ? "disableBulkLimitBtn" : ""
              }`}
              onClick={() => {
                memoVals.setBulkEditLimitObj((x) => {
                  return {
                    teamID: selectedTeam,
                    monWeek: selectedMonWeek,
                  };
                });
                setShowToolsLimits(false);
              }}
            >
              Bulk edit limits
            </p>{" "}
          </div>
          <div
            className={`${
              showToolsPublish ? "actionsDropperPublish" : "none"
            } `}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <p
              className={`schedActionItem ${
                isPublishedMix(state.sched) &&
                isPublishedMix(state.sched).unpublished === 0
                  ? "fadePublishBtn"
                  : ""
              }`}
              onClick={() => {
                axios
                  .post(
                    `${serverURL}/publish-team-week`,
                    {
                      teamID: locationMode ? "" : selectedTeam,
                      locationID: locationMode ? selectedTeam : "",
                      monWeek: selectedMonWeek,
                      data: true,
                    },

                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .then((response) => {
                    setShowTools(false);
                    setShowToolsPublish(false);

                    if (response.data.message === "success") {
                      publishLocally("publish");
                    }
                    memoVals.setIndicate((x) => {
                      return {
                        show: true,
                        message: "All shifts published",
                        colour: "green",
                        duration: 4000,
                      };
                    });
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }}
            >
              Publish all
            </p>
            <p
              className={`schedActionItem ${
                isPublishedMix(state.sched) &&
                isPublishedMix(state.sched).published === 0
                  ? "fadePublishBtn"
                  : ""
              }`}
              onClick={() => {
                // setPublishLoading(true);

                axios
                  .post(
                    `${serverURL}/publish-team-week`,
                    {
                      teamID: locationMode ? "" : selectedTeam,
                      locationID: locationMode ? selectedTeam : "",
                      monWeek: selectedMonWeek,
                      data: false,
                    },

                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .then((response) => {
                    setShowTools(false);
                    setShowToolsPublish(false);

                    if (response.data.message === "success") {
                      publishLocally("unpublish");
                    }
                    memoVals.setIndicate((x) => {
                      return {
                        show: true,
                        message: "All shifts unpublished",
                        colour: "blue",
                        duration: 4000,
                      };
                    });
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }}
            >
              Unpublish all
            </p>
            <div
              className={`schedActionItem ${fadeGenBtn ? "fadeGe nBtn" : ""}`}
              onClick={() => {
                if (
                  new Date().getTime() >=
                    dateStringer.createTimestampFromString(selectedMonWeek) &&
                  shiftsExist
                ) {
                  setShowSureGenModal("gen");
                } else {
                  // setGenerateLoading(true);
                  setShowTools(false);
                  setShowToolsPublish(false);
                  // fire
                  axios
                    .post(
                      `${serverURL}/generate-team-week`,
                      {
                        teamID: selectedTeam,
                        monWeek: selectedMonWeek,
                        ungenerating: false,
                        nowDs: dateStringer.createStringFromTimestamp(
                          new Date().getTime()
                        ),

                        // ungenerating: generated ? true : false,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      memoVals.setUpdateInfo((x) => !memoVals.updateInfo);
                      memoVals.setIndicate((x) => {
                        return {
                          show: true,
                          message: "Generating week schedule",
                          colour: "blue",
                          duration: 4000,
                        };
                      });
                      setTimeout(() => {
                        setShowPubRemind(true);
                      }, 1500);
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }
              }}
            >
              Generate week
            </div>{" "}
            <p
              className={`${state.sched ? "schedActionItem" : "none"}`}
              onClick={() => {
                if (
                  new Date().getTime() >=
                    dateStringer.createTimestampFromString(selectedMonWeek) &&
                  shiftsExist
                ) {
                  setShowSureGenModal("clear");
                } else {
                  setShowTools(false);
                  setShowToolsCopy(false);

                  setShowLimitTools(false);
                  setShowToolsPublish(false);
                  setShowToolsTools(false); // fire
                  axios
                    .post(
                      `${serverURL}/clear-mon-week`,
                      {
                        teamID: selectedTeam,
                        monWeek: selectedMonWeek,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      memoVals.setUpdateInfo((x) => !memoVals.updateInfo);
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }
              }}
            >
              Clear week
            </p>{" "}
          </div>
        </div>
      )}
      {/* END OF LIMIT TOOLS */}
      {/* COPY TOOLS */}
      {showToolsCopy && showCopyTools && (
        <div
          className="toolsUnderlay x4"
          onMouseLeave={() => {
            setShowToolsCopy(false);
          }}
          onClick={() => {
            setShowToolsCopy(false);
          }}
        >
          <div
            className="copyDropperDesktop"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <p
              className="schedActionItem"
              onClick={() => {
                setShowTools(false);
                setShowToolsCopy(false);

                let opens = {
                  mon: state.monOpens,
                  tue: state.tueOpens,
                  wed: state.wedOpens,
                  thu: state.thuOpens,
                  fri: state.friOpens,
                  sat: state.satOpens,
                  sun: state.sunOpens,
                };
                setSaveTemplateObj((x) => {
                  return {
                    teamID: locationMode ? "" : selectedTeam,
                    locationID: locationMode ? selectedTeam : "",
                    locationName: locationMode,
                    teamName,
                    monWeek: selectedMonWeek,
                    saving: false,
                    loading: true,
                    shifts: state.sched,
                    opens: opens,
                  };
                });
              }}
            >
              Load template
            </p>
            <p
              className="schedActionItem"
              onClick={() => {
                setShowTools(false);
                setShowToolsCopy(false);

                let opens = {
                  mon: state.monOpens,
                  tue: state.tueOpens,
                  wed: state.wedOpens,
                  thu: state.thuOpens,
                  fri: state.friOpens,
                  sat: state.satOpens,
                  sun: state.sunOpens,
                };
                setSaveTemplateObj((x) => {
                  return {
                    teamID: locationMode ? "" : selectedTeam,
                    locationID: locationMode ? selectedTeam : "",
                    locationName: locationMode,
                    teamName,
                    monWeek: selectedMonWeek,
                    saving: true,
                    loading: false,
                    shifts: state.sched,
                    opens: opens,
                  };
                });
              }}
            >
              Save template
            </p>
            <p
              className={`schedActionItem ${
                memoVals.copyWeek &&
                memoVals.copyWeek.teamID === selectedTeam &&
                memoVals.copyWeek.monWeek === selectedMonWeek
                  ? "copiedWeekTxt"
                  : ""
              }`}
              onClick={() => {
                let objToCopy = {
                  monWeek: selectedMonWeek,
                  teamID: selectedTeam,
                };
                setPastable(false);
                memoVals.setCopyWeek((x) => objToCopy);
                memoVals.setIndicate((x) => {
                  return {
                    show: true,
                    message: "Week schedule copied",
                    colour: "blue",
                    duration: 4000,
                  };
                });
                setShowToolsCopy(false);
                setShowTools(false);
              }}
            >
              {memoVals.copyWeek &&
              memoVals.copyWeek.teamID === selectedTeam &&
              memoVals.copyWeek.monWeek === selectedMonWeek
                ? "Copied"
                : "Copy week"}
            </p>
            <p
              className={`schedActionItem
            ${!pastable ? "pasteNotAvail" : ""}
            
            `}
              onClick={() => {
                setShowTools(false);

                if (pastable) {
                  setState({ pasteLoading: true });
                  setShowToolsCopy(false);

                  axios
                    .post(
                      `${serverURL}/copy-mon-week`,
                      {
                        teamID: locationMode ? "" : selectedTeam,
                        locationID: locationMode ? selectedTeam : "",
                        monWeek: memoVals.copyWeek.monWeek,
                        newMonWeek: selectedMonWeek,
                        nowDs: dateStringer.createStringFromTimestamp(
                          new Date().getTime()
                        ),
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        memoVals.setIndicate((x) => {
                          return {
                            show: true,
                            message: "Week schedule pasted",
                            colour: "green",
                            duration: 4000,
                          };
                        });
                        memoVals.setUpdateInfo((x) => !memoVals.updateInfo);
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }
              }}
            >
              Paste week
            </p>
            <p
              className={`${state.sched ? "schedActionItem" : "none"}`}
              onClick={() => {
                if (
                  new Date().getTime() >=
                    dateStringer.createTimestampFromString(selectedMonWeek) &&
                  shiftsExist
                ) {
                  setShowSureGenModal("clear");
                } else {
                  setShowTools(false);
                  setShowToolsCopy(false);

                  setShowLimitTools(false);
                  setShowToolsPublish(false);
                  setShowToolsTools(false); // fire
                  axios
                    .post(
                      `${serverURL}/clear-mon-week`,
                      {
                        teamID: selectedTeam,
                        monWeek: selectedMonWeek,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      memoVals.setUpdateInfo((x) => !memoVals.updateInfo);
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }
              }}
            >
              Clear week
            </p>{" "}
          </div>
          <div
            className={`${showToolsPublish ? "actionsDropperPublish" : "none"}`}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <p
              className={`schedActionItem ${
                isPublishedMix(state.sched) &&
                isPublishedMix(state.sched).unpublished === 0
                  ? "fadePublishBtn"
                  : ""
              }`}
              onClick={() => {
                axios
                  .post(
                    `${serverURL}/publish-team-week`,
                    {
                      teamID: locationMode ? "" : selectedTeam,
                      locationID: locationMode ? selectedTeam : "",
                      monWeek: selectedMonWeek,
                      data: true,
                    },

                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .then((response) => {
                    setShowTools(false);
                    setShowToolsPublish(false);

                    if (response.data.message === "success") {
                      publishLocally("publish");
                    }
                    memoVals.setIndicate((x) => {
                      return {
                        show: true,
                        message: "All shifts published",
                        colour: "green",
                        duration: 4000,
                      };
                    });
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }}
            >
              Publish all
            </p>
            <p
              className={`schedActionItem ${
                isPublishedMix(state.sched) &&
                isPublishedMix(state.sched).published === 0
                  ? "fadePublishBtn"
                  : ""
              }`}
              onClick={() => {
                axios
                  .post(
                    `${serverURL}/publish-team-week`,
                    {
                      teamID: locationMode ? "" : selectedTeam,
                      locationID: locationMode ? selectedTeam : "",
                      monWeek: selectedMonWeek,
                      data: false,
                    },

                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .then((response) => {
                    setShowTools(false);
                    setShowToolsPublish(false);

                    if (response.data.message === "success") {
                      publishLocally("unpublish");
                    }
                    memoVals.setIndicate((x) => {
                      return {
                        show: true,
                        message: "All shifts unpublished",
                        colour: "blue",
                        duration: 4000,
                      };
                    });
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }}
            >
              Unpublish all
            </p>
            <div
              className={`schedActionItem ${fadeGenBtn ? "fadeGe nBtn" : ""}`}
              onClick={() => {
                if (
                  new Date().getTime() >=
                    dateStringer.createTimestampFromString(selectedMonWeek) &&
                  shiftsExist
                ) {
                  setShowSureGenModal("gen");
                } else {
                  // setGenerateLoading(true);
                  setShowTools(false);
                  setShowToolsPublish(false);
                  // fire
                  axios
                    .post(
                      `${serverURL}/generate-team-week`,
                      {
                        teamID: selectedTeam,
                        monWeek: selectedMonWeek,
                        ungenerating: false,
                        nowDs: dateStringer.createStringFromTimestamp(
                          new Date().getTime()
                        ),

                        // ungenerating: generated ? true : false,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      memoVals.setUpdateInfo((x) => !memoVals.updateInfo);
                      memoVals.setIndicate((x) => {
                        return {
                          show: true,
                          message: "Generating week schedule",
                          colour: "blue",
                          duration: 4000,
                        };
                      });
                      setTimeout(() => {
                        setShowPubRemind(true);
                      }, 1500);
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }
              }}
            >
              Generate week
            </div>{" "}
            <p
              className={`${state.sched ? "schedActionItem" : "none"}`}
              onClick={() => {
                if (
                  new Date().getTime() >=
                    dateStringer.createTimestampFromString(selectedMonWeek) &&
                  shiftsExist
                ) {
                  setShowSureGenModal("clear");
                } else {
                  setShowTools(false);
                  setShowToolsCopy(false);

                  setShowLimitTools(false);
                  setShowToolsPublish(false);
                  setShowToolsTools(false); // fire
                  axios
                    .post(
                      `${serverURL}/clear-mon-week`,
                      {
                        teamID: selectedTeam,
                        monWeek: selectedMonWeek,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      memoVals.setUpdateInfo((x) => !memoVals.updateInfo);
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }
              }}
            >
              Clear week
            </p>{" "}
          </div>
        </div>
      )}
      {/* END OF COPY TOOLS */}
      {/* TOOLS TOOLS */}
      {showToolsTools ? (
        <div
          onMouseLeave={() => {
            setShowToolsTools(false);
          }}
          className="toolsUnderlay x5"
          onClick={() => {
            setShowToolsTools(false);
          }}
        >
          {showToolsTools ? (
            <div
              className={`toolsDropperDesktop ${
                locationMode && "toolsDropperDesktopLoc"
              }`}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <p
                className={`schedActionItem`}
                onClick={() => {
                  setShowToolsTools(false);
                  setShowShare(true);
                }}
              >
                Share
              </p>
              <div
                className={`schedActionItem ${
                  fadeGenBtn ? "fadeG enBtn" : ""
                } ${locationMode && "none"}`}
                onClick={() => {
                  if (
                    new Date().getTime() >=
                      dateStringer.createTimestampFromString(selectedMonWeek) &&
                    shiftsExist
                  ) {
                    setShowSureGenModal("gen");
                  } else {
                    setShowToolsTools(false);
                    setShowTools(false);
                    setShowToolsPublish(false);
                    // fire
                    axios
                      .post(
                        `${serverURL}/generate-team-week`,
                        {
                          teamID: selectedTeam,
                          monWeek: selectedMonWeek,
                          ungenerating: false,
                          nowDs: dateStringer.createStringFromTimestamp(
                            new Date().getTime()
                          ),

                          // ungenerating: generated ? true : false,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        memoVals.setUpdateInfo((x) => !memoVals.updateInfo);
                        memoVals.setIndicate((x) => {
                          return {
                            show: true,
                            message: "Generating week schedule",
                            colour: "blue",
                            duration: 4000,
                          };
                        });
                        setTimeout(() => {
                          setShowPubRemind(true);
                        }, 1500);
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }
                }}
              >
                Generate week
              </div>{" "}
              <p
                className="schedActionItem"
                onClick={() => {
                  setShowTools(false);
                  setShowToolsTools(false);
                  setShowToolsCopy(false);
                  let opens = {
                    mon: state.monOpens,
                    tue: state.tueOpens,
                    wed: state.wedOpens,
                    thu: state.thuOpens,
                    fri: state.friOpens,
                    sat: state.satOpens,
                    sun: state.sunOpens,
                  };
                  setSaveTemplateObj((x) => {
                    return {
                      teamID: locationMode ? "" : selectedTeam,
                      locationID: locationMode ? selectedTeam : "",
                      locationName: locationMode,
                      teamName,

                      monWeek: selectedMonWeek,
                      saving: false,
                      loading: true,
                      shifts: state.sched,
                      opens: opens,
                    };
                  });
                }}
              >
                Load template
              </p>
              <p
                className="schedActionItem"
                onClick={() => {
                  setShowTools(false);
                  setShowToolsCopy(false);
                  setShowToolsTools(false);

                  let opens = {
                    mon: state.monOpens,
                    tue: state.tueOpens,
                    wed: state.wedOpens,
                    thu: state.thuOpens,
                    fri: state.friOpens,
                    sat: state.satOpens,
                    sun: state.sunOpens,
                  };
                  setSaveTemplateObj((x) => {
                    return {
                      teamID: locationMode ? "" : selectedTeam,
                      locationID: locationMode ? selectedTeam : "",
                      locationName: locationMode,
                      teamName,
                      monWeek: selectedMonWeek,
                      saving: true,
                      loading: false,
                      shifts: state.sched,
                      opens: opens,
                    };
                  });
                }}
              >
                Save template
              </p>
              <p
                className={`schedActionItem ${
                  memoVals.copyWeek &&
                  memoVals.copyWeek.teamID === selectedTeam &&
                  memoVals.copyWeek.monWeek === selectedMonWeek
                    ? "copiedWeekTxt"
                    : ""
                }`}
                onClick={() => {
                  let objToCopy = {
                    monWeek: selectedMonWeek,
                    teamID: selectedTeam,
                  };
                  setPastable(false);
                  memoVals.setCopyWeek((x) => objToCopy);

                  memoVals.setIndicate((x) => {
                    return {
                      show: true,
                      message: "Week schedule copied",
                      colour: "blue",
                      duration: 4000,
                    };
                  });
                  setShowToolsCopy(false);
                  setShowTools(false);
                  setShowToolsTools(false);
                }}
              >
                {memoVals.copyWeek &&
                memoVals.copyWeek.teamID === selectedTeam &&
                memoVals.copyWeek.monWeek === selectedMonWeek
                  ? "Copied"
                  : "Copy week"}
              </p>
              <p
                className={`schedActionItem
            ${!pastable ? "pasteNotAvail" : ""}
            
            `}
                onClick={() => {
                  setShowTools(false);

                  setShowToolsCopy(false);
                  setShowToolsTools(false);

                  if (pastable) {
                    setState({ pasteLoading: true });
                    axios
                      .post(
                        `${serverURL}/copy-mon-week`,
                        {
                          teamID: locationMode ? "" : selectedTeam,
                          locationID: locationMode ? selectedTeam : "",
                          monWeek: memoVals.copyWeek.monWeek,
                          newMonWeek: selectedMonWeek,
                          nowDs: dateStringer.createStringFromTimestamp(
                            new Date().getTime()
                          ),
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          memoVals.setIndicate((x) => {
                            return {
                              show: true,
                              message: "Week schedule pasted",
                              colour: "green",
                              duration: 4000,
                            };
                          });
                          memoVals.setUpdateInfo((x) => !memoVals.updateInfo);
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }
                }}
              >
                Paste week
              </p>
              <p
                className={`schedActionItem ${
                  requireSeen ? "x1231311xa" : "x1231311xa"
                } ${locationMode && "none"}`}
                onClick={() => {
                  setShowAcceptModal(true);
                  memoVals.setModalOpen((x) => true);
                  setShowToolsTools(false);
                }}
              >
                Require acceptance{" "}
                <div
                  className={`reqSeenActive ${
                    !requireSeen ? "reqSeenActiveInactive" : ""
                  }`}
                ></div>
              </p>
              <p
                className={`schedActionItem`}
                onClick={() => {
                  setShowToolsTools(false);
                  axios
                    .post(
                      `${serverURL}/notify-team`,
                      {
                        teamID: locationMode ? "" : selectedTeam,
                        locationID: locationMode ? selectedTeam : "",
                        monWeek: selectedMonWeek,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        memoVals.setIndicate((x) => {
                          return {
                            show: true,
                            message: `${teamName} staff notified`,
                            colour: "green",
                            duration: 4000,
                          };
                        });
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Notify {locationMode ? "staff" : "team"}
              </p>
              <p
                className={`schedActionItem ${
                  isPublishedMix(state.sched) &&
                  isPublishedMix(state.sched).unpublished === 0
                    ? "fadePublishBtn"
                    : ""
                }`}
                onClick={() => {
                  // setPublishLoading(true);

                  axios
                    .post(
                      `${serverURL}/publish-team-week`,
                      {
                        teamID: locationMode ? "" : selectedTeam,
                        locationID: locationMode ? selectedTeam : "",
                        monWeek: selectedMonWeek,
                        data: true,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      setShowTools(false);
                      setShowToolsTools(false);

                      setShowToolsPublish(false);
                      if (response.data.message === "success") {
                        publishLocally("publish");
                      }

                      memoVals.setIndicate((x) => {
                        return {
                          show: true,
                          message: "All shifts published",
                          colour: "green",
                          duration: 4000,
                        };
                      });
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Publish all
              </p>
              <p
                className={`schedActionItem ${
                  isPublishedMix(state.sched) &&
                  isPublishedMix(state.sched).published === 0
                    ? "fadePublishBtn"
                    : ""
                }`}
                onClick={() => {
                  axios
                    .post(
                      `${serverURL}/publish-team-week`,
                      {
                        teamID: locationMode ? "" : selectedTeam,
                        locationID: locationMode ? selectedTeam : "",
                        monWeek: selectedMonWeek,
                        data: false,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      setShowTools(false);
                      setShowToolsPublish(false);
                      setShowToolsTools(false);

                      if (response.data.message === "success") {
                        publishLocally("unpublish");
                      }
                      memoVals.setIndicate((x) => {
                        return {
                          show: true,
                          message: "All shifts unpublished",
                          colour: "blue",
                          duration: 4000,
                        };
                      });
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Unpublish all
              </p>
              <p
                className={`${state.sched ? "schedActionItem" : "none"} ${
                  locationMode && "none"
                }`}
                onClick={() => {
                  if (
                    new Date().getTime() >=
                      dateStringer.createTimestampFromString(selectedMonWeek) &&
                    shiftsExist
                  ) {
                    setShowSureGenModal("clear");
                  } else {
                    setShowTools(false);
                    setShowToolsCopy(false);

                    setShowLimitTools(false);
                    setShowToolsPublish(false);
                    setShowToolsTools(false); // fire
                    axios
                      .post(
                        `${serverURL}/clear-mon-week`,
                        {
                          teamID: selectedTeam,
                          monWeek: selectedMonWeek,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        memoVals.setUpdateInfo((x) => !memoVals.updateInfo);
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }
                }}
              >
                Clear week
              </p>{" "}
              <p
                className={`schedActionItem teamSwapsFlex ${
                  locationMode && "none"
                }`}
                onClick={() => {
                  setShowToolsTools(false);

                  memoVals.setShowTeamSwapsModal((x) => selectedTeam);
                }}
              >
                Swap requests{" "}
                {memoVals.teamSwapsQty > 0 ? (
                  <span className="teamSwapReqsQtyItem">
                    {memoVals.teamSwapsQty > 9 ? "9+" : memoVals.teamSwapsQty}
                  </span>
                ) : (
                  ""
                )}
              </p>
              <p
                className={`schedActionItem ${locationMode && "none"}`}
                onClick={() => {
                  if (state.hideSchedLimits) {
                    setShowToolsTools(false);

                    axios
                      .post(
                        `${serverURL}/toggle-hide-sched-limits-for-team`,
                        { data: !state.hideSchedLimits, teamID: selectedTeam },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          setState({ hideSchedLimits: response.data.data });
                          memoVals.setUpdateLimits(
                            (x) => !memoVals.updateLimits
                          );

                          memoVals.setIndicate((x) => {
                            return {
                              show: true,
                              message: state.hideSchedLimits
                                ? "Limits are now enabled"
                                : "Limits are now disabled",
                              colour: "blue",
                              duration: 4000,
                              ts: new Date().getTime(),
                            };
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  } else {
                    memoVals.setBulkEditLimitObj((x) => {
                      return {
                        teamID: selectedTeam,
                        monWeek: selectedMonWeek,
                      };
                    });
                    setShowTools(false);
                    setShowToolsTools(false);
                  }
                }}
              >
                {state.hideSchedLimits ? "Enable limits" : "Limit tools"}
              </p>
            </div>
          ) : (
            ""
          )}
          <div
            className={`${showToolsPublish ? "actionsDropperPublish" : "none"}`}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <p
              className={`schedActionItem ${
                isPublishedMix(state.sched) &&
                isPublishedMix(state.sched).unpublished === 0
                  ? "fadePublishBtn"
                  : ""
              }`}
              onClick={() => {
                axios
                  .post(
                    `${serverURL}/publish-team-week`,
                    {
                      teamID: locationMode ? "" : selectedTeam,
                      locationID: locationMode ? selectedTeam : "",
                      monWeek: selectedMonWeek,
                      data: true,
                    },

                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .then((response) => {
                    setShowTools(false);
                    setShowToolsPublish(false);

                    if (response.data.message === "success") {
                      publishLocally("publish");
                    }
                    memoVals.setIndicate((x) => {
                      return {
                        show: true,
                        message: "All shifts published",
                        colour: "green",
                        duration: 4000,
                      };
                    });
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }}
            >
              Publish all
            </p>
            <p
              className={`schedActionItem ${
                isPublishedMix(state.sched) &&
                isPublishedMix(state.sched).published === 0
                  ? "fadePublishBtn"
                  : ""
              }`}
              onClick={() => {
                // setPublishLoading(true);

                axios
                  .post(
                    `${serverURL}/publish-team-week`,
                    {
                      teamID: locationMode ? "" : selectedTeam,
                      locationID: locationMode ? selectedTeam : "",
                      monWeek: selectedMonWeek,
                      data: false,
                    },

                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .then((response) => {
                    setShowTools(false);
                    setShowToolsPublish(false);

                    if (response.data.message === "success") {
                      publishLocally("unpublish");
                    }
                    memoVals.setIndicate((x) => {
                      return {
                        show: true,
                        message: "All shifts unpublished",
                        colour: "blue",
                        duration: 4000,
                      };
                    });
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }}
            >
              Unpublish all
            </p>
            <div
              className={`schedActionItem ${fadeGenBtn ? "fadeGe nBtn" : ""}`}
              onClick={() => {
                if (
                  new Date().getTime() >=
                    dateStringer.createTimestampFromString(selectedMonWeek) &&
                  shiftsExist
                ) {
                  setShowSureGenModal("gen");
                } else {
                  setShowToolsTools(false);
                  setShowTools(false);
                  setShowToolsPublish(false);
                  axios
                    .post(
                      `${serverURL}/generate-team-week`,
                      {
                        teamID: selectedTeam,
                        monWeek: selectedMonWeek,
                        ungenerating: false,
                        nowDs: dateStringer.createStringFromTimestamp(
                          new Date().getTime()
                        ),
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      memoVals.setUpdateInfo((x) => !memoVals.updateInfo);
                      memoVals.setIndicate((x) => {
                        return {
                          show: true,
                          message: "Generating week schedule",
                          colour: "blue",
                          duration: 4000,
                        };
                      });
                      setTimeout(() => {
                        setShowPubRemind(true);
                      }, 1500);
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }
              }}
            >
              Generate week
            </div>{" "}
            <p
              className={`${state.sched ? "schedActionItem" : "none"}`}
              onClick={() => {
                if (
                  new Date().getTime() >=
                    dateStringer.createTimestampFromString(selectedMonWeek) &&
                  shiftsExist
                ) {
                  setShowSureGenModal("clear");
                } else {
                  setShowTools(false);
                  setShowToolsCopy(false);

                  setShowLimitTools(false);
                  setShowToolsPublish(false);
                  setShowToolsTools(false); // fire
                  axios
                    .post(
                      `${serverURL}/clear-mon-week`,
                      {
                        teamID: selectedTeam,
                        monWeek: selectedMonWeek,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      memoVals.setUpdateInfo((x) => !memoVals.updateInfo);
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }
              }}
            >
              Clear week
            </p>{" "}
          </div>
        </div>
      ) : (
        ""
      )}
      {/* END OF TOOLS TOOLS */}
      <div className="shiftTilFilterHolder"></div>
      <div
        className={`filterSplitterContainer ${
          !memoVals.mob ? "borderLeftGrey" : ""
        }`}
      >
        <div className={`${!memoVals.mob ? "borderLeftGrey" : ""}`}></div>
      </div>
      <div
        className={`teamSchedulePage ${
          !memoVals.mob
            ? "desktopTeamSchedHeight"
            : "whiteBg paddingTop0 mobMyRotaSched"
        }`}
      >
        <div className={`${generateLoading ? "loadingSchedGen" : "none"}`}>
          <img
            src={horizontalBalls}
            alt="Loading"
            className="loadingSchedGenImg"
          />
        </div>
        {/* new content */}
        <div className={`${showTip ? "tipBox" : "none"}`}>
          <img
            src={whitecross}
            alt="close"
            className="tipBoxClose"
            onClick={() => {
              setShowTip(false);
            }}
          />
          <div className="tipBoxBody">
            <p className="tipBoxTxt">
              <span className="tipBoxTipSpan">Tip: </span>
              Click anywhere on the rota and use your arrow keys to move around.
            </p>
            <p
              className="dontShowTipBtn"
              onClick={() => {
                setShowTip(false);
                axios
                  .post(
                    `${serverURL}/disable-tip`,
                    {
                      tip: "arrows",
                    },

                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .catch((err) => {
                    console.error(err);
                  });
              }}
            >
              Don't show again
            </p>
          </div>
        </div>
        {loading ? (
          <img
            src={horizontalBalls}
            alt="Loading"
            className="teamSchedLoadingBallsImg"
          />
        ) : (
          ""
        )}

        <div
          onScroll={() => {
            console.log("SCROLLING");
            heldShift = { shiftID: "", ts: 0 };
          }}
          className={`teamSchedContainer ${
            !memoVals.mob
              ? `teamSchedDesktop ${
                  locationMode ? "teamSchedDesktopLocation" : ""
                }`
              : `teamSchedContainerMob ${
                  memoVals.mob && locationMode
                    ? `teamSchedContainerMobLocationMode ${
                        memoVals.device.ios
                          ? "teamSchedContainerMobLocationModeIos"
                          : ""
                      }`
                    : ""
                } ${memoVals.device.ios ? "teamSchedContainerMobIos" : ""}`
          }
          ${!memoVals.mob ? "borderLeftGrey_ whiteBg" : ""} ${
            loading ? "none" : ""
          } ${memoVals.device.vhIssue ? "vhIssue" : ""} 
          
          `}
        >
          <div className={`teamSchedInnerCont streamlinedParent`}>
            <div
              className={`nameColContainer streamlinedNameCol ${
                memoVals.mob ? "streamJtRowMob" : ""
              } ${state.loading ? "none" : ""}`}
            >
              <div
                className="streamlinedA1 streamlinedA1NameCol"
                onClick={() => {
                  console.log({ viz });
                  console.log(dateStringer.dsToTimeStrip(viz.totalMinDs));
                }}
              >
                <div
                  className={`rotaInsightsBtn ${
                    memoVals.mob ? "rotaInsightsBtnMob" : ""
                  } ${showViz ? "showVisBtnActive" : ""}`}
                  onClick={() => {
                    setShowViz(!showViz);
                  }}
                >
                  <img
                    src={showViz ? whiteGo : statsWhite}
                    alt="Insights"
                    className={`insightsIconImg ${
                      showViz
                        ? "showVizWhiteCross"
                        : memoVals.mob
                        ? "insightsIconImgMob"
                        : ""
                    } ${
                      memoVals.mob
                        ? `mobShowVizIcon showVizWhiteCrossMob ${
                            showViz ? "x283848484" : ""
                          } `
                        : ""
                    }`}
                  />
                  {collapse ? "" : memoVals.mob ? "Insights" : ""}
                </div>
                {window.innerWidth >= 820 && (
                  <p className="desktopWeekNum">Week {weekNum}</p>
                )}
                {showViewOptions && (
                  <div className="teamSchedOptionsBox">
                    <div
                      className="teamSchedViewOption"
                      onClick={() => {
                        setShowLimits(!showLimits);
                      }}
                    >
                      {/*  KNOB BEGIN */}
                      <div
                        className={`editUserReqClockOutKnobContainer x99001 limKnobHolder`}
                      >
                        {/* <div className="knobContainer"> */}
                        <div
                          className={`switchKnob ${
                            showLimits
                              ? "knobOn knobOnMgrColour knobRed"
                              : "knobOff"
                          } limKnob`}
                        ></div>
                        <div className="knobBg knobBgLim"></div>
                        {/* </div> */}
                      </div>
                      {/*  KNOB END */}
                      <p className="teamSchedViewOptionTxt">Leave limits</p>
                    </div>
                  </div>
                )}
              </div>
              {/* streamlined viz */}
              {showViz && !state.loading ? (
                <div
                  className={`vizBox blankVizBox ${
                    memoVals.mob ? "noBorderRadius blankVizBoxMob" : ""
                  }  ${memoVals.mob ? "vizBoxMob" : ""}`}
                >
                  <div
                    className={`blankVizRow ${
                      memoVals.mob ? "blankVizRowMob" : ""
                    }`}
                  >
                    <p
                      className={`blankVizRowTitle ${
                        memoVals.mob ? "blankVizRowTitleMob" : ""
                      }`}
                    >
                      Total cost:
                    </p>
                    <p
                      className={`blankVizRowValue ${
                        memoVals.mob ? "blankVizRowValueMob" : ""
                      }`}
                    >
                      {memoVals.currencySymbol}
                      {dateStringer.formatCost(calcTotals().totCost)}
                      <br />
                    </p>
                  </div>
                  <div
                    className={`blankVizRow ${
                      memoVals.mob ? "blankVizRowMob" : ""
                    }`}
                  >
                    <p
                      className={`blankVizRowTitle ${
                        memoVals.mob ? "blankVizRowTitleMob" : ""
                      }`}
                    ></p>
                    <p
                      className={`blankVizRowValue ${
                        memoVals.mob ? "blankVizRowValueMob" : ""
                      } vizSubMins  ${memoVals.mob ? "vizSubMinsMob" : ""}`}
                    >
                      {dateStringer.formatMinsDurationToHours(
                        calcTotals().totMins
                      )}
                      <br />
                    </p>
                  </div>

                  <div
                    className={`blankVizRow ${
                      memoVals.mob ? "blankVizRowMob" : ""
                    }`}
                  >
                    <p
                      className={`blankVizRowTitle ${
                        memoVals.mob ? "blankVizRowTitleMob" : ""
                      }`}
                    >
                      Shifts:
                    </p>
                    <p
                      className={`blankVizRowValue ${
                        memoVals.mob ? "blankVizRowValueMob" : ""
                      }`}
                    >
                      {memoVals.currencySymbol}
                      {dateStringer.formatCost(calcTotals().totShiftCost)}
                      <br />
                    </p>
                  </div>

                  <div
                    className={`blankVizRow ${
                      memoVals.mob ? "blankVizRowMob" : ""
                    }`}
                  >
                    <p
                      className={`blankVizRowTitle ${
                        memoVals.mob ? "blankVizRowTitleMob" : ""
                      }`}
                    ></p>
                    <p
                      className={`blankVizRowValue ${
                        memoVals.mob ? "blankVizRowValueMob" : ""
                      } vizSubMins  ${memoVals.mob ? "vizSubMinsMob" : ""}`}
                    >
                      {dateStringer.formatMinsDurationToHours(
                        calcTotals().totShiftMins
                      )}
                      <br />
                    </p>
                  </div>

                  <div
                    className={`blankVizRow ${
                      memoVals.mob ? "blankVizRowMob" : ""
                    }`}
                  >
                    <p
                      className={`blankVizRowTitle ${
                        memoVals.mob ? "blankVizRowTitleMob" : ""
                      }`}
                    >
                      Overtime:
                    </p>
                    <p
                      className={`blankVizRowValue ${
                        memoVals.mob ? "blankVizRowValueMob" : ""
                      }`}
                    >
                      {memoVals.currencySymbol}
                      {dateStringer.formatCost(calcTotals().totOvertimeCost)}
                      <br />
                    </p>
                  </div>
                  <div
                    className={`blankVizRow ${
                      memoVals.mob ? "blankVizRowMob" : ""
                    }`}
                  >
                    <p
                      className={`blankVizRowTitle ${
                        memoVals.mob ? "blankVizRowTitleMob" : ""
                      }`}
                    ></p>
                    <p
                      className={`blankVizRowValue ${
                        memoVals.mob ? "blankVizRowValueMob" : ""
                      } vizSubMins ${memoVals.mob ? "vizSubMinsMob" : ""}`}
                    >
                      {dateStringer.formatMinsDurationToHours(
                        calcTotals().totOvertimeMins
                      )}
                      <br />
                    </p>
                  </div>

                  <div
                    className={`blankVizRow ${
                      memoVals.mob ? "blankVizRowMob" : ""
                    }`}
                  >
                    <p
                      className={`blankVizRowTitle ${
                        memoVals.mob ? "blankVizRowTitleMob" : ""
                      }`}
                    >
                      Leave:
                    </p>
                    <p
                      className={`blankVizRowValue ${
                        memoVals.mob ? "blankVizRowValueMob" : ""
                      }`}
                    >
                      {memoVals.currencySymbol}
                      {dateStringer.formatCost(calcTotals().totLeaveCost)}
                      {/* {dateStringer.formatMinsDurationToHours(
                        calcTotals().totLeaveMins
                      )} */}
                      <br />
                    </p>
                  </div>
                  <div
                    className={`blankVizRow ${
                      memoVals.mob ? "blankVizRowMob" : ""
                    }`}
                  >
                    <p
                      className={`blankVizRowTitle ${
                        memoVals.mob ? "blankVizRowTitleMob" : ""
                      }`}
                    ></p>
                    <p
                      className={`blankVizRowValue ${
                        memoVals.mob ? "blankVizRowValueMob" : ""
                      } vizSubMins  ${memoVals.mob ? "vizSubMinsMob" : ""}`}
                    >
                      {dateStringer.formatMinsDurationToHours(
                        calcTotals().totLeaveMins
                      )}{" "}
                      {/* hello */}
                      <br />
                    </p>
                  </div>

                  <div
                    className={`blankVizRow ${
                      memoVals.mob ? "blankVizRowMob" : ""
                    }`}
                  >
                    <p
                      className={`blankVizRowTitle ${
                        memoVals.mob ? "blankVizRowTitleMob" : ""
                      }`}
                    >
                      Absence:
                    </p>
                    <p
                      className={`blankVizRowValue ${
                        memoVals.mob ? "blankVizRowValueMob" : ""
                      }`}
                    >
                      {/* {dateStringer.formatMinsDurationToHours(
                        calcTotals().totAbsenceMins
                      )} */}
                      {memoVals.currencySymbol}
                      {dateStringer.formatCost(calcTotals().totAbsenceCost)}
                      <br />
                    </p>
                  </div>
                  <div
                    className={`blankVizRow ${
                      memoVals.mob ? "blankVizRowMob" : ""
                    }`}
                  >
                    <p
                      className={`blankVizRowTitle ${
                        memoVals.mob ? "blankVizRowTitleMob" : ""
                      }`}
                    ></p>
                    <p
                      className={`blankVizRowValue ${
                        memoVals.mob ? "blankVizRowValueMob" : ""
                      } vizSubMins  ${
                        memoVals.mob ? "vizSubMinsMob" : ""
                      } noBorder`}
                    >
                      {/* Late:{" "}
                      {dateStringer.formatMinsDurationToHours(
                        calcTotals().totLateMins
                      )} */}
                      {dateStringer.formatMinsDurationToHours(
                        calcTotals().totAbsenceMins
                      )}
                      <br />
                    </p>
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* end of streamlined viz */}
              <div
                className={`${
                  showSpecDays() ? "specDayRow specDayRowStreamA1" : "none"
                }`}
              ></div>
              {highlightedComments[0] && !loading ? (
                <div className="highlightedCommentsA1"></div>
              ) : (
                ""
              )}
              {/* streamlined open a1 */}
              <div
                className={`openShiftsNameDiv ${
                  collapse ? "collapseOpenTxt" : ""
                } ${
                  (state.sunOpens.length === 0 &&
                    state.monOpens.length === 0 &&
                    state.tueOpens.length === 0 &&
                    state.wedOpens.length === 0 &&
                    state.thuOpens.length === 0 &&
                    state.friOpens.length === 0 &&
                    state.satOpens.length === 0) ||
                  state.pasteLoading
                    ? "zeroOpensHeight"
                    : ""
                }`}
                onClick={() => {
                  if (streamMovingShift.shiftID) {
                    setStreamMovingShift({ shiftID: "" });
                  }
                  if (moveUnit.itemID) {
                    setMoveUnit({});
                  }
                }}
              >
                <div
                  className={`openDiv1 ${
                    collapse ? "openDiv1mobCollapse" : ""
                  }  ${memoVals.mob ? "openDiv1Mob" : ""} ${
                    memoVals.mob ? `${collapse ? "opens44" : ""}` : ""
                  }`}
                >
                  Open shifts{" "}
                  {collapse ? (
                    ""
                  ) : (
                    <img
                      src={navyTri}
                      alt="Open shifts"
                      className="openShiftsTriSpan"
                    />
                  )}
                </div>
              </div>
              {!state.hideSchedLimits && (
                <div
                  className={`openShiftsNameDiv leaveLimtsA1 ${
                    (state.dayLimitData.mon && state.dayLimitData.mon.enable) ||
                    (state.dayLimitData.tue && state.dayLimitData.tue.enable) ||
                    (state.dayLimitData.wed && state.dayLimitData.wed.enable) ||
                    (state.dayLimitData.thu && state.dayLimitData.thu.enable) ||
                    (state.dayLimitData.fri && state.dayLimitData.fri.enable) ||
                    (state.dayLimitData.sat && state.dayLimitData.sat.enable) ||
                    (state.dayLimitData.sun && state.dayLimitData.sun.enable)
                      ? "leaveLimsA1Sub"
                      : ""
                  } ${state.loadingLimits ? "invis_" : ""}`}
                  onClick={() => {
                    if (streamMovingShift.shiftID) {
                      setStreamMovingShift({ shiftID: "" });
                    }
                    if (moveUnit.itemID) {
                      setMoveUnit({});
                    }
                  }}
                >
                  <div
                    className={`openDiv1 ${
                      collapse ? "openDiv1mobCollapse" : ""
                    }  ${memoVals.mob ? "openDiv1Mob x9283711" : ""} ${
                      memoVals.mob ? `${collapse ? "opens44" : ""}` : ""
                    }`}
                  >
                    {memoVals.mob ? "Limits" : "Leave limits"}
                    <img
                      src={navyTri}
                      alt="Open shifts"
                      className="openShiftsTriSpan"
                    />
                  </div>
                </div>
              )}
              {state.jobTitles.map((jt) => {
                return (
                  <div
                    className={`streamJtRow ${
                      memoVals.mob ? "streamJtRowMob_" : ""
                    }`}
                  >
                    <div
                      className={`streamJtJobTitle borderLeftStream ${
                        memoVals.mob ? "borderLeftStreamMob" : ""
                      } flexStart ${memoVals.mob ? "streamJtJobTitleMob" : ""}`}
                      onClick={() => {
                        if (streamMovingShift.shiftID) {
                          setStreamMovingShift({ shiftID: "" });
                        }
                        if (moveUnit.itemID) {
                          setMoveUnit({});
                        }
                        if (collapsedJts.includes(jt)) {
                          setCollapsedJts(
                            collapsedJts.filter((x) => {
                              return x !== jt;
                            })
                          );
                        } else {
                          setCollapsedJts([...collapsedJts, jt]);
                        }
                      }}
                    >
                      {!memoVals.mob && (
                        <img
                          src={downArrNavy}
                          alt="Collapse job title"
                          className={`streamCollapseJtImg ${
                            collapsedJts.includes(jt)
                              ? "streamCollapseJtImgCollapsed"
                              : ""
                          }`}
                        />
                      )}
                      {memoVals.mob
                        ? dateStringer.pluraliseNoun(jt).length > 34
                          ? dateStringer.shorten(
                              dateStringer.pluraliseNoun(jt),
                              32
                            )
                          : dateStringer.pluraliseNoun(jt)
                        : dateStringer.pluraliseNoun(jt)}
                    </div>
                    <div className="shellBlank"></div>
                    {state.sched
                      .filter((x) => {
                        return x.jobTitle === jt;
                      })
                      .sort((a, b) => {
                        return a.fName.localeCompare(b.fName);
                      })
                      .map((user, i) => {
                        if (!collapsedJts.includes(jt)) {
                          let userHasAvail = availIsProvidedForOneOfUsersDays(
                            user.userID,
                            true
                          );
                          return (
                            <div
                              onClick={() => {
                                console.log({ userHasAvail });
                                console.log(
                                  "...",
                                  availIsProvidedForOneOfUsersDays(
                                    user.userID,
                                    true
                                  )
                                );

                                console.log(
                                  "nonWDays: ",
                                  user.nonWorkingDays.length
                                );
                              }}
                              className={`streamUserRow ${
                                i === 0 ? "borderTopBbcada" : ""
                              } ${
                                i ===
                                state.sched.filter((x) => {
                                  return x.jobTitle === jt;
                                }).length -
                                  1
                                  ? "noBorder"
                                  : ""
                              } ${userHasAvail ? "availNameBlock" : ""}`}
                            >
                              {userHasAvail ? (
                                <div
                                  className={`availTest nameAvail ${
                                    memoVals.mob ? "nameAvailMob" : ""
                                  }`}
                                >
                                  Availability
                                  <img
                                    src={navyTri}
                                    alt="Open shifts"
                                    className="openShiftsTriSpan opac05"
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                              <div
                                className={`streamUserNameBlock ${
                                  memoVals.mob ? "streamUserNameBlockMob" : ""
                                } ${
                                  state.sched.filter((x) => {
                                    return x.jobTitle === jt;
                                  }).length ===
                                  i + 1
                                    ? "noBorderBottom"
                                    : ""
                                } ${
                                  checkUserState.permissions === 1 ||
                                  user.managesPrimaryTeam
                                    ? ""
                                    : "greyScale_ fullOpac"
                                }`}
                                onClick={() => {
                                  if (
                                    checkUserState.permissions === 1 ||
                                    user.managesPrimaryTeam
                                  ) {
                                    setShowEditUser(user.userID);
                                  }
                                }}
                              >
                                <img
                                  src={
                                    user.profPicUrl || profilePicturePlaceholder
                                  }
                                  alt={user.fName}
                                  className="streamlinedUserProPicImg"
                                />{" "}
                                <div className="streamNameAndHours">
                                  <p className="steamName">
                                    {user.fName} {user.lName}
                                  </p>
                                  <p className="streamUserHours">
                                    {calcUserData(user.userID).mins === 0
                                      ? "0 hours"
                                      : dateStringer.formatMinsDurationToHours(
                                          calcUserData(user.userID).mins,
                                          true
                                        )}{" "}
                                    {!memoVals.mob && "- "}{" "}
                                    {!memoVals.mob &&
                                      calcUserData(user.userID).shifts}{" "}
                                    {memoVals.mob
                                      ? ""
                                      : calcUserData(user.userID).shifts === 1
                                      ? "shift"
                                      : "shifts"}
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        } else {
                          return;
                        }
                      })}
                  </div>
                );
              })}
              <p
                className={`staffRotaAddUserLowLeftBtn lilacColours ${
                  memoVals.mob ? "staffRotaAddUserLowLeftBtnMob" : ""
                } ${locationMode && "none"}`}
                onClick={() => {
                  memoVals.setShowAddUserModal((x) => true);
                }}
              >
                Add employee{" "}
                {!memoVals.mob && (
                  <img
                    src={whiteAdd}
                    alt="Add user"
                    className="staffRotaAddWhiteCrossImg"
                  />
                )}
              </p>{" "}
            </div>
            {/* <div className="teamSchedSplit"></div> */}
            <div
              className={`teamSchedContent ${
                memoVals.device.ios ? "teamSchedContentIos" : ""
              }`}
              onScroll={() => {
                console.log("SCROLLING");
                heldShift = { shiftID: "", ts: 0 };
              }}
              ref={containerRef}
              id="teamSchedContent"
            >
              {/* {generateContent} */}
              {
                state.loading ? (
                  <img
                    src={horizontalBalls}
                    alt="Loading"
                    className={`loadingInbetweenBalls ${
                      memoVals.mob ? "loadingInbetweenBallsMob" : ""
                    }`}
                  />
                ) : (
                  // "Streamlined content"
                  // <div className="streamlinedDays">
                  <Fragment>
                    {" "}
                    {/* <p
                        onClick={() => {
                          console.log(state.daysArr);
                        }}
                      >
                        xx
                      </p> */}
                    {state.daysArr &&
                      state.daysArr.map((ds, dsIndex) => {
                        let highlight = () => {
                          let highlightFilt = highlightedComments.filter(
                            (x) => {
                              return x.ds === ds;
                            }
                          );

                          if (highlightFilt[0]) {
                            return highlightFilt[0].comment;
                          }
                        };

                        let dayIndex =
                          dateStringer.convertRotaDsIndexToDayNum(dsIndex);

                        let getRotaQty = (dayN) => {
                          if (dayN === 0) {
                            return state.monCommentsQty;
                          }

                          if (dayN === 1) {
                            return state.tueCommentsQty;
                          }

                          if (dayN === 2) {
                            return state.wedCommentsQty;
                          }

                          if (dayN === 3) {
                            return state.thuCommentsQty;
                          }

                          if (dayN === 4) {
                            return state.friCommentsQty;
                          }

                          if (dayN === 5) {
                            return state.satCommentsQty;
                          }

                          if (dayN === 6) {
                            return state.sunCommentsQty;
                          }
                        };

                        let dsObj = new Date(
                          dateStringer.createTimestampFromString(ds)
                        );

                        let dayOpens = state.sunOpens;
                        if (dsObj.getDay() === 1) {
                          dayOpens = state.monOpens;
                        }
                        if (dsObj.getDay() === 2) {
                          dayOpens = state.tueOpens;
                        }
                        if (dsObj.getDay() === 3) {
                          dayOpens = state.wedOpens;
                        }
                        if (dsObj.getDay() === 4) {
                          dayOpens = state.thuOpens;
                        }
                        if (dsObj.getDay() === 5) {
                          dayOpens = state.friOpens;
                        }
                        if (dsObj.getDay() === 6) {
                          dayOpens = state.satOpens;
                        }

                        dayOpens.sort(function (a, b) {
                          return a.openID.localeCompare(b.openID);
                        });
                        let generateOpens =
                          !state.pasteLoading &&
                          dayOpens.map((open) => {
                            if (!open.initial) {
                              let generateTags =
                                !basicView && open.tags
                                  ? open.tags.map((tag, i) => {
                                      if (i >= 1) {
                                        if (i === open.tags.length - 1) {
                                          return (
                                            <p
                                              className={`shTagInline appendingShTagInline `}
                                              key={tag}
                                            >
                                              +{open.tags.length - 1}
                                            </p>
                                          );
                                        }
                                      } else {
                                        return (
                                          <p
                                            className={`shTagInline  `}
                                            key={tag}
                                          >
                                            {shortenTag(tag)}
                                          </p>
                                        );
                                      }
                                    })
                                  : "";
                              return (
                                <div className="openUnitParent">
                                  <div
                                    key={open.openID}
                                    className="jtUserShiftsBox openShiftUnit"
                                    onMouseEnter={() => {
                                      setSelectedHorizAdd("");
                                      setSelectedHorizAddShift("");
                                      if (
                                        memoVals.width >
                                        hoverButtonsAtScreenWidth
                                      ) {
                                        if (
                                          state.hoveredShift.typeID !==
                                          open.openID
                                        ) {
                                          setState({
                                            hoveredShift: {
                                              typeID: open.openID,
                                              type: "open",
                                            },
                                          });
                                        } else {
                                          setState({ hoveredShift: {} });
                                        }
                                      }
                                    }}
                                    onClick={(e) => {
                                      // e.stopPropagation();
                                      console.log("OPEN MGR OPEN MODAL");
                                      memoVals.setShowOpenShiftModal((x) => {
                                        return {
                                          ds: open.ds,
                                          openID: open.openID || itemID,
                                          new: false,
                                          teamID: selectedTeam,
                                        };
                                      });
                                      if (
                                        state.hoveredShift.typeID !==
                                        open.openID
                                      ) {
                                        setState({
                                          hoveredShift: {
                                            typeID: open.openID,
                                            type: "open",
                                          },
                                        });
                                      } else {
                                        setState({ hoveredShift: {} });
                                      }
                                    }}
                                    onMouseLeave={() => {
                                      if (
                                        memoVals.width >
                                        hoverButtonsAtScreenWidth
                                      ) {
                                        setState({ hoveredShift: {} });
                                      }
                                    }}
                                  >
                                    <div className="shiftBoxLeft">
                                      <div
                                        className={`shiftLight shiftLightBlue ${
                                          open.bidUserIDs.length > 0
                                            ? "biddersShiftLight"
                                            : ""
                                        }`}
                                      ></div>
                                    </div>
                                    <div
                                      className={`shiftBoxMiddle ${
                                        open.tags && open.tags[0] && basicView
                                          ? "marginTop10"
                                          : ""
                                      }`}
                                    >
                                      {" "}
                                      {open.jt ? (
                                        <p className="teamSchedOpenJt">
                                          {dateStringer.shorten(open.jt, 18)}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                      <div className="shiftBoxMiddleRow1 x4859868998">
                                        <p
                                          className={`openShiftTimeStrip ${
                                            dateStringer.dsToTimeStrip(
                                              open.startDs,
                                              false
                                            ).length > 4 ||
                                            dateStringer.dsToTimeStrip(
                                              open.endDs,
                                              false
                                            ).length > 4
                                              ? "makeTimesSmaller"
                                              : ""
                                          } ${
                                            open.tags && open.tags[0]
                                              ? "tsStripWithOpenTags"
                                              : ""
                                          } `}
                                        >
                                          {`${dateStringer.dsToTimeStrip(
                                            open.startDs,
                                            false
                                          )} - ${dateStringer.dsToTimeStrip(
                                            open.endDs,
                                            false
                                          )}`}
                                        </p>
                                      </div>
                                      <div
                                        className={`shiftBoxMiddleRow2 openMiddleRow ${
                                          state.hoveredShift.typeID ===
                                          open.openID
                                            ? ""
                                            : ""
                                        } ${
                                          open.tags && open.tags[0]
                                            ? "openTagsClaimersTxt"
                                            : ""
                                        }`}
                                      >
                                        {open.bidUserIDs.length === 0 ? (
                                          <p className={`bidderQty`}>No bids</p>
                                        ) : (
                                          <p className={`bidderQty`}>
                                            {" "}
                                            {open.bidUserIDs.length} bid
                                            {open.bidUserIDs.length === 1
                                              ? ""
                                              : "s"}{" "}
                                          </p>
                                        )}
                                        <p className="openRate">
                                          {open.rate || 1}x
                                        </p>
                                      </div>
                                      {/*  */}
                                      {/*  */}
                                    </div>

                                    <div className="shiftBoxRight">
                                      <p className="shiftBoxTypeTxtOpen">
                                        OPEN
                                      </p>
                                    </div>
                                  </div>
                                  <div className="openInlineTags">
                                    {open.tags && open.tags[0]
                                      ? generateTags
                                      : ""}
                                  </div>
                                </div>
                              );
                            } else {
                              return "initial";
                            }
                          });

                        let generateDayLimitData = (index) => {
                          let limitObj = {};

                          if (index === 0) {
                            limitObj = state.dayLimitData.mon || {};
                          } else if (index === 1) {
                            limitObj = state.dayLimitData.tue || {};
                          } else if (index === 2) {
                            limitObj = state.dayLimitData.wed || {};
                          } else if (index === 3) {
                            limitObj = state.dayLimitData.thu || {};
                          } else if (index === 4) {
                            limitObj = state.dayLimitData.fri || {};
                          } else if (index === 5) {
                            limitObj = state.dayLimitData.sat || {};
                          } else if (index === 6) {
                            limitObj = state.dayLimitData.sun || {};
                          }

                          return (
                            <div
                              className={`${
                                state.pasteLoading ||
                                state.loadingMove ||
                                state.loadingLimits
                                  ? "none"
                                  : "dayLimitPowerDiv"
                              } ${state.hideSchedLimits ? "none" : ""} ${
                                state.limLoad === `${ds}dayload`
                                  ? "limLoad"
                                  : ""
                              } ${state.limLoad ? "limLoad" : ""}`}
                              onClick={() => {
                                if (streamMovingShift.shiftID) {
                                  setStreamMovingShift({ shiftID: "" });
                                }
                                if (moveUnit.itemID) {
                                  setMoveUnit({});
                                }
                              }}
                            >
                              <div
                                className="limitTitleAndPower"
                                onClick={() => {
                                  setState({ limLoad: `${ds}dayload` });
                                  axios
                                    .post(
                                      `${serverURL}/edit-day-limit-data-for-ds-on-team`,
                                      {
                                        teamID: selectedTeam,
                                        ds: ds,
                                        enable: !limitObj.enable ? true : false,
                                        limitByAll: limitObj.enable
                                          ? false
                                          : true,
                                        maxAllOff: limitObj.maxAllOff,
                                        limitByJobTitle: limitObj.enable
                                          ? true
                                          : false,
                                      },

                                      {
                                        withCredentials: true,
                                        credentials: "include",
                                      }
                                    )

                                    .then((response) => {
                                      if (response.data.message === "success") {
                                        // memoVals.setUpdateInfo(x =>!memoVals.updateInfo);
                                        axios
                                          .post(
                                            `${serverURL}/get-team-schedule-limit-all-data`,
                                            {
                                              teamID: selectedTeam,
                                              monWeek: selectedMonWeek,
                                            },
                                            {
                                              withCredentials: true,
                                              credentials: "include",
                                            }
                                          )
                                          .then((response2) => {
                                            if (
                                              response2.data.message ===
                                              "success"
                                            ) {
                                              setState({
                                                dayLimitData:
                                                  response2.data.output,
                                                jtLimitData:
                                                  response2.data
                                                    .jtSchedLimitData,
                                                limLoad: false,
                                              });
                                              setJtsThatArePrimaryTeamedWithThisTeam(
                                                response2.data
                                                  .jtsThatArePrimaryTeamedWithThisTeam
                                              );
                                            }
                                          });
                                      }
                                    })
                                    .catch((err) => {
                                      console.error(err);
                                    });
                                }}
                              >
                                {/* <p className="limitDayTitle">Limit</p> */}

                                {/*  KNOB BEGIN */}
                                <div
                                  className={`editUserReqClockOutKnobContainer x99001 dayLimknob`}
                                >
                                  {/* <div className="knobContainer"> */}
                                  <div
                                    className={`switchKnob ${
                                      limitObj.enable
                                        ? "knobOn knobOnMgrColour knobRed"
                                        : "knobOff"
                                    } limKnob`}
                                  ></div>
                                  <div className="knobBg knobBgLim"></div>
                                  {/* </div> */}
                                </div>
                                {/*  KNOB END */}

                                {/* <img
                                    onClick={() => {
                                      setState({ limLoad: `${ds}dayload` });
                                      axios
                                        .post(
                                          `${serverURL}/edit-day-limit-data-for-ds-on-team`,
                                          {
                                            teamID: selectedTeam,
                                            ds: ds,
                                            enable: !limitObj.enable
                                              ? true
                                              : false,
                                            limitByAll: limitObj.enable
                                              ? false
                                              : true,
                                            maxAllOff: limitObj.maxAllOff,
                                            limitByJobTitle: limitObj.enable
                                              ? true
                                              : false,
                                          },

                                          {
                                            withCredentials: true,
                                            credentials: "include",
                                          }
                                        )

                                        .then((response) => {
                                          if (
                                            response.data.message === "success"
                                          ) {
                                            // memoVals.setUpdateInfo(x =>!memoVals.updateInfo);
                                            axios
                                              .post(
                                                `${serverURL}/get-team-schedule-limit-all-data`,
                                                {
                                                  teamID: selectedTeam,
                                                  monWeek: selectedMonWeek,
                                                },
                                                {
                                                  withCredentials: true,
                                                  credentials: "include",
                                                }
                                              )
                                              .then((response2) => {
                                                if (
                                                  response2.data.message ===
                                                  "success"
                                                ) {
                                                  setState({
                                                    dayLimitData:
                                                      response2.data.output,
                                                    jtLimitData:
                                                      response2.data
                                                        .jtSchedLimitData,
                                                    limLoad: false,
                                                  });
                                                  setJtsThatArePrimaryTeamedWithThisTeam(
                                                    response2.data
                                                      .jtsThatArePrimaryTeamedWithThisTeam
                                                  );
                                                }
                                              });
                                          }
                                        })
                                        .catch((err) => {
                                          console.error(err);
                                        });
                                    }}
                                    src={schedLimitPowerOff}
                                    className={`${
                                      limitObj.enable
                                        ? "dayLimitPowerOn"
                                        : "dayLimitPowerOff"
                                    }`}
                                    alt="Disable/enable day limit"
                                  /> */}
                              </div>
                              {limitObj.enable ? (
                                <div
                                  className={`jobTitleOrDayDiv ${
                                    limitObj.enable ? "" : ""
                                  }`}
                                >
                                  {/* <p className="limitByAllTxt">Limit by</p> */}
                                  <select
                                    className="selectTeamOrJtLimitDropdown"
                                    value={limitObj.limitByAll ? "team" : "jt"}
                                    onChange={(e) => {
                                      setState({ limLoad: `${ds}dayload` });
                                      let limitByAll =
                                        e.target.value === "team"
                                          ? true
                                          : false;
                                      let limitByJobTitle =
                                        e.target.value === "jt" ? true : false;

                                      axios
                                        .post(
                                          `${serverURL}/edit-day-limit-data-for-ds-on-team`,
                                          {
                                            teamID: selectedTeam,
                                            ds: ds,
                                            enable: limitObj.enable,
                                            limitByAll:
                                              e.target.value === "team"
                                                ? true
                                                : false,
                                            maxAllOff: limitObj.maxAllOff,
                                            limitByJobTitle:
                                              e.target.value === "jt"
                                                ? true
                                                : false,
                                          },

                                          {
                                            withCredentials: true,
                                            credentials: "include",
                                          }
                                        )
                                        .then((response) => {
                                          if (
                                            response.data.message === "success"
                                          ) {
                                            let dsObj = new Date(
                                              dateStringer.createTimestampFromString(
                                                ds
                                              )
                                            );
                                            let dayS = "sun";
                                            if (dsObj.getDay() === 1) {
                                              dayS = "mon";
                                            }
                                            if (dsObj.getDay() === 2) {
                                              dayS = "tue";
                                            }
                                            if (dsObj.getDay() === 3) {
                                              dayS = "wed";
                                            }
                                            if (dsObj.getDay() === 4) {
                                              dayS = "thu";
                                            }
                                            if (dsObj.getDay() === 5) {
                                              dayS = "fri";
                                            }
                                            if (dsObj.getDay() === 6) {
                                              dayS = "sat";
                                            }

                                            let filtered =
                                              state.dayLimitData[dayS];
                                            //- - -
                                            let currOff = filtered.currOff;
                                            let enable = limitObj.enable;
                                            // let limitByAll = e.target.value === "team" ? true : false;
                                            // let limitByJobTitle =
                                            //   e.target.value === "jt" ? true : false;
                                            let maxAllOff = limitObj.maxAllOff;

                                            let objToAdd = {
                                              currOff,
                                              ds,
                                              enable,
                                              limitByAll,
                                              limitByJobTitle,
                                              maxAllOff,
                                              teamID: selectedTeam,
                                            };
                                            let newObj = {};
                                            if (dsObj.getDay() === 0) {
                                              newObj = {
                                                sun: objToAdd,
                                                mon: state.dayLimitData["mon"],
                                                tue: state.dayLimitData["tue"],
                                                wed: state.dayLimitData["wed"],
                                                thu: state.dayLimitData["thu"],
                                                fri: state.dayLimitData["fri"],
                                                sat: state.dayLimitData["sat"],
                                              };
                                            }
                                            if (dsObj.getDay() === 1) {
                                              newObj = {
                                                sun: state.dayLimitData["sun"],
                                                mon: objToAdd,
                                                tue: state.dayLimitData["tue"],
                                                wed: state.dayLimitData["wed"],
                                                thu: state.dayLimitData["thu"],
                                                fri: state.dayLimitData["fri"],
                                                sat: state.dayLimitData["sat"],
                                              };
                                            }
                                            if (dsObj.getDay() === 2) {
                                              newObj = {
                                                sun: state.dayLimitData["sun"],
                                                mon: state.dayLimitData["mon"],
                                                tue: objToAdd,
                                                wed: state.dayLimitData["wed"],
                                                thu: state.dayLimitData["thu"],
                                                fri: state.dayLimitData["fri"],
                                                sat: state.dayLimitData["sat"],
                                              };
                                            }
                                            if (dsObj.getDay() === 3) {
                                              newObj = {
                                                sun: state.dayLimitData["sun"],
                                                mon: state.dayLimitData["mon"],
                                                tue: state.dayLimitData["tue"],
                                                wed: objToAdd,
                                                thu: state.dayLimitData["thu"],
                                                fri: state.dayLimitData["fri"],
                                                sat: state.dayLimitData["sat"],
                                              };
                                            }
                                            if (dsObj.getDay() === 4) {
                                              newObj = {
                                                sun: state.dayLimitData["sun"],
                                                mon: state.dayLimitData["mon"],
                                                tue: state.dayLimitData["tue"],
                                                wed: state.dayLimitData["wed"],
                                                thu: objToAdd,
                                                fri: state.dayLimitData["fri"],
                                                sat: state.dayLimitData["sat"],
                                              };
                                            }
                                            if (dsObj.getDay() === 5) {
                                              newObj = {
                                                sun: state.dayLimitData["sun"],
                                                mon: state.dayLimitData["mon"],
                                                tue: state.dayLimitData["tue"],
                                                wed: state.dayLimitData["wed"],
                                                thu: state.dayLimitData["thu"],
                                                fri: objToAdd,
                                                sat: state.dayLimitData["sat"],
                                              };
                                            }
                                            if (dsObj.getDay() === 6) {
                                              newObj = {
                                                sun: state.dayLimitData["sun"],
                                                mon: state.dayLimitData["mon"],
                                                tue: state.dayLimitData["tue"],
                                                wed: state.dayLimitData["wed"],
                                                thu: state.dayLimitData["thu"],
                                                fri: state.dayLimitData["fri"],
                                                sat: objToAdd,
                                              };
                                            }

                                            setState({
                                              dayLimitData: newObj,
                                              limLoad: true,
                                            });

                                            axios
                                              .post(
                                                `${serverURL}/get-team-schedule-limit-all-data`,
                                                {
                                                  teamID: selectedTeam,
                                                  monWeek: selectedMonWeek,
                                                },
                                                {
                                                  withCredentials: true,
                                                  credentials: "include",
                                                }
                                              )
                                              .then((response2) => {
                                                if (
                                                  response2.data.message ===
                                                  "success"
                                                ) {
                                                  setState({
                                                    jtLimitData:
                                                      response2.data
                                                        .jtSchedLimitData,
                                                    limLoad: false,
                                                  });
                                                  setJtsThatArePrimaryTeamedWithThisTeam(
                                                    response2.data
                                                      .jtsThatArePrimaryTeamedWithThisTeam
                                                  );
                                                }
                                              });
                                          }
                                        })
                                        .catch((err) => {
                                          console.error(err);
                                        });
                                    }}
                                  >
                                    <option value="team">Team</option>
                                    <option value="jt">Job title</option>
                                  </select>
                                </div>
                              ) : (
                                <p className="limDisabledTitle">Limits</p>
                              )}
                            </div>
                          );
                        };

                        let generateDayLimitTeamData = (index) => {
                          let limitObj = {};

                          if (index === 0) {
                            limitObj = state.dayLimitData.mon || {};
                          } else if (index === 1) {
                            limitObj = state.dayLimitData.tue || {};
                          } else if (index === 2) {
                            limitObj = state.dayLimitData.wed || {};
                          } else if (index === 3) {
                            limitObj = state.dayLimitData.thu || {};
                          } else if (index === 4) {
                            limitObj = state.dayLimitData.fri || {};
                          } else if (index === 5) {
                            limitObj = state.dayLimitData.sat || {};
                          } else if (index === 6) {
                            limitObj = state.dayLimitData.sun || {};
                          }

                          if (
                            limitObj &&
                            limitObj.enable &&
                            limitObj.limitByAll
                          ) {
                            return (
                              <div
                                className={` ${
                                  state.loadingLimits
                                    ? "none"
                                    : "dayLimContainer"
                                } ${
                                  limitObj.maxAllOff < limitObj.currOff
                                    ? "limitColourBg"
                                    : ""
                                } ${state.limLoad ? "limLoad" : ""}`}
                                onClick={() => {
                                  if (streamMovingShift.shiftID) {
                                    setStreamMovingShift({ shiftID: "" });
                                  }
                                  if (moveUnit.itemID) {
                                    setMoveUnit({});
                                  }
                                }}
                              >
                                <div
                                  className={`dayLimLeft ${
                                    limitObj.maxAllOff < limitObj.currOff
                                      ? "limitExceedDayRed"
                                      : ""
                                  }`}
                                  onClick={() => {
                                    setShowBookedOffExplain({
                                      show: true,
                                      teamName: teamName,
                                      currOff: limitObj.currOff,
                                      maxOff: limitObj.maxAllOff,
                                    });
                                  }}
                                >
                                  <p className="dayLimCurrOffTxt">Off:</p>
                                  <p className="dayLimCurrOffValue">
                                    {limitObj.currOff}
                                  </p>
                                </div>
                                <div className={`dayLimRight `}>
                                  <p
                                    className={`dayLimLimitTxt ${
                                      limitObj.maxAllOff < limitObj.currOff
                                        ? "none"
                                        : ""
                                    }`}
                                  >
                                    Limit:
                                  </p>

                                  <img
                                    src={schedLimitMinus}
                                    className={`dayLimPlus ${
                                      state.limLoad === ds ? "limLoad" : ""
                                    } ${
                                      limitObj.maxAllOff === 0 ? "invis" : ""
                                    } schedLimitMinus`}
                                    onClick={() => {
                                      setState({ limLoad: ds });
                                      axios
                                        .post(
                                          `${serverURL}/edit-day-limit-data-for-ds-on-team`,
                                          {
                                            teamID: selectedTeam,
                                            ds: ds,
                                            enable: limitObj.enable || true,
                                            limitByAll:
                                              limitObj.limitByAll || true,
                                            maxAllOff: limitObj.maxAllOff - 1,
                                            limitByJobTitle:
                                              limitObj.limitByJobTitle || false,
                                          },

                                          {
                                            withCredentials: true,
                                            credentials: "include",
                                          }
                                        )
                                        .then((response) => {
                                          if (
                                            response.data.message === "success"
                                          ) {
                                            // memoVals.setUpdateInfo(x =>!memoVals.updateInfo);
                                            axios
                                              .post(
                                                `${serverURL}/get-team-schedule-limit-all-data`,
                                                {
                                                  teamID: selectedTeam,
                                                  monWeek: selectedMonWeek,
                                                  avoidJts: true,
                                                },
                                                {
                                                  withCredentials: true,
                                                  credentials: "include",
                                                }
                                              )
                                              .then((response2) => {
                                                if (
                                                  response2.data.message ===
                                                  "success"
                                                ) {
                                                  setState({
                                                    dayLimitData:
                                                      response2.data.output,
                                                    limLoad: false,
                                                  });
                                                  setJtsThatArePrimaryTeamedWithThisTeam(
                                                    response2.data
                                                      .jtsThatArePrimaryTeamedWithThisTeam
                                                  );
                                                }
                                              });
                                          }
                                        })
                                        .catch((err) => {
                                          console.error(err);
                                        });
                                    }}
                                  />
                                  <div
                                    className={`dayLimLimitValue ${
                                      limitObj.maxAllOff < limitObj.currOff
                                        ? "limitExceedDayRed"
                                        : ""
                                    }`}
                                  >
                                    {" "}
                                    <p
                                      className={`dayLimCurrOffTxt ${
                                        limitObj.maxAllOff < limitObj.currOff
                                          ? ""
                                          : "none"
                                      }`}
                                    >
                                      Limit:
                                    </p>
                                    {limitObj.maxAllOff}{" "}
                                    {/* <span className="dayLimitOffSpan">off</span> */}
                                  </div>

                                  <img
                                    src={schedLimitPlus}
                                    className={`dayLimPlus ${
                                      state.limLoad === ds ? "limLoad" : ""
                                    } schedLimitMinus ${
                                      limitObj.maxAllOff < limitObj.currOff
                                        ? "limitColourPlusBtn"
                                        : ""
                                    }`}
                                    onClick={() => {
                                      setState({ limLoad: ds });
                                      axios
                                        .post(
                                          `${serverURL}/edit-day-limit-data-for-ds-on-team`,
                                          {
                                            teamID: selectedTeam,
                                            ds: ds,
                                            enable: limitObj.enable || true,
                                            limitByAll:
                                              limitObj.limitByAll || true,
                                            maxAllOff: limitObj.maxAllOff + 1,
                                            limitByJobTitle:
                                              limitObj.limitByJobTitle || false,
                                          },

                                          {
                                            withCredentials: true,
                                            credentials: "include",
                                          }
                                        )
                                        .then((response) => {
                                          if (
                                            response.data.message === "success"
                                          ) {
                                            // memoVals.setUpdateInfo(x =>!memoVals.updateInfo);
                                            axios
                                              .post(
                                                `${serverURL}/get-team-schedule-limit-all-data`,
                                                {
                                                  teamID: selectedTeam,
                                                  monWeek: selectedMonWeek,
                                                  avoidJts: true,
                                                },
                                                {
                                                  withCredentials: true,
                                                  credentials: "include",
                                                }
                                              )
                                              .then((response2) => {
                                                if (
                                                  response2.data.message ===
                                                  "success"
                                                ) {
                                                  setState({
                                                    dayLimitData:
                                                      response2.data.output,
                                                    limLoad: false,
                                                  });
                                                  setJtsThatArePrimaryTeamedWithThisTeam(
                                                    response2.data
                                                      .jtsThatArePrimaryTeamedWithThisTeam
                                                  );
                                                }
                                              });
                                          }
                                        })
                                        .catch((err) => {
                                          console.error(err);
                                        });
                                    }}
                                  />
                                </div>
                              </div>
                            );
                          } else if (
                            !limitObj ||
                            (limitObj && !limitObj.enable)
                          ) {
                            return "Disabled limit";
                          } else {
                            return "Set limits by job titles below";
                          }
                        };

                        let generateVis = (ind) => {
                          let vizData = viz.monData;
                          if (ind === 1) {
                            vizData = viz.tueData;
                          }

                          if (ind === 2) {
                            vizData = viz.wedData;
                          }

                          if (ind === 3) {
                            vizData = viz.thuData;
                          }

                          if (ind === 4) {
                            vizData = viz.friData;
                          }

                          if (ind === 5) {
                            vizData = viz.satData;
                          }

                          if (ind === 6) {
                            vizData = viz.sunData;
                          }

                          let startTimeStrip = dateStringer.dsToTimeStrip(
                            viz.totalMinDs
                          );
                          let endTimeStrip = dateStringer.dsToTimeStrip(
                            viz.totalMaxDs
                          );
                          let midTs =
                            dateStringer.createTimestampFromString(
                              viz.totalMaxDs
                            ) -
                            (dateStringer.createTimestampFromString(
                              viz.totalMaxDs
                            ) -
                              dateStringer.createTimestampFromString(
                                viz.totalMinDs
                              )) /
                              2;
                          let midTimeStrip = dateStringer.dsToTimeStrip(
                            dateStringer.createStringFromTimestamp(midTs)
                          );

                          let generateVizCols = vizData.map((col) => {
                            let arr = [];
                            let looper = viz.userCount;
                            // if (col.qty.length > viz.userCount) {
                            //   looper = col.qty.length;
                            // }
                            for (var i = 0; i < looper; i++) {
                              if (col.qty[i]) {
                                arr.push("yes");
                              } else {
                                arr.push("no");
                              }
                            }

                            let generateUnits = arr.map((unit) => {
                              return (
                                <div
                                  className={`vizItem ${
                                    unit === "no" ? "noVisItem" : ""
                                  }`}
                                >
                                  &nbsp;
                                </div>
                              );
                            });

                            return (
                              <div className="vizCol">{generateUnits}</div>
                            );
                          });

                          return (
                            <div className="vizContent">
                              <div className="vizDataBox">
                                {generateVizCols}
                              </div>{" "}
                              <div className="vizKeyDivBullets">
                                <p className="vizKeyBullet"></p>
                                <p className="vizKeyBullet"></p>
                                <p className="vizKeyBullet"></p>
                              </div>
                              <div
                                className="vizKeyDiv"
                                onClick={() => {
                                  console.log({ viz });
                                }}
                              >
                                <p className="vizKeyStartEndTime">
                                  {!startTimeStrip.includes("1970") &&
                                    startTimeStrip}
                                </p>
                                <p className="vizKeyStartEndTime">
                                  {!midTimeStrip.includes("1970") &&
                                    midTimeStrip}
                                </p>
                                <p className="vizKeyStartEndTime">
                                  {!endTimeStrip.includes("1970") &&
                                    endTimeStrip}
                                </p>
                              </div>
                            </div>
                          );
                        };

                        return (
                          <div
                            className={`dayCol streamlinedDayCol ${
                              memoVals.mob ? "streamlinedDayColMob" : ""
                            }`}
                          >
                            <div
                              className={`dayColWidener ${
                                isDayLimitedByJt(dsIndex)
                                  ? "widerDayColStreamJt"
                                  : ""
                              } ${memoVals.mob ? "dayColWidenerMob" : ""} ${
                                ds === todayDs ? "todayStreamCol" : ""
                              }`}
                              ref={
                                // generateDayString(ds) === "Today"
                                ds === todayDs ? targetRef : nullRef
                              }
                              // railway
                            >
                              {" "}
                              <div
                                className="streamlinedA1"
                                onClick={() => {
                                  if (streamMovingShift.shiftID) {
                                    setStreamMovingShift({ shiftID: "" });
                                  }
                                  if (moveUnit.itemID) {
                                    setMoveUnit({});
                                  }
                                }}
                              >
                                <p className="streamDayTitle">
                                  {parseInt(ds.substr(9, 2))}{" "}
                                  <span className="streamDayTitleDay">
                                    {ds === todayDs
                                      ? "Today"
                                      : dateStringer.getThreeDayLettersFromDs(
                                          ds
                                        )}
                                    {/* {generateDayString(ds)} */}
                                  </span>
                                </p>
                                <div
                                  className={`rotaCommentDiv ${
                                    locationMode ? "none" : ""
                                  }`}
                                  onClick={() => {
                                    memoVals.setShowDayCommentModal((x) => {
                                      return {
                                        show: true,
                                        isMgr: true,
                                        teamID: selectedTeam,
                                        ds: ds,
                                      };
                                    });
                                  }}
                                >
                                  <img
                                    src={commentRota}
                                    alt="Day comments"
                                    className={`dayCommentIcon ${
                                      getRotaQty(dsIndex)
                                        ? "fullOpac hoverCommentActive"
                                        : "x9247925"
                                    }`}
                                  />
                                  {getRotaQty(dsIndex) ? (
                                    <p className="rotaCommentsQty">
                                      {getRotaQty(dsIndex)}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              {showViz && !state.loading ? (
                                <div
                                  className={`vizBox ${
                                    memoVals.mob ? "vizBoxMob" : ""
                                  }`}
                                >
                                  {generateVis(dsIndex)}
                                </div>
                              ) : (
                                ""
                              )}
                              {showSpecDays() && !state.loading ? (
                                <div
                                  className={`${
                                    showSpecDays() && !state.loading
                                      ? "specDayRow specDayRowStream"
                                      : "none"
                                  } ${
                                    specDay(ds) &&
                                    `activeSpecDayRow ${
                                      dsIndex === 0 ? "activeSpecDayRowMon" : ""
                                    }`
                                  }`}
                                >
                                  <p>
                                    {specDay(ds)}{" "}
                                    {specDay(ds) ? (
                                      <span className="specDayClosedSpan">
                                        {specDayClosed(ds) ? "Closed" : "Open"}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </p>{" "}
                                  {/* <img
              src={commentRota}
              alt="Day comments"
              className="dayCommentIcon"
            /> */}
                                </div>
                              ) : (
                                ""
                              )}
                              {highlightedComments[0] ? (
                                <div
                                  className={`highlightedCommBox ${
                                    highlight()
                                      ? `highlightedBG ${
                                          highlight().length > 70
                                            ? "smallerHighlight"
                                            : ""
                                        }`
                                      : ""
                                  }`}
                                  onClick={() => {
                                    if (highlight()) {
                                      memoVals.setShowDayCommentModal((x) => {
                                        return {
                                          show: true,
                                          isMgr: true,
                                          teamID: selectedTeam,
                                          ds: ds,
                                        };
                                      });
                                    }
                                  }}
                                >
                                  {highlight() ? (
                                    <p className="highlightExclaim">!</p>
                                  ) : (
                                    ""
                                  )}
                                  <p className="highlightTxt">
                                    {dateStringer.shorten(highlight(), 90)}
                                  </p>
                                  {highlight() ? (
                                    <p className="highlightExclaim">!</p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                              <div
                                className={`openShiftsArrayDiv ${
                                  (state.sunOpens.length === 0 &&
                                    state.monOpens.length === 0 &&
                                    state.tueOpens.length === 0 &&
                                    state.wedOpens.length === 0 &&
                                    state.thuOpens.length === 0 &&
                                    state.friOpens.length === 0 &&
                                    state.satOpens.length === 0) ||
                                  state.pasteLoading
                                    ? "zeroOpensHeight"
                                    : ""
                                }  
                                
          
`}
                                onClick={() => {
                                  if (streamMovingShift.shiftID) {
                                    setStreamMovingShift({ shiftID: "" });
                                  }
                                  if (moveUnit.itemID) {
                                    setMoveUnit({});
                                  }
                                }}
                              >
                                {generateOpens}

                                <div
                                  className={`${
                                    selectedHorizAdd === `${ds}open`
                                      ? `horizAddShiftBtn ${
                                          selectedHorizAdd === `${ds}open`
                                            ? "horizAddBtnActiveGreen"
                                            : ""
                                        }`
                                      : "horizAddShiftBtn fadedHorizAddOpen greenHorizBtn"
                                  } ${dayOpens.length === 0 ? "none" : ""} ${
                                    pastable && "none"
                                  } ${state.pasteLoading && "none"} `}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    scheduleNewShift("open", ds);
                                    setShowTip(false);

                                    if (streamMovingShift.shiftID) {
                                      setStreamMovingShift({ shiftID: "" });
                                    }
                                    if (moveUnit.itemID) {
                                      setMoveUnit({});
                                    }
                                  }}
                                  onMouseEnter={() => {
                                    setSelectedHorizAdd(`${ds}open`);
                                    setSureDelete("");
                                    setMoveUnit({});
                                  }}
                                  onMouseLeave={() => {
                                    setSelectedHorizAdd("");
                                    setSureDelete("");
                                  }}
                                >
                                  <p
                                    className={`addShiftPlusTxt ${
                                      selectedHorizAddShift === `${ds}open`
                                        ? "rotatePlusTxt"
                                        : ""
                                    }`}
                                    // onClick={(e) => {
                                    //   e.stopPropagation();
                                    // }}
                                    onClick={() => {
                                      setState({ hoveredShift: {} });
                                    }}
                                  >
                                    +
                                  </p>
                                </div>
                                <div
                                  className={`${
                                    dayOpens.length === 0 && !state.pasteLoading
                                      ? (state.sunOpens.length === 0 &&
                                          state.monOpens.length === 0 &&
                                          state.tueOpens.length === 0 &&
                                          state.wedOpens.length === 0 &&
                                          state.thuOpens.length === 0 &&
                                          state.friOpens.length === 0 &&
                                          state.satOpens.length === 0) ||
                                        state.pasteLoading
                                        ? "addOpenShiftEmptyNil"
                                        : "addOpenShiftEmpty"
                                      : "none"
                                  }`}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    scheduleNewShift("open", ds);
                                    setMoveUnit({});
                                    setShowTip(false);
                                  }}
                                  onMouseEnter={() => {
                                    setSelectedHorizAdd(`${ds}open`);
                                    setSureDelete("");
                                  }}
                                  onMouseLeave={() => {
                                    setSelectedHorizAdd("");
                                    setSureDelete("");
                                  }}
                                >
                                  <p
                                    className={`addShiftPlusTxt ${
                                      selectedHorizAddShift === `${ds}open`
                                        ? "rotatePlusTxt"
                                        : ""
                                    }`}
                                    onClick={() => {
                                      setState({ hoveredShift: {} });
                                    }}
                                  >
                                    +
                                  </p>
                                </div>
                              </div>
                              <div
                                className={`limitRowDayDataDiv  ${
                                  (state.pasteLoading ||
                                    state.loadingMove ||
                                    state.hideSchedLimits ||
                                    state.loadingLimits) &&
                                  state.hideSchedLimits
                                    ? "fadeLimitDiv none_"
                                    : ""
                                } `}
                              >
                                {generateDayLimitData(dsIndex)}
                              </div>
                              <div
                                className={`${
                                  (state.dayLimitData.mon &&
                                    state.dayLimitData.mon.enable) ||
                                  (state.dayLimitData.tue &&
                                    state.dayLimitData.tue.enable) ||
                                  (state.dayLimitData.wed &&
                                    state.dayLimitData.wed.enable) ||
                                  (state.dayLimitData.thu &&
                                    state.dayLimitData.thu.enable) ||
                                  (state.dayLimitData.fri &&
                                    state.dayLimitData.fri.enable) ||
                                  (state.dayLimitData.sat &&
                                    state.dayLimitData.sat.enable) ||
                                  (state.dayLimitData.sun &&
                                    state.dayLimitData.sun.enable)
                                    ? "teamLimitEditDiv"
                                    : "none"
                                } ${
                                  generateDayLimitTeamData(dsIndex) ===
                                  "Set limits by job titles below"
                                    ? "notApplicTeamLimitEditDiv"
                                    : ""
                                } ${
                                  generateDayLimitTeamData(dsIndex) ===
                                  "Disabled limit"
                                    ? "disableLimit"
                                    : ""
                                } ${
                                  state.pasteLoading ||
                                  state.loadingMove ||
                                  state.loadingLimits
                                    ? "none"
                                    : ""
                                } ${state.hideSchedLimits ? "none" : ""} `}
                              >
                                {/* edit limit data here curry */}
                                {generateDayLimitTeamData(dsIndex)}
                              </div>
                              {state.jobTitles.map((jt) => {
                                let generateSchedLimit = (jt, day) => {
                                  let active = false;
                                  let currOff = 0;
                                  let maxOff = 0;
                                  state.jtLimitData &&
                                    state.jtLimitData.forEach((lim) => {
                                      if (
                                        lim.ds === day &&
                                        lim.jt === jt &&
                                        lim.active
                                      ) {
                                        active = true;
                                        currOff = lim.currOff;
                                        maxOff = lim.maxOff;
                                      }
                                    });

                                  let showDayLimit = (ds) => {
                                    let dateObj = new Date(
                                      dateStringer.createTimestampFromString(ds)
                                    );
                                    if (dateObj.getDay() === 1) {
                                      // return true;
                                      if (
                                        state.dayLimitData.mon &&
                                        state.dayLimitData.mon.enable &&
                                        state.dayLimitData.mon.limitByJobTitle
                                      ) {
                                        return true;
                                      }
                                    } else if (dateObj.getDay() === 2) {
                                      if (
                                        state.dayLimitData.tue &&
                                        state.dayLimitData.tue.enable &&
                                        state.dayLimitData.tue.limitByJobTitle
                                      ) {
                                        return true;
                                      }
                                    } else if (dateObj.getDay() === 3) {
                                      if (
                                        state.dayLimitData.wed &&
                                        state.dayLimitData.wed.enable &&
                                        state.dayLimitData.wed.limitByJobTitle
                                      ) {
                                        return true;
                                      }
                                    } else if (dateObj.getDay() === 4) {
                                      if (
                                        state.dayLimitData.thu &&
                                        state.dayLimitData.thu.enable &&
                                        state.dayLimitData.thu.limitByJobTitle
                                      ) {
                                        return true;
                                      }
                                    } else if (dateObj.getDay() === 5) {
                                      if (
                                        state.dayLimitData.fri &&
                                        state.dayLimitData.fri.enable &&
                                        state.dayLimitData.fri.limitByJobTitle
                                      ) {
                                        return true;
                                      }
                                    } else if (dateObj.getDay() === 6) {
                                      if (
                                        state.dayLimitData.sat &&
                                        state.dayLimitData.sat.enable &&
                                        state.dayLimitData.sat.limitByJobTitle
                                      ) {
                                        return true;
                                      }
                                    } else if (dateObj.getDay() === 0) {
                                      if (
                                        state.dayLimitData.sun &&
                                        state.dayLimitData.sun.enable &&
                                        state.dayLimitData.sun.limitByJobTitle
                                      ) {
                                        return true;
                                      }
                                    } else {
                                      return false;
                                    }
                                  };
                                  return (
                                    <div
                                      className={`jtUserSchedLimitDiv ${
                                        showDayLimit(day) &&
                                        !state.loadingLimits
                                          ? ""
                                          : "none"
                                      } ${
                                        state.hideSchedLimits || state.loading
                                          ? "none"
                                          : ""
                                      } ${state.limLoad ? "limLoad" : ""}
            
            `}
                                    >
                                      {jtsThatArePrimaryTeamedWithThisTeam.includes(
                                        jt
                                      ) ? (
                                        <p
                                          className={`${
                                            active ? "none" : "limitTitle"
                                          }`}
                                        >
                                          No limit
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                      {jtsThatArePrimaryTeamedWithThisTeam.includes(
                                        jt
                                      ) ? (
                                        <img
                                          src={schedLimitMinus}
                                          alt="Deduct one"
                                          className={`${
                                            active ? "schedLimitIcon" : "none"
                                          } ${
                                            state.limLoad === `${day}${jt}`
                                              ? "limLoad"
                                              : ""
                                          } ${
                                            maxOff < 1 ? "none" : ""
                                          } schedLimPlus`}
                                          onClick={() => {
                                            setState({
                                              limLoad: `${day}${jt}`,
                                            });

                                            axios
                                              .post(
                                                `${serverURL}/activate-and-edit-sched-limit`,
                                                {
                                                  teamID: selectedTeam,
                                                  ds: day,
                                                  jobTitle: jt,
                                                  activate: active,
                                                  deductOne: true,
                                                  plusOne: false,
                                                  newMaxOff:
                                                    maxOff > 0 ? maxOff - 1 : 0,
                                                },

                                                {
                                                  withCredentials: true,
                                                  credentials: "include",
                                                }
                                              )
                                              .then((response) => {
                                                if (
                                                  response.data.message ===
                                                  "success"
                                                ) {
                                                  let currOff = 0;
                                                  let newArr = [];
                                                  state.jtLimitData.forEach(
                                                    (obj) => {
                                                      if (
                                                        obj.ds === day &&
                                                        obj.jt === jt
                                                      ) {
                                                        currOff = obj.currOff;
                                                      } else {
                                                        newArr.push(obj);
                                                      }
                                                    }
                                                  );
                                                  let newObj = {
                                                    ds: day,
                                                    jt: jt,
                                                    active:
                                                      response.data.active,
                                                    maxOff:
                                                      response.data.maxOff,
                                                    currOff: currOff,
                                                  };

                                                  newArr.push(newObj);
                                                  setState({
                                                    jtLimitData: newArr,
                                                    limLoad: "",
                                                  });
                                                }
                                              })
                                              .catch((err) => {
                                                console.error(err);
                                              });
                                          }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {jtsThatArePrimaryTeamedWithThisTeam.includes(
                                        jt
                                      ) ? (
                                        <div
                                          className={`${
                                            active
                                              ? "schedLimitDataTxtCont"
                                              : "none"
                                          }`}
                                        >
                                          {/* <p className="totAllowedOff">Limit</p> */}
                                          <p
                                            className={`totAllowedOffActual ${
                                              currOff > maxOff
                                                ? "limitOKMaxOff"
                                                : ""
                                            }`}
                                          >
                                            <span
                                              className={`${
                                                currOff > maxOff
                                                  ? "limitExceededCurrOff"
                                                  : ""
                                              }`}
                                            >
                                              {currOff}
                                            </span>{" "}
                                            <span className="limitOffSpan">
                                              off
                                            </span>{" "}
                                            / {maxOff}
                                          </p>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      {jtsThatArePrimaryTeamedWithThisTeam.includes(
                                        jt
                                      ) ? (
                                        <img
                                          src={schedLimitPlus}
                                          alt="Add or deduct one"
                                          className={`${
                                            active ? "schedLimitIcon" : "none"
                                          } ${active ? "schedLimPlus" : ""} ${
                                            state.limLoad === `${day}${jt}`
                                              ? "limLoad"
                                              : ""
                                          } ${
                                            limitColour(day, jt)
                                              ? "limitColourPlusBtn"
                                              : ""
                                          }`}
                                          onClick={() => {
                                            setState({
                                              limLoad: `${day}${jt}`,
                                            });

                                            axios
                                              .post(
                                                `${serverURL}/activate-and-edit-sched-limit`,
                                                {
                                                  teamID: selectedTeam,
                                                  ds: day,
                                                  jobTitle: jt,
                                                  activate: active,
                                                  newMaxOff: maxOff + 1,
                                                  // plusOne: true,
                                                  // deductOne: false,
                                                },

                                                {
                                                  withCredentials: true,
                                                  credentials: "include",
                                                }
                                              )
                                              .then((response) => {
                                                if (
                                                  response.data.message ===
                                                  "success"
                                                ) {
                                                  let currOff = 0;
                                                  let newArr = [];
                                                  state.jtLimitData.forEach(
                                                    (obj) => {
                                                      if (
                                                        obj.ds === day &&
                                                        obj.jt === jt
                                                      ) {
                                                        currOff = obj.currOff;
                                                      } else {
                                                        newArr.push(obj);
                                                      }
                                                    }
                                                  );
                                                  let newObj = {
                                                    ds: day,
                                                    jt: jt,
                                                    active:
                                                      response.data.active,
                                                    maxOff:
                                                      response.data.maxOff,
                                                    currOff: currOff,
                                                  };

                                                  newArr.push(newObj);
                                                  setState({
                                                    jtLimitData: newArr,
                                                    limLoad: "",
                                                  });
                                                }
                                              })
                                              .catch((err) => {
                                                console.error(err);
                                              });
                                          }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {jtsThatArePrimaryTeamedWithThisTeam.includes(
                                        jt
                                      ) ? (
                                        <div
                                          className="jtPowerSwitch"
                                          onClick={() => {
                                            setState({
                                              limLoad: `${day}${jt}`,
                                            });

                                            axios
                                              .post(
                                                `${serverURL}/activate-and-edit-sched-limit`,
                                                {
                                                  teamID: selectedTeam,
                                                  ds: day,
                                                  jobTitle: jt,
                                                  activate: !active,
                                                  newMaxOff: !active
                                                    ? 2
                                                    : maxOff,
                                                  plusOne: false,
                                                  deductOne: false,
                                                },

                                                {
                                                  withCredentials: true,
                                                  credentials: "include",
                                                }
                                              )
                                              .then((response) => {
                                                if (
                                                  response.data.message ===
                                                  "success"
                                                ) {
                                                  let currOff = 0;
                                                  let newArr = [];
                                                  state.jtLimitData.forEach(
                                                    (obj) => {
                                                      if (
                                                        obj.ds === day &&
                                                        obj.jt === jt
                                                      ) {
                                                        currOff = obj.currOff;
                                                      } else {
                                                        newArr.push(obj);
                                                      }
                                                    }
                                                  );
                                                  let newObj = {
                                                    ds: day,
                                                    jt: jt,
                                                    active:
                                                      response.data.active,
                                                    maxOff:
                                                      response.data.maxOff,
                                                    currOff: currOff,
                                                  };

                                                  newArr.push(newObj);
                                                  setState({
                                                    jtLimitData: newArr,
                                                    limLoad: "",
                                                  });
                                                }
                                              })
                                              .catch((err) => {
                                                console.error(err);
                                              });
                                          }}
                                        >
                                          <div
                                            className={`editUserReqClockOutKnobContainer x99001 dayLimknob`}
                                          >
                                            <div
                                              className={`switchKnob ${
                                                active
                                                  ? "knobOn knobOnJtLim"
                                                  : "knobOff"
                                              } limKnob`}
                                            ></div>
                                            <div className="knobBg knobBgLim"></div>
                                          </div>
                                        </div>
                                      ) : (
                                        // <img
                                        //   src={schedLimitPowerOff}
                                        //   alt="Deduct one"
                                        //   className={`schedLimitIcon ${
                                        //     state.limLoad === `${day}${jt}`
                                        //       ? "limLoad"
                                        //       : ""
                                        //   } ${
                                        //     active
                                        //       ? "schedLimPowerOn"
                                        //       : "schedLimPowerOff"
                                        //   }`}
                                        //   onClick={() => {
                                        //     setState({
                                        //       limLoad: `${day}${jt}`,
                                        //     });

                                        //     axios
                                        //       .post(
                                        //         `${serverURL}/activate-and-edit-sched-limit`,
                                        //         {
                                        //           teamID: selectedTeam,
                                        //           ds: day,
                                        //           jobTitle: jt,
                                        //           activate: !active,
                                        //           newMaxOff: !active
                                        //             ? 2
                                        //             : maxOff,
                                        //           plusOne: false,
                                        //           deductOne: false,
                                        //         },

                                        //         {
                                        //           withCredentials: true,
                                        //           credentials: "include",
                                        //         }
                                        //       )
                                        //       .then((response) => {
                                        //         if (
                                        //           response.data.message ===
                                        //           "success"
                                        //         ) {
                                        //           let currOff = 0;
                                        //           let newArr = [];
                                        //           state.jtLimitData.forEach(
                                        //             (obj) => {
                                        //               if (
                                        //                 obj.ds === day &&
                                        //                 obj.jt === jt
                                        //               ) {
                                        //                 currOff = obj.currOff;
                                        //               } else {
                                        //                 newArr.push(obj);
                                        //               }
                                        //             }
                                        //           );
                                        //           let newObj = {
                                        //             ds: day,
                                        //             jt: jt,
                                        //             active:
                                        //               response.data.active,
                                        //             maxOff:
                                        //               response.data.maxOff,
                                        //             currOff: currOff,
                                        //           };

                                        //           newArr.push(newObj);
                                        //           setState({
                                        //             jtLimitData: newArr,
                                        //             limLoad: "",
                                        //           });
                                        //         }
                                        //       })
                                        //       .catch((err) => {
                                        //         console.error(err);
                                        //       });
                                        //   }}
                                        // />
                                        ""
                                      )}
                                      {!jtsThatArePrimaryTeamedWithThisTeam.includes(
                                        jt
                                      ) ? (
                                        <p
                                          className="noPrimsJt"
                                          onClick={() => {
                                            setShowNoPrimLimits(jt);
                                          }}
                                        >
                                          No primary job titles
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  );
                                };

                                return (
                                  <div
                                    className={`streamJtRow ${
                                      memoVals.mob ? "streamJtRowMob_" : ""
                                    }`}
                                  >
                                    <div
                                      className={`streamJtJobTitle ${
                                        memoVals.mob
                                          ? "streamJtJobTitleMob"
                                          : ""
                                      } fitWidth  ${
                                        isDayLimitedByJt(dsIndex)
                                          ? `jtTopperWidthStream ${
                                              ds === yesterdayDs
                                                ? "x123456789"
                                                : ""
                                            }`
                                          : ""
                                      }`}
                                      onDragOver={(event) => {
                                        handleDragOver(event);
                                      }}
                                      onDrop={(event) => {
                                        if (moveUnit.itemID) {
                                          setMoveUnit({});
                                        }
                                      }}
                                      onClick={() => {
                                        if (streamMovingShift.shiftID) {
                                          setStreamMovingShift({ shiftID: "" });
                                        }
                                        if (moveUnit.itemID) {
                                          setMoveUnit({});
                                        }
                                      }}
                                    >
                                      {" "}
                                      <p className="streamDayTitleJt ">
                                        {ds === todayDs
                                          ? `Today`
                                          : dateStringer.getThreeDayLettersFromDs(
                                              ds
                                            )}
                                      </p>
                                      <div className="jtRowSec">
                                        {(!state.pasteLoading &&
                                          !state.hideSchedLimits) ||
                                        !state.loadingLimits
                                          ? generateSchedLimit(jt, ds)
                                          : ""}
                                      </div>
                                    </div>
                                    <div className="shellUser">shellll</div>
                                    {state.sched
                                      .filter((x) => {
                                        return x.jobTitle === jt;
                                      })
                                      .sort((a, b) => {
                                        return a.fName.localeCompare(b.fName);
                                      })
                                      .map((user, indi) => {
                                        if (!collapsedJts.includes(jt)) {
                                          let schedUser = state.sched.filter(
                                            (x) => {
                                              return x.userID === user.userID;
                                            }
                                          );
                                          let userHasAvail =
                                            availIsProvidedForOneOfUsersDays(
                                              user.userID,
                                              true
                                            ) -
                                              (user.nonWorkingDays
                                                ? user.nonWorkingDays.length
                                                : 0) >
                                            0;
                                          let items =
                                            dayIndex === 0
                                              ? schedUser[0].sunItems
                                              : dayIndex === 1
                                              ? schedUser[0].monItems
                                              : dayIndex === 2
                                              ? schedUser[0].tueItems
                                              : dayIndex === 3
                                              ? schedUser[0].wedItems
                                              : dayIndex === 4
                                              ? schedUser[0].thuItems
                                              : dayIndex === 5
                                              ? schedUser[0].friItems
                                              : schedUser[0].satItems;

                                          let shiftsArr = items.filter((x) => {
                                            if (x.ds) {
                                              return (
                                                `${x.ds.substr(
                                                  0,
                                                  11
                                                )}H00M00` === ds
                                              );
                                            }
                                          });
                                          let avail = getAvail(user.userID, ds);
                                          let nonWorkingDay =
                                            (avail && avail.includes("Not")) ||
                                            (!shiftsArr[0] &&
                                              user.nonWorkingDays &&
                                              user.nonWorkingDays.includes(
                                                dayIndex
                                              ));

                                          return (
                                            <div
                                              className="paul"
                                              onDragOver={(event) => {
                                                handleDragOver(event);
                                              }}
                                              onDrop={(event) => {
                                                console.log("dropping");
                                                handleDrop(
                                                  event,

                                                  user.userID,
                                                  ds
                                                );
                                              }}
                                            >
                                              {userHasAvail ? (
                                                <div
                                                  className={`availTest ${
                                                    avail &&
                                                    avail.includes("Not")
                                                      ? "unavailColourTxt"
                                                      : ""
                                                  }`}
                                                  onDragOver={(event) => {
                                                    handleDragOver(event);
                                                  }}
                                                  onDrop={(event) => {
                                                    if (moveUnit.itemID) {
                                                      setMoveUnit({});
                                                    }
                                                  }}
                                                  onClick={() => {
                                                    memoVals.setShowMgrAvailModal(
                                                      (x) => {
                                                        return {
                                                          ds: selectedMonWeek,
                                                          team: selectedTeam,
                                                          userID: user.userID,
                                                        };
                                                      }
                                                    );
                                                  }}
                                                >
                                                  {/* {avail.includes("Not")
                                                      ? ""
                                                      : "Available: "} */}
                                                  <p
                                                    className={`availBlob ${
                                                      avail &&
                                                      avail.includes("Not")
                                                        ? "unavailBlob"
                                                        : ""
                                                    }`}
                                                  ></p>
                                                  {avail === "Not available"
                                                    ? "Unavailable"
                                                    : avail}
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                              <div
                                                // onMouseLeave={() => {
                                                //   if (hoveredInlineWithShifts) {
                                                //     setHoveredInlineWithShifts(
                                                //       ""
                                                //     );
                                                //   }
                                                // }}
                                                // onMouseEnter={() => {
                                                //   console.log("entering paul");
                                                //   setHoveredInlineWithShifts(
                                                //     `${user.userID}${ds}`
                                                //   );
                                                // }}
                                                className={`streamlinedShiftsArr ${
                                                  memoVals.mob
                                                    ? `whiteBg ${
                                                        !nonWorkingDay &&
                                                        "streamlinedShiftsArrMob"
                                                      }`
                                                    : ""
                                                } ${
                                                  state.sched.filter((x) => {
                                                    return x.jobTitle === jt;
                                                  }).length ===
                                                  indi + 1
                                                    ? "streamlinedShiftsArrFinal"
                                                    : ""
                                                } ${
                                                  !shiftsArr[0] &&
                                                  !nonWorkingDay &&
                                                  !avail
                                                    ? `streamPlus ${
                                                        user.userID ===
                                                          memoVals
                                                            .checkUserState
                                                            .userID &&
                                                        !selfManageShifts
                                                          ? "disableSchednewShPlus"
                                                          : ""
                                                      }`
                                                    : ""
                                                } ${
                                                  streamMovingShift.shiftID
                                                    ? "streamMoving"
                                                    : ""
                                                }`}
                                                onClick={() => {
                                                  if (
                                                    streamMovingShift.shiftID
                                                  ) {
                                                    console.log(
                                                      "MOVE SHIFT HERE"
                                                    );

                                                    moveShiftUnit(
                                                      "move",
                                                      ds,
                                                      user.userID,
                                                      streamMovingShift.duplicating
                                                    );
                                                    // let moveShiftUnit = (moveOrDupe, day, userID, duplicate) => {

                                                    setStreamMovingShift({
                                                      shiftID: "",
                                                    });
                                                  } else {
                                                    // add new shift
                                                    if (
                                                      user.userID ===
                                                        memoVals.checkUserState
                                                          .userID &&
                                                      !selfManageShifts
                                                    ) {
                                                    } else {
                                                      if (
                                                        !shiftsArr[0] &&
                                                        !streamMovingShift.shiftID
                                                      ) {
                                                        scheduleNewShift(
                                                          "shift",
                                                          ds,
                                                          user.userID
                                                        );
                                                      }
                                                    }
                                                  }
                                                }}
                                              >
                                                {nonWorkingDay &&
                                                  !shiftsArr[0] &&
                                                  !streamMovingShift.shiftID && (
                                                    <div
                                                      className={`streamlinedNonWorkingDayItem ${
                                                        dsIndex === 0
                                                          ? `streamlinedNonWorkingDayItemMon ${
                                                              !memoVals.mob
                                                                ? "borderLeftNone"
                                                                : ""
                                                            }`
                                                          : ""
                                                      } ${
                                                        !memoVals.mob
                                                          ? ""
                                                          : "marginLeft0"
                                                      } ${
                                                        streamMovingShift.shiftID
                                                          ? "streamlinedNonWorkingDayItemStreaming"
                                                          : ""
                                                      }`}
                                                      onClick={() => {
                                                        // add new shift
                                                        if (
                                                          user.userID ===
                                                            memoVals
                                                              .checkUserState
                                                              .userID &&
                                                          !selfManageShifts
                                                        ) {
                                                        } else {
                                                          scheduleNewShift(
                                                            "shift",
                                                            ds,
                                                            user.userID
                                                          );
                                                        }
                                                      }}
                                                    >
                                                      {/* Non-working day */}+
                                                    </div>
                                                  )}
                                                {!shiftsArr[0] &&
                                                !nonWorkingDay &&
                                                !avail &&
                                                !streamMovingShift.shiftID
                                                  ? "+"
                                                  : ""}
                                                {!shiftsArr &&
                                                  !nonWorkingDay &&
                                                  avail && (
                                                    <p className="streamAvail">
                                                      {avail}
                                                    </p>
                                                  )}
                                                {shiftsArr
                                                  .sort((a, b) => {
                                                    return (
                                                      a.startTs - b.startTs
                                                    );
                                                  })
                                                  .map((sh, shInd) => {
                                                    let timeStrip = `${dateStringer.dsToTimeStrip(
                                                      sh.start
                                                    )} 
                                                    - 
                                                    ${dateStringer.dsToTimeStrip(
                                                      sh.end
                                                    )}`;

                                                    let tilStrip =
                                                      sh.tilApproved
                                                        ? dateStringer.generateTilShiftString(
                                                            sh.tilStart,
                                                            sh.tilEnd,
                                                            `${sh.start.substr(
                                                              12,
                                                              2
                                                            )}:${sh.start.substr(
                                                              15,
                                                              2
                                                            )}`,
                                                            `${sh.end.substr(
                                                              12,
                                                              2
                                                            )}:${sh.end.substr(
                                                              15,
                                                              2
                                                            )}`,
                                                            false,
                                                            "&",
                                                            sh.type === "shift"
                                                              ? "shift"
                                                              : "overtime",
                                                            false,
                                                            true
                                                          )
                                                        : "";
                                                    let generateTags =
                                                      !basicView && sh.tags
                                                        ? sh.tags.map(
                                                            (tag, i) => {
                                                              if (i >= 1) {
                                                                if (
                                                                  i ===
                                                                  sh.tags
                                                                    .length -
                                                                    1
                                                                ) {
                                                                  return (
                                                                    <p
                                                                      className={`shTagInline appendingShTagInline shTagStream lilacColours_`}
                                                                      key={tag}
                                                                    >
                                                                      +
                                                                      {sh.tags
                                                                        .length -
                                                                        1}
                                                                    </p>
                                                                  );
                                                                }
                                                              } else {
                                                                return (
                                                                  <p
                                                                    className={`shTagInline shTagStream lilacColours_`}
                                                                    key={tag}
                                                                  >
                                                                    {shortenTag(
                                                                      tag
                                                                    )}
                                                                  </p>
                                                                );
                                                              }
                                                            }
                                                          )
                                                        : "";
                                                    return (
                                                      <div
                                                        draggable={
                                                          sh.type === "shift" ||
                                                          sh.type === "til" ||
                                                          sh.type === "overtime"
                                                            ? !memoVals.mob
                                                              ? true
                                                              : false
                                                            : false
                                                        }
                                                        onDragStart={(
                                                          event
                                                        ) => {
                                                          if (
                                                            sh.type ===
                                                              "shift" ||
                                                            sh.type === "til" ||
                                                            sh.type ===
                                                              "overtime"
                                                          ) {
                                                            handleDragStart(
                                                              event,
                                                              sh,
                                                              user.userID
                                                            );
                                                          }
                                                        }}
                                                        // mob:
                                                        onTouchStart={(
                                                          event
                                                        ) => {
                                                          if (
                                                            sh.type ===
                                                              "shift" ||
                                                            sh.type ===
                                                              "overtime" ||
                                                            sh.type === "til"
                                                          ) {
                                                            // handleTouchStart(event, shiftID, type, userID, ds)
                                                            // startDs, endDs, durMins
                                                            handleTouchStart(
                                                              event,
                                                              sh.typeID,
                                                              sh.type,
                                                              user.userID,
                                                              ds,
                                                              sh.start,
                                                              sh.end,
                                                              sh.durMins
                                                            );
                                                          }
                                                        }}
                                                        onTouchEnd={(event) => {
                                                          if (
                                                            sh.type ===
                                                              "shift" ||
                                                            sh.type ===
                                                              "overtime" ||
                                                            sh.type === "til"
                                                          ) {
                                                            handleTouchEnd(
                                                              event,
                                                              sh.typeID
                                                            );
                                                          }
                                                        }}
                                                        // desktop
                                                        // onDrop={(event) => {
                                                        //   if (
                                                        //     sh.type ===
                                                        //       "shift" ||
                                                        //     sh.type ===
                                                        //       "til" ||
                                                        //     sh.type ===
                                                        //       "overtime"
                                                        //   ) {
                                                        //     handleDrop(
                                                        //       event,
                                                        //       user.userID,
                                                        //       ds
                                                        //     );
                                                        //   }
                                                        // }}
                                                        onDragOver={(event) => {
                                                          handleDragOver(event);
                                                        }}
                                                        onClick={(e) => {
                                                          if (
                                                            memoVals
                                                              .checkUserState
                                                              .userID ===
                                                              user.userID &&
                                                            !selfManageShifts
                                                          ) {
                                                            setTryingToOpenShiftUserManagesButNotAllowed(
                                                              {
                                                                shiftID:
                                                                  sh.typeID,
                                                                type: sh.type,
                                                              }
                                                            );
                                                          }
                                                          if (
                                                            streamMovingShift.shiftID
                                                          ) {
                                                          } else {
                                                            // memoVals.setIndicate(
                                                            //   (x) => {
                                                            //     return {
                                                            //       show: true,
                                                            //       message: `Select where to move ${
                                                            //         sh.type ===
                                                            //         "til"
                                                            //           ? "lieu shift"
                                                            //           : sh.type
                                                            //       } to`,
                                                            //       colour:
                                                            //         "blue",
                                                            //       duration: 3000,
                                                            //       ts: new Date().getTime(),
                                                            //     };
                                                            //   }
                                                            // );
                                                            // setStreamMovingShift(
                                                            //   {
                                                            //     shiftID:
                                                            //       sh.typeID,
                                                            //     originalUser:
                                                            //       user.userID,
                                                            //     originalDs: ds,
                                                            //   }
                                                            // );
                                                            console.log(
                                                              "shiftID: !!!!!!",
                                                              sh.typeID
                                                            );
                                                            // handleTouchStart(event, shiftID, type, userID, ds)
                                                            if (
                                                              sh.type ===
                                                                "shift" ||
                                                              sh.type ===
                                                                "til" ||
                                                              sh.type ===
                                                                "overtime"
                                                            ) {
                                                              handleTouchStart(
                                                                e,
                                                                sh.typeID,
                                                                sh.type,
                                                                user.userID,
                                                                ds,
                                                                sh.start,
                                                                sh.end,
                                                                sh.durMins
                                                              );
                                                            }
                                                            // paul;
                                                            if (
                                                              sh.type ===
                                                                "shift" ||
                                                              sh.type ===
                                                                "til" ||
                                                              sh.type ===
                                                                "overtime"
                                                            ) {
                                                              memoVals.setShowEditShiftModal(
                                                                (x) => {
                                                                  return {
                                                                    itemID:
                                                                      sh.typeID,
                                                                    itemType:
                                                                      sh.type,
                                                                    itemFName:
                                                                      user.fName,
                                                                    itemLName:
                                                                      user.lName,
                                                                    itemIsNew: false,
                                                                    itemUserID:
                                                                      user.userID,
                                                                  };
                                                                }
                                                              );
                                                            }
                                                            if (
                                                              sh.type ===
                                                                "leave" ||
                                                              sh.type ===
                                                                "absence"
                                                            ) {
                                                              memoVals.setShowReqMgrModal(
                                                                (x) => sh.reqID
                                                              );
                                                            }
                                                          }
                                                        }}
                                                        onMouseLeave={() => {
                                                          if (
                                                            hoveredStreamShiftID
                                                          ) {
                                                            setHoveredStreamShiftID(
                                                              ""
                                                            );
                                                          }
                                                        }}
                                                        onMouseEnter={() => {
                                                          // if (
                                                          //   !hoveredStreamShiftID
                                                          // ) {
                                                          if (!sh.otherTeam) {
                                                            if (
                                                              sh.type ===
                                                                "shift" ||
                                                              sh.type ===
                                                                "til" ||
                                                              sh.type ===
                                                                "overtime"
                                                            ) {
                                                              setHoveredStreamShiftID(
                                                                sh.typeID
                                                              );
                                                            }
                                                          }
                                                          // }
                                                        }}
                                                        className={`streamlinedShiftBox ${
                                                          tagsFilt(sh.tags)
                                                            ? ""
                                                            : "none"
                                                        } ${
                                                          sh.otherTeam
                                                            ? "disabledShiftBox"
                                                            : ""
                                                        } ${
                                                          movingShiftID &&
                                                          movingShiftID ===
                                                            sh.typeID
                                                            ? "invis"
                                                            : ""
                                                        } ${
                                                          shInd ===
                                                            shiftsArr.length -
                                                              1 &&
                                                          shiftsArr.length !== 1
                                                            ? "noBorderRight_"
                                                            : ""
                                                        }  ${
                                                          sh.type === "leave"
                                                            ? sh.isAbsence
                                                              ? `streamlinedShiftBoxAbsence ${
                                                                  sh.ongoing
                                                                    ? "streamlinedShiftBoxAbsenceOngoing"
                                                                    : ""
                                                                }`
                                                              : `streamlinedShiftBoxLeave ${
                                                                  sh.ongoing
                                                                    ? "streamlinedShiftBoxLeaveOngoing"
                                                                    : ""
                                                                }`
                                                            : ""
                                                        } ${
                                                          shInd > 0 &&
                                                          shInd ===
                                                            shiftsArr.length - 1
                                                            ? "finalItemLeaveAbs"
                                                            : ""
                                                        } ${
                                                          (sh.type ===
                                                            "shift" ||
                                                            sh.type === "til" ||
                                                            sh.type ===
                                                              "overtime") &&
                                                          !sh.published
                                                            ? "unpubBw"
                                                            : ""
                                                        } ${
                                                          streamShiftID.shiftID ===
                                                            sh.typeID &&
                                                          sh.type !== "leave"
                                                            ? "streamedSh"
                                                            : ""
                                                        } ${
                                                          streamMovingShift.shiftID ===
                                                          sh.typeID
                                                            ? "movingStreamShiftBox"
                                                            : ""
                                                        } ${
                                                          sh.ghost
                                                            ? "ghostStreamBg"
                                                            : ""
                                                        }`}
                                                      >
                                                        {sh.type === "leave" ? (
                                                          <img
                                                            src={
                                                              sh.isAbsence
                                                                ? streamCross
                                                                : rotaPlane
                                                            }
                                                            alt="On leave"
                                                            className={`streamlinePlane ${
                                                              sh.isAbsence
                                                                ? "fullOpac"
                                                                : ""
                                                            }`}
                                                          />
                                                        ) : (
                                                          <div
                                                            className={`streamlinedShiftBlob ${
                                                              (sh.requireAccept &&
                                                                sh.accepted) ||
                                                              (!sh.requireAccept &&
                                                                !sh.absent) ||
                                                              sh.tsClock
                                                                ? "streamlinedShiftBlobGreen"
                                                                : ""
                                                            } ${
                                                              sh.absent
                                                                ? "streamlinedShiftBlobRed"
                                                                : ""
                                                            } ${
                                                              sh.type ===
                                                              "leave"
                                                                ? "streamlinedShiftOvertimeBlob"
                                                                : sh.type ===
                                                                    "til" ||
                                                                  sh.type ===
                                                                    "overtime"
                                                                ? "streamlinedShiftTilBlob"
                                                                : ""
                                                            } 
                                                             ${
                                                               sh.ghost
                                                                 ? "streamlinedShiftBlobGhost"
                                                                 : ""
                                                             } ${
                                                              sh.otherTeam
                                                                ? "otherTeamBlob"
                                                                : ""
                                                            }
                                                              `}
                                                          ></div>
                                                        )}
                                                        {sh.type === "leave" ||
                                                        sh.type ===
                                                          "absence" ? (
                                                          <div
                                                            className={`streamLeavInfoDiv ${
                                                              sh.isAbsence
                                                                ? "streamLeavInfoDivAbs"
                                                                : ""
                                                            }`}
                                                          >
                                                            <p
                                                              className={`streamLeaveInfoUpper`}
                                                            >
                                                              {sh.leaveTypeName &&
                                                              sh.leaveTypeName
                                                                .length > 15
                                                                ? dateStringer.shorten(
                                                                    sh.leaveTypeName,
                                                                    13
                                                                  )
                                                                : sh.leaveTypeName}
                                                            </p>
                                                            <p
                                                              className={`streamLeaveInfoLower ${
                                                                user.primaryTeamID !==
                                                                selectedTeam
                                                                  ? "none_"
                                                                  : ""
                                                              }`}
                                                            >
                                                              {user.primaryTeamID !==
                                                              selectedTeam
                                                                ? dateStringer.shorten(
                                                                    user.primaryTeamName,
                                                                    16
                                                                  )
                                                                : dateStringer.formatMinsDurationToHours(
                                                                    sh.durMins,
                                                                    true
                                                                  )}
                                                            </p>
                                                            <p
                                                              className={`streamLeaveInfoLower x858983 ${
                                                                !sh.durMins &&
                                                                "none"
                                                              }`}
                                                            >
                                                              {!sh.durMins
                                                                ? "No leave required"
                                                                : sh.firstHalfOff
                                                                ? "Last half off"
                                                                : sh.lastHalfOff
                                                                ? "First half off"
                                                                : "Full shift off"}
                                                            </p>
                                                          </div>
                                                        ) : (
                                                          <div className="streamLineShiftTxtBox">
                                                            {sh.shTeamName ||
                                                            sh.location ? (
                                                              <div className="shItemTopRow">
                                                                <p className="shInlineLocation">
                                                                  {/* harrison */}
                                                                  {sh.requireAccept ? (
                                                                    sh.accepted ? (
                                                                      <img
                                                                        src={
                                                                          doubleTick
                                                                        }
                                                                        alt="Doube ticks"
                                                                        className="shTickDouble x48958522"
                                                                      />
                                                                    ) : (
                                                                      <img
                                                                        src={
                                                                          lightBlueQuestionMark
                                                                        }
                                                                        alt="Double tick"
                                                                        className="shTickSingle x2487587233"
                                                                      />
                                                                    )
                                                                  ) : (
                                                                    ""
                                                                  )}
                                                                  <img
                                                                    src={pin}
                                                                    alt="Location"
                                                                    className="shTeamRotaPinImg"
                                                                  />
                                                                  {dateStringer.shorten(
                                                                    sh.shTeamName ||
                                                                      sh.location,
                                                                    16
                                                                  )}
                                                                </p>
                                                              </div>
                                                            ) : !requireSeen &&
                                                              nowDateObj.getTime() >=
                                                                sh.startTs &&
                                                              nowDateObj.getTime() <
                                                                dateStringer.createTimestampFromString(
                                                                  sh.end
                                                                ) ? (
                                                              <div className="shItemTopRow">
                                                                <div
                                                                  className={`activeShLight activeShLightBlue ${
                                                                    sh.type ===
                                                                      "overtime" ||
                                                                    sh.type ===
                                                                      "til"
                                                                      ? "activeShLightPink"
                                                                      : ""
                                                                  }`}
                                                                ></div>
                                                                {sh.nightShift &&
                                                                !sh.ghost ? (
                                                                  <img
                                                                    src={
                                                                      nightshift
                                                                    }
                                                                    className="teamRotaNightShiftImg"
                                                                    alt="Night shift"
                                                                  />
                                                                ) : (
                                                                  ""
                                                                )}
                                                              </div>
                                                            ) : !requireSeen ||
                                                              !sh.requireAccept ||
                                                              sh.ghost ||
                                                              sh.midnightTimestamp <
                                                                todayDateObj.getTime() ? (
                                                              sh.nightShift ? (
                                                                <div className="shItemTopRow">
                                                                  {sh.nightShift &&
                                                                  !sh.ghost ? (
                                                                    <img
                                                                      src={
                                                                        nightshift
                                                                      }
                                                                      className={`teamRotaNightShiftImg marginLeftMinus1 ${
                                                                        sh.otherTeam
                                                                          ? "teamRotaNightShiftImgOtherTeam"
                                                                          : ""
                                                                      }`}
                                                                      alt="Night shift"
                                                                    />
                                                                  ) : (
                                                                    ""
                                                                  )}
                                                                </div>
                                                              ) : (
                                                                ""
                                                              )
                                                            ) : !sh.accepted ? (
                                                              <div className="shItemTopRow">
                                                                {nowDateObj.getTime() >=
                                                                  sh.startTs &&
                                                                nowDateObj.getTime() <
                                                                  dateStringer.createTimestampFromString(
                                                                    sh.end
                                                                  ) ? (
                                                                  <div className="activeShLight activeShLightGrey"></div>
                                                                ) : (
                                                                  ""
                                                                )}
                                                                <img
                                                                  src={
                                                                    lightBlueQuestionMark
                                                                  }
                                                                  alt="Double tick"
                                                                  className="shTickSingle"
                                                                />
                                                                {sh.nightShift &&
                                                                !sh.ghost ? (
                                                                  <img
                                                                    src={
                                                                      nightshift
                                                                    }
                                                                    className="teamRotaNightShiftImg"
                                                                    alt="Night shift"
                                                                  />
                                                                ) : (
                                                                  ""
                                                                )}
                                                                <p className="shInlineLocation">
                                                                  {dateStringer.shorten(
                                                                    sh.location,
                                                                    sh.nightShift
                                                                      ? 10
                                                                      : 17
                                                                  )}
                                                                </p>
                                                              </div>
                                                            ) : sh.accepted ? (
                                                              <div className="shItemTopRow">
                                                                {nowDateObj.getTime() >=
                                                                  sh.startTs &&
                                                                nowDateObj.getTime() <
                                                                  dateStringer.createTimestampFromString(
                                                                    sh.end
                                                                  ) ? (
                                                                  <div className="activeShLight"></div>
                                                                ) : (
                                                                  ""
                                                                )}
                                                                <img
                                                                  src={
                                                                    doubleTick
                                                                  }
                                                                  alt="Doube ticks"
                                                                  className="shTickDouble"
                                                                />{" "}
                                                                {sh.nightShift &&
                                                                !sh.ghost ? (
                                                                  <img
                                                                    src={
                                                                      nightshift
                                                                    }
                                                                    className="teamRotaNightShiftImg"
                                                                    alt="Night shift"
                                                                  />
                                                                ) : (
                                                                  ""
                                                                )}
                                                                <p className="shInlineLocation">
                                                                  {dateStringer.shorten(
                                                                    sh.location,
                                                                    sh.nightShift
                                                                      ? 10
                                                                      : 17
                                                                  )}
                                                                </p>
                                                              </div>
                                                            ) : (
                                                              <p>"-"</p>
                                                            )}
                                                            {sh.ghost ? (
                                                              <p className="streamShiftTimes colourWhite">
                                                                Paid day off
                                                              </p>
                                                            ) : (
                                                              <p
                                                                className={`streamShiftTimes ${
                                                                  sh.tilApproved &&
                                                                  tilStrip &&
                                                                  tilStrip.length >
                                                                    15
                                                                    ? `x449209392094 ${
                                                                        memoVals.mob
                                                                          ? "x449209392094Mob"
                                                                          : ""
                                                                      }`
                                                                    : ""
                                                                } ${
                                                                  sh.otherTeam
                                                                    ? "bbcada"
                                                                    : ""
                                                                } ${
                                                                  sh.absent &&
                                                                  !sh.partialAbsent
                                                                    ? "colourRed"
                                                                    : ""
                                                                } ${
                                                                  timeStrip.length >=
                                                                    122 ||
                                                                  tilStrip.includes(
                                                                    "Whole"
                                                                  )
                                                                    ? "x248943834834"
                                                                    : ""
                                                                } ${
                                                                  (tilStrip &&
                                                                    tilStrip.length >
                                                                      18) ||
                                                                  tilStrip.includes(
                                                                    "Whole"
                                                                  )
                                                                    ? "marginRight10"
                                                                    : ""
                                                                } ${
                                                                  tilStrip.includes(
                                                                    "Whole"
                                                                  )
                                                                    ? "tilStripWholeTeamSched"
                                                                    : ""
                                                                }`}
                                                              >
                                                                {sh.tilApproved
                                                                  ? tilStrip
                                                                  : timeStrip}{" "}
                                                              </p>
                                                            )}
                                                            {sh.tags &&
                                                            sh.tags[0] &&
                                                            (sh.type ===
                                                              "shift" ||
                                                              sh.type ===
                                                                "til" ||
                                                              sh.type ===
                                                                "overtime") &&
                                                            hoveredStreamShiftID ===
                                                              sh.typeID &&
                                                            !streamMovingShift.shiftID &&
                                                            !memoVals.mob &&
                                                            1 + 1 === 3 ? (
                                                              <div className="hobbler"></div>
                                                            ) : (
                                                              <div
                                                                className={`shiftBoxLowerStream `}
                                                              >
                                                                {sh.partialAbsent ? (
                                                                  <p className="absShInlineTxt">
                                                                    <img
                                                                      src={
                                                                        darkRedCross
                                                                      }
                                                                      alt="Absent"
                                                                      className="shPartAbsInlImg"
                                                                    />{" "}
                                                                    {
                                                                      sh.partialAbsentStart
                                                                    }{" "}
                                                                    -{" "}
                                                                    {
                                                                      sh.partialAbsentEnd
                                                                    }
                                                                  </p>
                                                                ) : sh.tsClock ? (
                                                                  <p className="tsClockTxt">
                                                                    {sh.tsClock ===
                                                                    "clock"
                                                                      ? "Clock card"
                                                                      : "Timesheet"}
                                                                  </p>
                                                                ) : Array.isArray(
                                                                    sh.tags
                                                                  ) &&
                                                                  sh.tags[0] &&
                                                                  !sh.ghost ? (
                                                                  generateTags
                                                                ) : (
                                                                  <p
                                                                    className={`streamNoTags ${
                                                                      !sh.published
                                                                        ? "streamNoTagsUnpub"
                                                                        : ""
                                                                    }`}
                                                                  >
                                                                    {sh.location &&
                                                                    1 + 1 === 3
                                                                      ? dateStringer.shorten(
                                                                          sh.location,
                                                                          18
                                                                        )
                                                                      : sh.otherTeam
                                                                      ? dateStringer.shorten(
                                                                          sh.otherTeam,
                                                                          18
                                                                        )
                                                                      : sh.ghost
                                                                      ? dateStringer.formatMinsDurationToHours(
                                                                          sh.durMins,
                                                                          true
                                                                        )
                                                                      : ""}
                                                                  </p>
                                                                )}
                                                              </div>
                                                            )}
                                                          </div>
                                                        )}
                                                        {/* {(sh.type === "shift" ||
                                                          sh.type ===
                                                            "overtime" ||
                                                          sh.type ===
                                                            "til") && (
                                                          <p
                                                            className={`streamOverTilType ${
                                                              sh.type ===
                                                              "shift"
                                                                ? "invis"
                                                                : ""
                                                            }`}
                                                          >
                                                            {sh.type ===
                                                            "overtime"
                                                              ? "Overtime"
                                                              : "Lieu"}
                                                          </p>
                                                        )} */}

                                                        {(sh.type === "shift" ||
                                                          sh.type === "til" ||
                                                          sh.type ===
                                                            "overtime") &&
                                                        hoveredStreamShiftID ===
                                                          sh.typeID &&
                                                        !streamMovingShift.shiftID &&
                                                        !memoVals.mob ? (
                                                          <img
                                                            src={
                                                              duplicateShiftHere
                                                            }
                                                            alt="Duplicate shift"
                                                            className={`hovDupeImg ${
                                                              timeStrip.length >=
                                                              122
                                                                ? "hovDupeImgx88"
                                                                : ""
                                                            } ${
                                                              tilStrip &&
                                                              tilStrip.length >
                                                                18
                                                                ? "x3289489d34"
                                                                : ""
                                                            } ${
                                                              tilStrip.includes(
                                                                "Whole"
                                                              )
                                                                ? "wholeHovDUpeImg"
                                                                : ""
                                                            } ${
                                                              memoVals
                                                                .checkUserState
                                                                .userID ===
                                                                user.userID &&
                                                              !selfManageShifts
                                                                ? "invis"
                                                                : ""
                                                            }`}
                                                            onClick={(e) => {
                                                              e.stopPropagation();

                                                              if (
                                                                memoVals
                                                                  .checkUserState
                                                                  .userID ===
                                                                  user.userID &&
                                                                !selfManageShifts
                                                              ) {
                                                              } else {
                                                                if (
                                                                  moveUnit.itemID ===
                                                                  sh.typeID
                                                                ) {
                                                                  setMoveUnit(
                                                                    {}
                                                                  );
                                                                  memoVals.setIndicate(
                                                                    (x) => {
                                                                      return {
                                                                        show: false,
                                                                        message:
                                                                          "",
                                                                        colour:
                                                                          "",
                                                                        duration: 0,
                                                                      };
                                                                    }
                                                                  );
                                                                } else {
                                                                  memoVals.setIndicate(
                                                                    (x) => {
                                                                      return {
                                                                        show: true,
                                                                        message:
                                                                          "Select where to duplicate shift...",
                                                                        colour:
                                                                          "orange",
                                                                        duration: 1500,
                                                                      };
                                                                    }
                                                                  );

                                                                  setStreamMovingShift(
                                                                    {
                                                                      duplicating: true,
                                                                      shiftID:
                                                                        sh.typeID,

                                                                      originalUser:
                                                                        user.userID,
                                                                      originalDs:
                                                                        ds,
                                                                    }
                                                                  );
                                                                  setMoveUnit({
                                                                    itemID:
                                                                      sh.typeID,
                                                                    type: sh.type,
                                                                    oldUserID:
                                                                      user.userID,
                                                                    oldDs: ds,
                                                                    duplicate: true,
                                                                    startDs:
                                                                      sh.start,
                                                                    endDs:
                                                                      sh.end,
                                                                    durMins:
                                                                      sh.durMins,
                                                                  });
                                                                }
                                                              }
                                                            }}
                                                          />
                                                        ) : (
                                                          ""
                                                        )}
                                                      </div>
                                                    );
                                                  })}
                                                {shiftsArr[0] && (
                                                  <p
                                                    className="inlineAddShBtn"
                                                    onClick={() => {
                                                      scheduleNewShift(
                                                        "shift",
                                                        ds,
                                                        user.userID
                                                      );
                                                    }}
                                                  >
                                                    +
                                                  </p>
                                                )}
                                                {/* prettygreen */}
                                                {/* <p
                                                    onClick={() => {
                                                      console.log({
                                                        ds,
                                                        userID: user.userID,
                                                      });
                                                    }}
                                                  >
                                                    777
                                                  </p> */}
                                                {streamMovingShift.shiftID && (
                                                  // streamMovingShift.originalDs !==
                                                  //   ds &&
                                                  // streamMovingShift.originalUser !==
                                                  //   user.userID &&
                                                  <img
                                                    src={moveShiftHere}
                                                    className={`moveShiftHereDottedIcon moveShiftHereDottedIconStream ${
                                                      shiftsArr.filter((x) => {
                                                        return (
                                                          x.typeID ===
                                                          streamMovingShift.shiftID
                                                        );
                                                      }).length > 0
                                                        ? "none"
                                                        : ""
                                                    }`}
                                                    alt="Move shift here"
                                                  />
                                                )}
                                                {/* <div className="block11">
                                                  HELO
                                                </div> */}
                                              </div>
                                            </div>

                                            // <div
                                            //   className={`streamUserRow

                                            //   `}
                                            // >
                                            //   <div
                                            //     className={`streamUserNameBlock ${
                                            //       mob
                                            //         ? "streamUserNameBlockMob"
                                            //         : ""
                                            //     }`}
                                            //   >
                                            //     <img
                                            //       src={
                                            //         user.profPicUrl ||
                                            //         profilePicturePlaceholder
                                            //       }
                                            //       alt={user.fName}
                                            //       className="streamlinedUserProPicImg"
                                            //     />{" "}
                                            //     {user.fName} {user.lName}
                                            //   </div>
                                            // </div>
                                          );
                                        } else {
                                          return;
                                        }
                                      })}
                                  </div>
                                );
                              })}
                              <div className="streamSchedBottomCostBlock">
                                <p
                                  className="streamSchedBottomRow"
                                  onClick={() => {
                                    console.log(calcTotals());
                                  }}
                                >
                                  <span className="streamSchedBottomTitle alignLeft2">
                                    Cost
                                  </span>
                                  <br />
                                  {memoVals.currencySymbol}
                                  {dsIndex === 0
                                    ? `${calcTotals().monCost.toFixed(
                                        JSON.stringify(
                                          calcTotals().monCost.toFixed(2)
                                        ).includes(".00")
                                          ? 0
                                          : 2
                                      )}`
                                    : dsIndex === 1
                                    ? `${calcTotals().tueCost.toFixed(
                                        JSON.stringify(
                                          calcTotals().tueCost.toFixed(2)
                                        ).includes(".00")
                                          ? 0
                                          : 2
                                      )}`
                                    : dsIndex === 2
                                    ? `${calcTotals().wedCost.toFixed(
                                        JSON.stringify(
                                          calcTotals().wedCost.toFixed(2)
                                        ).includes(".00")
                                          ? 0
                                          : 2
                                      )}`
                                    : dsIndex === 3
                                    ? `${calcTotals().thuCost.toFixed(
                                        JSON.stringify(
                                          calcTotals().thuCost.toFixed(2)
                                        ).includes(".00")
                                          ? 0
                                          : 2
                                      )}`
                                    : dsIndex === 4
                                    ? `${calcTotals().friCost.toFixed(
                                        JSON.stringify(
                                          calcTotals().friCost.toFixed(2)
                                        ).includes(".00")
                                          ? 0
                                          : 2
                                      )}`
                                    : dsIndex === 5
                                    ? `${calcTotals().satCost.toFixed(
                                        JSON.stringify(
                                          calcTotals().satCost.toFixed(2)
                                        ).includes(".00")
                                          ? 0
                                          : 2
                                      )}`
                                    : dsIndex === 6
                                    ? `${calcTotals().sunCost.toFixed(
                                        JSON.stringify(
                                          calcTotals().sunCost.toFixed(2)
                                        ).includes(".00")
                                          ? 0
                                          : 2
                                      )}`
                                    : ""}
                                </p>
                                <p className="streamSchedBottomRow">
                                  <span className="streamSchedBottomTitle alignRight2">
                                    Hours
                                  </span>
                                  <br />

                                  {dsIndex === 0
                                    ? `${dateStringer.formatMinsDurationToHours(
                                        calcTotals().monMins
                                      )}`
                                    : dsIndex === 1
                                    ? `${dateStringer.formatMinsDurationToHours(
                                        calcTotals().tueMins
                                      )}`
                                    : dsIndex === 2
                                    ? `${dateStringer.formatMinsDurationToHours(
                                        calcTotals().wedMins
                                      )}`
                                    : dsIndex === 3
                                    ? `${dateStringer.formatMinsDurationToHours(
                                        calcTotals().thuMins
                                      )}`
                                    : dsIndex === 4
                                    ? `${dateStringer.formatMinsDurationToHours(
                                        calcTotals().friMins
                                      )}`
                                    : dsIndex === 5
                                    ? `${dateStringer.formatMinsDurationToHours(
                                        calcTotals().satMins
                                      )}`
                                    : dsIndex === 6
                                    ? `${dateStringer.formatMinsDurationToHours(
                                        calcTotals().sunMins
                                      )}`
                                    : ""}
                                </p>
                              </div>{" "}
                            </div>
                          </div>
                        );
                      })}
                  </Fragment>
                  // </div>
                )
                //  (
                //   generateContent
                // )
              }
            </div>
          </div>
        </div>

        {streamMovingShift.shiftID ? (
          <div
            className="canxMoveShMob"
            onClick={() => {
              setStreamMovingShift({ shiftID: "" });
              setMoveUnit({});
            }}
          >
            <img src={greyCross} alt="Cancel" className="canxMovImg" />
          </div>
        ) : (
          ""
        )}
        {streamShiftID.shiftID && memoVals.mob && (
          <div
            className="dupeUnderlay"
            onClick={() => {
              setStreamShiftID({ shiftID: "", type: "" });
            }}
          >
            <div
              className="mobileDupeBtn"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <p
                className="dupeMobBtn greyColours"
                onClick={() => {
                  let typeString =
                    streamShiftID.type === "til"
                      ? "lieu shift"
                      : streamShiftID.type;
                  setStreamShiftID({ shiftID: "", type: "" });

                  axios
                    .post(
                      `${serverURL}/delete-shift`,
                      {
                        shiftID: streamShiftID.shiftID,
                        type: streamShiftID.type,
                        nowDs: dateStringer.createStringFromTimestamp(
                          new Date().getTime()
                        ),
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        let newArr = [];
                        state.sched.forEach((usr) => {
                          let monNew = [];
                          let tueNew = [];
                          let wedNew = [];
                          let thuNew = [];
                          let friNew = [];
                          let satNew = [];
                          let sunNew = [];

                          usr.monItems.forEach((item) => {
                            if (item.typeID !== streamShiftID.shiftID) {
                              monNew.push(item);
                            }
                          });

                          usr.tueItems.forEach((item) => {
                            if (item.typeID !== streamShiftID.shiftID) {
                              tueNew.push(item);
                            }
                          });

                          usr.wedItems.forEach((item) => {
                            if (item.typeID !== streamShiftID.shiftID) {
                              wedNew.push(item);
                            }
                          });

                          usr.thuItems.forEach((item) => {
                            if (item.typeID !== streamShiftID.shiftID) {
                              thuNew.push(item);
                            }
                          });

                          usr.friItems.forEach((item) => {
                            if (item.typeID !== streamShiftID.shiftID) {
                              friNew.push(item);
                            }
                          });

                          usr.satItems.forEach((item) => {
                            if (item.typeID !== streamShiftID.shiftID) {
                              satNew.push(item);
                            }
                          });

                          usr.sunItems.forEach((item) => {
                            if (item.typeID !== streamShiftID.shiftID) {
                              sunNew.push(item);
                            }
                          });

                          usr.monItems = monNew;
                          usr.tueItems = tueNew;
                          usr.wedItems = wedNew;
                          usr.thuItems = thuNew;
                          usr.friItems = friNew;
                          usr.satItems = satNew;
                          usr.sunItems = sunNew;

                          newArr.push(usr);
                        });
                        setState({
                          sched: newArr,
                          // singleDay: dsObj.getDay(),
                        });

                        memoVals.setIndicate((x) => {
                          return {
                            show: true,
                            message: `Deleted ${typeString}`,
                            colour: "blue",
                            duration: 4000,
                          };
                        });
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                {" "}
                Delete{" "}
                <img
                  src={shiftDelete}
                  alt="Duplicate"
                  className="duplicateShMobImg x32849832"
                />
              </p>
              <p
                className="dupeMobBtn"
                onClick={() => {
                  memoVals.setIndicate((x) => {
                    return {
                      show: true,
                      message: `Select where to move ${
                        streamShiftID.type === "til"
                          ? "lieu shift"
                          : streamShiftID.type
                      } to`,
                      colour: "orange",
                      duration: 1500,
                      ts: new Date().getTime(),
                    };
                  });
                  setMoveUnit({
                    itemID: streamShiftID.shiftID,
                    type: streamShiftID.type,
                    oldUserID: streamShiftID.userID,
                    oldDs: `${streamShiftID.ds.substr(0, 11)}H00M00`,
                    duplicate: false,
                    startDs: streamShiftID.start,
                    endDs: streamShiftID.end,
                    durMins: streamShiftID.durMins,
                  });

                  setStreamMovingShift({
                    shiftID: streamShiftID.shiftID,
                    originalUser: streamShiftID.userID,
                    originalDs: streamShiftID.ds,
                  });
                  console.log({
                    shiftID: streamShiftID.shiftID,
                    originalUser: streamShiftID.userID,
                    originalDs: streamShiftID.ds,
                  });

                  setStreamShiftID({ shiftID: "" });
                }}
              >
                {" "}
                Move{" "}
                <img
                  src={shiftMove}
                  alt="Duplicate"
                  className="duplicateShMobImg"
                />
              </p>
              <p
                className="dupeMobBtn"
                onClick={() => {
                  if (moveUnit.itemID === streamShiftID.shiftID) {
                    setMoveUnit({});
                    memoVals.setIndicate((x) => {
                      return {
                        show: false,
                        message: "",
                        colour: "",
                        duration: 0,
                      };
                    });
                  } else {
                    memoVals.setIndicate((x) => {
                      return {
                        show: true,
                        message: "Select where to duplicate shift...",
                        colour: "orange",
                        duration: 1500,
                      };
                    });
                    setStreamMovingShift({
                      duplicating: true,
                      shiftID: streamShiftID.shiftID,

                      originalUser: streamShiftID.userID,
                      originalDs: streamShiftID.ds,
                    });
                    setMoveUnit({
                      itemID: streamShiftID.shiftID,
                      type: streamShiftID.type,
                      oldUserID: streamShiftID.userID,
                      oldDs: streamShiftID.ds,
                      duplicate: true,
                      startDs: streamShiftID.startDs,
                      endDs: streamShiftID.endDs,
                      durMins: streamShiftID.durMins,
                    });
                    setStreamShiftID({ shiftID: "" });
                  }
                }}
              >
                {" "}
                Duplicate{" "}
                <img
                  src={shiftDuplicate}
                  alt="Duplicate"
                  className="duplicateShMobImg"
                />
              </p>
            </div>
          </div>
        )}

        {moveUnit.itemID && !memoVals.mob && !moveUnit.duplicate && (
          <div
            className="rotaDeleteDropPort"
            onClick={() => {
              setMoveUnit({});
            }}
            onDragOver={(event) => {
              handleDragOver(event);
            }}
            onDrop={(event) => {
              if (moveUnit.itemID) {
                // setTypeIDsToHide([
                //   ...typeIDsToHide,
                //   sh.typeID,
                // ]);
                let typeString =
                  moveUnit.type === "til" ? "lieu shift" : moveUnit.type;
                axios
                  .post(
                    `${serverURL}/delete-shift`,
                    {
                      shiftID: moveUnit.itemID,
                      type: moveUnit.type,
                      nowDs: dateStringer.createStringFromTimestamp(
                        new Date().getTime()
                      ),
                    },

                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .then((response) => {
                    if (response.data.message === "success") {
                      // let dsObj = new Date(
                      //   dateStringer.createTimestampFromString(day)
                      // );

                      setMoveUnit({});

                      let newArr = [];
                      state.sched.forEach((usr) => {
                        let monNew = [];
                        let tueNew = [];
                        let wedNew = [];
                        let thuNew = [];
                        let friNew = [];
                        let satNew = [];
                        let sunNew = [];

                        usr.monItems.forEach((item) => {
                          if (item.typeID !== moveUnit.itemID) {
                            monNew.push(item);
                          }
                        });

                        usr.tueItems.forEach((item) => {
                          if (item.typeID !== moveUnit.itemID) {
                            tueNew.push(item);
                          }
                        });

                        usr.wedItems.forEach((item) => {
                          if (item.typeID !== moveUnit.itemID) {
                            wedNew.push(item);
                          }
                        });

                        usr.thuItems.forEach((item) => {
                          if (item.typeID !== moveUnit.itemID) {
                            thuNew.push(item);
                          }
                        });

                        usr.friItems.forEach((item) => {
                          if (item.typeID !== moveUnit.itemID) {
                            friNew.push(item);
                          }
                        });

                        usr.satItems.forEach((item) => {
                          if (item.typeID !== moveUnit.itemID) {
                            satNew.push(item);
                          }
                        });

                        usr.sunItems.forEach((item) => {
                          if (item.typeID !== moveUnit.itemID) {
                            sunNew.push(item);
                          }
                        });

                        usr.monItems = monNew;
                        usr.tueItems = tueNew;
                        usr.wedItems = wedNew;
                        usr.thuItems = thuNew;
                        usr.friItems = friNew;
                        usr.satItems = satNew;
                        usr.sunItems = sunNew;

                        newArr.push(usr);
                      });
                      setState({
                        sched: newArr,
                        // singleDay: dsObj.getDay(),
                      });

                      memoVals.setIndicate((x) => {
                        return {
                          show: true,
                          message: `Deleted ${typeString}`,
                          colour: "blue",
                          duration: 4000,
                        };
                      });
                    }
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }
            }}
          >
            <img
              src={greyBin}
              alt="Delete shift"
              className="rotaDelPortBinImg"
            />
          </div>
        )}
        {!moveUnit.itemID && !showGenOffer[0] ? (
          <p
            className={`addShiftBtnOnTeamShifts
           ${state.loading ? "disableAddBtn" : ""}
          `}
            onClick={() => {
              if (!state.loading) {
                setShowTilOrShiftPopUp(true);
                setSelectedAddShiftEmpty("");
                setSelectedHorizAddShift("");
              }
              if (streamMovingShift.shiftID) {
                setStreamMovingShift({ shiftID: "" });
              }
              if (moveUnit.itemID) {
                setMoveUnit({});
              }
            }}
          >
            +
          </p>
        ) : (
          ""
        )}
      </div>
      {/* {renderLeaveReqModal()} */}
      {/* {renderEditShiftModal()} */}
      {/* {renderOpenShiftModal()} */}
      {/* {renderBulkLimitModal()} */}
      {renderSaveTemplateModal()}
      {showBookedOffExplain ? (
        <div
          className="addTilOrShiftOptionsUnderlay"
          onClick={() => {
            setShowBookedOffExplain(false);
          }}
        >
          <div
            className="overlapsRenewalModalBox"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <p className="overlapsRenTxt">
              The <span className="limBold">booked off</span> counter will only
              include staff who have booked time off - and whose primary team is{" "}
              {showBookedOffExplain.teamName}.
            </p>
            <p
              className="tempSavedCloseBtn"
              onClick={() => {
                setShowBookedOffExplain(false);
              }}
            >
              Understood
            </p>
          </div>
        </div>
      ) : (
        ""
      )}
      {memoVals.showAddShiftTeamSched ? (
        <AddShiftModal2
          teamID={selectedTeam}
          type="shift"
          setShowAddShift={setShowAddShiftTeamSched}
        />
      ) : (
        ""
      )}
      {memoVals.showAddTilModalTeamSched ? (
        <AddShiftModal2
          teamID={selectedTeam}
          type={memoVals.tilOrOvertime === "til" ? "til" : "overtime"}
          setShowAddTilModal={setShowAddTilModalTeamSched}
        />
      ) : (
        ""
      )}
      {/* {showAddLeaveOnTeam ? (
        <AddShiftModal2
          teamID={selectedTeam}
          type="leave"
          setShowAddLeaveOnTeam={setShowAddLeaveOnTeam}
          loadFromReqsPage={
            new Date().getTime() <
            dateStringer.createTimestampFromString(selectedMonWeek)
              ? selectedMonWeek
              : dateStringer.createStringFromTimestamp(new Date().getTime())
          }
        />
      ) : (
        ""
      )} */}
      <div
        className={`${
          showTilOrShiftPopUp ? "addTilOrShiftOptionsUnderlay" : "none"
        }`}
        onClick={() => {
          setShowTilOrShiftPopUp(false);
        }}
      >
        {" "}
        <div
          className="addShiftBtnOnTeamShiftsOnOverlay"
          onClick={() => {
            setShowTilOrShiftPopUp(false);
            setState({ hoveredShift: {} });
          }}
        >
          <p className="diagPlus">+</p>
        </div>
        <div className="addTilShiftBtnsContainer">
          <p
            className="addTilBtn x23838833443"
            onClick={() => {
              let opens = {
                mon: state.monOpens,
                tue: state.tueOpens,
                wed: state.wedOpens,
                thu: state.thuOpens,
                fri: state.friOpens,
                sat: state.satOpens,
                sun: state.sunOpens,
              };
              setSaveTemplateObj((x) => {
                return {
                  teamID: locationMode ? "" : selectedTeam,
                  locationID: locationMode ? selectedTeam : "",
                  locationName: locationMode,
                  teamName,
                  monWeek: selectedMonWeek,
                  saving: true,
                  loading: false,
                  shifts: state.sched,
                  opens: opens,
                };
              });
              setShowTilOrShiftPopUp(false);
            }}
          >
            Save template
          </p>
          <p
            className="addTilBtn x23838833443"
            onClick={() => {
              let opens = {
                mon: state.monOpens,
                tue: state.tueOpens,
                wed: state.wedOpens,
                thu: state.thuOpens,
                fri: state.friOpens,
                sat: state.satOpens,
                sun: state.sunOpens,
              };
              setSaveTemplateObj((x) => {
                return {
                  teamID: locationMode ? "" : selectedTeam,
                  locationID: locationMode ? selectedTeam : "",
                  locationName: locationMode,
                  teamName,
                  monWeek: selectedMonWeek,
                  saving: true,
                  loading: false,
                  shifts: state.sched,
                  opens: opens,
                  shell: true,
                };
              });
              setShowTilOrShiftPopUp(false);
            }}
          >
            Save shell
          </p>
          <div className="teamSchedMenuDivider"></div>

          <p
            className="addTilBtn x23838833443"
            onClick={() => {
              setShowTilOrShiftPopUp(false);
              let opens = {
                mon: state.monOpens,
                tue: state.tueOpens,
                wed: state.wedOpens,
                thu: state.thuOpens,
                fri: state.friOpens,
                sat: state.satOpens,
                sun: state.sunOpens,
              };
              setSaveTemplateObj((x) => {
                return {
                  teamID: locationMode ? "" : selectedTeam,
                  locationID: locationMode ? selectedTeam : "",
                  locationName: locationMode,
                  teamName,
                  monWeek: selectedMonWeek,
                  saving: false,
                  loading: true,
                  shifts: state.sched,
                  opens: opens,
                };
              });
            }}
          >
            Load template / shell
          </p>

          <div className="teamSchedMenuDivider"></div>
          <p
            className="addTilBtn extendPurpleLeftBorder"
            onClick={() => {
              memoVals.setAddShiftPage((x) => 0);
              memoVals.setAddShiftOrigin((x) => "onTeam");
              setShowTilOrShiftPopUp(false);
              memoVals.setShowAddLeaveOnTeam(true);
            }}
          >
            Leave / Absence
          </p>
          <p
            className="addTilBtn extendGreyLeftBorder"
            onClick={() => {
              memoVals.setAddTilSelf((x) => false);
              memoVals.setAddTilTeam((x) => selectedTeam);
              memoVals.setAddTilUserKnown((x) => false);
              memoVals.setAddTilPage((x) => 0);
              memoVals.setAddTilUserID((x) => "");
              if (showTilOrShiftPopUp) {
                memoVals.setShowAddTilModalTeamSched((x) => true);
              }
              memoVals.setTilOrOvertime((x) => "til");
            }}
          >
            Overtime (time in lieu)
          </p>
          <p
            className="addTilBtn extendGreyLeftBorder"
            onClick={() => {
              memoVals.setAddTilSelf((x) => false);
              memoVals.setAddTilTeam((x) => selectedTeam);
              memoVals.setAddTilUserKnown((x) => false);
              memoVals.setAddTilPage((x) => 0);
              memoVals.setAddTilUserID((x) => "");
              if (showTilOrShiftPopUp) {
                memoVals.setShowAddTilModalTeamSched((x) => true);
              }
              memoVals.setTilOrOvertime((x) => "overtime");
            }}
          >
            Overtime
          </p>

          <p
            className="addTilBtn extendBlueLeftBorder"
            onClick={() => {
              memoVals.setAddShiftPage((x) => 0);
              memoVals.setAddShiftOrigin((x) => "onTeam");
              setShowTilOrShiftPopUp(false);

              memoVals.setShowAddShiftTeamSched((x) => true);
            }}
          >
            Shift
          </p>
        </div>
      </div>
      {/* modals can go here */}
      {/* indi only modal */}
      {/* {showIndiOnlyModal ? (
        <div
          className="indiOnlyUnderlay"
          onClick={() => {
            setShowIndiOnlymodal(false);
          }}
        >
          <div className="overlapsRenewalModalBox">
            <div className="overlapsRenTxt">
              You cannot amend team rotas because you don't manage any teams.
              <br />
              <br />
              You manage the following staff:
              <br />
              <div className="indiOnlyStaffList">
                {individuals.map((item) => {
                  return (
                    <p key={item.userID} className="individualUserManagesNav">
                      {item.fName} {item.lName}
                    </p>
                  );
                })}
              </div>
              You can amend their shifts in the{" "}
              <NavLink to="/team-shifts">
                <span
                  onClick={() => {
                    memoVals.setSelectedDesktop((x) => "team-shifts");
                    memoVals.setUserShiftsPage((x) => "team-shifts");
                    memoVals.setShiftsPage((x) => "team-shifts");
                    memoVals.setCalPage((x) => "team-shifts");
                    memoVals.setPage((x) => "team-shifts");
                  }}
                  className="indiOnlyTeamHoursSpan"
                >
                  Team hours
                </span>
              </NavLink>{" "}
              page.{" "}
            </div>
            <NavLink to="/rota">
              <p
                className="tempSavedCloseBtn"
                onClick={() => {
                  setShowIndiOnlymodal(false);
                }}
              >
                Understood
              </p>
            </NavLink>
          </div>
        </div>
      ) : (
        ""
      )} */}
      {/* end of indi only modal */}
      {showGenOffer[0] ? (
        <div
          className="editShiftModalUnderlay showGenUnderlayFadeIn"
          onClick={() => {
            setShowGenOffer([]);
          }}
        >
          <div
            style={memoVals.mobModal ? modalPositionGen : null}
            ref={modalRefGen}
            className={`${
              memoVals.mob
                ? `showGenOfferModalMob ${
                    memoVals.device.ios ? "showGenOfferModalMobIos" : ""
                  }`
                : "showGenOfferModal"
            }`}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {memoVals.mobModal && (
              <div className="modalSwiper modalSwiperGen"></div>
            )}
            <div
              className="showGenHeader"
              onTouchStart={handleDownGen}
              onTouchMove={memoVals.mobModal ? handleMoveGen : null}
              onTouchEnd={memoVals.mobModal ? handleUpGen : null}
              onMouseDown={memoVals.mobModal ? handleDownGen : null}
              onMouseMove={memoVals.mobModal ? handleMoveGen : null}
              onMouseUp={memoVals.mobModal ? handleUpGen : null}
            >
              <p className="showGenInfotTxt">
                Generate shifts for the week commencing{" "}
                {dateStringer.printedDateFromDs(selectedMonWeek)} based on the
                contracted hours of your following staff:
              </p>
              <img src={lightning} alt="Wizard" className="showGenLightning" />
            </div>
            <div className="showGenNames">
              {showGenOffer.map((name) => {
                return <p className="showGenName">{name}</p>;
              })}
            </div>

            {templateExists ? (
              <p
                className="launchTempGenBtn"
                onClick={() => {
                  setShowGenOffer([]);

                  let opens = {
                    mon: state.monOpens,
                    tue: state.tueOpens,
                    wed: state.wedOpens,
                    thu: state.thuOpens,
                    fri: state.friOpens,
                    sat: state.satOpens,
                    sun: state.sunOpens,
                  };
                  setSaveTemplateObj((x) => {
                    return {
                      teamID: locationMode ? "" : selectedTeam,
                      locationID: locationMode ? selectedTeam : "",
                      locationName: locationMode,
                      teamName,
                      monWeek: selectedMonWeek,
                      saving: false,
                      loading: true,
                      shifts: state.sched,
                      opens: opens,
                    };
                  });
                }}
              >
                Load a template
              </p>
            ) : (
              ""
            )}
            <div className="showGenBtnDiv">
              <p
                className="areYouSureModalYesBtn showGenGenBtnColours"
                onClick={() => {
                  setShowGenOffer([]);

                  axios
                    .post(
                      `${serverURL}/generate-team-week`,
                      {
                        teamID: selectedTeam,
                        monWeek: selectedMonWeek,
                        ungenerating: false,
                        nowDs: dateStringer.createStringFromTimestamp(
                          new Date().getTime()
                        ),

                        // ungenerating: generated ? true : false,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      memoVals.setUpdateInfo((x) => !memoVals.updateInfo);
                      memoVals.setIndicate((x) => {
                        return {
                          show: true,
                          message: "Generating week schedule",
                          colour: "blue",
                          duration: 4000,
                        };
                      });

                      setTimeout(() => {
                        setShowPubRemind(true);
                      }, 1500);
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Generate
              </p>
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setShowGenOffer([]);
                }}
              >
                Close
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {showPubRemind ? (
        <div
          className="editShiftModalUnderlay showGenUnderlayFadeIn"
          onClick={() => {
            setShowPubRemind(false);
          }}
        >
          <div
            className={`${
              memoVals.mob
                ? `showGenOfferModalMob ${
                    memoVals.device.ios ? "showGenOfferModalMobIos" : ""
                  }`
                : "showGenOfferModal"
            }`}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="showGenHeader">
              <p className="showGenInfotTxt">
                Do you want to publish the shifts now?
              </p>
            </div>

            <div className="showGenBtnDiv">
              <p
                className="areYouSureModalYesBtn showGenGenBtnColours"
                onClick={() => {
                  setShowPubRemind(false);

                  axios
                    .post(
                      `${serverURL}/publish-team-week`,
                      {
                        teamID: locationMode ? "" : selectedTeam,
                        locationID: locationMode ? selectedTeam : "",

                        monWeek: selectedMonWeek,
                        data: true,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        publishLocally("publish");
                      }

                      memoVals.setIndicate((x) => {
                        return {
                          show: true,
                          message: "All shifts published",
                          colour: "green",
                          duration: 4000,
                        };
                      });
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Publish
              </p>
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setShowPubRemind(false);
                }}
              >
                Not yet
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div
        className={`teamSchedFilterBarRight marginTopMinus5 ${
          state.loading ? "loadingSchedFade" : ""
        } ${memoVals.mob ? "x234223323" : ""} ${locationMode ? "hidden_" : ""}`}
      >
        <img
          src={
            filterTags ? filterClose : filterTagsArr[0] ? filterActive : filter
          }
          alt="Filter"
          className={`filterSchedBtn ${
            filterTags ? "filterSchedBtnActive" : ""
          } ${filterTagsArr[0] ? "filterSchedBtnActiveTags" : ""}`}
          onClick={() => {
            setFilterTags(!filterTags);
          }}
        />{" "}
        {window.innerWidth > 1179 ? (
          <a
            className={`myRotaAllowSwapsBtn printRotaBtn `}
            onClick={() => {
              setShowShare(true);
            }}
          >
            {" "}
            Share <img src={shareGrey} alt="Share" className="shareGreyImg" />
          </a>
        ) : (
          ""
        )}
        <p
          className={`${
            isPublishedMix(state.sched) &&
            isPublishedMix(state.sched).published > 0
              ? "publishedStatusPublished"
              : "publishedStatusUnpublished"
          } ${showTools || showLimitTools ? "none_" : ""} ${
            locationMode && "none"
          }`}
        >
          {`${
            isPublishedMix(state.sched)
              ? `${isPublishedMix(state.sched).published} / ${
                  isPublishedMix(state.sched).published +
                  isPublishedMix(state.sched).unpublished
                } published`
              : `${
                  isPublishedMix(state.sched) === false
                    ? "No shifts published"
                    : "All shifts published"
                }`
          }`}
        </p>
        <div
          className={`${
            showPubTools
              ? `copyToolsBtn ${
                  showToolsPublish ? "x18391832" : ""
                } pubToolsBtn ${
                  isPublishedMix(state.sched)
                    ? isPublishedMix(state.sched).published ===
                      isPublishedMix(state.sched).published +
                        isPublishedMix(state.sched).unpublished
                      ? "extendPubBtn"
                      : ""
                    : ""
                }`
              : "none"
          } ${locationMode && "none"}`}
        >
          <div
            className={`desktopToolsBtnTxt toolsPublishTxt ${
              isPublishedMix(state.sched).unpublished > 0
                ? "publishOutstanding"
                : ""
            } ${
              isPublishedMix(state.sched)
                ? isPublishedMix(state.sched).published ===
                  isPublishedMix(state.sched).published +
                    isPublishedMix(state.sched).unpublished
                  ? "extendPubTxt"
                  : ""
                : ""
            }`}
            onClick={() => {
              if (showToolsPublish) {
                setShowToolsPublish(false);
              }
              if (showToolsLimits) {
                setShowToolsLimits(false);
              }

              if (showToolsCopy) {
                setShowToolsCopy(false);
              }

              if (showToolsTools) {
                setShowToolsTools(false);
              }
              if (
                isPublishedMix(state.sched) &&
                isPublishedMix(state.sched).published ===
                  isPublishedMix(state.sched).published +
                    isPublishedMix(state.sched).unpublished
              ) {
                axios
                  .post(
                    `${serverURL}/publish-team-week`,
                    {
                      teamID: locationMode ? "" : selectedTeam,
                      locationID: locationMode ? selectedTeam : "",
                      monWeek: selectedMonWeek,
                      data: false,
                    },

                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .then((response) => {
                    if (response.data.message === "success") {
                      publishLocally("unpublish");
                    }
                    setShowTools(false);
                    setShowToolsPublish(false);
                    memoVals.setIndicate((x) => {
                      return {
                        show: true,
                        message: "All shifts unpublished",
                        colour: "blue",
                        duration: 4000,
                      };
                    });
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              } else {
                axios
                  .post(
                    `${serverURL}/publish-team-week`,
                    {
                      teamID: locationMode ? "" : selectedTeam,
                      locationID: locationMode ? selectedTeam : "",
                      monWeek: selectedMonWeek,
                      data: true,
                    },

                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .then((response) => {
                    if (response.data.message === "success") {
                      publishLocally("publish");
                    }
                    setShowTools(false);
                    setShowToolsPublish(false);
                    memoVals.setIndicate((x) => {
                      return {
                        show: true,
                        message: "All shifts published",
                        colour: "green",
                        duration: 4000,
                      };
                    });
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }
            }}
          >
            <img src={toolsPublish} className="toolsPublish" alt="tools" />

            {isPublishedMix(state.sched)
              ? isPublishedMix(state.sched).published ===
                isPublishedMix(state.sched).published +
                  isPublishedMix(state.sched).unpublished
                ? "Unpublish all"
                : "Publish all"
              : "Publish"}
          </div>
          <div
            className="toolsDownArrContainer"
            onClick={() => {
              setShowToolsPublish(!showToolsPublish);

              if (showToolsLimits) {
                setShowToolsLimits(false);
              }

              if (showToolsCopy) {
                setShowToolsCopy(false);
              }

              if (showToolsTools) {
                setShowToolsTools(false);
              }
            }}
          >
            <img
              src={showToolsPublish ? thickCross : whiteDown}
              alt="down"
              className="desktopTooslBtnDownImg"
            />
          </div>
        </div>
        <div
          className={`${
            showLimTools
              ? `copyToolsBtn ${showToolsLimits ? "x18391832" : ""} `
              : "none"
          } ${locationMode && "none"}`}
        >
          <div
            className={`desktopToolsBtnTxt ${
              state.hideSchedLimits ? "limBtnFade" : ""
            }`}
            onClick={() => {
              if (showToolsPublish) {
                setShowToolsPublish(false);
              }
              if (showToolsLimits) {
                setShowToolsLimits(false);
              }

              if (showToolsCopy) {
                setShowToolsCopy(false);
              }

              if (showToolsTools) {
                setShowToolsTools(false);
              }
              axios
                .post(
                  `${serverURL}/toggle-hide-sched-limits-for-team`,
                  { data: !state.hideSchedLimits, teamID: selectedTeam },

                  {
                    withCredentials: true,
                    credentials: "include",
                  }
                )
                .then((response) => {
                  if (response.data.message === "success") {
                    setState({ hideSchedLimits: response.data.data });
                    memoVals.setUpdateLimits((x) => !memoVals.updateLimits);
                    memoVals.setIndicate((x) => {
                      return {
                        show: true,
                        message: state.hideSchedLimits
                          ? "Limits are now enabled"
                          : "Limits are now disabled",
                        colour: "blue",
                        duration: 4000,
                      };
                    });
                  }
                })
                .catch((err) => {
                  console.error(err);
                });
            }}
          >
            <img
              src={state.hideSchedLimits ? whitePower : whitePower}
              className={`toolsLimit ${
                state.hideSchedLimits ? "whitePowerLim" : "whitePowerLim"
              }`}
              alt="tools"
            />
            Limits
          </div>
          <div
            className="toolsDownArrContainer"
            onClick={() => {
              setShowToolsLimits(!showToolsLimits);

              if (showToolsPublish) {
                setShowToolsPublish(false);
              }

              if (showToolsCopy) {
                setShowToolsCopy(false);
              }

              if (showToolsTools) {
                setShowToolsTools(false);
              }
            }}
          >
            <img
              src={showToolsLimits ? thickCross : whiteDown}
              alt="down"
              className="desktopTooslBtnDownImg"
            />
          </div>
        </div>
        <div
          className={`${
            showCopyTools
              ? `copyToolsBtn ${showToolsCopy ? "x18391832" : ""}`
              : "none"
          } ${locationMode && "none"}`}
        >
          <div
            className="desktopToolsBtnTxt"
            onClick={() => {
              if (showToolsPublish) {
                setShowToolsPublish(false);
              }
              if (showToolsLimits) {
                setShowToolsLimits(false);
              }

              if (showToolsCopy) {
                setShowToolsCopy(false);
              }

              if (showToolsTools) {
                setShowToolsTools(false);
              }
              if (pastable && memoVals.copyWeek) {
                // execute paste
                setState({ pasteLoading: true });
                axios
                  .post(
                    `${serverURL}/copy-mon-week`,
                    {
                      teamID: locationMode ? "" : selectedTeam,
                      locationID: locationMode ? selectedTeam : "",
                      monWeek: memoVals.copyWeek.monWeek,
                      newMonWeek: selectedMonWeek,
                      nowDs: dateStringer.createStringFromTimestamp(
                        new Date().getTime()
                      ),
                    },

                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .then((response) => {
                    if (response.data.message === "success") {
                      memoVals.setIndicate((x) => {
                        return {
                          show: true,
                          message: "Week schedule pasted",
                          colour: "green",
                          duration: 4000,
                        };
                      });
                      memoVals.setUpdateInfo((x) => !memoVals.updateInfo);
                    }
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              } else {
                let objToCopy = {
                  monWeek: selectedMonWeek,
                  teamID: selectedTeam,
                };
                setPastable(false);
                memoVals.setCopyWeek((x) => objToCopy);
                setShowTools(false);
                memoVals.setIndicate((x) => {
                  return {
                    show: true,
                    message: "Week schedule copied",
                    colour: "blue",
                    duration: 4000,
                  };
                });
              }
            }}
          >
            <img src={toolsCopy} className="toolsCopy" alt="tools" />
            {pastable
              ? "Paste"
              : `${
                  memoVals.copyWeek &&
                  memoVals.copyWeek.teamID === selectedTeam &&
                  memoVals.copyWeek.monWeek === selectedMonWeek
                    ? "Copied"
                    : "Copy"
                }`}
          </div>
          <div
            className="toolsDownArrContainer"
            onClick={() => {
              setShowToolsCopy(!showToolsCopy);

              if (showToolsPublish) {
                setShowToolsPublish(false);
              }
              if (showToolsLimits) {
                setShowToolsLimits(false);
              }

              if (showToolsTools) {
                setShowToolsTools(false);
              }
            }}
          >
            <img
              src={showToolsCopy ? thickCross : whiteDown}
              alt="down"
              className="desktopTooslBtnDownImg"
            />
          </div>
        </div>
        <div
          className={`${!memoVals.mob ? "toolsToolsMobOnlyBtn" : "none"}`}
          onClick={() => {
            setShowToolsTools(!showToolsTools);

            if (showToolsPublish) {
              setShowToolsPublish(false);
            }
            if (showToolsLimits) {
              setShowToolsLimits(false);
            }

            if (showToolsCopy) {
              setShowToolsCopy(false);
            }
          }}
        >
          <div className="desktopToolsBtnTxtToolsOnly ">
            {memoVals.teamSwapsQty > 0 ? (
              <p className={`toolsTeamSwapsNumber `}>
                {memoVals.teamSwapsQty > 9 ? "9+" : memoVals.teamSwapsQty}
              </p>
            ) : (
              <img src={toolsCog} className={`activeToolsCogImg`} alt="Tools" />
            )}{" "}
            Tools
          </div>
          <div className="toolsDownArrContainerToolsOnly" onClick={() => {}}>
            <img
              src={showToolsTools ? thickCross : whiteDown}
              alt="down"
              className="desktopTooslBtnDownImg"
            />
          </div>
        </div>
        {memoVals.mob ? (
          <div
            className={`schedToolsBtn ${showTools ? "x3298398" : ""}`}
            onClick={() => {
              setShowTools(!showTools);
              setShowToolsLimits(false);
            }}
          >
            {memoVals.teamSwapsQty > 0 ? (
              <p className={`toolsTeamSwapsNumberMob `}>
                {memoVals.teamSwapsQty > 9 ? "9+" : memoVals.teamSwapsQty}
              </p>
            ) : (
              <img
                src={toolsCog}
                className={`activeToolsCogImg `}
                alt="Tools"
              />
            )}
            Tools{" "}
            <img
              src={showTools ? thickCross : whiteDown}
              className="toolsDownImg"
              alt="Tools"
            />
          </div>
        ) : (
          ""
        )}
      </div>
      {showSureGenModal ? (
        <div
          className="overlapsRenewalModalUnderlay"
          onClick={() => {
            setShowSureGenModal(false);
          }}
        >
          <div
            className="overlapsRenewalModalBox"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {" "}
            <div className="overlapsRenTxt">
              This rota week has started in the past and contains existing
              shifts.
              <br />
              <br />
              If you{" "}
              {showSureGenModal === "gen"
                ? "generate this rota week now, any existing shifts will be deleted."
                : "clear this rota week, any existing shifts will be deleted."}{" "}
              <br />
              <br />
              <p className="x081981">
                This also means that any hours recorded from from timesheets and
                clock cards will disappear.
              </p>
              <br />
              <br />
              Are you sure you want to{" "}
              {showSureGenModal === "gen" ? "generate" : "clear"} this rota
              week?
            </div>
            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  if (showSureGenModal === "gen") {
                    setShowToolsTools(false);
                    setShowTools(false);
                    setShowToolsPublish(false);
                    setShowSureGenModal(false);
                    // fire
                    axios
                      .post(
                        `${serverURL}/generate-team-week`,
                        {
                          teamID: selectedTeam,
                          monWeek: selectedMonWeek,
                          ungenerating: false,
                          nowDs: dateStringer.createStringFromTimestamp(
                            new Date().getTime()
                          ),
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        memoVals.setUpdateInfo((x) => !memoVals.updateInfo);
                        memoVals.setIndicate((x) => {
                          return {
                            show: true,
                            message: "Generating week schedule",
                            colour: "blue",
                            duration: 4000,
                          };
                        });
                        setTimeout(() => {
                          setShowPubRemind(true);
                        }, 1500);
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  } else {
                    // fire clear here
                    setShowTools(false);
                    setShowToolsCopy(false);

                    setShowLimitTools(false);
                    setShowToolsPublish(false);
                    setShowToolsTools(false); // fire
                    setShowSureGenModal(false);

                    axios
                      .post(
                        `${serverURL}/clear-mon-week`,
                        {
                          teamID: selectedTeam,
                          monWeek: selectedMonWeek,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        memoVals.setUpdateInfo((x) => !memoVals.updateInfo);
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }
                }}
              >
                {showSureGenModal === "gen" ? "Generate week" : "Clear week"}{" "}
              </p>{" "}
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setShowSureGenModal(false);
                }}
              >
                Cancel
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {showShare && (
        <div
          className="clockOnModalUnderlay"
          onClick={() => {
            setPassword(originalPassword);
            setShowShare(false);
            setPasswordEdited(false);
          }}
        >
          <div
            className={`dayCommentsModal ${
              memoVals.mobModal ? "mobModalShoulder" : "maxViewZoneModalWidth"
            }`}
            onClick={(e) => {
              e.stopPropagation();
            }}
            style={modalPosition}
            ref={modalRef}
          >
            {memoVals.mobModal && (
              <div className="modalSwiper modalSwiperShowShare"></div>
            )}
            <div
              className={`mySwapsHeader spaceBetween ${
                memoVals.mobModal ? "padLR2pc" : "padLR2pc"
              }`}
              onTouchStart={handleDown}
              onTouchMove={memoVals.mobModal ? handleMove : null}
              onTouchEnd={memoVals.mobModal ? handleUp : null}
              onMouseDown={memoVals.mobModal ? handleDown : null}
              onMouseMove={memoVals.mobModal ? handleMove : null}
              onMouseUp={memoVals.mobModal ? handleUp : null}
            >
              <img
                src={navyClose}
                alt="Close"
                className="closeMySwapsModalImg"
                onClick={() => {
                  setPassword(originalPassword);
                  setPasswordEdited(false);

                  setShowShare(false);
                }}
              />
              <p>Share rota week</p>
            </div>
            <div className="dayCommentsModalBody heightFitContent">
              <div className="gpsZoneRow noBorder">
                <p className="lonLatTitles">
                  {locationMode ? "Location" : "Team"}
                </p>

                <p className={`lonLatVal`}>
                  {locationMode ? locationMode : teamName}
                </p>
              </div>{" "}
              <div className="gpsZoneRow">
                <p className="lonLatTitles">Rota week</p>

                <p className={`lonLatVal`}>
                  {dateStringer.printedDateFromDs(selectedMonWeek)} -{" "}
                  {dateStringer.printedDateFromDs(lastDs, true)}
                </p>

                {!shareLink && (
                  <p className="linkNotActive">
                    Click the 'Get link' button below to generate a link to a
                    stripped-down version of this rota to be shared with anyone.
                    Viewers will not need a FlowRota account to view it.
                  </p>
                )}
              </div>{" "}
              {shareLink && (
                <div className="gpsZoneRow">
                  <p className="lonLatTitles">Password (optional)</p>

                  <div className="sharePassDiv">
                    <input
                      type="text"
                      className="shareRotaPassInput"
                      value={password}
                      onKeyPress={(e) => {
                        enterFct(e);
                      }}
                      onChange={(e) => {
                        setPassword(e.target.value);

                        setPasswordEdited(true);
                      }}
                    />

                    {passwordEdited ? (
                      <img
                        src={whiteTick}
                        alt="Show password"
                        className={`showSharePassImg saveRotaPass`}
                        onClick={() => {
                          enterFct("tick");
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}
              {shareLink && (
                <div className="gpsZoneRow shareLinkBg">
                  <p className="lonLatTitles">Sharable link</p>

                  <div className="sharePassDiv">
                    <p className="sharablelink">
                      {appURL}/fr_{shareLink}
                    </p>
                    {copied ? (
                      <p
                        className="copiedLink"
                        onClick={() => {
                          setCopied(false);
                        }}
                      >
                        Copied
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="shareRotaIconsDiv">
                    <a
                      className="shareRotaShareBtn"
                      href={`mailto:?subject=${
                        memoVals.checkUserState.fName
                      } has shared ${dateStringer.possession(
                        teamName
                      )} rota for ${dateStringer.printedDateWithYrFromDWithoutDay(
                        selectedMonWeek
                      )} with you.&body=Here is the team rota for ${teamName} commencing ${dateStringer.printedDateFromDs(
                        selectedMonWeek
                      )}. ${appURL}/fr_${shareLink}`}
                    >
                      Email{" "}
                      <img
                        src={emailWhite}
                        alt="Email"
                        className="shareRotaIconImg emailWhite"
                      />
                    </a>
                    <a
                      className="shareRotaShareBtn"
                      href={`https://wa.me/?text=Here is the team rota for ${teamName} commencing ${dateStringer.printedDateFromDs(
                        selectedMonWeek
                      )}. ${appURL}/fr_${shareLink}`}
                      target="_blank"
                    >
                      WhatsApp{" "}
                      <img
                        src={whatsappWhite}
                        alt="Email"
                        className="shareRotaIconImg"
                      />
                    </a>
                    <p
                      className="showSharePassImg p183141"
                      onClick={() => {
                        const copyToClipboard = async (textToCopy) => {
                          try {
                            if (navigator.clipboard) {
                              // Use Clipboard API if available
                              await navigator.clipboard.writeText(textToCopy);
                              setCopied(true);

                              console.log(
                                "Text successfully copied to clipboard!"
                              );
                            } else {
                              // Fallback for browsers that do not support Clipboard API
                              const textArea =
                                document.createElement("textarea");
                              textArea.value = textToCopy;
                              document.body.appendChild(textArea);
                              textArea.select();
                              document.execCommand("copy");
                              document.body.removeChild(textArea);
                              setCopied(true);

                              console.log(
                                "Text successfully copied to clipboard (fallback)"
                              );
                            }
                          } catch (err) {
                            console.error(
                              "Unable to copy text to clipboard:",
                              err
                            );
                          }
                        };

                        copyToClipboard(`${appURL}/fr_${shareLink}`);

                        setTimeout(() => {
                          setCopied(false);
                        }, 4000);
                      }}
                    >
                      Copy
                    </p>
                  </div>
                </div>
              )}
            </div>
            <div className="dayCommentsModalFooter">
              <div className="viewMapsAndSaveDiv">
                {linkExists && (
                  <p
                    className="shareRotaCta shareRotaBack x384829x"
                    onClick={() => {
                      setSureDestroy(true);
                    }}
                  >
                    Destroy link
                  </p>
                )}

                {sureDestroy ? (
                  <div
                    className="areYouSureModalUnderlay"
                    onClick={() => {
                      setSureDestroy(false);
                    }}
                  >
                    <div
                      className="formCreatedModal"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <p className="overlapsRenTxt">
                        Are you sure you want to destory this link?
                        <br />
                        <br />
                        You can generate a new link at any time.
                      </p>
                      <div className="areYouModalBtnsDiv">
                        <p
                          className="areYouSureModalYesBtn"
                          onClick={() => {
                            axios
                              .post(
                                `${serverURL}/destroy-rota-link`,
                                {
                                  monWeek: selectedMonWeek,
                                  teamID: selectedTeam,
                                },

                                {
                                  withCredentials: true,
                                  credentials: "include",
                                }
                              )
                              .then((response) => {
                                if (response.data.message === "success") {
                                  setSureDestroy(false);
                                  if (shareLink) {
                                    setShareLink(false);
                                    setLinkExists(false);
                                    setPassword("");
                                    setOriginalPassword("");
                                  }

                                  if (shareEmail) {
                                    setShareEmail(false);
                                  }
                                }
                              })
                              .catch((err) => {
                                console.error(err);
                              });
                          }}
                        >
                          Yes
                        </p>
                        <p
                          className="areYouModalNoBtn"
                          onClick={() => {
                            setSureDestroy(false);
                          }}
                        >
                          No
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {!linkExists && (shareLink || shareEmail) ? (
                  <p
                    className="shareRotaCta shareRotaBack"
                    onClick={() => {
                      if (shareLink) {
                        setShareLink(false);
                      }

                      if (shareEmail) {
                        setShareEmail(false);
                      }
                    }}
                  >
                    Back
                  </p>
                ) : (
                  ""
                )}
                {!shareLink && !shareEmail && (
                  <p
                    className="shareRotaCta blueColours"
                    onClick={() => {
                      axios
                        .post(
                          `${serverURL}/create-rota-link`,
                          {
                            monWeek: selectedMonWeek,
                            teamID: locationMode ? "" : selectedTeam,
                            locationID: locationMode ? selectedTeam : "",
                            password,
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message === "success") {
                            setShareLink(response.data.shortenedLink);
                            setPassword(response.data.password);
                            setLinkExists(true);
                          }
                        });
                    }}
                  >
                    Get link
                  </p>
                )}
                {!shareLink ? (
                  <p className="linkNotYetActiveIndicate">No link yet</p>
                ) : (
                  <p className="linkNotYetActiveIndicate linkActive">
                    Link active
                  </p>
                )}
                {/* <p
                  className="shareRotaCta shareRotaBack x248928742"
                  onClick={() => {}}
                >
                  Notify {locationMode ? 'staff' : 'team'} 
                </p> */}
                {/* {!shareLink && !shareEmail && (
                  <p
                    className="shareRotaCta marginLeft8"
                    onClick={() => {
                      setShareEmail(true);
                    }}
                  >
                    Share
                  </p>
                )} */}
              </div>

              <p
                className="closeSeeUserIDBtn"
                onClick={() => {
                  setPassword("");
                  setPassword(originalPassword);

                  setPasswordEdited(false);

                  setShowShare(false);
                }}
              >
                Close
              </p>
            </div>
          </div>
        </div>
      )}
      {showAcceptModal && (
        <div
          className="clockOnModalUnderlay"
          onClick={() => {
            setShowAcceptModal(false);
            memoVals.setModalOpen((x) => false);
          }}
        >
          <div
            className={`dayCommentsModal ${
              memoVals.mobModal ? "mobModalShoulder" : "maxViewZoneModalWidth"
            }`}
            onClick={(e) => {
              e.stopPropagation();
            }}
            style={modalPosition}
            ref={modalRef}
          >
            {memoVals.mobModal && (
              <div className="modalSwiper modalSwiperShowAccept"></div>
            )}
            <div
              className={`mySwapsHeader spaceBetween ${
                memoVals.mobModal ? "padLR2pc" : "padLR2pc"
              }`}
              onTouchStart={handleDown}
              onTouchMove={memoVals.mobModal ? handleMove : null}
              onTouchEnd={memoVals.mobModal ? handleUp : null}
              onMouseDown={memoVals.mobModal ? handleDown : null}
              onMouseMove={memoVals.mobModal ? handleMove : null}
              onMouseUp={memoVals.mobModal ? handleUp : null}
            >
              <img
                src={navyClose}
                alt="Close"
                className="closeMySwapsModalImg"
                onClick={() => {
                  setShowAcceptModal(false);
                  memoVals.setModalOpen((x) => false);
                }}
              />
              <p></p>

              <p>Shift acceptance</p>
            </div>
            <div className="dayCommentsModalBody heightFitContent">
              <div
                className={`gpsZoneRow noBorder xd3719 ${
                  !memoVals.mob ? "clickShiftRow" : ""
                }`}
                onClick={() => {
                  axios
                    .post(
                      `${serverURL}/require-team-accept-shifts`,
                      {
                        teamID: selectedTeam,
                        data: !requireSeen,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      setRequireSeen(response.data.data);
                      memoVals.setUpdateInfo((x) => !memoVals.updateInfo);
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                {/* <p className="lonLatTitles">Team</p> */}

                <p className={`lonLatTitles l129018`}>
                  Require members of {teamName} to accept new shifts
                </p>
                {/*  KNOB BEGIN */}
                <div
                  className={`editUserReqClockOutKnobContainer  requireSeenKnob`}
                >
                  <div className="knobContainer knobContainerEditUser x4899854344">
                    <div
                      className={`switchKnob ${
                        requireSeen
                          ? "knobOn knobOnMgrColour knobRed"
                          : "knobOff"
                      }`}
                    ></div>
                    <div className="knobBg"></div>
                  </div>
                </div>
                {/*  KNOB END */}
              </div>{" "}
              {requireSeen && (
                <div className="gpsZoneRow xd3719">
                  {/* <p className="lonLatTitles">Team</p> */}

                  <p className={`lonLatTitles l129018 l298424`}>
                    Shifts accepted by members of {teamName} will show double
                    ticks.
                  </p>
                  <img
                    src={doubleTick}
                    alt="Doube ticks"
                    className="doubleTickExampleImg"
                  />
                </div>
              )}
            </div>
            <div className="dayCommentsModalFooter">
              <p></p>

              <p
                className="closeSeeUserIDBtn"
                onClick={() => {
                  setShowAcceptModal(false);
                  memoVals.setModalOpen((x) => false);
                }}
              >
                Close
              </p>
            </div>
          </div>
        </div>
      )}
      {showCannotManageOwnShift.show ? (
        <div
          className="overlapsRenewalModalUnderlay"
          onClick={() => {
            setShowCannotManageOwnShift({ show: false });
          }}
        >
          <div
            className="overlapsRenewalModalBox"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {" "}
            {!tryingToOpenShiftUserManagesButNotAllowed.shiftID ? (
              <div className="overlapsRenTxt">
                You are unable to amend, add or remove your own shifts as a
                manager within {teamName}.<br />
                <br />
                If you need to amend, add or remove your own shifts as a manger
                of your own team, ask a FlowRota administrator to enable you to
                amend your own shifts.
                <br />
                <br />
                Share this{" "}
                <a
                  className="makeBold cursorPointer x8499282"
                  href="https://flowrota.com/support/manager-self-allowances-enable"
                  target="_blank"
                >
                  guidance link
                </a>{" "}
                with them.
              </div>
            ) : (
              <div className="overlapsRenTxt">
                You are unable to amend your own shifts as a manager within{" "}
                {teamName}.<br />
                <br />
                If you need to amend your own shifts as a manger of your own
                team, ask a FlowRota administrator to enable you to amend your
                own shifts.
                <br />
                <br />
                Share this{" "}
                <a
                  className="makeBold cursorPointer x8499282"
                  href="https://flowrota.com/support/manager-self-allowances-enable"
                  target="_blank"
                >
                  guidance link
                </a>{" "}
                with them.
              </div>
            )}
            {!tryingToOpenShiftUserManagesButNotAllowed.shiftID ? (
              <p
                className="tempSavedCloseBtn"
                onClick={() => {
                  setShowCannotManageOwnShift({ show: false });
                }}
              >
                Understood
              </p>
            ) : (
              <div className="areYouModalBtnsDiv">
                <p
                  className="areYouSureModalYesBtn"
                  onClick={() => {
                    // open my shift
                    setShowCannotManageOwnShift({ show: false });

                    memoVals.setShowMyShiftModal((x) => {
                      return {
                        unitType:
                          tryingToOpenShiftUserManagesButNotAllowed.type,
                        unitID:
                          tryingToOpenShiftUserManagesButNotAllowed.shiftID,
                      };
                    });
                    setTryingToOpenShiftUserManagesButNotAllowed(false);
                  }}
                >
                  View{" "}
                  {showCannotManageOwnShift.type === "til"
                    ? "lieu shift"
                    : showCannotManageOwnShift.type}
                </p>{" "}
                <p
                  className="areYouModalNoBtn"
                  onClick={() => {
                    setShowCannotManageOwnShift({ show: false });
                    setTryingToOpenShiftUserManagesButNotAllowed(false);
                  }}
                >
                  Close
                </p>
              </div>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
      {locationMode && (
        <div
          className={`locModeParent ${memoVals.mob ? "locModeParentMob" : ""}`}
        >
          <p className={`locMode ${memoVals.mob ? "locModeMob" : ""}`}>
            All staff at location:{" "}
            <span className="makeBold marginLeft8px">
              {dateStringer.shorten(locationMode, memoVals.mob ? 30 : 99)}
            </span>
          </p>
        </div>
      )}
      {imgPreload}
    </div>
  );
};

export default TeamSchedule;

import React, { useState, useContext, useEffect, useMemo } from "react";
// cleaned wef 24 jul 23
// params cleared 10 sep 23
// preloaded images 19 sep 23
// axios caught 21 sep 23

import axios from "axios";
import { CalendarContext } from "../../../contexts/CalendarContext";
import { DataContext } from "../../../contexts/DataContext";
import { StyleContext } from "../../../contexts/StyleContext";
import { UserContext } from "../../../contexts/UserContext";

import serverURL from "../../../serverURL";

import dateStringer from "../../../tools/dateStringer";

import horizontalBalls from "../../../img/loaders/horizontalBalls.svg";
import timesheetImg from "../../../img/general/ts.svg";
import profilePicturePlaceholder from "../../../img/general/profilePicturePlaceholder.svg";
import teamIcon from "../../../img/general/teamIcon.svg";
import rotaDate from "../../../img/general/rotaDate.svg";
import rotaDur from "../../../img/general/rotaDur.svg";
import whiteTick from "../../../img/general/whiteTick.svg";
import editGrey from "../../../img/general/ts1.svg";
import rotaTeam from "../../../img/general/rotaTeam.svg";

import "../../../styles/calendar.css";

import ShiftsHeader from "./ShiftsHeader";
import TeamHoursDataBox from "./TeamHoursDataBox";

const TeamTimesheets = ({ sideBoxData, my, setMy }) => {
  const { setCalPage } = useContext(CalendarContext);
  const {
    setPage,
    availableMonths,
    selectedShiftTeam,
    setShowViewTimesheet,
    updateUserTimesheets,
    device,
    setShowEditUser,
    countData,
  } = useContext(DataContext);
  const { mob, full, fullButNarrow } = useContext(StyleContext);
  const { checkUserState } = useContext(UserContext);

  const memoVals = useMemo(
    () => ({
      setPage, //
      availableMonths, //
      selectedShiftTeam, //
      setShowViewTimesheet, //
      updateUserTimesheets, //
      setCalPage, //
      mob, //
      full, //
      checkUserState,
      fullButNarrow, //
      device,
      setShowEditUser,
    }),
    [
      setPage, //
      availableMonths, //
      selectedShiftTeam, //
      setShowViewTimesheet, //
      updateUserTimesheets, //
      setCalPage, //
      mob, //
      full, //
      fullButNarrow, //
      checkUserState,
      device,
      setShowEditUser,
    ]
  );

  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);
  let [imagesLoaded, setImagesLoaded] = useState(0);

  useEffect(() => {
    console.log({ dataLoaded, imagesLoaded });
    if (dataLoaded && imagesLoaded === 8) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);
  let imgPreload = (
    <div className="imagesHidden">
      {" "}
      <img
        src={timesheetImg}
        alt="Timesheet"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaTeam}
        alt="Timesheet"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={editGrey}
        alt="Timesheet"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={whiteTick}
        alt="Timesheet"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaDate}
        alt="Timesheet"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaDur}
        alt="Timesheet"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={teamIcon}
        alt="Timesheet"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={profilePicturePlaceholder}
        alt="Timesheet"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params && params.timesheetID) {
      memoVals.setShowViewTimesheet((x) => {
        return {
          show: true,
          timesheetID: dateStringer.cleanParam(params.timesheetID),
          isMgr: true,
        };
      });

      let currentUrl = new URL(window.location.href);

      // Remove query parameters by setting search to an empty string
      currentUrl.search = "";

      // Update the URL without the query parameters
      // todo: uncomment this
      // window.history.replaceState({}, document.title, currentUrl.href);
    }
  }, []);
  let [clockOns, setClockOns] = useState([]);
  let [pendingExist, setPendingExist] = useState(0);

  let [timesheets, setTimesheets] = useState([]);

  useEffect(() => {
    // clean not needed
    let today = new Date();
    let monthNum = today.getMonth();
    if (monthNum < 10) {
      monthNum = `0${monthNum}`;
    } else {
    }
    let yearNum = JSON.stringify(today.getFullYear()).substr(2, 2);

    setMy(`${monthNum}${yearNum}`);

    memoVals.setCalPage((x) => "team-shifts");
  }, []);

  let [users, setUsers] = useState([]);
  let [selectedUserID, setSelectedUserID] = useState("");
  const cancelSource1 = axios.CancelToken.source();
  const cancelSource2 = axios.CancelToken.source();

  useEffect(() => {
    if (!users[0]) {
      axios
        .post(
          `${serverURL}/get-users-user-can-manage`,
          { someData: 1 },
          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            setUsers(
              response.data.usersArr.sort((a, b) => {
                return a.fName.localeCompare(b.fName);
              })
            );
          }
        })
        .catch((err) => {
          console.error(err);
        });

      return () => {
        cancelSource1.cancel("Component unmounted");
      };
    }
  }, [timesheets]);

  let [reloading, setReloading] = useState(false);
  let [isCurrMy, setIsCurrMy] = useState(false);
  useEffect(() => {
    axios
      .post(
        `${serverURL}/get-team-timesheets`,
        { teamID: memoVals.selectedShiftTeam, my },
        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource2.token,
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          setIsCurrMy(response.data.currMonth);
          setDataLoaded(true);
          let pending = 0;
          response.data.teamTimesheets.forEach((ts) => {
            if (!ts.approved && !ts.declined) {
              pending++;
            }
          });

          if (reloading) {
            setReloading(false);
          }

          let pends = [];
          let decided = [];
          if (response.data.teamTimesheets) {
            response.data.teamTimesheets.forEach((ts) => {
              if (!ts.approved && !ts.declined) {
                pends.push(ts);
              } else {
                decided.push(ts);
              }
            });
          }

          let arr = [...pends];
          if (pending && decided[0]) {
            arr.push({ split: true });
          }

          arr = [...arr, ...decided];

          setPendingExist(pending);
          setTimesheets(arr || []);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [memoVals.selectedShiftTeam, my, memoVals.updateUserTimesheets]);

  let generateMonthOptions = memoVals.availableMonths.map((month) => {
    if (dateStringer.getTsFromMy(month) <= new Date().getTime()) {
      let formatMonth = "Jan";
      let formatYear = month.substr(2, 2);
      if (month.substr(0, 2) === "00") {
        formatMonth = "Jan";
      }
      if (month.substr(0, 2) === "01") {
        formatMonth = "Feb";
      }
      if (month.substr(0, 2) === "02") {
        formatMonth = "Mar";
      }
      if (month.substr(0, 2) === "03") {
        formatMonth = "Apr";
      }
      if (month.substr(0, 2) === "04") {
        formatMonth = "May";
      }
      if (month.substr(0, 2) === "05") {
        formatMonth = "Jun";
      }
      if (month.substr(0, 2) === "06") {
        formatMonth = "Jul";
      }
      if (month.substr(0, 2) === "07") {
        formatMonth = "Aug";
      }
      if (month.substr(0, 2) === "08") {
        formatMonth = "Sep";
      }
      if (month.substr(0, 2) === "09") {
        formatMonth = "Oct";
      }
      if (month.substr(0, 2) === "10") {
        formatMonth = "Nov";
      }
      if (month.substr(0, 2) === "11") {
        formatMonth = "Dec";
      }

      return (
        <option key={month} value={month}>
          {formatMonth} {formatYear}
        </option>
      );
    }
  });

  useEffect(() => {
    memoVals.setPage((x) => "team-submissions");
  }, []);

  let [showFilter, setShowFilter] = useState(false);

  document.title = "FlowRota - Staff timesheets";

  let generateTimesheets = useMemo(() => {
    return timesheets.map((ts, i) => {
      if (ts.split) {
        return <div className="pendingTsSplitterMgr"></div>;
      } else {
        const startDate = dateStringer.printedDateFromDs(ts.startDs);
        const endDateCondition =
          ts.startDs !== `${ts.endDs.substr(0, 11)}H00M00`
            ? dateStringer.printedDateFromDs(ts.endDs)
            : "";
        const dashCondition =
          ts.startDs !== `${ts.endDs.substr(0, 11)}H00M00` ? "-" : "";

        const fullString = `${startDate} ${dashCondition} ${endDateCondition}`;

        return (
          <div
            className={`mgrClockUnitWrapperOpen ${
              ts.declined ? "opac07_" : ""
            } tsUnitWithoutApprove
            `}
            index={i}
          >
            <div className="clockNameAndTeamDiv tsNameAndTeamDiv">
              <div
                className={`mgrClockNameDiv  pendClockName_ ${
                  window.innerWidth > 1000
                    ? "tsFullWidthItem"
                    : window.innerWidth >= 820
                    ? "tsMediumWidthItem"
                    : "tsMobWidthItem"
                }`}
                onClick={(e) => {
                  e.stopPropagation();

                  memoVals.setShowEditUser((x) => ts.userID);
                }}
              >
                <img
                  src={ts.picUrl || profilePicturePlaceholder}
                  alt={ts.fName}
                  className="itemNameTabImg"
                />{" "}
                {ts.fName} {ts.lName}{" "}
                {ts.you ? <span className="youSpan">(You)</span> : ""}
              </div>
            </div>

            <div
              className={`mgrClockUnit mgrTsUnit noBorderRadiusBottomRight_ ${
                memoVals.mob ? "borderRadius10" : "tsUnitDekstop"
              }`}
              onClick={() => {
                memoVals.setShowViewTimesheet((x) => {
                  return {
                    show: true,
                    timesheetID: ts.timesheetID,
                    isMgr: true,
                  };
                });
              }}
            >
              {!ts.approved && !ts.declined ? (
                <div
                  className={`unsubmittedTsBlob clockAwaitBlob teamTsBlob`}
                ></div>
              ) : (
                ""
              )}
              <div className="teamClockLeftParent">
                <div className="tilShiftSection0"></div>
                <div
                  className={`tilShiftSection1Clock pendingClockSection1 ${
                    window.innerWidth < 1080 && memoVals.full
                      ? "pendingClockSection1CorrectionMidFull"
                      : ""
                  } ${window.innerWidth < 458 ? "x248484984" : ""}`}
                >
                  <p
                    className={`tilShiftDs tilShiftDsFirstRow flexStart ${
                      fullString.length > 20 && memoVals.mob
                        ? "fullStringTsSmaller"
                        : ""
                    }`}
                  >
                    {/* x222 */}
                    <img
                      src={rotaDate}
                      alt="Clocked times"
                      className="teamClockRotaClockImg x48948998"
                    />
                    {fullString}
                  </p>

                  <p
                    className={`tilShiftDs tilShiftDs flexStart teamClocksLowerRow teamClocksLowerRow2`}
                  >
                    <img
                      src={rotaDur}
                      alt="Clocked times"
                      className="teamClockRotaClockImg teamClockRotaClockImgCoffee"
                    />
                    {dateStringer.formatMinsDurationToHours(ts.durMins)}{" "}
                    {ts.declined && (
                      <span className="tsDeclinedSpan">Declined</span>
                    )}
                  </p>
                  <p
                    className={`tilShiftDs tilShiftDs flexStart teamClocksLowerRow teamClocksLowerRow2 x458958945895`}
                  >
                    <img
                      src={rotaTeam}
                      alt="Clocked times"
                      className="teamClockRotaClockImg teamClockRotaClockImgCoffee"
                    />
                    {dateStringer.shorten(ts.teamName, 24)}
                  </p>
                </div>
              </div>
              <div className={`teamClockRightParent`}>
                <div className="tilShiftSection2 pendingClockSection2">
                  <div className="tsIllus">
                    <p className="tsIllusTitle">
                      Timesheet{" "}
                      <img
                        src={editGrey}
                        alt="Timesheet"
                        className="tsIllusPencil"
                      />
                    </p>
                    <p className="tsIllusValue">
                      {ts.itemsQty} record{ts.itemsQty === 1 ? "" : "s"}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* {clock.oneOfTwo === "two" ? (
                <div className="x485487597252"></div>
              ) : (
                ""
              )}{" "} */}
          </div>
          // <div
          //   className={`
          //  mgrClockUnitWrapperOpen x9879741 ${
          //    memoVals.mob && i === 0 ? "pullFirstMobClockUnitUp" : ""
          //  } animateZoomIn`}
          //   onClick={() => {
          //     memoVals.setShowViewTimesheet((x) => {
          //       return {
          //         show: true,
          //         timesheetID: ts.timesheetID,
          //         isMgr: true,
          //       };
          //     });
          //   }}
          // >
          //   <div className="clockNameAndTeamDiv">
          //     <div
          //       className={`mgrClockNameDiv userTabEditUser ${
          //         !ts.approved && !ts.declined
          //           ? "pendClockName userTabEditUserPend"
          //           : ""
          //       } `}
          //       onClick={(e) => {
          //         e.stopPropagation();
          //         memoVals.setShowEditUser((x) => ts.userID);
          //       }}
          //     >
          //       <img
          //         src={ts.picUrl || profilePicturePlaceholder}
          //         alt={ts.fName}
          //         className="itemNameTabImg"
          //       />{" "}
          //       {ts.fName} {ts.lName}{" "}
          //       {ts.userID === memoVals.checkUserState.userID ? (
          //         <span className="youSpan">(You)</span>
          //       ) : (
          //         ""
          //       )}
          //     </div>
          //     <div
          //       className={`mgrClockNameDiv clockTeamNameTopRight x981452 ${
          //         !ts.approved && !ts.declined ? "pendClockName" : ""
          //       }`}
          //     >
          //       {memoVals.mob || memoVals.fullButNarrow
          //         ? dateStringer.shorten(ts.teamName, 15)
          //         : dateStringer.shorten(ts.teamName, 26)}{" "}
          //       <img src={teamIcon} alt="Team" className="teamIconHours" />
          //     </div>{" "}
          //   </div>

          //   <div
          //     className={`mgrReqItem noAni ${
          //       !ts.approved && !ts.declined ? "pend" : ""
          //     } ${memoVals.mob ? "borderRadius10" : ""}`}
          //     onClick={() => {}}
          //   >
          //     <div
          //       className={`tsUnitLeft ${
          //         !ts.submitted
          //           ? `tsUnitLeftNonSubmitted ${
          //               memoVals.mob && (ts.approved || ts.declined)
          //                 ? "x88381831"
          //                 : ""
          //             }`
          //           : ""
          //       }`}
          //     >
          //       {!ts.approved && !ts.declined ? (
          //         <div className="unsubmittedTsBlob"></div>
          //       ) : (
          //         ""
          //       )}
          //       <img
          //         src={timesheetImg}
          //         alt="Timesheet"
          //         className={`tsUnitImg ${!ts.submitted ? "x308491" : ""}`}
          //       />
          //     </div>
          //     <div
          //       className={`reqColBarDiv reqColBarAdjust5 ${
          //         ts.approved ? "" : "divPend"
          //       } ${ts.declined ? "divDec" : ""}`}
          //     ></div>
          //     <div
          //       className={`tsUnitMiddle ${
          //         memoVals.mob ? "tsUnitMiddleMob" : ""
          //       }`}
          //     >
          //       <div className="tsUnitMidLeft">
          //         <div
          //           className={`tsFromTilSection ${
          //             !ts.approved && !ts.declined ? "x32897478243" : ""
          //           }`}
          //         >
          //           <div className="tsUnitFromToSection">
          //             {/* <p className="tsUnitDsTitle">From</p> */}
          //             <p
          //               className={`tsUnitDsValue x2456788743 ${
          //                 memoVals.mob ? "tsUnitDsValueMob" : ""
          //               }`}
          //             >
          //               {/* {ts.fName} {ts.lName[0]} */}
          //               {dateStringer.printedDateFromDs(ts.startDs)}{" "}
          //               {ts.startDs !== `${ts.endDs.substr(0, 11)}H00M00`
          //                 ? "-"
          //                 : ""}{" "}
          //               {ts.startDs !== `${ts.endDs.substr(0, 11)}H00M00`
          //                 ? dateStringer.printedDateFromDs(ts.endDs)
          //                 : ""}
          //             </p>
          //           </div>
          //         </div>
          //         <div className="tsUnitTeamName x3413567">
          //           {ts.approved || ts.declined ? (
          //             <p
          //               className={`submittedTsBadgeMgr notYetSubMgr ${
          //                 ts.declined ? "x091131" : "approvedTsTxt"
          //               }`}
          //             >
          //               {ts.approved ? "Approved" : "Declined"}
          //             </p>
          //           ) : (
          //             <p className={`submittedTsBadgeMgr notYetSubMgr`}>
          //               Awaiting approval
          //             </p>
          //           )}
          //         </div>
          //       </div>
          //       <div className="tsUnitMidRight">
          //         <div className="tsUnitFromToSection x1341">
          //           <p className="tsUnitDsTitle">Records</p>
          //           <p className="tsUnitDsValue">{ts.itemsQty}</p>
          //         </div>
          //       </div>
          //     </div>
          //     <div className="itemTongue tsTongue">
          //       <p className="tongueTxt clockTongueTxt">Timesheet</p>
          //     </div>
          //     {/* <div className="tsUnitRight"></div> */}
          //   </div>
          // </div>
        );
      }
    });
  }, [timesheets, memoVals.mob, window.innerWidth]);

  let [blockApprove, setBlockApprove] = useState(false);
  return (
    <div
      className={`userShiftsPage ${memoVals.mob ? "" : "pageLeft240pxDesktop"}`}
    >
      <ShiftsHeader my={my} setMy={setMy} />
      {/* <div className="adminCurverCropper bgColUpcoming absenceShoulder">
        <div className="adminCurve"></div>
      </div>{" "} */}
      <div className={`desktopMaster ${memoVals.mob ? "desktopPurpBg" : ""}`}>
        <div
          className={`desktopMasterMain  ${
            memoVals.mob ? "mob100Witdth mob100WitdthPlus2" : ""
          } ${!memoVals.full && !memoVals.mob ? "midContentWidth" : ""}  ${
            memoVals.mob ? "mgrHoursShoulderBar" : ""
          }`}
        >
          <div
            className={`teamTsFilterBar ${
              !memoVals.mob ? "borderLeftGrey" : "teamTsFilterBarMob"
            }`}
          >
            <div
              className={`shiftsPageFilterBarLeft ${
                memoVals.mob
                  ? "shiftsPageFilterBarLeftMobTsTeam mobTsMonthSelTeam"
                  : ""
              }`}
            >
              <select
                className={`shiftsTilMonthDropdown shiftsTilMonthDropdownSmall ${
                  my === dateStringer.getMyFromTs(new Date().getTime())
                    ? "colour00aaff"
                    : ""
                }`}
                value={my}
                onChange={(e) => {
                  setReloading(true);
                  // setUpdateShifts(!updateShifts);
                  setMy(e.target.value);
                }}
              >
                {generateMonthOptions}
              </select>
            </div>{" "}
            <div
              className={`shiftsPageFilterBarRight ${
                memoVals.mob ? "shiftsPageFilterBarRightMobTsTeam" : ""
              }`}
            >
              <select
                className={`shiftNamesDropdown teamTsUsersDropper ${
                  memoVals.mob
                    ? "shiftsHeaderTeamDropdownMob teamTsUsersDropperMob"
                    : ""
                }`}
                value={selectedUserID}
                onChange={(e) => {
                  setSelectedUserID(e.target.value);
                }}
              >
                <option value="allStaff">-- All of your staff</option>
                {users.map((usr, i) => {
                  return (
                    <option value={usr.userID} key={i}>
                      {usr.fName} {usr.lName[0]}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="shiftTilFilterHolder"></div>
          <div className="filterSplitterContainer">
            <div className="filterSplitter"></div>
          </div>
          {/* - - - - */}

          <div
            className={`tsContainer ${!memoVals.full ? "noBorderRight" : ""} ${
              memoVals.mob
                ? `tsContainerStaffMob ${
                    memoVals.device.ios ? "tsContainerStaffMobIos" : ""
                  }`
                : ""
            }`}
          >
            <img
              src={horizontalBalls}
              className={`${
                loading || reloading ? "loadingHoriztonalImg" : "none"
              }`}
              alt="Loading"
            />

            {isCurrMy && !loading && !reloading && countData.timesheets > 0 ? (
              <p className="clocksAwaitingTitles notYetSubmitTitleArr">
                Awaiting approval{" "}
                <span className="pendingChNum pendingChNumMgr">
                  {countData.timesheets}
                </span>
              </p>
            ) : (
              ""
            )}
            {!loading && !reloading ? generateTimesheets : ""}
            {!loading && !reloading && !timesheets[0] ? (
              <p
                className={`noShiftsTxt noTimesheetsTxt ${
                  memoVals.mob ? "noTimesheetsTxtMob" : ""
                }`}
              >
                No timesheets in {dateStringer.printMonthAndYearFromMy(my)}
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
        <div
          className={`${
            memoVals.mob || !memoVals.full ? "none" : "desktopMasterSecondary"
          }`}
        >
          <TeamHoursDataBox setLoadUser={false} setShowRequests={false} />{" "}
        </div>
      </div>
      {blockApprove ? (
        <div className="addAbsenceModalUnderlay">
          <div className="formCreatedModal">
            <p className="overlapsRenTxt">
              You cannot approve your own timesheets. <br />
              <br />
              Only another manager from your primary team or a FlowRota admin
              can approve them.
              <br />
              <br />
              If you require permission to approve your own timesheets, a
              FlowRota admin can grant it by following{" "}
              <a
                href="https://flowrota.com/support/self-approve-permissions"
                target="_blank"
                className="colour00aaff"
              >
                this guide
              </a>
              .
            </p>

            <div className="areYouModalBtnsDiv">
              <p
                className="closeSwapsNoLongerBtn"
                onClick={() => {
                  setBlockApprove(false);
                }}
              >
                Understood
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {imgPreload}
    </div>
  );
};

export default TeamTimesheets;

import React, { useRef, useContext, useEffect, useState, useMemo } from "react";
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios done
import { NavLink } from "react-router-dom";
import axios from "axios";
import serverURL from "../serverURL";
import DemoUserSelect from "./DemoUserSelect";
import ManagerShiftModal from "../components/calendar/shifts/ManagerShiftModal";

import "../styles/navbar.css";

import { DataContext } from "../contexts/DataContext";
import { UserContext } from "../contexts/UserContext";
import { RequestContext } from "../contexts/RequestContext";
import { CalendarContext } from "../contexts/CalendarContext";
import { StyleContext } from "../contexts/StyleContext";
import dateStringer from "../tools/dateStringer";

import placeholder from "../img/general/profilePicturePlaceholder.svg";
import checkbox1 from "../img/general/checkboxInstall1.svg";
import checkbox2 from "../img/general/checkboxInstall4.svg";
import whitecross from "../img/modals/close.svg";
import whitebin from "../img/general/whitebin.svg";
import swap2 from "../img/general/swap2white.svg";
import swap2navy from "../img/general/swap2.svg";

import chat from "../img/general/chatNotif.svg";
import notifShiftChange from "../img/general/notifShiftChange.svg";
import whiteTick from "../img/general/whiteTickNotif.svg";
import thickCross from "../img/general/thickCrossNotif.svg";
import notifClock from "../img/general/notifClock.svg";
import absenceNotif from "../img/general/absenceNotif.svg";
import notifRota from "../img/general/notifRota.svg";
import plusWhite from "../img/general/plus_whiteNotif.svg";
import formNotif from "../img/general/formNotif.svg";
import whiteBell from "../img/general/bell2.svg";
import hammer from "../img/general/hammer.svg";
import newDoc from "../img/general/newDoc.svg";

import appURL from "../appURL";

const Notifications = ({
  handleDown,
  handleMove,
  handleUp,
  modalPosition,
  modalRef,
  notifsLoading,
  setNotifsLoading,
}) => {
  const { mob, mobModal } = useContext(StyleContext);
  const { setShowOpenShiftModal } = useContext(CalendarContext);
  const { checkUserState } = useContext(UserContext);

  let today00Obj = new Date();
  today00Obj.setTime(0, 0, 0, 0);
  let today00Ts = today00Obj.getTime();
  let yesterday00Obj = new Date();
  yesterday00Obj.setDate(yesterday00Obj.getDate() - 1);
  yesterday00Obj.setTime(0, 0, 0, 0);
  let yesterday00Ts = yesterday00Obj.getTime();
  const {
    setShowUserReqFromNav,
    showNotifications,
    setShowNotifications,
    setUnreadNotifs,
    device,
    setShowEditShiftModal,
    setItemType,
    setItemID,
    setShowMyShiftModal,
    setShowMessages,
    setShowSwapsModal,
    setClockOnID,
    setShowReqMgrModal,
    setShowTeamSwapsModal,
    setShowViewTimesheet,
    setShowDayCommentModal,
    setShowForm,
    setShowMgrAcceptanceModal,
    setShowAddAvailModal,
    setShowReqStats,
    setShowChecklist,
    modalOpen,
    setModalOpen,
    setShowActiveClocks,
    setShowUserOpenShiftModal,
    countData,
    setShowNotifReqs,
    setShowMyDocsModal,
  } = useContext(DataContext);

  const memoVals = useMemo(
    () => ({
      setShowUserReqFromNav,
      showNotifications,
      setShowNotifications,
      setUnreadNotifs,
      device,
      setShowEditShiftModal,
      setItemType,
      setItemID,
      setShowMyShiftModal,
      setShowMessages,
      setShowSwapsModal,
      setClockOnID,
      setShowReqMgrModal,
      setShowTeamSwapsModal,
      setShowViewTimesheet,
      setShowDayCommentModal,
      setShowForm,
      setShowMgrAcceptanceModal,
      setShowAddAvailModal,
      setShowOpenShiftModal,
      mobModal,
      mob,
      setShowReqStats,
      checkUserState,
      setShowChecklist,
      modalOpen,
      setModalOpen,
      setShowActiveClocks,
      setShowUserOpenShiftModal,
      setShowNotifReqs,
      setShowMyDocsModal,
    }),
    [
      setShowUserReqFromNav, //
      showNotifications, //
      setShowNotifications, //
      setUnreadNotifs, //
      device, //
      setShowEditShiftModal, //
      setItemType, //
      setItemID, //
      setShowMyShiftModal, //
      setShowMessages, //
      setShowSwapsModal, //
      setClockOnID, //
      setShowReqMgrModal, //
      setShowTeamSwapsModal, //
      setShowViewTimesheet, //
      setShowDayCommentModal, //
      setShowForm, //
      setShowMgrAcceptanceModal, //
      setShowAddAvailModal, //
      setShowOpenShiftModal, //
      mobModal, //
      mob, //
      setShowReqStats,
      checkUserState,
      setShowChecklist,
      modalOpen,
      setModalOpen,
      setShowActiveClocks,
      setShowUserOpenShiftModal,
      setShowNotifReqs,
      setShowMyDocsModal,
    ]
  );

  useEffect(() => {
    memoVals.setModalOpen((x) => true);

    return () => {
      memoVals.setModalOpen((x) => false);
    };
  }, [memoVals.setModalOpen]);

  const scrollRef = useRef(null);

  let [noMoreNotifs, setNoMoreNotifs] = useState(false);
  let [teamSwapsRequiringAction, setTeamSwapsRequiringAction] = useState(0);
  let [userSwapsRequiringAction, setUserSwapsRequiringAction] = useState(0);

  let [verifSent, setVerifSent] = useState(false);
  let [verifAlreadySent, setVerifAlreadySent] = useState(false);
  let [imagesLoaded, setImagesLoaded] = useState(0);
  let [dataLoaded, setDataLoaded] = useState(false);
  let [hideLoadMore, setHideLoadMore] = useState(true);
  let [reason, setReason] = useState("");

  let [sureClear, setSureClear] = useState(false);

  const handleImageError = (event) => {
    // Handle the error by setting a fallback image source
    event.target.src =
      "https://ik.imagekit.io/flowapps/profilePicturePlaceholder.svg";
  };

  useEffect(() => {
    if (dataLoaded && imagesLoaded === 17) {
      setNotifsLoading(false);
      // setTimeout(() => {
      setHideLoadMore(false);
      // }, 2000);
    }
  }, [dataLoaded, imagesLoaded]);

  let imgPreload = (
    <div className="imagesHidden">
      {/* Previously added images */}
      {/* ... */}
      {/* Additional images */}{" "}
      <img
        src={absenceNotif}
        alt="Bin 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={newDoc}
        alt="Bin 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={whiteBell}
        alt="Bin 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={hammer}
        alt="Bin 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={formNotif}
        alt="Bin 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={swap2navy}
        alt="Bin 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={notifRota}
        alt="Bin 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={notifShiftChange}
        alt="Bin 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={thickCross}
        alt="Bin 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={notifClock}
        alt="Bin 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whiteTick}
        alt="Bin 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={placeholder}
        alt="Bin 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={chat}
        alt="Bin 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={checkbox1}
        alt="Swap 2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={checkbox2}
        alt="Swap 2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whitecross}
        alt="Chat"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whitebin}
        alt="Note White"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );
  let [hoveredNotif, setHoveredNotif] = useState({ key: -1, id: "" });
  let [hoveredNotifBin, setHoveredNotifBin] = useState({ key: -1, id: "" });

  let [notifs, setNotifs] = useState([]);
  let [isMgr, setIsMgr] = useState(false);
  let [showUsr, setShowUsr] = useState(true);
  let [showMgr, setShowMgr] = useState(true);
  let [todayDs, setTodayDs] = useState(
    `${dateStringer
      .createStringFromTimestamp(new Date().getTime())
      .substr(0, 11)}H00M00`
  );
  let dateObj = new Date();
  let beginDateObj = new Date(dateObj.getTime());
  beginDateObj.setDate(beginDateObj.getDate() - 6);
  beginDateObj.setHours(0, 0, 0, 0);

  let [dsBegin, setDsBegin] = useState(
    dateStringer.createStringFromTimestamp(beginDateObj.getTime())
  );

  let [dsEnd, setDsEnd] = useState(
    dateStringer.createStringFromTimestamp(86400000 * 360 + dateObj.getTime())
  );
  useEffect(() => {
    let dateObj = new Date();
    dateObj.setHours(23, 59, 0, 0);
    setDsEnd(dateStringer.createStringFromTimestamp(dateObj.getTime()));
  }, [memoVals.showNotifications]);
  let [loadMore, setLoadMore] = useState(0);
  let [loadMoreLoading, setLoadMoreLoading] = useState(false);
  console.log("Notifs rendered: ", new Date());
  console.log({ dsBegin, dsEnd });

  let [emailNotVerified, setEmailNotVerified] = useState(false);

  useEffect(() => {
    if (dsBegin) {
      const cancelSource1 = axios.CancelToken.source();

      axios
        .post(
          `${serverURL}/get-notifications`,
          {
            nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
            dsBegin,
            dsEnd,
            loadMore,
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message === "no more notifs") {
            setDataLoaded(true);
            setIsMgr(response.data.isMgr);

            setNoMoreNotifs(true);
            if (response.data.needToVerifyEmail) {
              setEmailNotVerified(response.data.needToVerifyEmail);
            } else {
            }
          }
          if (response.data.message === "none found") {
            setDataLoaded(true);
            setIsMgr(response.data.isMgr);

            let dsOfLastItem = response.data.dsOfLastItem;

            let endObj = new Date(
              dateStringer.createTimestampFromString(dsOfLastItem)
            );
            endObj.setHours(endObj.getHours() + 6);

            let startObj = new Date(endObj.getTime());
            startObj.setDate(startObj.getDate() - 6);
            startObj.setHours(0, 0, 0, 0);
            setDsBegin(
              dateStringer.createStringFromTimestamp(startObj.getTime())
            );
            setDsEnd(dateStringer.createStringFromTimestamp(endObj.getTime()));

            setLoadMore(loadMore + 1);
          }
          if (response.data.message === "success") {
            setDataLoaded(true);
            setIsMgr(response.data.isMgr);
            if (response.data.needToVerifyEmail) {
              setEmailNotVerified(response.data.needToVerifyEmail);
            } else {
            }
            // response.data.notifs.forEach((notif) => {
            //   let newObj = notif;
            //   if (notif.jsx) {
            //     newObj["jsx"] = notif.jsx.replace(/\\/g, "");
            //   } else {
            //     arr.push(notif);
            //   }
            // });
            let arr = response.data.notifs.sort((a, b) => {
              return b.tsMade - a.tsMade;
            });

            let newArr = [...notifs];
            arr.forEach((x) => {
              newArr.push(x);
            });

            setNotifs(newArr);
            setTeamSwapsRequiringAction(response.data.teamSwapsRequiringAction);
            setUserSwapsRequiringAction(response.data.userSwapsRequiringAction);
            if (loadMoreLoading) {
              setLoadMoreLoading(false);
            }
            // setNotifs(
            //   response.data.notifs.sort((a, b) => {
            //     return b.tsMade - a.tsMade;
            //   })
            // );
          }
        })
        .catch((err) => {
          console.error(err);
        });

      return () => {
        cancelSource1.cancel("Component unmounted");
      };
    }
  }, [dsBegin, loadMore]);

  // master return

  console.log(
    countData.staffSwaps +
      countData.leaveReqs +
      countData.forms +
      countData.ttrs +
      countData.shiftEdits +
      countData.drops +
      countData.clocks +
      countData.timesheets +
      countData.loggedShifts +
      countData.declined +
      countData.selfLoggedAbsence
  );
  console.log({ isMgr });
  return (
    <div
      className={`notificationsBox noBorder ${notifsLoading ? "none" : ""} ${
        memoVals.mob ? "notificationsBoxMob" : "notificationsBoxDesktop"
      } `}
      onClick={(e) => {
        e.stopPropagation();
      }}
      style={modalPosition}
      ref={modalRef}
    >
      {memoVals.mobModal && (
        <div
          className="modalSwiper modalSwiperNotifs"
          onTouchStart={memoVals.mobModal ? handleDown : null}
          onTouchMove={memoVals.mobModal ? handleMove : null}
          onTouchEnd={memoVals.mobModal ? handleUp : null}
          onMouseDown={memoVals.mobModal ? handleDown : null}
          onMouseMove={memoVals.mobModal ? handleMove : null}
          onMouseUp={memoVals.mobModal ? handleUp : null}
        ></div>
      )}
      <div className={`notifHead `}>
        <div
          className={`mgrNotifKey ${memoVals.mob ? "mgrNotifKeyMob" : ""}`}
          onClick={(e) => {
            e.stopPropagation();
            // memoVals.setShowNotifications(x=>false);
          }}
        >
          {/* {memoVals.mob ? (
            <img
              src={whitecross}
              alt="Close"
              className={`closeUpcomingImg x314565342 ${
                memoVals.mob ? "x992910011" : ""
              }`}
              onClick={() => {
                memoVals.setShowNotifications((x) => false);
              }}
            />
          ) : (
            ""
          )}
          {memoVals.mob ? (
            <p className="notifMobTitleTxt">Notifications</p>
          ) : (
            ""
          )} */}
          <div
            className={`mgrNotifKeyTitle ${memoVals.mob ? "none_" : ""}`}
            onClick={() => {
              if (memoVals.mob) {
                memoVals.setShowNotifications((x) => false);
              }
            }}
          >
            <div
              className={`disFlexStart ${
                memoVals.mob ? "disFlexStartMob" : ""
              }`}
              onTouchStart={memoVals.mobModal ? handleDown : null}
              onTouchMove={memoVals.mobModal ? handleMove : null}
              onTouchEnd={memoVals.mobModal ? handleUp : null}
              onMouseDown={memoVals.mobModal ? handleDown : null}
              onMouseMove={memoVals.mobModal ? handleMove : null}
              onMouseUp={memoVals.mobModal ? handleUp : null}
              onClick={(e) => {
                e.stopPropagation();
                if (memoVals.mobModal) {
                  memoVals.setShowNotifications((x) => false);
                }
              }}
            >
              <img
                src={whitecross}
                alt="Close"
                className={`closeUpcomingImg x314565342 ${
                  memoVals.mob ? "x992910011" : ""
                }`}
                onClick={() => {
                  memoVals.setShowNotifications((x) => false);
                }}
              />{" "}
              <p className="notifTitleHeaderTxt">Notifications</p>
            </div>
            {/* {!memoVals.mob ? "Notifications" : ""} */}
          </div>
          {isMgr &&
          countData.staffSwaps +
            countData.leaveReqs +
            countData.forms +
            countData.ttrs +
            countData.shiftEdits +
            countData.drops +
            countData.clocks +
            countData.timesheets +
            countData.loggedShifts +
            countData.declined +
            countData.selfLoggedAbsence >
            0 ? (
            <div
              className="mgrNotifKeyRight"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div
                className="notifReqsBtn lilacColours_ zoomIn"
                onClick={(e) => {
                  e.stopPropagation();

                  memoVals.setShowNotifications((x) => false);
                  memoVals.setShowNotifReqs((x) => true);
                  console.log({
                    staffSwaps: countData.staffSwaps,
                    leaveReqs: countData.leaveReqs,
                    forms: countData.forms,
                    ttrs: countData.ttrs,
                    shiftEdits: countData.shiftEdits,
                    drops: countData.drops,
                    clocks: countData.clocks,
                    timesheets: countData.timesheets,
                    loggedShifts: countData.loggedShifts,
                    declined: countData.declined,
                    selfLoggedAbsence: countData.selfLoggedAbsence,
                  });
                }}
              >
                Staff requests{" "}
                <p className="staffReqQtyBlobNotif">
                  <img
                    src={whiteBell}
                    alt="Requests"
                    className="notifReqsBellImg"
                  />
                  {countData.staffSwaps +
                    countData.leaveReqs +
                    countData.forms +
                    countData.ttrs +
                    countData.shiftEdits +
                    countData.drops +
                    countData.clocks +
                    countData.timesheets +
                    countData.loggedShifts +
                    countData.declined +
                    countData.selfLoggedAbsence >
                  99
                    ? "99+"
                    : countData.staffSwaps +
                      countData.leaveReqs +
                      countData.forms +
                      countData.ttrs +
                      countData.shiftEdits +
                      countData.drops +
                      countData.clocks +
                      countData.timesheets +
                      countData.loggedShifts +
                      countData.declined +
                      countData.selfLoggedAbsence}
                </p>
                {/* {countData.staffSwaps +
                  countData.leaveReqs +
                  countData.forms +
                  countData.ttrs +
                  countData.shiftEdits +
                  countData.drops +
                  countData.clocks +
                  countData.timesheets +
                  countData.loggedShifts +
                  countData.declined +
                  countData.selfLoggedAbsence >
                99
                  ? "99+"
                  : countData.staffSwaps +
                    countData.leaveReqs +
                    countData.forms +
                    countData.ttrs +
                    countData.shiftEdits +
                    countData.drops +
                    countData.clocks +
                    countData.timesheets +
                    countData.loggedShifts +
                    countData.declined +
                    countData.selfLoggedAbsence}{" "}
                request
                {countData.staffSwaps +
                  countData.leaveReqs +
                  countData.forms +
                  countData.ttrs +
                  countData.shiftEdits +
                  countData.drops +
                  countData.clocks +
                  countData.timesheets +
                  countData.loggedShifts +
                  countData.declined +
                  countData.selfLoggedAbsence ===
                1
                  ? ""
                  : "s"} */}
                {/* <img
                  src={whiteBell}
                  alt="Requests"
                  className="notifReqsBellImg"
                /> */}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div
        className={`notifWrapper ${
          !memoVals.mob ? "notifWrapperDesktop" : "notifWrapperMob"
        } ${memoVals.device.vhIssue ? "vhIssueNotifWrapper" : ""} ${
          memoVals.device.ios ? "notifWrapperIos" : ""
        }`}
        ref={scrollRef}
      >
        {emailNotVerified ? (
          <div className="needToVerifNotifBox needToVeri">
            <div className="needToVerifDiv">
              <p className="notifVerifTxt">You need to verify your email</p>
              <p className="notifVerifTxtSub">{emailNotVerified}</p>
            </div>
            <p
              className="verifNotifBtn"
              onClick={() => {
                // memoVals.setShowNotifications(x=>false);
                axios
                  .post(
                    `${serverURL}/resend-verify-email`,
                    {
                      nowDs: dateStringer.createStringFromTimestamp(
                        new Date().getTime()
                      ),
                    },

                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .then((response) => {
                    if (response.data.message.includes("retry")) {
                      setVerifAlreadySent(true);
                    }
                    if (response.data.message === "success") {
                      // setIndicate({
                      //   show: true,
                      //   message: "Verification email sent to your email",
                      //   colour: "blue",
                      //   duration: 4000,
                      // });
                      setVerifSent(true);
                    }
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }}
            >
              Verify
            </p>
          </div>
        ) : (
          ""
        )}

        {/* {memoVals.checkUserState &&
            memoVals.checkUserState.initTodo &&
            memoVals.mob && (
              <div
                className={`initToDoBtnNav mobInitTodo`}
                onClick={() => {
                  memoVals.setShowChecklist((x) => true);
                  memoVals.setShowNotifications((x) => false);
                }}
              >
                <img src={todo} alt="Todo" className={`todoCheckListNAVMob`} />
                <div className="initToDoTxtMob">
                  <p>Set up checklist</p>
                  <div className="checkListSpan checkListSpanmob">
                    {memoVals.checkUserState.checklistDone
                      ? memoVals.checkUserState.checklistDone.filter((x) => {
                          return !JSON.stringify(x).includes(".");
                        }).length
                      : 0}{" "}
                    / 10{" "}
                    <div className="checkRotaBlobs">
                      <div
                        className={`checkBlobMob ${
                          memoVals.checkUserState.checklistDone &&
                          memoVals.checkUserState.checklistDone.filter((x) => {
                            return !x.includes(".");
                          }).length >= 1
                            ? "checkBlobDone"
                            : ""
                        }`}
                      ></div>
                      <div
                        className={`checkBlobMob ${
                          memoVals.checkUserState.checklistDone &&
                          memoVals.checkUserState.checklistDone.filter((x) => {
                            return !x.includes(".");
                          }).length >= 2
                            ? "checkBlobDone"
                            : ""
                        }`}
                      ></div>

                      <div
                        className={`checkBlobMob ${
                          memoVals.checkUserState.checklistDone &&
                          memoVals.checkUserState.checklistDone.filter((x) => {
                            return !x.includes(".");
                          }).length >= 3
                            ? "checkBlobDone"
                            : ""
                        }`}
                      ></div>

                      <div
                        className={`checkBlobMob ${
                          memoVals.checkUserState.checklistDone &&
                          memoVals.checkUserState.checklistDone.filter((x) => {
                            return !x.includes(".");
                          }).length >= 4
                            ? "checkBlobDone"
                            : ""
                        }`}
                      ></div>

                      <div
                        className={`checkBlobMob ${
                          memoVals.checkUserState.checklistDone &&
                          memoVals.checkUserState.checklistDone.filter((x) => {
                            return !x.includes(".");
                          }).length >= 5
                            ? "checkBlobDone"
                            : ""
                        }`}
                      ></div>

                      <div
                        className={`checkBlobMob ${
                          memoVals.checkUserState.checklistDone &&
                          memoVals.checkUserState.checklistDone.filter((x) => {
                            return !x.includes(".");
                          }).length >= 6
                            ? "checkBlobDone"
                            : ""
                        }`}
                      ></div>

                      <div
                        className={`checkBlobMob ${
                          memoVals.checkUserState.checklistDone &&
                          memoVals.checkUserState.checklistDone.filter((x) => {
                            return !x.includes(".");
                          }).length >= 7
                            ? "checkBlobDone"
                            : ""
                        }`}
                      ></div>

                      <div
                        className={`checkBlobMob ${
                          memoVals.checkUserState.checklistDone &&
                          memoVals.checkUserState.checklistDone.filter((x) => {
                            return !x.includes(".");
                          }).length >= 8
                            ? "checkBlobDone"
                            : ""
                        }`}
                      ></div>

                      <div
                        className={`checkBlobMob ${
                          memoVals.checkUserState.checklistDone &&
                          memoVals.checkUserState.checklistDone.filter((x) => {
                            return !x.includes(".");
                          }).length >= 9
                            ? "checkBlobDone"
                            : ""
                        }`}
                      ></div>

                      <div
                        className={`checkBlobMob ${
                          memoVals.checkUserState.checklistDone &&
                          memoVals.checkUserState.checklistDone.filter((x) => {
                            return !x.includes(".");
                          }).length >= 10
                            ? "checkBlobDone"
                            : ""
                        }`}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            )} */}
        {userSwapsRequiringAction > 0 && showUsr ? (
          <div
            className={`needToVerifNotifBox x892 ${
              memoVals.mob ? "x1091091a" : ""
            } navyColours_`}
          >
            <div className="swapNotifBlob x272721"></div>
            <div className="needToVerifDiv">
              <p
                className={`notifVerifTxt x29849283211 ${
                  !memoVals.mob ? "fontSize12Notif" : ""
                }`}
              >
                <span className="swapsQtySpanNotif">
                  {userSwapsRequiringAction} shift swap request
                  {userSwapsRequiringAction > 1 ? "s" : ""}
                </span>{" "}
                made with you by{" "}
                {userSwapsRequiringAction === 1
                  ? "another colleague"
                  : "other colleagues"}
                {/* {userSwapsRequiringAction === 1 ? "s a" : ""}  */}
              </p>
              {/* <p className="notifVerifTxtSub">{emailNotVerified}</p> */}
            </div>
            <p
              className={`verifNotifBtn blueColours x2984298291111 ${
                !memoVals.mob ? "aa1010" : "x2984298291111"
              } x373718111 ${!memoVals.mob ? "x828288111" : ""}`}
              onClick={() => {
                memoVals.setShowSwapsModal((x) => true);
                memoVals.setShowNotifications((x) => false);
              }}
            >
              View swaps{" "}
              <img
                src={swap2}
                alt="Swap notification"
                className="swapNotifImg x109100001"
              />
            </p>
          </div>
        ) : (
          ""
        )}
        {teamSwapsRequiringAction > 0 && showMgr ? (
          <div
            className={`needToVerifNotifBox x892 ${
              memoVals.mob ? "x1091091a" : ""
            }`}
          >
            <div className="swapNotifBlob"></div>
            <div className="needToVerifDiv">
              <p
                className={`notifVerifTxt x29849283211 ${
                  !memoVals.mob ? "fontSize12Notif" : ""
                }`}
              >
                <span className="swapsQtySpanNotif">
                  {teamSwapsRequiringAction} shift swap
                  {teamSwapsRequiringAction > 1 ? "s" : ""}
                </span>{" "}
                require
                {teamSwapsRequiringAction === 1 ? "s a" : ""} approval by a
                manager
              </p>
              {/* <p className="notifVerifTxtSub">{emailNotVerified}</p> */}
            </div>
            <p
              className={`verifNotifBtn x2984298291111 ${
                !memoVals.mob ? "aa1010" : ""
              }`}
              onClick={() => {
                memoVals.setShowTeamSwapsModal((x) => true);
                memoVals.setShowNotifications((x) => false);
              }}
            >
              View swaps{" "}
              <img
                src={swap2}
                alt="Swap notification"
                className="swapNotifImg x109100001"
              />
            </p>
          </div>
        ) : (
          ""
        )}

        {!notifs[0] && noMoreNotifs && !emailNotVerified ? (
          <p className="noNotifs">You have no notifications yet</p>
        ) : (
          notifs.map((notif, i) => {
            if (
              (showUsr && (notif.usr || !notif.mgr)) ||
              (showMgr && notif.mgr)
            ) {
              let linkable;
              if (
                notif.mgrReqID ||
                notif.reqID ||
                notif.shiftID ||
                notif.tilID ||
                notif.msgUserID ||
                notif.code.includes("3") || // swap request and mgr's accept/decline shifts
                notif.code === "4a" ||
                notif.code === "4b" ||
                notif.code === "4c" ||
                notif.code === "4d" ||
                notif.code === "5a" ||
                notif.code === "5b" ||
                notif.code === "5c" ||
                notif.code === "5d" ||
                notif.code === "5e" ||
                notif.code === "7c" ||
                notif.code === "7d" ||
                notif.code === "7e" ||
                notif.code === "8a" ||
                notif.code === "10a" ||
                notif.code === "10b" ||
                notif.code === "11a" ||
                notif.code === "11b" ||
                notif.code === "11c" ||
                notif.code === "36d" ||
                notif.code === "9d" ||
                notif.code === "9b" ||
                notif.code === "14a" ||
                notif.code === "14b" ||
                notif.code === "14c" ||
                notif.code === "14d" ||
                notif.code === "15a" ||
                notif.code === "40a" ||
                notif.code === "40b" ||
                notif.code === "40c" ||
                notif.code === "40d" ||
                notif.code === "40e" ||
                notif.code === "40f" ||
                notif.code === "40g" ||
                notif.code === "40h" ||
                notif.code === "40i" ||
                notif.code === "40j" ||
                notif.code.includes("39") ||
                notif.code === "50a" ||
                notif.code === "50b" ||
                notif.code === "56a" ||
                notif.code === "32a" ||
                notif.code === "59"
              ) {
                if (notif.code === "3c" || notif.code === "3e") {
                } else {
                  linkable = true;
                }
              }
              // if (
              //   notif.code === "3d" &&
              //   notif.jsx.includes("nothing from them")
              // ) {
              //   linkable = false;
              // }
              let status = "neutral"; // approved declined or neutral
              if (notif.code === "1a") {
                status = "approved";
              }

              if (notif.code === "1b") {
                status = "declined";
              }
              let clickNotif = () => {
                if (linkable) {
                  if (notif.code === "3f") {
                    memoVals.setShowSwapsModal((x) => true);
                  }
                  if (notif.code === "39a") {
                    // show clocked on
                    memoVals.setShowActiveClocks((x) => true);
                    memoVals.setShowNotifications((x) => false);
                  }
                  if (notif.code === "39b") {
                    // show clock card
                    memoVals.setClockOnID((x) => {
                      return { clockOnID: notif.clockOnID, isMgr: true };
                    });
                    memoVals.setShowNotifications((x) => false);
                  }
                  if (notif.code === "40a") {
                    memoVals.setShowTeamSwapsModal((x) => {
                      return { show: true, history: true };
                    });
                    memoVals.setShowNotifications((x) => false);
                  }

                  if (notif.code === "40b") {
                    memoVals.setShowMyShiftModal((x) => {
                      return {
                        unitType: notif.tilID ? "til" : "shift",
                        unitID: notif.shiftID ? notif.shiftID : notif.tilID,
                      };
                    });
                    memoVals.setShowNotifications((x) => false);
                  }
                  if (notif.code === "40c") {
                    if (notif.jsx.includes("nothing")) {
                      memoVals.setShowSwapsModal((x) => "hist");
                      memoVals.setShowNotifications((x) => false);
                    } else {
                      memoVals.setShowMyShiftModal((x) => {
                        return {
                          unitType: notif.tilID ? "til" : "shift",
                          unitID: notif.shiftID ? notif.shiftID : notif.tilID,
                        };
                      });
                      memoVals.setShowNotifications((x) => false);
                    }
                  }
                  if (notif.code === "40d") {
                    memoVals.setShowMyShiftModal((x) => {
                      return {
                        unitType: notif.tilID ? "til" : "shift",
                        unitID: notif.shiftID ? notif.shiftID : notif.tilID,
                      };
                    });
                    memoVals.setShowNotifications((x) => false);
                  }
                  if (notif.code === "40e") {
                    memoVals.setShowSwapsModal((x) => true);

                    memoVals.setShowNotifications((x) => false);
                  }
                  if (notif.code === "53") {
                    memoVals.setShowUserOpenShiftModal((x) => notif.openID);
                    memoVals.setShowNotifications((x) => false);
                  }
                  if (notif.code === "40f") {
                    memoVals.setShowTeamSwapsModal((x) => true);
                    memoVals.setShowNotifications((x) => false);
                  }
                  if (notif.code === "40g") {
                    memoVals.setShowSwapsModal((x) => true);

                    memoVals.setShowNotifications((x) => false);
                  }
                  if (
                    notif.code === "4a" ||
                    notif.code === "4b" ||
                    notif.code === "4c" ||
                    notif.code === "4d"
                  ) {
                    memoVals.setClockOnID((x) => {
                      return { clockOnID: notif.clockOnID, isMgr: false };
                    });
                    memoVals.setShowNotifications((x) => false);
                  }
                  if (notif.code === "59") {
                    memoVals.setShowNotifications((x) => false);
                    memoVals.setShowMyDocsModal((x) => true);
                  }
                  if (notif.code === "15a") {
                    memoVals.setShowReqStats((x) => "myStats");
                    console.log("show req stats");
                    memoVals.setShowNotifications((x) => false);
                  }
                  console.log(notif.code);
                  if (notif.code === "14b" || notif.code === "9b") {
                    memoVals.setClockOnID((x) => {
                      return {
                        clockOnID: notif.clockOnID,
                        isMgr: notif.code === "9b" ? false : notif.ackMgr,
                      };
                    });
                    memoVals.setShowNotifications((x) => false);
                  }

                  if (notif.code === "10a" || notif.code === "10b") {
                    memoVals.setShowForm((x) => {
                      return { isMgr: false, formID: notif.formID };
                    });
                    memoVals.setShowNotifications((x) => false);
                    // window.location.href = `${appURL}/absence?formID=${notif.formID}`;
                  }
                  if (
                    notif.code === "11a" ||
                    notif.code === "11b" ||
                    notif.code === "11c"
                  ) {
                    window.location.href = `${appURL}/timesheets?timesheetID=${notif.timesheetID}`;
                  }
                  if (notif.code === "37a") {
                    // open mgr timesheet
                    memoVals.setShowViewTimesheet((x) => {
                      return {
                        show: true,
                        timesheetID: notif.timesheetID,
                        isMgr: true,
                      };
                    });
                    memoVals.setShowNotifications((x) => false);
                  }
                  if (notif.code === "7c" || notif.code === "7e") {
                    // go to rota
                    window.location.href = `${appURL}/rota?monWeek=${notif.monWeek}&teamID=${notif.teamID}`;
                  }

                  if (notif.code === "34a") {
                    window.location.href = `${appURL}/staff-absence/forms?formID=${notif.formID}`;
                  }

                  if (notif.code === "35a") {
                    window.location.href = `${appURL}/team-rota?load=true&teamID=${notif.teamID}&monweek=${notif.monWeek}&showAvailabilityUserID=${notif.availUserID}`;
                  }

                  if (
                    notif.code === "5a" ||
                    notif.code === "5b" ||
                    notif.code === "5c" ||
                    notif.code === "5d" ||
                    notif.code === "5e"
                  ) {
                    //  shift here
                    memoVals.setShowMyShiftModal((x) => {
                      return {
                        unitType: "shift",
                        unitID: notif.shiftID,
                      };
                    });
                    memoVals.setShowNotifications((x) => false);
                  }

                  if (notif.code === "3d" && !notif.jsx.includes("completed")) {
                    if (
                      !notif.jsx.includes("nothing from them") &&
                      notif.sellersNewShiftID
                    ) {
                      axios
                        .post(
                          `${serverURL}/check-shift-til`,
                          {
                            shiftID: notif.sellersNewShiftID,
                            notifID: notif.notifID,
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message === "success") {
                            memoVals.setShowMyShiftModal((x) => {
                              return {
                                unitType: response.data.type,
                                unitID: notif.sellersNewShiftID,
                              };
                            });
                            memoVals.setShowNotifications((x) => false);
                          }
                          if (response.data.message === "nothing exists") {
                            memoVals.setShowMyShiftModal((x) => {
                              return {
                                unitType: "shiftID",
                                unitID: "1",
                              };
                            });
                            setNotifs(
                              notifs.filter((x) => {
                                return x.notifID !== notif.notifID;
                              })
                            );
                          }
                        });
                    } else {
                      memoVals.setShowSwapsModal((x) => true);
                      memoVals.setShowNotifications((x) => false);
                    }
                  }

                  if (notif.code === "50a") {
                    console.log({ notif });
                    memoVals.setShowEditShiftModal((x) => {
                      return {
                        itemID: notif.shiftID || notif.tilID,
                        itemType: notif.shiftID
                          ? "shift"
                          : notif.jsx && notif.jsx.includes("overtime")
                          ? "overtime"
                          : "til",

                        itemIsNew: false,
                        notes: false,
                      };
                    });

                    memoVals.setShowNotifications((x) => false);
                  }
                  if (notif.code === "3d" && notif.jsx.includes("completed")) {
                    if (notif.wantedItemID) {
                      axios
                        .post(
                          `${serverURL}/check-shift-til`,
                          {
                            shiftID: notif.wantedItemID,
                            notifID: notif.notifID,
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message === "success") {
                            memoVals.setShowMyShiftModal((x) => {
                              return {
                                unitType: response.data.type,
                                unitID: notif.sellersNewShiftID,
                              };
                            });
                            memoVals.setShowNotifications((x) => false);
                          }
                          if (response.data.message === "nothing exists") {
                            memoVals.setShowMyShiftModal((x) => {
                              return {
                                unitType: "shiftID",
                                unitID: "1",
                              };
                            });
                            setNotifs(
                              notifs.filter((x) => {
                                return x.notifID !== notif.notifID;
                              })
                            );
                          }
                        });
                    } else {
                      memoVals.setShowSwapsModal((x) => true);
                      memoVals.setShowNotifications((x) => false);
                    }
                  }
                  if (notif.code === "3b") {
                    if (notif.wantedItemID) {
                      axios
                        .post(
                          `${serverURL}/check-shift-til`,
                          {
                            shiftID: notif.wantedItemID,
                            notifID: notif.notifID,
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message === "success") {
                            memoVals.setShowMyShiftModal((x) => {
                              return {
                                unitType: response.data.type,
                                unitID: notif.wantedItemID,
                                otherUser: !notif.jsx.includes("been made"),
                              };
                            });
                            memoVals.setShowNotifications((x) => false);
                          }
                          if (response.data.message === "nothing exists") {
                            memoVals.setShowMyShiftModal((x) => {
                              return {
                                unitType: "shiftID",
                                unitID: "1",
                              };
                            });
                            setNotifs(
                              notifs.filter((x) => {
                                return x.notifID !== notif.notifID;
                              })
                            );
                          }
                        });
                    } else {
                      memoVals.setShowSwapsModal((x) => true);
                      memoVals.setShowNotifications((x) => false);
                    }
                  }
                  if (
                    notif.code === "3d" &&
                    notif.jsx.includes("nothing from you") &&
                    !notif.jsx.includes("completed")
                  ) {
                    axios
                      .post(
                        `${serverURL}/check-shift-til`,
                        {
                          shiftID: notif.wantedItemID,
                          notifID: notif.notifID,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          memoVals.setShowMyShiftModal((x) => {
                            return {
                              unitType: response.data.type,
                              unitID: notif.wantedItemID,
                            };
                          });
                          memoVals.setShowNotifications((x) => false);
                        }
                        if (response.data.message === "nothing exists") {
                          memoVals.setShowMyShiftModal((x) => {
                            return {
                              unitType: "shiftID",
                              unitID: "1",
                            };
                          });
                          setNotifs(
                            notifs.filter((x) => {
                              return x.notifID !== notif.notifID;
                            })
                          );
                        }
                      });
                  }

                  if (notif.code === "8a") {
                    // open user availablilty modal
                    // notif.teamID and notif.monWeek
                    memoVals.setShowAddAvailModal((x) => {
                      return {
                        monWeek: notif.monWeek,
                        loadTeamID: notif.teamID,
                      };
                    });
                    memoVals.setShowNotifications((x) => false);
                  }
                  if (notif.code === "3a") {
                    // window.location.href = `${appURL}/rota?swaps=true`;
                    memoVals.setShowSwapsModal((x) => true);
                    memoVals.setShowNotifications((x) => false);
                  }
                  if (notif.jsx.includes("However") && notif.code !== "33b") {
                    memoVals.setShowNotifications((x) => false);
                    memoVals.setShowSwapsModal((x) => true);
                  }

                  if (notif.msgUserID) {
                    memoVals.setShowMessages((x) => notif.msgUserID);
                    memoVals.setShowNotifications((x) => false);
                  }
                  if (notif.reqID && !notif.mgr) {
                    memoVals.setShowUserReqFromNav((x) => notif.reqID);

                    memoVals.setShowNotifications((x) => false);
                  }

                  if (notif.reqID && notif.mgr) {
                    memoVals.setShowReqMgrModal((x) => notif.reqID);

                    memoVals.setShowNotifications((x) => false);
                  }

                  if (
                    notif.code === "57a" ||
                    notif.code === "57b" ||
                    notif.code === "57c" ||
                    notif.code === "57d"
                  ) {
                    memoVals.setShowMyShiftModal((x) => {
                      return {
                        unitType: notif.jsx.includes("shift")
                          ? "shift"
                          : notif.jsx.includes("overtime")
                          ? "overtime"
                          : "til",
                        unitID: notif.shiftID,
                        otherUser:
                          notif.code === "57a" ||
                          notif.code === "57d" ||
                          notif.code === "57e",
                      };
                    });
                    memoVals.setShowNotifications((x) => false);
                  }

                  if (
                    notif.code !== "36a" &&
                    notif.code !== "38a" &&
                    !notif.code.includes("40") &&
                    !notif.code.includes("50") &&
                    notif.code !== "57a" &&
                    notif.code !== "57b" &&
                    notif.code !== "57c" &&
                    notif.code !== "56a" &&
                    notif.code !== "57d"
                  ) {
                    if (notif.shiftID && notif.mgr) {
                      memoVals.setItemID((x) => notif.shiftID),
                        memoVals.setItemType((x) => "shift"),
                        memoVals.setShowEditShiftModal((x) => true);
                      memoVals.setShowNotifications((x) => false);
                    }

                    if (notif.shiftID && !notif.mgr) {
                      memoVals.setShowMyShiftModal((x) => {
                        return {
                          unitType: "shift",
                          unitID: notif.shiftID,
                          otherUser: notif.code === "7g",
                        };
                      });
                      memoVals.setShowNotifications((x) => false);
                    }
                    if (notif.tilID && notif.mgr) {
                      console.log(
                        { tilID: notif.tilID },
                        {
                          type: notif.jsx.includes("overtime")
                            ? "overtime"
                            : "til",
                        }
                      );
                      memoVals.setItemID((x) => notif.tilID),
                        memoVals.setItemType((x) =>
                          notif.jsx.includes("overtime") ? "overtime" : "til"
                        ),
                        memoVals.setShowEditShiftModal((x) => true);
                      memoVals.setShowNotifications((x) => false);
                    }
                    if (notif.tilID && !notif.mgr) {
                      memoVals.setShowMyShiftModal((x) => {
                        return {
                          unitType: notif.jsx.includes("overtime")
                            ? "overtime"
                            : "til",
                          unitID: notif.tilID,
                          otherUser: notif.code === "7g",
                        };
                      });
                      memoVals.setShowNotifications((x) => false);
                      memoVals.setUnreadNotifs((x) => 0);
                    }
                  }

                  if (
                    notif.code === "36a" ||
                    notif.code === "38a" ||
                    notif.code === "31b" ||
                    notif.code === "32a" ||
                    notif.code === "32b"
                  ) {
                    // memoVals.setItemID((x) => notif.shiftID || notif.tilID),
                    // memoVals.setItemType((x) =>
                    //   notif.shiftID ? "shift" : "overtime"
                    // ),
                    // memoVals.setShowEditShiftModal((x) => true);
                    memoVals.setShowEditShiftModal((x) => {
                      return {
                        itemID: notif.shiftID || notif.tilID,
                        itemType: notif.shiftID
                          ? "shift"
                          : notif.jsx && notif.jsx.includes("overtime")
                          ? "overtime"
                          : "til",
                        // itemFName: dateStringer.cleanParam(params.fName),
                        // itemLName: dateStringer.cleanParam(params.lName),
                        itemIsNew: false,
                        notes: false,
                        // userID: dateStringer.cleanParam(params.userID),
                      };
                    });
                    memoVals.setShowNotifications((x) => false);
                  }

                  if (notif.code === "36b") {
                    //       "`https://app.flowrota.com/team-rota?monWeek=${dateStringer.getMonWeekOfDs(
                    //   dataObj.startDs
                    // )}&teamID=${dataObj.teamID}&openID=${dataObj.openID}`"
                    // open a mgr open modal
                    memoVals.setShowOpenShiftModal((x) => {
                      return {
                        ds: notif.startDs,
                        openID: notif.openID,
                        new: false,
                        teamID: notif.teamID,
                      };
                    });
                    memoVals.setShowNotifications((x) => false);
                  }

                  if (notif.code === "31a" && notif.editID) {
                    // go to staff shift change requests
                    window.location.href = `${appURL}/team-hours?requests=true&editID=${notif.editID}`;
                  }

                  if (notif.code === "31c") {
                    memoVals.setShowMgrAcceptanceModal((x) => true);
                    memoVals.setShowNotifications((x) => false);
                  }

                  if (notif.code === "33a" || notif.code === "33c") {
                    // launch team swaps
                    memoVals.setShowTeamSwapsModal((x) => true);
                    memoVals.setShowNotifications((x) => false);
                  }

                  if (notif.code === "33b") {
                    // launch team swaps
                    memoVals.setShowTeamSwapsModal((x) => {
                      return { show: true, history: true };
                    });
                    memoVals.setShowNotifications((x) => false);
                  }

                  if (notif.code === "36c") {
                    memoVals.setClockOnID((x) => {
                      return {
                        clockOnID: notif.clockOnID,
                        isMgr: true,
                      };
                    });
                    memoVals.setShowNotifications((x) => false);
                  }

                  if (notif.code === "36d") {
                    memoVals.setShowDayCommentModal((x) => {
                      return {
                        show: true,
                        isMgr: true,
                        teamID: notif.teamID,
                        ds: notif.startDs,
                      };
                    });
                    memoVals.setShowNotifications((x) => false);
                  }

                  if (notif.code === "9d") {
                    memoVals.setShowDayCommentModal((x) => {
                      return {
                        show: true,
                        isMgr: false,
                        teamID: notif.teamID,
                        ds: notif.startDs,
                      };
                    });
                    memoVals.setShowNotifications((x) => false);
                  }

                  if (notif.code === "14a") {
                    memoVals.setShowDayCommentModal((x) => {
                      return {
                        show: true,
                        isMgr: false,
                        teamID: notif.teamID,
                        ds: notif.startDs,
                      };
                    });
                    memoVals.setShowNotifications((x) => false);
                  }

                  if (notif.code === "56a") {
                    console.log("launch open shift modal here");
                    if (notif.shiftID || notif.tilID) {
                      // shiftID or tilID present if won
                      memoVals.setShowEditShiftModal((x) => {
                        return {
                          itemID: notif.shiftID || notif.tilID,
                          itemType: notif.shiftID
                            ? "shift"
                            : notif.jsx && notif.jsx.includes("overtime")
                            ? "overtime"
                            : "til",

                          itemIsNew: false,
                          notes: false,
                        };
                      });
                    } else {
                      // show open
                      // gigging
                      memoVals.setShowOpenShiftModal((x) => {
                        return {
                          // ds: notif.ds,
                          openID: notif.openID,
                          // new: false,
                          // teamID: notif.teamID,
                        };
                      });
                    }

                    memoVals.setShowNotifications((x) => false);
                  }
                }
              };

              let clockedDayStrip = "";
              if (notif.clockedDs) {
                if (notif.code === "39a" || notif.code === "39b") {
                  let clockedOnTs = new Date(
                    dateStringer.createTimestampFromString(notif.clockedDs)
                  );

                  if (clockedOnTs >= today00Ts) {
                    clockedDayStrip = "today";
                  } else if (
                    clockedOnTs < today00Ts &&
                    clockedOnTs >= yesterday00Ts
                  ) {
                    clockedDayStrip = "yesterday";
                  } else {
                    return `on ${dateStringer.printedDateFromDs(
                      notif.clockedDs
                    )}`;
                  }
                }
              }
              return (
                <div
                  className={`notifItem ${
                    loadMoreLoading ? "disableNotif22" : ""
                  } ${notif.mgr ? "notifIsMgr" : ""} ${
                    memoVals.mob ? "" : "zoomNotifIn"
                  } ${
                    linkable && !memoVals.mob
                      ? `notifLinkable ${notif.mgr ? "notifLinkableMgr" : ""} ${
                          hoveredNotif.id === notif.notifID ||
                          hoveredNotif.id === notif.amendID
                            ? "hoveredNotifHovered"
                            : ""
                        }`
                      : ""
                  }  ${
                    notif.read
                      ? "notifIsRead"
                      : `x889988 ${notif.mgr ? `unReadNotifMgr` : ""}`
                  }`}
                  // onMouseEnter={() => {
                  //   console.log({ code: notif.code });
                  //   if (!memoVals.mob) {
                  //     setHoveredNotifBin({
                  //       id: notif.notifID || notif.amendID,
                  //       key: i,
                  //     });
                  //   }
                  // }}
                  // onMouseLeave={() => {
                  //   if (!memoVals.mob) {
                  //     setHoveredNotifBin({ id: "", key: -1 });
                  //   }
                  // }}
                  onClick={() => {
                    if (memoVals.mob) {
                      clickNotif();
                    }
                  }}
                >
                  <img
                    src={notif.picUrl || placeholder}
                    alt="Notification"
                    className={`notifImg ${notif.mgr ? "notifImgMgr" : ""}`}
                    onError={handleImageError}
                    onClick={() => {
                      clickNotif();
                    }}
                    onMouseEnter={() => {
                      if (!memoVals.mob) {
                        setHoveredNotif({
                          id: notif.notifID || notif.amendID,
                          key: i,
                        });
                      }
                    }}
                    onMouseLeave={() => {
                      if (!memoVals.mob) {
                        setHoveredNotif({ id: "", key: -1 });
                      }
                    }}
                  />

                  <div
                    className={`notifMid ${
                      memoVals.mob && window.innerWidth > 450 ? "notifMid1" : ""
                    } ${
                      memoVals.mob && window.innerWidth > 520 ? "notifMid2" : ""
                    } `}
                    onMouseEnter={() => {
                      console.log("notif:", notif);
                      if (!memoVals.mob) {
                        setHoveredNotif({
                          id: notif.notifID || notif.amendID,
                          key: i,
                        });
                      }
                    }}
                    onMouseLeave={() => {
                      if (!memoVals.mob) {
                        setHoveredNotif({ id: "", key: -1 });
                      }
                    }}
                    onClick={() => {
                      clickNotif();
                    }}
                  >
                    {notif.code === "4c" ||
                    notif.code === "4d" ||
                    notif.code === "39a" ||
                    notif.code === "39b" ? (
                      <img
                        src={notifClock}
                        alt="Swap notification"
                        className="swapNotifImg"
                      />
                    ) : notif.code === "7c" ? (
                      <img
                        src={notifRota}
                        alt="Swap notification"
                        className="swapNotifImg"
                      />
                    ) : notif.code === "1f" ||
                      notif.code === "1g" ||
                      notif.code === "1h" ? (
                      <img
                        src={absenceNotif}
                        alt="Swap notification"
                        className="swapNotifImg"
                      />
                    ) : notif.code === "1d" ||
                      notif.code === "31c" ||
                      notif.code === "1b" ||
                      notif.code === "6b" ||
                      notif.code === "11b" ||
                      notif.code === "38a" ||
                      notif.code === "4b" ? (
                      <img
                        src={thickCross}
                        alt="Swap notification"
                        className="swapNotifImg"
                      />
                    ) : notif.code === "1a" ||
                      notif.code === "5a" ||
                      notif.code === "6a" ||
                      notif.code === "11a" ||
                      notif.code === "13a" ||
                      notif.code === "10b" ||
                      notif.code === "31b" ||
                      notif.code === "57b" ||
                      notif.code === "51a" ||
                      notif.code === "4a" ? (
                      <img
                        src={whiteTick}
                        alt="Swap notification"
                        className="swapNotifImg"
                      />
                    ) : notif.code === "7d" ? (
                      <img
                        src={plusWhite}
                        alt="Swap notification"
                        className="swapNotifImg"
                      />
                    ) : notif.code === "2a" ? (
                      <img
                        src={chat}
                        alt="Swap notification"
                        className="swapNotifImg"
                      />
                    ) : notif.code === "2a" ? (
                      <img
                        src={chat}
                        alt="Swap notification"
                        className="swapNotifImg"
                      />
                    ) : notif.code === "56a" ? (
                      <img
                        src={hammer}
                        alt="Swap notification"
                        className="swapNotifImg"
                      />
                    ) : notif.code === "7f" ? (
                      <img
                        src={whiteTick}
                        alt="Swap notification"
                        className="swapNotifImg"
                      />
                    ) : notif.code === "59" ? (
                      <img
                        src={newDoc}
                        alt="Swap notification"
                        className="swapNotifImg newDocNotifImg"
                      />
                    ) : (
                      <img
                        src={notifShiftChange}
                        alt="Swap notification"
                        className="swapNotifImg"
                      />
                    )}

                    <div className="notifMidDiv">
                      {notif.code === "39b" ? (
                        <p
                          key={notif.notifID}
                          className={`notifTxt ${
                            memoVals.mobModal ? "notifTxtMob" : ""
                          }`}
                        >
                          <span className="notifBold">
                            {notif.clockedFName} {notif.clockedLName}
                          </span>{" "}
                          clocked off at{" "}
                          <span className="notifBold">
                            {dateStringer.dsToTimeStrip(notif.clockedDs)}
                          </span>{" "}
                          in <span className="notifBold">{notif.teamName}</span>{" "}
                          {clockedDayStrip}
                        </p>
                      ) : notif.code === "39a" ? (
                        <p
                          key={notif.notifID}
                          className={`notifTxt ${
                            memoVals.mobModal ? "notifTxtMob" : ""
                          }`}
                        >
                          <span className="notifBold">
                            {notif.clockedFName} {notif.clockedLName}
                          </span>{" "}
                          clocked on at{" "}
                          <span className="notifBold">
                            {dateStringer.dsToTimeStrip(notif.clockedDs)}
                          </span>{" "}
                          in <span className="notifBold">{notif.teamName}</span>{" "}
                          {clockedDayStrip}
                        </p>
                      ) : (
                        <p
                          key={notif.notifID}
                          className={`notifTxt ${
                            memoVals.mobModal ? "notifTxtMob" : ""
                          }`}
                          dangerouslySetInnerHTML={{
                            __html: dateStringer
                              .sanitize(notif.jsx)
                              .replace(/classname/g, "class"),
                          }}
                        ></p>
                      )}
                      {/* {notif.code}{" "} */}
                      {notif.jsx2 &&
                      (notif.code === "7d" ||
                        notif.code === "57b" ||
                        notif.code === "57c" ||
                        notif.code === "57d") ? (
                        <p
                          key={notif.jsx2}
                          className={`notifTxt notifTxtJsx2 ${
                            memoVals.mobModal ? "notifTxtMob" : ""
                          }`}
                          dangerouslySetInnerHTML={{
                            __html: dateStringer
                              .sanitize(notif.jsx2)
                              .replace(/classname/g, "class"),
                          }}
                        ></p>
                      ) : (
                        ""
                      )}
                    </div>
                    {notif.subData && notif.subData.startDs ? (
                      <div className="notifSubData">
                        <div className="notifSubTxt">
                          <p className="notifSubTxtBubble">
                            {dateStringer.dsToTimeStrip(notif.subData.startDs)}{" "}
                            - {dateStringer.dsToTimeStrip(notif.subData.endDs)}
                          </p>
                          {/* {"   "}-{"   "}
                        <p className="notifSubTxtBubble">
                          {dateStringer.dsToTimeStrip(notif.subData.endDs)}
                        </p> */}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  {hoveredNotifBin.id === (notif.notifID || notif.amendID) &&
                  hoveredNotifBin.key === i ? (
                    <img
                      src={whitebin}
                      alt="Remove"
                      className={`deleteNotifImg x883811ex ${
                        !memoVals.mob
                          ? `deleteNotifImgDesktop ${
                              notif.mgr ? "mgrNotifDeleteHover" : ""
                            } deleteNotifImgMob`
                          : ""
                      }`}
                      onClick={(e) => {
                        e.stopPropagation();
                        axios
                          .post(
                            `${serverURL}/remove-notif`,
                            {
                              notifID: notif.notifID,
                              amendID: notif.amendID,
                              type: notif.type,
                            },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              let newArr = [];
                              notifs.forEach((n) => {
                                let add = true;
                                if (n.notifID && n.notifID === notif.notifID) {
                                  add = false;
                                }

                                if (add) {
                                  newArr.push(n);
                                }
                              });
                              setNotifs(newArr);
                            }
                          });
                      }}
                    />
                  ) : (
                    <p className="notifStatus">
                      {`${notif.dsMade.substr(0, 11)}H00M00` === todayDs
                        ? dateStringer.dsToTimeStrip(notif.dsMade)
                        : dateStringer.printedDateFromDs(notif.dsMade)}
                      {memoVals.mob ? <br /> : ""}
                      {memoVals.mob ? (
                        <img
                          src={whitebin}
                          alt="Remove"
                          className={`deleteNotifImg ${
                            !memoVals.mob
                              ? "deleteNotifImgDesktop"
                              : "deleteNotifImgMob"
                          }`}
                          onClick={(e) => {
                            e.stopPropagation();
                            axios
                              .post(
                                `${serverURL}/remove-notif`,
                                {
                                  notifID: notif.notifID,
                                  amendID: notif.amendID,
                                  type: notif.type,
                                },

                                {
                                  withCredentials: true,
                                  credentials: "include",
                                }
                              )
                              .then((response) => {
                                if (response.data.message === "success") {
                                  let newArr = [];
                                  notifs.forEach((n) => {
                                    let add = true;
                                    if (n.notifID === notif.notifID) {
                                      add = false;
                                    }

                                    if (add) {
                                      newArr.push(n);
                                    }
                                  });
                                  setNotifs(newArr);
                                }
                              });
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </p>
                  )}
                  <div
                    className={`notifBlob ${
                      (notif.jsx.includes("approved your") &&
                        !notif.jsx.includes("not yet")) ||
                      notif.code === "4a" ||
                      notif.code === "5a" ||
                      notif.code === "7f" ||
                      notif.code === "11a" ||
                      notif.code === "31b" ||
                      (notif.code === "33b" && !notif.jsx.includes("owever")) ||
                      notif.code === "13a" ||
                      notif.code === "40a" ||
                      notif.code === "40b" ||
                      notif.code === "40c" ||
                      notif.code === "40d" ||
                      notif.code === "39a" ||
                      notif.code === "39b"
                        ? "notifBlobApproved"
                        : notif.code === "3c" ||
                          notif.code === "3e" ||
                          notif.code === "3f" ||
                          notif.code === "4b" ||
                          notif.code === "5b" ||
                          notif.code === "7g" ||
                          notif.code === "7h" ||
                          notif.code === "11b" ||
                          notif.code === "31c" ||
                          notif.code === "33c"
                        ? "notifBlobDeclined"
                        : status === "approved" ||
                          notif.code === "5a" ||
                          notif.code === "5c"
                        ? "notifBlobApproved"
                        : status === "declined"
                        ? "notifBlobDeclined"
                        : ""
                    }`}
                  ></div>
                </div>
              );
            }
          })
        )}
        {showMgr || showUsr ? (
          <div className="notifBottomBar">
            <p className="x920901ss"> &nbsp;</p>
            {!noMoreNotifs && !notifsLoading ? (
              <div
                className={`loadMoreNotifsHolder ${
                  hideLoadMore ? "invis" : ""
                }`}
              >
                <p
                  className={`loadUpcomingMoreBtn x298497113 ${
                    !memoVals.mobModal ? "x92499101042" : ""
                  }`}
                  onClick={() => {
                    setLoadMoreLoading(true);
                    let beginDsObject = new Date(
                      dateStringer.createTimestampFromString(dsBegin)
                    );
                    beginDsObject.setDate(beginDsObject.getDate() - 6);
                    beginDsObject.setHours(0, 0, 0, 0);

                    let endDsObject = new Date(
                      dateStringer.createTimestampFromString(dsEnd)
                    );
                    endDsObject.setDate(endDsObject.getDate() - 6);
                    endDsObject.setHours(0, 0, 0, 0);
                    endDsObject.setMinutes(endDsObject.getMinutes() - 1);

                    setDsBegin(
                      dateStringer.createStringFromTimestamp(
                        beginDsObject.getTime()
                      )
                    );
                    setDsEnd(
                      dateStringer.createStringFromTimestamp(
                        endDsObject.getTime()
                      )
                    );
                    setLoadMore(loadMore + 1);
                  }}
                >
                  Load more
                </p>
              </div>
            ) : (
              <p className="x920901ss"> &nbsp;</p>
            )}

            {notifs && notifs[0] ? (
              <div className="loadMoreNotifsHolder">
                <p
                  className="clearNotifs"
                  onClick={() => {
                    setSureClear(true);
                  }}
                >
                  Clear all
                </p>
              </div>
            ) : (
              <p className="x920901ss"> &nbsp;</p>
            )}
          </div>
        ) : (
          ""
        )}

        <br />
      </div>
      {memoVals.mob ? (
        <div className="notifFooter">
          <div className="mgrNotifKeyRight">
            {/* <div
              className="mgrNotifKeyTxt mobMgrNotifKey"
              onClick={() => {
                setShowUsr(!showUsr);
           
              }}
            >
              <p className="notifKeyAsTxt">As an</p>
              <p className="notifKeyDiv">
                Employee{" "}
                <img
                  className="mgrNotifkeyImg mgrNotifkeyImgMob"
                  src={showUsr ? checkbox2 : checkbox1}
                />
              </p>

          
            </div>{" "}
            <div
              className="mgrNotifKeyTxt mobMgrNotifKey"
              onClick={() => {
                setShowMgr(!showMgr);
           
              }}
            >
              <p className="notifKeyAsTxt">As a</p>
              <p className="notifKeyDiv asAMgr">
                Manager
                <img
                  className="mgrNotifkeyImg mgrNotifkeyImgMob"
                  src={showMgr ? checkbox2 : checkbox1}
                />{" "}
              </p>
            </div>{" "}
          */}
          </div>
          <p
            className={`myShiftModalFooterRight x13984927123 `}
            onClick={() => {
              memoVals.setShowNotifications((x) => false);
            }}
          >
            Close
          </p>
        </div>
      ) : (
        ""
      )}
      {imgPreload}
      {sureClear ? (
        <div
          className="areYouSureModalUnderlay"
          onClick={() => {
            setSureClear(false);
          }}
        >
          <div
            className="formCreatedModal"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <p className="overlapsRenTxt">
              Are you sure you want to clear all of your notifications?
              <br />
              <br />
              You will not be able to get them back.
            </p>
            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  setSureClear(false);

                  axios
                    .post(
                      `${serverURL}/clear-notifications`,
                      {
                        someData: 1,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        setNotifs([]);
                      }
                    })
                    .catch((e) => {
                      console.log(e);
                    });
                }}
              >
                Yes
              </p>
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setSureClear(false);
                }}
              >
                No
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {verifSent ? (
        <div className="addAbsenceModalUnderlay">
          <div className="formCreatedModal">
            <p className="overlapsRenTxt">
              A verification email has been sent to your email address.
              <br />
              <br />
              Please check your emails using the same device as the one you are
              using now, or on a device where you are logged in and follow the
              instructions. <br />
              <br />
              Check your junk folder if an email doesn't arrive within a few
              minutes.
            </p>

            <div className="areYouModalBtnsDiv">
              <p
                className="closeSwapsNoLongerBtn"
                onClick={() => {
                  setVerifSent(false);
                  memoVals.setShowNotifications((x) => false);
                }}
              >
                Understood
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {verifAlreadySent ? (
        <div className="addAbsenceModalUnderlay">
          <div className="formCreatedModal">
            <p className="overlapsRenTxt">
              A verification email has already been sent to you.
              <br />
              <br />
              Please wait a few moments before trying again. <br />
              <br />
              Check your junk folder if an email doesn't arrive within a few
              minutes.
            </p>

            <div className="areYouModalBtnsDiv">
              <p
                className="closeSwapsNoLongerBtn"
                onClick={() => {
                  setVerifAlreadySent(false);
                }}
              >
                Understood
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Notifications;

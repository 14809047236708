import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useMemo,
  useReducer,
  Fragment,
} from "react";
// cleaned wef 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios done
import axios from "axios";
import "../../styles/leaveRequests.css";
// import EditReqDatesModal from "./EditReqDatesModal";
import ViewTeamOnRequest from "./ViewTeamOnRequest";

import dateStringer from "../../tools/dateStringer";
import serverURL from "../../serverURL";

// import CheckUser from "../../tools/CheckUser";

import { DataContext } from "../../contexts/DataContext";
import { UserContext } from "../../contexts/UserContext";
import { RequestContext } from "../../contexts/RequestContext";
import { CalendarContext } from "../../contexts/CalendarContext";
import { StyleContext } from "../../contexts/StyleContext";

import horizontalBalls from "../../img/loaders/horizontalBalls.svg";

import reqLoader from "../../img/general/reqLoader2.gif";
import whiteHelp from "../../img/general/whiteHelp.svg";
import redTriangle from "../../img/general/redTriangle.svg";
import checkboxInstall1 from "../../img/general/checkboxInstall1.svg";
import checkboxInstall2 from "../../img/general/checkboxInstall2.svg";
import crossWhite from "../../img/general/crossWhite.svg";
import whiteTick from "../../img/general/whiteTick.svg";
import unitTick from "../../img/general/unitTick.svg";
import unitCross from "../../img/general/unitCross.svg";
import editNavy from "../../img/general/editNavy.svg";
import returnArrow from "../../img/general/returnArrow.svg";
import whitecross from "../../img/general/whitecross.svg";
import close from "../../img/modals/close.svg";
import absence from "../../img/general/absence.svg";
import profilePicturePlaceholder from "../../img/general/profilePicturePlaceholder.svg";
import noteWhite from "../../img/general/noteWhite.svg";
import whiteGoBack from "../../img/general/whiteGoBack.svg";
import whiteGo from "../../img/general/whiteGo.svg";

const LeaveReqModal = ({ unitReqID, unitIsUserReq, loadedFromTeamViewer }) => {
  const {
    leaveTypeArr,
    setIndicate,
    openManagerShiftModal,
    setShowReqModalInViewer,
    showEditUser,
    setShowReqMgrModal,
    setShowEditUser,
    setOutstandingReqsQty,
    showAddLeaveModal,
    setShowAddLeaveModal,
    setDeletingReqID,
    reqIDToLoadWhenClosingEditUser,
    setReqIDToLoadWhenClosingEditUser,
    setNoPermissions,
    updateTabData,
    setUpdateTabData,
    modalOpen,
    setModalOpen,
    setShowUserReqFromNav,
    device,
    countData,
    setCountData,
  } = useContext(DataContext);
  const { checkUserState, currencySymbol } = useContext(UserContext);
  const { mobModal } = useContext(StyleContext);
  const {
    showViewTeamOnRequest,
    setShowViewTeamOnRequest,
    showEditDateModal,
    updateReq,
    setUpdateReq,
  } = useContext(RequestContext);
  const { setUpdatedItem, setUpdateInfo, updateInfo } =
    useContext(CalendarContext);

  const memoVals = useMemo(
    () => ({
      leaveTypeArr,
      setIndicate,
      openManagerShiftModal,
      setShowReqModalInViewer,
      showEditUser,
      setShowReqMgrModal,
      setShowEditUser,
      setOutstandingReqsQty,
      showAddLeaveModal,
      setShowAddLeaveModal,
      setDeletingReqID,
      reqIDToLoadWhenClosingEditUser,
      setReqIDToLoadWhenClosingEditUser,
      setNoPermissions,
      updateTabData,
      setUpdateTabData,
      checkUserState,
      currencySymbol,
      mobModal,
      showViewTeamOnRequest,
      setShowViewTeamOnRequest,
      showEditDateModal,
      updateReq,
      setUpdateReq,
      setUpdatedItem,
      setUpdateInfo,
      updateInfo,
      modalOpen,
      setModalOpen,
      setShowUserReqFromNav,
      device,
    }),
    [
      leaveTypeArr, //
      setIndicate, //
      openManagerShiftModal, //
      setShowReqModalInViewer, //
      showEditUser, //
      setShowReqMgrModal, //
      setShowEditUser, //
      setOutstandingReqsQty, //
      showAddLeaveModal, //
      setShowAddLeaveModal, //
      setDeletingReqID, //
      reqIDToLoadWhenClosingEditUser, //
      setReqIDToLoadWhenClosingEditUser, //
      setNoPermissions, //
      updateTabData, //
      setUpdateTabData, //
      checkUserState, //
      currencySymbol, //
      mobModal, //
      showViewTeamOnRequest, //
      setShowViewTeamOnRequest, //
      showEditDateModal, //
      updateReq, //
      setUpdateReq, //
      setUpdatedItem, //
      setUpdateInfo, //
      updateInfo, //
      modalOpen,
      setModalOpen,
      setShowUserReqFromNav,
      device,
    ]
  );

  useEffect(() => {
    memoVals.setModalOpen((x) => true);

    return () => {
      memoVals.setModalOpen((x) => false);
    };
  }, [memoVals.setModalOpen]);

  let shortenHeaderLeaveName = (val) => {
    if (val && val.length > 26) {
      return `${val.substr(0, 23)}...`;
    } else {
      return val;
    }
  };
  let [changed, setChanged] = useState(false); // if true, need to update parent requests holder, as the request data has changed somewhat

  let [showExcessMgr, setShowExcessMgr] = useState(false);

  let [showExcessExplain, setShowExcessExplain] = useState(false);

  let [datesChanged, setDatesChanged] = useState(false);

  let [showSelfApproveModal, setShowSelfApproveModal] = useState(false);

  let [showNoteModal, setShowNoteModal] = useState(false);
  let [reqNote, setReqNote] = useState("");

  let [regen, setRegen] = useState(false);

  let [showLimits, setShowLimits] = useState(false);
  let apostrophise = (name) => {
    if (name) {
      if (name[name.length - 1] === "s") {
        return `${name}'`;
      } else {
        return `${name}'s`;
      }
    } else {
      return "";
    }
  };

  let excessScrollBox = useRef();

  useEffect(() => {
    // clean not needed
    if (excessScrollBox.current && showExcessMgr) {
      excessScrollBox.current.scrollTop = 0;
    }
  }, [showExcessMgr]);

  let formatMonthCalIcon = (val) => {
    if (val === "00") {
      return "Jan";
    } else if (val === "01") {
      return "Feb";
    } else if (val === "02") {
      return "Mar";
    } else if (val === "03") {
      return "Apr";
    } else if (val === "04") {
      return "May";
    } else if (val === "05") {
      return "Jun";
    } else if (val === "06") {
      return "Jul";
    } else if (val === "07") {
      return "Aug";
    } else if (val === "08") {
      return "Sep";
    } else if (val === "09") {
      return "Oct";
    } else if (val === "10") {
      return "Nov";
    } else if (val === "11") {
      return "Dec";
    }
  };

  let formatDurationWithHalf = (startHalf, endHalf, days) => {
    let numberToReturn = days;
    if (startHalf) {
      numberToReturn -= 0.5;
    }
    if (endHalf) {
      numberToReturn -= 0.5;
    }
    return numberToReturn;
  };

  let whenWasRequestMade = (created) => {
    let todayStart = new Date();
    todayStart.setHours(0, 0, 0, 0);
    let yesterdayStart = new Date();
    yesterdayStart.setDate(todayStart.getDate() - 1);
    yesterdayStart.setHours(0, 0, 0, 0);

    let createdObj = new Date(created);
    createdObj.setHours(0, 0, 0, 0);

    let numberOfDays =
      Math.round(todayStart.getTime() - createdObj.getTime()) / 86400000;

    if (created >= todayStart.getTime()) {
      return "today";
    }
    if (created >= yesterdayStart.getTime() && created < todayStart.getTime()) {
      return "yesterday";
    }
    return `${Math.round(numberOfDays)} days ago`;
  };

  let createdHowManyDaysAgo = (ts) => {
    let now = new Date();
    let daysAgo = (now.getTime() - ts) / 1000 / 60 / 60 / 24;
    return Math.ceil(daysAgo) - 1;
  };

  // modal to edit/delete request
  let [reqInfoModalOpen, setReqInfoModalOpen] = useState(false);
  let [reqInfoTitle, setReqInfoTitle] = useState();
  let [reqSubmitted, setReqSubmitted] = useState();
  let [reqID, setReqID] = useState();
  let [declinedReqModal, setDeclinedReqModal] = useState(false);
  let [approvedReqModal, setApprovedReqModal] = useState(false);
  let [declineReason, setDeclineReason] = useState("");
  let [declinedBy, setDeclinedBy] = useState();
  let [approvedBy, setApprovedBy] = useState();

  let [sureDeleteReq, setSureDeleteReq] = useState(false);
  let [reqIsPending, setReqIsPending] = useState(false);
  let [reqIsPast, setReqIsPast] = useState(false);

  let [showDupeModal, setShowDupeModal] = useState(false);
  let [mgrPendingsExist, setMgrPendingsExist] = useState(false);

  let [showMgrReqDecisionModal, setShowMgrReqDecisionModal] = useState(false);
  let [staffReq, setStaffReq] = useState({});
  let [sureDeclinedReq, setSureDeclinedReq] = useState(false);
  let [approveLoading, setApproveLoading] = useState(false);
  let [declineLoading, setDeclineLoading] = useState(false);

  let [showMgrReqModal, setShowMgrReqModal] = useState(false);
  let [changeDecision, setChangeDecision] = useState(false);

  // filter states
  let [showReqFilter, setShowReqFilter] = useState(false);
  let [allNames, setAllNames] = useState([]); // array of objects w/ fName, lName and userID
  let [filterUserID, setFilterUserID] = useState("everyone");
  // let [filterApproved, setFilterApproved] = useState(false);
  // let [filterDeclined, setFilterDeclined] = useState(false);

  let [editDateStart, setEditDateStart] = useState();
  let [editDateEnd, setEditDateEnd] = useState();

  let [dateAlreadyRequested, setDateAlreadyRequested] = useState(false);

  let [reqStartDs, setReqStartDs] = useState("Y2000M00D01H00M00");
  let [reqEndDs, setReqEndDs] = useState("Y2000M00D01H00M00");
  let [requestStartHalf, setRequestStartHalf] = useState(false);
  let [requestEndHalf, setRequestEndHalf] = useState(false);
  let [requestDsArr, setRequestDsArr] = useState([]);

  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);
  let [imagesLoaded, setImagesLoaded] = useState(0);

  useEffect(() => {
    if (dataLoaded && imagesLoaded === 17) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);

  let imgPreload = (
    <div className="imagesHidden">
      <img src={reqLoader} alt="Request Loader" className="" />
      <img
        src={whiteHelp}
        alt="White Help"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={redTriangle}
        alt="Red Triangle"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={checkboxInstall1}
        alt="Checkbox Install 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={checkboxInstall2}
        alt="Checkbox Install 2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={crossWhite}
        alt="Cross White"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whiteTick}
        alt="White Tick"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={unitTick}
        alt="Unit Tick"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={unitCross}
        alt="Unit Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={editNavy}
        alt="Edit Grey"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={returnArrow}
        alt="Return Arrow"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whitecross}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={close}
        alt="Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={absence}
        alt="Absence"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={profilePicturePlaceholder}
        alt="Profile Picture Placeholder"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={noteWhite}
        alt="Note White"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whiteGoBack}
        alt="White Go Back"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whiteGo}
        alt="White Go"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );
  let [durArr, setDurArr] = useState([]);

  let [jam, setJam] = useState(false);
  let [jamMgr, setJamMgr] = useState([]); // HOLDS NAMES OF JAMMED USERS

  let [fNameSched, setFNameSched] = useState("");
  let [lNameSched, setLNameSched] = useState("");

  let [timesArr, setTimesArr] = useState([]);
  // let [firstDay, setFirstDay] = useState("Y2000M00D01H00M00")
  // let [lastDay, setLastDay] = useState("Y2000M00D01H00M00")

  let [showPreviousPayExcessModal, setShowPreviousPayExcessModal] =
    useState(false);
  let [overridePreviousPayExcess, setOverridePreviousPayExcess] =
    useState(false);

  // array for the request's user's datestrings that they already have off, meaning anotehr request cannot be edited to overlap it
  let [dsArrUserAlreadyHasRequested, setDsArrUserAlreadyHasRequested] =
    useState([]);

  let [sureDeleteAbsence, setSureDeleteAbsence] = useState(false);
  let [forceBeforeToday, setForceBeforeToday] = useState(false);

  let formatMinsDurationToHours = (mins) => {
    if (mins === 0) {
      return "None";
    }
    let hours = Math.floor(mins / 60);
    let m = mins % 60;

    let hString = "hr";
    let mString = "m";

    if (m === 0) {
      mString = "";
      m = "";
    } else if (m === 1) {
      mString = "m";
    }

    if (hours === 0) {
      hString = "";
      hours = "";
      mString = "mins";
    } else if (hours === 1) {
      hString = "hr";
    }

    if (!m && hours > 1) {
      hString = " hours";
    } else if (!m && hours === 1) {
      hString = " hour";
    }

    return `${hours}${hString}${hString && mString ? " " : ""}${
      m === "" ? m : Math.round(m)
    }${!hString && mins < 60 ? " " : ""}${mString}`;
  };

  let tonight2359 = new Date();
  tonight2359.setHours(23, 59, 59, 0);
  let today0000 = new Date();
  today0000.setHours(0, 0, 0, 0);

  useEffect(() => {
    // clean not needed
    setState({ newEndHalf: false, newStartHalf: false });
  }, [memoVals.showEditDateModal]);

  let [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      cost: 0,
      durArr: [],
      timesArr: [],
      teamID: "",
      fName: "",
      lName: "",
      costArr: [],
      workingPattern: "",
      isOwnReq: false,
      allowSelfApprove: false,
      reqID: "",
      staffReq: {},
      newStartHalf: "",
      newEndHalf: "",
      showMgrReqModal: false,
      fNameSched: "",
      lNameSched: "",
      showExcessMgr: false,
      reqUserID: "",
      excessMgrType: "",
      excessMgrPay: false,
      excessMgrAllow: false,
      leaveTypeName: "",
      totalExcessMins: 0,
      contractedDuration: 0,
      excessDuration: 0,
      reqStartDs: "",
      reqEndDs: "",
      requestStartHalf: false,
      requestEndHalf: false,
      requestDsArr: [],
      startTime: "",
      endTime: "",
      dontShowStartTime: true,
      dontShowStartTime: true,
      profPicUrl: "",
      limitArr: [],
      teamName: "",
      isAbsence: false,
      totAllow: 0,
      usedMins: 0,
      usedDays: 0,
      remaining: 0,
      userDailyMins: 0,
      sufficient: false,
      year: 0,
      dayCount: 0,
    }
  );

  let [totalCost, setTotalCost] = useState(0);
  let [deductTotalCost, setDeductTotalCost] = useState(0);
  let [totSal, setTotSal] = useState(0);
  let [includesSalaried, setIncludesSalaried] = useState(false);
  useEffect(() => {
    // clean not needed
    let c = 0;
    let deduct = 0;
    let incSal = false;
    let tSal = 0;
    if (state.costArr && state.costArr[0]) {
      state.costArr.forEach((obj) => {
        if (!state.paid) {
          deduct += obj.cost;
        } else {
          if (!obj.salaried) {
            c += obj.cost;
          } else {
            tSal += obj.cost;
            if (obj.excessOrActual === "excess") {
              c += obj.cost;
            } else {
              incSal = true;
            }
          }
        }
      });
    }

    if (tSal) {
      setTotSal(tSal);
    } else {
      setTotSal(0);
    }
    if (c) {
      setTotalCost(c);
    } else {
      setTotalCost(0);
    }

    if (deduct) {
      setDeductTotalCost(deduct);
    } else {
      setDeductTotalCost(0);
    }

    if (incSal) {
      setIncludesSalaried(incSal);
    } else {
      setIncludesSalaried(false);
    }
  }, [state]);

  let [isPendingReq, setIsPendingReq] = useState(false);
  let [rate, setRate] = useState(1);
  let [reqDoesNotExist, setReqDoesNotExist] = useState(false);
  let [excessEnabledOnClient, setExcessEnabledOnClient] = useState(false);
  let [excessEnabledOnUser, setExcessEnabledOnUser] = useState(false);
  let [showCannotManageOwnShift, setShowCannotManageOwnShift] = useState({
    show: false,
  });

  let [deductions, setDeductions] = useState(0);
  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    axios
      .post(
        `${serverURL}/get-single-request-data`,
        {
          reqID: unitReqID,
          isMgr: true,
        },
        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message === "cannot self manage") {
          setShowCannotManageOwnShift({
            show: true,
            teamName: response.data.teamName,
            isAbsene: response.data.isAbsence,
          });
        } else {
          if (
            response.data.message ===
            "you do not manage this user's primary team"
          ) {
            memoVals.setNoPermissions((x) => {
              return {
                show: true,
                type: "period",
                teamName: response.data.teamName,
                fName: response.data.fName,
                isYou:
                  response.data.reqUserID === memoVals.checkUserState.userID,
              };
            });
            memoVals.setShowReqMgrModal((x) => false);
          }
          if (response.data.message === "getReq not valid") {
            setReqDoesNotExist(true);
          }
          if (response.data.message === "success") {
            setExcessEnabledOnClient(response.data.excessEnabledOnClient);
            setExcessEnabledOnUser(response.data.excessEnabledOnUser);

            response.data.cost.costArr.sort(function (a, b) {
              return (
                dateStringer.createTimestampFromString(a.ds) -
                dateStringer.createTimestampFromString(b.ds)
              );
            });

            let excMins = 0;
            if (response.data.data.excessDurArr) {
              response.data.data.excessDurArr.forEach((exc) => {
                excMins += exc.mins;
              });
            }

            let excessDuration = 0;
            if (
              response.data.data.excessMins &&
              response.data.data.excessMins > 0
            ) {
              excessDuration = response.data.data.excessMins;
            } else if (
              response.data.data.excessMinsExtendAllowance &&
              response.data.data.excessMinsExtendAllowance > 0
            ) {
              excessDuration = response.data.data.excessMinsExtendAllowance;
            } else {
              excessDuration = 0;
            }
            setIsPendingReq(response.data.pending);
            setRate(response.data.rate);
            let deductable = 0;
            let costFilt = response.data.cost.costArr.filter((x) => {
              return (
                x.deductable &&
                x.excessOrActual === "actual" &&
                x.deductFromSalary
              );
            });
            costFilt.forEach((x) => {
              deductable += x.deductable;
            });
            setDeductions(deductable);
            setState({
              cost: response.data.cost.cost,
              durArr: response.data.durationArray,
              timesArr: response.data.timesArr,
              costArr: response.data.cost.costArr,

              workingPattern: response.data.workingPattern,
              isOwnReq: response.data.isOwnReq,
              allowSelfApprove: response.data.allowSelfApprove,
              reqID: response.data.data.reqID,
              staffReq: response.data.data,
              paid: response.data.paid,
              rate: response.data.rate,
              newStartHalf: response.data.data.reqStartHalf,
              newEndHalf: response.data.data.reqEndHalf,
              showMgrReqModal: true,
              fName: response.data.fName,
              lName: response.data.lName,
              fNameSched: response.data.fName,
              lNameSched: response.data.lName,
              showExcessMgr: false,
              reqUserID: response.data.data.userID,
              excessMgrType: response.data.data.excessType,
              excessMgrPay: response.data.data.excessMins > 0 ? true : false,
              excessMgrAllow:
                response.data.data.excessMinsExtendAllowance > 0 ? true : false,

              leaveTypeName: response.data.leaveTypeName,
              totalExcessMins: excMins,
              contractedDuration: response.data.data.zMins
                ? response.data.data.zMins
                : response.data.data.duration,
              excessDuration: excessDuration,
              reqStartDs: `${
                response.data.data.dateStringArray
                  ? response.data.data.dateStringArray[0]
                  : "Y2000M00D01H00M00"
              }`,
              reqEndDs: `${
                response.data.data.dateStringArray
                  ? response.data.data.dateStringArray[
                      response.data.data.dateStringArray.length - 1
                    ]
                  : "Y2000M00D01H00M00"
              }`,
              requestStartHalf: response.data.data.reqStartHalf,
              requestEndHalf: response.data.data.reqEndHalf,
              requestDsArr: response.data.data.dateStringArray,
              startTime: response.data.startEndTimeObj
                ? response.data.startEndTimeObj.start
                : "",
              endTime: response.data.startEndTimeObj
                ? response.data.startEndTimeObj.end
                : "",
              dontShowStartTime:
                response.data.startEndTimeObj.dontShowStart === false
                  ? false
                  : true,

              dontShowEndTime:
                response.data.startEndTimeObj.dontShowEnd === false
                  ? false
                  : true,
              profPicUrl: response.data.profPicUrl,
              limitArr: response.data.isAbsence ? [] : response.data.limitData,
              teamName: response.data.teamName,
              isAbsence: response.data.isAbsence,

              year: response.data.year ? response.data.year : 0,
            });

            if (response.data.data.leaveTypeID === "annLeave") {
              setState({
                totAllow: response.data.allowInfo
                  ? response.data.allowInfo.totalAllowMins
                  : 0,
                usedMins: response.data.allowInfo
                  ? response.data.allowInfo.usedMins
                  : 0,
                usedDays: response.data.allowInfo
                  ? response.data.allowInfo.usedDays
                  : 0,
                remaining: response.data.allowInfo
                  ? response.data.allowInfo.remaining
                  : 0,
                userDailyMins: response.data.allowInfo
                  ? response.data.allowInfo.userDailyMins
                  : 0,
                sufficient:
                  (response.data.data.zMins || response.data.data.duration) <=
                  (response.data.allowInfo
                    ? response.data.allowInfo.remaining
                    : 0)
                    ? true
                    : false,
              });
            }

            if (response.data.data.leaveTypeID !== "annLeave") {
              let customFilt =
                response.data.allowInfo &&
                response.data.allowInfo.customData &&
                response.data.allowInfo.customData[0]
                  ? response.data.allowInfo.customData.filter((x) => {
                      return x.leaveTypeID === response.data.data.leaveTypeID;
                    })
                  : [];

              if (customFilt[0]) {
                let dayCount = 0;
                if (response.data.data.durationArray) {
                  response.data.data.durationArray.forEach((d) => {
                    if (d.half) {
                      dayCount += 0.5;
                    } else {
                      dayCount++;
                    }
                  });
                }
                let thisDuration =
                  customFilt[0].daysOrHours === "hours"
                    ? response.data.data.zMins
                      ? response.data.data.zMins
                      : response.data.data.duration
                    : dayCount;
                setState({
                  totAllow: customFilt[0].totAllow ? customFilt[0].totAllow : 0,
                  usedMins: customFilt[0].used ? customFilt[0].used : 0,
                  usedDays: customFilt[0].used ? customFilt[0].used : 0,
                  remaining: customFilt[0].remaining
                    ? customFilt[0].remaining
                    : 0,
                  userDailyMins: response.data.allowInfo
                    ? response.data.allowInfo.userDailyMins
                    : 0,
                  sufficient:
                    thisDuration <= customFilt[0].remaining ? true : false,
                  daysOrHours: customFilt[0].daysOrHours,
                  dayCount,
                });
              }
            }

            if (memoVals.reqIDToLoadWhenClosingEditUser) {
              memoVals.setReqIDToLoadWhenClosingEditUser((x) => "");
            }
            if (dataLoaded) {
              setDataLoaded(false);
            }
            setDataLoaded(true);
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });

    // console.log({ memoVals.updateReq, changed });
    if (memoVals.updateReq) {
      setChanged(true);
    } else {
      setChanged(false);
    }

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [memoVals.updateReq, unitReqID]);

  useEffect(() => {
    // clean not needed
    if (memoVals.showAddLeaveModal && memoVals.showAddLeaveModal.update) {
      setChanged(true);

      memoVals.setUpdateReq((x) => !memoVals.updateReq);
    }
  }, [memoVals.showAddLeaveModal]);

  let getLeaveName = (id) => {
    let filtered =
      memoVals.leaveTypeArr &&
      memoVals.leaveTypeArr.filter((item) => {
        return item.leaveTypeID === id;
      });
    if (filtered && filtered[0]) {
      return filtered[0].name;
    } else {
      return "";
    }
  };

  let createDate = (start, end, ongoing) => {
    if (start && end) {
      let startDate = parseInt(start.substr(9, 2));
      let startMonth = parseInt(start.substr(6, 2));
      let endDate = parseInt(end.substr(9, 2));
      let endMonth = parseInt(end.substr(6, 2));
      let yr = `'${JSON.stringify(parseInt(end.substr(1, 4))).substr(2, 2)}`;
      let startMonthOutput = "";
      if (startMonth === 0) {
        startMonthOutput = "Jan";
      } else if (startMonth === 1) {
        startMonthOutput = "Feb";
      } else if (startMonth === 2) {
        startMonthOutput = "Mar";
      } else if (startMonth === 3) {
        startMonthOutput = "Apr";
      } else if (startMonth === 4) {
        startMonthOutput = "May";
      } else if (startMonth === 5) {
        startMonthOutput = "Jun";
      } else if (startMonth === 6) {
        startMonthOutput = "Jul";
      } else if (startMonth === 7) {
        startMonthOutput = "Aug";
      } else if (startMonth === 8) {
        startMonthOutput = "Sep";
      } else if (startMonth === 9) {
        startMonthOutput = "Oct";
      } else if (startMonth === 10) {
        startMonthOutput = "Nov";
      } else if (startMonth === 11) {
        startMonthOutput = "Dec";
      }

      let endMonthOutput = "";
      if (endMonth === 0) {
        endMonthOutput = "Jan";
      } else if (endMonth === 1) {
        endMonthOutput = "Feb";
      } else if (endMonth === 2) {
        endMonthOutput = "Mar";
      } else if (endMonth === 3) {
        endMonthOutput = "Apr";
      } else if (endMonth === 4) {
        endMonthOutput = "May";
      } else if (endMonth === 5) {
        endMonthOutput = "Jun";
      } else if (endMonth === 6) {
        endMonthOutput = "Jul";
      } else if (endMonth === 7) {
        endMonthOutput = "Aug";
      } else if (endMonth === 8) {
        endMonthOutput = "Sep";
      } else if (endMonth === 9) {
        endMonthOutput = "Oct";
      } else if (endMonth === 10) {
        endMonthOutput = "Nov";
      } else if (endMonth === 11) {
        endMonthOutput = "Dec";
      }

      // if (ongoing) {
      //   `${startDate} ${endMonthOutput} ${yr}`;
      //   return;
      // } else {
      if (startMonthOutput !== endMonthOutput && !ongoing) {
        return `${startDate} ${startMonthOutput} - ${endDate} ${endMonthOutput} ${yr}`;
      } else if (
        (startMonthOutput === endMonthOutput && startDate === endDate) ||
        ongoing
      ) {
        return `${startDate} ${startMonthOutput} ${yr}`;
      } else {
        return `${startDate} - ${endDate} ${endMonthOutput} ${yr}`;
      }
      // }
    }
  };

  let todayDateObject = new Date();
  todayDateObject.setHours(0, 0, 0, 0);
  let todayDateString = dateStringer.createStringFromTimestamp(
    todayDateObject.getTime()
  );

  let shortenLeaveTypeBubble = (text, app, dec) => {
    if (state.isAbsence) {
      return text;
    } else {
      let is13thDigitSpace = false;
      if (text[17] === " ") {
        is13thDigitSpace = true;
      }
      // if (!app && !dec) {
      //   return text;
      // } else {
      if (text.length >= 24) {
        return `${text.substr(0, is13thDigitSpace ? 21 : 22)}...`;
      } else {
        return text;
      }
    }
  };

  let updateParentIfChanged = (
    callChangedRegardless,
    deleteReq,
    isApproving,
    isDeclining
  ) => {
    if (callChangedRegardless || changed) {
      let days = 0;
      if (state.staffReq.durationArray && state.staffReq.durationArray[0]) {
        state.staffReq.durationArray.forEach((x) => {
          if (x.half) {
            days += 0.5;
          } else {
            days += 1;
          }
        });
      }
      let changeObj = {
        type: "leave",
        reqStart: state.staffReq.reqStart,
        reqEnd: state.staffReq.reqEnd,
        delete: deleteReq,
        reqID: state.staffReq.reqID,
        leaveTypeID: state.staffReq.leaveTypeID,
        approved: isApproving
          ? true
          : isDeclining
          ? false
          : state.staffReq.approved,
        declined: isDeclining
          ? true
          : isApproving
          ? false
          : state.staffReq.declined,
        zMins: state.staffReq.zMins,
        fName: state.fNameSched,
        lName: state.lNameSched,
        cost: state.cost,
        leaveName: state.leaveTypeName,
        teamID: state.teamID,
        userID: state.staffReq.userID,
        startHalf: state.staffReq.reqStartHalf,
        endHalf: state.staffReq.reqEndHalf,
        durArr: state.durArr,
        costArr: state.costArr,
        timesArr: state.timesArr,
        workingPattern: state.workingPattern,
        leaveTypeID: state.staffReq.leaveTypeID,
        limitExceeded: state.limitArr[0] ? true : false,
        days,
      };

      memoVals.setUpdatedItem((x) => changeObj);
      console.log("changed req: ", changeObj);
    }

    setState({ showReqMgrModal: false });
  };

  let [showCostDiv, setShowCostDiv] = useState(false);
  let [showAllowInfo, setShowAllowInfo] = useState(false);
  let generateCostDiv = useMemo(() => {
    return state.costArr
      ? state.costArr.map((item) => {
          if (
            item.cost > 0 ||
            item.salaried ||
            (!item.cost && !item.salaried && item.excessOrActual === "actual")
          ) {
            return (
              <div className="costArrRow">
                <p className="costArrTitle">
                  {/* • */}
                  {dateStringer.printedDateFromDs(item.ds)}
                </p>

                {item.cost === 0 && item.salaried ? (
                  <p
                    className={`incSal3 ${!state.paid ? "incSal3unpaid" : ""}`}
                  >
                    {!state.paid
                      ? item.deductFromSalary && item.deductable
                        ? `${memoVals.currencySymbol}${dateStringer.formatCost(
                            item.deductable
                          )} deducted from salary`
                        : "Unpaid"
                      : "Salary"}
                  </p>
                ) : (
                  <p className="costArrValue">
                    {memoVals.currencySymbol}
                    {item.cost ? item.cost.toFixed(2) : 0}
                    <br />
                    <span className="costContractedExcessSpan">
                      {item.excessOrActual === "actual"
                        ? `${
                            item.mpwAtTheTime === 0
                              ? "based on average"
                              : `${
                                  item.salaried
                                    ? `${
                                        !state.paid
                                          ? "deducted from salary"
                                          : "based on salary"
                                      }` // molly
                                    : "based on hourly rate"
                                }`
                          }`
                        : "from overtime"}
                    </span>
                  </p>
                )}
              </div>
            );
          }
        })
      : "";
  }, [showCostDiv]);

  let generateAllowDiv = useMemo(() => {
    return (
      <Fragment>
        <div className="costArrRow">
          <p className="costArrTitle">Remaining</p>

          <p className={`costArrValue ${!state.sufficient ? "colourRed" : ""}`}>
            {/* {dateStringer.formatMinsDurationToHours(state.remaining)} */}

            {state.daysOrHours && state.daysOrHours === "days"
              ? `${state.remaining} ${state.remaining <= 1 ? "day" : "days"}`
              : dateStringer.formatMinsDurationToHours(
                  state.remaining,
                  false,
                  true
                )}
            <br />
            {state.daysOrHours && state.daysOrHours === "days" ? (
              ""
            ) : (
              <span
                className={`${
                  state.remaining < 0 ? "none" : "costContractedExcessSpan"
                }`}
              >
                {dateStringer.convertMinsToDays(
                  state.remaining,
                  state.userDailyMins,
                  true
                )}
              </span>
            )}
          </p>
        </div>
        <div className="costArrRow">
          <p className="costArrTitle">This request</p>

          <p className="costArrValue colour00aaff">
            {/* {formatMinsDurationToHours(state.contractedDuration)} */}
            {/* {state.dayCount} */}
            {state.daysOrHours && state.daysOrHours === "days" && state.dayCount
              ? `${state.dayCount} ${state.dayCount <= 1 ? "day" : "days"}`
              : formatMinsDurationToHours(state.contractedDuration)}
            <br />
            {state.daysOrHours && state.daysOrHours === "days" ? (
              ""
            ) : (
              <span className="costContractedExcessSpan">
                {" "}
                {`${formatDurationWithHalf(
                  state.requestStartHalf,
                  state.requestEndHalf,
                  state.requestDsArr.length
                )}`}{" "}
                days{" "}
              </span>
            )}
          </p>
        </div>{" "}
        <div className="costArrRow">
          <p className="costArrTitle">Allowance year</p>

          <p className="costArrValue">{state.year}</p>
        </div>
        <div className="costArrRow">
          <p className="costArrTitle">Total allowance</p>

          <p className="costArrValue">
            {state.daysOrHours && state.daysOrHours === "days"
              ? `${state.totAllow} ${state.totAllow <= 1 ? "day" : "days"}`
              : dateStringer.formatMinsDurationToHours(state.totAllow)}
          </p>
        </div>
      </Fragment>
    );
  }, [showAllowInfo]);

  state.limitArr.sort(
    (a, b) =>
      dateStringer.createTimestampFromString(a.ds) -
      dateStringer.createTimestampFromString(b.ds)
  );

  let generateLimits = state.limitArr
    ? state.limitArr.map((lim) => {
        return (
          <div className="costArrRow">
            <p className="costArrTitle">
              {/* ⁃  */}
              {dateStringer.printedDateFromDs(lim.ds)}
            </p>

            <div className="limArr">
              <div className="limRowFlex firstLimRowFlex">
                <p className="limRowTitleSpan">Limit:</p> {lim.maxOff}
              </div>
              <br />{" "}
              <div className="limRowFlex">
                <p className="limRowTitleSpan">
                  {isPendingReq ? "Will be off:" : "Currently off:"}
                </p>{" "}
                {lim.currOff}
              </div>
              <br />{" "}
              <p className="limitBtLimRowTxt">
                Limit by: <br />
                <span className="limitByJtOrTeamSpan">
                  {lim.limitByTeamName
                    ? `All staff in ${lim.limitByTeamName}`
                    : `${lim.limitByJt}${
                        lim.limitByJt[lim.limitByJt.length - 1] === "s"
                          ? "es"
                          : "s"
                      }`}
                </span>
              </p>
              {/* <br /> */}
              {/* {Math.round((item.cost + Number.EPSILON) * 100) / 100}
          <span className="costContractedExcessSpan">
            {item.excessOrActual === "actual"
              ? `${
                  state.workingPattern === "zero"
                    ? "based on average"
                    : "contracted"
                }`
              : "from overtime"}
          </span> */}
            </div>
          </div>
        );
      })
    : "";

  useEffect(() => {
    // clean not needed
    if (excessScrollBox.current) {
      excessScrollBox.current.scrollTop = 0;
    }
  }, [state]);

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 30) {
      console.log("CLOSE!");
      if (loadedFromTeamViewer) {
        memoVals.setShowReqModalInViewer((x) => "");
      } else {
        memoVals.setShowReqMgrModal((x) => false);
        setState({
          startTime: "",
          endTime: "",
          showMgrReqModal: false,
          totalExcessMins: 0,
          showExcessMgr: false,
        });

        setOverridePreviousPayExcess(false);

        setSureDeclinedReq(false);
        setChangeDecision(false);

        // updateParentIfChanged(false, false);
      }
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  let [isAtTop, setIsAtTop] = useState(true);
  let [okToDrag, setOkToDrag] = useState(true);
  // MASTER RETURN
  return (
    <div
      className={`${
        !memoVals.openManagerShiftModal.shiftID &&
        !memoVals.openManagerShiftModal.tilID
          ? "reqInfoUnderlay"
          : "none"
      }  ${memoVals.showEditUser ? "editUserIsShownOnVtor_" : ""}`}
      onClick={() => {
        if (loadedFromTeamViewer) {
          memoVals.setShowReqModalInViewer((x) => "");
        } else {
          memoVals.setShowReqMgrModal((x) => false);
          setState({
            startTime: "",
            endTime: "",
            showMgrReqModal: false,
            totalExcessMins: 0,
            showExcessMgr: false,
          });

          setOverridePreviousPayExcess(false);

          setSureDeclinedReq(false);
          setChangeDecision(false);
        }
      }}
    >
      {loading ? (
        <div
          className={`shiftLoadingBox ${
            memoVals.mobModal ? "shiftLoadingBoxMob" : "shiftLoadingBoxDesktop"
          }`}
        >
          <img
            src={horizontalBalls}
            alt="Loading"
            className={`shiftLoadingBallsImg ${
              memoVals.mobModal ? "shiftLoadingBallsImgMob" : "zoomIn"
            }`}
          />
        </div>
      ) : state.showMgrReqModal ? (
        <div
          className={`${
            state.staffReq.approved && !state.staffReq.declined
              ? "reqInfoModalBox approvedModalBg"
              : `${
                  !state.staffReq.approved && state.staffReq.declined
                    ? "reqInfoModalBox declinedModalBg"
                    : "reqInfoModalBox"
                }`
          } ${
            memoVals.mobModal
              ? `mobReqInfoModalBox ${
                  memoVals.device.ios ? "mobReqInfoModalBoxIos" : ""
                } mobModalShoulder slideUp ${
                  sureDeclinedReq
                    ? `sureDeclinedReqModalMob ${
                        state.staffReq.approved
                          ? "sureDeclineModalBoxApprovedWithOutRegenMob"
                          : "sureDeclineModalBoxNotYetApprovedWithOutRegenMob"
                      } ${
                        state.workingPattern == "fixedScheduled" &&
                        state.staffReq.approved
                          ? "sureDeclinedWithReganModalMob"
                          : ""
                      }`
                    : ""
                }`
              : `zoomIn ${
                  sureDeclinedReq
                    ? `sureDeclinedReqModal  ${
                        state.staffReq.approved
                          ? "sureDeclineModalBoxApprovedWithOutRegen"
                          : "sureDeclineModalBoxNotYetApprovedWithOutRegen"
                      } ${
                        state.workingPattern == "fixedScheduled" &&
                        state.staffReq.approved
                          ? "sureDeclinedWithReganModal"
                          : ""
                      }`
                    : ""
                }`
          }
            mgrModal ${state.isAbsence ? "absenceModalMgr" : ""} `}
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={modalPosition}
          ref={modalRef}
        >
          {memoVals.mobModal && (
            <div
              className="modalSwiper modalSwiperLeaveReqModal"
              onTouchStart={handleDown}
              onTouchMove={memoVals.mobModal ? handleMove : null}
              onTouchEnd={memoVals.mobModal ? handleUp : null}
              onMouseDown={memoVals.mobModal ? handleDown : null}
              onMouseMove={memoVals.mobModal ? handleMove : null}
              onMouseUp={memoVals.mobModal ? handleUp : null}
            ></div>
          )}
          <div
            className="reqInfoHeader"
            onClick={() => {
              if (memoVals.mobModal) {
                if (loadedFromTeamViewer) {
                  memoVals.setShowReqModalInViewer((x) => "");
                } else {
                  memoVals.setShowReqMgrModal((x) => false);

                  setState({
                    startTime: "",
                    endTime: "",
                    showMgrReqModal: false,
                    totalExcessMins: 0,
                    showExcessMgr: false,
                  });

                  setOverridePreviousPayExcess(false);

                  setSureDeclinedReq(false);
                  setChangeDecision(false);

                  // updateParentIfChanged(false, false);
                }
              }
            }}
            onTouchStart={handleDown}
            onTouchMove={memoVals.mobModal ? handleMove : null}
            onTouchEnd={memoVals.mobModal ? handleUp : null}
            onMouseDown={memoVals.mobModal ? handleDown : null}
            onMouseMove={memoVals.mobModal ? handleMove : null}
            onMouseUp={memoVals.mobModal ? handleUp : null}
          >
            <img
              src={memoVals.mobModal ? close : close}
              className="closeReqInfoModalImg"
              alt="Close"
              onClick={() => {
                if (loadedFromTeamViewer) {
                  memoVals.setShowReqModalInViewer((x) => "");
                } else {
                  memoVals.setShowReqMgrModal((x) => false);

                  setState({
                    startTime: "",
                    endTime: "",
                    showMgrReqModal: false,
                    totalExcessMins: 0,
                    showExcessMgr: false,
                  });

                  setOverridePreviousPayExcess(false);

                  setSureDeclinedReq(false);
                  setChangeDecision(false);

                  // updateParentIfChanged(false, false);
                }
              }}
            />
            <p className="reqInfoTitleTxt">{`${apostrophise(
              state.staffReq.fName || state.fNameSched
            )} ${
              state.isAbsence
                ? "absence"
                : `${
                    !state.staffReq.approved && !state.staffReq.declined
                      ? "request"
                      : "leave"
                  }`
            }`}</p>
            {/* <div  className="closeReqInfoModalImg"> */}
            <img
              src={`${
                state.isAbsence
                  ? absence
                  : state.staffReq.approved && !state.staffReq.declined
                  ? unitTick
                  : unitCross
              }`}
              className={`modalReqDecisionImg ${
                !state.staffReq.approved && !state.staffReq.declined
                  ? "invis"
                  : ""
              }`}
            />
            {/* </div> */}
          </div>
          <div
            className={`requestSubHeader ${
              state.isAbsence ? "isAbsenceSubHeaderCorrection" : ""
            }`}
          >
            <p
              className={`leaveTypeSubBubble ${
                state.isAbsence
                  ? "isAbsenceLeaveTypeBorderLeft"
                  : "isLeaveTypeBorderLeft"
              } ${sureDeclinedReq ? "none" : ""} ${
                state.isOwnReq &&
                !state.allowSelfApprove &&
                memoVals.checkUserState.permissions !== 1
                  ? "defaultCursor_"
                  : ""
              } ${state.staffReq.declined ? "defaultCursor x23894398423" : ""}`}
              onClick={() => {
                if (!state.staffReq.declined) {
                  if (
                    state.isOwnReq &&
                    !state.allowSelfApprove &&
                    memoVals.checkUserState.permissions !== 1
                  ) {
                    setShowSelfApproveModal(true);
                  } else {
                    memoVals.setShowAddLeaveModal((x) => {
                      return {
                        show: true,
                        startDs: "",
                        userID: state.reqUserID,
                        leaveTypeID: state.staffReq.leaveTypeID,
                        fName: state.fName,
                        reqID: state.staffReq.reqID,
                        editing: true,
                        editStartDs: state.staffReq.reqStart,
                        editEndDs: state.staffReq.reqEnd,
                        ongoing: state.staffReq.ongoing,
                        isAbsence: state.isAbsence,
                        openedFromModal: true,
                      };
                    });
                  }
                }
              }}
            >
              {shortenLeaveTypeBubble(state.leaveTypeName)}{" "}
              <img
                src={editNavy}
                className={`${
                  state.staffReq.declined ||
                  loadedFromTeamViewer ||
                  (state.isOwnReq &&
                    !state.allowSelfApprove &&
                    memoVals.checkUserState.permissions !== 1)
                    ? "none"
                    : "inlineEditReqDateImg2"
                }`}
                alt="Edit"
              />
            </p>
            {!state.isAbsence ? (
              <p
                className={`${
                  state.staffReq.approved || state.staffReq.declined
                    ? `approvedDeclinedBubble ${
                        state.staffReq.approved
                          ? "greenBubble"
                          : "redBubble noBorder"
                      }`
                    : "none"
                }`}
                // onClick={() => {
                // setChangeDecision(true);
                // }}
              >{`${
                state.staffReq.approved
                  ? state.staffReq.addedByMgr
                    ? "Added by manager"
                    : "Approved"
                  : `${
                      state.staffReq.declineReason &&
                      state.staffReq.declineReason.includes("cancelled")
                        ? "Cancelled"
                        : "Declined"
                    }`
              }`}</p>
            ) : (
              <p>&nbsp;</p>
            )}
          </div>
          {/* non-declining content */}
          <div
            className={`${
              sureDeclinedReq
                ? "none"
                : `staffReqModalContentContainer ${
                    memoVals.device.ios
                      ? "staffReqModalContentContainerIos"
                      : ""
                  }`
            }`}
          >
            <div
              className={`${
                state.staffReq.declined &&
                !state.staffReq.approved &&
                state.staffReq.declineReason
                  ? "staffReqModalContentLeft Scroll"
                  : "staffReqModalContentLeft"
              } ${
                state.isAbsence && memoVals.mobModal
                  ? "staffReqModalContentLeftAbs"
                  : ""
              } ${memoVals.mobModal ? "mobMgrReqBodyLeft" : ""}`}
              ref={excessScrollBox}
              // onScroll={(e) => {
              //   if (e.target.scrollTop === 0) {
              //     setIsAtTop(true);
              //     setOkToDrag(false);
              //   } else {
              //     setIsAtTop(false);
              //   }
              // }}
              // onTouchStart={
              //   memoVals.mobModal && okToDrag && isAtTop ? handleDown : null
              // }
              // onTouchMove={
              //   memoVals.mobModal && okToDrag && isAtTop ? handleMove : null
              // }
              // onTouchEnd={() => {
              //   memoVals.mobModal && okToDrag && isAtTop ? handleUp : null;
              //   console.log(
              //     memoVals.mobModal && okToDrag && isAtTop ? "HANDLE UP" : "NO"
              //   );
              //   setOkToDrag(true);
              // }}
              // onMouseDown={
              //   memoVals.mobModal && okToDrag && isAtTop ? handleDown : null
              // }
              // onMouseMove={
              //   memoVals.mobModal && okToDrag && isAtTop ? handleMove : null
              // }
              // onMouseUp={() => {
              //   memoVals.mobModal && okToDrag && isAtTop ? handleUp : null;
              //   console.log(
              //     memoVals.mobModal && okToDrag && isAtTop ? "HANDLE UP" : "NO"
              //   );
              //   setOkToDrag(true);
              // }}
            >
              {/* --------------------------------------------------------- */}
              <div
                className={`${
                  state.showExcessMgr ? "excessMgrLeftContentBox" : "none"
                }`}
              >
                <div className="excessHeaderContainer">
                  <img
                    src={whiteGoBack}
                    className="returnBackImgAccruedLeaveOnReqImg"
                    alt="Back"
                    onClick={() => {
                      setState({ showExcessMgr: false });
                    }}
                  />
                  <p className="excessMgrTxtHeader" onClick={() => {}}>
                    Accrued additional leave
                  </p>
                  <img
                    src={showExcessExplain ? whitecross : whiteHelp}
                    alt="Help"
                    className="overtimeHolidayHelpIcon"
                    onClick={() => {
                      setShowExcessExplain(!showExcessExplain);
                    }}
                  />
                </div>
                <div
                  className={`${
                    showExcessExplain ? "excessExplainBridge" : "none"
                  }`}
                ></div>
                <div
                  className={`${
                    showExcessExplain ? "excessExplainDiv" : "none"
                  }`}
                >
                  <p className="excessExplainTitle">
                    Additional leave accrued from overtime
                  </p>
                  This is the amount of leave{" "}
                  {state.staffReq.fName || state.fNameSched} has accrued from
                  any hours worked beyond their contracted weekly hours
                  (averaged across the reference period).{" "}
                  {state.isAbsence
                    ? "If enabled, the additional amount payable will be paid within this absence period."
                    : "This can be paid as part of the request, or used to extend their overall allowance - or neither."}
                </div>
                <div className="accruedLeaveDurationPreview">
                  <p className="accruedMinsTxt">
                    This request:{" "}
                    <span className="accruedMinsSpan">
                      {formatMinsDurationToHours(state.totalExcessMins)}
                    </span>
                  </p>
                </div>{" "}
                <div className="excessMgrTxtHeaderSplitter"></div>
                {/* excess row - - - - - - - - - - - - - - - - - -  */}
                <div
                  className={`${
                    state.staffReq.reqStart
                      ? `${
                          dateStringer.createTimestampFromString(
                            state.staffReq.reqStart
                          ) < todayDateObject.getTime() &&
                          !overridePreviousPayExcess
                            ? "excessRow previousPayExcessFade"
                            : "excessRow"
                        }`
                      : "excessRow"
                  }`}
                  onClick={() => {
                    if (
                      state.staffReq.reqStart &&
                      dateStringer.createTimestampFromString(
                        state.staffReq.reqStart
                      ) < todayDateObject.getTime() &&
                      !overridePreviousPayExcess
                    ) {
                      setShowPreviousPayExcessModal(true);
                    }
                  }}
                >
                  <div
                    className="excessRowHeader"
                    onClick={() => {
                      if (
                        state.staffReq.reqStart &&
                        dateStringer.createTimestampFromString(
                          state.staffReq.reqStart
                        ) < todayDateObject.getTime() &&
                        !overridePreviousPayExcess
                      ) {
                      } else {
                        let today = new Date();
                        today.setHours(0, 0, 0, 0);
                        let todayDs = dateStringer.createStringFromTimestamp(
                          today.getTime()
                        );

                        axios
                          .post(
                            `${serverURL}/edit-single-request-excess-type`,
                            {
                              reqID: state.staffReq.reqID,
                              todayDs: todayDs,
                              forceBeforeToday: forceBeforeToday,
                              data: !state.excessMgrPay
                                ? "pay"
                                : `${state.excessMgrAllow ? "allow" : "null"}`,
                            },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              memoVals.setUpdateReq((x) => !memoVals.updateReq);
                              memoVals.setUpdateTabData(
                                (x) => !memoVals.updateTabData
                              );
                              // setUpdateMgrReqSingleReqID(state.staffReq.reqID);
                              // setState({ cost: response.data.cost.cost });

                              // setUpdateMgrReqs(!updateMgrReqs);
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                        if (state.excessMgrPay === false) {
                          // you are changing to pay so make allow/null false
                          setState({
                            excessMgrAllow: false,
                          });
                        }
                        if (state.excessMgrPay === true) {
                        }
                        setState({ excessMgrPay: !state.excessMgrPay });
                      }
                    }}
                  >
                    {/*  KNOB BEGIN */}
                    <div className="knobContainer excessRowKnob">
                      <div
                        className={`switchKnob ${
                          state.excessMgrPay
                            ? // &&
                              // (excessMgrType === "pay" ||
                              //   excessMgrType === "auto")
                              "knobOn"
                            : "knobOff"
                        }`}
                      ></div>

                      <div className="knobBg"></div>
                    </div>
                    {/*  KNOB END */}
                    <p className="excessRowTitle">Pay</p>
                  </div>

                  <p className="excessRowSubTxt">
                    Pay {state.staffReq.fName || state.fNameSched} the accrued
                    leave.
                  </p>
                </div>
                {/* end of excess row -  - - - - - - - - - - - - - - - -  */}
                {/* {!state.isAbsence ? (
                  <div className="excessRowSplitter"></div>
                ) : (
                  ""
                )} */}
                {/* excess row - - - - - - - - - - - - - - - - - -  */}
                {!state.isAbsence && 2 + 2 === 5 ? (
                  <div className="excessRow">
                    <div
                      className="excessRowHeader"
                      onClick={() => {
                        let today = new Date();
                        today.setHours(0, 0, 0, 0);
                        let todayDs = dateStringer.createStringFromTimestamp(
                          today.getTime()
                        );

                        axios
                          .post(
                            `${serverURL}/edit-single-request-excess-type`,
                            {
                              reqID: state.staffReq.reqID,
                              todayDs: todayDs,
                              forceBeforeToday: forceBeforeToday,
                              data: !state.excessMgrAllow
                                ? "allow"
                                : `${state.excessMgrPay ? "pay" : "null"}`,
                            },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              // setState({ cost: response.data.cost.cost });
                              memoVals.setUpdateReq((x) => !memoVals.updateReq);
                              memoVals.setUpdateTabData(
                                (x) => !memoVals.updateTabData
                              );

                              // setUpdateMgrReqSingleReqID(staffReq.reqID);
                              // setUpdateMgrReqs(!updateMgrReqs);
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                        if (state.excessMgrAllow === false) {
                          // you are changing to allow so make pay/null false
                          setState({ excessMgrPay: false });
                        }
                        if (state.excessMgrAllow === true) {
                          // you are changing to now allows so if pay is also false, set to null
                          // setExcessMgrAllow(false);
                          if (state.excessMgrPay === false) {
                          }
                        }

                        setState({ excessMgrAllow: !state.excessMgrAllow });
                      }}
                    >
                      {/*  KNOB BEGIN */}
                      <div
                        className="knobContainer excessRowKnob"
                        onClick={() => {
                          setState({ excessMgrAllow: !state.excessMgrAllow });
                        }}
                      >
                        <div
                          className={`switchKnob ${
                            state.excessMgrAllow ? "knobOn" : "knobOff"
                          }`}
                        ></div>

                        <div className="knobBg"></div>
                      </div>
                      {/*  KNOB END */}
                      <p className="excessRowTitle">Add to allowance</p>
                    </div>

                    <p className="excessRowSubTxt">
                      Extend{" "}
                      {apostrophise(state.staffReq.fName || state.fNameSched)}{" "}
                      allowance to include the accrued leave.
                    </p>
                  </div>
                ) : (
                  ""
                )}
                {/* end of excess row -  - - - - - - - - - - - - - - - -  */}
                {/* <div className='excessRowSplitter'></div> */}
              </div>

              <div
                className={`${
                  state.showExcessMgr ? "none" : "nonExcessContent"
                }`}
              >
                <div className="editDateBarContainerFlex">
                  <div
                    className={`staffReqModalDatesString ${
                      !state.staffReq.declined ? "clickShiftRow" : ""
                    } ${
                      state.staffReq.reqStart &&
                      state.staffReq.reqEnd &&
                      createDate(state.staffReq.reqStart, state.staffReq.reqEnd)
                        .length > 17
                        ? "makeDsTxt OnReqSmaller"
                        : ""
                    } ${
                      loadedFromTeamViewer ||
                      (state.isOwnReq &&
                        !state.allowSelfApprove &&
                        memoVals.checkUserState.permissions !== 1)
                        ? "defCursor_"
                        : ""
                    } ${state.staffReq.declined ? "defaultCursor" : ""}`}
                    onClick={() => {
                      if (
                        state.isOwnReq &&
                        !state.allowSelfApprove &&
                        memoVals.checkUserState.permissions !== 1
                      ) {
                        setShowSelfApproveModal(true);
                      } else {
                        if (!loadedFromTeamViewer) {
                          if (!state.staffReq.declined) {
                            setDatesChanged(false);
                            memoVals.setShowAddLeaveModal((x) => {
                              return {
                                show: true,
                                startDs: "",
                                userID: state.reqUserID,
                                leaveTypeID: state.staffReq.leaveTypeID,
                                fName: state.fName,
                                reqID: state.staffReq.reqID,
                                editing: true,
                                editStartDs: state.staffReq.reqStart,
                                editEndDs: state.staffReq.reqEnd,
                                ongoing: state.staffReq.ongoing,
                                isAbsence: state.isAbsence,
                              };
                            });
                          }
                        }
                      }
                    }}
                  >
                    {createDate(
                      state.staffReq.reqStart,
                      state.staffReq.reqEnd,
                      state.staffReq.ongoing
                    )}
                    {state.staffReq.ongoing ? (
                      <span className="reqOngoingSpan">On-going</span>
                    ) : (
                      ""
                    )}
                    <img
                      src={editNavy}
                      className={`${
                        state.staffReq.declined ||
                        loadedFromTeamViewer ||
                        (state.isOwnReq &&
                          !state.allowSelfApprove &&
                          memoVals.checkUserState.permissions !== 1)
                          ? "none"
                          : "inlineEditReqDateImg"
                      }`}
                      alt="Edit"
                    />
                  </div>
                </div>

                {state.staffReq.ongoing ? (
                  <div className="editDateBarContainerFlex">
                    <div
                      className={`staffReqModalDatesString ongoingTxtFormat`}
                    >
                      Currently open-ended
                      <p
                        className="endOngoingNowBtn"
                        onClick={() => {
                          if (
                            state.isOwnReq &&
                            !state.allowSelfApprove &&
                            memoVals.checkUserState.permissions !== 1
                          ) {
                          } else {
                            if (!loadedFromTeamViewer) {
                              if (!state.staffReq.declined) {
                                setDatesChanged(false);
                                memoVals.setShowAddLeaveModal((x) => {
                                  return {
                                    show: true,
                                    startDs: "",
                                    userID: state.reqUserID,
                                    leaveTypeID: state.staffReq.leaveTypeID,
                                    fName: state.fName,
                                    reqID: state.staffReq.reqID,
                                    editing: true,
                                    editStartDs: state.staffReq.reqStart,
                                    editEndDs: state.staffReq.reqEnd,
                                    ongoing: state.staffReq.ongoing,
                                    toEndOngoing: true,
                                    isAbsence: state.isAbsence,
                                  };
                                });
                              }
                            }
                          }
                        }}
                      >
                        End now
                      </p>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div className="allowanceUsedTxt">
                  {`${
                    state.isAbsence
                      ? "Absence duration"
                      : state.reqIsPast
                      ? "Allowance used"
                      : "Allowance to use"
                  }`}{" "}
                  {state.staffReq.ongoing ? (
                    <span className="ongoingUpToNowSpan">- up to now</span>
                  ) : (
                    ""
                  )}
                </div>

                <p
                  className={`staffReqDuration ${
                    isPendingReq && state.sufficient
                      ? "suffDur"
                      : isPendingReq && !state.sufficient
                      ? "insuffDur"
                      : ""
                  }`}
                >
                  {formatMinsDurationToHours(state.staffReq.zMins)}
                </p>
                {excessEnabledOnClient &&
                excessEnabledOnUser &&
                state.totalExcessMins > 0 &&
                state.paid &&
                !state.staffReq.declined ? (
                  <div className="mgrLeaveStartEndSplitter toUseSplitter"></div>
                ) : (
                  ""
                )}
                {excessEnabledOnClient &&
                excessEnabledOnUser &&
                state.totalExcessMins > 0 &&
                state.paid &&
                !state.staffReq.declined ? (
                  <div className="allowanceUsedTxt">Accrued from overtime</div>
                ) : (
                  ""
                )}
                {excessEnabledOnClient &&
                excessEnabledOnUser &&
                state.totalExcessMins > 0 &&
                state.paid &&
                !state.staffReq.declined ? (
                  <div className="staffReqDuration">
                    <span className={state.excessMgrPay ? "" : "x29842981"}>
                      +{formatMinsDurationToHours(state.totalExcessMins)}{" "}
                    </span>
                    <div
                      className="manageExcessOnReqBtn"
                      onClick={() => {
                        if (
                          state.isOwnReq &&
                          !state.allowSelfApprove &&
                          memoVals.checkUserState.permissions !== 1
                        ) {
                          setShowSelfApproveModal(true);
                        } else {
                          if (
                            state.staffReq.reqStart &&
                            dateStringer.createTimestampFromString(
                              state.staffReq.reqStart
                            ) < todayDateObject.getTime() &&
                            !overridePreviousPayExcess
                          ) {
                            setShowPreviousPayExcessModal(true);
                            // console.log(
                            //   "x19783497132",
                            //   overridePreviousPayExcess
                            // );
                          } else {
                            let today = new Date();
                            today.setHours(0, 0, 0, 0);
                            let todayDs =
                              dateStringer.createStringFromTimestamp(
                                today.getTime()
                              );

                            // setLoading(true);
                            if (showCostDiv) {
                              setShowCostDiv(false);
                            }
                            axios
                              .post(
                                `${serverURL}/edit-single-request-excess-type`,
                                {
                                  reqID: state.staffReq.reqID,
                                  todayDs: todayDs,
                                  forceBeforeToday: forceBeforeToday,
                                  data: !state.excessMgrPay ? "pay" : "null",
                                },

                                {
                                  withCredentials: true,
                                  credentials: "include",
                                }
                              )
                              .then((response) => {
                                if (response.data.message === "success") {
                                  memoVals.setUpdateReq(
                                    (x) => !memoVals.updateReq
                                  );
                                  memoVals.setUpdateTabData(
                                    (x) => !memoVals.updateTabData
                                  );

                                  // setUpdateMgrReqSingleReqID(state.staffReq.reqID);
                                  // setState({ cost: response.data.cost.cost });

                                  // setUpdateMgrReqs(!updateMgrReqs);
                                }
                              })
                              .catch((err) => {
                                console.error(err);
                              });
                            if (state.excessMgrPay === false) {
                              // you are changing to pay so make allow/null false
                              setState({
                                excessMgrAllow: false,
                              });
                            }
                            if (state.excessMgrPay === true) {
                            }
                            setState({ excessMgrPay: !state.excessMgrPay });
                          }
                        }
                      }}
                    >
                      Pay{" "}
                      <img
                        src={
                          state.excessMgrPay
                            ? checkboxInstall2
                            : checkboxInstall1
                        }
                        alt="Manage"
                        className="manageExcessWhiteGoImg"
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {state.costArr[0] ? (
                  <div
                    className="costBoxContainer"
                    onClick={() => {
                      setShowCostDiv(!showCostDiv);
                    }}
                  >
                    <div className="allowanceUsedTxt">
                      {!state.isAbsence ? "Request cost" : "Absence cost"}{" "}
                      {state.staffReq.ongoing ? (
                        <span className="ongoingUpToNowSpan">- up to now</span>
                      ) : (
                        ""
                      )}
                      {!state.paid ? (
                        <span className="ongoingUpToNowSpan">- unpaid</span>
                      ) : (
                        ""
                      )}
                    </div>
                    {includesSalaried && totalCost > 0 ? (
                      <p
                        className={`incSal ${
                          totSal ? "totSalIncludedSize" : ""
                        }`}
                      >
                        Salary{" "}
                        {totSal ? (
                          <span className="totSalIncl">
                            {memoVals.currencySymbol}
                            {totSal ? totSal.toFixed(0) : 0}
                          </span>
                        ) : (
                          ""
                        )}
                      </p>
                    ) : (
                      ""
                    )}
                    <div
                      className={`reqCostDivToggle ${
                        memoVals.mobModal ? "mobReqCostDivToggle" : ""
                      } ${showCostDiv ? "noBorder" : ""}`}
                      onClick={() => {
                        setShowCostDiv(!showCostDiv);
                      }}
                    >
                      {loading ? (
                        <img
                          src={reqLoader}
                          className="reqCostLoadImg"
                          alt="loading"
                        />
                      ) : (
                        <div className={`staffReqDuration noBorder`}>
                          {totalCost === 0 && includesSalaried ? (
                            <p
                              className={`incSal2 ${
                                totSal ? "totSalIncludedSize" : ""
                              }`}
                            >
                              Salary{" "}
                              {totSal ? (
                                <span className="totSalIncl">
                                  {memoVals.currencySymbol}
                                  {totSal ? totSal.toFixed(0) : 0}
                                </span>
                              ) : (
                                ""
                              )}
                            </p>
                          ) : deductTotalCost ? (
                            <p className="incSal4">{`${
                              memoVals.currencySymbol
                            }${
                              deductTotalCost ? deductTotalCost.toFixed(2) : 0
                            } deduction`}</p>
                          ) : (
                            ""
                          )}
                          {totalCost > 0 && includesSalaried ? "+" : ""}
                          {(totalCost === 0 && includesSalaried) ||
                          deductTotalCost
                            ? ""
                            : memoVals.currencySymbol}
                          {(totalCost === 0 && includesSalaried) ||
                          deductTotalCost
                            ? ""
                            : totalCost > 0
                            ? totalCost.toFixed(0)
                            : !state.paid && deductions
                            ? `${dateStringer.formatCost(deductions)} deduction`
                            : "0"}
                          <img
                            src={whiteGo}
                            alt="Show costs"
                            className={`reqCostDropImg ${
                              showCostDiv
                                ? "showCostImgInactive"
                                : "whiteGoActive"
                            } ${state.costArr[0] ? "" : "none"}`}
                            onClick={() => {
                              setShowCostDiv(!showCostDiv);
                            }}
                          />{" "}
                        </div>
                      )}
                    </div>

                    <div className={`${showCostDiv ? "reqCostDiv" : "none"}`}>
                      {generateCostDiv}
                    </div>
                  </div>
                ) : (
                  <div className="noReqCostSplitter"></div>
                )}

                {isPendingReq ? (
                  <div
                    className="costBoxContainer allowBoxContainer"
                    onClick={() => {
                      setShowAllowInfo(!showAllowInfo);
                    }}
                  >
                    <div
                      className="allowanceUsedTxt"
                      onClick={() => {
                        console.log({
                          totAllow: state.totAllow,
                          usedMins: state.usedMins,
                          usedDays: state.usedDays,
                          remaining: state.remaining,
                          sufficient: state.sufficient,
                          userDailyMins: state.userDailyMins,
                          reqDays: dateStringer.convertMinsToDays(
                            state.contractedDuration,
                            state.userDailyMins,
                            true
                          ),
                        });
                      }}
                    >
                      Allowance
                    </div>

                    <div
                      className={`reqCostDivToggle ${
                        memoVals.mobModal ? "mobReqCostDivToggle" : ""
                      } ${showAllowInfo ? "noBorder" : ""}`}
                      onClick={() => {
                        setShowAllowInfo(!showAllowInfo);
                      }}
                    >
                      <div className={`staffReqDuration noBorder`}>
                        <p
                          className={`allowStatus ${
                            state.sufficient ? "suffLeaveReq" : "insuffLeaveReq"
                          }`}
                        >
                          {state.sufficient
                            ? "Sufficient allowance"
                            : "Insufficient allowance"}
                        </p>
                        <img
                          src={whiteGo}
                          alt="Show costs"
                          className={`reqCostDropImg ${
                            showAllowInfo
                              ? "showCostImgInactive"
                              : "whiteGoActive"
                          }`}
                          onClick={() => {
                            setShowAllowInfo(!showAllowInfo);
                          }}
                        />{" "}
                      </div>
                    </div>
                    <div className={`${showAllowInfo ? "reqCostDiv" : "none"}`}>
                      {generateAllowDiv}
                    </div>
                  </div>
                ) : (
                  ""
                  // <div className="noReqCostSplitter"></div>
                )}

                {/* new manage request content row */}
                <div
                  className={`${
                    state.staffReq.excessMins && state.staffReq.excessMins > 0 // daisy
                      ? "payableTxt x198419"
                      : "none"
                  } ${state.staffReq.declined ? "none" : ""} ${
                    state.isAbsence && !state.paid ? "none" : ""
                  }`}
                  // onClick={() => {
                  //   setShowExcessMgr(true);
                  // }}
                >
                  Total leave hours{" "}
                  {/* <p
                  className={`${
                    state.staffReq.reqStart &&
                    dateStringer.createTimestampFromString(
                      state.staffReq.reqStart
                    ) > today0000.getTime()
                      ? "mgrRequestExcessMinsConfirmedOrEstimatedSpan"
                      : "mgrRequestExcessMinsConfirmedOrEstimatedSpan confirmedDiscBgOnly"
                  } ${state.staffReq.declined ? "none" : ""}`}
                  onClick={() => {
                    // setShowExcessMgr(true);
                  }}
                >
                  {`${
                    state.staffReq.reqStart &&
                    dateStringer.createTimestampFromString(
                      state.staffReq.reqStart
                    ) > today0000.getTime()
                      ? "estimated"
                      : "confirmed"
                  }`}
                </p> */}
                </div>
                <p
                  className={`${
                    state.staffReq.excessMins > 0
                      ? "staffReqDuration excessMinsMgrReqVal"
                      : "none"
                  } ${state.staffReq.declined ? "none" : ""} ${
                    state.isAbsence && !state.paid ? "none" : ""
                  }`}
                  onClick={() => {
                    // setShowExcessMgr(true);
                  }}
                >
                  {state.excessMgrType === "pay"
                    ? formatMinsDurationToHours(
                        state.staffReq.zMins + state.staffReq.excessMins
                      )
                    : formatMinsDurationToHours(state.staffReq.zMins)}
                </p>
                {/* end of  manage request content row */}
                {state.staffReq.excessMins && state.staffReq.excessMins > 0 ? (
                  <div
                    className={`mgrLeaveStartEndSplitter payableSplitter ${
                      state.isAbsence && !state.paid ? "none" : ""
                    }`}
                  ></div>
                ) : (
                  ""
                )}
                <div
                  className={`${
                    state.staffReq.zMins && (state.startTime || state.endTime)
                      ? "mgrLeaveStartAndEndRow"
                      : "none"
                  }`}
                >
                  <p className="mgrReqLeaveEndStartTitle">From </p>
                  <p
                    className={`${
                      state.startTime ? "mgrReqStartEndVal" : "none"
                    }`}
                  >
                    {state.dontShowStartTime
                      ? dateStringer.printedDateFromDs(state.startTime)
                      : dateStringer.printedDateWithTimeFromDs(state.startTime)}
                  </p>
                  <p
                    className={`${
                      state.staffReq.reqStartHalf ? "startHalfSpan" : "none"
                    }`}
                  >
                    half day
                  </p>
                  <div className="mgrLeaveStartEndSplitter"></div>
                  <p className="mgrReqLeaveEndStartTitle">Until</p>
                  <p
                    className={`${
                      state.endTime ? "mgrReqStartEndVal" : "none"
                    }`}
                  >
                    {state.dontShowEndTime
                      ? dateStringer.printedDateFromDs(state.endTime)
                      : dateStringer.printedDateWithTimeFromDs(
                          state.endTime
                        )}{" "}
                  </p>
                  <p
                    className={`${
                      state.staffReq.reqEndHalf ? "startHalfSpan" : "none"
                    }`}
                  >
                    half day
                  </p>{" "}
                  {state.paid && (
                    <div className="mgrLeaveStartEndSplitter"></div>
                  )}
                  {state.paid && (
                    <p className="mgrReqLeaveEndStartTitle">Costed to</p>
                  )}
                  {state.paid && (
                    <p className="mgrReqStartEndVal">{state.teamName} </p>
                  )}
                  <div
                    className={`${
                      (!state.staffReq.approved && !state.staffReq.declined) ||
                      state.limitArr[0]
                        ? "none"
                        : "mgrLeaveStartEndSplitter"
                    }`}
                  ></div>{" "}
                </div>

                {/* limits row */}
                {state.limitArr[0] ? (
                  <div
                    className="reqLimitsContainer"
                    onClick={() => {
                      setShowLimits(!showLimits);
                    }}
                  >
                    <p className="approveByTxtTitle">Limits exceeded</p>
                    <div className="exceededDaysDiv">
                      <p
                        className="nOutOfDaysSpan"
                        // onClick={() => {
                        //   setShowLimits(!showLimits);
                        // }}
                      >
                        <span className="colourDarkRed">
                          {state.limitArr.length}
                        </span>{" "}
                        out of {state.staffReq.dateStringArray.length}{" "}
                        {state.staffReq.dateStringArray.length === 1
                          ? "day"
                          : "days"}
                      </p>
                      {/* jamie */}
                      <img
                        src={whiteGo}
                        alt="Show costs"
                        className={`limitsDrop ${
                          showLimits ? "showCostImgInactive" : "whiteGoActive"
                        }`}
                        onClick={() => {
                          setShowLimits(!showLimits);
                        }}
                      />{" "}
                    </div>

                    {showLimits ? (
                      <div className="reqLimitsArray">{generateLimits}</div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
                {/* {state.limitArr[0] && !showLimits ? (
                <div className="mgrLeaveStartEndSplitter"></div>
              ) : (
                ""
              )} */}
                {/* end of limits row */}

                <div
                  className={`${
                    state.staffReq.declined || state.staffReq.approved
                      ? "reqApprovedBy"
                      : " none"
                  }`}
                >
                  <p className="approveByTxtTitle">{`${
                    state.isAbsence || state.staffReq.addedByMgr
                      ? "Added by"
                      : state.staffReq.approved && !state.staffReq.declined
                      ? "Approved by"
                      : `${
                          state.staffReq.declineReason &&
                          state.staffReq.declineReason.includes("cancelled")
                            ? "Cancelled by"
                            : "Declined by"
                        }`
                  }
                `}</p>
                  <p className="approveByTxtName approvedByMarginTop">{`${
                    state.staffReq.approved && !state.staffReq.declined
                      ? `${dateStringer.nameFormat(state.staffReq.approvedBy)}`
                      : `${dateStringer.nameFormat(state.staffReq.declinedBy)}`
                  }`}</p>
                </div>

                <p
                  className={`${
                    state.staffReq.declined &&
                    !state.staffReq.approved &&
                    state.staffReq.declineReason &&
                    !state.staffReq.declineReason.includes("cancelled")
                      ? "staffReqDeclineReasonTxt"
                      : "none"
                  }`}
                >
                  {state.staffReq.declineReason}
                </p>
              </div>
            </div>
            <div
              className="staffReqModalContentRight"
              onTouchStart={handleDown}
              onTouchMove={memoVals.mobModal ? handleMove : null}
              onTouchEnd={memoVals.mobModal ? handleUp : null}
              onMouseDown={memoVals.mobModal ? handleDown : null}
              onMouseMove={memoVals.mobModal ? handleMove : null}
              onMouseUp={memoVals.mobModal ? handleUp : null}
            >
              <div
                className={`${state.staffReq.note ? "reqNoteBtnMgr" : "none"}`}
                onClick={() => {
                  setReqNote(state.staffReq.note);
                  setShowNoteModal(true);
                  if (!state.staffReq.noteViewed) {
                    // TODO: Toggle note viewed request
                    axios
                      .post(
                        `${serverURL}/req-note-viewed`,
                        { reqID: state.staffReq.reqID },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          // update staffReq
                          let newObj = state.staffReq;
                          newObj.noteViewed = true;

                          setState({ staffReq: newObj });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }
                }}
              >
                <div
                  className={`${
                    state.staffReq.noteViewed
                      ? "noteNotViewedDot x981971"
                      : "noteNotViewedDot"
                  }`}
                ></div>
                Note{" "}
                <img
                  src={noteWhite}
                  alt="Request note"
                  className="reqNoteBtnNoteImg"
                />
              </div>
              <div
                className={`${
                  loadedFromTeamViewer
                    ? "none"
                    : `viewReqTeamCal ${
                        state.limitArr[0] ? "viewReqTeamCalWarning" : ""
                      }`
                }`}
                onClick={() => {
                  setReqID(state.reqID);

                  memoVals.setShowViewTeamOnRequest((x) => true);
                }}
              >
                Team{" "}
                {state.limitArr[0] ? (
                  <img
                    src={redTriangle}
                    alt="Limits active"
                    className="limitsRedTriangleImg"
                  />
                ) : (
                  ""
                )}
              </div>
              {state.limitArr[0] ? (
                <p
                  className={`${
                    isPendingReq ? "willBeUnder" : "reqUnderstaffedTxt"
                  } ${loadedFromTeamViewer ? "understaffedRed" : ""}`}
                >
                  {isPendingReq ? "Understaffed" : "Understaffed"}
                </p>
              ) : (
                ""
              )}
              <div className="reqRightBarDivider"></div>

              {state.profPicUrl ? (
                <img
                  src={state.profPicUrl}
                  alt="Profile"
                  className="requestProfPicImg"
                  onClick={() => {
                    // if (memoVals.showEditUser) {
                    // console.log("XX11");
                    if (loadedFromTeamViewer) {
                      memoVals.setShowReqModalInViewer((x) => "");
                    } else {
                      memoVals.setShowReqMgrModal((x) => false);
                      setState({
                        startTime: "",
                        endTime: "",
                        showMgrReqModal: false,
                        totalExcessMins: 0,
                        showExcessMgr: false,
                      });
                      setOverridePreviousPayExcess(false);
                      setSureDeclinedReq(false);
                      setChangeDecision(false);
                      // updateParentIfChanged(false, false);
                    }

                    console.log("shutting");
                    // jess
                    // } else {
                    //   console.log("xx22");

                    memoVals.setShowEditUser((x) => state.reqUserID);
                    memoVals.setShowReqMgrModal((x) => false);

                    memoVals.setReqIDToLoadWhenClosingEditUser(
                      (x) => state.staffReq.reqID
                    );
                    // reqID: state.staffReq.reqID,
                    // state.reqUserI
                    // );
                    // }
                  }}
                />
              ) : (
                <div
                  className="reqBlankProfPicHolder"
                  onClick={() => {
                    memoVals.setShowEditUser((x) => state.reqUserID);
                    memoVals.setShowReqMgrModal((x) => false);
                  }}
                >
                  <img
                    src={profilePicturePlaceholder}
                    alt="Profile"
                    className="reqBlankProfilePicPlaceholderImg"
                    onClick={() => {
                      if (memoVals.showEditUser) {
                        setShowMgrReqModal(false);
                        memoVals.setShowEditUser((x) => state.reqUserID);
                        memoVals.setShowReqMgrModal((x) => false);

                        // jess
                      } else {
                        memoVals.setShowEditUser((x) => state.reqUserID);
                        memoVals.setShowReqMgrModal((x) => false);
                      }
                    }}
                  />
                  <div className="reqBlankProPicInitialsHolder">
                    <p className="reqBlankProfPicInitial">
                      {state.fName ? state.fName[0] : ""}
                    </p>
                    <p className="reqBlankProfPicInitial">
                      {state.lName ? state.lName[0] : ""}
                    </p>
                  </div>
                </div>
              )}
              <div
                className={`${
                  state.staffReq.zMins > 0 ? "reqTimelineDiv" : "none"
                }`}
              >
                {/* FIRST ICON --USER, not mgr */}
                <div
                  className={`${
                    state.reqStartDs === state.reqEndDs
                      ? "timelineIcon mgrTimelineIcon pushDownIfSingle"
                      : "timelineIcon mgrTimelineIcon"
                  } ${
                    approvedReqModal && !declinedReqModal
                      ? "timelineIconGreenBg"
                      : ""
                  } ${
                    declinedReqModal && !approvedReqModal
                      ? "timelineIconRedBg"
                      : ""
                  }

              `}
                >
                  <p className="iconCalDateNumber">
                    {state.reqStartDs.substr(9, 2)}
                    <span
                      className={`${
                        state.requestStartHalf ||
                        (state.requestEndHalf &&
                          state.requestDsArr.length === 1)
                          ? "halfDayIconAppend"
                          : "none"
                      }`}
                    >
                      1/2
                    </span>
                  </p>{" "}
                  <div className="iconBlob">
                    {formatMonthCalIcon(state.reqStartDs.substr(6, 2))}
                  </div>
                </div>
                {/* if request is only one day, and half, then explain to the user which part of the shift is off -- TODO: if user is fixedSched, you can put the time */}
                <div
                  className={`${
                    state.requestDsArr.length === 1 &&
                    (state.requestStartHalf || state.requestEndHalf)
                      ? "halfDayInfoHook"
                      : "none"
                  }`}
                ></div>
                <p
                  className={`${
                    state.requestDsArr.length === 1 &&
                    (state.requestStartHalf || state.requestEndHalf)
                      ? "showHalfDayInfo"
                      : "none"
                  }`}
                >{`${state.requestEndHalf ? "First" : "Last"} half`}</p>
                <div
                  className={`${
                    state.reqStartDs === state.reqEndDs
                      ? "none"
                      : "middleTimelineBar"
                  }`}
                >
                  <div className="timelineBarCircle"></div>
                  <div className="timelineBarLine"></div>
                  <p
                    className={`timelineDur ${
                      approvedReqModal ? "approvedTimelineDurColour" : ""
                    } ${declinedReqModal ? "declinedTimelineDurColour" : ""}`}
                  >
                    {`${formatDurationWithHalf(
                      state.requestStartHalf,
                      state.requestEndHalf,
                      state.requestDsArr.length
                    )}`}{" "}
                    days
                  </p>
                  <div className="timelineBarCircle"></div>
                </div>
                {/* SECOND ICON --USER, not mgr */}
                <div
                  className={`timelineIcon mgrTimelineIcon ${
                    state.reqStartDs === state.reqEndDs ? "none" : ""
                  }
              ${approvedReqModal ? "timelineIconGreenBg" : ""}
              ${declinedReqModal ? "timelineIconRedBg" : ""}`}
                >
                  <p className="iconCalDateNumber">
                    {state.reqEndDs.substr(9, 2)}

                    <span
                      className={`${
                        state.requestEndHalf ? "halfDayIconAppend" : "none"
                      }`}
                    >
                      1/2
                    </span>
                  </p>
                  <div className="iconBlob">
                    {formatMonthCalIcon(state.reqEndDs.substr(6, 2))}
                  </div>
                </div>{" "}
              </div>
              <div
                className={`${
                  state.staffReq.zMins > 0 ? "reqTimelineDiv" : "none"
                } none`}
              >
                {/* FIRST ICON */}
                <div
                  className={`${
                    state.staffReq.dateStringArray &&
                    state.staffReq.dateStringArray.length > 1
                      ? ""
                      : "pushDownIfSingle"
                  } ${
                    state.staffReq.approved && !state.staffReq.declined
                      ? "timelineIcon mgrTimelineIcon timelineIconGreenBg"
                      : `${
                          state.staffReq.declined && !state.staffReq.approved
                            ? "timelineIcon mgrTimelineIcon timelineIconRegBg"
                            : "timelineIcon mgrTimelineIcon"
                        }`
                  }`}
                >
                  <p className="iconCalDateNumber">
                    {state.staffReq.duration > 0 || state.staffReq.zMins > 0
                      ? `${
                          state.staffReq.dateStringArray.length > 0
                            ? state.staffReq.dateStringArray[0].substr(9, 2)
                            : 0
                        }`
                      : 0}
                    <span
                      className={`${
                        state.staffReq.reqStartHalf
                          ? "halfDayIconAppend"
                          : "none"
                      }`}
                    >
                      1/2
                    </span>
                  </p>{" "}
                  <div className="iconBlob">
                    {formatMonthCalIcon(
                      state.staffReq.duration > 0 || state.staffReq.zMins > 0
                        ? `${
                            state.staffReq.dateStringArray.length > 0
                              ? state.staffReq.dateStringArray[0].substr(6, 2)
                              : 0
                          }`
                        : "00"
                    )}
                  </div>
                </div>
                <div
                  className={`${
                    state.staffReq.dateStringArray &&
                    state.staffReq.dateStringArray.length > 1
                      ? "middleTimelineBar"
                      : "none"
                  }`}
                >
                  <div className="timelineBarCircle"></div>
                  <div className="timelineBarLine"></div>
                  <p
                    className={`${
                      state.staffReq.approved && !state.staffReq.declined
                        ? "timelineDur approvedTimelineDurColour"
                        : `${
                            state.staffReq.declined && !state.staffReq.approved
                              ? "timelineDur declinedTimelineDurColour"
                              : "timelineDur"
                          }`
                    }`}
                  >
                    {`${
                      state.staffReq.dateStringArray
                        ? `${formatDurationWithHalf(
                            state.staffReq.reqStartHalf,
                            state.staffReq.reqEndHalf,
                            state.staffReq.dateStringArray.length
                          )}`
                        : "55"
                    }`}{" "}
                    days
                  </p>
                  <div className="timelineBarCircle"></div>
                </div>
                {/* SECOND ICON */}
                <div
                  className={`${
                    state.staffReq.approved && !state.staffReq.declined
                      ? "timelineIcon mgrTimelineIcon timelineIconGreenBg"
                      : `${
                          state.staffReq.declined && !state.staffReq.approved
                            ? "timelineIcon mgrTimelineIcon timelineIconRegBg"
                            : "timelineIcon mgrTimelineIcon"
                        }`
                  } ${
                    state.staffReq.dateStringArray &&
                    state.staffReq.dateStringArray.length === 1
                      ? "none"
                      : ""
                  }`}
                >
                  <p className="iconCalDateNumber">
                    {state.staffReq.duration > 0 || state.staffReq.zMins > 0
                      ? `${
                          state.staffReq.dateStringArray.length > 0
                            ? state.staffReq.dateStringArray[
                                state.staffReq.dateStringArray.length - 1
                              ].substr(9, 2)
                            : 0
                        }`
                      : 0}
                    <span
                      className={`${
                        state.staffReq.reqEndHalf ? "halfDayIconAppend" : "none"
                      }`}
                    >
                      1/2
                    </span>
                  </p>
                  <div className="iconBlob">
                    {formatMonthCalIcon(
                      state.staffReq.duration > 0 || state.staffReq.zMins > 0
                        ? `${
                            state.staffReq.dateStringArray.length > 0
                              ? state.staffReq.dateStringArray[
                                  state.staffReq.dateStringArray.length - 1
                                ].substr(6, 2)
                              : 0
                          }`
                        : "00"
                    )}
                  </div>
                </div>{" "}
              </div>
              {/* <div className="reqRateDivider"></div> */}
              <div
                className={`reqRate ${
                  state.staffReq.zMins <= 0 ? "x28838482" : ""
                }`}
              >
                <p
                  className={`reqRate0 ${
                    rate === 0 || !state.paid ? "x2398429842" : ""
                  }`}
                >
                  Rate
                </p>

                <p
                  className={`reqRate1 ${
                    rate === 0 || !state.paid ? "fontSize14Rate" : ""
                  }`}
                >
                  {rate === 0 || !state.paid ? "Unpaid" : `${rate}x`}
                </p>
              </div>
            </div>
          </div>
          {/* declining page - add decline reason note */}
          <div
            className={`${
              sureDeclinedReq
                ? `staffReqModalContentContainer ${
                    memoVals.device.ios
                      ? "staffReqModalContentContainerIos"
                      : ""
                  }`
                : "none"
            }`}
          >
            <div
              className={`declineReasonContainer ${
                memoVals.mobModal
                  ? `mobDeclineReasonContainer ${
                      state.workingPattern == "fixedScheduled" &&
                      state.staffReq.approved
                        ? "sureDeclinedReasonContainerWithReganModalMob"
                        : ""
                    }`
                  : ""
              }`}
            >
              <p className="declineReasonTitle">
                Reason{" "}
                <span className="declineReasonSpanOptional">(optional)</span>
              </p>
              <textarea
                className={`declineReasonInput ${
                  memoVals.mobModal ? "mobDeclinReasonInput" : ""
                }`}
                value={declineReason}
                type="textarea"
                onChange={(e) => {
                  setDeclineReason(e.target.value);
                }}
              ></textarea>
              {/* regenner */}
              <div
                className={`${
                  state.workingPattern === "fixedScheduled" &&
                  state.staffReq.approved
                    ? "regenSchedOnReq"
                    : "none"
                } ${
                  regen
                    ? "regenSchedContainer selectedRegenSchedContainer regenActiveOnReq"
                    : "regenSchedContainer"
                }
      
           ${unitIsUserReq ? "none" : ""} `}
                onClick={() => {
                  setRegen(!regen);
                }}
              >
                <p className="regenSchedBtnTxt">Re-generate schedule</p>
                {/*  KNOB BEGIN */}
                <div className="knobContainer editHalfKnobContainer">
                  <div
                    className={`switchKnob ${regen ? "knobOn" : "knobOff"}`}
                  ></div>

                  <div className="knobBg"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`${
              loadedFromTeamViewer ? "none" : "mgrRequestModalBottomBar"
            } `}
          ></div>
          <div
            className={`${
              loadedFromTeamViewer ? "loadedFromTeamViewerBottomBar" : "none"
            }`}
          ></div>
          <div
            className={`mgrReqestFooterHolder ${
              memoVals.mobModal ? "mgrReqestFooterHolderMob" : ""
            }`}
          >
            {" "}
            {!approveLoading ? (
              changeDecision ? (
                <div className="decideReqActionsDiv">
                  <img
                    src={returnArrow}
                    onClick={() => {
                      if (sureDeclinedReq) {
                        setSureDeclinedReq(false);
                      } else {
                        setChangeDecision(false);
                      }
                    }}
                    className="reqDecisionReturnArrImg"
                    alt="Back"
                  />

                  {!sureDeclinedReq && !state.staffReq.approved ? (
                    <p
                      className="approveClockBtn"
                      onClick={() => {
                        setApproveLoading(true);
                        setChanged(true);
                        updateParentIfChanged(true, false, true, false);

                        axios
                          .post(
                            `${serverURL}/approve-request`,
                            {
                              reqID: state.staffReq.reqID,
                              approvedBy: `${memoVals.checkUserState.fName} ${memoVals.checkUserState.lName}`,
                            },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              // setTimeout(() => {

                              memoVals.setIndicate((x) => {
                                return {
                                  show: true,
                                  message: `${state.leaveTypeName} approved`,
                                  colour: "green",
                                  duration: 4000,
                                };
                              });

                              setCountData({
                                leaveReqs: countData.leaveReqs - 1,
                              });
                              memoVals.setUpdateReq((x) => !memoVals.updateReq);
                              setState({ showReqMgrModal: false });
                              memoVals.setShowReqModalInViewer((x) => "");
                              setApproveLoading(false);
                              setChangeDecision(false);
                              memoVals.setOutstandingReqsQty(
                                (x) => response.data.outReqIDs
                              );
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }}
                    >
                      Approve{" "}
                      <img
                        src={whiteTick}
                        alt="Approve"
                        className="approveClockTickImg"
                      />
                    </p>
                  ) : (
                    ""
                  )}
                  {!state.staffReq.declined ? (
                    <p
                      className="declineClockBtn"
                      onClick={() => {
                        if (sureDeclinedReq) {
                          setSureDeclinedReq(false);
                          setChangeDecision(false);
                          setDeclineLoading(true);
                          setChanged(true);
                          updateParentIfChanged(true, false, false, true);
                          console.log("DELCINE HERE");

                          axios
                            .post(
                              `${serverURL}/decline-request`,
                              {
                                reqID: state.staffReq.reqID,
                                reason: declineReason,
                                edited: true,
                                declinedBy: `${memoVals.checkUserState.fName} ${memoVals.checkUserState.lName}`,
                                regen:
                                  state.workingPattern === "fixedScheduled"
                                    ? regen
                                    : false,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.status === 200) {
                                setCountData({
                                  leaveReqs: countData.leaveReqs - 1,
                                });
                                memoVals.setIndicate((x) => {
                                  return {
                                    show: true,
                                    message: `${state.leaveTypeName}
                               declined`,
                                    colour: "red",
                                    duration: 4000,
                                  };
                                });
                                memoVals.setUpdateReq(
                                  (x) => !memoVals.updateReq
                                );
                                setState({ showReqMgrModal: false });
                                memoVals.setShowReqModalInViewer((x) => "");
                                setDeclineLoading(false);
                                memoVals.setShowReqModalInViewer((x) => "");
                                setChangeDecision(false);

                                memoVals.setOutstandingReqsQty(
                                  (x) => response.data.outReqIDs
                                );
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        } else {
                          setSureDeclinedReq(true);
                        }
                      }}
                    >
                      Decline{" "}
                      {!sureDeclinedReq ? (
                        <img
                          src={crossWhite}
                          alt="Approve"
                          className="approveClockTickImg x34981"
                        />
                      ) : (
                        "request"
                      )}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                <div
                  className={`${
                    changeDecision ||
                    declineLoading ||
                    (state.isOwnReq &&
                      !state.allowSelfApprove &&
                      memoVals.checkUserState.permissions !== 1) ||
                    (state.staffReq.declineReason &&
                      state.staffReq.declineReason.includes("cancelled"))
                      ? "invis"
                      : `${
                          state.staffReq.approved || state.staffReq.declined
                            ? ""
                            : "changeDecisionButton mgrCtaColours"
                        }`
                  } ${
                    state.staffReq.approved || state.staffReq.declined
                      ? "editDecBg"
                      : ""
                  }`}
                  onClick={() => {
                    if (state.isAbsence || state.staffReq.addedByMgr) {
                      console.log("remove absence");
                      setSureDeleteAbsence(true);
                    } else {
                      console.log(
                        "isOwn:",
                        state.isOwnReq,
                        "allowSelfApprove: ",
                        state.allowSelfApprove,
                        "permissions: ",
                        memoVals.checkUserState.permissions
                      );
                      if (
                        (state.isOwnReq && state.allowSelfApprove) ||
                        memoVals.checkUserState.permissions == 1
                      ) {
                        setChangeDecision(true);
                      }
                      if (!state.isOwnReq) {
                        setChangeDecision(true);
                      }
                    }
                  }}
                >
                  {state.isAbsence || state.staffReq.addedByMgr
                    ? `Delete ${state.staffReq.isAbsence ? "absence" : "leave"}`
                    : !state.staffReq.approved && !state.staffReq.declined
                    ? "Respond"
                    : "Edit decision"}
                </div>
              )
            ) : (
              <img
                src={reqLoader}
                className={`${
                  approveLoading || declineLoading ? "reqLoadingImg" : "none"
                }`}
                alt="loading"
              />
            )}
            <p
              className={`closeReqInfo`}
              onClick={() => {
                if (loadedFromTeamViewer) {
                  memoVals.setShowReqModalInViewer((x) => "");
                } else {
                  memoVals.setShowReqMgrModal((x) => false);
                  setState({
                    startTime: "",
                    endTime: "",
                    showMgrReqModal: false,
                    totalExcessMins: 0,
                    showExcessMgr: false,
                  });
                  setOverridePreviousPayExcess(false);
                  setSureDeclinedReq(false);
                  setChangeDecision(false);
                  // updateParentIfChanged(false, false);
                }
              }}
            >
              Close
            </p>
          </div>
        </div>
      ) : (
        ""
      )}
      {/* {memoVals.showEditDateModal ? (
        <EditReqDatesModal
          unitReqID={state.reqID}
          unitUserID={state.reqUserID}
          unitWorkPattern={state.workingPattern}
          unitIsUserReq={unitIsUserReq}
          unitStartDs={state.staffReq.reqStart}
          unitEndDs={state.staffReq.reqEnd}
          setDatesChanged={setDatesChanged}
        />
      ) : (
        ""
      )} */}

      {memoVals.showViewTeamOnRequest && !loadedFromTeamViewer ? (
        <ViewTeamOnRequest reqID={state.reqID} isMgr={true} />
      ) : (
        ""
      )}

      {/* SURE DELETE ABSENCE MODAL */}
      {sureDeleteAbsence ? (
        <div
          className="overlapsRenewalModalUnderlay"
          onClick={() => {
            setSureDeleteAbsence(false);
          }}
        >
          <div
            className="overlapsRenewalModalBox"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {" "}
            <p className="overlapsRenTxt">
              Are you sure you want to delete {state.fName}'
              {state.fName[state.fName.length - 1] === "s" ? "" : "s"}{" "}
              {state.isAbsence ? "absence" : "leave"}?
              <br />
              <br />
              FlowRota will re-generate any shifts if {state.fName} is
              contracted for any.
            </p>
            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  axios
                    .post(
                      `${serverURL}/delete-req-and-regen`,
                      {
                        reqID: state.reqID || unitReqID,
                        nowDs: dateStringer.createStringFromTimestamp(
                          new Date().getTime()
                        ),
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      // remove any existing shifts
                      // add the new shifts
                      if (response.data.message === "success") {
                        memoVals.setDeletingReqID((x) => state.reqID);
                        // robert
                        if (state.staffReq.ongoing) {
                          setCountData({
                            ongoingAbs: countData.ongoingAbs - 1,
                          });
                        } else {
                          if (
                            !state.staffReq.approved &&
                            !state.staffReq.declined
                          ) {
                            setCountData({
                              leaveReqs: countData.leaveReqs - 1,
                            });
                          }
                        }
                        memoVals.setShowReqMgrModal((x) => false);
                        memoVals.setUpdateInfo((x) => !memoVals.updateInfo);
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Delete{" "}
              </p>{" "}
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setSureDeleteAbsence(false);
                }}
              >
                Cancel
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {/* END OF SURE DELETE ABSENCE MODAL */}
      {showNoteModal ? (
        <div
          className="reqNoteUnderlay"
          onClick={(e) => {
            e.stopPropagation();
            setShowNoteModal(false);
            setReqNote("");
          }}
        >
          <div
            className="reqNoteModalBox"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <p className="reqNoteBodyTxt">{reqNote}</p>
            <p
              className="closeReqNoteModalBtn"
              onClick={() => {
                setShowNoteModal(false);
              }}
            >
              Close
            </p>
          </div>
        </div>
      ) : (
        ""
      )}

      {showPreviousPayExcessModal ? (
        <div
          className="areYouSureModalUnderlay"
          onClick={(e) => {
            e.stopPropagation();
            setShowPreviousPayExcessModal(false);
          }}
        >
          <div
            className="formCreatedModal"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <p className="overlapsRenTxt">
              This request is in the past.{" "}
              {state.staffReq.fName || state.fNameSched} may have already been
              paid for this request.
              <br />
              <br />
              Changing this option may affect the total amount payable to{" "}
              {state.staffReq.fName || state.fNameSched} historically.
            </p>
            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  let today = new Date();
                  today.setHours(0, 0, 0, 0);
                  let todayDs = dateStringer.createStringFromTimestamp(
                    today.getTime()
                  );

                  setLoading(true);
                  if (showCostDiv) {
                    setShowCostDiv(false);
                  }
                  axios
                    .post(
                      `${serverURL}/edit-single-request-excess-type`,
                      {
                        reqID: state.staffReq.reqID,
                        todayDs: todayDs,
                        forceBeforeToday: forceBeforeToday,
                        data: !state.excessMgrPay ? "pay" : "null",
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        memoVals.setUpdateReq((x) => !memoVals.updateReq);
                        memoVals.setUpdateTabData(
                          (x) => !memoVals.updateTabData
                        );
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                  if (state.excessMgrPay === false) {
                    // you are changing to pay so make allow/null false
                    setState({
                      excessMgrAllow: false,
                    });
                  }
                  if (state.excessMgrPay === true) {
                  }
                  setState({ excessMgrPay: !state.excessMgrPay });

                  setOverridePreviousPayExcess(true);
                  setShowPreviousPayExcessModal(false);
                  setForceBeforeToday(true);
                }}
              >
                Proceed
              </p>
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setOverridePreviousPayExcess(true);
                  setShowPreviousPayExcessModal(false);
                  setForceBeforeToday(true);
                }}
              >
                Cancel
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {/*  */}
      {reqDoesNotExist ? (
        <div className="addAbsenceModalUnderlay">
          <div className="formCreatedModal">
            <p className="overlapsRenTxt">This period no longer exists.</p>

            <div className="areYouModalBtnsDiv">
              <p
                className="closeSwapsNoLongerBtn"
                onClick={() => {
                  setReqDoesNotExist(false);
                }}
              >
                Close
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {/* {showSelfApproveModal ? (
        <div
          className="addAbsenceModalUnderlay"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="formCreatedModal">
            <p className="overlapsRenTxt">
              According to your profile settings set by the FlowRota
              administrator, you are not allowed to approve, edit or decline
              your own requests.
              <br />
              <br />
              Another manager of your primary team or a system admin can do
              this.
            </p>
            <div className="areYouModalBtnsDiv">
              <p
                className="closeSwapsNoLongerBtn"
                onClick={() => {
                  setShowSelfApproveModal(false);
                }}
              >
                Understood
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )} */}
      {showCannotManageOwnShift.show ? (
        <div
          className="overlapsRenewalModalUnderlay"
          onClick={() => {
            memoVals.setShowReqMgrModal((x) => false);
          }}
        >
          <div
            className="overlapsRenewalModalBox"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {" "}
            <div className="overlapsRenTxt">
              You cannot manage or approve your own leave requests.
              <br />
              <br />
              Only another manager of your primary team (
              {showCannotManageOwnShift.teamName}) can handle your requests
              unless a FlowRota administrator grants you the permissions to
              self-manage them.
              <br />
              <br />
              For permissions, please speak to a FlowRota administrator.
              <br />
              <br />
              Share this{" "}
              <a
                className="makeBold cursorPointer x8499282"
                href="https://flowrota.com/support/self-approve-permissions"
                target="_blank"
              >
                guidance link
              </a>{" "}
              with them.
            </div>
            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  memoVals.setShowUserReqFromNav(
                    `${
                      showCannotManageOwnShift.isAbsence ? "*" : ""
                    }${unitReqID}`
                  );
                  memoVals.setShowReqMgrModal((x) => false);
                }}
              >
                View leave
              </p>{" "}
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  memoVals.setShowReqMgrModal((x) => false);
                }}
              >
                Close
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {imgPreload}
    </div>
  );
};

export default LeaveReqModal;

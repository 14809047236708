import React, { createContext, useState, useEffect, useReducer } from "react";
import axios from "axios";

export const DataContext = createContext();
// general mixed data context

const DataContextProvider = (props) => {
  // should the mobile navbar be hidden?
  let [hideNavbar, setHideNavbar] = useState(false);

  // underlayShow is a status of the shadow background. If true, show
  // CSS stored approx. line 30 os modals.css
  let [underlayShow, setUnderlayShow] = useState(false);

  // TeamAllowanceViews is on?
  // let [teamAllows, setTeamAllows] = useState(false);
  // let toggleTeamAllows = () => {
  //   setTeamAllows(!teamAllows);
  // };

  // Navbar selected f button
  // check conditionals in navbar.js to see what each number means
  let [selectedNav, setSelectedNav] = useState(1);
  let changeNavTo = (number) => {
    setSelectedNav(number);
  };

  // array of teams in the signup form
  let [signUpTeams, setSignUpTeams] = useState([]);

  // allowances in settings: show current, prev, future
  // previous: last year, 2: current, 3: next
  let [allowancesYr, setAllowancesYr] = useState("current");

  // on the user, edit working pattern
  let [editWorkPattern, setEditWorkPattern] = useState(false);

  // Add new special day info stored for POST request
  let today = new Date();
  let todayAt00 = today.setHours(0, 0, 0, 0);
  let [specialDayDate, setSpecialDayDate] = useState(todayAt00); // timestamp of midnight
  let [specialDayName, setSpecialDayName] = useState("");
  let [specialDayRate, setSpecialDayRate] = useState(1);
  let [specialDayCompanyClosed, setSpecialDayCompanyClosed] = useState(false);
  let [payClose, setPayClose] = useState(false);

  // list of all teams avaiable on the client
  let [allTeams, setAllTeams] = useState([]);

  // state for the array of users
  let [adminUsersArray, setAdminUsersArray] = useState([]);

  // show the edit user modal?
  let [showEditUser, setShowEditUser] = useState(false);
  let [showUserAdminSettingInput, setShowUserAdminSettingInput] =
    useState(false);

  // get list of teams and store it in context
  let [teamsList, setTeamsList] = useState([]);

  // selected user in admin users page, which one is highligheted
  let [selectedUser, setSelectedUser] = useState(0);

  let [teamlessUsersArray, setTeamlessUsersArray] = useState([]);

  // used in EditUser.js - store total sched mins in here
  let [fixedMinsTotal, setFixedMinsTotal] = useState(0);

  // requests page
  let [reqsPage, setReqsPage] = useState("upcoming");
  let [showTeamReqSettings, setShowTeamReqSettings] = useState();

  let [delayReqs, setDelayReqs] = useState(false); // delays the requests loading on the requests page to give Express a change to format the new annual leave request upon submission
  // error code for ErrorModal
  let [errorCode, setErrorCode] = useState();
  let [showErrorModal, setShowErrorModal] = useState(false);

  // update sched after a change in editUser modal is made
  let [updateSched, setUpdateSched] = useState(false);

  // EDIT USER MODAL (admin Users.js)

  // - - - -

  // - - - -
  // mon
  let [editMstaH, setEditMstaH] = useState();
  let [editMstaM, setEditMstaM] = useState();
  let [editMendH, setEditMendH] = useState();
  let [editMendM, setEditMendM] = useState();
  let [editMbrk, setEditMbrk] = useState();
  // tue
  let [editTstaH, setEditTstaH] = useState();
  let [editTstaM, setEditTstaM] = useState();
  let [editTendH, setEditTendH] = useState();
  let [editTendM, setEditTendM] = useState();
  let [editTbrk, setEditTbrk] = useState();

  // wed
  let [editWstaH, setEditWstaH] = useState();
  let [editWstaM, setEditWstaM] = useState();
  let [editWendH, setEditWendH] = useState();
  let [editWendM, setEditWendM] = useState();
  let [editWbrk, setEditWbrk] = useState();

  // thu
  let [editTHstaH, setEditTHstaH] = useState();
  let [editTHstaM, setEditTHstaM] = useState();
  let [editTHendH, setEditTHendH] = useState();
  let [editTHendM, setEditTHendM] = useState();
  let [editTHbrk, setEditTHbrk] = useState();

  // fri
  let [editFstaH, setEditFstaH] = useState();
  let [editFstaM, setEditFstaM] = useState();
  let [editFendH, setEditFendH] = useState();
  let [editFendM, setEditFendM] = useState();
  let [editFbrk, setEditFbrk] = useState();

  // sat
  let [editSstaH, setEditSstaH] = useState();
  let [editSstaM, setEditSstaM] = useState();
  let [editSendH, setEditSendH] = useState();
  let [editSendM, setEditSendM] = useState();
  let [editSbrk, setEditSbrk] = useState();

  // sun
  let [editSUstaH, setEditSUstaH] = useState();
  let [editSUstaM, setEditSUstaM] = useState();
  let [editSUendH, setEditSUendH] = useState();
  let [editSUendM, setEditSUendM] = useState();
  let [editSUbrk, setEditSUbrk] = useState();

  // state for stats get request data
  let [totUsrs, setTotUsrs] = useState(0);
  let [costPerMonth, setCostPerMonth] = useState(0);
  let [workforceAbsence, setWorkforceAbsence] = useState(0);
  let [yrReset, setYrReset] = useState("D01M01");

  // Admin Teams data
  let [selectedEditTeam, setSelecteEditTeam] = useState();
  let [showEditTeam, setShowEditTeam] = useState(false);
  let [teamLoading, setTeamLoading] = useState(false);
  let [editTeamID, setEditTeamID] = useState();
  let [editTeamName, setEditTeamName] = useState();
  let [editTeamUsers, setEditTeamUsers] = useState([]);
  let [editTeamMgrs, setEditTeamMgrs] = useState([]);
  // show modal for specific user
  let [showSpecificUser, setShowSpecificUser] = useState(false);
  let [specificUser, setSpecificUser] = useState();
  let [specificFName, setSpecificFName] = useState();
  let [specificLName, setSpecificLName] = useState();
  let [specificIsMgr, setSpecificIsMgr] = useState();
  let [specificIsUsr, setSpecificIsUsr] = useState();

  let [adminTeams, setAdminTeams] = useState([]);

  let [isTeamNew, setIsTeamNew] = useState(false);

  // admins
  let [adminsArray, setAdminsArray] = useState([]);

  // data of requests on LeaveReqsPage

  let [mgrTeams, setMgrTeams] = useState([]);
  let [selectedTeam, setSelectedTeam] = useState("allstaff");
  let [teamHasPendingReqs, setTeamHasPendings] = useState([]);
  let [teamHasDecidedReqs, setTeamHasDecidedReqs] = useState([]);

  // for use when adding new user, determining if user needs pro-rating
  let [yearStart, setYearStart] = useState("D01M01");
  let [currYrStartTs, setCurrYrStartTs] = useState(0);
  let [beyondYearStart, setBeyondYearStart] = useState();

  // admin shifts - store the rate of each day, so when an add-shift modal appears, depending on the day of the chosen date, the rate will be set
  let [dayRates, setDayRates] = useState([
    { day: "sun", rate: 1 },
    { day: "mon", rate: 1 },
    { day: "tue", rate: 1 },
    { day: "wed", rate: 1 },
    { day: "thu", rate: 1 },
    { day: "fri", rate: 1 },
    { day: "sat", rate: 1 },
  ]);

  // edit user admin bank

  // on the req's page
  let [showRecentReqNoteModal, setShowRecentReqNoteModal] = useState(false);
  let [recentReqID, setRecentReqID] = useState("");
  let [updateRequests, setUpdateRequests] = useState(false);
  //
  //
  //
  //
  // ADD SHIFT MODAL - - - - - - - - - - - - - - - - - -
  let [showAddShiftModal, setShowAddShiftModal] = useState(false);
  let [availableTags, setAvailableTags] = useState([]);
  let [showAvailableTagsModal, setShowAvailableTagsModal] = useState(false);
  let [selectedShiftTeam, setSelectedShiftTeam] = useState("allStaff");
  let [availableTeamsForShifts, setAvailableTeamsForShifts] = useState([]);
  let [tags, setTags] = useState([]);
  let [addShiftTags, setAddShiftTags] = useState([]);
  let [shiftTeam, setShiftTeam] = useState();
  let [shiftPublicNote, setShiftPublicNote] = useState();
  let [shiftMgrNote, setShiftMgrNote] = useState();
  let [defaultStart, setDefaultStart] = useState("");
  let [defaultEnd, setDefaultEnd] = useState("");
  let [defaultBrk, setDefaultBrk] = useState("");
  let [defaultRate, setDefaultRate] = useState(1);
  let [showMgrNoteInput, setShowMgrInput] = useState(false);
  let [showPublicNoteInput, setShowPublicNoteInput] = useState(false);
  let [showEditShiftModal, setShowEditShiftModal] = useState(false);

  let [showAddShift, setShowAddShift] = useState(false);
  let [addShiftDate, setAddShiftDate] = useState();
  // let [addShiftStart, setAddShiftStart] = useState();
  let [addShiftEnd, setAddShiftEnd] = useState();
  // let [addShiftBreak, setAddShiftBreak] = useState();

  // let [shiftTeamID, setShiftTeamID] = useState("");

  let [startEndTimeArray, setStartEndTimeArray] = useState([]);

  let [addShiftRate, setAddShiftRate] = useState(1);
  let [addShiftPage, setAddShiftPage] = useState(1);
  // 0: choose userID, 1: date and times, 2: note & team

  let [teamsUserIsPartOf, setTeamsUserIsPartOf] = useState([]);
  let [primaryTeamIDDefault, setPrimaryTeamIDDefault] = useState();

  let [shiftTemplates, setShiftTemplates] = useState([]);
  let [showShiftTemplateModal, setShowShiftTemplateModal] = useState(false);

  let [showShiftModalTeamName, setShowShiftModalTeamName] = useState("");

  let [dateIsBeforeUserStarted, setDateIsBeforeUserStarted] = useState(false);

  let [halfDaysOff, setHalfDaysOff] = useState([]); // array of datestrings
  let [fullDaysOff, setFullDaysOff] = useState([]);

  let [userHasHalfDayOff, setUserHasHalfDayOff] = useState(false);
  let [userHasFullDayOff, setUserHasFullDayOff] = useState(false);

  let [shiftCost, setShiftCost] = useState(0);

  let [shiftIsBank, setShiftIsBank] = useState(false);
  let [bankWarning, setBankWarning] = useState(false);

  let [isGhost, setIsGhost] = useState(false); // actual on the shift
  let [ghost, setGhost] = useState(false);

  let [unpaidAbsent, setUnpaidAbsent] = useState(false);
  let [partialAbsent, setPartialAbsent] = useState(false);
  let [editPartialTimes1, setEditPartialTimes1] = useState(false);
  let [editPartialTimes2, setEditPartialTimes2] = useState(false);

  let [editPartialStart, setEditPartialStart] = useState();
  let [editPartialEnd, setEditPartialEnd] = useState();
  let [editPartialStartLocal, setEditPartialStartLocal] = useState("00:00");
  let [editPartialEndLocal, setEditPartialEndLocal] = useState("23:59");

  // min and max absence start and ends
  let [maxPartialStart, setMaxPartialStart] = useState(0);
  let [maxPartialEnd, setMaxPartialEnd] = useState(0);
  let [minPartialStart, setMinPartialStart] = useState(0);
  let [minPartialEnd, setMinPartialEnd] = useState(0);

  let [partialAbsenceMins, setPartialAbsenceMins] = useState(0);

  let [showGhostOption, setShowGhostOption] = useState(false);

  let [overriddenGhostShifts, setOverriddenGhostShifts] = useState([]);

  let [editShiftShiftID, setEditShiftShiftID] = useState();
  let [editShiftStart, setEditShiftStart] = useState();
  let [editShiftEnd, setEditShiftEnd] = useState();
  let [editShiftBrk, setEditShiftBrk] = useState();
  let [editShiftRate, setEditShiftRate] = useState(1);
  let [editShiftWfh, setEditShiftWfh] = useState();
  let [editShiftSwappable, setEditShiftSwappable] = useState();
  let [editShiftAbsent, setEditShiftAbsent] = useState();
  let [shiftDurationMins, setShiftDurationMins] = useState(0);
  let [editShiftWorked, setEditShiftWorked] = useState();
  let [editShiftMidnightTimestamp, setEditShiftMidnightTimestamp] = useState();

  let [editedShiftDate, setEditedShiftDate] = useState("");
  let [sureDeleteShift, setSureDeleteShift] = useState(false);

  let [dontAccrue, setDontAccrue] = useState(false);

  let [assocAbsenceID, setAssocAbsenceID] = useState("");
  let [assocAbsenceName, setAssocAbsenceName] = useState("");

  let [showEditBrk, setShowEditBrk] = useState(false);
  let [breakPurgatory, setBreakPurgatory] = useState(0);

  let [showEditTimes, setShowEditTimes] = useState(false);
  let [startPurgatory, setStartPurgatory] = useState("");
  let [endPurgatory, setEndPurgatory] = useState("");
  let [startPurgatorySubmit, setStartPurgatorySubmit] = useState("");
  let [endPurgatorySubmit, setEndPurgatorySubmit] = useState("");
  let [midnightTimestampPurgatory, setMidnightTimestampPurgatory] = useState();

  let [showDatepicker, setShowDatepicker] = useState(false);

  let [editPublicNote, setEditPublicNote] = useState(false);
  let [editPublicNoteValue, setEditPublicNoteValue] = useState("");
  let [addingNewTag, setAddingNewTag] = useState(false);

  let [newTagValue, setNewTagValue] = useState("");

  let [addShiftOrigin, setAddShiftOrigin] = useState("onUser");

  let [monthSelectValue, setMonthSelectValue] = useState("");

  // do not delete when cleaning:
  let [updateShifts, setUpdateShifts] = useState(false);
  // END OF ADD SHIFT MODAL - - - - - - - - - - - - - - -
  //
  //
  //
  //
  //
  //
  // ADD TIL MODAL - - - - - - - - - - - - - - -
  let [tilOrOvertime, setTilOrOvertime] = useState("overtime");

  let [showAddTilModal, setShowAddTilModal] = useState(false);
  let [addTilUserID, setAddTilUserID] = useState("");
  let [addTilSelf, setAddTilSelf] = useState("");
  let [addTilTeam, setAddTilTeam] = useState("");
  let [addTilPage, setAddTilPage] = useState(1);
  let [addTilUserKnown, setAddTilUserKnown] = useState(true);
  // END OF ADD TIL MODAL - - - - - - - - - - - - - - -

  let [highlightEdit, setHighlightEdit] = useState(false);
  let [updateShiftsOnceItemAdded, setUpdateShiftsOnceItemAdded] =
    useState(false);

  let [changesMadeToWorkingPattern, setChangesMadeToWorkingPattern] =
    useState(false);

  let [openChangesModal, setOpenChangesModal] = useState(false);
  let [teamSchedGen, setTeamSchedGen] = useState(0);
  let [schedGenteamName, setSchedGenTeamName] = useState("");

  // edituser modal
  let [mpwTimelineConflicts, setMpwTimelineConflicts] = useState(false);

  let [changesMade, setChangesMade] = useState(false);
  let [changesMadeVar, setChangesMadeVar] = useState(false);
  let [changesMadeVar2, setChangesMadeVar2] = useState(false);

  let [updateShiftReqs, setUpdateShiftReqs] = useState(false);

  let [update2, setUpdate2] = useState(false);

  let [showAddAvailModal, setShowAddAvailModal] = useState(false);

  let [showUserAbsenceModal, setShowUserAbsenceModal] = useState({}); // {absenceID, isMgr}

  let [showUserReqFromNav, setShowUserReqFromNav] = useState(""); // contains reqID
  let [updateUserReqs, setUpdateUserReqs] = useState(false);
  let [updateMgrReqs, setUpdateMgrReqs] = useState(false); //
  let [updateMgrReqSingleReqID, setUpdateMgrReqSingleReqID] = useState("");
  let [loadUserReqOnlyOnceMade, setLoadUserReqOnceMade] = useState("");
  // show ManagerShift modal in navbar
  // -- stores the shiftID
  let [openManagerShiftModal, setOpenManagerShiftModal] = useState({});

  let [updateSchedWithSingleItem, setUpdateSchedWithSingleItem] = useState({});
  let [showReqModalInViewer, setShowReqModalInViewer] = useState("");

  let [updateOpen, setUpdateOpen] = useState({}); // if an open item is updated, to update the data in team sched without having to reload the team-schedule data
  let [showAddTilModalTeamSched, setShowAddTilModalTeamSched] = useState(false);
  let [showAddShiftTeamSched, setShowAddShiftTeamSched] = useState(false);
  let [addShiftTilPassBackToTeamSched, setAddShiftTilPassBackToTeamSched] =
    useState(""); // ds
  let [indicate, setIndicate] = useState({
    show: false,
    message: "",
    colour: "",
    duration: 0,
  });

  let [updateMgrAbsences, setUpdateMgrAbsences] = useState(false);

  let [showMyShiftModal, setShowMyShiftModal] = useState({});
  // type: typeID

  let [selectedDatePeriod, setSelectedDatePeriod] = useState("");

  let [forceDeleteShiftOrTil, setForceDeleteShiftOrTil] = useState("");
  let [dontAddNewItemToRota, setDontAddNewItemToRota] = useState(false);

  let [showMpwTimeline, setShowMpwTimeline] = useState(false);
  // when a new shift/til is added, saved, and then deleted, it still shows in the team rota. store the shift/til ID in here, so the team sched doesn't render it
  let [showClockOnModal, setShowClockOnModal] = useState(false);
  let [myAbsencePage, setMyAbsencePage] = useState("absence"); // absence or stats
  let [settingsAllowances, setSettingsAlowances] = useState("profile"); // preview toggle (change back to allowances when done)

  let [page, setPage] = useState("");
  let [availableMonths, setAvailableMonths] = useState([]);
  // let [my, setMy] = useState("0000");
  let [hoursDataLoading, setHoursDataLoading] = useState(true);
  let [sideBoxData, setSideBoxData] = useState({
    totalMins: 0,
    regularMins: 0,
    overtimeMins: 0,
    tilEarned: 0,
    tilTaken: 0,
    absenceMins: 0,
    leaveMins: 0,
    totalEarned: 0,
  });
  let [showStats, setShowStats] = useState(false);
  let [clockOnID, setClockOnID] = useState("");
  let [showUnclocked, setShowUnclocked] = useState(false);

  let [deletedClockOnID, setDeletedClockOnID] = useState("");
  let [runTopBar, setRunTopBar] = useState(false);
  let [unclockedQty, setUnclockedQty] = useState(0);

  let [loadUnclocks, setLoadUnclocks] = useState(false);

  let [reloadManagerShift, setReloadManagerShift] = useState(false);
  let [reloadTeamClockOns, setReloadTeamClockOns] = useState(false);
  let [changedClockCard, setChangedClockCard] = useState({});
  let [loadSingleUserSched, setLoadSingleUserSched] = useState("");
  let [changedMidnightTimestamp, setChangedMidnightTimestamp] = useState(false);

  let [editReqClockOut, setEditReqClockout] = useState(false);

  let [activeClocksQty, setActiveClocksQty] = useState(0);
  let [userIsClockedOn, setUserIsClockedOn] = useState(false);
  let [userIsOnClockedBreak, setUserIsOnClockedBreak] = useState(false);
  let [updateHeaderClocks, setUpdateHeaderClocks] = useState(false);
  let [showActiveClocks, setShowActiveClocks] = useState(false);
  let [msgQty, setMsgQty] = useState(0);
  let [showMessages, setShowMessages] = useState(false);

  let [showReqStats, setShowReqStats] = useState(false);

  let [updatedReq, setUpdatedReq] = useState({});

  let [showReqMgrModal, setShowReqMgrModal] = useState(false);
  let [allowsUpdated, setAllowsUpdated] = useState(false);

  let [showUserOpenShiftModal, setShowUserOpenShiftModal] = useState("");

  let [showMgrAvailModal, setShowMgrAvailModal] = useState(false);
  let [showInstallModal, setShowInstallModal] = useState(false);
  let [updatedOpenShift, setUpdatedOpenShift] = useState({
    openID: "",
    bidUserIDs: [],
  });
  let [updateTabData, setUpdateTabData] = useState(false); // in edit user - if mgr shift modal is updated
  let [countMyChanges, setCountMyChanges] = useState(0);

  let [shiftIDNoLongerExists, setShiftIDNoLongerExists] = useState({
    userID: "",
    itemID: "",
  });

  let [device, setDevice] = useState({
    device: "mobile",
    fullScreen: false,
    browser: "chrome",
    vhIssue: false,
  });

  let [seeUserID, setSeeUserID] = useState({
    userID: "",
    openedFromUserRota: false,
  });

  let [swap, setSwap] = useState({ shiftID: "", type: "" });
  let [showSwapsModal, setShowSwapsModal] = useState(false);
  let [allowSwaps, setAllowSwaps] = useState(false);
  let [loadUser, setLoadUser] = useState({});

  let [selectedName, setSelectedName] = useState("allNames");
  let [selectedDay, setSelectedDay] = useState("allDays");

  let [inboundSwapsQty, setInboundSwapsQty] = useState(0);
  let [outboundSwapsQty, setOutboundSwapsQty] = useState(0);

  let [showTeamSwapsModal, setShowTeamSwapsModal] = useState(false);
  let [teamSwapsQty, setTeamSwapsQty] = useState(0);
  // myShift modal object for storing if user has requested a swap on shift
  let [iWant, setIWant] = useState({});

  let [reloadMySwaps, setReloadMySwaps] = useState(false);

  let [showDayCommentModal, setShowDayCommentModal] = useState({
    show: false,
    isMgr: false,
    ds: "",
    teamID: "",
  });

  let [amendDayComment, setAmendDayComment] = useState({
    minusOne: false,
    plusOne: false,
    ds: "",
  });

  // dynamically swap the shifts on the rota when a swap is initiated
  let [dynamicSwap, setDynamicSwap] = useState({
    getRidOfTypeIDs: [],
    newItem1: {},
    newItem2: {},
  });

  // if myShift updates to have a note or swap, update userRota
  let [dynamicSwapOrNote, setDynanicSwapOrNote] = useState({
    typeID: "",
    swapOrNote: "swap / note",
    note: false,
    swap: false,
  });

  // manager requests number notifier in the desktop nav menu
  let [outstandingReqsQty, setOutstandingReqsQty] = useState(0);
  let [staffHoursQty, setStaffHoursQty] = useState(0);

  let [showAddLeaveModal, setShowAddLeaveModal] = useState({
    show: false,
    startDs: "",
    userID: "",
    leaveTypeID: "annLeave",
    fName: "",
    reqID: "", // if editing existing one
    editing: false,
    editStartDs: "",
    editEndDs: "",
    ongoing: false,
    toEndOngoing: false,
    isAbsence: false,
  });

  let [showContact, setShowContact] = useState(false);

  let [absencePage, setAbsencePage] = useState("shifts"); // periods or shifts
  let [showCreateFormModal, setShowCreateFormModal] = useState(false);

  let [showForm, setShowForm] = useState({ isMgr: false, formID: "" });

  // dynamically add newly created form to parent components
  let [pushForm, setPushForm] = useState({
    isMgr: false,
    fName: "",
    lName: "",
    formID: "",
    questionsLength: 0,
    responsesLength: 0,
    title: "",
    itemsLength: 0,
    completedDs: "",
    createdDs: "",
    filledIn: false,
    signedByMgr: false,
    mins: 0,
    deleting: false,
    updating: false,
    creating: false,
  });

  let [showChanges, setShowChanges] = useState(false);

  let [removeMyChangeEditID, setRemoveMyChangeEditID] = useState("");

  let [deletingReqID, setDeletingReqID] = useState("");

  let [calendarPage, setCalendarPage] = useState("");

  let [showViewTeamDayModal, setShowViewTeamDayModal] = useState(false);
  let [showClosedDaysHistory, setShowClosedDaysHistory] = useState(false);
  let [absentQty, setAbsentQty] = useState(0);

  let [viewZoneModal, setViewZoneModal] = useState(false);
  let [addZoneModal, setAddZoneModal] = useState(false);
  let [gpsArray, setGpsArray] = useState([]);

  let [showAddUserModal, setShowAddUserModal] = useState(false);

  let [addNewUserDynamic, setAddNewUserDynamic] = useState(false); // if active, will be an object of a newly created user, used to update the Users (admin) page dynamically, without reloading users

  let [updateSingleUserInUsers, setUpdateSingleUserInUsers] = useState({
    userID: "",
    removeFromUsers: false,
    removeFromDisabled: false,
  });
  let [employeeCount, setEmployeeCount] = useState(0);
  let [showAddLeaveOnTeam, setShowAddLeaveOnTeam] = useState(false);
  let [showViewTeamModal, setShowViewTeamModal] = useState("");
  let [showAddTeamModal, setShowAddTeamModal] = useState(false);
  let [updateTeam, setUpdateTeam] = useState(false); // will hold an object of a team to to update in adminTeans
  let [teamCount, setTeamCount] = useState(0);
  let [locationsCount, setLocationsCount] = useState(0);
  let [updateUserAdmin, setUpdateUserAdmin] = useState({});
  let [showTimesheet, setShowTimesheet] = useState("");
  let [updateCalAvail, setUpdateCalAvail] = useState(false);

  let [openShiftConvert, setOpenShiftConvert] = useState({}); // dynamically updates team rota is open shift is converted to user, or user's shift is converted to open
  let [selectedDesktop, setSelectedDesktop] = useState("calendar");
  let [showViewTimesheet, setShowViewTimesheet] = useState({
    show: false,
    timesheetID: "",
    isMgr: true,
  });
  let [addTagToAllUserShifts, setAddtagToAllUserShifts] = useState({
    userID: "",
    tag: "",
  });

  let [showViewUserTeamModal, setShowViewUserTeamModal] = useState(""); // to be a teamID
  let [updateUserTimesheets, setUpdateUserTimesheets] = useState(false);

  // let [teamHoursSideBoxData, setTeamHoursSideBoxData] = useReducer(
  //   (state, newState) => ({ ...state, ...newState }),
  //   {
  //     months: [],
  //     staff: [],
  //     teamName: "",
  //     loading: true,
  //     my: `${
  //       new Date().getMonth() < 10 ? "0" : ""
  //     }${new Date().getMonth()}${JSON.stringify(
  //       new Date().getFullYear()
  //     ).substr(2, 2)}`,
  //   }
  // );

  // in the topbar, active clocks:
  let [upcomingRequiringClocks, setUpcomingRequiringClocks] = useState(0);

  let [reloadTs, setReloadTs] = useState(false);
  let [indicating, setIndicating] = useState(false);

  let [usersUserCanMange, setUserUserCanMange] = useState([]);
  let [updateUnclocked, setUpdateUnclocked] = useState(false);
  let [updateAccount, setUpdateAccount] = useState(false);
  let [tilReqMade, setTilReqMade] = useState(false);

  let [goToLimits, setGoToLimits] = useState(false);
  // toggled when viewing limit calculator from desktop navbar menu button

  let [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  let [showSpecialDayModal, setShowSpecialDayModal] = useState({ show: false });
  let [sureRemoveSpecialDay, setSureRemoveSpecialDay] = useState("");
  let [sureDeleteSpecialDay, setSureDeleteSpecialDay] = useState("");

  let [updatedSpecDay, setUpdatedSpecDay] = useState({ data: false });
  let [showLeaveTypeModal, setShowLeaveTypeModal] = useState({ show: false });
  let [sureRemoveLeaveType, setSureRemoveLeaveType] = useState("");
  let [showAbsenceTypeModal, setShowAbsenceTypeModal] = useState({
    show: false,
  });
  let [sureRemoveAbsenceType, setSureRemoveAbsenceType] = useState("");
  let [unreadNotifs, setUnreadNotifs] = useState(0);
  let [showNotifications, setShowNotifications] = useState(false);

  let [itemType, setItemType] = useState("");
  let [itemID, setItemID] = useState("");

  let [updateCalComments, setUpdateCalComments] = useState(false);
  let [standalone, setStandalone] = useState(false);

  let [notYetAccepted, setNotYetAccepted] = useState(0);
  let [showYetToAcceptOnly, setShowYetToAcceptOnly] = useState(false);
  let [acceptedShiftID, setAcceptedShiftID] = useState("");
  let [declinedShiftID, setDeclinedShiftID] = useState("");
  let [mgrHasAcceptTeams, setMgrHasAcceptTeams] = useState(false);
  let [acceptDecline, setAcceptDecline] = useState({
    accepted: 0,
    declined: 0,
  });

  let [showMgrAcceptanceModal, setShowMgrAcceptanceModal] = useState(false);
  let [showMobMenu, setShowMobMenu] = useState(false);

  let [udpateMyRota, setUpdateMyRota] = useState(false);

  let [amendReqSent, setAmendReqSent] = useState(false);

  let [reqIDToLoadWhenClosingEditUser, setReqIDToLoadWhenClosingEditUser] =
    useState("");
  let [showUpdatedReqsModal, setShowUpdatedReqsModal] = useState([]);

  let [updatedHighlightedComment, setUpdatedHighlightedComment] =
    useState(false);
  let [updatedDashData, setUpdatedDashData] = useState(false);
  let [noPermissions, setNoPermissions] = useState(false);

  let [updateSingleUserAllowances, setUpdateSingleUserAllowances] =
    useState("");

  let [showConvertToilModal, setShowConvertToilModal] = useState(""); // is userID
  let [showAddAbsenceModal, setShowAddAbsenceModal] = useState(false);
  // this is a userID of a user who is having their allowances changed - it will force axios to request allownaces for this user in DashBoardAllowances.js -- this user only, rather than load all others

  let [showMyDocsModal, setShowMyDocsModal] = useState(false);

  let [showOpenShiftsModal, setShowOpenShiftsModal] = useState(false);
  let [showOpenShiftsMgrModal, setShowOpenShiftsMgrModal] = useState(false);
  let [updateOpensModal, setUpdateOpensModal] = useState(false);

  let [removeLoggedShiftID, setRemoveLoggedShiftID] = useState("");
  let [showChecklist, setShowChecklist] = useState(false);
  let [modalOpen, setModalOpen] = useState(false);
  let [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
  let [showTrialExpiredModal, setShowTrialExpiredModal] = useState(false);
  let [createNewTimesheet, setCreateNewTimesheet] = useState(false);
  let [showUserPayRollModal, setShowUserPayRollModal] = useState({
    show: false,
    userID: "",
    startDs: "",
    endDs: "",
  });

  let [teamHoursSideBoxData, setTeamHoursSideBoxData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      months: [],
      staff: [],
      teamName: "",
      loading: true,
      my: `${
        new Date().getMonth() < 10 ? "0" : ""
      }${new Date().getMonth()}${JSON.stringify(
        new Date().getFullYear()
      ).substr(2, 2)}`,
    }
  );

  let [mobRotaMenuOpen, setMobRotaMenuOpen] = useState(false);

  let [addOpenShift, setAddOpenShift] = useState(false);
  let [viewAbsenceUserTimeline, setViewAbsenceUserTimeline] = useState({
    show: false,
  });

  let [countData, setCountData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      online: 0,
      // user
      inboundSwaps: 0,
      outboundSwaps: 0,
      shiftAccepts: 0,
      outstandingChanges: 0,
      openShifts: 0,
      userForms: 0,
      userLeave: 0,
      userTimesheets: 0,
      unverified: false,
      // mgr
      accepted: 0,
      declined: 0,
      staffSwaps: 0,
      leaveReqs: 0,
      forms: 0,
      ongoingAbs: 0,
      ttrs: 0,
      shiftEdits: 0,
      drops: 0,
      clocks: 0,
      timesheets: 0,
      loggedShifts: 0,
      shiftAcceptsOutstandingMgr: 0,
      selfLoggedAbsence: 0,
      unclocked: 0,
      currClocked: 0,
      upcomingRequiringClocks: 0,
      openShiftsMgr: 0,
    }
  );

  let [showNotifReqs, setShowNotifReqs] = useState(false);

  let [approvedTsId, setApprovedTsId] = useState("");
  let [approvedCCId, setApprovedCCId] = useState("");
  let [removeDeclinedItemID, setRemoveDeclinedItemID] = useState("");
  let [removeSelfLoggedAbsenceItem, setRemoveSelfLoggedAbsenceItem] =
    useState("");

  let [showDeclinedShiftModal, setShowDeclinedMShiftModal] = useState("");
  let [showUserHoursModal, setShowUserHoursModal] = useState({
    show: false,
    userID: "",
    monWeek: "",
    fromEditUser: false,
    fromShift: false,
  });

  let [showScheduledTodayModal, setShowScheduledTodayModal] = useState({
    show: false,
    teamID: "",
    ds: "",
  });

  let [updateAdminLocations, setUpdateAdminLocations] = useState(false);
  let [openShiftEdited, setOpenShiftEdited] = useState(false);
  // master

  return (
    <DataContext.Provider
      value={{
        openShiftEdited,
        setOpenShiftEdited,
        updateAdminLocations,
        setUpdateAdminLocations,
        locationsCount,
        setLocationsCount,
        showScheduledTodayModal,
        setShowScheduledTodayModal,
        showUserHoursModal,
        setShowUserHoursModal,
        removeSelfLoggedAbsenceItem,
        setRemoveSelfLoggedAbsenceItem,
        removeDeclinedItemID,
        setRemoveDeclinedItemID,
        showDeclinedShiftModal,
        setShowDeclinedMShiftModal,

        approvedCCId,
        setApprovedCCId,
        approvedTsId,
        setApprovedTsId,
        showNotifReqs,
        setShowNotifReqs,
        countData,
        setCountData,
        viewAbsenceUserTimeline,
        setViewAbsenceUserTimeline,
        addOpenShift,
        setAddOpenShift,
        mobRotaMenuOpen,
        setMobRotaMenuOpen,
        removeLoggedShiftID,
        setRemoveLoggedShiftID,
        teamHoursSideBoxData,
        setTeamHoursSideBoxData,
        showUserPayRollModal,
        setShowUserPayRollModal,
        createNewTimesheet,
        setCreateNewTimesheet,
        showTrialExpiredModal,
        setShowTrialExpiredModal,
        showDeleteAccountModal,
        setShowDeleteAccountModal,
        modalOpen,
        setModalOpen,
        showChecklist,
        setShowChecklist,
        updateOpensModal,
        setUpdateOpensModal,
        showOpenShiftsModal,
        setShowOpenShiftsModal,
        showOpenShiftsMgrModal,
        setShowOpenShiftsMgrModal,
        showMyDocsModal,
        setShowMyDocsModal,
        showAddAbsenceModal,
        setShowAddAbsenceModal,
        showConvertToilModal,
        setShowConvertToilModal,
        updateSingleUserAllowances,
        setUpdateSingleUserAllowances,
        noPermissions,
        setNoPermissions,
        updatedDashData,
        setUpdatedDashData,
        updatedHighlightedComment,
        setUpdatedHighlightedComment,
        showUpdatedReqsModal,
        setShowUpdatedReqsModal,
        reqIDToLoadWhenClosingEditUser,
        setReqIDToLoadWhenClosingEditUser,
        updateTabData,
        setUpdateTabData,
        amendReqSent,
        setAmendReqSent,
        udpateMyRota,
        setUpdateMyRota,
        showMobMenu,
        setShowMobMenu,
        showMgrAcceptanceModal,
        setShowMgrAcceptanceModal,
        acceptDecline,
        setAcceptDecline,
        mgrHasAcceptTeams,
        setMgrHasAcceptTeams,
        declinedShiftID,
        setDeclinedShiftID,
        acceptedShiftID,
        setAcceptedShiftID,
        showYetToAcceptOnly,
        setShowYetToAcceptOnly,
        notYetAccepted,
        setNotYetAccepted,
        standalone,
        setStandalone,
        updateCalComments,
        setUpdateCalComments,
        itemType,
        setItemType,
        itemID,
        setItemID,
        showNotifications,
        setShowNotifications,
        unreadNotifs,
        setUnreadNotifs,
        sureRemoveAbsenceType,
        setSureRemoveAbsenceType,
        showAbsenceTypeModal,
        setShowAbsenceTypeModal,
        sureRemoveLeaveType,
        setSureRemoveLeaveType,
        showLeaveTypeModal,
        setShowLeaveTypeModal,
        updatedSpecDay,
        setUpdatedSpecDay,
        sureDeleteSpecialDay,
        setSureDeleteSpecialDay,
        sureRemoveSpecialDay,
        setSureRemoveSpecialDay,
        showSpecialDayModal,
        setShowSpecialDayModal,
        selectedYear,
        setSelectedYear,
        goToLimits,
        setGoToLimits,
        tilReqMade,
        setTilReqMade,
        updateUnclocked,
        setUpdateUnclocked,
        updateAccount,
        setUpdateAccount,
        usersUserCanMange,
        setUserUserCanMange,
        indicating,
        setIndicating,
        reloadTs,
        setReloadTs,
        upcomingRequiringClocks,
        setUpcomingRequiringClocks,
        // teamHoursSideBoxData,
        // setTeamHoursSideBoxData,
        updateUserTimesheets,
        setUpdateUserTimesheets,
        showViewUserTeamModal,
        setShowViewUserTeamModal,
        addTagToAllUserShifts,
        setAddtagToAllUserShifts,
        showViewTimesheet,
        setShowViewTimesheet,
        selectedDesktop,
        setSelectedDesktop,
        openShiftConvert,
        setOpenShiftConvert,
        updateCalAvail,
        setUpdateCalAvail,
        showTimesheet,
        setShowTimesheet,
        updateUserAdmin,
        setUpdateUserAdmin,
        teamCount,
        setTeamCount,
        updateTeam,
        setUpdateTeam,
        showAddTeamModal,
        setShowAddTeamModal,
        showViewTeamModal,
        setShowViewTeamModal,
        showAddLeaveOnTeam,
        setShowAddLeaveOnTeam,
        employeeCount,
        setEmployeeCount,
        updateSingleUserInUsers,
        setUpdateSingleUserInUsers,
        addNewUserDynamic,
        setAddNewUserDynamic,
        showAddUserModal,
        setShowAddUserModal,
        gpsArray,
        setGpsArray,
        viewZoneModal,
        setViewZoneModal,
        addZoneModal,
        setAddZoneModal,
        userIsOnClockedBreak,
        setUserIsOnClockedBreak,
        absentQty,
        setAbsentQty,
        showClosedDaysHistory,
        setShowClosedDaysHistory,
        showViewTeamDayModal,
        setShowViewTeamDayModal,
        calendarPage,
        setCalendarPage,
        deletingReqID,
        setDeletingReqID,
        removeMyChangeEditID,
        setRemoveMyChangeEditID,
        showChanges,
        setShowChanges,
        showContact,
        setShowContact,
        pushForm,
        setPushForm,
        showForm,
        setShowForm,
        showCreateFormModal,
        setShowCreateFormModal,
        absencePage,
        setAbsencePage,
        showAddLeaveModal,
        setShowAddLeaveModal,
        staffHoursQty,
        setStaffHoursQty,
        outstandingReqsQty,
        setOutstandingReqsQty,
        countMyChanges,
        setCountMyChanges,
        updatedOpenShift,
        setUpdatedOpenShift,
        showUserOpenShiftModal,
        setShowUserOpenShiftModal,
        shiftIDNoLongerExists,
        setShiftIDNoLongerExists,
        dynamicSwapOrNote,
        setDynanicSwapOrNote,
        dynamicSwap,
        setDynamicSwap,
        reloadMySwaps,
        setReloadMySwaps,
        amendDayComment,
        setAmendDayComment,
        showDayCommentModal,
        setShowDayCommentModal,
        teamSwapsQty,
        setTeamSwapsQty,
        showTeamSwapsModal,
        setShowTeamSwapsModal,
        iWant,
        setIWant,
        inboundSwapsQty,
        setInboundSwapsQty,
        outboundSwapsQty,
        setOutboundSwapsQty,
        selectedName,
        setSelectedName,
        selectedDay,
        setSelectedDay,
        loadUser,
        setLoadUser,
        allowSwaps,
        setAllowSwaps,
        showSwapsModal,
        setShowSwapsModal,
        swap,
        setSwap,
        seeUserID,
        setSeeUserID,
        device,
        setDevice,
        showInstallModal,
        setShowInstallModal,
        showMgrAvailModal,
        setShowMgrAvailModal,
        // editUserState,
        // setEditUserState,
        allowsUpdated,
        setAllowsUpdated,
        showReqMgrModal,
        setShowReqMgrModal,
        updatedReq,
        setUpdatedReq,
        showReqStats,
        setShowReqStats,
        msgQty,
        setMsgQty,
        showMessages,
        setShowMessages,
        showActiveClocks,
        setShowActiveClocks,
        updateHeaderClocks,
        setUpdateHeaderClocks,
        userIsClockedOn,
        setUserIsClockedOn,

        activeClocksQty,
        setActiveClocksQty,

        editReqClockOut,
        setEditReqClockout,

        changedMidnightTimestamp,
        setChangedMidnightTimestamp,
        loadSingleUserSched,
        setLoadSingleUserSched,
        changedClockCard,
        setChangedClockCard,
        reloadTeamClockOns,
        setReloadTeamClockOns,
        reloadManagerShift,
        setReloadManagerShift,
        unclockedQty,
        setUnclockedQty,
        loadUnclocks,
        setLoadUnclocks,
        runTopBar,
        setRunTopBar,
        showUnclocked,
        setShowUnclocked,
        deletedClockOnID,
        setDeletedClockOnID,
        clockOnID,
        setClockOnID,
        showStats,
        setShowStats,
        sideBoxData,
        setSideBoxData,
        hoursDataLoading,
        setHoursDataLoading,
        // my,
        // setMy,
        availableMonths,
        setAvailableMonths,
        settingsAllowances,
        setSettingsAlowances,
        myAbsencePage,
        setMyAbsencePage,
        page,
        setPage,
        showClockOnModal,
        setShowClockOnModal,
        showMpwTimeline,
        setShowMpwTimeline,
        dontAddNewItemToRota,
        setDontAddNewItemToRota,
        forceDeleteShiftOrTil,
        setForceDeleteShiftOrTil,
        updateSched,
        setUpdateSched,

        showReqModalInViewer,
        setShowReqModalInViewer,
        selectedDatePeriod,
        setSelectedDatePeriod,
        showMyShiftModal,
        setShowMyShiftModal,
        updateMgrAbsences,
        setUpdateMgrAbsences,
        addShiftTilPassBackToTeamSched,
        setAddShiftTilPassBackToTeamSched,
        showAddTilModalTeamSched,
        setShowAddTilModalTeamSched,
        showAddShiftTeamSched,
        setShowAddShiftTeamSched,
        showUserAbsenceModal,
        setShowUserAbsenceModal,
        updateOpen,
        setUpdateOpen,
        updateSchedWithSingleItem,
        setUpdateSchedWithSingleItem,
        indicate,
        setIndicate,
        loadUserReqOnlyOnceMade,
        setLoadUserReqOnceMade,
        updateMgrReqSingleReqID,
        setUpdateMgrReqSingleReqID,
        updateMgrReqs,
        setUpdateMgrReqs,
        updateUserReqs,
        setUpdateUserReqs,
        showUserReqFromNav,
        setShowUserReqFromNav,
        openManagerShiftModal,
        setOpenManagerShiftModal,
        showAddAvailModal,
        setShowAddAvailModal,

        changesMade,
        setChangesMade,

        changesMadeVar2,
        setChangesMadeVar2,
        changesMadeVar,
        setChangesMadeVar,
        update2,
        setUpdate2,
        updateShiftReqs,
        setUpdateShiftReqs,
        mpwTimelineConflicts,
        setMpwTimelineConflicts,
        openChangesModal,
        setOpenChangesModal,
        teamSchedGen,
        setTeamSchedGen,
        schedGenteamName,
        setSchedGenTeamName,
        changesMadeToWorkingPattern,
        setChangesMadeToWorkingPattern,
        updateShifts,
        setUpdateShifts,
        updateShiftsOnceItemAdded,
        setUpdateShiftsOnceItemAdded,
        addTilPage,
        setAddTilPage,
        showAddTilModal,
        setShowAddTilModal,
        addTilUserID,
        setAddTilUserID,
        addTilSelf,
        setAddTilSelf,
        addTilTeam,
        setAddTilTeam,
        addTilUserKnown,
        setAddTilUserKnown,
        tilOrOvertime,
        setTilOrOvertime,
        // add til values

        editWorkPattern,
        setEditWorkPattern,
        highlightEdit,
        setHighlightEdit,
        // end of add til values
        // add shift values
        monthSelectValue,
        setMonthSelectValue,
        addShiftOrigin,
        setAddShiftOrigin,
        availableTags,
        setAvailableTags,
        showAvailableTagsModal,
        setShowAvailableTagsModal,
        selectedShiftTeam,
        setSelectedShiftTeam,
        availableTeamsForShifts,
        setAvailableTeamsForShifts,
        tags,
        setTags,
        addShiftTags,
        setAddShiftTags,
        shiftTeam,
        setShiftTeam,
        shiftPublicNote,
        setShiftPublicNote,
        shiftMgrNote,
        setShiftMgrNote,
        defaultStart,
        setDefaultStart,
        defaultEnd,
        setDefaultEnd,
        defaultBrk,
        setDefaultBrk,
        defaultRate,
        setDefaultRate,
        showMgrNoteInput,
        setShowMgrInput,
        showPublicNoteInput,
        setShowPublicNoteInput,
        showEditShiftModal,
        setShowEditShiftModal,
        showAddShift,
        setShowAddShift,
        addShiftDate,
        setAddShiftDate,
        // addShiftStart,
        // setAddShiftStart,
        // addShiftEnd,
        // setAddShiftEnd,
        // addShiftBreak,
        // setAddShiftBreak,
        // shiftTeamID,
        // setShiftTeamID,
        startEndTimeArray,
        setStartEndTimeArray,
        addShiftRate,
        setAddShiftRate,
        addShiftPage,
        setAddShiftPage,
        teamsUserIsPartOf,
        setTeamsUserIsPartOf,
        primaryTeamIDDefault,
        setPrimaryTeamIDDefault,
        shiftTemplates,
        setShiftTemplates,
        showShiftTemplateModal,
        setShowShiftTemplateModal,
        showShiftModalTeamName,
        setShowShiftModalTeamName,
        dateIsBeforeUserStarted,
        setDateIsBeforeUserStarted,
        halfDaysOff,
        setHalfDaysOff,
        fullDaysOff,
        setFullDaysOff,
        userHasHalfDayOff,
        setUserHasHalfDayOff,
        userHasFullDayOff,
        setUserHasFullDayOff,
        shiftCost,
        setShiftCost,
        shiftIsBank,
        setShiftIsBank,
        bankWarning,
        setBankWarning,
        isGhost,
        setIsGhost,
        ghost,
        setGhost,
        unpaidAbsent,
        setUnpaidAbsent,
        partialAbsent,
        setPartialAbsent,
        editPartialTimes1,
        setEditPartialTimes1,
        editPartialTimes2,
        setEditPartialTimes2,
        editPartialStart,
        setEditPartialStart,
        editPartialEnd,
        setEditPartialEnd,
        editPartialStartLocal,
        setEditPartialStartLocal,
        editPartialEndLocal,
        setEditPartialEndLocal,
        maxPartialStart,
        setMaxPartialStart,
        maxPartialEnd,
        setMaxPartialEnd,
        minPartialStart,
        setMinPartialStart,
        minPartialEnd,
        setMinPartialEnd,
        partialAbsenceMins,
        setPartialAbsenceMins,
        showGhostOption,
        setShowGhostOption,
        overriddenGhostShifts,
        setOverriddenGhostShifts,
        editShiftShiftID,
        setEditShiftShiftID,
        editShiftStart,
        setEditShiftStart,
        editShiftEnd,
        setEditShiftEnd,
        editShiftBrk,
        setEditShiftBrk,
        editShiftRate,
        setEditShiftRate,
        editShiftWfh,
        setEditShiftWfh,
        editShiftSwappable,
        setEditShiftSwappable,
        editShiftAbsent,
        setEditShiftAbsent,
        shiftDurationMins,
        setShiftDurationMins,
        editShiftWorked,
        setEditShiftWorked,
        editShiftMidnightTimestamp,
        setEditShiftMidnightTimestamp,
        editedShiftDate,
        setEditedShiftDate,
        sureDeleteShift,
        setSureDeleteShift,
        dontAccrue,
        setDontAccrue,
        assocAbsenceID,
        setAssocAbsenceID,
        assocAbsenceName,
        setAssocAbsenceName,
        showEditBrk,
        setShowEditBrk,
        breakPurgatory,
        setBreakPurgatory,
        showEditTimes,
        setShowEditTimes,
        startPurgatory,
        setStartPurgatory,
        endPurgatory,
        setEndPurgatory,
        startPurgatorySubmit,
        setStartPurgatorySubmit,
        endPurgatorySubmit,
        setEndPurgatorySubmit,
        showDatepicker,
        setShowDatepicker,
        midnightTimestampPurgatory,
        setMidnightTimestampPurgatory,
        editPublicNote,
        setEditPublicNote,
        editPublicNoteValue,
        setEditPublicNoteValue,
        addingNewTag,
        setAddingNewTag,
        newTagValue,
        setNewTagValue,

        // end of add shift values
        underlayShow,
        setUnderlayShow,
        selectedNav,
        setSelectedNav,
        changeNavTo,

        hideNavbar,
        setHideNavbar,
        signUpTeams,
        setSignUpTeams,

        allowancesYr,
        setAllowancesYr,

        specialDayDate,
        setSpecialDayDate,
        specialDayName,
        setSpecialDayName,
        specialDayRate,
        setSpecialDayRate,
        specialDayCompanyClosed,
        setSpecialDayCompanyClosed,
        payClose,
        setPayClose,
        allTeams,
        setAllTeams,
        selectedUser,
        setSelectedUser,
        adminUsersArray,
        setAdminUsersArray,
        teamsList,
        setTeamsList,

        showEditUser,
        setShowEditUser,

        editMstaH,
        setEditMstaH,
        editMstaM,
        setEditMstaM,
        editMendH,
        setEditMendH,
        editMendM,
        setEditMendM,
        editMbrk,
        setEditMbrk,
        editTstaH,
        setEditTstaH,
        editTstaM,
        setEditTstaM,
        editTendH,
        setEditTendH,
        editTendM,
        setEditTendM,
        editTbrk,
        setEditTbrk,
        editWstaH,
        setEditWstaH,
        editWstaM,
        setEditWstaM,
        editWendH,
        setEditWendH,
        editWendM,
        setEditWendM,
        editWbrk,
        setEditWbrk,
        editTHstaH,
        setEditTHstaH,
        editTHstaM,
        setEditTHstaM,
        editTHendH,
        setEditTHendH,
        editTHendM,
        setEditTHendM,
        editTHbrk,
        setEditTHbrk,
        editFstaH,
        setEditFstaH,
        editFstaM,
        setEditFstaM,
        editFendH,
        setEditFendH,
        editFendM,
        setEditFendM,
        editFbrk,
        setEditFbrk,
        editSstaH,
        setEditSstaH,
        editSstaM,
        setEditSstaM,
        editSendH,
        setEditSendH,
        editSendM,
        setEditSendM,
        editSbrk,
        setEditSbrk,
        editSUstaH,
        setEditSUstaH,
        editSUstaM,
        setEditSUstaM,
        editSUendH,
        setEditSUendH,
        editSUendM,
        setEditSUendM,
        editSUbrk,
        setEditSUbrk,
        totUsrs,
        setTotUsrs,
        costPerMonth,
        setCostPerMonth,
        workforceAbsence,
        setWorkforceAbsence,
        yrReset,
        setYrReset,
        showUserAdminSettingInput,
        setShowUserAdminSettingInput,
        fixedMinsTotal,
        setFixedMinsTotal,

        showErrorModal,
        setShowErrorModal,
        errorCode,
        setErrorCode,
        selectedEditTeam,
        setSelecteEditTeam,
        showEditTeam,
        setShowEditTeam,
        teamLoading,
        setTeamLoading,
        editTeamID,
        setEditTeamID,
        editTeamName,
        setEditTeamName,
        editTeamUsers,
        setEditTeamUsers,
        editTeamMgrs,
        setEditTeamMgrs,
        showSpecificUser,
        setShowSpecificUser,
        specificUser,
        setSpecificUser,
        specificFName,
        setSpecificFName,
        specificLName,
        setSpecificLName,
        specificIsMgr,
        setSpecificIsMgr,
        specificIsUsr,
        setSpecificIsUsr,
        adminTeams,
        setAdminTeams,
        isTeamNew,
        setIsTeamNew,
        teamlessUsersArray,
        setTeamlessUsersArray,
        adminsArray,
        setAdminsArray,
        reqsPage,
        setReqsPage,
        delayReqs,
        setDelayReqs,

        mgrTeams,
        setMgrTeams,
        teamHasPendingReqs,
        setTeamHasPendings,
        teamHasDecidedReqs,
        setTeamHasDecidedReqs,
        selectedTeam,
        setSelectedTeam,
        showTeamReqSettings,
        setShowTeamReqSettings,
        yearStart,
        setYearStart,
        beyondYearStart,
        setBeyondYearStart,
        currYrStartTs,
        setCurrYrStartTs,

        dayRates,
        setDayRates,

        showRecentReqNoteModal,
        setShowRecentReqNoteModal,
        recentReqID,
        setRecentReqID,
        updateRequests,
        setUpdateRequests,
        showAddShiftModal,
        setShowAddShiftModal,
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
};

export default DataContextProvider;

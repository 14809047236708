import React, {
  useState,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useMemo,
} from "react";
// cleaned wef 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios done
import axios from "axios";

import { StyleContext } from "../contexts/StyleContext";
import { DataContext } from "../contexts/DataContext";
import { UserContext } from "../contexts/UserContext";

import dateStringer from "../tools/dateStringer";
import serverURL from "../serverURL";
import popup2 from "../img/general/popup2.svg";

import navyClose from "../img/general/navyClose.svg";
import greyEdit from "../img/general/editGrey.svg";
import whiteTick from "../img/general/whiteTick.svg";
import returnArrow from "../img/general/returnArrow.svg";
import crossWhite from "../img/general/crossWhite.svg";
import whitebin from "../img/general/whitebin.svg";
import editReq from "../img/general/editReq.svg";
import editNavy from "../img/general/editNavy.svg";
import navyTick from "../img/general/navyTick.svg";
import rotaTag from "../img/general/rotaTag.svg";
import pin from "../img/general/pin.svg";
import gps1 from "../img/general/gps1.svg";
import gps2 from "../img/general/gps2.svg";
import gps3 from "../img/general/gps3.svg";
import allowArr from "../img/general/allowArr.svg";
import rotaDur from "../img/general/rotaDur.svg";
import tick from "../img/general/checkboxInstall4.svg";
import cross from "../img/general/thickCrossNotif.svg";
import pin00aaff from "../img/general/pin00aaff.svg";
import tag00aaff from "../img/general/tag00aaff.svg";
import rotaDur00aaff from "../img/general/rotaDur00aaff.svg";
import rotaZzz from "../img/general/rotaZzz.svg";
import tick0077ff from "../img/general/tick0077ff.svg";
import tickBa0000 from "../img/general/tickBa0000.svg";
import navyAdd from "../img/general/navyAdd.svg";

import popup from "../img/general/popupNavy.svg";
import popupNavy from "../img/general/popupNavy.svg";

import profilePicturePlaceholder from "../img/general/profilePicturePlaceholder.svg";

import horizontalBalls from "../img/loaders/horizontalBalls.svg";

import "../styles/calendar.css";

const ViewTimesheetModal = ({ mgr }) => {
  let [isMgr, setIsMgr] = useState(true);
  const {
    showViewTimesheet,
    setShowViewTimesheet,
    setIndicate,
    setShowTimesheet,
    showMyShiftModal,
    setShowMyShiftModal,
    updateUserTimesheets,
    setUpdateUserTimesheets,
    reloadTs,
    setClockOnID,
    setOpenManagerShiftModal,
    device,
    setShowEditUser,
    updatedDashData,
    setUpdatedDashData,
    modalOpen,
    setModalOpen,
    showEditUser,
    countData,
    setCountData,
    approvedTsId,
    setApprovedTsId,
    approvedCCId,
    setApprovedCCId,
    setShowUserHoursModal,
  } = useContext(DataContext);
  const { currencySymbol } = useContext(UserContext);

  const memoVals = useMemo(
    () => ({
      showViewTimesheet, //
      setShowViewTimesheet, //
      setIndicate, //
      setShowTimesheet, //
      showMyShiftModal, //
      setShowMyShiftModal, //
      updateUserTimesheets, //
      setUpdateUserTimesheets, //
      reloadTs, //
      setClockOnID, //
      setOpenManagerShiftModal, //
      device, //
      setShowEditUser, //
      updatedDashData, //
      setUpdatedDashData, //
      modalOpen,
      setModalOpen,
      showEditUser,
      setShowUserHoursModal,
    }),
    [
      showViewTimesheet, //
      setShowViewTimesheet, //
      setIndicate, //
      setShowTimesheet, //
      showMyShiftModal, //
      setShowMyShiftModal, //
      updateUserTimesheets, //
      setUpdateUserTimesheets, //
      reloadTs, //
      setClockOnID, //
      setOpenManagerShiftModal, //
      device, //
      setShowEditUser, //
      updatedDashData, //
      setUpdatedDashData, //
      modalOpen,
      setModalOpen,
      showEditUser,
      setShowUserHoursModal,
    ]
  );

  useEffect(() => {
    memoVals.setModalOpen((x) => true);

    return () => {
      memoVals.setModalOpen((x) => false);
    };
  }, [memoVals.setModalOpen]);

  useEffect(() => {
    memoVals.setShowTimesheet((x) => false);
  }, []);

  let [showGpsArr, setShowGpsArr] = useState([]);

  let [imagesLoaded, setImagesLoaded] = useState(0);
  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if (dataLoaded && imagesLoaded === 25) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);

  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={navyClose}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={navyAdd}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={tick0077ff}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={tickBa0000}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaDur00aaff}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={tag00aaff}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={pin00aaff}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={cross}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaDur}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={popup2}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={allowArr}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={gps1}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={gps2}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={gps3}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={navyTick}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={popupNavy}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={profilePicturePlaceholder}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={popup}
        alt="2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={editNavy}
        alt="2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={greyEdit}
        alt="2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whiteTick}
        alt="3"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={returnArrow}
        alt="4"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={crossWhite}
        alt="5"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whitebin}
        alt="6"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={editReq}
        alt="7"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  const { mob, mobModal } = useContext(StyleContext);
  let [availableTags, setAvailableTags] = useState([]);
  let [dataEdited, setDataEdited] = useState(false);
  let [editDecision, setEditDecision] = useState(false);
  let [sureDelete, setSureDelete] = useState(false);
  let [sureDeleteMgr, setSureDeleteMgr] = useState(false);

  let offset = (actual, target, isBreak) => {
    if (isBreak) {
      if (actual < target) {
        const diff = dateStringer.formatMinsDurationToHours(
          actual - target,
          true
        );
        return mob || !mob ? (
          <>
            {diff} <span className="triSize">↓</span>
          </>
        ) : (
          `${diff} short`
        );
      }
      if (target < actual) {
        const diff = dateStringer.formatMinsDurationToHours(
          target - actual,
          true
        );
        return mob || !mob ? (
          <>
            {diff} <span className="triSize">↑</span>
          </>
        ) : (
          `${diff} over`
        );
      }
    }

    let actualDateObj = new Date(
      dateStringer.createTimestampFromString(actual)
    );
    let targetDateObj = new Date(
      dateStringer.createTimestampFromString(target)
    );

    if (actualDateObj.getTime() < targetDateObj.getTime()) {
      let minsDiff = Math.round(
        (targetDateObj.getTime() - actualDateObj.getTime()) / 1000 / 60
      );
      const diff = dateStringer.formatMinsDurationToHours(minsDiff, true);
      return mob ? (
        <>
          {diff} <span className="triSize">↓</span>
        </>
      ) : (
        `${diff} early`
      );
    }
    if (actualDateObj.getTime() > targetDateObj.getTime()) {
      let minsDiff = Math.round(
        (actualDateObj.getTime() - targetDateObj.getTime()) / 1000 / 60
      );
      const diff = dateStringer.formatMinsDurationToHours(minsDiff, true);
      return mob ? (
        <>
          {diff} <span className="triSize">↑</span>
        </>
      ) : (
        <>
          {diff} <span className="triSize">↑</span>
        </>
      );
    }
  };

  let [showSureChangeFocus, setShowSureChangeFocus] = useState(false);
  let [submitted, setSubmitted] = useState("");
  let [sureExit, setSureExit] = useState(false);
  let mobileWidthBreak = 820;
  mobileWidthBreak = 99999999999999;
  const [width, setWidth] = useState(window.innerWidth);
  let [editingEntry, setEditingEntry] = useState("");

  let [headerData, setHeaderData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      startDs: "",
      endDs: "",
      teamName: "",
      approved: false,
      declined: false,
      decidedBy: "",
      decidedDs: "",
      declinedReason: "",
    }
  );

  let [fName, setFName] = useState("");
  let [lName, setLName] = useState("");
  let [showAddTagToRecordItem, setShowAddTagToRecordedItem] = useState("");

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  //   let [changesMade, setChangesMade] = useState(false);

  useEffect(() => {
    // cleaned
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  let [dsArr, setDsArr] = useState([]);

  let calcTotMins = () => {
    let mins = 0;
  };

  let [reload, setReload] = useState(false);
  let [profPicUrl, setProfPicUrl] = useState("");
  let [locations, setLocations] = useState([]);

  let [showCannotManageOwnShift, setShowCannotManageOwnShift] = useState(false);
  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    if (memoVals.showViewTimesheet.timesheetID) {
      axios
        .post(
          `${serverURL}/open-timesheet`,
          {
            timesheetID: memoVals.showViewTimesheet.timesheetID, // "new" is new one needs to be made
            isMgr: isMgr,
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message === "cannot manage own timesheet") {
            setShowCannotManageOwnShift(true);
            setHeaderData({ teamName: response.data.teamName });
          } else {
            if (response.data.message === "success") {
              if (payClockedLoading) {
                setTimeout(() => {
                  setPayClockedLoading(false);
                }, 400);
              }
              setProfPicUrl(response.data.profPicUrl);
              setLocations(response.data.locations || []);
              setAvailableTags(response.data.availableTags);
              setHeaderData({
                startDs: response.data.startDs,
                fName: response.data.fName,
                endDs: response.data.endDs,
                teamName: response.data.teamName,
                approved: response.data.approved,
                declined: response.data.declined,
                decidedBy: response.data.decidedByName,
                decidedDs: response.data.decidedDs,
                userID: response.data.userID,
              });
              if (!response.data.output[0]) {
                setDsArr([{ ds: "", items: [], clocks: [], scheduled: [] }]);
              } else {
                setDsArr(response.data.output || []);
                let clocks = 0;
                let sched = 0;
                response.data.output.forEach((dsObj) => {
                  if (dsObj.clocks) {
                    dsObj.clocks.forEach((c) => {
                      clocks++;
                    });
                  }

                  if (dsObj.scheduled) {
                    dsObj.scheduled.forEach((c) => {
                      sched++;
                    });
                  }
                });
              }
              setSubmitted(response.data.submitted || "");
              if (isMgr) {
                setFName(response.data.fName);
                setLName(response.data.lName);
              }
              setDataLoaded(true);
            } else {
              setPayClockedLoading(false);
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [reload, memoVals.reloadTs]);

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 30) {
      memoVals.setShowViewTimesheet((x) => {
        return { show: false };
      });
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  let [payClockedLoading, setPayClockedLoading] = useState(false);

  let calcDurMins = (start, end, brk) => {
    // start and end to be 00:00
    if (start && end) {
      let startTs = dateStringer.HHMMToMsToday(start);
      let endTs = dateStringer.HHMMToMsToday(end);
      return (endTs - startTs) / 1000 / 60 - brk || 0;
    }
  };

  let countMins = () => {
    let mins = 0;
    dsArr.forEach((ds) => {
      // uncomment:

      if (ds.clocks && Array.isArray(ds.clocks)) {
        ds.clocks.forEach((clock) => {
          mins += clock.payableDurMins;
        });
      }
      if (ds.scheduled && Array.isArray(ds.scheduled)) {
        ds.scheduled.forEach((sched) => {
          let startObj = new Date(
            dateStringer.createTimestampFromString(sched.startDs)
          );
          let endObj = new Date(
            dateStringer.createTimestampFromString(sched.endDs)
          );
          let grossMs = endObj.getTime() - startObj.getTime();
          if (sched.brkMins && typeof sched.brkMins === "number") {
            grossMs -= sched.brkMins * 60 * 1000;
          }
          if (sched.loggedExcess) {
            grossMs += sched.loggedExcess * 60 * 1000;
          }
          mins += grossMs / 1000 / 60;
        });
      }
      // uncomment:

      if (ds.items && Array.isArray(ds.items)) {
        ds.items.forEach((item) => {
          mins += item.payableDurMins;
        });
      }
    });

    return mins;
  };

  // master return
  return (
    <div
      className={`bulkEditLimitUnderlay ${
        memoVals.showMyShiftModal.unitID || memoVals.showEditUser ? "none" : ""
      }`}
      onClick={() => {
        memoVals.setShowViewTimesheet((x) => {
          return { show: false };
        });
      }}
    >
      {/*        */}
      {/*        */}
      {/*        */}
      {/*        */}
      {/*        */}
      <div
        className={`availModalBox ${
          mobModal || memoVals.device.vhIssue ? "x13892843" : ""
        } ${
          mobModal
            ? `availModalBoxMob mobModalShoulder ${
                memoVals.device.ios ? "paddingBottom20" : ""
              }`
            : "timesheetModalBoxDesktop"
        } timesheetModalBox ${isMgr ? "mgrModal" : ""} ${
          loading ? "none" : ""
        }`}
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={modalPosition}
        ref={modalRef}
      >
        {mobModal && !loading && dsArr[0] && (
          <div
            className="modalSwiper modalSwiperViewTs"
            onTouchStart={handleDown}
            onTouchMove={mobModal ? handleMove : null}
            onTouchEnd={mobModal ? handleUp : null}
            onMouseDown={mobModal ? handleDown : null}
            onMouseMove={mobModal ? handleMove : null}
            onMouseUp={mobModal ? handleUp : null}
          ></div>
        )}
        {!loading && dsArr[0] ? (
          <div
            className="bulkEditLimitModalHeader"
            onTouchStart={handleDown}
            onTouchMove={mobModal ? handleMove : null}
            onTouchEnd={mobModal ? handleUp : null}
            onMouseDown={mobModal ? handleDown : null}
            onMouseMove={mobModal ? handleMove : null}
            onMouseUp={mobModal ? handleUp : null}
          >
            <img
              src={navyClose}
              alt="Close"
              className="closeBulkEditLimitModalIcon"
              onClick={() => {
                memoVals.setShowViewTimesheet((x) => {
                  return { show: false };
                });
              }}
            />
            <p
              className={`bulkEditLimitModalHeaderTitle ${
                loading ? "none" : ""
              }`}
              onClick={() => {
                if (mob) {
                  memoVals.setShowViewTimesheet((x) => {
                    return { show: false };
                  });
                }
              }}
            >
              {isMgr
                ? `${dateStringer.possession(fName)} timesheet`
                : `Submitted timesheet`}
            </p>
            <img
              src={navyClose}
              alt="Close"
              className="closeBulkEditLimitModalIcon bulkEditCloseBlank"
            />{" "}
          </div>
        ) : (
          ""
        )}
        <div className="tsHeaderBox">
          {isMgr ? (
            <div className="tsHeaderRow">
              <p className="tsHeaderRowTitle">Employee</p>
              <p className="tsHeaderRowValue">
                {fName} {lName}{" "}
                <img
                  src={profPicUrl || profilePicturePlaceholder}
                  alt={headerData.fName}
                  className="tsHeaderPPimg"
                />{" "}
                {/* <img
                src={popupNavy}
                className="tsHeaderEmpPopupImg"
                alt="Employee"
              /> */}
              </p>
            </div>
          ) : (
            ""
          )}
          <div className="tsHeaderRow">
            <p className="tsHeaderRowTitle">Period</p>
            <p className="tsHeaderRowValue">
              {" "}
              {dateStringer.createDatePeriod(
                headerData.startDs,
                headerData.endDs,
                true
              )}
            </p>
          </div>{" "}
          <div className="tsHeaderRow">
            <p className="tsHeaderRowTitle">Team</p>
            <p className="tsHeaderRowValue">
              {dateStringer.shorten(headerData.teamName, 25)}
            </p>
          </div>
        </div>
        <div className="tsHeaderKey">
          {" "}
          <div className="tsRowHeader">
            <p className="tsRowHeaderNull"></p>
            <p className="tsRowHeaderStartEnd">Start</p>
            <p className="tsRowHeaderStartEnd">End</p>
            <p className="tsRowHeaderStartEnd">Break</p>
            <p className="tsRowHeaderStartEnd">Info</p>
          </div>
        </div>
        <div className="timesheetBody whiteBg">
          {/* content here */}
          {dsArr.map((item, i) => {
            // let recordedExist = false;
            // let filt = item.items.filter((xx) => {
            //   return !xx.ghostTitle;
            // });
            // if (filt[0]) {
            //   recordedExist = true;
            // }

            return (
              <div
                className={`tsDsRow ${
                  i - 1 === dsArr.length ? "noBorder" : ""
                } ${i === 0 ? "tsDsRowFirst" : ""} ${
                  i === dsArr.length - 1 ? "noBorder" : ""
                }`}
                key={i}
              >
                <p className="tsRowHeaderNull">
                  {dateStringer.getThreeDayLettersFromDs(item.ds)}
                  <br />
                  {dateStringer.printedDateWithYrFromDs(
                    item.ds,
                    false,
                    true,
                    false,
                    true
                  )}
                </p>
                <div className="tsRightDataBlock">
                  {[...item.clocks, ...item.scheduled, ...item.items].length ===
                  0 ? (
                    <p className="noTsItemsTxt">No hours recorded</p>
                  ) : (
                    ""
                  )}{" "}
                  {[...item.clocks, ...item.scheduled, ...item.items].map(
                    (x, ind) => {
                      if (x.masterType === "clock") {
                        let clock = x;

                        return (
                          <div key={ind} className="overflow">
                            <div
                              className={`tsTopRow ${
                                headerData.approved
                                  ? "tsTopRowApp"
                                  : headerData.declined
                                  ? "tsTopRowDec"
                                  : ""
                              } ${ind === 0 ? "noTopBorder" : ""}`}
                            >
                              Clocked{" "}
                              {clock.type === "til"
                                ? "overtime (in lieu)"
                                : clock.type === "shift"
                                ? "shift"
                                : "overtime"}{" "}
                              {!clock.approved && !clock.declined ? (
                                <p className="tsTopRowAppStatus">
                                  - not yet approved
                                </p>
                              ) : (
                                ""
                              )}
                              {clock.approved && !clock.declined ? (
                                <p className="tsTopRowAppStatus txtColourGreen_">
                                  -{" "}
                                  {!headerData.approved && !headerData.declined
                                    ? "already"
                                    : ""}{" "}
                                  approved
                                </p>
                              ) : (
                                ""
                              )}
                              {!clock.approved && clock.declined ? (
                                <p className="tsTopRowAppStatus txtColourRed">
                                  -{" "}
                                  {!headerData.approved && !headerData.declined
                                    ? "already"
                                    : ""}{" "}
                                  declined
                                </p>
                              ) : (
                                ""
                              )}
                            </div>

                            {/* <div className="tsSchedClockedTxtTitles"> */}
                            <p
                              className={`schedClockedTs ${
                                mob ? "schedClockedTsMob" : ""
                              }`}
                            >
                              Clocked
                            </p>
                            {/* </div> */}

                            <div className="tsRightDataBlockUpper">
                              <div className="tsRightBlock paddingTop14">
                                {" "}
                                {dateStringer.dsToTimeStrip(
                                  clock.startDs,
                                  true
                                )}
                                {clock.schedStartDs &&
                                clock.startDs !== clock.schedStartDs ? (
                                  <br />
                                ) : (
                                  ""
                                )}
                                {clock.schedStartDs &&
                                clock.startDs !== clock.schedStartDs ? (
                                  <span
                                    className={`tsClockOffset ${
                                      isMgr ? "tsClockOffsetMgr" : ""
                                    }`}
                                  >
                                    {offset(clock.startDs, clock.schedStartDs)}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="tsRightBlock paddingTop14">
                                {" "}
                                {clock.endDs
                                  ? dateStringer.dsToTimeStrip(
                                      clock.endDs,
                                      true
                                    )
                                  : "-"}
                                {clock.endDs &&
                                clock.schedEndDs &&
                                clock.endDs !== clock.schedEndDs ? (
                                  <br />
                                ) : (
                                  ""
                                )}
                                {clock.endDs &&
                                clock.schedEndDs &&
                                clock.endDs !== clock.schedEndDs ? (
                                  <span
                                    className={`tsClockOffset ${
                                      isMgr ? "tsClockOffsetMgr" : ""
                                    }`}
                                  >
                                    {offset(clock.endDs, clock.schedEndDs)}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="tsRightBlock paddingTop14 borderRightLightGrey">
                                {dateStringer.formatMinsDurationToHours(
                                  (clock.break1durMins || 0) +
                                    (clock.break2durMins || 0) +
                                    (clock.break3durMins || 0),
                                  true
                                )}
                                {clock.schedBrk &&
                                (clock.break1durMins || 0) +
                                  (clock.break2durMins || 0) +
                                  (clock.break3durMins || 0) !==
                                  clock.schedBrk ? (
                                  <br />
                                ) : (
                                  ""
                                )}
                                {clock.schedBrk &&
                                (clock.break1durMins || 0) +
                                  (clock.break2durMins || 0) +
                                  (clock.break3durMins || 0) !==
                                  clock.schedBrk ? (
                                  <span
                                    className={`tsClockOffset ${
                                      isMgr ? "tsClockOffsetMgr" : ""
                                    }`}
                                  >
                                    {" "}
                                    {offset(
                                      (clock.break1durMins || 0) +
                                        (clock.break2durMins || 0) +
                                        (clock.break3durMins || 0),
                                      clock.schedBrk,
                                      true
                                    )}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="tsRightBlockInfo tsRightBlockInfoOver">
                                <div className="tsClTagsDiv">
                                  <img
                                    src={rotaDur}
                                    alt="Tags"
                                    className="tsClTagImg rotaDurTsImg"
                                  />{" "}
                                  <div className="tsTagsArr fontSize14_  fontWeight500">
                                    Clocked:{" "}
                                    {dateStringer.formatMinsDurationToHours(
                                      clock.payableDurMins || 0,
                                      true
                                    )}
                                  </div>
                                </div>
                                {clock.tags &&
                                Array.isArray(clock.tags) &&
                                clock.tags[0] ? (
                                  <div className="tsClTagsDiv">
                                    <img
                                      src={rotaTag}
                                      alt="Tags"
                                      className="tsClTagImg tsClTagImgTag"
                                    />{" "}
                                    <div className="tsTagsArr">
                                      {clock.tags.map((tag) => {
                                        return (
                                          <div className="tsTagItem">
                                            {dateStringer.shorten(tag, 14)}
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}

                                {/* == = = = = == = === .  */}
                                {clock.assocShiftID || clock.assocTilID ? (
                                  <div
                                    className={`tsOtprefBox x2489498298233 ${
                                      payClockedLoading ? "disableFade" : ""
                                    }`}
                                    onClick={() => {
                                      setPayClockedLoading(true);
                                      axios
                                        .post(
                                          `${serverURL}/toggle-pay-clocked-on-shift`,
                                          {
                                            shiftID: clock.assocShiftID
                                              ? clock.assocShiftID
                                              : "",

                                            tilID: clock.assocTilID
                                              ? clock.assocTilID
                                              : "",
                                            clockOnID: clock.clockOnID,
                                            data: !clock.payClocked,
                                          },

                                          {
                                            withCredentials: true,
                                            credentials: "include",
                                          }
                                        )
                                        .then((response) => {
                                          if (
                                            response.data.message === "success"
                                          ) {
                                            setReload(!reload);
                                          }
                                        });
                                      // axios
                                      //   .post(
                                      //     `${serverURL}/amend-logged-excess-toil-in-timesheet`,
                                      //     {
                                      //       timesheetID:
                                      //         memoVals.showViewTimesheet
                                      //           .timesheetID,
                                      //       shiftID:
                                      //         sched.shiftID || sched.tilID,
                                      //       data: !sched.loggedExcessToil,
                                      //     },
                                      //     {
                                      //       withCredentials: true,
                                      //       credentials: "include",
                                      //     }
                                      //   )
                                      //   .then((response) => {
                                      //     if (
                                      //       response.data.message === "success"
                                      //     ) {
                                      //       setReload(!reload);
                                      //     }
                                      //   });
                                    }}
                                  >
                                    <div className="tsOptTickBoxDiv">
                                      <img
                                        src={
                                          clock.payClocked ? tick0077ff : tick
                                        }
                                        alt="Pref"
                                        className={`tsOptTickBoxDivImg ${
                                          clock.payClocked ? "" : "invis"
                                        }`}
                                      />
                                    </div>
                                    <p
                                      className={`logTsValLoc ${
                                        memoVals.mobModal
                                          ? "fontSize10"
                                          : "fontSize12"
                                      } ${
                                        clock.payClocked ? "colour0077ff" : ""
                                      } `}
                                    >
                                      Pay clocked duration only
                                    </p>
                                    {/* <p className="logTsTitleLoc x48958545676">
                                      Preference:
                                    </p>
                                    <p className="logTsValLoc fontSize12">
                                      {sched.loggedExcess > 0
                                        ? "add to TOIL"
                                        : "deduct from TOIL"}
                                    </p> */}
                                  </div>
                                ) : (
                                  ""
                                )}
                                {/* = = = = =  */}
                                {!clock.gpsActive ? (
                                  <div className="tsClTagsDiv">
                                    <img
                                      src={gps1}
                                      alt="Tags"
                                      className="tsClTagImg tsClTagImgGps"
                                    />{" "}
                                    <div className="gpsNoneTsTxt">
                                      No GPS data
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    className="tsClTagsDiv alignCenter cursorPointer"
                                    onClick={() => {
                                      if (
                                        showGpsArr.includes(clock.clockOnID)
                                      ) {
                                        setShowGpsArr(
                                          showGpsArr.filter((x) => {
                                            return x !== clock.clockOnID;
                                          })
                                        );
                                      } else {
                                        setShowGpsArr([
                                          ...showGpsArr,
                                          clock.clockOnID,
                                        ]);
                                      }
                                    }}
                                  >
                                    <img
                                      src={
                                        clock.wrongLocation ||
                                        clock.excssiveDistance
                                          ? gps3
                                          : gps2
                                      }
                                      alt="Tags"
                                      className="tsClTagImg tsClTagImgGps"
                                    />{" "}
                                    <div
                                      className={`gpsNoneTsTxt ${
                                        clock.wrongLocation ||
                                        clock.excssiveDistance
                                          ? "colour143051"
                                          : ""
                                      }`}
                                    >
                                      {clock.wrongLocation ||
                                      clock.excssiveDistance
                                        ? "Wrong location"
                                        : clock.expectedLocationName ||
                                          "GPS recorded"}
                                    </div>
                                    <img
                                      src={allowArr}
                                      alt="GPS array"
                                      className={`gpsArrDownArr ${
                                        showGpsArr.includes(clock.clockOnID)
                                          ? "gpsArrDownArrActive"
                                          : ""
                                      }`}
                                    />
                                  </div>
                                )}
                                {showGpsArr.includes(clock.clockOnID) ? (
                                  <div className="showGpsArrDiv">
                                    {clock.gpsArr.map((gps, iii) => {
                                      return (
                                        <div
                                          className={`tsGpsRow ${
                                            iii === clock.gpsArr.length - 1
                                              ? "noBorder"
                                              : ""
                                          }`}
                                          key={iii}
                                        >
                                          <div
                                            className={`tsGpsRowRow  ${
                                              mob ? "tsGpsRowRowMob" : ""
                                            }`}
                                          >
                                            <p className="tsGpsRowTitleMain">
                                              {gps.what === "clockOn"
                                                ? "Clocked on"
                                                : gps.what === "break1start"
                                                ? "Break 1 start"
                                                : gps.what === "break2start"
                                                ? "Break 2 start"
                                                : gps.what === "break3start"
                                                ? "Break 3 start"
                                                : gps.what === "break1end"
                                                ? "Break 1 end"
                                                : gps.what === "break2end"
                                                ? "Break 2 end"
                                                : gps.what === "break3end"
                                                ? "Break 3 end"
                                                : "Clocked off"}
                                            </p>{" "}
                                            <p className="tsGpsRowValueMain"></p>
                                          </div>
                                          <div
                                            className={`tsGpsRowRow  ${
                                              mob ? "tsGpsRowRowMob" : ""
                                            }`}
                                          >
                                            <p
                                              className={`tsGpsRowTitle  ${
                                                mob ? "x498985393" : ""
                                              }`}
                                            >
                                              GPS
                                            </p>{" "}
                                            <a
                                              target="_blank"
                                              href={`https://www.google.com/maps/search/?api=1&query=${gps.lat},${gps.lon}`}
                                              className={`tsGpsRowValue fontSize10 cursorPointer`}
                                            >
                                              {gps.lat}, {gps.lon}{" "}
                                              <img
                                                src={popup2}
                                                alt="GPS maps"
                                                className="gpsMapPopupTxImg"
                                              />
                                            </a>
                                          </div>
                                          {gps.location ? (
                                            <div
                                              className={`tsGpsRowRow  ${
                                                mob ? "tsGpsRowRowMob" : ""
                                              }`}
                                            >
                                              <p
                                                className={`tsGpsRowTitle ${
                                                  window.innerWidth < 382
                                                    ? "x498985393"
                                                    : ""
                                                }`}
                                              >
                                                {gps.unexpected
                                                  ? "Clocked location"
                                                  : "Location"}
                                              </p>{" "}
                                              <p
                                                className={`tsGpsRowValue ${
                                                  clock.expectedLocationName &&
                                                  gps.location !==
                                                    clock.expectedLocationName
                                                    ? "colourBa0000"
                                                    : ""
                                                }`}
                                              >
                                                {gps.location}
                                              </p>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                          {gps.location && gps.unexpected ? (
                                            <div
                                              className={`tsGpsRowRow  ${
                                                mob ? "tsGpsRowRowMob" : ""
                                              }`}
                                            >
                                              <p
                                                className={`tsGpsRowTitle ${
                                                  mob ? "x498985393" : ""
                                                }`}
                                              >
                                                Scheduled location
                                              </p>{" "}
                                              <p className={`tsGpsRowValue`}>
                                                {dateStringer.shorten(
                                                  clock.expectedLocationName,
                                                  23
                                                )}
                                              </p>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                          {gps.location ? (
                                            <div
                                              className={`tsGpsRowRow  ${
                                                mob ? "tsGpsRowRowMob" : ""
                                              }`}
                                            >
                                              <p className="tsGpsRowTitle">
                                                Distance
                                              </p>{" "}
                                              <p
                                                className={`tsGpsRowValue ${
                                                  gps.distance > 300
                                                    ? "colourBa0000"
                                                    : ""
                                                }`}
                                              >
                                                {dateStringer.convertMetresToKm(
                                                  gps.distance
                                                )}
                                              </p>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                          <div
                                            className={`tsGpsRowRow  ${
                                              mob ? "tsGpsRowRowMob" : ""
                                            }`}
                                          >
                                            <p className="tsGpsRowTitle">
                                              Accuracy
                                            </p>{" "}
                                            <p
                                              className={`tsGpsRowValue ${
                                                gps.acc > 300
                                                  ? "colourBa0000"
                                                  : ""
                                              }`}
                                            >
                                              {gps.acc < 1000
                                                ? `${Math.round(gps.acc)}m`
                                                : dateStringer.convertMetresToKm(
                                                    gps.acc
                                                  )}
                                            </p>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>

                            <div
                              className={`tsRightDataBlockLower ${
                                clock.schedStartDs === clock.startDs &&
                                clock.schedEndDs === clock.endDs &&
                                clock.schedBrk ===
                                  clock.break1durMins +
                                    clock.break2durMins +
                                    clock.break3durMins
                                  ? "marginTopMinus15"
                                  : ""
                              } ${
                                showGpsArr.includes(clock.clockOnID) &&
                                clock.schedStartDs
                                  ? "x34882848844"
                                  : ""
                              }`}
                            >
                              {!clock.schedStartDs ? (
                                <p
                                  className={`noSchedTsSh ${
                                    showGpsArr.includes(clock.clockOnID)
                                      ? "x34882848844"
                                      : ""
                                  }`}
                                >
                                  No shift scheduled
                                </p>
                              ) : (
                                ""
                              )}
                              <div
                                className={`tsRightBlock paddingTop14 noBottomBorder_ bbcada ${
                                  !clock.schedStartDs ? "none" : ""
                                }`}
                              >
                                {dateStringer.dsToTimeStrip(
                                  clock.schedStartDs,
                                  true
                                )}
                                <p
                                  className={`schedClockedTsClocked ${
                                    mob ? "schedClockedTsClockedMob" : ""
                                  }`}
                                >
                                  Scheduled
                                </p>
                              </div>
                              <div
                                className={`tsRightBlock paddingTop14 noBottomBorder_ bbcada ${
                                  !clock.schedStartDs ? "none" : ""
                                }`}
                              >
                                {dateStringer.dsToTimeStrip(
                                  clock.schedEndDs,
                                  true
                                )}
                              </div>
                              <div
                                c
                                className={`tsRightBlock paddingTop14 noBottomBorder_ borderRightLightGrey  bbcada ${
                                  !clock.schedStartDs ? "none" : ""
                                }`}
                              >
                                {" "}
                                {dateStringer.formatMinsDurationToHours(
                                  clock.schedBrk || 0,
                                  true
                                )}
                              </div>
                              <div className="tsRightBlockInfo"></div>
                            </div>

                            <div className="tsLowRow">
                              <p
                                className="viewApprovedTsItem greyColours_"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  // if (!clock.schedStartDs) {
                                  memoVals.setClockOnID((x) => {
                                    return {
                                      clockOnID: clock.clockOnID,
                                      isMgr: isMgr,
                                    };
                                  });
                                  // }
                                }}
                              >
                                Clock card{" "}
                                <img
                                  src={popup}
                                  alt="View item"
                                  className="viewTsItemPopUp"
                                />
                              </p>{" "}
                              {clock.schedStartDs ? (
                                <p
                                  className="viewApprovedTsItem greyColours_ x349859823"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    // happy
                                    if (isMgr) {
                                      memoVals.setOpenManagerShiftModal((x) => {
                                        return {
                                          shiftID: clock.assocShiftID,
                                          tilID: clock.assocTilID,
                                          openedFromShiftsPage: false,
                                          type:
                                            clock.assocShiftID === "shift"
                                              ? "shift"
                                              : "til",
                                        };
                                      });
                                    } else {
                                      memoVals.setShowMyShiftModal((x) => {
                                        return {
                                          unitType:
                                            clock.assocShiftID === "shift"
                                              ? "shift"
                                              : "til",
                                          unitID:
                                            clock.assocShiftID ||
                                            clock.assocTilID,
                                        };
                                      });
                                    }
                                  }}
                                >
                                  Scheduled{" "}
                                  {clock.type === "shift"
                                    ? "shift"
                                    : "overtime"}
                                  <img
                                    src={popup}
                                    alt="View item"
                                    className="viewTsItemPopUp"
                                  />
                                </p>
                              ) : (
                                ""
                              )}
                              <br />
                              {/* CTA here */}
                              {/* {!headerData.approved && !headerData.declined ? (
                            <div className="appCCTsBtn l">
                              Approve{" "}
                              <div className="appCCtsBtnCheck">
                                <img
                                  src={navyTick}
                                  alt="Approve"
                                  className="appCCtsBtnCheckImg"
                                />
                              </div>{" "}
                            </div>
                          ) : (
                            ""
                          )} */}
                            </div>
                          </div>
                        );
                      }
                      if (x.masterType === "scheduled") {
                        let sched = x;
                        let existsInClocks = item.clocks.filter((x) => {
                          return (x.assocShiftID || x.assocTilID) === x.shiftID;
                        });
                        if (existsInClocks[0]) {
                          return;
                        }

                        return (
                          <div key={ind}>
                            <div
                              className={`tsTopRow ${
                                headerData.approved
                                  ? "tsTopRowApp"
                                  : headerData.declined
                                  ? "tsTopRowDec"
                                  : ""
                              } ${x.ghostTitle ? "ghostTsBg" : ""} ${
                                ind === 0 ? "noTopBorder" : ""
                              } `}
                            >
                              {x.ghostTitle ? x.ghostTitle : "Scheduled"}{" "}
                              {x.ghostTitle
                                ? ""
                                : sched.type === "til"
                                ? "overtime (in lieu)"
                                : sched.type === "shift"
                                ? "shift"
                                : "overtime"}{" "}
                              {sched.absent ? (
                                sched.partialAbsent ? (
                                  <span className="tsAbsSpan">
                                    - Partially absent
                                  </span>
                                ) : (
                                  <span className="tsAbsSpan">- Absent</span>
                                )
                              ) : (
                                ""
                              )}
                            </div>
                            {/* loggedUpper */}
                            {sched.loggedStart ||
                            sched.loggedEnd ||
                            typeof sched.loggedBrk === "number" ? (
                              <p
                                className={`schedClockedTs ${
                                  mob ? "schedClockedTsMob" : ""
                                }`}
                              >
                                Logged
                              </p>
                            ) : (
                              ""
                            )}

                            <div className="tsRightDataBlockUpper">
                              {sched.loggedStart ? (
                                <div className="tsRightBlock loggedTsRightBlock">
                                  {sched.loggedStart}
                                </div>
                              ) : (
                                <div
                                  className={`tsRightBlock ${
                                    sched.ghostTitle ? "ghostTsTxtColour" : ""
                                  } ${
                                    sched.absent && !sched.partialAbsent
                                      ? "absTsTime"
                                      : ""
                                  }`}
                                >
                                  {dateStringer.dsToTimeStrip(
                                    sched.startDs,
                                    true
                                  )}
                                </div>
                              )}
                              {sched.loggedEnd ? (
                                <div className="tsRightBlock loggedTsRightBlock">
                                  {" "}
                                  {sched.loggedEnd}
                                </div>
                              ) : (
                                <div
                                  className={`tsRightBlock ${
                                    sched.ghostTitle ? "ghostTsTxtColour" : ""
                                  } ${
                                    sched.absent && !sched.partialAbsent
                                      ? "absTsTime"
                                      : ""
                                  }`}
                                >
                                  {" "}
                                  {sched.endDs
                                    ? dateStringer.dsToTimeStrip(
                                        sched.endDs,
                                        true
                                      )
                                    : "-"}
                                </div>
                              )}
                              {typeof sched.loggedBrk === "number" ? (
                                <div className="tsRightBlock loggedTsRightBlock borderRightLightGrey">
                                  {" "}
                                  {dateStringer.formatMinsDurationToHours(
                                    sched.loggedBrk || 0,
                                    true
                                  )}
                                </div>
                              ) : (
                                <div
                                  className={`tsRightBlock borderRightLightGrey ${
                                    sched.ghostTitle ? "ghostTsTxtColour" : ""
                                  } ${
                                    sched.absent && !sched.partialAbsent
                                      ? "absTsTime"
                                      : ""
                                  }`}
                                >
                                  {dateStringer.formatMinsDurationToHours(
                                    sched.brkMins || 0,
                                    true
                                  )}
                                </div>
                              )}
                              <div className="tsRightBlockInfo tsRightBlockInfoOver">
                                {sched.ghostTitle ? (
                                  <div className="tsClTagsDiv">
                                    <img
                                      src={rotaZzz}
                                      alt="Tags"
                                      className="tsClTagImg smileTs"
                                    />{" "}
                                    <div className="tsTagsArr fontSize14_ colour0077ff_">
                                      Paid day off
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                                <div className="tsClTagsDiv">
                                  <img
                                    src={rotaDur}
                                    alt="Tags"
                                    className="tsClTagImg rotaDurTsImg"
                                  />{" "}
                                  <div className="tsTagsArr x4298593824 fontSize14_ fontWeight500">
                                    <span className="tsPayableTitle">
                                      Payable:
                                    </span>
                                    {/* <br /> */}
                                    <span>
                                      {dateStringer.formatMinsDurationToHours(
                                        sched.payableDurMins || 0
                                      )}
                                    </span>
                                  </div>
                                </div>
                                {sched.loggedExcess &&
                                typeof sched.loggedExcess === "number" ? (
                                  <div className="tsClTagsDiv">
                                    <img
                                      src={rotaDur}
                                      alt="Tags"
                                      className="tsClTagImg rotaDurTsImg x2394389439856"
                                    />{" "}
                                    <div
                                      className={`tsTagsArr fontSize14_ fontWeight500 ${
                                        sched.loggedExcess < 0
                                          ? "c3894598585"
                                          : "colour0077ff"
                                      }`}
                                    >
                                      {/* {sched.loggedExcess < 0 ? "-" : "+"} */}
                                      {dateStringer.formatMinsDurationToHours(
                                        sched.loggedExcess,
                                        true
                                      )}{" "}
                                      {sched.loggedExcess < 0
                                        ? "under"
                                        : "over"}{" "}
                                      scheduled
                                      {/* {sched.loggedExcess < 0
                                        ? "< scheduled"
                                        : "> scheduled"} */}
                                      {/* difference */}
                                      {/* <span className="x86986589569865">
                                        {sched.loggedExcess > 0
                                          ? `over uratischeduled don`
                                          : `under scheduled duration`}
                                      </span> */}
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}

                                {sched.loggedExcess &&
                                typeof sched.loggedExcess === "number" ? (
                                  <div
                                    className={`tsOtprefBox                                       ${
                                      (!sched.tags ||
                                        (Array.isArray(sched.tags) &&
                                          sched.tags.length === 0)) &&
                                      !sched.locationName
                                        ? "noBorderBottom"
                                        : "x2489498298233"
                                    } ${
                                      payClockedLoading ? "disableFade" : ""
                                    }`}
                                    onClick={() => {
                                      setPayClockedLoading(true);
                                      axios
                                        .post(
                                          `${serverURL}/amend-logged-excess-toil-in-timesheet`,
                                          {
                                            timesheetID:
                                              memoVals.showViewTimesheet
                                                .timesheetID,

                                            shiftID:
                                              sched.shiftID || sched.tilID,
                                            data: !sched.loggedExcessToil,
                                          },

                                          {
                                            withCredentials: true,
                                            credentials: "include",
                                          }
                                        )
                                        .then((response) => {
                                          if (
                                            response.data.message === "success"
                                          ) {
                                            setReload(!reload);
                                          }
                                        });
                                    }}
                                  >
                                    <div className="tsOptTickBoxDiv">
                                      <img
                                        src={
                                          sched.loggedExcess > 0 &&
                                          sched.loggedExcessToil
                                            ? tick0077ff
                                            : sched.loggedExcess < 0 &&
                                              sched.loggedExcessToil
                                            ? tickBa0000
                                            : tick
                                        }
                                        alt="Pref"
                                        className={`tsOptTickBoxDivImg ${
                                          sched.loggedExcessToil ? "" : "invis"
                                        }`}
                                      />
                                    </div>
                                    <p
                                      className={`logTsValLoc fontSize12 ${
                                        sched.loggedExcess > 0 &&
                                        sched.loggedExcessToil
                                          ? "colour0077ff"
                                          : ""
                                      } ${
                                        sched.loggedExcess < 0 &&
                                        sched.loggedExcessToil
                                          ? "colourBa0000"
                                          : ""
                                      }`}
                                    >
                                      {sched.loggedExcess > 0
                                        ? "Add to TOIL"
                                        : "Deduct from TOIL"}
                                    </p>
                                    {/* <p className="logTsTitleLoc x48958545676">
                                      Preference:
                                    </p>
                                    <p className="logTsValLoc fontSize12">
                                      {sched.loggedExcess > 0
                                        ? "add to TOIL"
                                        : "deduct from TOIL"}
                                    </p> */}
                                  </div>
                                ) : (
                                  ""
                                )}
                                {sched.tags &&
                                Array.isArray(sched.tags) &&
                                sched.tags[0] ? (
                                  <div className="tsClTagsDiv">
                                    <img
                                      src={rotaTag}
                                      alt="Tags"
                                      className="tsClTagImg tsClTagImgTag"
                                    />{" "}
                                    <div className="tsTagsArr">
                                      {sched.tags.map((tag) => {
                                        return (
                                          <div className="tsTagItem">
                                            {dateStringer.shorten(tag, 14)}
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}

                                {sched.locationName ? (
                                  <div className="tsClTagsDiv">
                                    <img
                                      src={pin}
                                      alt="Tags"
                                      className="tsClTagImg tsClTagImgPin"
                                    />{" "}
                                    <p className="tsLocName">
                                      {sched.locationName}
                                    </p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>

                            {/* loggedLower */}

                            <div className="tsRightDataBlockLower x2490530959533">
                              {sched.absent ? (
                                <div className="mgrTsAbsInfo">
                                  <p className="mgrTsAbsInfoTitle">
                                    {sched.unpaidAbsent
                                      ? "Unpaid absence"
                                      : "Paid absence"}
                                  </p>
                                  <p className="mgrTsAbsInfoPartial">
                                    {sched.partialAbsent
                                      ? `${sched.partialAbsentStart} - ${sched.partialAbsentEnd}`
                                      : "Whole shift off"}
                                  </p>
                                  {sched.partialAbsentDuration &&
                                  sched.partialAbsent ? (
                                    <p className="mgrTsAbsInfoPartial">
                                      {dateStringer.formatMinsDurationToHours(
                                        sched.partialAbsentDuration / 1000 / 60
                                      )}{" "}
                                      absent
                                    </p>
                                  ) : (
                                    ""
                                  )}

                                  {sched.absenceName ? (
                                    <p className="mgrTsAbsInfoUnpaid">
                                      {sched.absenceName}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}

                              {sched.loggedStart ||
                              typeof sched.loggedBrk === "number" ||
                              sched.loggedEnd ? (
                                <p
                                  className={`schedTsLoggTitle ${
                                    mob ? "schedTsLoggTitleMob" : ""
                                  }`}
                                >
                                  Scheduled
                                </p>
                              ) : (
                                ""
                              )}

                              {sched.loggedStart ||
                              typeof sched.loggedBrk === "number" ||
                              sched.loggedEnd ? (
                                <div className={`noSchedTsSh flexStart`}>
                                  {!sched.loggedStart ? (
                                    <div className="tsRightBlock">&nbsp;</div>
                                  ) : (
                                    <div className="tsRightBlock x3098895555">
                                      {" "}
                                      {/* <p className="x39039009109"> */}
                                      {dateStringer.dsToTimeStrip(
                                        sched.startDs,
                                        true
                                      )}
                                      {/* </p> */}
                                    </div>
                                  )}
                                  {!sched.loggedEnd ? (
                                    <div className="tsRightBlock">&nbsp;</div>
                                  ) : (
                                    <div className="tsRightBlock x3098895555">
                                      {" "}
                                      {sched.endDs
                                        ? dateStringer.dsToTimeStrip(
                                            sched.endDs,
                                            true
                                          )
                                        : "-"}
                                    </div>
                                  )}
                                  {typeof sched.loggedBrk !== "number" ||
                                  (!sched.loggedBrk &&
                                    sched.loggedBrk !== 0) ? (
                                    <div className="tsRightBlock">&nbsp; </div>
                                  ) : (
                                    <div className="tsRightBlock x3098895555 borderRightLightGrey">
                                      {dateStringer.formatMinsDurationToHours(
                                        sched.brkMins || 0,
                                        true
                                      )}
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <div
                                  className={`noSchedTsSh flexStart  ${
                                    sched.absent ? "noRightBorder" : ""
                                  }`}
                                ></div>
                              )}
                              {/* 
                              {sched.loggedExcess ? (
                                <div className="tsLogLocDiv">
                                  <img
                                    src={rotaDur00aaff}
                                    className="loggedLocPinTsImg loggedLocPinTsImgDur"
                                    alt="Location"
                                  />
                                  <div className="loggedTsTitleVal">
                                    <p className="logTsTitleLoc">
                                      Logged vs scheduled:
                                    </p>
                                    <p className="logTsValLoc">
                                      {dateStringer.formatMinsDurationToHours(
                                        sched.loggedExcess
                                      )}{" "}
                                      {sched.loggedExcess > 0
                                        ? "over"
                                        : "under"}
                                    </p>
                                  </div>{" "}
                                </div>
                              ) : (
                                ""
                              )} */}
                              {/* {sched.loggedExcess ? (
                                <div className="tsLogLocDiv">
                                  <img
                                    src={rotaDur00aaff}
                                    className="loggedLocPinTsImg loggedLocPinTsImgDur invis"
                                    alt="Location"
                                  />
                                  <div className="loggedTsTitleVal">
                                    <p className="logTsTitleLoc x48958545676">
                                      {sched.loggedExcess < 0
                                        ? "Shortfall"
                                        : "Overtime"}{" "}
                                      preference
                                    </p>
                                    <p className="logTsValLoc fontSize12">
                                      {sched.loggedExcessToil
                                        ? "To add to TOIL balance"
                                        : "To deduct from TOIL balance"}
                                    </p>
                                  </div>{" "}
                                </div>
                              ) : (
                                ""
                              )} */}
                              {sched.loggedLocationID ? (
                                <div className="tsLogLocDiv">
                                  <img
                                    src={pin00aaff}
                                    className="loggedLocPinTsImg"
                                    alt="Location"
                                  />
                                  <div className="loggedTsTitleVal">
                                    <p className="logTsTitleLoc">
                                      Logged location:
                                    </p>
                                    <p className="logTsValLoc">
                                      {" "}
                                      {locations.filter((x) => {
                                        return (
                                          x.locationID ===
                                          sched.loggedLocationID
                                        );
                                      })[0]
                                        ? locations.filter((x) => {
                                            return (
                                              x.locationID ===
                                              sched.loggedLocationID
                                            );
                                          })[0].locationName
                                        : "Unknown location"}
                                    </p>
                                  </div>{" "}
                                </div>
                              ) : (
                                ""
                              )}
                              {Array.isArray(sched.loggedTags) &&
                              sched.loggedTags[0] ? (
                                <div className="tsLogLocDiv">
                                  <img
                                    src={tag00aaff}
                                    className="loggedLocPinTsImg"
                                    alt="Location"
                                  />
                                  <div className="loggedTsTitleVal">
                                    <p className="logTsTitleLoc">Logged tags</p>
                                    <div className="logTsValLoc">
                                      {sched.tags.map((tag, i) => {
                                        if (!sched.loggedTags?.includes(tag)) {
                                          return (
                                            <p
                                              className="loggedTagInlineTsItem loggedTagInlineTsItemStrike"
                                              key={i}
                                            >
                                              {tag}
                                            </p>
                                          );
                                        }
                                      })}
                                      {sched.loggedTags.map((tag, i) => {
                                        // if (
                                        //   sched.loggedTags.length === 1 &&
                                        //   sched.tags?.includes(tag)
                                        // ) {
                                        // } else {
                                        return (
                                          <p
                                            className="loggedTagInlineTsItem"
                                            key={i}
                                          >
                                            {tag}
                                          </p>
                                        );
                                        // }
                                      })}
                                    </div>
                                  </div>{" "}
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="tsRightBlockInfo"></div>
                            </div>

                            <div className="tsLowRow">
                              {" "}
                              <p
                                className="viewApprovedTsItem navyColours_"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (isMgr) {
                                    memoVals.setOpenManagerShiftModal((x) => {
                                      return {
                                        shiftID: sched.shiftID,
                                        tilID: sched.tilID,
                                        openedFromShiftsPage: false,
                                        type: sched.type,
                                      };
                                    });
                                  } else {
                                    memoVals.setShowMyShiftModal((x) => {
                                      return {
                                        unitType: sched.type,
                                        unitID: sched.shiftID || sched.tilID,
                                      };
                                    });
                                  }
                                }}
                              >
                                View{" "}
                                {sched.type === "shift" ? "shift" : "overtime"}
                                <img
                                  src={popup}
                                  alt="View item"
                                  className="viewTsItemPopUp"
                                />
                              </p>
                              {/* CTA here */}
                            </div>
                          </div>
                        );
                      }
                      if (x.masterType === "item") {
                        let item = x;
                        return (
                          <div key={ind}>
                            <div
                              className={`tsTopRow ${
                                headerData.approved
                                  ? "tsTopRowApp"
                                  : headerData.declined
                                  ? "tsTopRowDec"
                                  : ""
                              } ${ind === 0 ? "noTopBorder" : ""} `}
                            >
                              Recorded{" "}
                              {item.type === "til"
                                ? "overtime (in lieu)"
                                : item.type === "shift"
                                ? "shift"
                                : "overtime"}
                            </div>
                            <div className="tsRightDataBlockUpper">
                              <div className="tsRightBlock">{item.start}</div>
                              <div className="tsRightBlock">{item.end}</div>
                              <div className="tsRightBlock borderRightLightGrey">
                                {dateStringer.formatMinsDurationToHours(
                                  item.brk || 0,
                                  true
                                )}
                              </div>
                              <div className="tsRightBlockInfo tsRightBlockInfoOver">
                                <div className="tsClTagsDiv">
                                  <img
                                    src={rotaDur}
                                    alt="Tags"
                                    className="tsClTagImg rotaDurTsImg"
                                  />{" "}
                                  <div className="tsTagsArr fontSize14_ fontWeight500">
                                    {dateStringer.formatMinsDurationToHours(
                                      item.payableDurMins || 0
                                    )}
                                  </div>
                                </div>
                                {item.tags &&
                                Array.isArray(item.tags) &&
                                item.tags[0] ? (
                                  <div className="tsClTagsDiv">
                                    <img
                                      src={rotaTag}
                                      alt="Tags"
                                      className="tsClTagImg tsClTagImgTag"
                                    />{" "}
                                    <div className="tsTagsArr">
                                      {item.tags.map((tag) => {
                                        return (
                                          <div className="tsTagItem">
                                            {dateStringer.shorten(tag, 14)}{" "}
                                            {headerData.approved ||
                                            headerData.declined ? (
                                              ""
                                            ) : (
                                              <img
                                                src={cross}
                                                alt="Remove tag"
                                                className="removeTsTagCrossImg"
                                                onClick={() => {
                                                  axios
                                                    .post(
                                                      `${serverURL}/add-tag-to-recorded-item-in-timesheet`,
                                                      {
                                                        timesheetID:
                                                          memoVals
                                                            .showViewTimesheet
                                                            .timesheetID,
                                                        itemID: x.itemID,
                                                        tag: tag,
                                                        remove: true,
                                                      },
                                                      {
                                                        withCredentials: true,
                                                        credentials: "include",
                                                      }
                                                    )
                                                    .then((response) => {
                                                      if (
                                                        response.data
                                                          .message === "success"
                                                      ) {
                                                        // update new tags here for the recorded item
                                                        // setDsArr([{ ds: "", items: [], clocks: [], scheduled: [] }]);
                                                        setReload(!reload);
                                                      }
                                                    });
                                                }}
                                              />
                                            )}
                                          </div>
                                        );
                                      })}{" "}
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {headerData.approved ||
                                headerData.declined ||
                                availableTags.length === 0 ||
                                availableTags.filter((t) => {
                                  if (x.tags && x.tags.includes(t)) {
                                  } else {
                                    return t;
                                  }
                                }).length === 0 ? (
                                  ""
                                ) : showAddTagToRecordItem !== x.itemID ? (
                                  <p
                                    className="recordedItemAddTagBtn"
                                    onClick={() => {
                                      setShowAddTagToRecordedItem(x.itemID);
                                    }}
                                  >
                                    Add tag{" "}
                                    <img
                                      src={navyAdd}
                                      alt="Add tag"
                                      className="navyAddTagTs"
                                    />
                                  </p>
                                ) : (
                                  <select
                                    className="addTagTsDropper"
                                    value=""
                                    onChange={(e) => {
                                      let val = e.target.value;
                                      if (val) {
                                        axios
                                          .post(
                                            `${serverURL}/add-tag-to-recorded-item-in-timesheet`,
                                            {
                                              timesheetID:
                                                memoVals.showViewTimesheet
                                                  .timesheetID,
                                              itemID: x.itemID,
                                              tag: val,
                                            },
                                            {
                                              withCredentials: true,
                                              credentials: "include",
                                            }
                                          )
                                          .then((response) => {
                                            if (
                                              response.data.message ===
                                              "success"
                                            ) {
                                              // update new tags here for the recorded item
                                              // setDsArr([{ ds: "", items: [], clocks: [], scheduled: [] }]);
                                              setReload(!reload);
                                            }
                                          });
                                      }
                                    }}
                                  >
                                    <option value="">Tag</option>
                                    {availableTags.map((tag) => {
                                      if (!item.tags.includes(tag)) {
                                        return (
                                          <option value={tag} key={tag}>
                                            {tag}
                                          </option>
                                        );
                                      }
                                    })}
                                  </select>
                                )}
                                {item.locationName ? (
                                  <div className="tsClTagsDiv">
                                    <img
                                      src={pin}
                                      alt="Tags"
                                      className="tsClTagImg tsClTagImgPin"
                                    />{" "}
                                    <p className="tsLocName">
                                      {item.locationName}
                                    </p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="tsRightDataBlockLower">
                              <div className="noSchedTsSh flexStart">
                                {/* <p className="logTsTimesBtn">
                                  Amend times{" "}
                                  <img
                                    src={editNavy}
                                    alt="Edit"
                                    className="tsEditPencil"
                                  />
                                </p>{" "} */}
                              </div>

                              <div className="tsRightBlockInfo"></div>
                            </div>

                            <div className="tsLowRow">
                              {item.itemID && headerData.approved ? (
                                <p
                                  className="viewApprovedTsItem greyColours_"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    memoVals.setOpenManagerShiftModal((x) => {
                                      console.log({ item });
                                      return {
                                        shiftID:
                                          item.type === "shift"
                                            ? item.itemID
                                            : "",
                                        tilID:
                                          item.type !== "shift"
                                            ? item.itemID
                                            : "",
                                        fName: headerData.fName,
                                        lName: headerData.lName,
                                        openedFromShiftsPage: false,
                                        type: item.type,
                                      };
                                    });
                                  }}
                                >
                                  View{" "}
                                  {item.type === "shift" ? "shift" : "overtime"}
                                  <img
                                    src={popup}
                                    alt="View item"
                                    className="viewTsItemPopUp"
                                  />
                                </p>
                              ) : (
                                ""
                              )}
                              {item.itemID &&
                              headerData.approved &&
                              item.note ? (
                                <br />
                              ) : (
                                ""
                              )}
                              {item.note ? (
                                isMgr ? (
                                  <p className="tsNoteFromTitle">
                                    Note by {fName}:
                                  </p>
                                ) : (
                                  <p className="tsNoteFromTitle">Note:</p>
                                )
                              ) : (
                                ""
                              )}{" "}
                              {item.note ? (
                                <p className="tsNoteFromValue">{item.note}</p>
                              ) : (
                                ""
                              )}{" "}
                              {/* <p
                                className="viewApprovedTsItem greyColours"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  memoVals.setOpenManagerShiftModal((x) => {
                                    return {
                                      shiftID: sched.shiftID,
                                      tilID: sched.shiftID,
                                      openedFromShiftsPage: false,
                                      type: sched.type,
                                    };
                                  });
                                }}
                              >
                                View {sched.type === "shift" ? "shift" : "overtime"}
                                <img
                                  src={popup}
                                  alt="View item"
                                  className="viewTsItemPopUp"
                                />
                              </p> */}
                            </div>
                          </div>
                        );
                      }
                    }
                  )}
                </div>
                {/*  */}
              </div>
            );

            //
            //
            //
            //
            //
          })}
          <div className="tsTotalsRow">
            <p className="tsHoursTotalDur">
              Total hours:{" "}
              <span className="colour00aaff">
                {dateStringer.formatMinsDurationToHours(countMins())}
              </span>
            </p>
            <div
              className="tsWkHrBtn"
              onClick={() => {
                memoVals.setShowUserHoursModal((x) => {
                  return {
                    show: true,
                    userID: headerData.userID,
                    monWeek: dateStringer.getMonWeekOfDs(headerData.startDs),
                    fromEditUser: false,
                    fromShift: false,
                  };
                });
              }}
            >
              Week hours{" "}
              <img
                src={popupNavy}
                alt="Weekly hours"
                className="tsWkHrBtnPopup"
              />
            </div>
          </div>
        </div>{" "}
        <div className={`availModalFooter tsFooterSize`}>
          <div className={`mgrTsDecideBtnsContainer ${loading ? "invis" : ""}`}>
            {!headerData.approved && !headerData.declined ? (
              <p
                className="approveClockBtn"
                onClick={() => {
                  if (editingEntry && dataEdited) {
                    setShowSureChangeFocus(true);
                  } else {
                    // approve ts here --
                    // NOTE! Also do this in the showSure modal at the bottom x198191
                    axios
                      .post(
                        `${serverURL}/approve-ts`,
                        {
                          timesheetID: memoVals.showViewTimesheet.timesheetID,
                          nowDs: dateStringer.createStringFromTimestamp(
                            new Date().getTime()
                          ),
                        },
                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          setApprovedTsId(
                            memoVals.showViewTimesheet.timesheetID
                          );
                          console.log(response.data);
                          setCountData({
                            timesheets: countData.timesheets - 1,
                          });
                          // setTimeout(() => {
                          setReload(!reload);
                          memoVals.setUpdatedDashData(
                            (x) => !memoVals.updatedDashData
                          );
                          console.log("APPROVING TS 1");
                          memoVals.setUpdateUserTimesheets(
                            (x) => !memoVals.updateUserTimesheets
                          );

                          memoVals.setIndicate((x) => {
                            return {
                              show: true,
                              message: `Approved ${dateStringer.possession(
                                fName
                              )} timesheet`,
                              colour: "green",
                              duration: 4000,
                            };
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }
                }}
              >
                Approve{" "}
                <img
                  src={whiteTick}
                  alt="Approve"
                  className="approveClockTickImg"
                />
              </p>
            ) : (
              ""
            )}
            {!headerData.approved && !headerData.declined && !editDecision ? (
              <p
                className="declineClockBtn"
                onClick={() => {
                  axios
                    .post(
                      `${serverURL}/decline-ts`,
                      {
                        timesheetID: memoVals.showViewTimesheet.timesheetID,
                        nowDs: dateStringer.createStringFromTimestamp(
                          new Date().getTime()
                        ),
                      },
                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        setApprovedTsId(memoVals.showViewTimesheet.timesheetID);
                        setCountData({
                          timesheets: countData.timesheets - 1,
                        });
                        setHeaderData({
                          declined: true,
                          approved: false,
                          decidedBy: response.data.decidedBy,
                          declinedReason: response.data.declinedReason,
                          deciedDs: response.data.decidedDs,
                        });
                        setReload(!reload);
                        memoVals.setUpdatedDashData(
                          (x) => !memoVals.updatedDashData
                        );
                        memoVals.setUpdateUserTimesheets(
                          (x) => !memoVals.updateUserTimesheets
                        );

                        // setDeclined(true);
                        // setApproved(false);
                        // setDecidedBy(response.data.decidedBy);
                        // setDeclinedReason(response.data.declinedReason);
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Decline{" "}
                <img
                  src={crossWhite}
                  alt="Approve"
                  className="approveClockTickImg x34981"
                />
              </p>
            ) : (
              ""
            )}

            {
              headerData.declined || headerData.approved ? (
                <div className="deleteAndShiftHolder">
                  <p
                    className={`deleteShiftBtn deleteClockBtn width38px`}
                    onClick={() => {
                      setSureDeleteMgr(true);
                    }}
                  >
                    <img
                      src={whitebin}
                      className="clockWhiteBin"
                      alt="Delete"
                    />
                    {/* Delete */}
                  </p>

                  {!editDecision ? (
                    <p
                      className="editClockDecisionBtn"
                      onClick={() => {
                        setEditDecision(true);
                      }}
                    >
                      <img
                        src={editReq}
                        alt="Edit"
                        className="editClockDecImg"
                      />
                    </p>
                  ) : (
                    ""
                  )}

                  {editDecision ? (
                    <p
                      className="backEditDecision"
                      onClick={() => {
                        setEditDecision(false);
                      }}
                    >
                      <img
                        src={returnArrow}
                        alt="Back"
                        className="backeditdecisionImg"
                      />
                    </p>
                  ) : (
                    ""
                  )}

                  {editDecision && headerData.declined ? (
                    <p
                      className="approveClockBtn"
                      onClick={() => {
                        axios
                          .post(
                            `${serverURL}/approve-ts`,
                            {
                              timesheetID:
                                memoVals.showViewTimesheet.timesheetID,
                              nowDs: dateStringer.createStringFromTimestamp(
                                new Date().getTime()
                              ),
                            },
                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              setApprovedTsId(
                                memoVals.showViewTimesheet.timesheetID
                              );
                              // setTimeout(() => {
                              setReload(!reload);
                              memoVals.setUpdatedDashData(
                                (x) => !memoVals.updatedDashData
                              );
                              console.log("APPROVING TS 2");

                              memoVals.setUpdateUserTimesheets(
                                (x) => !memoVals.updateUserTimesheets
                              );

                              memoVals.setIndicate((x) => {
                                return {
                                  show: true,
                                  message: `Approved ${dateStringer.possession(
                                    fName
                                  )} timesheet`,
                                  colour: "green",
                                  duration: 4000,
                                };
                              });
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                        setEditDecision(false);
                      }}
                    >
                      Approve{" "}
                      <img
                        src={whiteTick}
                        alt="Approve"
                        className="approveClockTickImg"
                      />
                    </p>
                  ) : (
                    ""
                  )}

                  {editDecision && headerData.approved ? (
                    <p
                      className="declineClockBtn"
                      onClick={() => {
                        axios
                          .post(
                            `${serverURL}/decline-ts`,
                            {
                              timesheetID:
                                memoVals.showViewTimesheet.timesheetID,
                              nowDs: dateStringer.createStringFromTimestamp(
                                new Date().getTime()
                              ),
                            },
                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              setApprovedTsId(
                                memoVals.showViewTimesheet.timesheetID
                              );
                              setHeaderData({
                                declined: true,
                                approved: false,
                                decidedBy: response.data.decidedBy,
                                declinedReason: response.data.declinedReason,
                                deciedDs: response.data.decidedDs,
                              });
                              setReload(!reload);
                              memoVals.setUpdatedDashData(
                                (x) => !memoVals.updatedDashData
                              );
                              memoVals.setUpdateUserTimesheets(
                                (x) => !memoVals.updateUserTimesheets
                              );

                              // setDeclined(true);
                              // setApproved(false);
                              // setDecidedBy(response.data.decidedBy);
                              // setDeclinedReason(response.data.declinedReason);
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                        setEditDecision(false);
                      }}
                    >
                      Decline{" "}
                      <img
                        src={crossWhite}
                        alt="Approve"
                        className="approveClockTickImg x34981"
                      />
                    </p>
                  ) : (
                    ""
                  )}

                  {!editDecision ? (
                    <p
                      className={`tsSubmittedTs x140814 ${
                        headerData.approved
                          ? "ccApp"
                          : headerData.declined
                          ? "ccDec"
                          : ""
                      }`}
                      // onClick={() => {
                      //   axios
                      //     .post(
                      //       `${serverURL}/decline-ts`,
                      //       {
                      //         timesheetID:
                      //           memoVals.showViewTimesheet.timesheetID,
                      //         nowDs: dateStringer.createStringFromTimestamp(
                      //           new Date().getTime()
                      //         ),
                      //       },
                      //       {
                      //         withCredentials: true,
                      //         credentials: "include",
                      //       }
                      //     )
                      //     .then((response) => {
                      //       if (response.data.message === "success") {
                      //         setHeaderData({
                      //           declined: true,
                      //           approved: false,
                      //           decidedBy: response.data.decidedBy,
                      //           declinedReason:
                      //             response.data.declinedReason,
                      //           deciedDs: response.data.decidedDs,
                      //         });
                      //         setReload(!reload);
                      //         memoVals.setUpdatedDashData(
                      //           (x) => !memoVals.updatedDashData
                      //         );
                      //         memoVals.setUpdateUserTimesheets(
                      //           (x) => !memoVals.updateUserTimesheets
                      //         );

                      //         // setDeclined(true);
                      //         // setApproved(false);
                      //         // setDecidedBy(response.data.decidedBy);
                      //         // setDeclinedReason(response.data.declinedReason);
                      //       }
                      //     })
                      //     .catch((err) => {
                      //       console.error(err);
                      //     });
                      // }}
                    >
                      <span
                        className={`tsSubmittedTsTitleSpan 
                          
                          `}
                      >
                        {headerData.approved ? "Approved" : "Declined"} by
                      </span>
                      <br />
                      {headerData.decidedBy}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                <p className="tsSubmittedTs x23256786">
                  <span className="tsSubmittedTsTitleSpan">Submitted</span>
                  <br />
                  {dateStringer.printedDateFromDs(submitted)}
                </p>
              )

              // (
              //   <p className="tsSubmittedTs x140814">
              //     <span className="tsSubmittedTsTitleSpan">Declined by</span>
              //     <br />
              //     {headerData.decidedBy}
              //   </p>
              // ) : (
              //   <p className="tsSubmittedTs">
              //     <span className="tsSubmittedTsTitleSpan">Submitted</span>
              //     <br />
              //     {dateStringer.printedDateFromDs(submitted)}
              //   </p>
              // )
            }
          </div>

          <p
            className="cancelBulkModalBtn"
            onClick={() => {
              memoVals.setShowViewTimesheet((x) => {
                return { show: false };
              });
            }}
          >
            Close
          </p>
        </div>
      </div>
      {/* ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ----  */}
      {/* ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ----  */}
      {/* ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ----  */}
      {/* ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ----  */}
      {/* ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ----  */}
      {/* ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ----  */}
      {/* ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ----  */}

      {/* ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ----  */}
      {loading ? (
        <div
          className={`shiftLoadingBox ${
            mobModal ? "shiftLoadingBoxMob" : "shiftLoadingBoxDesktop"
          }`}
        >
          <img
            src={horizontalBalls}
            alt="Loading"
            className={`shiftLoadingBallsImg ${
              mobModal ? "shiftLoadingBallsImgMob" : "zoomIn"
            }`}
          />
        </div>
      ) : (
        ""
      )}
      {sureExit ? (
        <div
          className="overlapsRenewalModalUnderlay"
          onClick={() => {
            setSureExit(false);
          }}
        >
          <div
            className="overlapsRenewalModalBox"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {" "}
            <p className="overlapsRenTxt">
              You have unsaved changes.
              <br />
              <br />
              Are you sure you want to exit this timesheet?
            </p>
            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  memoVals.setShowViewTimesheet((x) => {
                    return { show: false };
                  });
                }}
              >
                Exit{" "}
                {/* <img src={popup} alt="Info" className="addLeaveModalInfoPopUp" /> */}
              </p>{" "}
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setSureExit(false);
                }}
              >
                Cancel
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {sureDelete ? (
        <div
          className="overlapsRenewalModalUnderlay"
          onClick={() => {
            setSureDelete(false);
          }}
        >
          <div
            className="overlapsRenewalModalBox"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {" "}
            <p className="overlapsRenTxt">
              Are you sure you want to delete this timesheet?
              {headerData.approved ? <br /> : ""}
              {headerData.approved ? <br /> : ""}
              {headerData.approved
                ? "Any scheduled shifts and shifts created by this timesheet will not be removed."
                : ""}
            </p>
            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  axios
                    .post(
                      `${serverURL}/delete-timesheet`,
                      {
                        timesheetID: memoVals.showViewTimesheet.timesheetID, // "new" is new one needs to be made
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        memoVals.setShowViewTimesheet((x) => {
                          return { show: false };
                        });
                        memoVals.setUpdateUserTimesheets(
                          (x) => !memoVals.updateUserTimesheets
                        );
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Yes{" "}
                {/* <img src={popup} alt="Info" className="addLeaveModalInfoPopUp" /> */}
              </p>{" "}
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setSureDelete(false);
                }}
              >
                Cancel
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {showSureChangeFocus ? (
        <div
          className="addAbsenceModalUnderlay"
          onClick={() => {
            setShowRequestAllModal(false);
          }}
        >
          {" "}
          <div
            className="formCreatedModal"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <p className="overlapsRenTxt">
              You haven't saved the last recorded item you were amending.
              <br />
              <br />
              {typeof showSureChangeFocus === "string"
                ? "Are you sure you want to discard any changes?"
                : "Are you sure you want to approve this timesheet without saving any changes?"}
            </p>
            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  if (typeof showSureChangeFocus === "string") {
                    setEditingEntry(showSureChangeFocus);
                    setShowSureChangeFocus(false);
                    setDataEdited(false);
                  } else {
                    // approve request here x198191
                    axios
                      .post(
                        `${serverURL}/approve-ts`,
                        {
                          timesheetID: memoVals.showViewTimesheet.timesheetID,
                          nowDs: dateStringer.createStringFromTimestamp(
                            new Date().getTime()
                          ),
                        },
                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          setApprovedTsId(
                            memoVals.showViewTimesheet.timesheetID
                          );
                          console.log(response.data);
                          // setTimeout(() => {

                          memoVals.setUpdateUserTimesheets(
                            (x) => !memoVals.updateUserTimesheets
                          );
                          console.log("APPROVING TS 3");
                          setCountData({
                            timesheets: countData.timesheets - 1,
                          });

                          memoVals.setIndicate((x) => {
                            return {
                              show: true,
                              message: `Approved ${dateStringer.possession(
                                fName
                              )} timesheet`,
                              colour: "green",
                              duration: 4000,
                            };
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });

                    setShowSureChangeFocus(false);
                    setEditingEntry("");
                    setDataEdited(false);
                  }
                }}
              >
                Yes -{" "}
                {typeof showSureChangeFocus !== "string"
                  ? "Approve"
                  : "Discard"}
              </p>
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setShowSureChangeFocus(false);
                  setDataEdited(false);
                }}
              >
                Cancel
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {sureDeleteMgr ? (
        <div
          className="addAbsenceModalUnderlay"
          onClick={() => {
            setSureDelete(false);
          }}
        >
          {" "}
          <div
            className="formCreatedModal"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <p className="overlapsRenTxt">
              Are you sure you want to delete this timesheet?
              {headerData.approved ? <br /> : ""}{" "}
              {headerData.approved ? <br /> : ""}
              {headerData.approved
                ? "Any scheduled shifts and shifts created from this timesheet will not be removed."
                : ""}
            </p>
            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  axios
                    .post(
                      `${serverURL}/delete-timesheet`,
                      {
                        timesheetID: memoVals.showViewTimesheet.timesheetID, // "new" is new one needs to be made
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        memoVals.setShowViewTimesheet((x) => {
                          return { show: false };
                        });
                        memoVals.setUpdateUserTimesheets(
                          (x) => !memoVals.updateUserTimesheets
                        );
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Yes - Delete
              </p>
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setSureDeleteMgr(false);
                }}
              >
                Cancel
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {showCannotManageOwnShift ? (
        <div
          className="overlapsRenewalModalUnderlay"
          onClick={() => {
            memoVals.setShowViewTimesheet((x) => {
              return { show: false };
            });
          }}
        >
          <div
            className="overlapsRenewalModalBox"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {" "}
            <div className="overlapsRenTxt">
              You are unable to amend or approve your own timesheets within{" "}
              {headerData.teamName}. Another manager of this team should do this
              instead.
              <br />
              <br />
              For permissions to amend or approve your own timesheets within
              this team, ask a FlowRota administrator to enable you to manage
              your own timesheets within {headerData.teamName}.
              <br />
              <br />
              Share this{" "}
              <a
                className="makeBold cursorPointer x8499282"
                href="https://flowrota.com/support/manager-self-allowances-enable"
                target="_blank"
              >
                guidance link
              </a>{" "}
              with them.
            </div>
            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  // setIsMgr(false);
                  memoVals.setShowViewTimesheet({ show: false });
                  memoVals.setShowTimesheet((x) => {
                    return {
                      timesheetID: memoVals.showViewTimesheet.timesheetID,
                    };
                  });

                  setShowCannotManageOwnShift(false);
                }}
              >
                View timesheet
              </p>{" "}
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  memoVals.setShowViewTimesheet((x) => {
                    return { show: false };
                  });
                }}
              >
                Close
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {imgPreload}
    </div>
  );
};

export default ViewTimesheetModal;

import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useMemo,
  memo,
} from "react";
// cleaned 11 aug 23
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios not needed

import axios from "axios";
import "../../styles/leaveRequests.css";
import { NavLink } from "react-router-dom";

import dateStringer from "../../tools/dateStringer";

import CheckUser from "../../tools/CheckUser";

import { DataContext } from "../../contexts/DataContext";
import { UserContext } from "../../contexts/UserContext";
import { RequestContext } from "../../contexts/RequestContext";
import { CalendarContext } from "../../contexts/CalendarContext";
import { StyleContext } from "../../contexts/StyleContext";

import MyReqsDataBox from "./MyReqsDataBox";
import MgrReqsDataBox from "./MgrReqsDataBox";
import TopBar from "../../components/TopBar";

import Navbar from "../Navbar";
import serverURL from "../../serverURL";
import horizontalBalls from "../../img/loaders/horizontalBalls.svg";

import closeGrey from "../../img/general/closeGrey.svg";
import redTriangle from "../../img/general/redTriangle.svg";
import whiteBack2 from "../../img/general/whiteBack2.svg";
import plane from "../../img/general/plane.svg";
import whiteGo from "../../img/general/whiteGo.svg";
import plane2 from "../../img/general/plane2.svg";
// import popupNavy from "../../img/general/popup.svg";
import popupNavy2 from "../../img/general/popupNavy.svg";
import rotaElipsis from "../../img/general/rotaElipsis.svg";
import rotaElipsis2 from "../../img/general/rotaElipsis2.svg";

import rotaDate from "../../img/general/rotaDate.svg";
import rotaEuro from "../../img/general/rotaEuro.svg";
import rotaPound from "../../img/general/rotaPound.svg";
import rotaDollar from "../../img/general/rotaDollar.svg";
import rotaDur from "../../img/general/rotaDur.svg";
import greenTick from "../../img/general/greenTick.svg";
import sun from "../../img/general/sun.svg";
import desktopBurger from "../../img/general/desktopBurger.svg";
import unitCross from "../../img/general/unitCross.svg";

import profilePicturePlaceholder from "../../img/general/profilePicturePlaceholder.svg";

import navyAdd from "../../img/general/navyAdd.svg";

const LeaveReqsPage = ({ isTeam }) => {
  CheckUser();
  const {
    setSelectedNav,
    reqsPage,
    setReqsPage,
    mgrTeams,
    setMgrTeams,
    teamHasPendingReqs,
    setTeamHasPendings,
    teamHasDecidedReqs,
    setTeamHasDecidedReqs,
    selectedTeam,
    setSelectedTeam,
    updateRequests,
    updateMgrReqSingleReqID,
    setUpdateMgrReqSingleReqID,
    setPage,
    showReqStats,
    setShowReqStats,
    setShowUserReqFromNav,
    updatedReq,
    showReqMgrModal,
    setShowReqMgrModal,
    deletingReqID,
    device,
    setShowAddLeaveOnTeam,
    selectedYear,
    setSelectedYear,
    showReqModal,
    updateUserReqs,
    setShowEditUser,
    countData,
    updateTabData,
  } = useContext(DataContext);
  const { setShowReqModal } = useContext(RequestContext);

  const { mob, full, width, wide, fullButNarrow } = useContext(StyleContext);
  const { updatedItem } = useContext(CalendarContext);
  const { checkUserState, currencySymbol } = useContext(UserContext);

  const memoVals = useMemo(
    () => ({
      setSelectedNav,
      reqsPage,
      setReqsPage,
      mgrTeams,
      setMgrTeams,
      teamHasPendingReqs,
      setTeamHasPendings,
      teamHasDecidedReqs,
      setTeamHasDecidedReqs,
      selectedTeam,
      setSelectedTeam,
      updateRequests,
      updateMgrReqSingleReqID,
      setUpdateMgrReqSingleReqID,
      setPage,
      showReqStats,
      setShowReqStats,
      setShowUserReqFromNav,
      updatedReq,
      showReqMgrModal,
      setShowReqMgrModal,
      deletingReqID,
      device,
      setShowAddLeaveOnTeam,
      selectedYear,
      setSelectedYear,
      showReqModal,
      mob,
      full,
      width,
      wide,
      fullButNarrow,
      updatedItem,
      updateUserReqs,
      checkUserState,
      currencySymbol,
      setShowEditUser,
      updateTabData,
      setShowReqModal,
    }),
    [
      setSelectedNav,
      reqsPage,
      setReqsPage,
      mgrTeams,
      setMgrTeams,
      teamHasPendingReqs,
      setTeamHasPendings,
      teamHasDecidedReqs,
      setTeamHasDecidedReqs,
      selectedTeam,
      setSelectedTeam,
      updateRequests,
      updateMgrReqSingleReqID,
      setUpdateMgrReqSingleReqID,
      setPage,
      showReqStats,
      setShowReqStats,
      setShowUserReqFromNav,
      updatedReq,
      showReqMgrModal,
      setShowReqMgrModal,
      deletingReqID,
      device,
      setShowAddLeaveOnTeam,
      selectedYear,
      setSelectedYear,
      showReqModal,
      mob,
      full,
      width,
      wide,
      fullButNarrow,
      updatedItem,
      updateUserReqs,
      checkUserState,
      currencySymbol,
      setShowEditUser,
      updateTabData,
      setShowReqModal,
    ]
  );

  let showReqs = useRef();
  // let upcomingRef = useRef();
  // let pastRef = useRef();
  // let declinedRef = useRef();

  let [requests, setRequests] = useState([]);
  let [pendingRequests, setPendingRequests] = useState([]);
  let [upcomingRequests, setUpcomingRequests] = useState([]);
  let [pastRequests, setPastRequests] = useState([]);
  let [declinedRequests, setDeclinedRequests] = useState([]);
  let [mgrRequests, setMgrRequests] = useState([]);

  let [initial, setInitial] = useState(false);

  useEffect(() => {
    if (isTeam) {
      document.title = "FlowRota - Staff leave";
      memoVals.setReqsPage((x) => "team");
    } else {
      document.title = "FlowRota - My leave";

      memoVals.setPage((x) => "upcoming");
    }
  }, [isTeam]);

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
  }, [reqsPage]);

  let [teamDeclined, setTeamDeclined] = useState(false);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params && params.reqID) {
      memoVals.setShowUserReqFromNav((x) =>
        dateStringer.cleanParam(params.reqID)
      );
      window.history.pushState({}, document.title, window.location.pathname);
    }

    if (params.myallowances) {
      memoVals.setShowReqStats(() => "myStats");
    }

    if (params && !params.staffallowances) {
      memoVals.setShowReqMgrModal((x) =>
        dateStringer.cleanParam(params.mgrReqID)
      );
      window.history.pushState({}, document.title, window.location.pathname);
    }

    if (
      params &&
      params.userID &&
      params.fName &&
      params.lName &&
      !memoVals.full
    ) {
      memoVals.setReqsPage((x) => {
        return {
          userID: dateStringer.cleanParam(params.userID),
          fName: dateStringer.cleanParam(params.fName),
          lName: dateStringer.cleanParam(params.lName),
        };
      });
      window.history.pushState({}, document.title, window.location.pathname);
    }
  }, []);

  let outstandingExists = (arr) => {
    let count = 0;
    arr.forEach((r) => {
      if (!r.approved) {
        // console.log("lll", r);

        count++;
      }
    });

    if (count > 0) {
      return true;
    }
  };

  // let [showAddLeaveOnTeam, setShowAddLeaveOnTeam] = useState(false);
  let [selectedType, setSelectedType] = useState("allTypes");

  useEffect(() => {
    setShowFilter(false);
    memoVals.setSelectedTeam((x) => "allstaff");
    if (selectedType !== "allTypes") {
      setSelectedType("allTypes");
    }
  }, [memoVals.reqsPage]);

  let [showFilter, setShowFilter] = useState(false);

  let [yearsArr, setYearsArr] = useState([new Date().getFullYear()]);

  useEffect(() => {
    if (!memoVals.selectedYear) {
      memoVals.setSelectedYear(
        (x) => checkUserState.currClientYear || new Date().getFullYear()
      );
    }
  }),
    [];

  let [types, setTypes] = useState([]);
  // modal to edit/delete request
  types &&
    types.sort(function (a, b) {
      return a.name.localeCompare(b.name);
    });

  let [showDupeModal, setShowDupeModal] = useState(false);

  let [showMgrHistory, setShowMgrHistory] = useState(false);

  // filter states
  let [showReqFilter, setShowReqFilter] = useState(false);
  let [allNames, setAllNames] = useState([]); // array of objects w/ fName, lName and userID
  let [filterUserID, setFilterUserID] = useState("everyone");

  let [imagesLoaded, setImagesLoaded] = useState(0);
  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if (dataLoaded && imagesLoaded === 18) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);

  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={sun}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={unitCross}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaElipsis2}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaElipsis}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={desktopBurger}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={profilePicturePlaceholder}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={
          memoVals.currencySymbol === "£"
            ? rotaPound
            : memoVals.currencySymbol === "$"
            ? rotaDollar
            : rotaEuro
        }
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={rotaDate}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaDur}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaElipsis}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={greenTick}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      {/* <img
        src={popupNavy}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      /> */}
      <img
        src={closeGrey}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={redTriangle}
        alt="2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whiteBack2}
        alt="3"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={plane}
        alt="4"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whiteGo}
        alt="5"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={plane2}
        alt="6"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      {/* <img
        src={popup2}
        alt="7"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      /> */}
      <img
        src={navyAdd}
        alt="8"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  let shortenLeaveName = (string) => {
    if (window.innerWidth < 400) {
      if (string.length > 17) {
        return `${string.substr(0, 15)}...`;
      } else {
        return string;
      }
    }
    if (string.length > 21) {
      return `${string.substr(0, 19)}...`;
    } else {
      return string;
    }
    if (string) {
      if (memoVals.mob || memoVals.full) {
        if (string.length > 17) {
          return `${string.substr(0, 15)}...`;
        } else {
          return string;
        }
      } else {
        if (memoVals.width < 1160) {
          if (string.length > 22) {
            return `${string.substr(0, 19)}...`;
          } else {
            return string;
          }
        } else {
          return string;
        }
      }
    }
  };

  // let formatMinsDurationToHours = (mins) => {
  //   if (mins === 0) {
  //     return "None";
  //   }
  //   let hours = Math.floor(mins / 60);
  //   let m = mins % 60;

  //   let hString = "hr";
  //   let mString = "m";

  //   if (m === 0) {
  //     mString = "";
  //     m = "";
  //   } else if (m === 1) {
  //     mString = "m";
  //   }

  //   if (hours === 0) {
  //     hString = "";
  //     hours = "";
  //     mString = "mins";
  //   } else if (hours === 1) {
  //     hString = "hr";
  //   }

  //   if (!m && hours > 1) {
  //     hString = " hours";
  //   } else if (!m && hours === 1) {
  //     hString = " hour";
  //   }
  //   if (memoVals.mob) {
  //     if (!m) {
  //       return `${hours}h`;
  //     } else {
  //       return `${hours}h ${m}m`;
  //     }
  //   } else {
  //     return `${hours}${hString}${hString && mString ? " " : ""}${
  //       m === "" ? m : Math.round(m)
  //     }${!hString && mins < 60 ? " " : ""}${mString}`;
  //   }
  // };

  let tonight2359 = new Date();
  tonight2359.setHours(23, 59, 59, 0);
  let today0000 = new Date();
  today0000.setHours(0, 0, 0, 0);

  useEffect(() => {
    if (!memoVals.reqsPage.userID && memoVals.reqsPage !== "team") {
      const cancelSource1 = axios.CancelToken.source();

      axios
        .post(
          `${serverURL}/get-all-user-requests`,
          {
            someData: 1,
            status: memoVals.reqsPage,
            nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
            year: memoVals.selectedYear,
            getCosts: true,
            // singleReqID: showUserReqModal
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            setYearsArr(response.data.yearsArr);
            setTypes(response.data.types);

            setRequests(response.data.array);
            // setMgrRequests(response.data.array);
            // let pendingArr = [];
            // response.data.array.forEach((req) => {
            //   if (!req.declined && !req.approved) {
            //     pendingArr.push(req);
            //   }
            // });
            // pendingArr.sort((a, b) => {
            //   return (
            //     dateStringer.createTimestampFromString(a.reqStart) -
            //     dateStringer.createTimestampFromString(b.reqStart)
            //   );
            // });
            // setPendingRequests(pendingArr);

            // let upcomingArr = [];
            // response.data.array.forEach((req) => {
            //   if (
            //     dateStringer.createTimestampFromString(req.reqEnd) >=
            //       today0000 &&
            //     !req.declined &&
            //     req.approved
            //   ) {
            //     upcomingArr.push(req);
            //   }
            // });
            // upcomingArr.sort((a, b) => {
            //   return (
            //     dateStringer.createTimestampFromString(a.reqStart) -
            //     dateStringer.createTimestampFromString(b.reqStart)
            //   );
            // });
            // setUpcomingRequests(upcomingArr);

            // let pastArr = [];
            // response.data.array.forEach((req) => {
            //   if (
            //     dateStringer.createTimestampFromString(req.reqEnd) <
            //       today0000 &&
            //     !req.declined &&
            //     req.approved
            //   ) {
            //     pastArr.push(req);
            //   }
            // });
            // pastArr.sort((a, b) => {
            //   return (
            //     dateStringer.createTimestampFromString(b.reqStart) -
            //     dateStringer.createTimestampFromString(a.reqStart)
            //   );
            // });
            // setPastRequests(pastArr);

            // let declinedArr = [];
            // response.data.array.forEach((req) => {
            //   if (req.declined && !req.approved) {
            //     declinedArr.push(req);
            //   }
            // });
            // declinedArr.sort((a, b) => {
            //   return (
            //     dateStringer.createTimestampFromString(a.reqStart) -
            //     dateStringer.createTimestampFromString(b.reqStart)
            //   );
            // });
            // setDeclinedRequests(declinedArr);
            if (dataLoaded) {
              setDataLoaded(false);
            }
            setDataLoaded(true);
          }
        })
        .catch((err) => {
          console.error(err);
        });

      return () => {
        cancelSource1.cancel("Component unmounted");
      };
    }
  }, [memoVals.reqsPage, memoVals.selectedYear, memoVals.updateUserReqs]);

  useEffect(() => {
    memoVals.setSelectedNav((x) => 3.1);

    memoVals.setUpdateMgrReqSingleReqID((x) => "");
  }, [memoVals.updateRequests, memoVals.reqsPage]);

  let [runUpdate, setRunUpdate] = useState(false);

  let [upcomingMgrApprovedCount, setUpcomingMgrApprovedCount] = useState(0);
  useEffect(() => {
    if (memoVals.reqsPage === "team" || memoVals.reqsPage.userID) {
      // const cancelSource1 = axios.CancelToken.source();
      const cancelSource2 = axios.CancelToken.source();
      //  const urlSearchParams = new URLSearchParams(window.location.search);
      //             const params = Object.fromEntries(urlSearchParams.entries());
      // axios
      //   .post(
      //     `${serverURL}/get-teams-user-manages`,
      //     { someData: 1 },

      //     {
      //       withCredentials: true,
      //       credentials: "include",
      //       cancelToken: cancelSource1.token,
      //     }
      //   )
      //   .then((response) => {
      //     if (response.status === 200 && response.data.message === "success") {
      //       memoVals.setMgrTeams((x) => response.data.array);
      //     }
      //   })
      //   .catch((err) => {
      //     console.error(err);
      //   });

      // get mgr's requests
      let status = "upcoming";
      if (showMgrHistory && !teamDeclined && !memoVals.reqsPage.userID) {
        status = "past";
      }
      if (showMgrHistory && teamDeclined && !memoVals.reqsPage.userID) {
        status = "declined";
      }

      axios
        .post(
          `${serverURL}/get-manager-requests-long`,
          {
            singleReqID: memoVals.updateMgrReqSingleReqID,
            year: memoVals.selectedYear,
            status,
            today0000: dateStringer.createStringFromTimestamp(
              today0000.getTime()
            ),
            userID: memoVals.reqsPage.userID || false,
            getCosts: false,
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource2.token,
          }
        )
        .then((response) => {
          if (response.data.message === "no permissions") {
            window.location.href = "/requests";
          }
          if (response.data.message === "success") {
            setYearsArr(response.data.yearsArr);

            if (!initial) {
              memoVals.setSelectedYear((x) => response.data.yearUsed);
              setInitial(true);
            }

            let arr = [];
            let arr2 = [];
            let usersArr = [];

            let types = [];
            let typeIDs = [];
            let appr = 0;
            response.data.array.forEach((req) => {
              if (!typeIDs.includes(req.leaveTypeID)) {
                typeIDs.push(req.leaveTypeID);
                types.push({
                  name: req.leaveName || "-",
                  typeID: req.leaveTypeID,
                });
              }

              if (status === "upcoming") {
                if (req.approved && !req.declined) {
                  appr++;
                }
              }

              if (!req.approved && !req.declined) {
                arr.push(req.teamID);
                arr.push("allstaff");
                usersArr.push({
                  fName: req.fName,
                  lName: req.lName,
                  userID: req.userID,
                });
              } else if (req.approved || req.declined) {
                arr2.push(req.teamID);
                arr2.push("allstaff");
                usersArr.push({
                  fName: req.fName,
                  lName: req.lName,
                  userID: req.userID,
                  teamID: req.teamID,
                });
              }
            });
            if (dataLoaded) {
              setDataLoaded(false);
            }

            setDataLoaded(true);
            if (status === "upcoming") {
              setUpcomingMgrApprovedCount(appr);
            } else {
              setUpcomingMgrApprovedCount(0);
            }
            setTypes(types);
            const urlSearchParams = new URLSearchParams(window.location.search);
            const params = Object.fromEntries(urlSearchParams.entries());
            setAllNames(response.data.allNames);

            if (params && params.staffallowances) {
              memoVals.setShowReqStats((x) => "mgr");
              window.history.pushState(
                {},
                document.title,
                window.location.pathname
              );
            }
            // if (!memoVals.reqsPage.userID) {
            //   if (memoVals.updateMgrReqSingleReqID) {
            //     let uniqueArrayOfNames = usersArr.filter(
            //       (thing, index, self) =>
            //         index === self.findIndex((t) => t.userID === thing.userID)
            //     );
            //     setAllNames(uniqueArrayOfNames);
            //   } else {
            //     setAllNames(response.data.allNames);
            //   }
            // }
            memoVals.teamHasPendingReqs.forEach((r) => {
              if (r && r.reqID !== memoVals.updateMgrReqSingleReqID) {
                arr.push(r);
              }
            });
            memoVals.setTeamHasPendings((x) => arr);

            memoVals.teamHasDecidedReqs.forEach((r) => {
              if (r && r.reqID !== memoVals.updateMgrReqSingleReqID) {
                arr2.push(r);
              }
            });
            memoVals.setTeamHasDecidedReqs((x) => arr2);

            // let arr3 = response.data.array || [];

            if (memoVals.updateMgrReqSingleReqID) {
              mgrRequests.forEach((r) => {
                if (r && r.reqID !== memoVals.updateMgrReqSingleReqID) {
                  arr3.push(r);
                }
              });
            }

            // setMgrRequests(response.data.array || []);

            if (memoVals.updateMgrReqSingleReqID) {
              mgrRequests.forEach((req) => {});
              if (
                !response.data.array[0].approved &&
                !response.data.array[0].declined
              ) {
              }
            } else {
              response.data.array.forEach((req) => {
                if (!req.approved && !req.declined) {
                }
              });
            }

            setMgrRequests(response.data.array);
          }
        })
        .catch((err) => {
          console.error(err);
        });

      return () => {
        // cancelSource1.cancel("Component unmounted");
        cancelSource2.cancel("Component unmounted");
      };
    }
  }, [
    memoVals.updateTabData,
    memoVals.reqsPage,
    runUpdate,
    showMgrHistory,
    teamDeclined,
    memoVals.deletingReqID,
    memoVals.selectedYear,
  ]);

  useEffect(() => {
    if (
      memoVals.updatedReq.reqID ||
      (memoVals.updatedReq.updatedObj && memoVals.updatedReq.updatedObj.reqID)
    ) {
      if (memoVals.updatedReq.delete) {
        if (memoVals.updatedReq.pending) {
          let newArr = [];
          pendingRequests.forEach((r) => {
            if (r.reqID !== memoVals.updatedReq.reqID) {
              newArr.push(r);
            }
          });
          setPendingRequests(newArr);
        }

        if (memoVals.updatedReq.approved) {
          let newArr = [];
          upcomingRequests.forEach((r) => {
            if (r.reqID !== memoVals.updatedReq.reqID) {
              newArr.push(r);
            }
          });
          setUpcomingRequests(newArr);
        }

        if (memoVals.updatedReq.declined) {
          let newArr = [];
          declinedRequests.forEach((r) => {
            if (r.reqID !== memoVals.updatedReq.reqID) {
              newArr.push(r);
            }
          });
          setDeclinedRequests(newArr);
        }
      }

      if (
        memoVals.updatedReq.updatedObj &&
        memoVals.updatedReq.updatedObj.makePending
      ) {
        // remove from all user arrays, and ensure is added to pendings array
        let declinedArr = [];
        declinedRequests.forEach((r) => {
          if (r.reqID !== memoVals.updatedReq.updatedObj.reqID) {
            declinedArr.push(r);
          }
        });
        setDeclinedRequests(declinedArr);

        let pastArr = [];
        pastRequests.forEach((r) => {
          if (r.reqID !== memoVals.updatedReq.updatedObj.reqID) {
            pastArr.push(r);
          }
        });
        setPastRequests(declinedArr);

        let upcomingArr = [];
        upcomingRequests.forEach((r) => {
          if (r.reqID !== memoVals.updatedReq.updatedObj.reqID) {
            upcomingArr.push(r);
          }
        });
        setUpcomingRequests(upcomingArr);

        let pendingArr = [];
        pendingRequests.forEach((r) => {
          if (r.reqID !== memoVals.updatedReq.updatedObj.reqID) {
            pendingArr.push(r);
          } else {
          }
        });
        pendingArr.push(memoVals.updatedReq.updatedObj);
        setPendingRequests(pendingArr);
      }
    }
  }, [memoVals.updatedReq]);

  useEffect(() => {
    if (memoVals.updatedItem) {
      let arr3 = [];

      mgrRequests.forEach((req) => {
        if (req.reqID === memoVals.updatedItem.reqID) {
          if (!memoVals.updatedItem.delete) {
            let newItem = memoVals.updatedItem;
            let filt = mgrRequests.filter((x) => {
              return x.reqID === memoVals.updatedItem.reqID;
            });
            if (filt[0]) {
              newItem["picUrl"] = filt[0].picUrl;
            }
            arr3.push(memoVals.updatedItem);
          }
        } else {
          arr3.push(req);
        }
      });

      setMgrRequests(arr3);
      // setUpdatedItem({});

      arr3.forEach((req) => {
        if (!req.approved && !req.declined) {
        }
      });
    }
  }, [memoVals.updatedItem]);

  useEffect(() => {
    if (showReqs.current) {
      showReqs.current.scrollTo(0, 0);
    }
  }, [
    memoVals.reqsPage,
    memoVals.selectedTeam,
    memoVals.selectedYear,
    selectedType,
    teamDeclined,
    showMgrHistory,
    filterUserID,
  ]);

  let createDate = (start, end) => {
    if (start && end) {
      let startDate = parseInt(start.substr(9, 2));
      let startMonth = parseInt(start.substr(6, 2));
      let endDate = parseInt(end.substr(9, 2));
      let endMonth = parseInt(end.substr(6, 2));
      let yr = `'${JSON.stringify(parseInt(end.substr(1, 4))).substr(2, 2)}`;
      let startMonthOutput = "";
      if (startMonth === 0) {
        startMonthOutput = "Jan";
      } else if (startMonth === 1) {
        startMonthOutput = "Feb";
      } else if (startMonth === 2) {
        startMonthOutput = "Mar";
      } else if (startMonth === 3) {
        startMonthOutput = "Apr";
      } else if (startMonth === 4) {
        startMonthOutput = "May";
      } else if (startMonth === 5) {
        startMonthOutput = "Jun";
      } else if (startMonth === 6) {
        startMonthOutput = "Jul";
      } else if (startMonth === 7) {
        startMonthOutput = "Aug";
      } else if (startMonth === 8) {
        startMonthOutput = "Sep";
      } else if (startMonth === 9) {
        startMonthOutput = "Oct";
      } else if (startMonth === 10) {
        startMonthOutput = "Nov";
      } else if (startMonth === 11) {
        startMonthOutput = "Dec";
      }

      let endMonthOutput = "";
      if (endMonth === 0) {
        endMonthOutput = "Jan";
      } else if (endMonth === 1) {
        endMonthOutput = "Feb";
      } else if (endMonth === 2) {
        endMonthOutput = "Mar";
      } else if (endMonth === 3) {
        endMonthOutput = "Apr";
      } else if (endMonth === 4) {
        endMonthOutput = "May";
      } else if (endMonth === 5) {
        endMonthOutput = "Jun";
      } else if (endMonth === 6) {
        endMonthOutput = "Jul";
      } else if (endMonth === 7) {
        endMonthOutput = "Aug";
      } else if (endMonth === 8) {
        endMonthOutput = "Sep";
      } else if (endMonth === 9) {
        endMonthOutput = "Oct";
      } else if (endMonth === 10) {
        endMonthOutput = "Nov";
      } else if (endMonth === 11) {
        endMonthOutput = "Dec";
      }

      if (memoVals.reqsPage === "team" && !showMgrHistory && !teamDeclined) {
        // ramadan
        if (startMonthOutput !== endMonthOutput) {
          return `${startDate} ${startMonthOutput} - ${endDate} ${endMonthOutput}`;
        } else if (
          startMonthOutput === endMonthOutput &&
          startDate === endDate
        ) {
          return `${startDate} ${endMonthOutput}`;
        } else {
          return `${startDate} - ${endDate} ${endMonthOutput}`;
        }
      } else {
        if (startMonthOutput !== endMonthOutput) {
          return `${startDate} ${startMonthOutput} - ${endDate} ${endMonthOutput} ${yr}`;
        } else if (
          startMonthOutput === endMonthOutput &&
          startDate === endDate
        ) {
          return `${startDate} ${endMonthOutput} ${yr}`;
        } else {
          return `${startDate} - ${endDate} ${endMonthOutput} ${yr}`;
        }
      }
    }
  };

  mgrRequests.sort((a, b) => {
    if (!memoVals.reqsPage.userID) {
      return (
        dateStringer.createTimestampFromString(a.reqStart) -
        dateStringer.createTimestampFromString(b.reqStart)
      );
    } else {
      return (
        dateStringer.createTimestampFromString(b.reqStart) -
        dateStringer.createTimestampFromString(a.reqStart)
      );
    }
  });

  let pendMgrQty = 0;

  if (!showMgrHistory && !teamDeclined && mgrRequests[0]) {
    mgrRequests.forEach((item) => {
      if (!item.approved && !item.declined) {
        pendMgrQty++;
      }
    });
  }

  let pendingMgrReqs = mgrRequests.filter(
    (item) => !item.approved && !item.declined
  );

  let generateMgrReqsPENDING = React.useMemo(() => {
    return pendingMgrReqs.map((item) => {
      let durationString = () => {
        let exc = 0;
        if (item.excessMins) {
          exc = item.excessMins;
        }
        let zMins = item.zMins ? item.zMins : 0;
        let val = Math.round(exc) + Math.round(zMins);
        if (val < 60) {
          return `${val}m`;
        }
        return `${dateStringer.formatMinsDurationToHours(val, false, true)}`;
      };

      if (
        memoVals.selectedTeam === item.teamID ||
        memoVals.selectedTeam === "allstaff"
      ) {
        if (filterUserID === item.userID || filterUserID === "everyone") {
          return (
            <div className="reqUnitParent" key={item.reqID}>
              <div
                onClick={() => {
                  memoVals.setShowReqMgrModal((x) => item.reqID);
                }}
                className={`mgrReqItem pend ${
                  memoVals.mob ? "borderRadius10x" : ""
                } leaveReqItem leaveReqItemLeave`}
              >
                {" "}
                <div className="unsubmittedTsBlob annLeaveBlob"></div>
                <div className="reqPlaneDiv">
                  <img
                    src={item.leaveTypeID === "annLeave" ? plane2 : plane}
                    alt="Requested leave"
                    className={`reqPlaneIcon ${
                      memoVals.mob ? "reqPlaneIconMob" : ""
                    }`}
                  />
                </div>
                <div className="reqColBarDiv divPend"></div>
                <div
                  className={`mgrReqItemCol2 ${
                    memoVals.mob ? "mgrReqItemCol2Mob" : ""
                  }`}
                >
                  <div className="mgrReqTopRow">
                    <div className="mgrReqName mgrReqName2">
                      {/* ------ */}
                      <div
                        className={`mgrReqDateStrip pendDateDiv x29898988444 staffAbsPeriodLeftContent`}
                      >
                        <div
                          className={`mgrReqDayLengthSpan reqsLeaveNameAboveCalIcons `}
                        >
                          <img
                            src={desktopBurger}
                            alt="Request duration"
                            className="rotaSunReqs leaveNameBurger"
                          />
                          {dateStringer.shorten(
                            item.leaveName,
                            memoVals.mob ? 20 : 30
                          )}
                        </div>

                        <div
                          className={`mgrReqDayLengthSpan upcomingMgrReqsSpan `}
                        >
                          <img
                            src={sun}
                            alt="Request duration"
                            className="rotaSunReqs"
                          />
                          {item.days} day
                          {item.days !== 1 ? "s" : ""}{" "}
                          <span className="colour00aaff x384849283455">
                            requested
                          </span>
                        </div>
                        <div
                          className={`mgrReqDayLengthSpan upcomingMgrReqsSpan upcomingMgrReqsSpanDur `}
                        >
                          <img
                            src={rotaDur}
                            alt="Request duration"
                            className="rotaSunReqs"
                          />
                          {item.ongoing ? "Ongoing" : durationString()}
                        </div>
                      </div>
                      {/* - - - - - - - - - - - - - - - - - - - - - - - - - */}
                    </div>
                    <div className="absTimelineVal absTimelineValReqs marginRight0">
                      <div
                        className={`absCalDiv ${
                          item.reqStart === item.reqEnd ? "invis" : ""
                        }`}
                      >
                        <div className="absCalDivUpper">
                          <div className="absDivCalNip"></div>
                          <div className="absDivCalNip"></div>
                        </div>
                        <div
                          className={`absCalDivLower ${
                            1 + 1 === 2
                              ? "absCalDivLowerAllTime"
                              : "absCalDivLowerAllTime"
                          }`}
                        >
                          <p className="absCalDivLowerDate absCalDivLowerDateReqs">
                            {parseInt(item.reqStart.substr(9, 2))}
                          </p>
                          <p className="absCalDivLowerMonth absCalDivLowerMonthReqs absCalDivLowerMonthReqs2">
                            {dateStringer.getMonthThreeLettersFromDs(
                              item.reqStart
                            )}
                          </p>

                          <p className="absCalDivLowerYear">
                            {item.reqStart.substr(1, 4)}
                          </p>
                        </div>
                      </div>
                      <div
                        className={`absCanDivSplitter ${
                          item.reqStart === item.reqEnd ? "invis" : ""
                        }`}
                      ></div>
                      <div className={`absCalDiv`}>
                        <div className="absCalDivUpper">
                          <div className="absDivCalNip"></div>
                          <div className="absDivCalNip"></div>
                        </div>
                        <div
                          className={`absCalDivLower ${
                            1 + 1 === 2
                              ? "absCalDivLowerAllTime"
                              : "absCalDivLowerAllTime"
                          }`}
                        >
                          <p className="absCalDivLowerDate absCalDivLowerDateReqs">
                            {parseInt(item.reqEnd.substr(9, 2))}
                          </p>
                          <p className="absCalDivLowerMonth absCalDivLowerMonthReqs absCalDivLowerMonthReqs2">
                            {dateStringer.getMonthThreeLettersFromDs(
                              item.reqEnd
                            )}
                          </p>{" "}
                          <p className="absCalDivLowerYear">
                            {item.reqStart.substr(1, 4)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`mgrReqNameTab pendClockName tabLeaveMgr`}
                onClick={() => {
                  memoVals.setShowEditUser((x) => {
                    return { userID: item.userID, allows: true };
                  });
                }}
              >
                <img
                  src={item.picUrl || profilePicturePlaceholder}
                  alt={item.fName}
                  className="itemNameTabImg"
                />{" "}
                {item.fName} {item.lName}
                {item.userID === memoVals.checkUserState.userID ? (
                  <span className="youSpan">(You)</span>
                ) : (
                  ""
                )}
              </div>
            </div>
          );
        }
      }
    });
  }, [pendingMgrReqs, memoVals.selectedTeam, filterUserID]);

  if (showMgrHistory) {
    mgrRequests.sort((a, b) => {
      return (
        dateStringer.createTimestampFromString(b.reqStart) -
        dateStringer.createTimestampFromString(a.reqStart)
      );
    });
  } else {
  }

  let mgrPrevQty = 0;
  let mgrDeclinedQty = 0;
  if (
    memoVals.reqsPage === "team" &&
    !memoVals.reqsPage.userID &&
    showMgrHistory &&
    !teamDeclined
  ) {
    let approvedFilt = mgrRequests.filter((obj) => obj.approved);
    approvedFilt.forEach((item) => {
      if (dateStringer.createTimestampFromString(item.reqEnd) < today0000) {
        mgrPrevQty++;
      }
    });
  }

  if (
    memoVals.reqsPage === "team" &&
    !memoVals.reqsPage.userID &&
    showMgrHistory &&
    teamDeclined
  ) {
    let declinedFilt = mgrRequests.filter((obj) => obj.declined);
    declinedFilt.forEach((item) => {
      mgrDeclinedQty++;
    });
  }

  let generateMgrReqs = React.useMemo(() => {
    return mgrRequests.map((item) => {
      if (
        (item.approved && showMgrHistory && !teamDeclined) ||
        (item.declined && showMgrHistory && teamDeclined) ||
        (!showMgrHistory && item.approved) ||
        memoVals.reqsPage.userID
      ) {
        let durationString = () => {
          let exc = 0;
          if (item.excessMins) {
            exc = item.excessMins;
          }
          let zMins = item.zMins ? item.zMins : 0;
          let val = Math.round(exc) + Math.round(zMins);
          if (val < 60) {
            return `${val}m`;
          }
          return `${dateStringer.formatMinsDurationToHours(val, false, true)}`;
        };

        let shortenLeaveName = (string) => {
          if (string && typeof string === "string") {
            if (memoVals.mob) {
              if (string && string.length > 20) {
                return `${string.substr(0, 17)}...`;
              } else {
                return string;
              }
            } else {
              if (string && string.length > 25) {
                return `${string.substr(0, 22)}...`;
              } else {
                return string;
              }
            }
          }
        };

        if (
          memoVals.selectedTeam === item.teamID ||
          memoVals.selectedTeam === "allstaff"
        ) {
          if (filterUserID === item.userID || filterUserID === "everyone") {
            return (
              <div
                className="reqUnitParent                   key={item.reqID}
              "
              >
                <div
                  onClick={() => {
                    memoVals.setShowReqMgrModal((x) => item.reqID);
                  }}
                  className={`mgrReqItem ${
                    item.approved
                      ? "leftBorderGreen"
                      : `${
                          item.declined && !item.ongoing
                            ? "leftBorderRed"
                            : "pendMgr"
                        }`
                  } mgrReqItemNonPendHover  ${
                    memoVals.mob ? "borderRadius10x" : ""
                  } leaveReqItem leaveReqItemLeave`}
                >
                  {" "}
                  <div className="reqPlaneDiv">
                    <img
                      src={item.leaveTypeID === "annLeave" ? plane2 : plane}
                      alt="Requested leave"
                      className={`reqPlaneIcon ${
                        memoVals.mob ? "reqPlaneIconMob" : ""
                      }`}
                    />
                  </div>
                  <div
                    className={`reqColBarDiv ${item.declined ? "divDec" : ""}`}
                  ></div>
                  <div
                    className={`mgrReqItemCol2 ${
                      memoVals.mob ? "mgrReqItemCol2Mob" : ""
                    }`}
                  >
                    <div className="mgrReqTopRow">
                      <div className="mgrReqName mgrReqName2">
                        {/* ------ */}
                        <div
                          className={`mgrReqDateStrip pendDateDiv x29898988444 x338994 staffAbsPeriodLeftContent`}
                        >
                          <div
                            className={`mgrReqDayLengthSpan reqsLeaveNameAboveCalIcons `}
                          >
                            <img
                              src={desktopBurger}
                              alt="Request duration"
                              className="rotaSunReqs leaveNameBurger"
                            />
                            {dateStringer.shorten(
                              item.leaveName,
                              memoVals.mob ? 26 : 330
                            )}
                          </div>

                          <div
                            className={`mgrReqDayLengthSpan upcomingMgrReqsSpan `}
                          >
                            <img
                              src={sun}
                              alt="Request duration"
                              className="rotaSunReqs"
                            />
                            {item.days} day
                            {item.days !== 1 ? "s" : ""}
                            <span className="reqAppSpanColourInline x384849283455">
                              {item.approved ? " approved" : ""}
                            </span>
                          </div>
                          <div
                            className={`mgrReqDayLengthSpan upcomingMgrReqsSpan upcomingMgrReqsSpanDur ${
                              item.declined ? "ba0000" : ""
                            }`}
                          >
                            <img
                              src={item.declined ? unitCross : rotaDur}
                              alt="Request duration"
                              className="rotaSunReqs"
                            />
                            {item.declined
                              ? "Declined"
                              : item.ongoing
                              ? "Ongoing"
                              : durationString()}
                          </div>
                        </div>
                        {/* - - - - - - - - - - - - - - - - - - - - - - - - - */}
                      </div>
                      <div className="absTimelineVal absTimelineValReqs marginRight0">
                        <div
                          className={`absCalDiv ${
                            item.reqStart === item.reqEnd ? "invis" : ""
                          }`}
                        >
                          <div className="absCalDivUpper">
                            <div className="absDivCalNip"></div>
                            <div className="absDivCalNip"></div>
                          </div>
                          <div
                            className={`absCalDivLower ${
                              1 + 1 === 2
                                ? "absCalDivLowerAllTime"
                                : "absCalDivLowerAllTime"
                            }`}
                          >
                            <p className="absCalDivLowerDate absCalDivLowerDateReqs">
                              {parseInt(item.reqStart.substr(9, 2))}
                            </p>
                            <p className="absCalDivLowerMonth absCalDivLowerMonthReqs absCalDivLowerMonthReqs2">
                              {dateStringer.getMonthThreeLettersFromDs(
                                item.reqStart
                              )}
                            </p>

                            <p className="absCalDivLowerYear">
                              {item.reqStart.substr(1, 4)}
                            </p>
                          </div>
                        </div>
                        <div
                          className={`absCanDivSplitter ${
                            item.reqStart === item.reqEnd ? "invis" : ""
                          }`}
                        ></div>
                        <div className={`absCalDiv`}>
                          <div className="absCalDivUpper">
                            <div className="absDivCalNip"></div>
                            <div className="absDivCalNip"></div>
                          </div>
                          <div
                            className={`absCalDivLower ${
                              1 + 1 === 2
                                ? "absCalDivLowerAllTime"
                                : "absCalDivLowerAllTime"
                            }`}
                          >
                            <p className="absCalDivLowerDate absCalDivLowerDateReqs">
                              {parseInt(item.reqEnd.substr(9, 2))}
                            </p>
                            <p className="absCalDivLowerMonth absCalDivLowerMonthReqs absCalDivLowerMonthReqs2">
                              {dateStringer.getMonthThreeLettersFromDs(
                                item.reqEnd
                              )}
                            </p>{" "}
                            <p className="absCalDivLowerYear">
                              {item.reqStart.substr(1, 4)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div
                    className={`itemTongue leaveTongue${
                      item.declined ? "Declined" : ""
                    }`}
                  >
                    <p className="tongueTxt leaveTongueTxt">Leave</p>
                  </div> */}
                </div>
                <div
                  className={`mgrReqNameTab tabLeaveMgr`}
                  onClick={() => {
                    memoVals.setShowEditUser((x) => {
                      return { userID: item.userID, allows: true };
                    });
                  }}
                >
                  <img
                    src={item.picUrl || profilePicturePlaceholder}
                    alt={item.fName}
                    className="itemNameTabImg"
                  />{" "}
                  {item.fName} {item.lName}
                  {item.userID === memoVals.checkUserState.userID ? (
                    <span className="youSpan">(You)</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            );
          }
        }
      }
    });
  }, [mgrRequests, memoVals.selectedTeam, filterUserID]);

  let generateRequests = requests.map((req) => {
    let durationString = () => {
      let exc = 0;
      if (req.excessMins) {
        exc = req.excessMins;
      }
      let zMins = req.zMins ? req.zMins : 0;
      let val = Math.round(exc) + Math.round(zMins);
      if (val < 60) {
        return `${val}m`;
      }
      return `${dateStringer.formatMinsDurationToHours(val, false, true)}`;
    };
    return (
      <div
        className={`dvStateHighlight dvStateHighlightShift ${
          !memoVals.mob
            ? "absShiftItemDesktop leaveItemNewDesktop"
            : "absShiftItemMob leaveNewItemWidth"
        } newMyAbsShFlex ${
          !req.approved && !req.declined ? "myLeaveItemHeight" : ""
        }`}
        key={req.reqID}
        onClick={() => {
          memoVals.setShowUserReqFromNav((x) => req.reqID);
        }}
      >
        <div
          className={`newMyAbsShLeft ${
            memoVals.mob ? "newMyAbsShLeftMob" : ""
          }`}
        >
          {!req.approved && !req.declined && (
            <div className="myHoursNeedAccLeave myHoursNeedAccLeave2"></div>
          )}
          {!req.approved && !req.declined ? (
            ""
          ) : (
            <img
              src={req.leaveTypeID === "annLeave" ? plane2 : plane}
              alt="User shift"
              className={`openShiftBlockDayViewImg openShiftBlockDayViewImgShift x3289478222Req ${
                memoVals.mob
                  ? "mobLeaveNewImg"
                  : reqsPage === "declined"
                  ? "x3289478222ReqDeclinedDesktop"
                  : reqsPage === "past"
                  ? "x3289478222ReqPastDesktop"
                  : ""
              }`}
            />
          )}
          <div className="dvStateHighlightedNote paddingLeft6px">
            <span className="weight600">{req.leaveTypeName}</span>
            <br />
            <p className="dvStateHighlightedNoteName">
              <img
                src={sun}
                alt="Rota clock inline"
                className="rotaClockOpenDvInlineImg x23984937842"
              />
              {req.ongoing
                ? "On-going"
                : `${req.days} day${req.days === 1 ? "" : "s"}`}
              {/* {dateStringer.printedDateFromDs(
                req.reqStart,
                req.reqStart.substr(1, 4) !== req.reqEnd.substr(1, 4) ||
                  req.reqStart.substr(0, 11) === req.reqEnd.substr(0, 11)
                  ? true
                  : false
              )}{" "}
              {req.reqStart.substr(0, 11) !== req.reqEnd.substr(0, 11)
                ? "-"
                : ""}{" "}
              {req.reqStart.substr(0, 11) !== req.reqEnd.substr(0, 11)
                ? dateStringer.printedDateFromDs(req.reqEnd, true)
                : ""} */}
            </p>

            <div className={`dvShiftNameRow `}>
              <img
                src={req.approved ? greenTick : rotaElipsis}
                className="dvShPP"
                alt="Leave request status"
              />{" "}
              <p
                className={`dvShName colour143051_ ${
                  req.approved
                    ? "ccApp"
                    : req.declined
                    ? "ccDec"
                    : "colour00aaff"
                }`}
              >
                {req.approved
                  ? "Approved"
                  : req.declined
                  ? "Declined"
                  : "Awaiting approval"}
              </p>
            </div>
            <div className={`dvShiftNameRow `}>
              <img src={rotaDur} className="dvShPP" alt="Leave duration" />{" "}
              <p className="dvShName colour143051_">
                {req.ongoing ? "On-going leave period" : durationString()}
              </p>
            </div>
            {memoVals.reqsPage !== "declined" && (
              <div
                className={`dvShiftNameRow ${
                  (!req.unpaid && !memoVals.checkUserState.showCosts) ||
                  req.ongoing
                    ? "none"
                    : ""
                }`}
              >
                <img
                  src={
                    memoVals.currencySymbol === "£"
                      ? rotaPound
                      : memoVals.currencySymbol === "$"
                      ? rotaDollar
                      : rotaEuro
                  }
                  className="dvShPP dvShPPMoney"
                  alt="leave pay"
                />{" "}
                <p className="dvShName colour143051_">
                  {req.unpaid
                    ? "Unpaid"
                    : req.salaried
                    ? `Salary${
                        parseFloat(req.cost) > 0
                          ? ` + ${
                              memoVals.currencySymbol
                            }${dateStringer.formatCost(req.cost)}`
                          : parseFloat(req.cost) > 0
                          ? req.cost
                          : ""
                          ? `${
                              memoVals.currencySymbol
                            }${dateStringer.formatCost(req.cost)}`
                          : ""
                      }`
                    : `${memoVals.currencySymbol}${dateStringer.formatCost(
                        req.cost
                      )}`}
                  {/* !checkUserState.showCosts ? (
                    ""
                  ) : item.salaried && !item.ongoing ? (
                    <p
                      className={`mgrReqCostTxt  ${
                        memoVals.mob ? "none" : ""
                      } `}
                    >
                      {memoVals.mob ? "Sal" : "Salary"}{" "}
                      {item.cost > 0 ? (
                        <span className="reqSalPlusSpan">
                          + {memoVals.currencySymbol}
                          {Math.round(item.cost)}
                        </span>
                      ) : (
                        ""
                      )}
                    </p>
                  ) : (
                    <p
                      className={`mgrReqCostTxt  ${
                        memoVals.mob ? "none" : ""
                      }  ${item.ongoing ? "none" : ""}`}
                    >
                      {memoVals.currencySymbol}
                      {Math.round(item.cost)}
                    </p> */}
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="myShRightDiv noOverflowLeaveCal">
          <div className="absTimelineVal absTimelineValReqs marginRight0">
            <div
              className={`absCalDiv ${
                req.reqStart === req.reqEnd ? "invis" : ""
              }`}
            >
              <div className="absCalDivUpper">
                <div className="absDivCalNip"></div>
                <div className="absDivCalNip"></div>
              </div>
              <div
                className={`absCalDivLower ${
                  1 + 1 === 2
                    ? "absCalDivLowerAllTime"
                    : "absCalDivLowerAllTime"
                }`}
              >
                <p className="absCalDivLowerDate absCalDivLowerDateReqs">
                  {parseInt(req.reqStart.substr(9, 2))}
                </p>
                <p className="absCalDivLowerMonth absCalDivLowerMonthReqs absCalDivLowerMonthReqs2">
                  {dateStringer.getMonthThreeLettersFromDs(req.reqStart)}
                </p>

                <p className="absCalDivLowerYear">
                  {req.reqStart.substr(1, 4)}
                </p>
              </div>
            </div>
            <div
              className={`absCanDivSplitter ${
                req.reqStart === req.reqEnd ? "invis" : ""
              }`}
            ></div>
            <div className={`absCalDiv`}>
              <div className="absCalDivUpper">
                <div className="absDivCalNip"></div>
                <div className="absDivCalNip"></div>
              </div>
              <div
                className={`absCalDivLower ${
                  1 + 1 === 2
                    ? "absCalDivLowerAllTime"
                    : "absCalDivLowerAllTime"
                }`}
              >
                <p className="absCalDivLowerDate absCalDivLowerDateReqs">
                  {parseInt(req.reqEnd.substr(9, 2))}
                </p>
                <p className="absCalDivLowerMonth absCalDivLowerMonthReqs absCalDivLowerMonthReqs2">
                  {dateStringer.getMonthThreeLettersFromDs(req.reqEnd)}
                </p>{" "}
                <p className="absCalDivLowerYear">
                  {req.reqStart.substr(1, 4)}
                </p>
              </div>
            </div>
          </div>
          {/* {req.ongoing ? (
            <div
              className={`newMyAbsShRightSec tilShiftDurationTxtTeam  absentButPaid ${
                memoVals.mob ? "newMyAbsShRightSecMob" : ""
              } shiftItemDurMins`}
            >
              On-going
            </div>
          ) : (
            <div
              className={`newMyAbsShRightSec tilShiftDurationTxtTeam  absentButPaid ${
                memoVals.mob ? "newMyAbsShRightSecMob" : ""
              } shiftItemDurMins`}
            >
              {req.days} day{req.days === 1 ? "" : "s"}
            </div>
          )} */}
        </div>
      </div>
    );
  });

  let generateTypes = React.useMemo(() => {
    return types.map((t) => {
      return <option value={t.leaveTypeID}>{t.name}</option>;
    });
  }, [types]);

  declinedRequests.sort((a, b) => {
    return (
      dateStringer.createTimestampFromString(b.reqStart) -
      dateStringer.createTimestampFromString(a.reqStart)
    );
  });

  pastRequests.sort((a, b) => {
    return (
      dateStringer.createTimestampFromString(b.reqStart) -
      dateStringer.createTimestampFromString(a.reqStart)
    );
  });

  allNames &&
    allNames.sort(function (a, b) {
      return a.fName.localeCompare(b.fName);
    });

  let generateAllNamesFilter = allNames.map((item) => {
    let isItInTheMgrReqsArray = mgrRequests.filter(
      (item2) =>
        item2.userID === item.userID &&
        (memoVals.selectedTeam === item.teamID ||
          memoVals.selectedTeam === "allstaff")
    );

    if (isItInTheMgrReqsArray[0]) {
      return (
        <option key={item.userID} value={item.userID}>{`${item.fName} ${
          item.lName ? item.lName[0] : ""
        }`}</option>
      );
    } else {
      return "";
    }
  });

  let todayDateObject = new Date();
  todayDateObject.setHours(0, 0, 0, 0);
  let todayDateString = dateStringer.createStringFromTimestamp(
    todayDateObject.getTime()
  );

  let generateYearsArr = useMemo(() => {
    yearsArr.sort();
    return (
      yearsArr &&
      yearsArr.map((year) => {
        // if (parseInt(year) <= new Date().getFullYear()) {
        return (
          <option key={year} value={year}>
            {year}
          </option>
        );
        // }
      })
    );
  }, [yearsArr]);

  let typesPending = (type) => {
    let flag = false;

    pendingRequests.forEach((r) => {
      if (r.leaveTypeID === type) {
        flag = true;
      }
    });

    return flag;
  };

  let typesApproved = (type) => {
    let flag = false;

    upcomingRequests.forEach((r) => {
      if (r.leaveTypeID === type) {
        flag = true;
      }
    });

    return flag;
  };

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
      console.log("BACK BUTTON PRESSED", memoVals.reqsPage);
      if (memoVals.reqsPage.userID) {
        console.log("Going back from selected staff");
        memoVals.setReqsPage((x) => "team");
        setShowMgrHistory(false);
        setTeamDeclined(false);
      }

      if (memoVals.reqsPage === "team") {
        memoVals.setReqsPage((x) => "upcoming");
      }
    });
  }, [memoVals.reqsPage]);
  // MASTER RETURN
  return (
    <div className="reqsPageHolder">
      {/* {renderLeaveReqModal()} {renderUserLeaveReqModal()} */}
      <div
        className={`${
          memoVals.reqsPage === "team" || memoVals.reqsPage.userID
            ? "reqsPageMgr"
            : "reqsPage"
        }`}
      >
        {/* this is for phone top corners -- i know it will work well with andriod, but unsure about iphone as the top bar won't be black */}
        <div className="blackCornerBg"></div>
        {/* end */}
        <div
          className={`${
            (memoVals.reqsPage === "team" || memoVals.reqsPage.userID) &&
            memoVals.mob
              ? "reqsPageUpperContainerTeam"
              : "reqsPageUpperContainer"
          } ${!memoVals.mob ? "pageLeft240pxDesktop" : ""} ${
            memoVals.mob ? "noBorderBottom" : ""
          }`}
        >
          {!memoVals.mob ? <TopBar /> : ""}
          {/* Reqs page header here */}
          <div className="pageHeaderMain none">
            <div className="pageHeaderRow1">
              <p className="pageHeaderRow1Title">Requests</p>
              <div className="pageHeaderRowMenuRow">
                <div className="pageHeaderRow1MenuItem">Upcoming</div>
              </div>
            </div>

            <div className="pageHeaderRow2">
              <div className="pageHeaderRow2TeamBtn">Staff leave</div>
              <div className="pageHeaderRow2StatstBn">Allowances</div>
            </div>
          </div>

          <div className="shiftsHeaderContentContainer">
            <div
              className={`shiftsHeaderLeft ${
                memoVals.reqsPage === "team" && memoVals.mob
                  ? "mobTeamReqsHeaderLeft"
                  : ""
              } ${memoVals.wide ? "wideTitle" : ""}`}
            >
              {/* {memoVals.selectedYear} */}
              <div
                className={`myRequestsHeaderLeftTitle ${
                  memoVals.reqsPage === "team" || memoVals.reqsPage.userID
                    ? `staffColour ${memoVals.mob ? "colorWhite" : ""}`
                    : ""
                } ${
                  memoVals.reqsPage === "team" && memoVals.mob
                    ? "staffReqsTitleDynamic"
                    : ""
                } ${!memoVals.mob ? "headerTitleSize myReqsTitle2" : ""} ${
                  memoVals.mob
                    ? `${
                        memoVals.reqsPage !== "team"
                          ? "myRequestsHeaderLeftTitleMob3"
                          : "myRequestsHeaderLeftTitleMob2"
                      }`
                    : ` ${
                        memoVals.reqsPage === "team" || memoVals.reqsPage.userID
                          ? "headerTitleColourMgr"
                          : ""
                      }`
                }`}
              >
                {(memoVals.reqsPage === "team" || memoVals.reqsPage.userID) &&
                !memoVals.mob ? (
                  <span className="staffTitleLeftBlob">.</span>
                ) : (
                  ""
                )}
                {memoVals.reqsPage.userID
                  ? `${memoVals.reqsPage.fName}'s leave`
                  : memoVals.reqsPage !== "team"
                  ? `${memoVals.mob ? `My leave` : "My leave"}`
                  : "Staff leave"}
              </div>
              {memoVals.reqsPage.userID ? (
                <div
                  className={`${
                    memoVals.mob ? "namedReqsBackBtnMob" : "namedReqsBackBtn"
                  } none`}
                  onClick={() => {
                    memoVals.setReqsPage((x) => "team");
                    setShowMgrHistory(false);
                    setTeamDeclined(false);
                  }}
                >
                  <img
                    src={whiteBack2}
                    className="namedReqsBackBtnImg"
                    alt="Back"
                  />{" "}
                  Staff leave
                </div>
              ) : (
                <div
                  className={`reqsHeaderMenuRow ${
                    memoVals.reqsPage === "team" ? "reqsHeaderMenuRowTeam" : ""
                  } ${memoVals.mob ? "reqsHeaderMenuRowMob" : ""}`}
                >
                  <div
                    className={`shiftHeaderMenuItem ${
                      memoVals.reqsPage === "upcoming"
                        ? `shiftHeaderMenuItemSelect`
                        : ""
                    } ${
                      memoVals.reqsPage === "team" && showMgrHistory
                        ? "reqsTeamHoverBtn"
                        : ""
                    } ${
                      memoVals.mob && memoVals.reqsPage === "team"
                        ? "noHover"
                        : ""
                    } ${
                      memoVals.reqsPage === "team" &&
                      !showMgrHistory &&
                      !teamDeclined
                        ? `reqsMenuTeamBtnSel ${
                            memoVals.mob ? "colourWhite" : ""
                          }`
                        : ""
                    }`}
                    onClick={() => {
                      if (memoVals.reqsPage === "team") {
                        if (showMgrHistory || teamDeclined) {
                          setLoading(true);
                          setShowMgrHistory(false);
                          setTeamDeclined(false);
                        }
                      } else {
                        if (memoVals.reqsPage !== "upcoming") {
                          setLoading(true);
                          memoVals.setReqsPage((x) => "upcoming");
                        }
                      }
                    }}
                  >
                    {/* Upcoming */}
                    <p className="headerMenuTopRow">
                      Upcoming{" "}
                      {memoVals.reqsPage === "team" ? (
                        countData.leaveReqs > 0 ? (
                          <p className="shiftsHeaderQty shiftsHeaderQtyMgr">
                            {countData.leaveReqs > 9
                              ? "9+"
                              : countData.leaveReqs}
                          </p>
                        ) : (
                          ""
                        )
                      ) : countData.userLeave ? (
                        <p className="shiftsHeaderQty">
                          {countData.userLeave > 9 ? "9+" : countData.userLeave}
                        </p>
                      ) : (
                        ""
                      )}
                    </p>

                    <p
                      className={`headerMenuBottomRow ${
                        memoVals.reqsPage === "upcoming" ||
                        (memoVals.reqsPage === "team" &&
                          !showMgrHistory &&
                          !teamDeclined)
                          ? "none"
                          : "x198389191"
                      } `}
                    ></p>
                    <p
                      className={`headerMenuBottomRow ${
                        memoVals.reqsPage === "upcoming" ||
                        (memoVals.reqsPage === "team" &&
                          !showMgrHistory &&
                          !teamDeclined)
                          ? ""
                          : "none"
                      }  ${
                        memoVals.reqsPage === "team" ? "purpeMenuBottom" : ""
                      }`}
                    ></p>
                  </div>{" "}
                  <div className="subHeadSplit"></div>
                  <div
                    className={`${
                      memoVals.reqsPage === "team"
                        ? `shifHeaderMenuSplitterTeam ${
                            memoVals.mob ? "mobTeamHoursSplitterTeam" : ""
                          }`
                        : "shifHeaderMenuSplitter"
                    }`}
                  ></div>{" "}
                  <div
                    className={`shiftHeaderMenuItem ${
                      memoVals.reqsPage === "past"
                        ? `shiftHeaderMenuItemSelect`
                        : ""
                    } ${
                      memoVals.reqsPage === "team" &&
                      (!showMgrHistory || teamDeclined)
                        ? "reqsTeamHoverBtn"
                        : ""
                    } ${
                      memoVals.mob && memoVals.reqsPage === "team"
                        ? "noHover"
                        : ""
                    } ${
                      memoVals.reqsPage === "team" &&
                      showMgrHistory &&
                      !teamDeclined
                        ? `reqsMenuTeamBtnSel ${
                            memoVals.mob ? "colourWhite" : ""
                          }`
                        : ""
                    }`}
                    onClick={() => {
                      if (memoVals.reqsPage === "team") {
                        if (teamDeclined || !showMgrHistory) {
                          setLoading(true);

                          setTeamDeclined(false);
                          setShowMgrHistory(true);
                        }
                      } else {
                        memoVals.setPage((x) => "past");
                        if (memoVals.reqsPage !== "past") {
                          setLoading(true);
                          memoVals.setReqsPage((x) => "past");
                        }
                      }
                    }}
                  >
                    <p className="headerMenuTopRow">Past</p>

                    <p
                      className={`headerMenuBottomRow ${
                        memoVals.reqsPage === "past" ||
                        (memoVals.reqsPage === "team" &&
                          showMgrHistory &&
                          !teamDeclined)
                          ? "none"
                          : "x198389191"
                      } `}
                    ></p>
                    <p
                      className={`headerMenuBottomRow ${
                        memoVals.reqsPage === "past" ||
                        (memoVals.reqsPage === "team" &&
                          showMgrHistory &&
                          !teamDeclined)
                          ? ""
                          : "none"
                      }  ${
                        memoVals.reqsPage === "team" ? "purpeMenuBottom" : ""
                      }`}
                    ></p>
                  </div>{" "}
                  <div className="subHeadSplit"></div>
                  <div
                    className={`shiftHeaderMenuItem ${
                      memoVals.reqsPage === "declined"
                        ? `shiftHeaderMenuItemSelect`
                        : ""
                    } ${
                      memoVals.reqsPage === "team" &&
                      (!showMgrHistory || !teamDeclined)
                        ? "reqsTeamHoverBtn"
                        : ""
                    } ${
                      memoVals.mob && memoVals.reqsPage === "team"
                        ? "noHover"
                        : ""
                    } ${
                      memoVals.reqsPage === "team" &&
                      showMgrHistory &&
                      teamDeclined
                        ? `reqsMenuTeamBtnSel ${
                            memoVals.mob ? "colourWhite" : ""
                          }`
                        : ""
                    }`}
                    onClick={() => {
                      if (memoVals.reqsPage === "team") {
                        if (!teamDeclined) {
                          setLoading(true);
                          setTeamDeclined(true);
                          setShowMgrHistory(true);
                        }
                        // daisy
                      } else {
                        memoVals.setPage((x) => "declined");

                        if (memoVals.reqsPage !== "declined") {
                          setLoading(true);
                          memoVals.setReqsPage((x) => "declined");
                        }
                      }
                    }}
                  >
                    <p className="headerMenuTopRow">Declined</p>

                    <p
                      className={`headerMenuBottomRow ${
                        memoVals.reqsPage === "declined" ||
                        (memoVals.reqsPage === "team" && teamDeclined)
                          ? "none"
                          : "x198389191"
                      } `}
                    ></p>
                    <p
                      className={`headerMenuBottomRow ${
                        memoVals.reqsPage === "declined" ||
                        (memoVals.reqsPage === "team" && teamDeclined)
                          ? ""
                          : "none"
                      }  ${
                        memoVals.reqsPage === "team" && teamDeclined
                          ? "purpeMenuBottom"
                          : ""
                      }`}
                    ></p>
                  </div>
                </div>
              )}
            </div>

            {memoVals.reqsPage !== "team" &&
            (checkUserState.permissions < 3 || checkUserState.indiOnly) ? (
              <NavLink
                to="/team-leave"
                // to={`${!shiftsPage.includes("team") ? "/team-hours" : "/hours"}`}
                onClick={() => {
                  memoVals.setReqsPage((x) => "team");
                }}
              >
                <div
                  className={`staffHoursBtn staffReqsBtn minWidth164 pinkColours ${
                    memoVals.mob && memoVals.reqsPage.userID ? "none_" : ""
                  } ${
                    window.innerWidth > 520
                      ? `staffReqsWiderThan520 ${
                          !memoVals.mob ? "staffReqsWiderThan520Full" : ""
                        }`
                      : ""
                  }`}
                >
                  <p className="teamHrsBtn">
                    {memoVals.full || !memoVals.full
                      ? typeof memoVals.reqsPage === "object" &&
                        memoVals.reqsPage.userID
                        ? "All staff leave"
                        : "Staff leave"
                      : "Staff"}
                  </p>
                  <img src={whiteGo} alt="Team" className="whiteGoImg" />
                  {countData.leaveReqs > 0 ? (
                    <p className="staffHoursQtyBub staffHoursQtyBubMgr">
                      {countData.leaveReqs > 9 ? "9+" : countData.leaveReqs}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </NavLink>
            ) : (
              ""
            )}
            {memoVals.reqsPage === "team" ? (
              <NavLink
                to="/leave"
                // to={`${!shiftsPage.includes("team") ? "/team-hours" : "/hours"}`}
                onClick={() => {
                  // console.log("setting reqs page to team");
                  memoVals.setReqsPage((x) => "upcoming");
                }}
              >
                <div
                  className={`staffHoursBtn staffReqsBtn minWidth164 ${
                    memoVals.mob && memoVals.reqsPage.userID ? "none" : ""
                  } ${
                    memoVals.mob && memoVals.reqsPage === "team"
                      ? "staffHoursBtnDynamic"
                      : ""
                  } ${
                    window.innerWidth > 520
                      ? `staffReqsWiderThan520 ${
                          memoVals.reqsPage === "team"
                            ? memoVals.mob
                              ? "staffReqsWiderThan520Team"
                              : ""
                            : ""
                        } ${!memoVals.mob ? "staffReqsWiderThan520Full" : ""}`
                      : ""
                  } blueColours`}
                >
                  <p className="teamHrsBtn">My leave</p>
                  <img src={whiteGo} alt="Team" className="whiteGoImg" />
                </div>
              </NavLink>
            ) : (
              ""
            )}
          </div>
        </div>

        <div
          className={`requestPageParent ${
            !memoVals.mob
              ? "pageLeft240pxDesktop borderLeftGrey"
              : `${
                  memoVals.reqsPage !== "team" && !memoVals.reqsPage.userID
                    ? "whiteBg"
                    : ""
                }`
          } `}
        >
          {" "}
          <div
            className={`desktopMaster ${
              (memoVals.mob && memoVals.reqsPage === "team") ||
              memoVals.reqsPage.userID
                ? "desktopPurpBg"
                : "desktopWhiteBg"
            }`}
          >
            <div
              className={`desktopMasterMain  ${
                memoVals.mob ? "mob100Witdth" : ""
              } ${!memoVals.full && !memoVals.mob ? "midContentWidth" : ""}`}
            >
              <div
                className={`requestsContainer ${
                  memoVals.mob
                    ? `mobReqsContainerFullWidth ${
                        memoVals.reqsPage !== "team"
                          ? "mobReqsContainerFullWidthUser"
                          : ""
                      }`
                    : ""
                }`}
              >
                {/* requests container starts */}

                {/* UPCOMING PAGE INCLUDING YET TO BE APPROVED */}
                {memoVals.reqsPage !== "team" && !memoVals.reqsPage.userID ? (
                  <img
                    src={horizontalBalls}
                    alt="loading"
                    className={`${loading ? "blueLoadingReqs" : "none"}`}
                  />
                ) : (
                  ""
                )}
                {memoVals.reqsPage !== "team" &&
                !memoVals.reqsPage.userID &&
                !loading ? (
                  <div
                    className={`reqsPageFilterHolder ${
                      !memoVals.mob ? "borderRightGrey" : "noBorderRight_"
                    } ${loading ? "noBorder" : ""}`}
                  >
                    <div
                      className={`reqPageFilterBarMy ${
                        memoVals.reqsPage !== "team" && !memoVals.full
                          ? `reqPageFilterBarMyUpcomingNonFull ${
                              !memoVals.mob
                                ? "reqPageFilterBarMyUpcomingMedium"
                                : ""
                            }`
                          : ""
                      }`}
                    >
                      {memoVals.reqsPage === "upcoming" && !memoVals.full ? (
                        <div
                          className={`myAllowsWhiteBtn ${
                            checkUserState.permissions > 2
                              ? "nonMgrAvailBtn"
                              : ""
                          } ${
                            memoVals.mob
                              ? "myAllowsWhiteBtnMob myAllowsWhiteBtnMob2"
                              : ""
                          }`}
                          onClick={() => {
                            memoVals.setShowReqStats((x) => "myStats");
                          }}
                        >
                          {memoVals.mob ? "Allowances" : "My allowances"}
                          <img
                            src={popupNavy2}
                            alt="Staff rota"
                            className={`availPopupImg ${
                              window.innerWidth < 370 ? "invis" : ""
                            }`}
                          />
                        </div>
                      ) : (
                        // <div className="myAllowancesBtnInline">
                        //   My allowances
                        // </div>
                        ""
                      )}
                      <div
                        className={`myReqsFilterBar ${
                          !memoVals.mob &&
                          !memoVals.full &&
                          (memoVals.reqsPage === "past" ||
                            memoVals.reqsPage === "declined")
                            ? "x29482983298311"
                            : ""
                        } ${
                          memoVals.mob
                            ? `mobMyReqsFilterbarLeavePage ${
                                memoVals.reqsPage === "past" ||
                                memoVals.reqsPage === "declined"
                                  ? "nonUpcomingFiltBarMob"
                                  : ""
                              }`
                            : ""
                        } ${
                          memoVals.reqsPage === "upcoming" && !memoVals.full
                            ? "myReqsFilterBarUpcomingNonFull"
                            : ""
                        } ${
                          memoVals.reqsPage === "upcoming" && memoVals.mob
                            ? "x1038198"
                            : ""
                        } ${memoVals.full ? "myReqsFilterBarFull" : ""}`}
                      >
                        <select
                          className={`myReqsYearDropdown ${
                            selectedYear === new Date().getFullYear()
                              ? "colour00aaff"
                              : ""
                          } ${memoVals.reqsPage === "upcoming" ? "none" : ""} ${
                            memoVals.mob ? "x238998489484_" : ""
                          }`}
                          value={memoVals.selectedYear}
                          onChange={(e) => {
                            let val = e.target.value;
                            if (val) {
                              setMgrRequests([]);
                              setLoading(true);
                              memoVals.setSelectedYear((x) => val);
                              // setRunUpdate(!runUpdate);
                            }
                          }}
                        >
                          {!yearsArr.includes(memoVals.selectedYear) ? (
                            <option value="">--</option>
                          ) : (
                            ""
                          )}
                          {generateYearsArr}
                        </select>

                        <select
                          className={`myReqsTypesDropdown invis`}
                          id="myReqsTypesDropdownId"
                          value={selectedType}
                          onChange={(e) => {
                            setSelectedType(e.target.value);
                          }}
                        >
                          {" "}
                          <option value="allTypes">All types</option>
                          <option value="annLeave">Annual leave</option>
                          {generateTypes}
                        </select>
                      </div>
                    </div>
                  </div>
                ) : (
                  <p></p>
                )}

                {/* {memoVals.reqsPage !== "team" && !memoVals.reqsPage.userID ? (
                  <div
                    className={`Container borderRightGrey  ${
                      loading || !memoVals.full ? "noBorder" : ""
                    }`}
                  >
                    {!loading ? <div className="reqFilterSplitter"></div> : ""}
                  </div>
                ) : (
                  ""
                )} */}
                <div
                  // ref={upcomingRef}
                  className={`${
                    memoVals.reqsPage === "upcoming" &&
                    !memoVals.reqsPage.userID &&
                    !loading
                      ? `showReqs myUpcomingReqsHeight ${
                          memoVals.full ? "myUpcomingReqsHeightFull" : ""
                        } ${
                          memoVals.device.ios ? "myUpcomingReqsHeightIos" : ""
                        }`
                      : "none"
                  }  ${
                    memoVals.mob
                      ? `noBorder ${
                          memoVals.reqsPage !== "team" &&
                          !memoVals.reqsPage.userID
                            ? `minW101pc2 ${
                                memoVals.device.ios ? "minW101pc2Ios" : ""
                              }`
                            : `minW101pc ${
                                memoVals.device.ios ? "minW101pcIos" : ""
                              }`
                        }`
                      : ""
                  } ${memoVals.device.vhIssue ? "vhIssue" : ""}`}
                >
                  {/* <p
                    className={`${
                      pendingRequests.length > 0 &&
                      (typesPending(selectedType) ||
                        selectedType === "allTypes")
                        ? "pendingReqsUserTitle awaitingPullUp "
                        : "none"
                    }`}
                    onClick={() => {}}
                  >
                    Awaiting a decision
                  </p> */}
                  {generateRequests}
                  {!loading && requests.length === 0 ? (
                    <div className="noShiftsTxtContainer noPastReqs">
                      <div className="noShiftsSideBar"></div>
                      <p
                        className={`noShiftsTxt ${
                          memoVals.mob ? "noShiftsTxtLeaveMob" : "marginTop0"
                        }`}
                      >
                        No upcoming leave in {memoVals.selectedYear}
                      </p>

                      <div className="noShiftsSideBar"></div>
                    </div>
                  ) : (
                    ""
                  )}
                  {!loading ? (
                    <div
                      className={`addStaffLeaveBtn newLeaveReqInlineBtn ${
                        !memoVals.full ? "nonFullAddLeaveBtn" : ""
                      } ${memoVals.mob ? "x349898455" : ""}`}
                      onClick={() => {
                        memoVals.setShowReqModal((x) => true);
                      }}
                    >
                      New leave request{" "}
                      <img
                        src={navyAdd}
                        alt="Add form"
                        className="whiteAddFormImg"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {/* PAST PAGE */}
                <div
                  // ref={pastRef}
                  className={`${
                    memoVals.reqsPage === "past" &&
                    !memoVals.reqsPage.userID &&
                    !loading
                      ? `showReqs myUpcomingReqsHeight ${
                          memoVals.full ? "myUpcomingReqsHeightFull" : ""
                        } ${
                          memoVals.device.ios ? "myUpcomingReqsHeightIos" : ""
                        } paddingTop20 myPreviousReqs`
                      : "none"
                  } ${
                    memoVals.mob
                      ? `noBorder ${
                          memoVals.reqsPage !== "team" &&
                          !memoVals.reqsPage.userID
                            ? `minW101pc2 ${
                                memoVals.device.ios ? "minW101pc2Ios" : ""
                              }`
                            : `minW101pc ${
                                memoVals.device.ios ? "minW101pcIos" : ""
                              }`
                        }`
                      : ""
                  } ${memoVals.device.vhIssue ? "vhIssue" : ""}`}
                >
                  {generateRequests}
                  {!loading &&
                  memoVals.reqsPage === "past" &&
                  requests.length === 0 ? (
                    <div className="noShiftsTxtContainer noPastReqs">
                      <div className="noShiftsSideBar"></div>
                      <p
                        className={`noShiftsTxt ${
                          memoVals.mob
                            ? "noShiftsTxtLeaveMob"
                            : "noShiftsTxtLeaveMob5"
                        }`}
                      >
                        No approved leave in {memoVals.selectedYear}
                      </p>

                      <div className="noShiftsSideBar"></div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {/* DECLINED PAGE */}
                <div
                  // ref={declinedRef}
                  className={`${
                    memoVals.reqsPage === "declined" &&
                    !memoVals.reqsPage.userID &&
                    !loading
                      ? `showReqs myUpcomingReqsHeight ${
                          memoVals.full ? "myUpcomingReqsHeightFull" : ""
                        } ${
                          memoVals.device.ios ? "myUpcomingReqsHeightIos" : ""
                        } paddingTop20`
                      : "none"
                  } ${
                    memoVals.mob
                      ? `noBorder ${
                          memoVals.reqsPage !== "team" &&
                          !memoVals.reqsPage.userID
                            ? `minW101pc2 ${
                                memoVals.device.ios ? "minW101pc2Ios" : ""
                              }`
                            : `minW101pc ${
                                memoVals.device.ios ? "minW101pcIos" : ""
                              }`
                        }`
                      : ""
                  } ${memoVals.device.vhIssue ? "vhIssue" : ""}`}
                >
                  {generateRequests}
                  <div
                    className={`${
                      !loading && requests.length === 0
                        ? "noShiftsTxtContainer noPastReqs"
                        : "none"
                    }`}
                  >
                    <div className="noShiftsSideBar"></div>
                    {!loading ? (
                      <p
                        className={`noShiftsTxt ${
                          memoVals.mob
                            ? "noShiftsTxtLeaveMobDec"
                            : `noShiftsTxtLeaveMob6 ${
                                memoVals.full ? "noShiftsTxtLeaveMob6Full" : ""
                              }`
                        }`}
                      >
                        No declined leave
                      </p>
                    ) : (
                      ""
                    )}
                    <div className="noShiftsSideBar"></div>
                  </div>
                </div>
                {/*  TEAMS PAGE */}
                {/* - - - -  */}
                {/* {showMgrHistory ? ( */}
                {!loading ? (
                  <div
                    className={`reqsPageFilterHolder ${
                      !memoVals.mob ? "borderRightGrey" : ""
                    } ${loading ? "noBorder" : ""}`}
                  >
                    <div
                      className={`reqPageFilterBar  ${
                        !showMgrHistory && !teamDeclined
                          ? `teamReqsSelectorBar ${
                              !memoVals.mob && !memoVals.full ? "x1378491" : ""
                            }`
                          : "marginLef t17px"
                      }`}
                    >
                      <div
                        className={`reqsFiltBarLeft teamReqsFilterBar ${
                          !showMgrHistory && !memoVals.reqsPage.userID
                            ? "none"
                            : "flexEnd_"
                        }`}
                      >
                        <select
                          className={`myReqsYearDropdown  ${
                            memoVals.reqsPage === "upcoming"
                              ? "none"
                              : "x238998489484_"
                          }`}
                          value={memoVals.selectedYear}
                          onChange={(e) => {
                            let val = e.target.value;
                            if (val) {
                              console.log(e.target.value);
                              setMgrRequests([]);
                              setLoading(true);
                              memoVals.setSelectedYear((x) => val);
                              // setRunUpdate(!runUpdate);
                            }
                          }}
                        >
                          {generateYearsArr}
                        </select>
                      </div>
                      <div className="mgrReqsShoulderTopRight">
                        {memoVals.reqsPage === "team" &&
                        !memoVals.full && // chicken
                        !showMgrHistory &&
                        !teamDeclined ? (
                          <div
                            className={`myAllowsWhiteBtn ${
                              checkUserState.permissions > 2
                                ? "nonMgrAvailBtn"
                                : ""
                            } ${
                              memoVals.mob
                                ? "myAllowsWhiteBtnMob"
                                : "minWidth140"
                            } staffLeaveAllowsBtn`}
                            onClick={() => {
                              //   memoVals.setShowReqStats(x=>"myStats");
                              // }}

                              //   onClick={() => {
                              //     if (memoVals.reqsPage === "team") {
                              memoVals.setShowReqStats((x) => "mgr");
                              //     } else {
                              // memoVals.setShowReqStats(x=>"myStats");
                              //     }
                            }}
                          >
                            {memoVals.mob ? "Allowances" : "Staff allowances"}
                            <img
                              src={popupNavy2}
                              alt="Staff rota"
                              className={`availPopupImg ${
                                window.innerWidth < 370 ? "invis" : ""
                              }`}
                            />
                          </div>
                        ) : (
                          // <div
                          //   className={`mobReqsAllowancesBtn`}
                          //   onClick={() => {
                          //     if (memoVals.reqsPage === "team") {
                          //       memoVals.setShowReqStats(x=>"mgr");
                          //     } else {
                          //       memoVals.setShowReqStats(x=>"myStats");
                          //     }
                          //   }}
                          // >
                          //   <p
                          //     className={`mobReqsAllowancesBtnTxt allowsColourTeamTxt_ `}
                          //   >
                          //     Allowances2
                          //   </p>
                          //   <img
                          //     src={
                          //       memoVals.reqsPage === "team"
                          //         ? memoVals.mob
                          //           ? popup2
                          //           : popup2
                          //         : stats
                          //     }
                          //     alt="Stats"
                          //     className="mobReqStatsImg"
                          //   />
                          // </div>
                          ""
                        )}{" "}
                        {!memoVals.reqsPage.userID &&
                        !showMgrHistory &&
                        !teamDeclined &&
                        memoVals.reqsPage === "team" &&
                        !memoVals.mob ? (
                          <div
                            className={`addStaffLeaveBtn ${
                              !memoVals.full ? "nonFullAddLeaveBtn" : ""
                            }`}
                            onClick={() => {
                              memoVals.setShowAddLeaveOnTeam((x) => true);
                            }}
                          >
                            Add leave{" "}
                            <img
                              src={navyAdd}
                              alt="Add form"
                              className="whiteAddFormImg"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      {memoVals.reqsPage === "team" ? (
                        <div
                          className={`shiftsPageFilterBarRight ${
                            memoVals.mob ? "marginTop0" : ""
                          } ${
                            showMgrHistory ||
                            teamDeclined ||
                            memoVals.reqsPage.userID
                              ? "historyOrDeclinedFilterBar"
                              : ""
                          } ${
                            !showMgrHistory &&
                            !teamDeclined &&
                            !memoVals.reqsPage.userID
                              ? "upcomingFilterBarTeam"
                              : ""
                          } ${
                            !memoVals.reqsPage.userID &&
                            !showMgrHistory &&
                            !teamDeclined &&
                            memoVals.reqsPage === "team" &&
                            !memoVals.mob
                              ? "filterBarWithAddLeave"
                              : ""
                          }`}
                        >
                          {" "}
                          {!memoVals.reqsPage.userID ? (
                            <select
                              className={`${
                                filterUserID === "everyone"
                                  ? "shiftNamesDropdown x2082982"
                                  : "shiftNamesDropdown x2082982"
                              } ${
                                memoVals.mob
                                  ? `x108429811 ${
                                      showMgrHistory || teamDeclined
                                        ? "x29488845342"
                                        : ""
                                    } ${
                                      window.innerWidth < 370
                                        ? `x3388242 ${
                                            showMgrHistory ? "x33883121" : ""
                                          }`
                                        : ""
                                    }`
                                  : `${
                                      showMgrHistory || teamDeclined
                                        ? "reqLeaveDropTeamDesktop"
                                        : ""
                                    }`
                              } ${
                                memoVals.fullButNarrow ? "x29382983" : ""
                              } fontSize16_`}
                              value={filterUserID}
                              onChange={(e) => {
                                setFilterUserID(e.target.value);
                              }}
                            >
                              <option value="everyone">
                                All of your staff
                              </option>
                              {generateAllNamesFilter}
                            </select>
                          ) : (
                            <select
                              className={`${
                                selectedType === "allTypes"
                                  ? "shiftNamesDropdown x2082982"
                                  : "shiftNamesDropdown x2082982"
                              } ${
                                !memoVals.mob
                                  ? showMgrHistory || teamDeclined
                                    ? "reqLeaveDropTeamDesktop"
                                    : ""
                                  : ""
                              }`}
                              value={selectedType}
                              onChange={(e) => {
                                setSelectedType(e.target.value);
                              }}
                            >
                              <option value="allTypes">All types</option>
                              <option value="annLeave">Annual leave</option>

                              {types.map((t) => {
                                if (t.typeID !== "annLeave") {
                                  return (
                                    <option value={t.typeID}>{t.name}</option>
                                  );
                                }
                              })}
                            </select>
                          )}{" "}
                          {/* {memoVals.reqsPage === "team" && !memoVals.reqsPage.userID ? (
                            <select
                              value={memoVals.selectedTeam}
                              className={`teamReqTeamsDropdown ${
                                !memoVals.mob
                                  ? "fullTeamDrop"
                                  : `${
                                      memoVals.selectedTeam !== "allstaff"
                                        ? "mobteamSelectedDrop"
                                        : ""
                                    } shiftsHeaderTeamDropdownMob`
                              } ${
                                memoVals.selectedTeam !== "allstaff"
                                  ? "teamSelectedDropReqs"
                                  : ""
                              } reqsPageTeamsSelector`}
                              onChange={(e) => {
                                setShowReqFilter(false);
                                setShowMgrHistory(false);
                                setFilterUserID("everyone");
                                memoVals.setSelectedTeam( x =>e.target.value);
                              }}
                            >
                              <option value="allstaff">All teams</option>

                              {generateTeamSelectBar}
                            </select>
                          ) : (
                            ""
                          )} */}
                        </div>
                      ) : (
                        ""
                      )}
                      {memoVals.reqsPage.fName && memoVals.reqsPage.userID ? (
                        <p className="showingNameReqsOnlyTxt">
                          Showing {memoVals.reqsPage.fName}
                          {memoVals.reqsPage.fName[
                            memoVals.reqsPage.fName.length - 1
                          ] === "s"
                            ? "'"
                            : "'s"}{" "}
                          requests only
                        </p>
                      ) : (
                        ""
                      )}{" "}
                    </div>
                  </div>
                ) : (
                  <p></p>
                )}
                {/* {showMgrHistory ? ( */}
                <div
                  className={`Container ${
                    memoVals.mob ? "ContainerMobMarginTopMinus10" : ""
                  } borderRightGrey  ${loading ? "noBorder" : ""}`}
                >
                  {!loading ? <div className="reqFilterSplitter"></div> : ""}
                </div>

                <div
                  ref={showReqs}
                  className={`${
                    memoVals.reqsPage === "team" ||
                    (memoVals.reqsPage.userID && !loading)
                      ? `showReqs ${showFilter ? "showFilterTeamShowReqs" : ""}`
                      : "none"
                  }  ${
                    memoVals.teamHasPendingReqs.includes(memoVals.selectedTeam)
                      ? ""
                      : "paddingTop0 marginTop0"
                  } ${
                    memoVals.mob
                      ? `noBorder ${
                          memoVals.reqsPage !== "team" &&
                          !memoVals.reqsPage.userID
                            ? `minW101pc2 ${
                                memoVals.device.ios ? "minW101pc2Ios" : ""
                              }`
                            : `minW101pc ${
                                memoVals.device.ios ? "minW101pcIos" : ""
                              }`
                        }`
                      : ""
                  } ${
                    !showMgrHistory && !teamDeclined
                      ? `showReqsTeamUpcoming ${
                          memoVals.reqsPage.userID ? "paddingTop30" : ""
                        }`
                      : ""
                  } mgrScrollColour ${
                    memoVals.device.vhIssue ? "vhIssue" : ""
                  } ${
                    (showMgrHistory || teamDeclined) && memoVals.mob
                      ? `mgrPrevScrollHeightMob ${
                          memoVals.device.ios ? "mgrPrevScrollHeightMobIos" : ""
                        }`
                      : ""
                  }`}
                  // ref={showReqs}
                >
                  <div
                    className={`${
                      outstandingExists(pendingMgrReqs) && !loading
                        ? "filterReqsBarContainer outstandingPullUpABit"
                        : "none"
                    }`}
                  >
                    <p
                      className={`${
                        showMgrHistory || memoVals.reqsPage.userID || loading
                          ? "none"
                          : "clocksAwaitingTitles outstandingPullUp"
                      }`}
                    >
                      Outstanding
                    </p>
                  </div>
                  {!loading ? generateMgrReqsPENDING : ""}
                  {mgrRequests.length === 0 ? (
                    <div
                      className={`${
                        (!loading && memoVals.reqsPage.userID) ||
                        (!loading &&
                          pendMgrQty === 0 &&
                          !showMgrHistory &&
                          !teamDeclined)
                          ? `noShiftsTxtContainer noUpcomingMgrReqs`
                          : "none"
                      }`}
                    >
                      <div className="noShiftsSideBar"></div>
                      {/* {!loading ? (
                        <p className={`noShiftsTxt ${memoVals.mob ? "noShiftsTxtLeaveMob":""}`}>
                          {memoVals.reqsPage.userID
                            ? `No requests from ${memoVals.reqsPage.fName} in ${memoVals.selectedYear}`
                            : "No requests from your staff"}
                        </p>
                      ) : (
                        ""
                      )} */}
                      <div className="noShiftsSideBar"></div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div
                    className={`${
                      outstandingExists(pendingMgrReqs) &&
                      !showMgrHistory &&
                      !memoVals.reqsPage.userID &&
                      !loading
                        ? "pendingSplitter"
                        : "none"
                    }`}
                  ></div>
                  <div className={`${showReqFilter ? "filterBox" : "none"}`}>
                    <select
                      className="nameFilterDropdown"
                      value={filterUserID}
                      onChange={(e) => {
                        setFilterUserID(e.target.value);
                      }}
                    >
                      <option value="everyone">Everyone</option>
                      {generateAllNamesFilter}
                    </select>
                  </div>
                  <img
                    src={horizontalBalls}
                    alt="loading"
                    className={`${loading ? "blueLoadingReqs" : "none"}`}
                  />
                  <div className="filterReqsBarContainer">
                    <p
                      className={`${
                        showMgrHistory ||
                        (!loading &&
                          pendMgrQty === 0 &&
                          !showMgrHistory &&
                          !teamDeclined) ||
                        memoVals.reqsPage.userID ||
                        loading
                          ? "none"
                          : "approvedTeamReqsTitle"
                      }`}
                    >
                      Approved
                    </p>
                  </div>

                  {!loading &&
                  mgrRequests.length === 0 &&
                  memoVals.reqsPage === "team" &&
                  !teamDeclined &&
                  !showMgrHistory ? (
                    <p className="noShiftsTxt x13141413 none">
                      No upcoming approved requests from staff you manage
                    </p>
                  ) : (
                    ""
                  )}
                  {!loading ? generateMgrReqs : ""}
                  <div
                    className={`${
                      (!loading &&
                        ((mgrPrevQty === 0 &&
                          !teamDeclined &&
                          showMgrHistory &&
                          !memoVals.reqsPage.userID) ||
                          (mgrDeclinedQty === 0 &&
                            teamDeclined &&
                            showMgrHistory))) ||
                      (!teamDeclined &&
                        !showMgrHistory &&
                        mgrRequests.filter((x) => {
                          return !x.declined && !x.approved;
                        }).length === 0)
                        ? `noShiftsTxtContainer noUpcomingMgrReqs ${
                            memoVals.mob
                              ? "noUpcomingMgrReqsMob"
                              : teamDeclined || showMgrHistory
                              ? "noShiftsTxtLeaveMob3"
                              : "noShiftsTxtLeaveMob4"
                          }`
                        : "none"
                    } ${loading ? "none" : ""}`}
                  >
                    <div className="noShiftsSideBar"></div>
                    <p
                      className={`noShiftsTxt ${
                        memoVals.mob
                          ? `noShiftsTxtLeaveMob ${
                              teamDeclined || showMgrHistory
                                ? "noShiftsTxtLeaveMob2"
                                : ""
                            }`
                          : ""
                      } ${
                        !teamDeclined &&
                        !showMgrHistory &&
                        pendingMgrReqs.length === 0
                          ? "none"
                          : ""
                      }`}
                    >
                      {teamDeclined
                        ? `No declined leave requests for your staff in ${memoVals.selectedYear}`
                        : showMgrHistory
                        ? `No approved leave requests for your staff in ${memoVals.selectedYear}`
                        : "No upcoming leave requests from staff you manage"}
                    </p>
                    <div className="noShiftsSideBar"></div>
                  </div>
                </div>
              </div>
            </div>
            {memoVals.full ? (
              <div className="desktopMasterSecondary">
                {memoVals.reqsPage === "team" || memoVals.reqsPage.userID ? (
                  allNames[0] ? (
                    <MgrReqsDataBox modal={false} allNames={allNames} />
                  ) : (
                    ""
                  )
                ) : (
                  <MyReqsDataBox
                    modal={false}
                    profileSideBox={true}
                    selectedYear={memoVals.selectedYear}
                    setSelectedYearParent={setSelectedYear}
                  />
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* end of requests */}

        {/* mgr Stats modal */}
        {memoVals.showReqStats === "mgr" ? (
          <div
            className="reqStatsUnderlay"
            onClick={(e) => {
              memoVals.setShowReqStats((x) => "");
            }}
          >
            <MgrReqsDataBox modal={true} allNames={allNames} />
          </div>
        ) : (
          ""
        )}

        {/* end of stats modals */}

        <div className={`${showDupeModal ? "reqInfoUnderlay" : "none"}`}>
          <div
            className="duplicateReqWarningModalBox navyBg"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="reqInfoDuplicateContainer">
              <div className="reqInfoTxtContainer">
                <p className="reqNotMadeTxt">Request not made</p>
                <p className="reqNotMadeinfoTxt">
                  The request you tried to make overlaps an existing one.{" "}
                </p>
              </div>
            </div>
            <p
              className="closeReqNotMadeNoteBtn"
              onClick={() => {
                setShowDupeModal(false);
              }}
            >
              OK
            </p>
          </div>
        </div>

        <Navbar />

        {/* {showAddLeaveOnTeam && memoVals.reqsPage === "team" ? (
          <AddShiftModal2
            teamID={"allStaff"}
            type="leave"
            setShowAddLeaveOnTeam={setShowAddLeaveOnTeam}
            loadFromReqsPage={dateStringer.createStringFromTimestamp(
              new Date().getTime()
            )}
          />
        ) : (
          ""
        )} */}
        {!memoVals.showReqMgrModal &&
        memoVals.mob &&
        memoVals.reqsPage === "team" &&
        !teamDeclined &&
        !showMgrHistory &&
        !memoVals.showReqStats &&
        !memoVals.showReqModal ? (
          <p
            className="addShiftBtnOnTeamShifts"
            onClick={() => {
              memoVals.setShowAddLeaveOnTeam((x) => {
                return {
                  new: true,
                };
              });
            }}
          >
            +
          </p>
        ) : (
          ""
        )}
      </div>
      {imgPreload}
    </div>
  );
};

export default LeaveReqsPage;

// CSS for this modal is in calendar.css

// INSTRUCTIONS TO LOAD MODAL
// use props
// show modal with setShowMyShiftModal stored in CalendarContext

import React, {
  useEffect,
  useState,
  useContext,
  useReducer,
  useMemo,
  useRef,
} from "react";
// cleaned wef 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23
// image ready 4 oct 23
// ios done 8 sep 24

import axios from "axios";

import { DataContext } from "../../../contexts/DataContext";
import { CalendarContext } from "../../../contexts/CalendarContext";
import { UserContext } from "../../../contexts/UserContext";

import serverURL from "../../../serverURL";
import { StyleContext } from "../../../contexts/StyleContext";

import dateStringer from "../../../tools/dateStringer";
import horizontalBalls from "../../../img/loaders/horizontalBalls.svg";

import EditMyShiftRequestModal from "./EditMyShiftRequestModal";
import TilTrimModal from "./TilTrimModal";
import CheckUser from "../../../tools/CheckUser";
import profilePicturePlaceholder from "../../../img/general/profilePicturePlaceholder.svg";
import rotaClock2 from "../../../img/general/rotaClock2.svg";

import bin1 from "../../../img/general/bin1.svg";
import swap2 from "../../../img/general/swap2white.svg";
import chat from "../../../img/general/chat.svg";
import noteImg from "../../../img/general/noteWhite.svg";
import noteImg2 from "../../../img/general/note.svg";
import edit from "../../../img/general/edit.svg";
import scissors from "../../../img/general/scissors.svg";
import arrGrey from "../../../img/general/arrGrey.svg";
import close from "../../../img/modals/close.svg";
import whitecross from "../../../img/general/whitecross.svg";
import cancelTil from "../../../img/general/cancelTil.svg";
import popup from "../../../img/general/popup2.svg";
import popupX from "../../../img/general/popup.svg";
import greenTick from "../../../img/general/greenTick.svg";
import whiteTick from "../../../img/general/whiteTick.svg";
import doubleTick from "../../../img/general/doubleTick.svg";
import clockWhite2 from "../../../img/general/clockWhite2.svg";
import pin from "../../../img/general/pin.svg";
import navyClose from "../../../img/general/navyClose.svg";

import closeGrey from "../../../img/general/closeGrey.svg";
import navySwap from "../../../img/general/navySwap.svg";
import editGrey from "../../../img/general/editGrey.svg";
import greyCross from "../../../img/general/greyCross.svg";
import like from "../../../img/general/like.svg";
import liked from "../../../img/general/liked.svg";
import editNavy from "../../../img/general/editNavy.svg";
import dashSwap from "../../../img/general/dashSwap.svg";
import navyTick from "../../../img/general/navyTick.svg";

import liked2 from "../../../img/general/liked2.svg";
import acknowledgeArr from "../../../img/general/acknowledgeArr.svg";

// type = til, overtime, shift
const MyShiftModal = ({ unitType, unitID, otherUser }) => {
  let [showEarnings, setShowEarnings] = useState(false);
  let [hoveredLikeCommentID, setHoveredLikeCommentID] = useState("");
  let [showLikedCommentIDs, setShowCommentLikedIDs] = useState([]);

  let [showSelfLogAbsenceModal, setShowSelfLogAbsenceModal] = useState("");

  let [sureCancelAbsence, setSureCancelAbsence] = useState(false);
  let [imagesLoaded, setImagesLoaded] = useState(0);
  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);

  let [showDecline, setShowDecline] = useState(false);
  let [declineReason, setDeclineReason] = useState("");
  let [reason, setReason] = useState("");
  let [tonightTs, setTonightTs] = useState(0);

  useEffect(() => {
    if (dataLoaded && imagesLoaded === 30) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);

  let imgPreload = (
    <div className="imagesHidden">
      {/* Previously added images */}
      {/* ... */}
      {/* Additional images */}
      <img
        src={pin}
        alt="Bin 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={bin1}
        alt="Bin 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={navyClose}
        alt="Bin 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={navyTick}
        alt="Bin 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={dashSwap}
        alt="Bin 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={clockWhite2}
        alt="Bin 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaClock2}
        alt="Bin 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={doubleTick}
        alt="Bin 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={profilePicturePlaceholder}
        alt="Bin 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whiteTick}
        alt="Bin 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={swap2}
        alt="Swap 2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={popupX}
        alt="Swap 2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={chat}
        alt="Chat"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={noteImg}
        alt="Note White"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={noteImg2}
        alt="Note"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={editNavy}
        alt="Note"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={edit}
        alt="Edit"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={scissors}
        alt="Scissors"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={arrGrey}
        alt="Arrow Grey"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={close}
        alt="Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={cancelTil}
        alt="Cancel Til"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={popup}
        alt="Popup 2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={closeGrey}
        alt="Close Grey"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={navySwap}
        alt="Navy Swap"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={editGrey}
        alt="Edit Grey"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={greyCross}
        alt="Grey Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={like}
        alt="Like"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={liked}
        alt="Liked"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={liked2}
        alt="Liked 2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={acknowledgeArr}
        alt="Acknowledge Arr"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  let [availableTags, setAvailableTags] = useState([]);

  let [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      itemType: "",
      itemID: "",
      location: "",
      itemTeamID: "",
      itemUserID: "",
      itemDs: "",
      itemStart: "",
      itemEnd: "",
      itemBreak: "",
      itemRate: 1,
      itemCost: 0,
      itemDuration: 0,
      itemNote: [],
      itemAddedBy: "",
      itemTeamName: "",
      itemTags: [],
      itemAddedByUserID: "",
      itemAbsenceData: {},
      itemIsAbsent: false,
      absenceName: "",
      ghost: false,
      takeTilRequestMade: false,
      takeTilRequest: false,
      tilStart: "",
      tilEnd: "",
      tilMins: 0,
      tilRequested: false,
      tilApproved: false,
      tilDeclined: false,
      takeTilID: "",
      clockData: {},
      clockOnID: "",
      history: [],
      closedDayName: "",
      blockSwaps: false,
      swapOption: false,
      fName: " ",
      lName: " ",
      otherUserID: "",
      profPicUrl: "",
      userID: "",
      iWant: {},
      lateDurMins: 0,
      lateStartDs: "",
      unpaidLate: false,
      createdByClock: false,
      inLieu: false,
      absenceAllowSelfLog: false,
      selfLoggedAbsence: false,
      selfLoggedAbsenceReason: false,
      requireAccept: false,
      accepted: "",
      shiftLeaveDurMins: 0,
      shiftLeaveName: "",
      shiftLeaveTypeID: "",
      shiftLeaveNote: "",
      shiftLeaveAddedBy: "",
      loggedExcess: 0,
      loggedExcessToil: "",
      loggedLocationID: "",
      loggedAddedTags: [],
      loggedPulledTags: [],
    }
  );

  let [otherUsersArr, setOtherUsersArr] = useState([]);

  let [sureRemoveLogged, setSureRemoveLogged] = useState(false);
  let [shiftIsPast, setShiftIsPast] = useState(false);
  const {
    showEditMyShiftModal,
    setShowEditMyShiftModal,
    showTilTrimModal,
    setShowTilTrimModal,
    updateTilData,
    setUpdateTilData,
  } = useContext(CalendarContext);
  const { currencySymbol, checkUserState } = useContext(UserContext);
  const { mobModal } = useContext(StyleContext);
  const {
    updateShifts,
    setUpdateShifts,
    showMyShiftModal,
    setShowMyShiftModal,
    setClockOnID,
    clockOnID,
    showMessages,
    setShowMessages,
    seeUserID,
    setSeeUserID,
    swap,
    setSwap,
    iWant,
    setIWant,
    setIndicate,
    setDynanicSwapOrNote,
    setRemoveMyChangeEditID,
    setShowClockOnModal,
    tilReqMade,
    setAcceptedShiftID,
    setDeclinedShiftID,
    udpateMyRota,
    setUpdateMyRota,
    amendReqSent,
    modalOpen,
    setModalOpen,
    device,
    countData,
    setCountData,
    setReloadTs,
    reloadTs,
  } = useContext(DataContext);

  const memoVals = useMemo(
    () => ({
      updateShifts,
      setUpdateShifts,
      showMyShiftModal,
      setShowMyShiftModal,
      setClockOnID,
      clockOnID,
      showMessages,
      setShowMessages,
      seeUserID,
      setSeeUserID,
      swap,
      setSwap,
      iWant,
      setIWant,
      setIndicate,
      setDynanicSwapOrNote,
      setRemoveMyChangeEditID,
      setShowClockOnModal,
      tilReqMade,
      setAcceptedShiftID,
      setDeclinedShiftID,
      udpateMyRota,
      setUpdateMyRota,
      amendReqSent,
      mobModal,
      currencySymbol,
      checkUserState,
      showEditMyShiftModal,
      setShowEditMyShiftModal,
      showTilTrimModal,
      setShowTilTrimModal,
      updateTilData,
      setUpdateTilData,
      modalOpen,
      setModalOpen,
      device,
      setReloadTs,
      reloadTs,
    }),
    [
      updateShifts, //
      setUpdateShifts, //
      showMyShiftModal, //
      setShowMyShiftModal, //
      setClockOnID, //
      clockOnID, //
      showMessages, //
      setShowMessages, //
      seeUserID, //
      setSeeUserID, //
      swap, //
      setSwap, //
      iWant, //
      setIWant, //
      setIndicate, //
      setDynanicSwapOrNote, //
      setRemoveMyChangeEditID, //
      setShowClockOnModal, //
      tilReqMade, //
      setAcceptedShiftID, //
      setDeclinedShiftID, //
      udpateMyRota, //
      setUpdateMyRota, //
      amendReqSent, //
      mobModal, //
      currencySymbol, //
      checkUserState, //
      showEditMyShiftModal, //
      setShowEditMyShiftModal, //
      showTilTrimModal, //
      setShowTilTrimModal, //
      updateTilData, //
      setUpdateTilData, //
      modalOpen,
      setModalOpen,
      device,
      setReloadTs,
      reloadTs,
    ]
  );

  let [showLogActualTimes, setShowLogActualTimes] = useState(false);
  let [logStartTime, setLogStartTime] = useState("00:00");
  let [logEndTime, setLogEndTime] = useState("00:00");
  let [logBrk, setLogBrk] = useState(0);

  // if logged already:
  let [loggedStartTime, setLoggedStartTime] = useState("");
  let [loggedEndTime, setLoggedEndTime] = useState("");
  let [loggedBrk, setLoggedBrk] = useState(0);
  let [preLoggedTimes, setPreLoggedTimes] = useState("");
  let [loggedLocationID, setLoggedLocationID] = useState("");
  let [loggedAddedTags, setLoggedAddedTags] = useState([]);
  let [loggedPulledTags, setLoggedPulledTags] = useState([]);
  let [locations, setLocations] = useState([]);
  let [loggedExcessMinsSaved, setLoggedExcessMinsSaved] = useState(0);
  let [excessToilSaved, setExcessToilSaved] = useState(false);

  let [showUnable, setShowUnable] = useState(false);

  let [forward, setForward] = useState(false);
  let [sureWithdraw, setSureWithdraw] = useState(false);
  useEffect(() => {
    memoVals.setModalOpen((x) => true);

    return () => {
      memoVals.setModalOpen((x) => false);
    };
  }, [memoVals.setModalOpen]);

  // const notes = useRef(null);

  let notesBox = useRef();

  useEffect(() => {
    let scrollContainer = notesBox.current;
    setTimeout(() => {
      if (scrollContainer) {
        const scrollHeight = scrollContainer.scrollHeight;
        const scrollTop = scrollContainer.scrollTop;
        const targetPosition = scrollHeight - scrollContainer.clientHeight;

        const duration = 300; // Adjust this value to set the animation duration in milliseconds
        const startTime = performance.now();

        const animateScroll = (currentTime) => {
          const elapsedTime = currentTime - startTime;
          const progress = Math.min(elapsedTime / duration, 1);
          scrollContainer.scrollTop =
            scrollTop + progress * (targetPosition - scrollTop);

          if (progress < 1) {
            requestAnimationFrame(animateScroll);
          }
        };

        requestAnimationFrame(animateScroll);
      }
    }, 500);
  }, [memoVals.amendReqSent]);

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 30) {
      memoVals.setShowMyShiftModal((x) => {
        return {};
      });
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  //  - - - - - - -

  // Create a ref to hold the modal element
  const modalRefLogAbs = useRef(null);

  const [isDraggingLogAbs, setIsDraggingLogAbs] = useState(false);
  const [dragStartLogAbs, setDragStartLogAbs] = useState({ x: 0, y: 0 });
  const [modalPositionLogAbs, setModalPositionLogAbs] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPositionLogAbs, setDragPositionLogAbs] = useState(0);

  const handleDownLogAbs = (e) => {
    setIsDraggingLogAbs(true);
    setDragStartLogAbs({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMoveLogAbs = (e) => {
    if (isDraggingLogAbs) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStartLogAbs.y;

      if (deltaY > 0) {
        setDragPositionLogAbs(deltaY);
        setModalPositionLogAbs({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUpLogAbs = () => {
    setIsDraggingLogAbs(false);

    if (dragPositionLogAbs > 10) {
      setShowSelfLogAbsenceModal();
    } else {
      setModalPositionLogAbs({ bottom: "0%", left: "0%" });
    }
  };

  useEffect(() => {
    if (showSelfLogAbsenceModal) {
      setModalPositionLogAbs({ bottom: "0%", left: "0%" });
    }
  }, [showSelfLogAbsenceModal]);
  // --- -- --- -- - --- -

  // Create a ref to hold the modal element
  const modalRefDecline = useRef(null);

  const [isDraggingDecline, setIsDraggingDecline] = useState(false);
  const [dragStartDecline, setDragStartDecline] = useState({ x: 0, y: 0 });
  const [modalPositionDecline, setModalPositionDecline] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPositionDecline, setDragPositionDecline] = useState(0);

  const handleDownDecline = (e) => {
    setIsDraggingDecline(true);
    setDragStartDecline({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMoveDecline = (e) => {
    if (isDraggingDecline) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStartDecline.y;

      if (deltaY > 0) {
        setDragPositionDecline(deltaY);
        setModalPositionDecline({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUpDecline = () => {
    setIsDraggingDecline(false);

    if (dragPositionDecline > 140) {
      setShowDecline(false);
    } else {
      setModalPositionDecline({ bottom: "0%", left: "0%" });
    }
  };

  useEffect(() => {
    if (showDecline) {
      setModalPositionDecline({ bottom: "0%", left: "0%" });
    }
  }, [showDecline]);

  let [sureCancelChange, setSureCancelChange] = useState({
    show: false,
    change: "",
    correspondingEditID: "",
    shiftID: "",
  });

  let [sureCancelTil, setSureCancelTil] = useState(false);
  let [amends, setAmends] = useState([]);
  let [changeRequests, setChangesRequests] = useState([]);

  let clearData = () => {
    setState(null);
  };

  useEffect(() => {
    // cleaned
    const handlePopstate = () => {
      window.history.pushState(null, document.title, window.location.href);
      memoVals.setShowMyShiftModal((x) => false);
    };

    // Add the event listener for "popstate" event
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handlePopstate);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  let today00 = new Date();
  today00.setHours(0, 0, 0, 0);

  let [shiftDoesNotExist, setShiftDoesNotExist] = useState(false);
  let [highlightedNote, setHighlightedNote] = useState({});

  let [loggedExcessMins, setLoggedExcessMins] = useState(0);
  let [excessToil, setExcessToil] = useState(false);
  useEffect(() => {
    if (
      logStartTime &&
      logEndTime &&
      logStartTime.length === 5 &&
      logEndTime.length === 5 &&
      showLogActualTimes
    ) {
      let baseDs = state.itemDs;

      let startDs = `${baseDs.substr(0, 11)}H${logStartTime.substr(
        0,
        2
      )}M${logStartTime.substr(3, 2)}`;
      let endDs = `${baseDs.substr(0, 11)}H${logEndTime.substr(
        0,
        2
      )}M${logEndTime.substr(3, 2)}`;

      let startTs = dateStringer.createTimestampFromString(startDs);
      let endTs = dateStringer.createTimestampFromString(endDs);
      let startObj = new Date(startTs);
      let endObj = new Date(endTs);
      if (endObj.getTime() <= startObj.getTime()) {
        endObj.setDate(endObj.getDate() + 1);
        endTs = endObj.getTime();
      }

      let scheduledDurMins = state.itemDuration;
      let loggedMs = endTs - startTs;

      let loggedMins = Math.round(loggedMs / 1000 / 60) - (logBrk || 0);

      console.log("loggedMs: ", loggedMs / 1000 / 60, "... logBrk: ", logBrk);
      let diff = loggedMins - scheduledDurMins;

      console.log({
        startDs: dateStringer.createStringFromTimestamp(startTs),
        endDs: dateStringer.createStringFromTimestamp(endTs),
        scheduledDurMins: state.itemDuration,
        baseDs,
        loggedMins,
        logBrk,
      });

      setLoggedExcessMins(diff);
    } else {
      if (loggedExcessMins !== 0) {
        setLoggedExcessMins(0);
      }
    }
  }, [logStartTime, logEndTime, logBrk, showLogActualTimes]);

  useEffect(() => {
    let tonight00 = new Date();
    tonight00.setDate(tonight00.getDate() + 1);
    tonight00.setHours(0, 0, 0, 0);
    setTonightTs(tonight00.getTime());
    if (!memoVals.clockOnID) {
      const cancelSource1 = axios.CancelToken.source();
      const cancelSource2 = axios.CancelToken.source();

      axios
        .post(
          `${serverURL}/get-my-single-shift-data`,
          {
            type: unitType,
            typeID: unitID,
            otherUser,
            nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            setLocations(response.data.locations);
            setLoggedLocationID(response.data.locationID);
            setShowEarnings(response.data.showEarnings);
            if (shiftDoesNotExist) {
              setShiftDoesNotExist(false);
            }

            setOtherUsersArr(response.data.otherUsers);
            let today = new Date();
            today.setHours(0, 0, 0, 0);
            if (
              dateStringer.createTimestampFromString(response.data.ds) <
              today.getTime()
            ) {
              setShiftIsPast(true);
            }

            response.data.history.sort(
              (a, b) =>
                dateStringer.createTimestampFromString(b.ds) -
                dateStringer.createTimestampFromString(a.ds)
            );

            if (dataLoaded) {
              setDataLoaded(false);
            }
            setDataLoaded(true);
            setLoggedExcessMinsSaved(response.data.loggedExcess);
            setExcessToilSaved(response.data.loggedExcessToil);
            setLogStartTime(response.data.start);
            setLogEndTime(response.data.end);
            setLogBrk(response.data.break);
            setLoggedStartTime(response.data.loggedStart);
            setLoggedEndTime(response.data.loggedEnd);
            setLoggedBrk(response.data.loggedBrk);
            setPreLoggedTimes(response.data.preLoggedTimes);
            setHighlightedNote(
              response.data.note
                ? response.data.note.filter((x) => {
                    return x.highlighted;
                  })
                  ? response.data.note.filter((x) => {
                      return x.highlighted;
                    })[0]
                  : {}
                : {}
            );
            setState({
              itemType: response.data.type,
              inLieu: response.data.inLieu,
              location: response.data.location,
              itemID: unitID,
              itemTeamID: response.data.teamID,
              itemUserID: response.data.userID,
              itemDs: response.data.ds,
              startTs: dateStringer.createTimestampFromString(
                `${response.data.ds.substr(0, 11)}H${response.data.start.substr(
                  0,
                  2
                )}M${response.data.start.substr(3, 2)}`
              ),
              itemStart: response.data.start,
              itemEnd: response.data.end,
              itemBreak: response.data.break,
              itemRate: response.data.rate,
              itemCost: response.data.cost,
              itemDuration: response.data.duration,
              itemNote: response.data.note,
              itemAddedBy: response.data.addedBy,
              itemTeamName: response.data.teamName,
              itemTags: response.data.tags,
              itemAddedByUserID: response.data.addedByUserID,
              itemAbsenceData: response.data.absenceData,
              itemIsAbsent: response.data.absent,
              absenceName: response.data.absenceData
                ? response.data.absenceData.absenceName
                : "",
              ghost: response.data.ghost,
              takeTilRequestMade: response.data.tilMins
                ? response.data.takeTilRequestMade
                : false,
              takeTilRequest: response.data.tilMins
                ? response.data.takeTilRequestMade
                : false,
              tilStart: response.data.tilMins ? response.data.tilStart : "",
              tilEnd: response.data.tilMins ? response.data.tilEnd : "",
              tilMins: response.data.tilMins,
              tilRequested: response.data.tilMins
                ? response.data.takeTilRequestMade
                : false,
              tilApproved: response.data.tilMins
                ? response.data.tilApproved
                : false,
              tilDeclined: response.data.tilMins
                ? response.data.tilDeclined
                : false,
              takeTilID: response.data.takeTilID,
              clockData: response.data.clockData,
              clockOnID: response.data.clockOnID,
              salaried: response.data.salaried,
              history: response.data.history,
              closedDayName: response.data.closedDayName,
              swapOption: response.data.swapOption,
              blockSwaps: response.data.blockSwaps,
              fName: response.data.fName,
              lName: response.data.lName,
              otherUserID: response.data.otherUserID,
              profPicUrl: response.data.profPicUrl,
              payClocked: response.data.payClocked,
              lateDurMins: response.data.lateMins,
              lateStartDs: response.data.lateStartDs,
              unpaidLate: response.data.unpaidLate,
              createdByClock: response.data.createdByClock,
              clockable: response.data.clockable,
              clockedOn: response.data.clockedOn,
              absenceAllowSelfLog: response.data.absenceAllowSelfLog,
              selfLoggedAbsence: response.data.selfLoggedAbsence,
              selfLoggedAbsenceReason: response.data.selfLoggedAbsenceReason,
              requireAccept: response.data.requireAccept,
              accepted: response.data.accepted,
              shiftLeaveDurMins: response.data.shiftLeaveDurMins,
              shiftLeaveName: response.data.shiftLeaveName,
              shiftLeaveTypeID: response.data.shiftLeaveTypeID,
              shiftLeaveNote: response.data.shiftLeaveNote,
              shiftLeaveAddedBy: response.data.shiftLeaveAddedBy,
              loggedExcess: response.data.loggedExcess,
              loggedExcessToil: response.data.loggedExcessToil,
              loggedLocationID: response.data.loggedLocationID,
              loggedAddedTags: response.data.loggedAddedTags || [],
              loggedPulledTags: response.data.loggedPulledTags || [],
            });
            setAmends(response.data.alerts || []);

            // response.data.changesArr.reverse();
            // ((a, b) => {
            //   return (
            //     dateStringer.createTimestampFromString(b.dsMade) -
            //     dateStringer.createStringFromTimestamp(a.dsMade)
            //   );
            // });
            setChangesRequests(response.data.changesArr || []);

            if (response.data.changesArr) {
              let changeArrFwd = response.data.changesArr.filter((x) => {
                return x.drop && x.meFwd && !x.approved && !x.declined;
              });

              if (changeArrFwd[0]) {
                setForward(changeArrFwd[0].correspondingEditID);
              } else {
                if (forward) {
                  setForward(false);
                }
              }
            }

            memoVals.setIWant((x) => response.data.iWant);
          }
          if (response.data.message.includes("does not exist")) {
            axios
              .post(
                `${serverURL}/get-my-single-shift-data`,
                {
                  type:
                    unitType === "til" || unitType === "overtime"
                      ? "shift"
                      : "til",
                  typeID: unitID,
                },

                {
                  withCredentials: true,
                  credentials: "include",
                  cancelToken: cancelSource2.token,
                }
              )
              .then((response) => {
                if (response.data.message.includes("not exist")) {
                  setShiftDoesNotExist(true);
                } else {
                }
                if (response.data.message === "success") {
                  setOtherUsersArr(response.data.otherUsers);
                  if (shiftDoesNotExist) {
                    setShiftDoesNotExist(false);
                  }
                  let today = new Date();
                  today.setHours(0, 0, 0, 0);
                  if (
                    dateStringer.createTimestampFromString(response.data.ds) <
                    today.getTime()
                  ) {
                    setShiftIsPast(true);
                  }

                  response.data.history.sort(
                    (a, b) =>
                      dateStringer.createTimestampFromString(b.ds) -
                      dateStringer.createTimestampFromString(a.ds)
                  );
                  if (dataLoaded) {
                    setDataLoaded(false);
                  }
                  setDataLoaded(true);
                  setState({
                    itemType: response.data.type,
                    itemID: unitID,
                    itemTeamID: response.data.teamID,
                    itemUserID: response.data.userID,
                    itemDs: response.data.ds,
                    itemStart: response.data.start,
                    itemEnd: response.data.end,
                    itemBreak: response.data.break,
                    itemRate: response.data.rate,
                    itemCost: response.data.cost,
                    itemDuration: response.data.duration,
                    itemNote: response.data.note,
                    itemAddedBy: response.data.addedBy,
                    itemTeamName: response.data.teamName,
                    itemTags: response.data.tags,
                    itemAddedByUserID: response.data.addedByUserID,
                    itemAbsenceData: response.data.absenceData,
                    itemIsAbsent: response.data.absent,
                    absenceName: response.data.absenceData
                      ? response.data.absenceData.absenceName
                      : "",
                    ghost: response.data.ghost,
                    takeTilRequestMade: response.data.tilMins
                      ? response.data.takeTilRequestMade
                      : false,
                    takeTilRequest: response.data.tilMins
                      ? response.data.takeTilRequestMade
                      : false,
                    tilStart: response.data.tilMins
                      ? response.data.tilStart
                      : "",
                    tilEnd: response.data.tilMins ? response.data.tilEnd : "",
                    tilMins: response.data.tilMins,
                    tilRequested: response.data.tilMins
                      ? response.data.takeTilRequestMade
                      : false,
                    tilApproved: response.data.tilMins
                      ? response.data.tilApproved
                      : false,
                    tilDeclined: response.data.tilMins
                      ? response.data.tilDeclined
                      : false,
                    takeTilID: response.data.takeTilID,
                    clockData: response.data.clockData,
                    clockOnID: response.data.clockOnID,
                    salaried: response.data.salaried,
                    history: response.data.history,
                    closedDayName: response.data.closedDayName,
                    swapOption: response.data.swapOption,
                    blockSwaps: response.data.blockSwaps,
                    fName: response.data.fName,
                    lName: response.data.lName,
                    otherUserID: response.data.otherUserID,
                    profPicUrl: response.data.profPicUrl,
                    payClocked: response.data.payClocked,
                    lateDurMins: response.data.lateMins,
                    lateStartDs: response.data.lateStartDs,
                    unpaidLate: response.data.unpaidLate,
                    createdByClock: response.data.createdByClock,
                  });
                  setAmends(response.data.alerts || []);

                  // .sort((a, b) => {
                  //   return (
                  //     dateStringer.createTimestampFromString(b.dsMade) -
                  //     dateStringer.createStringFromTimestamp(a.dsMade)
                  //   );
                  // });
                  setChangesRequests(response.data.changesArr || []);

                  memoVals.setIWant((x) => response.data.iWant);
                }
                if (response.data.message.includes("does not exist")) {
                  clearData();
                }
              })
              .catch((err) => {
                console.error(err);
              });
          }
        })
        .catch((err) => {
          console.error(err);
        });

      return () => {
        cancelSource1.cancel("Component unmounted");
        cancelSource2.cancel("Component unmounted");
      };
    }
  }, [memoVals.updateTilData, memoVals.clockOnID]);

  let calcClockBreak = (cd) => {
    let num = 0;
    if (cd.break1durMins) {
      num += cd.break1durMins;
    }

    if (cd.break2durMins) {
      num += cd.break2durMins;
    }

    if (cd.break3durMins) {
      num += cd.break3durMins;
    }

    return num;
  };

  let enterFct = (e) => {
    if (e.key === "Enter" || e === "add") {
      if (shiftAddNoteValue) {
        setToggleAddNote(false);
        let ds = dateStringer.createStringFromTimestamp(new Date().getTime());
        axios
          .post(
            `${serverURL}/add-note-to-shift`,
            {
              type: state.itemType,
              shiftID: state.itemID,
              ds,
              note: shiftAddNoteValue,
            },

            {
              withCredentials: true,
              credentials: "include",
            }
          )
          .then((response) => {
            if (response.data.message === "success") {
              setShiftAddNoteValue("");

              // response.data.publicNote.sort((a, b) => b.ts - a.ts);
              setTimeout(() => {
                if (notesBox.current) {
                  notesBox.current.scrollTo({
                    top: notesBox.current.scrollHeight, // Scroll to the bottom of the div
                    behavior: "smooth", // Smooth scroll behavior
                  });
                }
              }, 300);
              setState({
                itemNote: [
                  ...state.itemNote,
                  {
                    ds,
                    note: shiftAddNoteValue,
                    fName: "You",
                    lName: "",
                    userID: memoVals.checkUserState.userID,
                    noteID: response.data.noteID,
                    ts: response.data.ts,
                  },
                ],
              });

              memoVals.setDynanicSwapOrNote((x) => {
                return {
                  typeID: state.itemID,
                  swapOrNote: "note",
                  note:
                    response.data.publicNote &&
                    response.data.publicNote.length > 0
                      ? true
                      : false,
                  swap: "",
                };
              });
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  };

  let [showNotes, setShowNotes] = useState(
    memoVals.showMyShiftModal.showNotes || false
  );
  useEffect(() => {
    if (showNotes) {
      setTimeout(() => {
        if (notesBox.current) {
          notesBox.current.scrollTo({
            top: notesBox.current.scrollHeight, // Scroll to the bottom of the div
            behavior: "smooth", // Smooth scroll behavior
          });
        }
      }, 150);
    }
  }, [showNotes]);
  let [toggleAddNote, setToggleAddNote] = useState(false);
  let [shiftAddNoteValue, setShiftAddNoteValue] = useState("");
  // let generateShiftTags = useMemo(() => {
  //   return state.itemTags
  //     ? state.itemTags.map((tag, i) => {
  //         return (
  //           <div
  //             key={`${tag}${tag[i]}`}
  //             className={`tagUnit ${showNotes ? "notesTagUnit" : ""}`}
  //           >
  //             <p className="tagTxt">{tag}</p>
  //             {!showNotes ? (
  //               <img
  //                 className="pullTagImg"
  //                 src={closeGrey}
  //                 alt="Remove tag"
  //                 onClick={() => {
  //                   axios
  //                     .post(
  //                       `${serverURL}/remove-shift-tag`,
  //                       {
  //                         shiftID: state.itemID,
  //                         data: tag,
  //                         type: `${
  //                           state.itemType === "shift" ? "shift" : "til"
  //                         }`,
  //                       },

  //                       {
  //                         withCredentials: true,
  //                         credentials: "include",
  //                       }
  //                     )
  //                     .then((response) => {
  //                       if (response.data.message === "success") {
  //                         setChangesMade(true);

  //                         setState({ tags: response.data.tags });

  //                         // add back to available tags
  //                         // let preAvaTags = availableTags
  //                         if (!availableTags.includes(tag)) {
  //                           setAvailableTags([...availableTags, tag]);
  //                         }
  //                       }
  //                     })
  //                     .catch((err) => {
  //                       console.error(err);
  //                     });
  //                 }}
  //               />
  //             ) : (
  //               ""
  //             )}
  //           </div>
  //         );
  //       })
  //     : "";
  // }, [state.itemTags, showNotes]);
  useEffect(() => {
    // clean not needed
    setTimeout(() => {
      if (notesBox.current && toggleAddNote) {
        notesBox.current.scrollTo({
          top: notesBox.current.scrollHeight, // Scroll to the bottom of the div
          behavior: "smooth", // Smooth scroll behavior
        });
      }
    }, 300);
  }, [toggleAddNote]);
  useEffect(() => {
    // clean not needed
    if (notesBox.current) {
      notesBox.current.scrollTo({
        top: 0, // Scroll to the bottom of the div
        behavior: "smooth", // Smooth scroll behavior
      });
    }
  }, [memoVals.tilReqMade, showNotes]);

  let addNoteTextArea = useRef();
  useEffect(() => {
    // clean not needed

    if (addNoteTextArea.current) {
      addNoteTextArea.current.focus();
    }
  }, [toggleAddNote]);
  let generateTags = React.useMemo(() => {
    return (
      state.itemTags &&
      state.itemTags.map((tag) => {
        return (
          <p className="itemTag" key={Math.random()}>
            {tag}
          </p>
        );
      })
    );
  }, [state]);

  let calcClockedData = (data) => {
    if (data.startDs) {
      // "startDs": "Y2022M10D11H12M49",
      // "approved": false,
      // "break1durMins": null,
      // "break1endDs": null,
      // "break1startDs": null,
      // "break2durMins": null,
      // "break2endDs": null,
      // "break2startDs": null,
      // "break3durMins": null,
      // "break3endDs": null,
      // "break3startDs": null,

      let endObj = new Date(dateStringer.createTimestampFromString(data.endDs));
      let startObj = new Date(
        dateStringer.createTimestampFromString(data.startDs)
      );
      let durMins = (endObj.getTime() - startObj.getTime()) / 1000 / 60;

      if (data.break1durMins) {
        durMins -= data.break1durMins;
      }

      if (data.break2durMins) {
        durMins -= data.break2durMins;
      }

      if (data.break3durMins) {
        durMins -= data.break3durMins;
      }

      return durMins;
    } else {
      return "";
    }
  };

  let formatName = (name) => {
    if (name) {
      let output = "";
      let stop = false;
      let spaceAt = 0;
      for (var i = 0; i < name.length; i++) {
        if (name.charAt(i) !== " ") {
          if (!stop) {
            output += name.charAt(i);
          }
        } else {
          stop = true;
          spaceAt = i;
        }
      }

      return `${output} ${name.charAt(spaceAt + 1)}`;
    } else {
      return "";
    }
  };

  // state.itemNote.sort((a, b) => b.ts - a.ts);

  let generateNotes =
    state.itemNote && state.itemNote[0]
      ? state.itemNote
          .sort((a, b) => {
            return (
              a.ts - b.ts
              // dateStringer.createTimestampFromString(b.ds) -
              // dateStringer.createTimestampFromString(a.ds)
            );
          })
          .map((note) => {
            let nameVal = `${note.fName} ${note.lName}`;

            let usrLiked = false;
            if (note.likedUserObjects && note.likedUserObjects[0]) {
              note.likedUserObjects.forEach((obj) => {
                if (obj.userID === memoVals.checkUserState.userID) {
                  usrLiked = true;
                }
              });
            }
            return (
              <div className="shiftNotesObj">
                <div className="noteObjNameAndDs">
                  <p
                    className="noteObjName"
                    onClick={() => {
                      console.log(state.itemNote);
                    }}
                  >
                    {nameVal && nameVal.length > 14
                      ? `${note.fName} ${
                          note.lName && note.lName[0] ? note.lName[0] : ""
                        }`
                      : nameVal}
                  </p>
                  <p className="noteObjFsDs">
                    {dateStringer.printedDateWithTimeFromDs(note.ds)}
                  </p>
                </div>
                <p className="noteObjNote">{note.note}</p>
                <div className="deleteNoteRow marginBottom10">
                  {note.likedUserObjects &&
                  note.likedUserObjects[0] &&
                  note.likedUserObjects.length > 0 &&
                  memoVals.checkUserState.userID === state.itemUserID ? (
                    <div className="likedUsersSection">
                      {note.likedUserObjects[0] ? (
                        <p
                          className="ackTitle"
                          onClick={() => {
                            console.log({ usrLiked });
                          }}
                        >
                          Acknowledged:
                        </p>
                      ) : (
                        ""
                      )}
                      {note.likedUserObjects[0] ? (
                        <div
                          className="likedArrMapDiv"
                          onClick={() => {
                            if (showLikedCommentIDs.includes(note.noteID)) {
                              setShowCommentLikedIDs(
                                showLikedCommentIDs.filter((x) => {
                                  return x !== note.noteID;
                                })
                              );
                            } else {
                              setShowCommentLikedIDs([
                                ...showLikedCommentIDs,
                                note.noteID,
                              ]);
                            }
                          }}
                        >
                          {note.likedUserObjects.map((liked, i) => {
                            if (i < 8) {
                              return liked.profPicUrl ? (
                                <img
                                  src={liked.profPicUrl}
                                  alt={liked.fName}
                                  className="ackUsrIcon"
                                />
                              ) : (
                                <p className="ackUsrInitials">
                                  {liked.fName[0]}
                                  {liked.lName[0]}
                                </p>
                              );
                            }
                          })}{" "}
                          {note.likedUserObjects.length > 7 ? (
                            <p className="ackUsrExcess">
                              +{note.likedUserObjects.length - 8}
                            </p>
                          ) : (
                            ""
                          )}
                          {showLikedCommentIDs.includes(note.noteID) ? (
                            ""
                          ) : (
                            <img
                              src={acknowledgeArr}
                              alt="Show acknowledged"
                              className="showAckExpandIcon"
                              onClick={() => {
                                setShowCommentLikedIDs([
                                  ...showLikedCommentIDs,
                                  note.noteID,
                                ]);
                              }}
                            />
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                      {showLikedCommentIDs.includes(note.noteID) ? (
                        <div className="showLikedNamesCom">
                          <img
                            onClick={() => {
                              setShowCommentLikedIDs(
                                showLikedCommentIDs.filter((x) => {
                                  return x !== note.noteID;
                                })
                              );
                            }}
                            src={arrGrey}
                            alt="Acknowledged"
                            className="ackArrClose"
                          />
                          <div className="ackNamesList">
                            {note.likedUserObjects.map((x) => {
                              if (x.userID === memoVals.checkUserState.userID) {
                                return <p className="ackNameItem">You</p>;
                              } else {
                                return (
                                  <p className="ackNameItem">
                                    {x.fName} {x.lName}
                                  </p>
                                );
                              }
                            })}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    <p></p>
                  )}
                  {/* <div className="clockNoteAckArr">
            {note.likedUserIDs &&
              note.likedUserIDs[0] &&
              note.likedUserIDs.map((liker) => {
                return liker.substr(0, 3);
              })}
          </div> */}

                  {note.userID === memoVals.checkUserState.userID ? (
                    <img
                      src={bin1}
                      alt="Remove note"
                      className="binNoteImg"
                      onClick={() => {
                        axios
                          .post(
                            `${serverURL}/add-note-to-shift`,
                            {
                              type: state.itemType,
                              shiftID: state.itemID,
                              // ds: dateStringer.createStringFromTimestamp(
                              //   new Date().getTime()
                              // ),
                              // note: shiftAddNoteValue,
                              noteIDifRemoving: note.noteID,
                            },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              setToggleAddNote(false);
                              setShiftAddNoteValue("");

                              // response.data.publicNote.sort(
                              //   (a, b) => b.ts - a.ts
                              // );

                              setState({
                                itemNote: state.itemNote.filter((x) => {
                                  return x.noteID !== note.noteID;
                                }),
                              });

                              memoVals.setDynanicSwapOrNote((x) => {
                                return {
                                  typeID: state.itemID,
                                  swapOrNote: "note",
                                  note:
                                    response.data.publicNote &&
                                    response.data.publicNote.length > 0
                                      ? true
                                      : false
                                      ? true
                                      : false,
                                  swap: "",
                                };
                              });
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }}
                    />
                  ) : (
                    <img
                      src={
                        hoveredLikeCommentID === note.noteID &&
                        usrLiked &&
                        !memoVals.mobModal
                          ? liked2
                          : usrLiked
                          ? liked
                          : like
                      }
                      alt="Acknowledge note"
                      className={`binNoteImg ${usrLiked ? "x18429814581" : ""}`}
                      onMouseEnter={() => {
                        setHoveredLikeCommentID(note.noteID);
                      }}
                      onMouseLeave={() => {
                        setHoveredLikeCommentID("");
                      }}
                      onClick={() => {
                        setShowCommentLikedIDs(
                          (showLikedCommentIDs || []).filter((x) => {
                            return x !== note.noteID;
                          })
                        );

                        axios
                          .post(
                            `${serverURL}/acknowledge-shift-or-til-comment`,
                            {
                              type: state.itemType,
                              noteID: note.noteID,
                              shiftID: state.itemID,
                              undo: usrLiked ? true : false,
                              nowDs: dateStringer.createStringFromTimestamp(
                                new Date().getTime()
                              ),
                            },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              // update here
                              // setUpdate(!update);
                              if (!usrLiked) {
                                memoVals.setIndicate((x) => {
                                  return {
                                    show: true,
                                    message: `Acknowledged ${dateStringer.possession(
                                      note.fName
                                    )} note`,
                                    colour: "green",
                                    duration: 2000,
                                  };
                                });
                              }
                              let newArr = [];
                              state.itemNote.forEach((n) => {
                                if (n.noteID !== note.noteID) {
                                  newArr.push(n);
                                } else {
                                  // amend
                                  if (usrLiked) {
                                    // is undo
                                    // REMOVE IT
                                    let filteredOut = n.likedUserObjects.filter(
                                      (x) => {
                                        return (
                                          x.userID !==
                                          memoVals.checkUserState.userID
                                        );
                                      }
                                    );
                                    newArr.push({
                                      noteID: n.noteID,
                                      note: n.note,
                                      fName: n.fName,
                                      lName: n.lName,
                                      ds: n.ds,
                                      likedUserObjects: filteredOut,
                                      userID: n.noteID,
                                    });
                                  } else {
                                    newArr.push({
                                      noteID: n.noteID,
                                      note: n.note,
                                      fName: n.fName,
                                      lName: n.lName,
                                      ds: n.ds,
                                      likedUserObjects: [
                                        {
                                          fName: response.data.fName,
                                          lName: response.data.lName,
                                          userID:
                                            memoVals.checkUserState.userID,
                                          profPicUrl: response.data.profPicUrl,
                                        },
                                        ...n.likedUserObjects,
                                      ],
                                      userID: n.noteID,
                                    });

                                    // newObj.likedUserObjects.filter((x) => {
                                    //   return x.userID !== memoVals.checkUserState.userID;
                                    // });
                                  }
                                }
                              });

                              console.log({ newArr });
                              setState({ itemNote: newArr });
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }}
                    />
                  )}
                </div>
              </div>
            );
          })
      : "";

  let generateChanges = (
    <div className="changesArrDiv">
      {amends
        .sort((a, b) => {
          return b.ts - a.ts;
        })
        .map((amend) => {
          let change = "";
          if (amend.type === "break") {
            change = (
              <>
                The break was amended from
                <br />
                <span className="amendBoldSpan">
                  {dateStringer.formatMinsDurationToHours(amend.from)}
                </span>{" "}
                to{" "}
                <span className="amendBoldSpan">
                  {dateStringer.formatMinsDurationToHours(amend.to)}
                </span>
              </>
            );
          }
          if (amend.type === "times") {
            change = (
              <>
                The times were amended from
                <br />
                <span className="amendBoldSpan">{amend.from}</span> to{" "}
                <span className="amendBoldSpan">{amend.to}</span>
              </>
            );
          }
          if (amend.type === "date") {
            change = (
              <>
                The date was amended from
                <br />
                <span className="amendBoldSpan">
                  {dateStringer.printedDateFromDs(amend.from)}
                </span>{" "}
                to{" "}
                <span className="amendBoldSpan">
                  {dateStringer.printedDateFromDs(amend.to)}
                </span>
              </>
            );
          }
          if (amend.type === "type") {
            change = (
              <>
                The type was amended from
                <br />
                <span className="amendBoldSpan">{amend.from}</span> to{" "}
                <span className="amendBoldSpan">{amend.to}</span>
              </>
            );
          }
          if (amend.type === "adding") {
            change = (
              <>
                This{" "}
                <span className="amendBoldSpan">
                  {state.itemType === "til"
                    ? "overtime (time in lieu)"
                    : state.itemType}
                </span>{" "}
                was added
              </>
            );
          }
          if (amend.type === "tag") {
            if (amend.to) {
              change = (
                <>
                  The tag <span className="amendBoldSpan">{amend.to}</span> was
                  added
                </>
              );
            }
            if (amend.from) {
              change = (
                <>
                  The tag <span className="amendBoldSpan">{amend.from}</span>{" "}
                  was removed
                </>
              );
            }
          }
          return (
            <div className="shiftAmendUnit">
              <p className="shiftAmendType">{change}</p>
              <p className="shiftAmendBy">by {amend.mgr}</p>
              <p className="shiftAmendWhen">
                on {dateStringer.printedDateFromDs(amend.ds)}
              </p>
              {amend.acknowledged ? (
                <p
                  className={`shiftAmendAck ${
                    memoVals.checkUserState.userID === state.itemUserID
                      ? "none"
                      : ""
                  }`}
                >
                  <img
                    src={greenTick}
                    alt="Acknowledged"
                    className="acknowledgedAmendTickImg"
                  />
                  Acknowledged on{" "}
                  {dateStringer.printedDateFromDs(amend.acknowledged)}
                </p>
              ) : (
                <p
                  className={`shiftAmendAck ${
                    memoVals.checkUserState.userID === state.itemUserID
                      ? "none"
                      : ""
                  }`}
                >
                  Not yet acknowledged by {state.itemFName}
                </p>
              )}
            </div>
          );
        })}
    </div>
  );
  // //  React.useMemo(() => {
  //   return state.history.map((unit, i) => {
  //     if (unit && unit !== "none") {
  //       let final = false;
  //       if (i === state.history.length - 1) {
  //         final = true;
  //       }
  //       return (
  //         <div
  //           className={`shiftHistoryRow ${final ? "shiftHistoryRowFinal" : ""}`}
  //           key={unit.changeID}
  //         >
  //           <p className="shiftHistoryMain myShiftChangesColour">{unit.text}</p>
  //           <div className="shiftHistoryTimeContainer">
  //             <div className="cancelShiftChangeRequestTxtContainer"></div>
  //             <p className="shiftHistoryTime">
  //               {unit.ds.substr(12, 2)}:{unit.ds.substr(15, 2)},{" "}
  //               {dateStringer.printedDateFromDs(unit.ds).substr(4, 6)}
  //             </p>
  //           </div>
  //         </div>
  //       );
  //     }
  //   });
  // }, [state.history]);

  let renderEditMyShiftModal = () => {
    if (memoVals.showEditMyShiftModal) {
      return (
        <EditMyShiftRequestModal
          unitType={state.itemType}
          unitID={state.itemID}
          unitTeamID={state.itemTeamID}
          unitUserID={state.itemUserID}
          unitDs={state.itemDs}
          unitStart={state.itemStart}
          unitEnd={state.itemEnd}
          unitBreak={state.itemBreak}
          setUpdateChangeRequests={setChangesRequests}
        />
      );
    } else {
      return "";
    }
  };

  let renderTilTrimModal = () => {
    if (memoVals.showTilTrimModal) {
      return (
        <TilTrimModal
          unitType={state.itemType}
          unitID={state.itemID}
          unitDate={state.itemDs}
          unitShiftMins={state.itemDuration}
          unitStart={state.itemStart}
          unitEnd={state.itemEnd}
          unitTeamID={state.itemTeamID}
          unitBrkMins={state.itemBreak}
        />
      );
    }
  };

  let shortenTeamName = (name) => {
    if (name.length > 20) {
      return `${name.substr(0, 18)}...`;
    } else {
      return name;
    }
  };

  let logTagsArr = showLogActualTimes
    ? [...state.itemTags, ...loggedAddedTags]
    : [...state.itemTags, ...state.loggedAddedTags];

  if (
    !memoVals.clockOnID &&
    !memoVals.seeUserID.userID &&
    !memoVals.showMessages
  ) {
    return (
      <div
        className={`myShiftModalUnderlay ${
          memoVals.swap && memoVals.swap.shiftID ? "invis" : ""
        }`}
        onClick={() => {
          memoVals.setShowMyShiftModal((x) => {
            return {};
          });
          clearData();
        }}
      >
        {loading ? (
          <div
            className={`shiftLoadingBox ${
              memoVals.mobModal
                ? "shiftLoadingBoxMob"
                : "shiftLoadingBoxDesktop"
            }`}
          >
            <img
              src={horizontalBalls}
              alt="Loading"
              className={`shiftLoadingBallsImg ${
                memoVals.mobModal ? "shiftLoadingBallsImgMob" : "zoomIn"
              }`}
            />
          </div>
        ) : (
          <div
            className={`${
              state.itemStart && state.itemEnd && state.itemType
                ? "myShiftModalBox"
                : "none"
            } ${
              memoVals.mobModal
                ? "mobMyShiftModalBox slideUp mobModalShoulder"
                : "zoomIn myShiftModalBoxDesktop"
            }`}
            onClick={(e) => {
              e.stopPropagation();
            }}
            style={modalPosition}
            ref={modalRef}
          >
            {/* <div className="aa22">{parseInt(dragPosition)}</div> */}
            {memoVals.mobModal && (
              <div
                className="modalSwiper"
                onTouchStart={memoVals.mobModal ? handleDown : null}
                onTouchMove={memoVals.mobModal ? handleMove : null}
                onTouchEnd={memoVals.mobModal ? handleUp : null}
                onMouseDown={memoVals.mobModal ? handleDown : null}
                onMouseMove={memoVals.mobModal ? handleMove : null}
                onMouseUp={memoVals.mobModal ? handleUp : null}
              ></div>
            )}
            <div
              className="myShiftHeaderParent"
              onTouchStart={memoVals.mobModal ? handleDown : null}
              onTouchMove={memoVals.mobModal ? handleMove : null}
              onTouchEnd={memoVals.mobModal ? handleUp : null}
              onMouseDown={memoVals.mobModal ? handleDown : null}
              onMouseMove={memoVals.mobModal ? handleMove : null}
              onMouseUp={memoVals.mobModal ? handleUp : null}
            >
              <div className="myShiftHeaderLeft">
                <div
                  className="myShiftModalHeaderContainer"
                  onClick={() => {
                    if (memoVals.mobModal) {
                      memoVals.setShowMyShiftModal((x) => {
                        return {};
                      });
                      clearData();
                    }
                  }}
                >
                  <div
                    className="myShiftCloseHolder"
                    onClick={() => {
                      memoVals.setShowMyShiftModal((x) => {
                        return {};
                      });
                      clearData();
                    }}
                  >
                    <img
                      src={close}
                      alt="Close"
                      className="closeMyShiftModalImg"
                    />
                  </div>
                  <p
                    className="myShiftModalHeaderTitleTxt"
                    onClick={() => {
                      console.log(changeRequests);
                    }}
                  >
                    {state.ghost
                      ? "Paid day off"
                      : state.itemType === "shift"
                      ? `${
                          otherUser
                            ? `${state.fName}${
                                state.fName[state.fName.length - 1] === "s"
                                  ? "'"
                                  : "'s"
                              } shift`
                            : "My Shift"
                        }`
                      : `${
                          state.itemType === "til"
                            ? `${
                                otherUser
                                  ? `${state.fName}${
                                      state.fName[state.fName.length - 1] ===
                                      "s"
                                        ? "'"
                                        : "'s"
                                    } overtime (time in lieu)`
                                  : "My overtime (time in lieu)"
                              }`
                            : `${
                                otherUser
                                  ? `${state.fName}${
                                      state.fName[state.fName.length - 1] ===
                                      "s"
                                        ? "'"
                                        : "'s"
                                    } overtime`
                                  : "My overtime"
                              }`
                        }`}{" "}
                  </p>
                  {/* <div className="myShiftModalHeaderRight">&nbsp;</div> */}
                </div>
                <div className="shiftBubblesBar">
                  <div className="shiftTeamSubBubble">
                    {shortenTeamName(state.itemTeamName)}
                  </div>
                  {otherUser ? (
                    <p className="shiftCostBubble salariedCost">
                      {dateStringer.formatMinsDurationToHours(
                        state.itemDuration
                      )}
                    </p>
                  ) : (
                    ""
                  )}
                  {!state.salaried && !otherUser ? (
                    <p
                      className={`${
                        memoVals.checkUserState.showCosts &&
                        state.itemCost > 0 &&
                        showEarnings
                          ? "shiftCostBubble"
                          : "none"
                      }`}
                    >
                      {state.itemCost > 0 && showEarnings
                        ? `${memoVals.currencySymbol}${
                            state.itemCost % 1 === 0
                              ? state.itemCost
                              : state.itemCost.toFixed(2)
                          }`
                        : "0"}
                    </p>
                  ) : (
                    ""
                  )}

                  {state.salaried && !otherUser && showEarnings ? (
                    <p className="shiftCostBubble salariedCost">Salaried</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="myShiftHeaderRight">
                {state.profPicUrl ? (
                  <img
                    src={state.profPicUrl}
                    alt="Shift"
                    className="myShiftProfPicImg"
                    onClick={() => {
                      memoVals.setSeeUserID((x) => {
                        return {
                          userID: state.otherUserID,
                          openedFromUserRota: true,
                        };
                      });
                    }}
                  />
                ) : (
                  <div className="myShiftProfPicBlankDiv">
                    <img
                      src="https://ik.imagekit.io/timelify/propic_CTHtZwj7X.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1662246600966"
                      alt="Shift"
                      className="myShiftBlankProfPicImg"
                      onClick={() => {
                        memoVals.setSeeUserID((x) => {
                          return {
                            userID: state.otherUserID,
                            openedFromUserRota: true,
                          };
                        });
                      }}
                    />
                    <div
                      className="myShiftBlankInitialsHolder"
                      onClick={() => {
                        memoVals.setSeeUserID((x) => {
                          return {
                            userID: state.otherUserID,
                            openedFromUserRota: true,
                          };
                        });
                      }}
                    >
                      {state.fName ? state.fName[0] : ""}
                      {state.lName ? state.lName[0] : ""}
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* modal body */}
            <div
              className={`myShiftModalBody ${
                memoVals.mobModal
                  ? `mobMyShiftModalBody mobMyShiftModalBody2 ${
                      memoVals.device.ios ? "mobMyShiftModalBody2Ios" : ""
                    }`
                  : ""
              }`}
              ref={notesBox}
            >
              {showNotes ? (
                <div>
                  <div className="shiftNotesTitleAndAdd myShiftTitleAndAdd">
                    <p className="shiftNotesTitleTxt">Notes</p>
                  </div>

                  {/* {memoVals.mobModal && toggleAddNote ? ( */}
                  {/* // "" */}
                  {/* // ) : ( */}
                  <div className="notesHolder">
                    {state.itemNote && state.itemNote[0] ? (
                      generateNotes
                    ) : (
                      <p className="noShiftNotes">No notes yet</p>
                    )}
                    {!toggleAddNote && (
                      <div
                        className={`addClockNoteBtn addMyShNote blueColours ${
                          toggleAddNote ? "addClockNoteBtnActive" : ""
                        }`}
                        onClick={() => {
                          setToggleAddNote(!toggleAddNote);
                        }}
                      >
                        Add note{" "}
                        <img
                          src={noteImg}
                          alt="Add note"
                          className={`addNoteInlineImg ${
                            toggleAddNote ? "x19387459" : ""
                          }`}
                        />
                      </div>
                    )}
                    {toggleAddNote ? (
                      <div className="shiftAddNoteDiv x238939884">
                        <textarea
                          ref={addNoteTextArea}
                          className="shiftAddNoteTextArea"
                          value={shiftAddNoteValue}
                          onKeyPress={(e) => {
                            enterFct(e);
                          }}
                          onChange={(e) => {
                            setShiftAddNoteValue(e.target.value);
                          }}
                        ></textarea>
                        <div className="shiftAddNoteRightBtns">
                          <p
                            className={`shiftAddNoteSaveBtn ${
                              !shiftAddNoteValue ? "disableAddNoteBtn" : ""
                            }`}
                            onClick={() => {
                              enterFct("add");
                            }}
                          >
                            Add
                          </p>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {/* // )}  */}
                </div>
              ) : (
                <div className="">
                  <div className="myShiftRow">
                    <div className="myShiftRowLeft">
                      <p className="myShiftRowLeftTitle">When</p>{" "}
                      <p className="myShiftWhenDsStrip">
                        {dateStringer.printedDateFromDs(state.itemDs)}&nbsp;
                        {state.itemDs.substr(1, 4)}
                      </p>
                    </div>
                    <div className="myShiftRowRight"></div>
                  </div>
                  {state.ghost ? (
                    <div className="myShiftRow">
                      <div className="myShiftRowLeft paidTimeOffMyShift">
                        {state.closedDayName || "Paid day off"}
                      </div>
                      <div className="myShiftRowRight"></div>
                    </div>
                  ) : (
                    ""
                  )}
                  {!state.ghost ? (
                    <div className="myShiftRow">
                      <div className="myShiftRowLeft">
                        <div className="myShiftRowLeftTitle myShiftTimesRowFlex">
                          {preLoggedTimes
                            ? "Logged (approved) times"
                            : loggedStartTime && !preLoggedTimes
                            ? "Scheduled times"
                            : "Times"}
                          <p
                            className={`${
                              state.tilApproved ||
                              state.tilDeclined ||
                              state.tilRequested
                                ? `youReqTilSpan ${
                                    state.tilApproved
                                      ? "approved12TilRow"
                                      : `${
                                          state.tilDeclined
                                            ? "declined12TilRow"
                                            : ""
                                        }`
                                  }`
                                : "none"
                            }`}
                          >
                            {`${
                              state.tilDeclined
                                ? `${dateStringer.formatMinsDurationToHours(
                                    state.tilMins
                                  )} TOIL declined`
                                : `${
                                    state.tilApproved ? "" : "Requested"
                                  } ${dateStringer.formatMinsDurationToHours(
                                    state.tilMins
                                  )} TOIL${state.tilApproved ? " approved" : ""}
                          `
                            }`}
                          </p>
                        </div>
                        <div
                          className={`tilSplitShiftContainer ${
                            otherUser ? "maxWidth172" : ""
                          } ${
                            state.tilApproved &&
                            dateStringer.generateTilShiftString(
                              state.tilStart,
                              state.tilEnd,
                              state.itemStart,
                              state.itemEnd,
                              false,
                              "",
                              state.itemType
                            ).length > 14 &&
                            !dateStringer
                              .generateTilShiftString(
                                state.tilStart,
                                state.tilEnd,
                                state.itemStart,
                                state.itemEnd,
                                "",
                                false,
                                state.itemType
                              )
                              .includes("Whole")
                              ? ""
                              : "myShiftTimeStrip"
                          } ${
                            state.itemType !== "shift" &&
                            state.tilApproved &&
                            state.tilStart
                              ? "x139819841"
                              : ""
                          }`}
                        >
                          {/* <div
                            className={`${
                              state.tilApproved &&
                              dateStringer.generateTilShiftString(
                                state.tilStart,
                                state.tilEnd,
                                state.itemStart,
                                state.itemEnd,
                                false,
                                "",
                                state.itemType
                              ).length > 14 &&
                              !dateStringer
                                .generateTilShiftString(
                                  state.tilStart,
                                  state.tilEnd,
                                  state.itemStart,
                                  state.itemEnd,
                                  false,
                                  "",
                                  state.itemType
                                )
                                .includes("Whole")
                                ? "tilABShiftBox"
                                : "none"
                            }`}
                          >
                            <p
                              className={`tilArow ${
                                state.tilApproved
                                  ? "approved12TilRow maxW24"
                                  : ""
                              }`}
                            >
                              1
                            </p>
                            <p
                              className={`tilBrow ${
                                state.tilApproved
                                  ? "approved12TilRow maxW24"
                                  : ""
                              }`}
                            >
                              2
                            </p>
                          </div> */}

                          <p
                            className={`myShiftTimesRow ${
                              state.tilRequested &&
                              !state.tilApproved &&
                              !state.tilDeclined
                                ? "fadeTilTimes"
                                : ""
                            }`}
                          >
                            <span
                              className={`${
                                state.tilMins === state.itemDuration &&
                                state.takeTilRequestMade &&
                                !state.tilDeclined
                                  ? "allTilOff"
                                  : "none"
                              }`}
                            >
                              Whole {state.itemType} off as TOIL
                            </span>

                            <span
                              className={`${
                                state.tilMins === state.itemDuration &&
                                state.takeTilRequestMade &&
                                !state.tilDeclined
                                  ? "none"
                                  : `shiftTimesStrip ${
                                      loggedStartTime
                                        ? "shiftTimesStripFade"
                                        : ""
                                    } ${
                                      dateStringer
                                        .generateTilShiftString(
                                          state.tilStart,
                                          state.tilEnd,
                                          state.itemStart,
                                          state.itemEnd,
                                          false,
                                          "",
                                          state.itemType
                                        )
                                        .includes("Whole")
                                        ? "lineHeight22px"
                                        : ""
                                    }`
                              }`}
                              onClick={() => {
                                console.log({ state });
                              }}
                            >
                              {state.itemStart} - {state.itemEnd}
                              {/* {`${
                                (state.tilRequested || state.tilApproved) &&
                                !state.tilDeclined
                                  ? `${dateStringer.generateTilShiftString(
                                      state.tilStart,
                                      state.tilEnd,
                                      state.itemStart,
                                      state.itemEnd,
                                      false,
                                      "",
                                      state.itemType
                                    )}`
                                  : `${state.itemStart} - ${state.itemEnd}`
                              }`} */}
                            </span>
                            {state.lateStartDs ? <br /> : ""}
                            {state.lateStartDs ? (
                              <span className="lateStartDsMyShift">
                                Late start{" "}
                                {dateStringer.dsToTimeStrip(state.lateStartDs)}
                              </span>
                            ) : (
                              ""
                            )}
                          </p>

                          <p
                            className={`${
                              state.tilRequested &&
                              !state.tilApproved &&
                              !state.tilDeclined
                                ? "tilTimePreviewWhenRequested"
                                : "none"
                            } ${
                              state.tilMins === state.itemDuration &&
                              state.takeTilRequestMade
                                ? "none"
                                : ""
                            }`}
                          >
                            {state.tilStart} - {state.tilEnd}
                          </p>

                          <p
                            className={`${
                              state.takeTilRequestMade &&
                              (state.tilApproved || state.tilDeclined)
                                ? "blankTilSpaceRight"
                                : "none"
                            }`}
                          >
                            &nbsp;{" "}
                          </p>
                        </div>

                        {preLoggedTimes && !showLogActualTimes && (
                          <div className="loggedTimesRow">
                            <p className="loggedTimesRowTitle">
                              Originally scheduled
                            </p>
                            <p className="loggedTimesRowValue fadeOrigSched">
                              {preLoggedTimes}
                            </p>
                          </div>
                        )}

                        {state.loggedExcessToil &&
                        preLoggedTimes &&
                        state.loggedExcess &&
                        !otherUser ? (
                          <div className="shiftEditTitle editBreakMove excToilMgrShTxt excToilMgrShTxtMyShift">
                            <span
                              className={
                                state.loggedExcess < 0
                                  ? "colourBa0000"
                                  : "colour00aaff"
                              }
                            >
                              {dateStringer.formatMinsDurationToHours(
                                state.loggedExcess
                              )}
                            </span>{" "}
                            {state.loggedExcess < 0
                              ? "deficit deducted from"
                              : "overtime added to"}{" "}
                            your TOIL balance
                          </div>
                        ) : (
                          ""
                        )}
                        {state.startTs <= tonightTs &&
                          !showLogActualTimes &&
                          !loggedStartTime &&
                          !state.createdByClock &&
                          !state.clockData.startDs &&
                          !preLoggedTimes &&
                          !otherUser && (
                            <p
                              className="editMyShTimes"
                              onClick={() => {
                                if (state.itemIsAbsent) {
                                  setShowUnable(true);
                                } else {
                                  setShowLogActualTimes(true);
                                }
                              }}
                            >
                              Log actual times{" "}
                              <img
                                src={editGrey}
                                alt="Add actual times"
                                className="logActimg"
                              />
                            </p>
                          )}
                        {!showLogActualTimes &&
                          loggedStartTime &&
                          !state.itemIsAbsent &&
                          !otherUser && (
                            <div className="loggedTimesRow loggedTimesRowPending">
                              <p className="loggedTimesRowTitle">
                                Logged data (awaiting approval)
                              </p>
                              <p className="loggedTimesRowValue">
                                {loggedStartTime} - {loggedEndTime}{" "}
                                <span className="loggedBrkSize">
                                  {dateStringer.formatMinsDurationToHours(
                                    loggedBrk
                                  )}{" "}
                                  break
                                </span>{" "}
                                <img
                                  src={editNavy}
                                  alt="Edit"
                                  className="editLoggedTimes"
                                  onClick={() => {
                                    setLogStartTime(loggedStartTime);
                                    setLogEndTime(loggedEndTime);
                                    setLogBrk(loggedBrk);
                                    setLoggedLocationID(state.loggedLocationID);
                                    setShowLogActualTimes(true);
                                    setLoggedAddedTags(state.loggedAddedTags);
                                    setLoggedPulledTags(state.loggedPulledTags);
                                    setExcessToil(state.loggedExcessToil);

                                    if (!availableTags[0]) {
                                      axios
                                        .post(
                                          `${serverURL}/get-available-tags`,
                                          {
                                            teamID: state.itemTeamID,
                                          },

                                          {
                                            withCredentials: true,
                                            credentials: "include",
                                          }
                                        )
                                        .then((response) => {
                                          if (
                                            response.data.message === "success"
                                          ) {
                                            setAvailableTags(
                                              response.data.tags
                                            );
                                          }
                                        });
                                    }

                                    // hellothere
                                  }}
                                />
                              </p>
                              {locations[0] && state.loggedLocationID ? (
                                <p className="loggedTimesRowValue fadeOrigSched_">
                                  <img
                                    src={pin}
                                    alt="loggedLocImgPin"
                                    className="loggedLocImgPin"
                                  />{" "}
                                  {locations.filter((x) => {
                                    return (
                                      x.locationID === state.loggedLocationID
                                    );
                                  })[0]
                                    ? locations.filter((x) => {
                                        return (
                                          x.locationID ===
                                          state.loggedLocationID
                                        );
                                      })[0].locationName
                                    : "--"}
                                </p>
                              ) : (
                                ""
                              )}

                              {[...state.itemTags, ...state.loggedAddedTags]
                                .length > 0 ? (
                                <div className={`myShLogTagsDiv`}>
                                  {/* fsoe */}
                                  {logTagsArr.map((tag, i) => {
                                    return (
                                      <div
                                        className={`tsInlineTagItem ${
                                          state.loggedAddedTags.includes(tag) &&
                                          !state.itemTags.includes(tag)
                                            ? "newTsTag"
                                            : "-"
                                        } ${
                                          state.loggedPulledTags.includes(tag)
                                            ? "fadeTagTsSchedEdit"
                                            : ""
                                        }`}
                                        key={i}
                                      >
                                        {tag}
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : (
                                ""
                              )}

                              {loggedExcessMinsSaved !== 0 && (
                                <div className="loggedExcSavedRow">
                                  {dateStringer.formatMinsDurationToHours(
                                    loggedExcessMinsSaved
                                  )}{" "}
                                  {loggedExcessMinsSaved < 0
                                    ? "deficit"
                                    : "overtime"}{" "}
                                  {excessToilSaved
                                    ? loggedExcessMinsSaved < 0
                                      ? "requested to be deducted from your TOIL balance"
                                      : "requested to be added to your TOIL balance"
                                    : ""}{" "}
                                </div>
                              )}
                            </div>
                          )}
                        {showLogActualTimes && (
                          <div
                            className={`logActualDiv ${
                              excessToil &&
                              loggedExcessMins !== 0 &&
                              "openAnimation2"
                            }`}
                          >
                            <div className="logActualFlex">
                              <div className="logActualLeft">
                                <div className="logActualRow">
                                  <p className="logActualRowTitle">
                                    Log actual start time
                                  </p>
                                  <input
                                    type="time"
                                    className="logActualTimeInput"
                                    value={logStartTime}
                                    onChange={(e) => {
                                      let val = e.target.value;
                                      setLogStartTime(val);
                                    }}
                                  ></input>
                                </div>
                                <div className="logActualRow">
                                  <p className="logActualRowTitle">
                                    Log actual end time
                                  </p>
                                  <input
                                    type="time"
                                    className="logActualTimeInput"
                                    value={logEndTime}
                                    onChange={(e) => {
                                      let val = e.target.value;
                                      setLogEndTime(val);
                                    }}
                                  ></input>
                                </div>
                                <div className="logActualRow">
                                  <p className="logActualRowTitle">
                                    Log actual break
                                  </p>
                                  <div className="logBrkInputDiv">
                                    <input
                                      type="number"
                                      className="logActualTimeInput logBrkInput"
                                      value={logBrk || 0}
                                      onChange={(e) => {
                                        let val = e.target.value;
                                        setLogBrk(val < 0 || !val ? 0 : val);
                                      }}
                                    ></input>
                                    <p className="logBrkInputMinsTxt">mins</p>
                                  </div>
                                </div>
                              </div>{" "}
                              <div className="logActualRight">
                                <p className="logActualRightTitle">
                                  Difference
                                </p>
                                <div className="loggedExcFrame">
                                  {dateStringer.formatMinsDurationToHours(
                                    loggedExcessMins,
                                    true,
                                    false
                                  )}
                                </div>
                                <p
                                  className={`loggedExcTxt ${
                                    loggedExcessMins > 0
                                      ? "greenExcTxt"
                                      : loggedExcessMins < 0
                                      ? "redExcTxt"
                                      : "none"
                                  }`}
                                >
                                  {loggedExcessMins > 0
                                    ? "overtime"
                                    : loggedExcessMins < 0
                                    ? "deficit"
                                    : ""}{" "}
                                </p>
                                {loggedExcessMins !== 0 &&
                                  state.itemType !== "til" && (
                                    <div className="excessPrefDiv">
                                      <div
                                        className="excessPrefDivRow"
                                        onClick={() => {
                                          console.log("....");
                                          setExcessToil(!excessToil);
                                        }}
                                      >
                                        <p
                                          className={`excessPrefRowTxt ${
                                            excessToil && "colour143051"
                                          }`}
                                        >
                                          {loggedExcessMins > 0
                                            ? "Add to TOIL balance"
                                            : "Deduct from TOIL balance"}
                                        </p>{" "}
                                        <div
                                          className={`excessPrefDivRowCheckbox ${
                                            excessToil ? "whiteBg" : ""
                                          }`}
                                        >
                                          <img
                                            src={navyTick}
                                            alt="Preference"
                                            className={`excessPrevCheckImg ${
                                              excessToil ? "" : "hidden"
                                            }`}
                                          />
                                        </div>{" "}
                                      </div>
                                    </div>
                                  )}
                              </div>
                            </div>
                            <div className="logActualRow logActualRowTags">
                              <p className="logActualRowTitle">
                                Log actual tags
                              </p>
                              <div className="tsInlineTags marginTop0 noBorder">
                                {[...loggedAddedTags, ...state.itemTags]
                                  .length === 0 ? (
                                  <p className="noSchedTagsTsTxt noSchedTagsTsTxtShTagsLog">
                                    No tags
                                  </p>
                                ) : (
                                  [...state.itemTags, ...loggedAddedTags].map(
                                    (tag) => {
                                      let fadeTag =
                                        state.itemTags.includes(tag) &&
                                        loggedPulledTags.includes(tag);

                                      return (
                                        <p
                                          className={`tsInlineTagItem activeTsTagsInline ${
                                            fadeTag ? "fadeTagTsSchedEdit" : ""
                                          } ${
                                            !state.itemTags.includes(tag) &&
                                            loggedAddedTags.includes(tag)
                                              ? "newTsTag"
                                              : ""
                                          }`}
                                        >
                                          {!state.itemTags.includes(tag) &&
                                          loggedAddedTags.includes(tag)
                                            ? "+ "
                                            : ""}
                                          {tag}
                                          {!fadeTag ? (
                                            <img
                                              src={navyClose}
                                              alt="Remove tag"
                                              className={`removeTagTsSchedImg ${
                                                fadeTag ? "none" : ""
                                              }`}
                                              onClick={() => {
                                                if (
                                                  state.itemTags.includes(tag)
                                                ) {
                                                  setLoggedPulledTags([
                                                    ...loggedPulledTags,
                                                    tag,
                                                  ]);
                                                } else {
                                                  setLoggedAddedTags(
                                                    loggedAddedTags.filter(
                                                      (x) => {
                                                        return x !== tag;
                                                      }
                                                    )
                                                  );
                                                }
                                              }}
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </p>
                                      );
                                    }
                                  )
                                )}
                              </div>
                              <div className="logBrkInputDiv">
                                <select
                                  type="number"
                                  className="logActualTimeInput logLocSelect"
                                  value=""
                                  onChange={(e) => {
                                    let val = e.target.value;

                                    if (!val) {
                                      return;
                                    }
                                    if (loggedPulledTags.includes(val)) {
                                      setLoggedPulledTags(
                                        loggedPulledTags.filter((x) => {
                                          return x !== val;
                                        })
                                      );
                                    } else {
                                      setLoggedAddedTags([
                                        ...loggedAddedTags,
                                        val,
                                      ]);
                                    }
                                  }}
                                >
                                  {" "}
                                  <option value="">-- Add tag</option>
                                  {availableTags.map((tag, i) => {
                                    if (
                                      (!state.itemTags.includes(tag) &&
                                        !loggedAddedTags.includes(tag)) ||
                                      loggedPulledTags.includes(tag)
                                    ) {
                                      return (
                                        <option key={i} value={tag}>
                                          {tag}
                                        </option>
                                      );
                                    }
                                  })}
                                </select>
                              </div>
                            </div>
                            <div className="logActualRow">
                              <p className="logActualRowTitle">
                                Log actual location
                              </p>
                              <div className="logBrkInputDiv">
                                <select
                                  type="number"
                                  className="logActualTimeInput logLocSelect"
                                  value={loggedLocationID}
                                  onChange={(e) => {
                                    let val = e.target.value;
                                    setLoggedLocationID(val);
                                  }}
                                >
                                  {" "}
                                  <option>-- No location</option>
                                  {locations.map((loc) => {
                                    return (
                                      <option
                                        key={loc.locationID}
                                        value={loc.locationID}
                                      >
                                        {loggedLocationID === loc.locationID
                                          ? dateStringer.shorten(
                                              loc.locationName,
                                              25
                                            )
                                          : loc.locationName}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                            <p className="logActualInfo">
                              Your logged data will need to be approved by your
                              manager{" "}
                              {excessToil &&
                                `and your TOIL ${
                                  loggedExcessMins > 0
                                    ? "addition"
                                    : "deduction"
                                } preference will be considered at their discretion`}
                            </p>
                            <div className="saveActualRow">
                              {loggedStartTime && loggedEndTime ? (
                                <p
                                  className="saveActualRowCanx saveActualRowRemove"
                                  onClick={() => {
                                    setSureRemoveLogged(true);
                                  }}
                                >
                                  Remove
                                </p>
                              ) : (
                                <p></p>
                              )}
                              <div className="saveActualSection">
                                <p
                                  className="saveActualRowCanx"
                                  onClick={() => {
                                    setShowLogActualTimes(false);
                                  }}
                                >
                                  Cancel
                                </p>
                                <p
                                  className={`saveActualRowSave ${
                                    logStartTime.length !== 5 ||
                                    !logStartTime.includes(":") ||
                                    logEndTime.length !== 5 ||
                                    !logEndTime.includes(":")
                                      ? "disabled"
                                      : ""
                                  }`}
                                  onClick={() => {
                                    axios
                                      .post(
                                        `${serverURL}/log-actual-times`,
                                        {
                                          shiftID: state.itemID,
                                          type: state.itemType,
                                          start: logStartTime,
                                          end: logEndTime,
                                          brk: parseInt(logBrk || 0),
                                          nowDs:
                                            dateStringer.createStringFromTimestamp(
                                              new Date().getTime()
                                            ),
                                          loggedExcess: loggedExcessMins,
                                          loggedExcessToil: excessToil,
                                          locationID: loggedLocationID,
                                          loggedAddedTags,
                                          loggedPulledTags,
                                        },

                                        {
                                          withCredentials: true,
                                          credentials: "include",
                                        }
                                      )
                                      .then((response) => {
                                        if (
                                          response.data.message === "success"
                                        ) {
                                          setState({
                                            loggedAddedTags,
                                            loggedPulledTags,
                                          });
                                          setLoggedExcessMins(
                                            response.data.loggedExcess
                                          );
                                          setExcessToil(
                                            response.data.loggedExcessToil
                                          );
                                          setLoggedExcessMinsSaved(
                                            response.data.loggedExcess
                                          );
                                          setExcessToilSaved(
                                            response.data.loggedExcessToil
                                          );
                                          setShowLogActualTimes(false);
                                          setLoggedStartTime(
                                            response.data.loggedStart
                                          );
                                          setLoggedEndTime(
                                            response.data.loggedEnd
                                          );
                                          setLoggedBrk(response.data.loggedBrk);

                                          setLoggedAddedTags([]);
                                          setLoggedPulledTags([]);
                                          setState({
                                            loggedLocationID:
                                              response.data.loggedLocationID,
                                            loggedExcessToil:
                                              response.data.loggedExcessToil,
                                          });
                                          memoVals.setIndicate((x) => {
                                            return {
                                              show: true,
                                              message:
                                                "Logged actual start and end times",
                                              colour: "green",
                                              duration: 4000,
                                            };
                                          });
                                          memoVals.setReloadTs(
                                            (x) => !memoVals.reloadTs
                                          );
                                          if (notesBox.current) {
                                            notesBox.current.scrollTo({
                                              top: 0, // Scroll to the bottom of the div
                                              behavior: "smooth", // Smooth scroll behavior
                                            });
                                          }
                                        }
                                      });
                                  }}
                                >
                                  Save
                                </p>
                              </div>
                            </div>
                          </div>
                        )}

                        <p
                          className={`${
                            state.tilDeclined ? "tilDeclinedStrip" : "none"
                          }`}
                        >
                          TOIL declined: {state.tilStart} - {state.tilEnd}
                        </p>
                        <div
                          className={`${
                            state.tilApproved || state.tilRequested
                              ? "myShiftTimesRowOriginalRow"
                              : "none"
                          }`}
                        >
                          <p
                            className={`${
                              !state.tilDeclined
                                ? "originalShiftTimeSpan"
                                : "none"
                            }`}
                          >
                            <span className="originalShiftTimeSpanValue">
                              {dateStringer.formatMinsDurationToHours(
                                state.tilMins
                              )}
                            </span>{" "}
                            off as TOIL starting at {state.tilStart}
                          </p>

                          {/* <p
                            className={`${
                              state.tilApproved &&
                              !state.tilRequested &&
                              !state.tilDeclined
                                ? "tilStatusBubble tilApprovedBg"
                                : "none"
                            }`}
                          >
                            overtime (time in lieu) approved
                          </p> */}
                          <p
                            className={`${
                              state.tilDeclined &&
                              !state.tilRequested &&
                              !state.tilApproved
                                ? "tilStatusBubble tilDeclinedBg"
                                : "none"
                            }`}
                          >
                            TOIL declined
                          </p>
                          <p
                            className={`${
                              state.tilRequested &&
                              !state.tilDeclined &&
                              !state.tilApproved
                                ? "tilStatusBubble tilRequestedBg"
                                : "none"
                            }`}
                          >
                            Awaiting approval
                          </p>
                        </div>
                        <div
                          className={`${
                            // buckley

                            state.tilApproved || state.tilRequested
                              ? "cancelTilReqContainer"
                              : "none"
                          } ${
                            dateStringer.createTimestampFromString(
                              state.itemDs
                            ) <= today00.getTime()
                              ? "invis pullCancelTilUpBecauseInPast"
                              : ""
                          }`}
                        >
                          <div
                            className={`${
                              sureCancelTil ? "none" : "removeTil greyColours"
                            }`}
                            onClick={() => {
                              if (state.tilDeclined) {
                                axios
                                  .post(
                                    `${serverURL}/remove-til-request`,
                                    {
                                      takeTilID: state.takeTilID,
                                      type: state.itemType,
                                      shiftID: state.itemID,
                                      user: true,
                                      nowDs:
                                        dateStringer.createStringFromTimestamp(
                                          new Date().getTime()
                                        ),
                                    },

                                    {
                                      withCredentials: true,
                                      credentials: "include",
                                    }
                                  )
                                  .then((response) => {
                                    if (response.data.message === "success") {
                                      setCountData({
                                        outstandingChanges:
                                          countData.outstandingChanges - 1,
                                      });
                                      // TODO
                                      setState({
                                        tilMins: 0,
                                        tilApproved: false,
                                        tilDeclined: false,
                                        tilRequested: false,
                                        takeTilRequest: false,
                                        takeTilRequestMade: false,
                                      });
                                      memoVals.setShowTilTrimModal(
                                        (x) => false
                                      );
                                      memoVals.setUpdateShifts(
                                        (x) => !memoVals.updateShifts
                                      );
                                    } else {
                                    }
                                  })
                                  .catch((err) => {
                                    console.error(err);
                                  });
                              } else {
                                setSureCancelTil(true);
                              }
                            }}
                          >
                            <img
                              src={cancelTil}
                              alt="Cancel TIL"
                              className="cancelTilImg"
                            />
                            {state.tilDeclined
                              ? "Remove TOIL request"
                              : "Cancel TOIL request"}
                          </div>

                          <p
                            className={`${
                              sureCancelTil ? "removeTilSure" : "none"
                            }`}
                            onClick={() => {
                              // remove til action here
                              if (
                                state.tilRequested ||
                                state.tilApproved ||
                                state.tilDeclined
                              ) {
                                axios
                                  .post(
                                    `${serverURL}/remove-til-request`,
                                    {
                                      takeTilID: state.takeTilID,
                                      type: state.itemType,
                                      shiftID: state.itemID,
                                      user: true,
                                      nowDs:
                                        dateStringer.createStringFromTimestamp(
                                          new Date().getTime()
                                        ),
                                    },

                                    {
                                      withCredentials: true,
                                      credentials: "include",
                                    }
                                  )
                                  .then((response) => {
                                    if (response.data.message === "success") {
                                      // TODO
                                      setState({
                                        tilMins: 0,
                                        tilApproved: false,
                                        tilDeclined: false,
                                        tilRequested: false,
                                        takeTilRequest: false,
                                        takeTilRequestMade: false,
                                      });
                                      memoVals.setShowTilTrimModal(
                                        (x) => false
                                      );

                                      setSureCancelTil(false);
                                      memoVals.setUpdateShifts(
                                        (x) => !memoVals.updateShifts
                                      );
                                    } else {
                                    }
                                  })
                                  .catch((err) => {
                                    console.error(err);
                                  });
                              }
                            }}
                          >
                            {state.tilDeclined
                              ? "Remove request"
                              : "Cancel TIL"}
                            ?
                          </p>
                          <p className=""></p>
                        </div>
                      </div>
                      <div className="myShiftRowRight"></div>
                    </div>
                  ) : (
                    ""
                  )}
                  {/* end of row */}
                  {/* new row */}
                  {(state.clockable &&
                    !state.clockOnID &&
                    !state.ghost &&
                    !state.itemIsAbsent) ||
                  (state.clockedOn && !state.ghost && !state.itemIsAbsent) ? (
                    <div className="myShiftRow">
                      <p className="offerClockOn">
                        Clock {state.clockedOn ? "off" : "on"} now?
                      </p>
                      <p
                        className="clockOnNowBtn x2389438943"
                        onClick={() => {
                          memoVals.setShowMyShiftModal((x) => {
                            return {};
                          });
                          clearData();
                          memoVals.setShowClockOnModal((x) => true);
                        }}
                      >
                        Clock {state.clockedOn ? "off" : "on"}{" "}
                        <img
                          src={clockWhite2}
                          alt="Clock on or off"
                          className="myShClockPopupImg"
                        />
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  {/* end of row */}
                  {highlightedNote && highlightedNote.note && (
                    <div className="highNoteDiv">
                      <p className="highNoteTitle">Highlighted note</p>
                      <p
                        className="highNoteVal"
                        onClick={() => {
                          setShowNotes(true);
                        }}
                      >
                        {highlightedNote.note}
                      </p>
                    </div>
                  )}
                  {/* new row */}
                  <div
                    className={`${state.itemIsAbsent ? "myShiftRow" : "none"}`}
                  >
                    <div className="myShiftRowLeft">
                      <p className="myShiftRowLeftTitle">Absence</p>
                      <span className="myShiftAbsentTxtRed">
                        {state.itemAbsenceData.partialAbsent
                          ? `${state.itemAbsenceData.partialAbsentStart} - ${state.itemAbsenceData.partialAbsentEnd}`
                          : "Absent all day"}
                        &nbsp;
                        <span
                          className={`${
                            state.itemIsAbsent
                              ? "myShiftAbsentDurationSpan"
                              : "none"
                          }`}
                          onClick={() => {
                            console.log("itemdURATION: ", state.itemDuration);
                          }}
                        >
                          {!state.itemAbsenceData.partialAbsent
                            ? ""
                            : dateStringer.formatMinsDurationToHours(
                                `${
                                  state.itemAbsenceData.partialAbsent
                                    ? `${
                                        state.itemAbsenceData
                                          .partialAbsentDuration /
                                        1000 /
                                        60
                                      }`
                                    : `${state.itemDuration}`
                                }`
                              ) !== " mins"
                            ? ` ${dateStringer.formatMinsDurationToHours(
                                `${
                                  state.itemAbsenceData.partialAbsent
                                    ? `${
                                        state.itemAbsenceData
                                          .partialAbsentDuration /
                                        1000 /
                                        60
                                      }`
                                    : `${state.itemDuration}`
                                }`
                              )}`
                            : ""}
                        </span>
                        <span
                          className={`${
                            state.itemIsAbsent &&
                            state.itemUserID === memoVals.checkUserState.userID
                              ? "myShiftAbsentDurationSpan"
                              : "none"
                          }`}
                        >
                          &nbsp;-&nbsp;
                          {state.itemAbsenceData.unpaidAbsent
                            ? "Unpaid"
                            : "Paid"}
                        </span>
                      </span>
                    </div>
                    <div className="myShiftRowRight"></div>
                  </div>
                  {/* end of row */}
                  {/* new row */}
                  <div
                    className={`${
                      state.lateDurMins > 0 ? "myShiftRow" : "none"
                    }`}
                  >
                    <div className="myShiftRowLeft">
                      <p className="myShiftRowLeftTitle">Lateness</p>
                      <span className="shiftTimesStrip colourRed">
                        {dateStringer.formatMinsDurationToHours(
                          state.lateDurMins
                        )}{" "}
                        late &nbsp;
                      </span>
                      <span className="myShiftAbsentDurationSpan">
                        {state.unpaidLate ? "Unpaid" : "Paid"}
                      </span>
                    </div>
                    <div className="myShiftRowRight"></div>
                  </div>
                  {/* end of row */}
                  {/* new row */}
                  {state.clockData &&
                  state.clockData.startDs &&
                  state.clockData.endDs ? (
                    <div className="myShiftRow">
                      <div className="myShiftRowLeft">
                        {/* <p className="myShiftRowLeftTitle">Clocked times</p> */}

                        <div className="myShiftClockedDiv">
                          <div className="myShiftClockOnOffFlex">
                            <div className="myShiftClockedDivRow">
                              <p className="myShiftClockedDivRowTitle">
                                Clocked on
                              </p>
                              <p className="myShiftClockedDivRowValue">
                                {dateStringer.dsToTimeStrip(
                                  state.clockData.startDs,
                                  true
                                )}
                              </p>
                            </div>
                            <div className="myShiftClockedDivRow">
                              <p className="myShiftClockedDivRowTitle txtAlignRight">
                                Clocked off
                              </p>
                              <p className="myShiftClockedDivRowValue txtAlignRight">
                                {dateStringer.dsToTimeStrip(
                                  state.clockData.endDs,
                                  true
                                )}
                              </p>
                            </div>
                          </div>

                          <div className="myShiftClockOnOffFlex">
                            <div className="myShiftClockedDivRow">
                              <p className="myShiftClockedDivRowTitle">
                                Clocked duration
                              </p>
                              <p className="myShiftClockedDivRowValue">
                                {dateStringer.formatMinsDurationToHours(
                                  calcClockedData(state.clockData)
                                )}{" "}
                              </p>
                              {state.loggedExcess &&
                              !otherUser &&
                              state.loggedExcessToil ? (
                                <p className="myShLoggedExcClock">
                                  <span
                                    className={
                                      state.loggedExcess > 0
                                        ? "colour00aaff"
                                        : "colourBa0000"
                                    }
                                  >
                                    {dateStringer.formatMinsDurationToHours(
                                      state.loggedExcess
                                    )}{" "}
                                    {state.loggedExcess < 0
                                      ? "deducted from"
                                      : "added to"}
                                  </span>{" "}
                                  your TOIL balance
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="myShiftClockedDivRow">
                              {otherUser ? (
                                <div className="myShiftClockedDivRow">
                                  <p className="myShiftClockedDivRowTitle txtAlignRight">
                                    Clocked break
                                  </p>
                                  <p className="myShiftClockedDivRowValue txtAlignRight">
                                    {dateStringer.formatMinsDurationToHours(
                                      calcClockBreak(state.clockData)
                                    )}{" "}
                                  </p>
                                </div>
                              ) : (
                                <p
                                  className="viewClockOnMyShiftBtn"
                                  onClick={() => {
                                    memoVals.setClockOnID((x) => {
                                      return {
                                        clockOnID: state.clockOnID,
                                        isMgr: false,
                                        fromShiftManager: false,
                                      };
                                    });
                                  }}
                                >
                                  Clock card{" "}
                                  <img
                                    src={popup}
                                    alt="Clock card"
                                    className="clockCardMyShiftPopUp"
                                  />
                                </p>
                              )}
                              <p
                                className={`myShiftClockStatus ${
                                  state.clockData.approved
                                    ? "ccAPPR"
                                    : state.clockData.declined
                                    ? "ccDECL"
                                    : ""
                                }`}
                              >
                                {state.clockData.approved
                                  ? "Approved"
                                  : state.clockData.declined
                                  ? "Declined"
                                  : "Not yet approved"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="myShiftRowRight"></div>
                    </div>
                  ) : (
                    ""
                  )}
                  {/* end of row */}
                  {/* new row */}
                  {otherUser && forward ? (
                    <div className="myShiftRow myShiftRowFwd">
                      <p className="offerClockOn x23894292111 x38958524 marginLeft0">
                        <img
                          src={dashSwap}
                          alt="Accepted"
                          className="myShDoubleTick x34894358932"
                        />
                        {state.fName} wants to drop this{" "}
                        {state.itemType === "shift" ? "shift" : "overtime"} and
                        put you forward to cover it - pending manager approval
                      </p>

                      <div className="acceptOrDecDiv">
                        {" "}
                        <p
                          className="clockOnNowBtn pinkColours withDrawFwdBtn"
                          onClick={() => {
                            setSureWithdraw(true);
                          }}
                        >
                          Withdraw
                        </p>
                      </div>

                      {/* </div>{" "} */}
                    </div>
                  ) : (
                    ""
                  )}
                  {/* new row */}
                  {!otherUser && state.requireAccept ? (
                    <div className="myShiftRow">
                      {/* <div className="myShiftRowLeft"> */}
                      {!state.accepted ? (
                        <p className="offerClockOn">
                          Accept{" "}
                          {state.itemType === "til"
                            ? "overtime (time in lieu)"
                            : state.itemType}
                        </p>
                      ) : (
                        <p className="offerClockOn x23894292111">
                          <img
                            src={doubleTick}
                            alt="Accepted"
                            className="myShDoubleTick"
                          />
                          You accepted this{" "}
                          {state.itemType === "til"
                            ? "overtime (time in lieu)"
                            : state.itemType}{" "}
                          on {dateStringer.printedDateFromDs(state.accepted)}
                        </p>
                      )}
                      {!state.accepted ? (
                        <div className="acceptOrDecDiv">
                          {" "}
                          <p
                            className="clockOnNowBtn x991091808312"
                            onClick={() => {
                              let nowDs =
                                dateStringer.createStringFromTimestamp(
                                  new Date().getTime()
                                );
                              axios
                                .post(
                                  `${serverURL}/accept-shift`,
                                  {
                                    shiftID: state.itemID,
                                    type: state.itemType,
                                    nowDs,
                                  },

                                  {
                                    withCredentials: true,
                                    credentials: "include",
                                  }
                                )
                                .then((response) => {
                                  if (response.data.message === "success") {
                                    setCountData({
                                      shiftAccepts: countData.shiftAccepts - 1,
                                    });
                                    setState({
                                      accepted: nowDs,
                                    });
                                    memoVals.setAcceptedShiftID(
                                      (x) => state.itemID
                                    );
                                  }
                                });
                            }}
                          >
                            Accept
                            <img
                              src={whiteTick}
                              alt="Absence log"
                              className="absenceLogPopup x13453232d"
                            />
                          </p>
                          <p
                            className="declineShBtn"
                            onClick={() => {
                              setShowDecline(true);
                            }}
                          >
                            Decline
                          </p>
                        </div>
                      ) : (
                        <p></p>
                      )}
                      {/* </div>{" "} */}
                    </div>
                  ) : (
                    ""
                  )}
                  {!otherUser &&
                  state.absenceAllowSelfLog &&
                  !shiftIsPast &&
                  !state.selfLoggedAbsence &&
                  !state.itemIsAbsent ? (
                    <div className="myShiftRow">
                      {/* <div className="myShiftRowLeft"> */}
                      <p className="offerClockOn">Going to be absent?</p>
                      <p
                        className="clockOnNowBtn selfLogAbsColours"
                        onClick={() => {
                          setShowSelfLogAbsenceModal(true);
                          setReason("");
                        }}
                      >
                        Absent
                        <img
                          src={popupX}
                          alt="Absence log"
                          className="absenceLogPopup"
                        />
                      </p>
                      {/* </div>{" "} */}
                    </div>
                  ) : (
                    ""
                  )}
                  {state.selfLoggedAbsence && !otherUser ? (
                    <div className="myShiftRow x48924892875">
                      {/* <div className="myShiftRowLeft"> */}
                      <p className="offerClockOn x1389498523">
                        You have reported yourself as absent
                        <br />
                        <span className="x3928423">
                          {dateStringer.printedDateWithTimeFromDs(
                            state.selfLoggedAbsence
                          )}
                        </span>
                        <br /> <br />
                        <span className="x39284232">
                          {state.selfLoggedAbsenceReason}
                        </span>
                        <br />
                        <br />
                        <span className="selfLogApproval">
                          Not yet approved
                        </span>
                      </p>
                      <p
                        className="clockOnNowBtn canxSelfAbsenceBtn"
                        onClick={() => {
                          setSureCancelAbsence(true);
                        }}
                      >
                        Cancel
                        {/* <img
                          src={popupX}
                          alt="Absence log"
                          className="absenceLogPopup"
                        /> */}
                      </p>
                      {/* </div>{" "} */}
                    </div>
                  ) : (
                    ""
                  )}
                  {/* end of row */}
                  {/* new row */}
                  {otherUser && state.requireAccept && (
                    <div className="myShiftRow">
                      <div className="myShiftRowLeft">
                        {/* <p className="myShiftRowLeftTitle">
                          This{" "}
                          {state.itemType === "til" ? "overtime (time in lieu)" : state.itemType}{" "}
                          requires accepting
                        </p> */}

                        {state.accepted ? (
                          <span className="myShiftBreakSpan x189398311">
                            {state.fName} accepted this{" "}
                            {state.itemType === "til"
                              ? "overtime (time in lieu)"
                              : state.itemType}{" "}
                            on {dateStringer.printedDateFromDs(state.accepted)}
                          </span>
                        ) : (
                          <span className="myShiftBreakSpan">
                            {state.fName} has yet to accept this{" "}
                            {state.itemType === "til"
                              ? "overtime (time in lieu)"
                              : state.itemType}{" "}
                          </span>
                        )}
                      </div>
                      <div className="myShiftRowRight"></div>
                    </div>
                  )}
                  {/* end of row */}
                  {/* new row */}
                  {state.location && (
                    <div className="myShiftRow">
                      <div className="myShiftRowLeft">
                        <p className="myShiftRowLeftTitle">Location</p>
                        <p className="myShiftWhenDsStrip">{state.location}</p>
                      </div>
                      <div className="myShiftRowRight"></div>
                    </div>
                  )}
                  {/* end of row */}
                  {/* new row */}
                  {state.shiftLeaveDurMins && state.shiftLeaveName ? (
                    <div className="myShiftRow">
                      <div className="myShiftRowLeft">
                        <p className="myShiftRowLeftTitle">Paid leave</p>
                        <div className="">
                          <p
                            className={`myShiftLeaveContainerVal ${
                              !state.shiftLeaveNote ? "noPaddingBottom" : ""
                            }`}
                          >
                            {state.shiftLeaveName}
                            <br />
                            <span className="shiftLeaveDurMinsSpan">
                              {dateStringer.formatMinsDurationToHours(
                                state.shiftLeaveDurMins
                              )}
                            </span>

                            <br />
                            <span className="shiftleaveAddedBySpan">
                              Added by {state.shiftLeaveAddedBy}
                            </span>
                            {state.shiftLeaveNote && <br />}
                          </p>
                          {state.shiftLeaveNote && (
                            <p className="myShiftLeaveNoteTxt">
                              {state.shiftLeaveNote}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="myShiftRowRight"></div>
                    </div>
                  ) : (
                    ""
                  )}
                  {/* end of row */}

                  {/* new row */}
                  <div
                    className={`${
                      state.itemIsAbsent && !state.itemAbsenceData.partialAbsent
                        ? "none"
                        : "myShiftRow"
                    }`}
                  >
                    <div className="myShiftRowLeft">
                      <p
                        className="myShiftRowLeftTitle"
                        onClick={() => {
                          console.log(
                            "ddd: ",
                            `${
                              state.tilApproved
                                ? state.itemDuration - state.tilMins
                                : state.itemDuration
                            }`,
                            `${dateStringer.formatMinsDurationToHours(
                              parseInt(
                                `${
                                  state.tilApproved
                                    ? state.itemDuration - state.tilMins
                                    : state.itemDuration
                                }`
                              )
                            )}`
                          );
                        }}
                      >
                        {state.itemType === "til"
                          ? "Duration earned"
                          : state.inLieu
                          ? "Duation (payable & added to TOIL balance)"
                          : "Payable duration"}
                      </p>
                      <p
                        className={`${
                          state.tilMins === state.itemDuration &&
                          state.takeTilRequestMade &&
                          !state.tilDeclined
                            ? "wholeTilTxt"
                            : "none"
                        }`}
                      >
                        All{" "}
                        {dateStringer.formatMinsDurationToHours(
                          state.itemDuration
                        )}{" "}
                        are {state.tilApproved ? "taken" : "requested"} off as
                        TOIL
                      </p>
                      <span
                        className={`${
                          state.tilMins !== state.itemDuration ||
                          !state.takeTilRequestMade ||
                          state.tilDeclined
                            ? ""
                            : "none"
                        }`}
                      >
                        {" "}
                        {`${
                          state.itemDuration === 0
                            ? "0 mins"
                            : `${dateStringer.formatMinsDurationToHours(
                                state.itemDuration < 0
                                  ? 0
                                  : state.itemDuration || 0
                              )}`
                        }`}{" "}
                      </span>
                      &nbsp;
                      {state.tilMins === state.itemDuration &&
                      state.takeTilRequestMade &&
                      !state.tilDeclined ? (
                        <span className="myShiftBreakSpan x389427232212">
                          {dateStringer.formatMinsDurationToHours(
                            state.itemDuration
                          )}{" "}
                          payable
                        </span>
                      ) : !state.ghost && !state.payClocked ? (
                        <span className="myShiftBreakSpan">
                          /{" "}
                          {dateStringer.formatMinsDurationToHours(
                            state.itemBreak || 0
                          )}{" "}
                          {state.itemType === "til" || state.inLieu
                            ? "excluded break"
                            : "unpaid break"}
                        </span>
                      ) : (
                        ""
                      )}
                      <p
                        className={`${
                          state.tilApproved ? "tilAccountTxt" : "none"
                        } ${
                          state.tilMins === state.itemDuration &&
                          state.takeTilRequestMade
                            ? "none"
                            : ""
                        }`}
                      >
                        {" "}
                        Takes into account{" "}
                        {dateStringer.formatMinsDurationToHours(
                          state.tilMins
                        )}{" "}
                        of time off in lieu (TOIL)
                      </p>
                      {state.inLieu ? (
                        <div className="inLieuMyShiftTxt">
                          <p className="shiftInlieuSpan">
                            Overtime (time in lieu)
                          </p>
                          You will be paid for this shift, and the payable
                          duration will be added to your TOIL balance for you to
                          claim time off from other scheduled shifts
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="myShiftRowRight"></div>
                  </div>
                  {/* end of row */}
                  {memoVals.iWant.dsMade && otherUser ? (
                    <div className="myShiftSwappedRow ">
                      <div className="swappedTitleItem">
                        <img
                          src={navySwap}
                          alt="Swap"
                          className="navySwapImg"
                        />
                        Swap requested
                      </div>
                      <p className="awaitingSwapTxt">
                        {state.fName} has yet to accept
                      </p>
                      <p className="swappedWith">
                        <p className="swappedWithString">
                          You requested{" "}
                          {memoVals.iWant.offerNothing
                            ? `to take this ${
                                state.itemType === "til"
                                  ? "overtime (time in lieu)"
                                  : state.itemType
                              } from ${state.fName}`
                            : `this ${
                                state.itemType === "til"
                                  ? "overtime (time in lieu)"
                                  : state.itemType
                              } from ${state.fName} in return for your ${
                                memoVals.iWant.offeredType === "til"
                                  ? "overtime (time in lieu):"
                                  : `${memoVals.iWant.offeredType}:`
                              }`}
                        </p>
                        {memoVals.iWant.offerNothing ||
                        memoVals.iWant.noLongerExists ? (
                          <div className="myShiftSwappedItem">
                            <div className="myShiftSwappedItemLeft">
                              <div className="myShiftSwappedItemHalf">
                                <p className="noShiftOfferedTxt">
                                  {memoVals.iWant.noLongerExists
                                    ? "Nothing"
                                    : "No shift offered"}
                                </p>
                                {memoVals.iWant.noLongerExists ? (
                                  <p className="noLongerExistsTxt">
                                    Shift no longer exists
                                  </p>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="myShiftSwappedItemRight">
                              <img
                                src={editGrey}
                                alt="Edit"
                                className="myShiftSwapEditBtnImg"
                                onClick={() => {
                                  memoVals.setSwap((x) => {
                                    return {
                                      shiftID: state.itemID,
                                      type: state.itemType,
                                    };
                                  });
                                }}
                              />
                              <img
                                src={greyCross}
                                alt="Edit"
                                className="myShiftSwapEditBtnImg"
                                onClick={() => {
                                  axios
                                    .post(
                                      `${serverURL}/remove-swap-request`,
                                      {
                                        wanterUserID:
                                          memoVals.checkUserState.userID,
                                        shiftID: state.itemID,
                                        type: state.itemType,
                                        cancellingOwnSwap: true,
                                      },

                                      {
                                        withCredentials: true,
                                        credentials: "include",
                                      }
                                    )
                                    .then((response) => {
                                      if (response.data.message === "success") {
                                        memoVals.setIndicate((x) => {
                                          return {
                                            show: true,
                                            message: "Removed swap request",
                                            colour: "blue",
                                            duration: 4000,
                                          };
                                        });
                                        setIWant((x) => {
                                          return {};
                                        });
                                        memoVals.setDynanicSwapOrNote((x) => {
                                          return {
                                            typeID: state.itemID,
                                            swapOrNote: "swap",
                                            note: "",
                                            swap: false,
                                          };
                                        });
                                      }
                                    })
                                    .catch((err) => {
                                      console.error(err);
                                    });
                                }}
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="myShiftSwappedItem">
                            <div className="myShiftSwappedItemLeft">
                              <div className="myShiftSwappedItemHalf">
                                <p className="myShiftSwappedItemLeftDs">
                                  {dateStringer.printedDateFromDs(
                                    memoVals.iWant.offeredStartDs
                                  )}
                                </p>

                                <div className="myShiftSwappedItemLeftBrk">
                                  <p>
                                    {dateStringer.dsToTimeStrip(
                                      memoVals.iWant.offeredStartDs
                                    )}{" "}
                                    -{" "}
                                    {dateStringer.dsToTimeStrip(
                                      memoVals.iWant.offeredEndDs
                                    )}
                                  </p>

                                  <p>
                                    {dateStringer.formatMinsDurationToHours(
                                      memoVals.iWant.offeredDurMins
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="myShiftSwappedItemRight">
                              <img
                                src={editGrey}
                                alt="Edit"
                                className="myShiftSwapEditBtnImg"
                                onClick={() => {
                                  memoVals.setSwap((x) => {
                                    return {
                                      shiftID: state.itemID,
                                      type: state.itemType,
                                    };
                                  });
                                }}
                              />
                              <img
                                src={greyCross}
                                alt="Edit"
                                className="myShiftSwapEditBtnImg"
                                onClick={() => {
                                  axios
                                    .post(
                                      `${serverURL}/remove-swap-request`,
                                      {
                                        wanterUserID:
                                          memoVals.checkUserState.userID,
                                        shiftID: state.itemID,
                                        type: state.itemType,
                                        cancellingOwnSwap: true,
                                      },

                                      {
                                        withCredentials: true,
                                        credentials: "include",
                                      }
                                    )
                                    .then((response) => {
                                      if (response.data.message === "success") {
                                        memoVals.setIndicate((x) => {
                                          return {
                                            show: true,
                                            message: "Removed swap request",
                                            colour: "blue",
                                            duration: 4000,
                                          };
                                        });
                                        memoVals.setIWant((x) => {
                                          return {};
                                        });
                                        memoVals.setDynanicSwapOrNote((x) => {
                                          return {
                                            typeID: state.itemID,
                                            swapOrNote: "swap",
                                            note: "",
                                            swap: false,
                                          };
                                        });
                                      }
                                    })
                                    .catch((err) => {
                                      console.error(err);
                                    });
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  {/* new row */}
                  <div
                    className={`${state.absenceName ? "myShiftRow" : "none"}`}
                  >
                    <div className="myShiftRowLeft">
                      <p className="myShiftRowLeftTitle">Absence</p>
                      {state.absenceName}
                    </div>
                    <div className="myShiftRowRight"></div>
                  </div>
                  {/* end of row */}
                  {/* new row */}
                  {state.itemAbsenceData.unpaidAbsent &&
                  !state.itemAbsenceData.partialAbsent ? (
                    ""
                  ) : memoVals.checkUserState.userID === state.itemUserID &&
                    state.itemType !== "til" ? (
                    <div className="myShiftRow">
                      <div className="myShiftRowLeft">
                        <p className="myShiftRowLeftTitle">Rate of pay</p>
                        {state.itemRate}x{" "}
                      </div>
                      <div className="myShiftRowRight"></div>
                    </div>
                  ) : (
                    ""
                  )}
                  {/* end of row */}

                  {/* new row */}
                  <div
                    className={`${
                      state.itemTags && state.itemTags.length > 0
                        ? "myShiftRow"
                        : "none"
                    }`}
                  >
                    <div className="myShiftRowLeft">
                      <p className="myShiftRowLeftTitle">Tags & duties</p>
                      <div className="myShiftTagsContainer">{generateTags}</div>
                    </div>
                    <div className="myShiftRowRight"></div>
                  </div>
                  {/* end of row */}
                  {/* new row */}
                  {!otherUser ? (
                    <div
                      className={`${
                        state.swapOption &&
                        dateStringer.createTimestampFromString(state.itemDs) >=
                          today00.getTime() &&
                        !state.ghost &&
                        !state.itemIsAbsent &&
                        (!state.clockData ||
                          (state.clockData && !state.clockData.startDs))
                          ? `myShiftRow ${
                              !memoVals.mobModal
                                ? "myShiftAllowSwapsRowHover"
                                : ""
                            }`
                          : "none"
                      }`}
                      onClick={() => {
                        axios
                          .post(
                            `${serverURL}/block-shift-swaps`,
                            {
                              type:
                                state.itemType === "overtime"
                                  ? "til"
                                  : state.itemType,
                              shiftID: state.itemID,
                              data: !state.blockSwaps,
                            },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              setState({ blockSwaps: response.data.data });
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }}
                    >
                      {state.ghost || state.itemIsAbsent ? (
                        ""
                      ) : (
                        <div className="myShiftAllowSwapsRow">
                          <p className="myShiftAllowSwapsTitle">Allow swaps</p>
                          {/*  KNOB BEGIN */}
                          <div className="knobContainer myShiftAllowSwapsKnob">
                            <div
                              className={`switchKnob ${
                                !state.blockSwaps
                                  ? "knobOn knobOnMgrColour"
                                  : "knobOff"
                              }`}
                            ></div>
                            <div className="knobBg"></div>
                          </div>
                          {/*  KNOB END */}{" "}
                        </div>
                      )}
                    </div>
                  ) : state.itemNote && state.itemNote[0] ? (
                    <div className="myShiftNotesContainerOtherUser">
                      <p className="myShiftRowLeftTitle myShiftOtherUserNotesTitle">
                        Notes
                      </p>
                      {generateNotes}
                    </div>
                  ) : (
                    ""
                  )}
                  {/* end of row */}
                  {/* new row */}
                  <div className="myShiftRow">
                    <div className="myShiftRowLeft">
                      <p className="myShiftRowLeftTitle">
                        Colleagues working the same day
                      </p>

                      {!otherUsersArr[0] ? (
                        <p className="x991ddz">
                          Nobody else is scheduled to work this day in{" "}
                          {state.itemTeamName}
                        </p>
                      ) : (
                        <div className="myShiftOtherUsersBlock">
                          {otherUsersArr.map((user) => {
                            return (
                              <div className="myShOtherUserRow">
                                <img
                                  onClick={() => {
                                    memoVals.setSeeUserID((x) => {
                                      return {
                                        userID: user.userID,
                                        openedFromUserRota: false,
                                      };
                                    });
                                  }}
                                  src={user.pic || profilePicturePlaceholder}
                                  className={`otherUsrShImgMy`}
                                  alt={user.fName}
                                />
                                <div className="otherUsrShName">
                                  <p
                                    className="otherUsrShNameTopMy"
                                    onClick={() => {
                                      memoVals.setSeeUserID((x) => {
                                        return {
                                          userID: user.userID,
                                          openedFromUserRota: false,
                                        };
                                      });
                                    }}
                                  >
                                    {user.userID ===
                                    memoVals.checkUserState.userID
                                      ? "You"
                                      : user.fName}{" "}
                                    {user.userID ===
                                    memoVals.checkUserState.userID
                                      ? ""
                                      : user.lName}
                                  </p>
                                  <div className="otherUsrShNameLow">
                                    {user.items.map((x) => {
                                      return (
                                        <p className="otherUserShItem">
                                          <img
                                            src={rotaClock2}
                                            alt="times"
                                            className="otherUsrClock"
                                          />
                                          {dateStringer.dsToTimeStrip(
                                            x.startDs
                                          )}{" "}
                                          -{" "}
                                          {dateStringer.dsToTimeStrip(x.endDs)}
                                        </p>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                    <div className="myShiftRowRight"></div>
                  </div>
                  {/* end of row */}
                  {/* new row */}
                  {changeRequests[0] ? (
                    <div className="myShiftRow changesArrRow">
                      <p className="shiftHistoryTitle reqsToChangeTitle">
                        Requests to change this{" "}
                        {state.itemType === "til"
                          ? "overtime (time in lieu)"
                          : state.itemType}
                      </p>
                      <div className="changesArrDiv">
                        {changeRequests
                          .sort((a, b) => {
                            return b.tsMade - a.tsMade;
                          })
                          .map((change, i) => {
                            let oldStartCorrect = change.oldStart;
                            let oldEndCorrect = change.oldEnd;

                            if (change.oldStart.includes(":")) {
                              oldStartCorrect = `${change.newDs.substr(
                                0,
                                11
                              )}H${change.oldStart.substr(
                                0,
                                2
                              )}M${change.oldStart.substr(3, 2)}`;
                            }

                            if (change.oldEnd.includes(":")) {
                              oldEndCorrect = `${change.newDs.substr(
                                0,
                                11
                              )}H${change.oldEnd.substr(
                                0,
                                2
                              )}M${change.oldEnd.substr(3, 2)}`;
                            }

                            let oldStartObj = new Date(
                              dateStringer.createTimestampFromString(
                                oldStartCorrect
                              )
                            );
                            let oldEndObj = new Date(
                              dateStringer.createTimestampFromString(
                                oldEndCorrect
                              )
                            );

                            if (oldEndObj.getTime() <= oldStartObj.getTime()) {
                              oldEndObj.setDate(oldEndObj.getDate() + 1);
                              oldEndCorrect =
                                dateStringer.createStringFromTimestamp(
                                  oldEndObj.getTime()
                                );
                            }

                            let dateChanged =
                              change.oldDs.substr(0, 11) !==
                              change.newDs.substr(0, 11);
                            let startChanged =
                              oldStartCorrect !== change.newStart;
                            let endChanged = oldEndCorrect !== change.newEnd;
                            let breakChanged = change.oldBrk !== change.newBrk;

                            let oldStartObj2 = new Date(
                              dateStringer.createTimestampFromString(
                                oldStartCorrect
                              )
                            );
                            let oldEndObj2 = new Date(
                              dateStringer.createTimestampFromString(
                                oldEndCorrect
                              )
                            );
                            let oldDurMins =
                              (oldEndObj2.getTime() - oldStartObj2.getTime()) /
                                1000 /
                                60 -
                              (change.oldBrk || 0);

                            let newStartObj = new Date(
                              dateStringer.createTimestampFromString(
                                change.newStart
                              )
                            );
                            let newEndObj = new Date(
                              dateStringer.createTimestampFromString(
                                change.newEnd
                              )
                            );
                            let newDurMins =
                              (newEndObj.getTime() - newStartObj.getTime()) /
                                1000 /
                                60 -
                              (change.newBrk || 0);
                            return (
                              <div
                                className={`myShiftChangeArrUnit ${
                                  !change.approved && !change.declined
                                    ? "myShPendingChLeftBorder"
                                    : ""
                                }`}
                                onClick={() => {
                                  console.log(
                                    dateChanged,
                                    change.oldDs,
                                    change.newDs
                                  );
                                }}
                              >
                                <div className="changeUnitTop">
                                  <p className="changeUnitTitle">
                                    {change.drop
                                      ? "Drop request"
                                      : "Change request"}
                                  </p>
                                  <p
                                    className={
                                      change.approved
                                        ? "changeApprovedStat"
                                        : change.declined
                                        ? "changeDeclinedStat"
                                        : "changePendingStat"
                                    }
                                  >
                                    {change.approved
                                      ? "Approved"
                                      : change.declined
                                      ? "Declined"
                                      : "Pending"}{" "}
                                    {!change.approved && !change.declined ? (
                                      <img
                                        onClick={() => {
                                          console.log(
                                            "corres",
                                            change.correspondingEditID,
                                            "editid",
                                            change.editID
                                          );
                                          setSureCancelChange({
                                            show: true,
                                            change: state.itemType,
                                            correspondingEditID:
                                              change.correspondingEditID ||
                                              change.editID,
                                            shiftID: state.itemID,
                                            editID:
                                              change.correspondingEditID ||
                                              change.editID,
                                            drop: change.drop,
                                          });
                                        }}
                                        src={cancelTil}
                                        alt="Cancel request"
                                        className="cancelReqInMyShiftImg"
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </p>
                                </div>
                                <div className="changeReqDs">
                                  Requested{" "}
                                  {dateStringer.printedDateWithTimeFromDs(
                                    change.dsMade
                                  )}
                                </div>
                                <div className="changeItemsDiv">
                                  {dateChanged &&
                                  change.oldDs !== change.newDs ? (
                                    <p
                                      className={`changeItemRow ${
                                        change.approved ? "chAppBorder" : ""
                                      } ${
                                        change.declined ? "chDecBorder" : ""
                                      }`}
                                    >
                                      Date change from
                                      <br />
                                      {dateStringer.printedDateFromDs(
                                        change.oldDs
                                      )}{" "}
                                      to{" "}
                                      {dateStringer.printedDateFromDs(
                                        change.newDs
                                      )}
                                    </p>
                                  ) : (
                                    ""
                                  )}

                                  {startChanged &&
                                  oldStartCorrect.substr(12, 5) !==
                                    change.newStart.substr(12, 5) ? (
                                    <p
                                      className={`changeItemRow ${
                                        change.approved ? "chAppBorder" : ""
                                      } ${
                                        change.declined ? "chDecBorder" : ""
                                      }`}
                                    >
                                      Start time change from{" "}
                                      {dateStringer.dsToTimeStrip(
                                        oldStartCorrect
                                      )}{" "}
                                      to{" "}
                                      {dateStringer.dsToTimeStrip(
                                        change.newStart
                                      )}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                  {/* Y2020M01D00 H 0 0 M 0 0 */}
                                  {/* 012345678910111213141516 */}
                                  {endChanged &&
                                  oldEndCorrect.substr(12, 5) !==
                                    change.newEnd.substr(12, 5) ? (
                                    <p
                                      className={`changeItemRow ${
                                        change.approved ? "chAppBorder" : ""
                                      } ${
                                        change.declined ? "chDecBorder" : ""
                                      }`}
                                    >
                                      End time change from{" "}
                                      {dateStringer.dsToTimeStrip(
                                        oldEndCorrect
                                      )}{" "}
                                      to{" "}
                                      {dateStringer.dsToTimeStrip(
                                        change.newEnd
                                      )}
                                    </p>
                                  ) : (
                                    ""
                                  )}

                                  {breakChanged ? (
                                    <p
                                      className={`changeItemRow ${
                                        change.approved ? "chAppBorder" : ""
                                      } ${
                                        change.declined ? "chDecBorder" : ""
                                      }`}
                                    >
                                      Break duration change from{" "}
                                      {dateStringer.formatMinsDurationToHours(
                                        change.oldBrk
                                      )}{" "}
                                      to{" "}
                                      {dateStringer.formatMinsDurationToHours(
                                        change.newBrk
                                      )}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                {oldDurMins !== newDurMins ? (
                                  <p className="changeShiftDurMinsDiff">
                                    Old duration:{" "}
                                    <span className="changeDurSpanMins">
                                      {dateStringer.formatMinsDurationToHours(
                                        oldDurMins
                                      )}
                                    </span>
                                    <br /> New duration:{" "}
                                    <span className="changeDurSpanMins">
                                      {dateStringer.formatMinsDurationToHours(
                                        newDurMins
                                      )}
                                    </span>
                                  </p>
                                ) : (
                                  <p
                                    className={`changeShiftDurMinsDiff ${
                                      change.drop ? "paddingRight20" : ""
                                    }`}
                                  >
                                    {change.drop
                                      ? change.approved
                                        ? `You no longer want this ${
                                            state.itemType === "shift"
                                              ? "shift"
                                              : "overtime"
                                          }`
                                        : change.declined
                                        ? "Drop request declined"
                                        : "Drop request awaiting approval"
                                      : "No change to duration"}
                                  </p>
                                )}

                                {change.drop && change.coverFName ? (
                                  <p
                                    className={`changeShiftDurMinsDiff paddingRight20 colleageFwd`}
                                  >
                                    <img
                                      src={
                                        change.profPicUrl ||
                                        profilePicturePlaceholder
                                      }
                                      alt={change.coverFName}
                                      className="colleageFwdImg"
                                    />
                                    You put {change.coverFName}{" "}
                                    {change.coverLName} forward to cover it
                                  </p>
                                ) : (
                                  ""
                                )}
                                {change.note ? (
                                  <p className="chNote">
                                    <span className="chNoteSpanTitle">
                                      Note:
                                    </span>{" "}
                                    {change.note}
                                  </p>
                                ) : (
                                  ""
                                )}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {/* end of row */}
                  {/* new row */}
                  {state.history[0] ? (
                    <div className="myShiftRow">
                      <div className="myShiftRowLeft">
                        <p className="shiftHistoryTitle">History</p>
                        {state.history[0] !== "none" ? generateChanges : ""}
                        <div className="noChangesContainer">
                          <p
                            className={`${
                              state.history[0] === "none"
                                ? "noChangesTxt"
                                : "none"
                            }`}
                          >
                            {state.history[0] === "none"
                              ? `No changes have been made to this ${
                                  state.itemType === "shift"
                                    ? "shift"
                                    : `${
                                        state.itemType === "overtime"
                                          ? "overtime"
                                          : "overtime (time in lieu)"
                                      }`
                                }`
                              : ""}
                          </p>
                        </div>
                        {/* <p className="myShiftRowLeftTitle">Note</p>
                  <p className="itemNote">{state.itemNote[0] ? "notes" : ""}</p> */}
                      </div>
                      <div className="myShiftRowRight"></div>
                    </div>
                  ) : (
                    ""
                  )}
                  {/* end of row */}
                  {/* new row */}
                  {/* <div className={`${state.itemAddedBy ? "myShiftRow" : "none"}`}>
                  <div className="myShiftRowLeft">
                    <p className="myShiftRowLeftTitle">Added by</p>{" "}
                    {`${
                      state.itemAddedByUserID === memoVals.checkUserState.userID
                        ? "You"
                        : `${formatName(state.itemAddedBy)}`
                    }`}
                  </div>

                  <div className="myShiftRowRight"></div>
                </div> */}
                  {/* end of row */}
                  {/* </div> */}
                  {/* -------------------------- */}
                  {/* body history */}
                  {/* <div
              className={`${showNotes ? "shiftHistoryContainer" : "none"}`}
            >
              <p className="shiftHistoryTitle">History &amp; changes</p>
              {history[0] !== "none" ? generateChanges : ""}
              <div className="noChangesContainer">
                <p
                  className={`${
                    history[0] === "none" ? "noChangesTxt" : "none"
                  }`}
                >
                  {history[0] === "none"
                    ? `No changes have been made to this ${
                        state.itemType === "shift"
                          ? "shift"
                          : `${
                              state.itemType === "overtime"
                                ? "overtime"
                                : "time in lieu"
                            }`
                      }`
                    : ""}
                </p>
              </div>
            </div> */}
                </div>
              )}

              {/* end of body history */}
            </div>
            {/* end of modal body middle bit */}
            {/* modal footer */}
            <div
              className={`myShiftModalFooter ${
                memoVals.mobModal ? "mobMyShiftModalFooter" : ""
              }`}
            >
              {otherUser ? (
                <div
                  className={`myShiftModalFooterLeftMyShiftOtheruser ${
                    !dataLoaded ? "invis" : ""
                  }`}
                >
                  {otherUser &&
                  new Date().setHours(0, 0, 0, 0) <=
                    dateStringer.createTimestampFromString(state.itemDs) &&
                  state.swapOption &&
                  !state.blockSwaps &&
                  !state.ghost ? (
                    <p
                      className={`shiftSwapButton ${
                        showNotes ? "showNotesOpenShiftSwap" : ""
                      } lightBlueColours ${
                        memoVals.iWant.dsMade && otherUser ? "none" : ""
                      }`}
                      onClick={() => {
                        // console.log(
                        //   new Date().setHours(0, 0, 0, 0) <=
                        //     dateStringer.createTimestampFromString(state.itemDs)
                        // );

                        memoVals.setSwap((x) => {
                          return {
                            shiftID: state.itemID,
                            type: state.itemType,
                          };
                        });
                      }}
                    >
                      <img src={swap2} alt="Swap" className="swapBtnImg" />
                      Swap
                    </p>
                  ) : (
                    ""
                  )}
                  <div
                    className={`${
                      showNotes
                        ? `requestShiftHistoryBtnContainerActive`
                        : `requestShiftHistoryBtnContainer`
                    } `}
                    onClick={() => {
                      setShowNotes(!showNotes);
                    }}
                  >
                    <img
                      src={whitecross}
                      className={`${
                        showNotes ? "closeHistoryShiftImgCross" : "none"
                      }`}
                      alt="Close"
                    />
                    <div
                      className={`${
                        showNotes
                          ? "requestShiftHistoryBtnTxtActive"
                          : `requestShiftHistoryBtnTxtInactive ${
                              state.itemNote && state.itemNote.length > 0
                                ? `blueColours ${
                                    !memoVals.mobModal ? "x1313322_" : ""
                                  }`
                                : ""
                            }`
                      }`}
                      onClick={() => {}}
                    >
                      {" "}
                      {showNotes ? (
                        ""
                      ) : (
                        <img
                          src={
                            state.itemNote && state.itemNote.length > 0
                              ? noteImg
                              : noteImg
                          }
                          alt="Notes"
                          className="myShiftNoteCommentIcon"
                        />
                      )}
                      Notes{" "}
                      <span
                        className={`${
                          state.itemNote && state.itemNote.length > 9
                            ? "x98472515"
                            : "x5362"
                        } ${showNotes ? "none" : ""} ${
                          state.itemNote && state.itemNote.length === 0
                            ? "none"
                            : ""
                        }`}
                      >
                        (
                        {state.itemNote && state.itemNote[0]
                          ? state.itemNote.length < 10
                            ? state.itemNote.length
                            : `9+`
                          : 0}
                        )
                      </span>
                    </div>
                  </div>
                  {/* {otherUser ? (
                    <p
                      className={`myShiftMessageBtn`}
                      onClick={() => {
                        memoVals.setShowMessages((x) => state.otherUserID);
                      }}
                    >
                      <img src={chat} alt="Swap" className="myShiftMsgImg" />
                      Message
                    </p>
                  ) : (
                    ""
                  )} */}
                </div>
              ) : (
                <div className="myShiftModalFooterLeftMyShift">
                  <div
                    className={`${
                      showNotes
                        ? `requestShiftHistoryBtnContainerActive`
                        : `requestShiftHistoryBtnContainer`
                    } `}
                    onClick={() => {
                      setShowNotes(!showNotes);
                    }}
                  >
                    <img
                      src={whitecross}
                      className={`${
                        showNotes ? "closeHistoryShiftImgCross" : "none"
                      }`}
                      alt="Close"
                    />
                    <div
                      className={`${
                        showNotes
                          ? "requestShiftHistoryBtnTxtActive"
                          : `requestShiftHistoryBtnTxtInactive ${
                              state.itemNote && state.itemNote.length > 0
                                ? `blueColours ${
                                    !memoVals.mobModal ? "x1313322_" : ""
                                  }`
                                : ""
                            }`
                      }`}
                      onClick={() => {}}
                    >
                      {" "}
                      {showNotes ? (
                        ""
                      ) : (
                        <img
                          src={state.itemNote[0] ? noteImg : noteImg}
                          alt="Notes"
                          className="myShiftNoteCommentIcon"
                        />
                      )}
                      Notes{" "}
                      <span
                        className={`${
                          state.itemNote && state.itemNote.length > 9
                            ? "x98472515"
                            : "x5362"
                        } ${showNotes ? "none" : ""} ${
                          state.itemNote && state.itemNote.length === 0
                            ? "none"
                            : ""
                        }`}
                      >
                        (
                        {state.itemNote && state.itemNote[0]
                          ? state.itemNote.length < 10
                            ? state.itemNote.length
                            : `9+`
                          : 0}
                        )
                      </span>
                    </div>
                  </div>
                  {!otherUser && !state.clockOnID && !state.ghost ? (
                    <div
                      className={`requestShiftEditBtnContainer ${
                        showNotes ||
                        state.itemAbsenceData.absent ||
                        shiftIsPast ||
                        state.createdByClock
                          ? "none"
                          : ""
                      }`}
                    >
                      <p
                        className="requestShiftEditBtnTxt x139714"
                        onClick={() => {
                          memoVals.setShowEditMyShiftModal((x) => true);
                          setShowNotes(false);
                        }}
                      >
                        Edit
                        <img
                          src={edit}
                          alt="Take overtime (time in lieu)"
                          className="myShiftEditBtnImg"
                        />
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  {!otherUser &&
                  !state.clockOnID &&
                  !state.ghost &&
                  state.itemType === "shift" ? (
                    <div
                      className={`${
                        state.itemType === "til" ||
                        !memoVals.checkUserState.tilEnabled
                          ? "takeTilBtnContainer overtimeHTiLDisable"
                          : `takeTilBtnContainer`
                      } ${
                        showNotes ||
                        state.itemAbsenceData.absent ||
                        shiftIsPast ||
                        state.createdByClock
                          ? "none"
                          : ""
                      } 
       
              `}
                    >
                      <p
                        className="takeTilBtn"
                        onClick={() => {
                          if (state.itemType !== "til") {
                            if (
                              memoVals.checkUserState.tilEnabled &&
                              !state.itemAbsenceData.absent
                            ) {
                              memoVals.setShowTilTrimModal((x) => true);
                            }
                          }
                        }}
                      >
                        Use TOIL{" "}
                        <img
                          src={scissors}
                          alt="Take overtime (time in lieu)"
                          className="myShiftEditBtnImg"
                        />
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              )}
              <div
                className="myShiftModalFooterRight"
                onClick={() => {
                  clearData();
                  memoVals.setShowMyShiftModal((x) => {
                    return {};
                  });
                }}
              >
                Close
              </div>

              {sureCancelTil ? (
                <div
                  className="addAbsenceModalUnderlay"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSureCancelChange(false);
                  }}
                >
                  <div className="formCreatedModal">
                    <p className="overlapsRenTxt">
                      Are you sure you want to cancel your TOIL request?
                    </p>
                    <div className="areYouModalBtnsDiv">
                      <p
                        className="areYouSureModalYesBtn"
                        onClick={() => {
                          if (
                            state.tilRequested ||
                            state.tilApproved ||
                            state.tilDeclined
                          ) {
                            setSureCancelTil(false);

                            axios
                              .post(
                                `${serverURL}/remove-til-request`,
                                {
                                  takeTilID: state.takeTilID,
                                  type: state.itemType,
                                  shiftID: state.itemID,
                                  user: true,
                                  nowDs: dateStringer.createStringFromTimestamp(
                                    new Date().getTime()
                                  ),
                                },

                                {
                                  withCredentials: true,
                                  credentials: "include",
                                }
                              )
                              .then((response) => {
                                if (response.data.message === "success") {
                                  // TODO
                                  setState({
                                    tilMins: 0,
                                    tilApproved: false,
                                    tilDeclined: false,
                                    tilRequested: false,
                                    takeTilRequest: false,
                                    takeTilRequestMade: false,
                                  });
                                  memoVals.setShowTilTrimModal((x) => false);
                                  memoVals.setUpdateShifts(
                                    (x) => !memoVals.updateShifts
                                  );
                                } else {
                                }
                              })
                              .catch((err) => {
                                console.error(err);
                              });
                          }
                        }}
                      >
                        Yes
                      </p>
                      <p
                        className="areYouModalNoBtn"
                        onClick={() => {
                          setSureCancelTil(false);
                        }}
                      >
                        Cancel
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            {/* end of footer */}
          </div>
        )}

        {sureRemoveLogged ? (
          <div
            className="addAbsenceModalUnderlay"
            onClick={(e) => {
              e.stopPropagation();
              setSureCancelChange(false);
            }}
          >
            <div className="formCreatedModal">
              <p className="overlapsRenTxt">
                Are you sure you want to remove your logged start and end times?
              </p>
              <div className="areYouModalBtnsDiv">
                <p
                  className="areYouSureModalYesBtn"
                  onClick={() => {
                    axios
                      .post(
                        `${serverURL}/log-actual-times`,
                        {
                          shiftID: state.itemID,
                          type: state.itemType,
                          remove: true,
                          nowDs: dateStringer.createStringFromTimestamp(
                            new Date().getTime()
                          ),
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          memoVals.setReloadTs((x) => !memoVals.reloadTs);

                          setShowLogActualTimes(false);
                          setLoggedStartTime("");
                          setLoggedEndTime("");
                          setLoggedBrk(0);
                          setSureRemoveLogged(false);
                          setLogStartTime(state.itemStart);
                          setLogEndTime(state.itemEnd);
                          setLogBrk(state.itemBreak);
                          setLoggedAddedTags([]);
                          setLoggedPulledTags([]);
                          memoVals.setIndicate((x) => {
                            return {
                              show: true,
                              message:
                                "Removed your logged start and end times",
                              colour: "green",
                              duration: 4000,
                            };
                          });
                          if (notesBox.current) {
                            notesBox.current.scrollTo({
                              top: 0, // Scroll to the bottom of the div
                              behavior: "smooth", // Smooth scroll behavior
                            });
                          }
                        }
                      });
                  }}
                >
                  Yes
                </p>
                <p
                  className="areYouModalNoBtn"
                  onClick={() => {
                    setSureRemoveLogged(false);
                  }}
                >
                  Cancel
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {sureCancelAbsence ? (
          <div
            className="addAbsenceModalUnderlay"
            onClick={(e) => {
              e.stopPropagation();
              setSureCancelAbsence(false);
            }}
          >
            <div className="formCreatedModal">
              <p className="overlapsRenTxt">
                Are you sure you want to cancel your absence submission?
              </p>
              <div className="areYouModalBtnsDiv">
                <p
                  className="areYouSureModalYesBtn"
                  onClick={() => {
                    setSureCancelAbsence(false);
                    axios
                      .post(
                        `${serverURL}/self-log-absence`,
                        {
                          shiftID: state.itemID,
                          type: state.itemType,
                          reason,
                          nowDs: dateStringer.createStringFromTimestamp(
                            new Date().getTime()
                          ),
                          remove: true,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          setState({
                            selfLoggedAbsence: "",
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  Yes
                </p>
                <p
                  className="areYouModalNoBtn"
                  onClick={() => {
                    setSureCancelAbsence(false);
                  }}
                >
                  No
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {sureWithdraw ? (
          <div
            className="addAbsenceModalUnderlay"
            onClick={(e) => {
              e.stopPropagation();
              setSureWithdraw(false);
            }}
          >
            <div className="formCreatedModal">
              <p className="overlapsRenTxt">
                Are you sure you want to withdraw from covering{" "}
                {dateStringer.possession(state.fName)}{" "}
                {state.itemType === "shift" ? "shift" : "overtime"}?<br />
                <br />
                FlowRota will notify {state.fName}.
              </p>
              <div className="areYouModalBtnsDiv">
                <p
                  className="areYouSureModalYesBtn"
                  onClick={() => {
                    setSureWithdraw(false);
                    axios
                      .post(
                        `${serverURL}/withdraw-cover`,
                        {
                          editID: forward,
                          shiftID: unitID,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          setForward(false);
                          memoVals.setIndicate((x) => {
                            return {
                              show: true,
                              message: "Withdrawn from cover successful",
                              colour: "green",
                              duration: 4000,
                            };
                          });
                        }
                      });
                  }}
                >
                  Yes
                </p>
                <p
                  className="areYouModalNoBtn"
                  onClick={() => {
                    setSureWithdraw(false);
                  }}
                >
                  No
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {renderEditMyShiftModal()}
        {renderTilTrimModal()}
        {sureCancelChange.show ? (
          <div
            className="areYouSureModalUnderlay"
            onClick={() => {
              setSureCancelChange({ show: false });
            }}
          >
            <div
              className="formCreatedModal"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <p className="overlapsRenTxt">
                Are you sure you want to cancel this{" "}
                {sureCancelChange.drop
                  ? `request to drop your ${
                      state.itemType === "shift" ? "shift" : "overtime"
                    }`
                  : "change request"}
                ?
              </p>
              <div className="areYouModalBtnsDiv">
                <p
                  className="areYouSureModalYesBtn"
                  onClick={() => {
                    setSureCancelChange({ show: false });
                    axios
                      .post(
                        `${serverURL}/cancel-shift-change-request`,
                        {
                          change: sureCancelChange.change,
                          correspondingEditID:
                            sureCancelChange.correspondingEditID,
                          shiftID: sureCancelChange.shiftID,
                        },
                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          // HEIDI

                          memoVals.setUpdateShifts(
                            (x) => !memoVals.updateShifts
                          );
                          memoVals.setUpdateTilData(!memoVals.updateTilData);
                          setCountData({
                            outstandingChanges:
                              countData.outstandingChanges - 1,
                          });
                          memoVals.setIndicate((x) => {
                            return {
                              show: true,
                              message: "Change request removed",
                              colour: "green",
                              duration: 4000,
                            };
                          });
                          memoVals.setRemoveMyChangeEditID(
                            (x) => sureCancelChange.editID
                          );
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  Yes
                </p>
                <p
                  className="areYouModalNoBtn"
                  onClick={() => {
                    setSureCancelChange({ show: false });
                  }}
                >
                  No
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {showSelfLogAbsenceModal ? (
          <div
            className="clockOnModalUnderlay"
            onClick={(e) => {
              e.stopPropagation();
              setShowSelfLogAbsenceModal(false);
            }}
          >
            <div
              className={`dayCommentsModal dayCommentsModalLogAbs ${
                memoVals.mobModal ? "mobModalShoulder" : "maxViewZoneModalWidth"
              } ${memoVals.device.ios ? "paddingBottom35" : ""}`}
              onClick={(e) => {
                e.stopPropagation();
              }}
              style={modalPositionLogAbs}
              ref={modalRefLogAbs}
            >
              {memoVals.mobModal && (
                <div
                  className="modalSwiper modalSwiperLogAbs"
                  onTouchStart={handleDownLogAbs}
                  onTouchMove={memoVals.mobModal ? handleMoveLogAbs : null}
                  onTouchEnd={memoVals.mobModal ? handleUpLogAbs : null}
                  onMouseDown={memoVals.mobModal ? handleDownLogAbs : null}
                  onMouseMove={memoVals.mobModal ? handleMoveLogAbs : null}
                  onMouseUp={memoVals.mobModal ? handleUpLogAbs : null}
                ></div>
              )}
              <div
                className="mySwapsHeader"
                onClick={() => {
                  setShowSelfLogAbsenceModal(false);
                }}
                onTouchStart={handleDownLogAbs}
                onTouchMove={memoVals.mobModal ? handleMoveLogAbs : null}
                onTouchEnd={memoVals.mobModal ? handleUpLogAbs : null}
                onMouseDown={memoVals.mobModal ? handleDownLogAbs : null}
                onMouseMove={memoVals.mobModal ? handleMoveLogAbs : null}
                onMouseUp={memoVals.mobModal ? handleUpLogAbs : null}
              >
                <img
                  src={close}
                  alt="Close"
                  className="closeMySwapsModalImg"
                  onClick={() => {
                    setShowSelfLogAbsenceModal(false);
                  }}
                />
                <p>Log your absence</p>

                <p></p>
              </div>
              <div className="dayCommentsModalBody dayCommentsModalBodyLogAbs">
                <div className="gpsZoneRow noBorder">
                  <p className="lonLatTitles">
                    Absent{" "}
                    {state.itemType === "til"
                      ? "overtime (time in lieu)"
                      : state.itemType}
                  </p>

                  <p className={`lonLatVal`}>
                    {dateStringer.printedDateWithTimeFromDs(state.itemDs)}
                  </p>
                </div>
                <div className="gpsZoneRow">
                  <p className="lonLatTitles">Absence duration</p>

                  <p className={`lonLatVal`}>
                    {dateStringer.formatMinsDurationToHours(state.itemDuration)}
                  </p>
                </div>
                <div className="gpsZoneRow">
                  <p className="lonLatTitles">Reason for your absence</p>

                  <div className={`lonLatInputDiv`}>
                    {" "}
                    <textarea
                      type="text"
                      placeholder={`State why you are going to be absent for this ${
                        state.itemType === "til"
                          ? "overtime (time in lieu)"
                          : state.itemType
                      }`}
                      className={`latLonInput x13456783212`}
                      value={reason}
                      onChange={(e) => {
                        // setNewName(e.target.value);
                        setReason(e.target.value);
                      }}
                    ></textarea>
                    <p></p>
                  </div>
                </div>{" "}
              </div>
              <div className="dayCommentsModalFooter">
                <div className="viewMapsAndSaveDiv">
                  <a
                    className="viewGpsZoneMapBtn x4978284432"
                    onClick={() => {
                      setShowSelfLogAbsenceModal(false);

                      axios
                        .post(
                          `${serverURL}/self-log-absence`,
                          {
                            shiftID: state.itemID,
                            type: state.itemType,
                            reason,
                            nowDs: dateStringer.createStringFromTimestamp(
                              new Date().getTime()
                            ),
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message === "success") {
                            setState({
                              selfLoggedAbsence:
                                dateStringer.createStringFromTimestamp(
                                  new Date().getTime()
                                ),
                              selfLoggedAbsenceReason: reason,
                            });
                            setShowSelfLogAbsenceModal(false);
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >
                    Submit
                  </a>
                </div>

                <p
                  className="closeSeeUserIDBtn"
                  onClick={() => {
                    setShowSelfLogAbsenceModal(false);
                  }}
                >
                  Close
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {showDecline ? (
          <div
            className="clockOnModalUnderlay"
            onClick={(e) => {
              e.stopPropagation();
              setShowDecline(false);
            }}
          >
            <div
              style={modalPositionDecline}
              ref={modalRefDecline}
              className={`dayCommentsModal ${
                memoVals.mobModal ? "mobModalShoulder" : "maxViewZoneModalWidth"
              }`}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {memoVals.mobModal && (
                <div
                  className="modalSwiper modalSwiperDecline"
                  onTouchStart={handleDownDecline}
                  onTouchMove={memoVals.mobModal ? handleMoveDecline : null}
                  onTouchEnd={memoVals.mobModal ? handleUpDecline : null}
                  onMouseDown={memoVals.mobModal ? handleDownDecline : null}
                  onMouseMove={memoVals.mobModal ? handleMoveDecline : null}
                  onMouseUp={memoVals.mobModal ? handleUpDecline : null}
                ></div>
              )}
              <div
                className="mySwapsHeader"
                onTouchStart={handleDownDecline}
                onTouchMove={memoVals.mobModal ? handleMoveDecline : null}
                onTouchEnd={memoVals.mobModal ? handleUpDecline : null}
                onMouseDown={memoVals.mobModal ? handleDownDecline : null}
                onMouseMove={memoVals.mobModal ? handleMoveDecline : null}
                onMouseUp={memoVals.mobModal ? handleUpDecline : null}
              >
                <img
                  src={close}
                  alt="Close"
                  className="closeMySwapsModalImg"
                  onClick={() => {
                    setShowDecline(false);
                  }}
                />
                <p>
                  Decline this{" "}
                  {state.itemType === "til"
                    ? "overtime (time in lieu)"
                    : state.itemType}
                </p>

                <p></p>
              </div>
              <div className="dayCommentsModalBody fitContentHeight">
                <div className="gpsZoneRow noBorder">
                  <p className="lonLatTitles">
                    {state.itemType === "til"
                      ? "overtime (time in lieu)"
                      : state.itemType}{" "}
                    date
                  </p>

                  <p className={`lonLatVal`}>
                    {dateStringer.printedDateWithTimeFromDs(state.itemDs)}
                  </p>
                </div>
                <div className="gpsZoneRow">
                  <p className="lonLatTitles">Duration</p>

                  <p className={`lonLatVal`}>
                    {dateStringer.formatMinsDurationToHours(state.itemDuration)}
                  </p>
                </div>
                <div className="gpsZoneRow">
                  <p className="lonLatTitles">Reason for declining</p>

                  <div className={`lonLatInputDiv`}>
                    {" "}
                    <textarea
                      type="text"
                      placeholder={`Optional - state why you are declining this ${
                        state.itemType === "til"
                          ? "overtime (time in lieu)"
                          : state.itemType
                      }`}
                      className={`latLonInput x13456783212`}
                      value={declineReason}
                      onChange={(e) => {
                        setDeclineReason(e.target.value);
                      }}
                    ></textarea>
                    <p></p>
                  </div>
                </div>{" "}
              </div>
              <div className="dayCommentsModalFooter">
                <div className="viewMapsAndSaveDiv">
                  <a
                    className="viewGpsZoneMapBtn x4978284432"
                    onClick={() => {
                      axios
                        .post(
                          `${serverURL}/decline-shift`,
                          {
                            shiftID: state.itemID,
                            type: state.itemType,
                            nowDs: dateStringer.createStringFromTimestamp(
                              new Date().getTime()
                            ),
                            reason: declineReason,
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message === "success") {
                            memoVals.setDeclinedShiftID((x) => state.itemID);
                            setCountData({
                              shiftAccepts: countData.shiftAccepts - 1,
                            });
                            memoVals.setIndicate((x) => {
                              return {
                                show: true,
                                message: `Declined this ${
                                  state.itemType === "til"
                                    ? "overtime (time in lieu)"
                                    : state.itemType
                                }`,
                                colour: "blue",
                                duration: 4000,
                              };
                            });
                            setShowDecline(false);
                            memoVals.setShowMyShiftModal((x) => {
                              return {};
                            });
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >
                    Decline
                  </a>
                </div>

                <p
                  className="closeSeeUserIDBtn"
                  onClick={() => {
                    setShowDecline(false);
                  }}
                >
                  Close
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {shiftDoesNotExist ? (
          <div
            className="addAbsenceModalUnderlay"
            onClick={(e) => {
              e.stopPropagation();
              memoVals.setUpdateMyRota((x) => !memoVals.udpateMyRota);
              memoVals.setShowMyShiftModal((x) => false);
            }}
          >
            <div
              className="formCreatedModal"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              This shift no longer exists.
              <div className="areYouModalBtnsDiv">
                <p
                  className="closeSwapsNoLongerBtn"
                  onClick={() => {
                    memoVals.setUpdateMyRota((x) => !memoVals.udpateMyRota);

                    memoVals.setShowMyShiftModal((x) => false);
                  }}
                >
                  Close
                </p>
                {/* <p
                    className="areYouModalNoBtn"
                    onClick={() => {
                      setNotEnoughModal(false);
                    }}
                  >
                    Cancel
                  </p> */}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {showUnable ? (
          <div
            className="addAbsenceModalUnderlay"
            onClick={(e) => {
              e.stopPropagation();

              setShowUnable(false);
            }}
          >
            {" "}
            <div
              className="formCreatedModal"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <p className="overlapsRenTxt">
                You cannot log the actual hours of a shift you have been marked
                absent for.
                <br />
                <br />
                Ask your manager to amend this shift for you instead.
              </p>
              <div className="areYouModalBtnsDiv">
                <p
                  className="areYouSureModalYesBtn understoodBtn"
                  onClick={() => {
                    setShowUnable(false);
                  }}
                >
                  Understood
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {imgPreload}
      </div>
    );
  } else {
    return "";
  }
};

export default MyShiftModal;
